import React from "react";
import { useLocation } from "react-router-dom";
import PageLayout from "../PageLayout";
import CapacityManagement from "./common/CapacityManagement";

const ACP = () => {
  let location = useLocation();
  return (
    <div className="acp">
      <PageLayout>
        <CapacityManagement mode={location?.state} />
      </PageLayout>
    </div>
  );
};
export default ACP;
