/* eslint-disable import/no-anonymous-default-export */
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes.js";
import { publicRoutes, renderProtectedRoutes } from "./routes.js";
import { hasPublicRoutes, hasRequiredAuthData } from "../Utils/helpers.js";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

export default () => {
  const [isNavigating, setIsNavigating] = useState(false);
  const navigate = useNavigate();
  const isValid = hasRequiredAuthData();
  const location = useLocation();

  const { REACT_APP_AUTHORIZED_URL } = process.env;

  if (isEmpty(REACT_APP_AUTHORIZED_URL)) {
    throw new Error("Missing env file");
  }

  useEffect(() => {
    if (!hasPublicRoutes() && location.pathname !== "/") {
      localStorage.setItem("currentRoute", location.pathname);
    }
    //Removing the current Route when user closes the browser
    const handleBeforeUnload = () => {
      localStorage.removeItem("currentRoute");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location?.pathname]);

  useEffect(() => {
    let currentRoute = localStorage.getItem("currentRoute");
    const navigateUrlBasedOnRole = localStorage.getItem("navigateUrlBasedOnRole");
    currentRoute =
      !isEmpty(currentRoute) && currentRoute !== "/undefined"
        ? currentRoute
        : navigateUrlBasedOnRole;

    if(currentRoute?.includes('capacityManagement')) {
      currentRoute = '/capacityManagement/dashboard';
    }

    // console.info('%cHave a valid data in localStorage:', 'color: blue;', isValid)
    // console.info('%cCurrent route is:', 'color: blue;', currentRoute, !isEmpty(currentRoute))

    if (!hasPublicRoutes() && !isEmpty(currentRoute) && isValid) {
      setIsNavigating(true);
      navigate(currentRoute);
    }
  }, [isValid]);


  return (
    <Routes>
      <Route path="/*" element={<ProtectedRoutes />} />

      {publicRoutes?.map((item, key) => (
        <Route path={item.path} element={item.element} key={`other-routes-${key}`} />
      ))}

      {isValid && isNavigating && renderProtectedRoutes()}
    </Routes>
  );
};
