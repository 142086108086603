import React from "react";
import UserLogin from "../components/UserLogin";
import { Navigate } from "react-router-dom";
import { hasRequiredAuthData } from "../Utils/helpers";

export default function Login() {
  const currentRoute = localStorage.getItem('currentRoute')
  const isValid = hasRequiredAuthData()

  if (isValid) {
    return <Navigate to={currentRoute} replace/>
  }

  return <UserLogin />;
}