// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/warning.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-alert-confirm-box .p-dialog-footer button {
  border-radius: 1.6rem;
}
.p-alert-confirm-box .p-button-danger {
  background-color: #ffffff !important;
  border: 0.13rem solid #003da5 !important;
  color: #003da5 !important;
}
.p-alert-confirm-box .p-button-success {
  background-color: #ffffff !important;
  border: 2px solid #003da5 !important;
  color: #003da5 !important;
}
.p-alert-confirm-box .p-button-danger:hover {
  background-color: #003da5 !important;
  border: 2px solid #003da5 !important;
  color: #ffffff !important;
}
.p-alert-confirm-box .p-button-success:hover {
  background-color: #003da5 !important;
  border: 2px solid #003da5 !important;
  color: #ffffff !important;
}
.p-alert-confirm-box .p-dialog-content {
  width: 100% !important;
  font-size: 18px;
  font-weight: 600;
  color: #d37302;
}
.p-alert-confirm-box .infoText::before {
  width: 24px;
  position: absolute;
  left: 18px;
  content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.p-alert-confirm-box .button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/ConfirmDialog/AlertDialogBox.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;AAAJ;AAGE;EACE,oCAAA;EACA,wCAAA;EACA,yBAAA;AADJ;AAIE;EACE,oCAAA;EACA,oCAAA;EACA,yBAAA;AAFJ;AAKE;EACE,oCAAA;EACA,oCAAA;EACA,yBAAA;AAHJ;AAME;EACE,oCAAA;EACA,oCAAA;EACA,yBAAA;AAJJ;AAOE;EACE,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AALJ;AAQE;EACE,WAAA;EACA,kBAAA;EACA,UAAA;EACA,gDAAA;AANJ;AASE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAPJ","sourcesContent":[".p-alert-confirm-box {\n  .p-dialog-footer button {\n    border-radius: 1.6rem;\n  }\n\n  .p-button-danger {\n    background-color: #ffffff !important;\n    border: 0.13rem solid #003da5 !important;\n    color: #003da5 !important;\n  }\n\n  .p-button-success {\n    background-color: #ffffff !important;\n    border: 2px solid #003da5 !important;\n    color: #003da5 !important;\n  }\n\n  .p-button-danger:hover {\n    background-color: #003da5 !important;\n    border: 2px solid #003da5 !important;\n    color: #ffffff !important;\n  }\n\n  .p-button-success:hover {\n    background-color: #003da5 !important;\n    border: 2px solid #003da5 !important;\n    color: #ffffff !important;\n  }\n\n  .p-dialog-content {\n    width: 100% !important;\n    font-size: 18px;\n    font-weight: 600;\n    color: #d37302;\n  }\n\n  .infoText::before {\n    width: 24px;\n    position: absolute;\n    left: 18px;\n    content: url(\"../../assets/images/warning.svg\");\n  }\n\n  .button-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
