import React, { useEffect, useState, useRef } from "react";
import "./index.scss";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { useProofScopeURL } from "../../ProofScope/ViewFiles";
import { useDispatch, useSelector } from "react-redux";
import { getEnvironmentFromURL, sanitizeFileName, replaceSpecialChars } from "../../../utils";
import { textAreaMaxLength } from "../../../constants/index";
import { DownloadAnnotation } from "../../ProofScope/ExportXFDFGeneration";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { changeDateFormat } from "../../../utils";
import { Form } from "react-bootstrap";
import { InputTextarea } from "primereact/inputtextarea";
import ApprovedIcon from "../../../assets/images/ApprovedIcon.svg";
import RejectedIcon from "../../../assets/images/RejectedIcon.svg";
import _, { isArray } from "lodash";
import { Dialog } from "primereact/dialog";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import { downloadAzureFileShare } from "../../../apis/downloadProofscopeAzureFs";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import FileUploadManager from "../../common/FileUploadManager/FileUploadManager";
import { CharacterLimit } from "../../common/CharacterLimit/CharacterLimit";
import FileListContainer from "../../common/FileUploadManager/FileListContainer";
import { getSupplierFileInfo, getCopyXmlSupplierFileInfo } from "../../../apis/getSupplierFileInfo";
import useAzureUploadFolderStructure from "../../../hooks/useAzureUploadFolderStructure";
import { Message } from "primereact/message";

const UploadDesignIntentProofscope = ({
  Design_Intent_Name,
  upload,
  approve,
  approvalStatus,
  file_name,
  setformattedValue,
  setFileName,
  fileName,
  setAzureFile,
  azureFile,
  setAzureFileUploadMsg,
  item,
  buName,
  taskFolder,
  TaskID,
  ProjectID,
  viewFileName,
  setViewFileName,
  comments,
  setComments,
  tableData,
  setTableData,
  setAllRowsEdited,
  DropDownValuesData,
  setFileDetails,
  setVersion,
  version,
  toShowReferenceDocuments = false,
  loading,
  azureFileUploadMsg,
  thumbnailErrorMsg,
}) => {
  const toast = useRef(null);
  let { page1, page2, pageType } = useParams();
  const navigate = useNavigate();
  setViewFileName(item?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]?.File_Name);
  const dispatch = useDispatch();
  const [totalSize, setTotalSize] = useState(0);
  const { viewProofScopeFile, loadProofscope, isError, message, fileContent, setFileContent } =
    useProofScopeURL();
  const [assignee, setAssignee] = useState("");
  const [userAssigned, setUserAssigned] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  const [roles, setRoles] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [fileExtMsg, setFileExtMsg] = useState("");
  const [defectTypes, setDefectTypes] = useState([]);
  const [visible, setVisible] = useState(false);
  const [apporovalId, setApprovalId] = useState("");
  const [apporovalTaskId, setApprovalTaskId] = useState("");
  const folderStructure = useAzureUploadFolderStructure(taskFolder, version, false);

  const [referenceDocumentsList, setReferenceDocumentsList] = useState({});
  const [rejectionReasonError, setRejectionReasonError] = useState(null);

  let columns = [
    { field: "content", header: "Markup" },
    { field: "version", header: "Version" },
  ];

  if (taskFolder === "CIC" || taskFolder === "CICMATRIX") {
    columns = [
      { field: "content", header: "Markup" },
      { field: "version", header: "Version" },
      // { field: "creationDate", header: "Date" },
      { field: "role", header: "Responsible Function/Role*" },
      { field: "reason", header: "Reason*" },
      { field: "defectType", header: "Defect Type*" },
      { field: "comment", header: "Comments" },
    ];
  }
  const DesignJobDetails = item?.DesignJobDetails?.[0];

  const folderStructureWithoutVersion = useAzureUploadFolderStructure(taskFolder, version, false);

  useEffect(() => {
    if (!loading) {
      async function fetchSupplierFileInfo() {
        let poaNumbers = [];

        let GABriefFiles = {};
        let OtherReferenceDocuments = {};
        let RDTFileDetailsList = {};

        if (item?.CICDetails) {
          poaNumbers = item?.CICDetails?.map((detail) => detail?.POANumber);
          if (
            item?.CICDetails?.[0]?.GABriefFiles?.length &&
            Object.keys(item?.CICDetails?.[0]?.GABriefFiles?.[0]).length
          ) {
            GABriefFiles = item?.CICDetails?.[0]?.GABriefFiles;
          }

          if (
            item?.CICDetails?.[0]?.OtherReferenceDocuments?.length &&
            Object.keys(item?.CICDetails?.[0]?.OtherReferenceDocuments?.[0]).length
          ) {
            OtherReferenceDocuments = item?.CICDetails?.[0]?.OtherReferenceDocuments;
          }

          item?.CICDetails?.[0]?.RDTFileDetailsList?.length &&
            Object.keys(item?.CICDetails?.[0]?.RDTFileDetailsList?.[0]).length &&
            item?.CICDetails?.[0]?.RDTFileDetailsList?.map((obj, index) => {
              RDTFileDetailsList[`${Object.keys(obj)[0]}${index}`] = obj[Object.keys(obj)[0]];
            });
        } else if (item?.CICMatrixDetails) {
          poaNumbers = item?.CICMatrixDetails?.map((detail) => detail?.POANumber);

          item?.GABriefFiles?.length &&
            Object.keys(item?.GABriefFiles?.[0]).length &&
            item?.GABriefFiles?.map((obj, index) => {
              GABriefFiles[`${Object.keys(obj)[0]}${index}`] = obj[Object.keys(obj)[0]];
            });

          item?.OtherReferenceDocuments?.length &&
            Object.keys(item?.OtherReferenceDocuments?.[0]).length &&
            item?.OtherReferenceDocuments?.map((obj, index) => {
              OtherReferenceDocuments[`${Object.keys(obj)[0]}${index}`] = obj[Object.keys(obj)[0]];
            });

          item?.RDTFileDetailsList?.length &&
            Object.keys(item?.RDTFileDetailsList?.[0]).length &&
            item?.RDTFileDetailsList?.map((obj, index) => {
              RDTFileDetailsList[`${Object.keys(obj)[0]}${index}`] = obj[Object.keys(obj)[0]];
            });
        }

        const _referenceDocumentsList = await getSupplierFileInfo(folderStructureWithoutVersion);
        let copyXmlList = {};
        if (item?.Company && poaNumbers && poaNumbers?.length) {
          copyXmlList = await getCopyXmlSupplierFileInfo(poaNumbers, item?.Company);
        }

        for (let POANumbers in copyXmlList) {
          copyXmlList[POANumbers].forEach((copyXML) => {
            copyXML.isCopyXMLFile = true;
          });
        }

        if (copyXmlList && Object.keys(copyXmlList)?.length) {
          if (item?.CICDetails) {
            let tempObj = {};
            isArray(GABriefFiles) &&
              GABriefFiles.length &&
              (tempObj["GABriefFiles"] = GABriefFiles);
            isArray(OtherReferenceDocuments) &&
              OtherReferenceDocuments.length &&
              (tempObj["OtherReferenceDocuments"] = OtherReferenceDocuments);
            setReferenceDocumentsList({
              ..._referenceDocumentsList,
              ...copyXmlList,
              ...tempObj,
              ...RDTFileDetailsList,
            });
          } else if (item?.CICMatrixDetails) {
            setReferenceDocumentsList({
              ..._referenceDocumentsList,
              ...copyXmlList,
              ...GABriefFiles,
              ...OtherReferenceDocuments,
              ...RDTFileDetailsList,
            });
          } else {
            setReferenceDocumentsList({ ..._referenceDocumentsList, ...copyXmlList });
          }
        } else {
          if (item?.CICDetails) {
            let tempObj = {};
            isArray(GABriefFiles) &&
              GABriefFiles.length &&
              (tempObj["GABriefFiles"] = GABriefFiles);
            isArray(OtherReferenceDocuments) &&
              OtherReferenceDocuments.length &&
              (tempObj["OtherReferenceDocuments"] = OtherReferenceDocuments);
            setReferenceDocumentsList({
              ..._referenceDocumentsList,
              ...tempObj,
              ...RDTFileDetailsList,
            });
          } else if (item?.CICMatrixDetails) {
            setReferenceDocumentsList({
              ..._referenceDocumentsList,
              ...GABriefFiles,
              ...OtherReferenceDocuments,
              ...RDTFileDetailsList,
            });
          } else {
            setReferenceDocumentsList({ ..._referenceDocumentsList });
          }
        }
      }

      fetchSupplierFileInfo();
    }
  }, [loading]);

  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);
  useEffect(() => {
    if (User && item) {
      if (userInformation?.userid === item?.Assignee) {
        setCheckReadWriteAccess(true);
      } else {
        setCheckReadWriteAccess(false);
      }
    }
  }, [User, item]);

  useEffect(() => {
    setRoles(DropDownValuesData?.ArtworkAgilityTasksPage?.Artwork_Role);
    setReasons(DropDownValuesData?.ArtworkAgilityTasksPage?.Proofscope_Rejection);
    setDefectTypes(DropDownValuesData?.ArtworkAgilityTasksPage?.Defect_Type);
  }, [DropDownValuesData]);

  useEffect(() => {
    if (loadProofscope && toast.current) {
      toast.current.show({
        severity: "success",
        summary: "Proofscope File viewer",
        detail: "In progress... Please wait!",
        life: 100000,
      });
    }
    // Split the URL into key-value pairs
    const urlParts = item?.ApprovalID?.split("&");
    setApprovalId(urlParts?.[0]);
    setApprovalTaskId(urlParts?.[1]);
    setUserAssigned(urlParts?.[2]);
  }, [loadProofscope, userAssigned, item?.ApprovalID]);

  useEffect(() => {
    if (azureFile !== undefined && azureFile?.name !== undefined) {
      setFileName(azureFile?.name);
      // if (taskFolder === "CIC") {
      //   setFileDetails(azureFile);
      // }
    } else {
      setFileName("");
    }
  }, [azureFile]);

  const handleDownload = async () => {
    // Provide the necessary parameters to the downloadFile function

    const fileName = item?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]?.File_Name; // Replace with the desired file name
    try {
      // Call the downloadFile function and wait for the result
      const result = await downloadAzureFileShare(fileName, buName, taskFolder);

      if (result) {
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "File Downloaded",
            detail: "Download completed successfully!",
            life: 3000,
          });
        }
      } else {
        // Show an error toast message
        if (toast.current) {
          toast.current.show({
            severity: "error",
            summary: "File Download Failed",
            detail: "Download failed. Please try again.",
            life: 3000,
          });
        }
      }
    } catch (error) {
      // Show an error toast message
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An error occurred during file download. Please try again.",
          life: 3000,
        });
      }
    }
  };

  const env = getEnvironmentFromURL();
  const sanitizedBuName = sanitizeFileName(buName);
  const sanitizedTaskFolder = sanitizeFileName(taskFolder);
  const Assignee = item?.Assignee;
  useEffect(() => {
    if ( (item.Task_ID === TaskID) &&
      (TaskID.includes("ARDT") ||
      TaskID.includes("ACIC") ||
      TaskID.includes("ACICMatrix"))
      // && (approvalStatus !== 'Approved')
    ) {
      setIsLoading(true);
      // Check if di_name exists
      const sanitizedFileUrl = sanitizeFileName(
        item?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]?.File_Name
      );

      // Generate the paths for PDF and XFDF
      const filename = item?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]?.File_Name?.split(".")
        .slice(0, -1)
        .join(".");
      const fileExtension =
        item?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]?.File_Name.split(".").pop();
      const sanitizeFile = replaceSpecialChars(filename);

      const pdfPath = `cloudflow://awm/${env}/${buName}/${taskFolder}/${ProjectID}/${DesignJobDetails.Design_Job_ID}/${item?.DesignJobDetails?.[0]?.FileMetaDataList[0].Version}/${sanitizeFile}.${fileExtension}`;
      const XFDFPath = `cloudflow://awm/${env}/${buName}/${taskFolder}/${ProjectID}/${DesignJobDetails.Design_Job_ID}/${item?.DesignJobDetails?.[0]?.FileMetaDataList[0].Version}/${sanitizeFile}.XFDF`;

      const xfdfVersion =
        item?.DesignJobDetails?.[0]?.XfdfFileList?.length &&
        item?.DesignJobDetails?.[0]?.XfdfFileList[0].Version !== "" &&
        item?.DesignJobDetails?.[0]?.XfdfFileList[0].Version !== "NaN"
          ? (parseInt(item?.DesignJobDetails?.[0]?.XfdfFileList[0].Version) + 1).toString()
          : "1";
      const XFDFAzureFolderStructure = `${folderStructure}/XFDF/${xfdfVersion}/`; //instead of version it will be xfdfVersion extracted from GET API response of Projectplan or TaskDetails
      const storeApproveFileDetailsFormData = {
        FileList: [
          {
            Filename: `${sanitizeFile}.XFDF`,
            Version: xfdfVersion,
            AWMTaskID: TaskID,
            Size: "",
            Filepath: "",
            Full_Name: `${userInformation.firstName} ${userInformation.lastName}`,
            Email: `${userInformation.userid}@pg.com`,
            JobID: DesignJobDetails.Design_Job_ID,
            JobName: DesignJobDetails.Design_Job_Name,
            TypeOfFile: "xfdf",
            ViewPFURL: "",
          },
        ],
      };

      // Async function to download and process XFDF
      const downloadAndProcessXFDF = async () => {
        try {
          const xfdfContent = await DownloadAnnotation(
            pdfPath,
            XFDFPath,
            `${sanitizeFile}.XFDF`,
            XFDFAzureFolderStructure,
            DesignJobDetails.Design_Job_Status,
            item,
            DesignJobDetails.XfdfFileList,
            storeApproveFileDetailsFormData,
            ProjectID,
            approvalStatus,
            toast,
            page1,
            page2,
            pageType,
            navigate
          );
          // Download XFDF content

          if (xfdfContent.status === 500) {
            // setRejectionReasonError(
            //   `Unable to fetch rejection reasons from proofscope: ${xfdfContent.contents}`
            // );
            setRejectionReasonError(
              `Proofscope integration issue, markups might be out of sync, approval can be submitted. For rejections, please try again later.`
            );
          }
          // Parse the XML response
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(xfdfContent, "text/xml");

          // Extract comments from the XML
          const commentNodes = xmlDoc.querySelectorAll("text");
          const squareNodes = xmlDoc.querySelectorAll("square");
          const rectangleNodes = xmlDoc.querySelectorAll("rectangle");
          const inkNodes = xmlDoc.querySelectorAll("ink");
          const extractedAnnotations = [];

          // Extract text annotations
          Array.from(commentNodes)?.forEach((node) => {
            node.querySelector("p").textContent &&
              extractedAnnotations.push({
                markupId: node.getAttribute("name"),
                type: "text",
                creationDate: node.getAttribute("creationdate"),
                content: node.querySelector("p").textContent,
                role: "", // Initialize with the first value from reasonRoles
                reason: "", // Initialize with the first value from reasons
                defectType: "", // Initialize with the first value from defectType
                comment: "",
                taskId: "",
                version: "",
                MarkupTaskID: node.getAttribute("subject").split(".")[0],
              });
          });

          // Extract square annotations
          Array.from(squareNodes)?.forEach((node) => {
            node.querySelector("p").textContent &&
              extractedAnnotations.push({
                markupId: node.getAttribute("name"),
                type: "square",
                creationDate: node.getAttribute("creationdate"),
                content: node.querySelector("p").textContent,
                role: "", // Initialize with the first value from reasonRoles
                reason: "", // Initialize with the first value from reasons
                defectType: "", // Initialize with the first value from defectType
                comment: "",
                taskId: "",
                version: "",
                MarkupTaskID: node.getAttribute("subject").split(".")[0],
                // Extract additional attributes specific to square annotations here
              });
          });

          // Extract rectangle annotations
          Array.from(rectangleNodes).forEach((node) => {
            node.querySelector("p").textContent &&
              extractedAnnotations.push({
                markupId: node.getAttribute("name"),
                type: "rectangle",
                creationDate: node.getAttribute("creationdate"),
                content: node.querySelector("p").textContent,
                role: "", // Initialize with the first value from reasonRoles
                reason: "", // Initialize with the first value from reasons
                defectType: "", // Initialize with the first value from defectType
                comment: "",
                taskId: "",
                version: "",
                MarkupTaskID: node.getAttribute("subject").split(".")[0],
                // Extract additional attributes specific to rectangle annotations here
              });
          });
          // Extract square annotations
          Array.from(inkNodes)?.forEach((node) => {
            node.querySelector("p").textContent &&
              extractedAnnotations.push({
                markupId: node.getAttribute("name"),
                type: "ink",
                creationDate: node.getAttribute("creationdate"),
                content: node.querySelector("p").textContent,
                role: "", // Initialize with the first value from reasonRoles
                reason: "", // Initialize with the first value from reasons
                defectType: "", // Initialize with the first value from defectType
                comment: "",
                taskId: "",
                version: "",
                MarkupTaskID: node.getAttribute("subject").split(".")[0],
                // Extract additional attributes specific to square annotations here
              });
          });
          // Set the annotations in the state
          if (
            item?.RejectionReasons?.length
            // &&              item?.ApprovalID?.includes(item?.Assignee)
          ) {
            const mergedMarkupss = mergePegaAndProofscopeMarkups(
              extractedAnnotations,
              item?.RejectionReasons
            );
            setComments(sortMarkupsInDescendingOrder(mergedMarkupss));
          } else {
            setComments(sortMarkupsInDescendingOrder(extractedAnnotations));
          }

          // Set isLoading to false when done
          setIsLoading(false);
        } catch (error) {
          setComments([]);

          console.error("Error:", error.message);

          // Set isLoading to false on error
          setIsLoading(false);
        }
      };

      // Call the download and processing function
      setComments([]);
      downloadAndProcessXFDF();
    }
  }, [item]);

  const sortMarkupsInDescendingOrder = (data) => {
    return data?.sort((a, b) => {
      // Extract the version and last two decimal numbers from markupId
      const getVersion = obj => obj?.version;
      const getDecimalNumbers = obj => {
          const parts = obj?.markupId?.split('.');
          return parts?.slice(-2)?.map(Number);
      };

      const [versionA, decimalA] = [getVersion(a), getDecimalNumbers(a)];
      const [versionB, decimalB] = [getVersion(b), getDecimalNumbers(b)];

      // Sort by version in descending order
      if (versionA !== versionB) {
          return versionB - versionA;
      }

      // If versions are equal, sort by last two decimal numbers in descending order
      if (decimalA[0] !== decimalB[0]) {
          return decimalB[0] - decimalA[0];
      }

      return decimalB[1] - decimalA[1];
    });
  }

  const mergePegaAndProofscopeMarkups = (proofscopeMarkups, pegaMarkups) => {
    let tempPegaMarkups = [];
    pegaMarkups.map((pegaObj) => {
      tempPegaMarkups.push({
        markupId: pegaObj.MarkupID,
        type: "text",
        creationDate: "",
        content: pegaObj.Markup,
        role: pegaObj.ResponsibleFunctionRole,
        reason: pegaObj.Reason,
        defectType: pegaObj.DefectType,
        comment: pegaObj.Comment,
        taskId: pegaObj.AWM_Task_ID,
        version: pegaObj.VersionNumber,
        MarkupTaskID: "",
      });
    });

    let newProofscopeMarkups = [];

    proofscopeMarkups.map((obj1) => {
      const filteredData = tempPegaMarkups.filter((obj2) => {
        return obj1.markupId === obj2.markupId;
      });
      if (filteredData.length === 0) {
        newProofscopeMarkups.push(obj1);
      }
    });
    let mergedMarkups = [...newProofscopeMarkups, ...tempPegaMarkups];
    mergedMarkups.sort(customSort);
    return mergedMarkups;
  };

  function customSort(a, b) {
    if (a.version === "" && b.version !== "") {
      return -1; // Place object with empty version on top
    } else if (a.version !== "" && b.version === "") {
      return 1;
    } else {
      return b.version - a.version; // Sort by descending version
    }
  }

  const handleViewProofScopeClick = async () => {
    // event.preventDefault();
    try {
      if (Assignee === "") {
        setAssignee("Not a valid user!");
      } else {
        setAssignee(""); // Sanitize various variables including fileUrl

        if (item?.PFFileURL === "" || userAssigned !== Assignee) {
          await initiateViewProofscope();
        }

        if (userInformation?.userid === Assignee && apporovalTaskId === item?.Task_ID) {
          if (approvalStatus === "") {
            await initiateViewProofscope();
          } else {
            console.log("User ID matches Assignee. Setting file content...");
            setFileContent(item?.PFFileURL);
            setVisible(true);
          }
        } else {
          console.log("User ID does not match Assignee. Setting readonly file content...");
          setFileContent(item?.ViewPFFileURL);
          setVisible(true);
        }
      }
    } catch (error) {
      console.error("Error in handleViewProofScopeClick:", error);
    }
  };

  const initiateViewProofscope = async () => {
    //Chekcing prod env
    // const filePath = `cloudflow://awm/${env}/${sanitizedBuName}/${sanitizedTaskFolder}/${sanitizedFileUrl}`;
    // let singleFilePath = "";
    let filePath = [];
    if (item?.DesignJobDetails?.[0]?.FileMetaDataList?.length) {
      item?.DesignJobDetails?.[0]?.FileMetaDataList.map((obj) => {
        let path = `cloudflow://awm/${env}/${sanitizedBuName}/${sanitizedTaskFolder}/${ProjectID}/${DesignJobDetails.Design_Job_ID}/${obj.Version}/${obj.File_Name}`;
        // let path= `cloudflow://awm/${env}/${sanitizedBuName}/${sanitizedTaskFolder}/${ProjectID}/${DesignJobDetails.Design_Job_ID}/${obj.Version}/${obj.File_Name.split('.pdf')[0]}_${obj.Version}.pdf`;
        // let path= `cloudflow://awm/${env}/${sanitizedBuName}/${sanitizedTaskFolder}/${obj.File_Name}`;
        filePath.push(path);
      });
    }

    await viewProofScopeFile(
      ProjectID,
      item?.Task_Status,
      item?.Task_Key,
      TaskID,
      filePath,
      // item?.DesignJobDetails?.[0]?.FileMetaDataList?.length > 1 ? multipleFilePath : singleFilePath,
      // `cloudflow://awm/${env}/${sanitizedBuName}/${sanitizedTaskFolder}/${sanitizedFileUrl}`,
      Assignee,
      item?.FlowName,
      item?.FlowAction,
      // item?.DesignJobDetails?.[0]?.FileMetaDataList?.length
      filePath?.length
    );

    console.log("viewProofScopeFile called. Setting visible.");
    setVisible(true);
  };
  const closeDialog = async () => {
    setVisible(false);
    await dispatch(getTaskDetails(TaskID, ProjectID));
  };

  let di_name;
  // if (!approve) {
  if (approvalStatus !== "Approved") {
    di_name =
      // version !== "V0" &&
      // item?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]?.Timestamp !== ""
      // ? `${item?.Task_Name}_${version}`
      `${item?.Task_Name}`;
  }
  const allowedExtensions = [".pdf", ".zip", ".ai",".postscript"];

  const onFileUpload = (e, fileDetails) => {
    let _totalSize = totalSize;
    let files = e.files;
    if (!files || !files[0]) {
      // Handle the case when no file is selected
      return;
    }

    const originalFile = files[0];
    const extension = originalFile?.name?.split(".").pop();
    const newFileName = `${originalFile?.name?.split(".").shift()}_${
      fileDetails.Version
    }.${extension}`;

    const renamedFile = new File([originalFile], newFileName, { type: originalFile?.type });

    setAzureFileUploadMsg(false);
    setFileExtMsg("");

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
    renamedFile && setAzureFile(renamedFile);
    renamedFile?.name && setFileName(renamedFile.name);

    setVersion(fileDetails.Version);
    setFileDetails(fileDetails);
  };

  const onFileClear = () => {
    setAzureFile("");
    setTotalSize(0);
    setFileDetails(null);
    setformattedValue("");
    setVersion(0);
    setFileName("");
  };

  const DesignHeader = (di_name) => {
    return (
      <>
        <div
          style={{
            marginLeft: 20,
            padding: 5,
            width: "80%",
            wordWrap: "break-word",
          }}
          className="font-color"
        >
          {/* {approve ? `${item?.Task_Name}_${version}_${date}` : di_name} */}
          {approvalStatus === "Approved"
            ? // ? `${item?.Task_Name}_${version}`
              `${item?.Task_Name}`
            : di_name}
        </div>
        {(TaskID.includes("URDT") ||
          TaskID.includes("UCIC") ||
          (TaskID.includes("UCICMatrix") && item?.Task_Status === "Cancelled") ||
          ((TaskID.includes("ARDT") || TaskID.includes("ACIC") || TaskID.includes("ACICMatrix")) &&
            (approvalStatus !== "" || item?.Task_Status === "Cancelled"))) && (
          <div>
            {(approvalStatus === "Approved" ||
              approvalStatus === "Rejected" ||
              item?.Task_Status === "Cancelled") && (
              <img
                src={approvalStatus === "Approved" ? ApprovedIcon : RejectedIcon}
                alt="icon"
                height="18px"
                width="18px"
              ></img>
            )}
            <span
              className={`approval-status-text ${
                item?.Task_Status === "Cancelled" ? "Rejected" : approvalStatus
              }`}
            >
              {item?.Task_Status === "Complete-Rejected" ||
              item?.Task_Status === "Complete-Approved" ||
              item?.Task_Status === "Cancelled"
                ? item?.Task_Status
                : approvalStatus}
            </span>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (TaskID.includes("ARDT") || TaskID.includes("ACIC") || TaskID.includes("ACICMatrix")) {
      const clonedComments = _.cloneDeep(comments);
      setTableData(clonedComments);
    }
  }, [comments]);

  useEffect(() => {
    if (TaskID.includes("ACIC") || TaskID.includes("ACICMatrix")) {
      const taskIdSpecificTableData = tableData?.filter((obj) => {
        return obj.MarkupTaskID === TaskID;
      });
      const editCompletedTableData = taskIdSpecificTableData?.filter((obj) => {
        return obj.role !== "" && obj.defectType !== "" && obj.reason !== "";
      });
      if (editCompletedTableData?.length === taskIdSpecificTableData?.length) {
        setAllRowsEdited(true);
      } else {
        setAllRowsEdited(false);
      }
    }
  }, [tableData]);

  const renderMappingBody = (options, rowData, index) => {
    let field = rowData.field;

    return (
      <>
        {field === "content" && (
          <>
            <span>{`${rowData.rowIndex + 1}. ${options[field]}`}</span>
          </>
        )}
        {field === "version" && (
          <>
            <span>
              {" "}
              {options[field] !== null && options[field] !== ""
                ? options[field]
                : item?.DesignJobDetails?.[0]?.FileMetaDataList?.length}
            </span>
          </>
        )}
        {field === "creationDate" && changeDateFormat(options[field])}
        {field === "role" && options["version"] === "" && (
          <Form.Select
            value={options[field]}
            onChange={(e) => {
              options[field] = e.target.value;
              setTableData(_.cloneDeep(tableData));
            }}
            disabled={
              (options["MarkupTaskID"] !== "" && options["MarkupTaskID"] !== TaskID) ||
              (options["taskId"] !== "" && options["taskId"] !== TaskID) ||
              !checkReadWriteAccess
            }
          >
            <option hidden value="">
              Select Role
            </option>
            {roles?.map((val, index) => (
              <option key={index} value={val.Role_Name}>
                {val.Role_Name}
              </option>
            ))}
          </Form.Select>
        )}
        {field === "role" && options["version"] !== "" && options[field]}
        {field === "reason" && options["version"] === "" && (
          <Form.Select
            value={options[field]}
            onChange={(e) => {
              options[field] = e.target.value;
              setTableData(_.cloneDeep(tableData));
            }}
            disabled={
              (options["MarkupTaskID"] !== "" && options["MarkupTaskID"] !== TaskID) ||
              (options["taskId"] !== "" && options["taskId"] !== TaskID) ||
              !checkReadWriteAccess
            }
          >
            <option hidden value="">
              Select Reason
            </option>
            {reasons?.map((val, index) => (
              <option key={index} value={val.RejectionReason}>
                {val.RejectionReason}
              </option>
            ))}
          </Form.Select>
        )}
        {field === "reason" && options["version"] !== "" && options[field]}

        {field === "defectType" && options["version"] === "" && (
          <Form.Select
            value={options[field]}
            onChange={(e) => {
              options[field] = e.target.value;
              setTableData(_.cloneDeep(tableData));
            }}
            disabled={
              (options["MarkupTaskID"] !== "" && options["MarkupTaskID"] !== TaskID) ||
              (options["taskId"] !== "" && options["taskId"] !== TaskID) ||
              !checkReadWriteAccess
            }
          >
            <option hidden value="">
              Select Defect Type
            </option>
            {defectTypes?.map((val, index) => (
              <option key={index} value={val.DefectType}>
                {val.DefectType}
              </option>
            ))}
          </Form.Select>
        )}
        {field === "defectType" && options["version"] !== "" && options[field]}
        {field === "comment" &&
          options["version"] === "" &&
          item?.Task_Status !== "Complete-Rejected" &&
          item?.Task_Status !== "Complete-Approved" && (
            <>
              <InputTextarea
                value={options[field]}
                onChange={(e) => {
                  options[field] = e.target.value;
                  setTableData(_.cloneDeep(tableData));
                }}
                placeholder="Enter Comments"
                className="enter-comments"
                maxLength={textAreaMaxLength}
                disabled={
                  (options["MarkupTaskID"] !== "" && options["MarkupTaskID"] !== TaskID) ||
                  (options["taskId"] !== "" && options["taskId"] !== TaskID) ||
                  !checkReadWriteAccess
                }
              ></InputTextarea>
              <CharacterLimit field={options[field]} maxLength={textAreaMaxLength} />
            </>
          )}
        {field === "comment" && options["version"] !== "" && options[field]}

        {field !== "content" &&
          field !== "creationDate" &&
          field !== "role" &&
          field !== "reason" &&
          field !== "defectType" &&
          field !== "version" &&
          field !== "comment" &&
          options[field]}
      </>
    );
  };

  const processFileName = (file) =>
    file?.includes("/") ? file?.split("/").slice(1).join("/") : file;

  const dynamicColumns = () => {
    return [
      // <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} />,
      columns.map((col, i) => (
        <Column key={i} field={col.field} header={col.header} body={renderMappingBody} />
      )),
    ];
  };

  return (
    <div>
      {!visible && <Toast ref={toast} />}
      <div className="design-intent-header">{DesignHeader(di_name)}</div>
      {TaskID.includes("ACIC") && (
        <div>
          {item?.CICDetails ? (
            <div className="upload-cic-pmp-wrapper">
              [{item?.CICDetails.length}]{" "}
              {item?.CICDetails.length > 1 ? "PMP's in scope" : "PMP in scope"}:{" "}
              {item?.CICDetails.map((item, index) => (
                <span key={item.PMPNumber}>
                  {index > 0 && ", "}
                  {item.PMPNumber}
                </span>
              ))}
            </div>
          ) : item?.CICMatrixDetails ? (
            <div className="upload-cic-pmp-wrapper">
              [{item?.CICMatrixDetails.length}]{" "}
              {item?.CICMatrixDetails.length > 1 ? "PMP's in scope" : "PMP in scope"}:{" "}
              {item?.CICMatrixDetails.map((item, index) => (
                <span key={item.PMPNumber}>
                  {index > 0 && ", "}
                  {item.PMPNumber}
                </span>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <div className="approve-design-intent">
        {upload && (
          <>
            <div
              style={{
                marginLeft: 20,
                padding: 5,
              }}
            >
              <FileUploadManager
                taskdata={
                  item?.DesignJobDetails?.[0]?.FileMetaDataList !== undefined
                    ? item?.DesignJobDetails?.[0]?.FileMetaDataList
                    : ""
                }
                onFileUpload={onFileUpload}
                onFileClear={onFileClear}
                subFolder={taskFolder}
                accept={allowedExtensions}
                taskStatus={item?.Task_Status}
                checkReadWriteAccess={checkReadWriteAccess}
                // maxSize={2000000000}
              />
            </div>
          </>
        )}
        <div className="ps-error-msg">
          {azureFileUploadMsg && <Message severity="error" text={thumbnailErrorMsg} />}
        </div>
        {toShowReferenceDocuments && (
          <div className="supplier-file-list-container">
            <div className="supplier-file-list-heading">Reference Documents</div>
            <FileListContainer
              supplierFileList={referenceDocumentsList}
              subFolder={taskFolder}
              companyName={item?.Company}
            />
          </div>
        )}
        {approve && (
          <div
            style={{
              marginLeft: 20,
              padding: 5,
            }}
          >
            <div className="card flex justify-content-center">
              <Dialog
                header={
                  isError ? (
                    <span className="text-danger">Error</span>
                  ) : (
                    replaceSpecialChars(
                      item?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]?.File_Name
                    )
                  )
                }
                visible={visible}
                maximizable
                maximized
                style={{ width: loadProofscope ? "70vw" : "50vw" }}
                onHide={closeDialog}
              >
                {isError ? (
                  <>{message}</>
                ) : loadProofscope && item?.PFFileURL === "" ? (
                  <h4>Please wait!!! Proofscope file loading....</h4>
                ) : (
                  <iframe
                    src={fileContent}
                    title="File Content"
                    style={{ width: "100%", height: "500px" }}
                    onLoad={() => {
                      // Content has loaded
                      setVisible(true);
                    }}
                  />
                )}
              </Dialog>
            </div>
            {viewFileName === "" ? (
              fileName === "" ? (
                `No files uploaded yet please upload file!`
              ) : (
                ``
              )
            ) : (
              <div>
                <a
                  className="flex flex-column text-left"
                  disabled={loadProofscope}
                  onClick={(event) => {
                    if (loadProofscope) {
                      event.preventDefault();
                    } else {
                      handleViewProofScopeClick();
                    }
                  }}
                >
                  {/* Ask are we going to show image */}
                  {item?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]?.File_Path && (
                    <img
                      src={item?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]?.File_Path}
                      alt="Image"
                      width="80"
                      height="60"
                    />
                  )}
                  <div>
                    <br />
                    {fileName && viewFileName
                      ? replaceSpecialChars(
                          processFileName(fileName) || processFileName(viewFileName)
                        )
                      : replaceSpecialChars(fileName || viewFileName)}
                    <br />
                    {assignee && <p className="error-text">{assignee}</p>}
                  </div>
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      {(TaskID.includes("ARDT") || TaskID.includes("ACIC") || TaskID.includes("ACICMATRIX")) &&
        approvalStatus !== "Approved" && (
          <>
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <>
                <div className="ps-error-msg">
                  {rejectionReasonError && <Message severity="warn" text={rejectionReasonError} />}
                </div>
                {comments.length > 0 && (
                  <div>
                    <div className="design-intent-header font-color">
                      <div className="rejection-reason-header">Rejection Reasons</div>
                    </div>
                    <div className="rejection-table">
                      <div>
                        <DataTable
                          stripedRows
                          size={"small"}
                          selectionMode="checkbox"
                          scrollable
                          responsiveLayout="scroll"
                          className="mt-3"
                          tableStyle={{
                            width: "max-content",
                            minWidth: "100%",
                          }}
                          value={tableData}
                          emptyMessage="Please add annotations in case of approval rejections"
                        >
                          {dynamicColumns()}
                        </DataTable>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
    </div>
  );
};

export default UploadDesignIntentProofscope;
