import Api from ".";
import { store } from "../store/store";
import { DEVURL } from "./envUrl";
import * as types from "../store/types/types";

export const addDsbpToProject = async (projectId, InitiativeID, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/addDsbptoproject/${projectId}/${InitiativeID}`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
  });
  console.log("Hello hello ----->", addResponse);
  return addResponse;
};

//Here is code repetation but lets seperate this two API's

export const deleteDsbpFromProject = async (projectId, InitiativeID, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/deleteDsbp/${projectId}/${InitiativeID}`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
  });
  console.log("Hello hello ----->", addResponse);
  return addResponse;
};

export const getDsbpPMPDetails = async (projectId, payLoad, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/getDSBPPMPDetails/${projectId}`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: payLoad
  });
  let response = addResponse?.data?.DSBPDetails?.DSBP_InitiativeIDPage;
  const ownersList = addResponse?.data?.OwnerList;
  const specialProjectFlag = addResponse?.data?.SpecialProject;
  const accessType = addResponse?.data?.AccessType;
  // const accessType = "Read";
  const projectState = addResponse?.data?.ProjectState;
  const status=addResponse?.data?.Status;
  let filteredResp = [];
  // If initiative id is mapped to project but don't have an data against with initiative
  //id in brain then pega team is sending only initiative id
  // Below code we need to remove once pega team fix this issue
  if (response) {
    filteredResp = response.filter((ele) => ele.DSBP_InitiativeID && ele.DSBP_PMP_PIMaterialIDPage);
  }
  let mappedIds = [];
  if (response) {
    mappedIds = response.map((item) => item.DSBP_InitiativeID);
  }
  const projectData = {
    Project_Name: addResponse?.data?.ProjectName,
    BU: addResponse?.data?.BU,
    Region: addResponse?.data?.Region,
    Assignee: addResponse?.data?.Assignee,
  };
  return {
    resp: filteredResp,
    mappedInitiativeIDs: mappedIds,
    projectData,
    ownersList,
    specialProjectFlag,
    accessType,
    projectState,
    status
  };
};

export const getArtworkAlignmentTabDetails = async (
  InitiativeID,
  PIMaterialID,
  PIMaterialNumber,
  projectId,
  payLoad,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/pmpSnapshotData/${InitiativeID}/${PIMaterialID}/${projectId}`;
  // let apiURL = `https://run.mocky.io/v3/f1edf765-bb88-45ca-a8a3-e262c6c3f676/${InitiativeID}/${PIMaterialID}/${projectId}`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: payLoad
  });
  const accessType = addResponse?.data?.AccessType;
  // const accessType = "Read";
  return { 
    response: addResponse?.data?.PMPData,
    accessType,
    status:addResponse?.data?.PMPData?.Status
  };
};

export const onSubmitDsbpAction = async (formData, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/updatePMP`;
  // let apiURL = "https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/V1/UpdatePMP";
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  return addResponse;
};

export const onSubmitCreatePOAA = async (formData, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/createPOA`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  console.log("Hello hello ----->", addResponse);
  return addResponse?.data?.PMPList;
};

export const getDependencyMappingDetails = async (projectId,userAccessPayLoad, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/getDependencyMappingDetails/${projectId}`;
  // let apiURL = `https://pegasit.pg.com/prweb/api/ArtworkAgilityFile/v1/GetDependencyMappingDetails/${projectId}`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data:userAccessPayLoad
  });
  let response = addResponse?.data?.DSBPDetails;

  const dependencyTableData = response?.DSBP_InitiativeIDPage?.[0]?.DSBP_PMP_PIMaterialIDPage;

  // const isRDTData = response?.AWM_RDT_Page;
  const isRDTData = response?.Regional_Design_Template;
  const isIQData = response?.Ink_Qualification;
  const isCDPTData = response?.Color_Development_and_Print_Trial;
  const isGABrifData = response?.DSBP_GABrief_Page;
  const AccessType=addResponse?.data?.AccessType
  const specialProjectFlag = addResponse?.data?.SpecialProject;
  const projectState = addResponse?.data?.ProjectState;
  const projectData = {
    Project_Name: addResponse?.data?.ProjectName,
    BU: addResponse?.data?.BU,
    Region: addResponse?.data?.Region,
    Assignee: addResponse?.data?.Assignee,
    Artwork_Brand: addResponse?.data?.Artwork_Brand,
    Artwork_Category: addResponse?.data?.Artwork_Category,
    Artwork_SMO: addResponse?.data?.Artwork_SMO,
    CICs: addResponse?.data?.CICs,
  };
  store.dispatch({
    type: types.UPDATE_BU_REGION,
    payload: { bu: projectData.BU, region: projectData.Region },
  });
  return {
    dependencyTableData,
    isRDTData,
    isIQData,
    isCDPTData,
    isGABrifData,
    projectData,
    specialProjectFlag,
    AccessType,
    projectState
  };
};

export const getDependencyMappingTabDetails = async (
  InitiativeID,
  PIMaterialID,
  PIMaterialNumber,
  projectId,
  userAccessPayLoad,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/pmpSnapshotData/${InitiativeID}/${PIMaterialID}/${projectId}`;
  // let apiURL = `https://run.mocky.io/v3/f1edf765-bb88-45ca-a8a3-e262c6c3f676/${InitiativeID}/${PIMaterialID}/${projectId}`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data:userAccessPayLoad
  });
  return { response: addResponse?.data?.PMPData,AccessType: addResponse?.data?.AccessType};
};

export const onSubmitDependencyMappingAction = async (formData, projectId, headers = {}) => {
  const api = new Api();
  // let projectId = "A-2002";
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/storeDependencyMappingDetails/${projectId}`;
  // let apiURL = `https://pegasit.pg.com/prweb/api/ArtworkAgilityFile/v1/StoreDependencyMappingDetails/${projectId}`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  console.log("Add response ----->", addResponse);
  return addResponse;
};

export const handleConfirmFullScopeIn = async (projectId, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/confirmFullScopeIn/${projectId}`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
  });
  console.log("Hello hello ----->", addResponse);
  return addResponse;
};

export const createNewGaBriefTask = async (formData, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/createGABriefTask`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  console.log("Add response ----->", addResponse);
  return addResponse;
};

export const handleCICMatrix = async (formData, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/requestCICMatrix`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  console.log("Add response ----->", addResponse);
  return addResponse;
};

export const handleCICandRequestCICMatrix = async (formData, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/requestCIC`;
  // let apiURL = "https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v1/RequestCIC";
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  console.log("Add response ----->", addResponse);
  return addResponse;
};

export const requestCICAndRequestCicMatrix = async (formData, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });

  console.log("formdata", formData);

  let apiURL = `${DEVURL}/requestCICandRequestCICMatrix`;
  //  let apiURL = "https://pegasit.pg.com/prweb/api/ArtworkAgilityFile/v1/RequestCICandRequestCICMatrix";
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });

  console.log("Add response ----->", addResponse);
  return addResponse;
};
