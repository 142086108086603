// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-width {
  width: 100%;
}

.none_rdt_iq_cd {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/common/CustomInput/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AACA;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;AAEF","sourcesContent":[".wrapper-width {\n  width: 100%;\n}\n.none_rdt_iq_cd {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
