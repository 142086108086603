import * as types from "../types/types";

const initialState = {
  acpBookingData: [],
  acpDashboardProjectsRequiringPM: [],
  acpBookingCapacityValuesData: [],
  RTATasksUpdateResponse: [],
  RTATasksUpdateError: "null",
  rootBreadCrumb: "Capacity Management"
};

const acpBooking = (state = initialState, action) => {
  switch (action.type) {
    case types.ACP_BOOKING_DATA:
      return {
        ...state,
        acpBookingData: action.payload,
        RTATasksUpdateResponse: [],
        RTATasksUpdateError: "null",
      };
    case types.ACP_DASHBOARD_PROJECTS_REQUIRING_PM:
      return {
        ...state,
        acpDashboardProjectsRequiringPM: action.payload,
        RTATasksUpdateResponse: [],
        RTATasksUpdateError: "null",
      };
    case types.ACP_BOOKING_CAPACITY_VALUES_DATA:
      return {
        ...state,
        acpBookingCapacityValuesData: action.payload,
        RTATasksUpdateError: "null",
      };
    case types.UPDATE_RTA_TASKS_RESPONSE:
      return {
        ...state,
        RTATasksUpdateResponse: action.payload,
        RTATasksUpdateError: getErrorsfromResponse(action.payload),
      };

    case types.EMPTY_ERROR_DATA:
      return {
        ...state,
        RTATasksUpdateResponse: [],
        RTATasksUpdateError: "null",
      };

    default:
      return state;
  }
};
export default acpBooking;

const getErrorsfromResponse = (response) => {
  let errorMsgs = [];
  let RTATasksUpdateResponse = [];
  if(Object.keys(response).length && (response?.RTATasksPage || response?.ErrorMessage)){
    RTATasksUpdateResponse = response?.RTATasksPage || [];
    response?.ErrorMessage && errorMsgs.push(response?.ErrorMessage)
  }
  if (RTATasksUpdateResponse && RTATasksUpdateResponse.length) {
    RTATasksUpdateResponse.forEach((resObj) => {
      if (resObj?.RTATaskUpdateStatus?.includes("Failed")) {
        errorMsgs.push(resObj.TaskName + " : " + resObj.RTATaskUpdateMessage);
      }
    });
  }

  return errorMsgs;
};
