import React, { useEffect, useState, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import moment from 'moment';
import PageLayout from '../../PageLayout';
import DesignHeader from '../DesignJobs/DesignHeader';
import FooterButtons from '../DesignJobs/FooterButtons';
import AddNewDesign from '../DesignJobs/TaskHeader';
import ApproveDesignIntentContent from '../DesignJobs/ApproveDesignIntentContent';
import { getTaskDetails } from '../../../store/actions/taskDetailAction';
import { postSaveDesignIntent } from '../../../apis/uploadSaveAsDraft';
import { submitUploadApproveDesignIntent } from '../../../apis/uploadSubmitAPIs';
import { uploadtoAzureStrContainer } from '../../../apis/uploadAzuerContainer';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { redirectToProjectPlanOrTaskPage } from '../../../utils';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import useAzureUploadFolderStructure from '../../../hooks/useAzureUploadFolderStructure';
import { totalFileSizeQuotient } from '../../../constants/FileUploadConstant';

const breadcrumb = [
  { label: 'My Tasks', url: '/tasks' },
  { label: 'Upload Approved Design Intent' },
];

const UADI = () => {
  const toast = useRef(null);
  const [data, setData] = useState(null);
  const [designIntent, setDesignIntent] = useState(null);
  const [formattedValue, setformattedValue] = useState(0);
  const [fileName, setFileName] = useState('');
  const [azureFile, setAzureFile] = useState('');
  const [version, setVersion] = useState(0);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [loader, setLoader] = useState(false);
  const [azureFileUploadMsg, setAzureFileUploadMsg] = useState(false);
  let { TaskID, page1, page2, pageType, ProjectID } = useParams();
  const { TaskDetailsData, loading } = useSelector((state) => state.TaskDetailsReducer);
  const headerName = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.TaskPlaceholderName}`;
  const shortName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Type;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Key}`;
  const roleName = 'DI_';
  const checkReadWriteAccess = true;
  const BU = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU;
  const projectName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;
  const folderStructure = useAzureUploadFolderStructure('DI', version);

  useEffect(() => {
    dispatch(getTaskDetails(TaskID, ProjectID));
    setAzureFileUploadMsg(false);
  }, [dispatch, TaskID, ProjectID]);

  useEffect(() => {
    if (TaskDetailsData) {
      setDesignIntent(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || []);
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);
      const data =
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0] ||
        [];
      if (data) {
        data.Version !== '' && setVersion(data.Version);
        data.Timestamp !== '' &&
          setDate(moment(data.Timestamp, 'YYYYMMDD[T]HHmmss.SSS [GMT]').format('DD-MMMM-YYYY'));
      }
    }
  }, [TaskDetailsData]);

  const handleCancel = () => {
    redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
  };

  const onSaveAsDraft = async () => {
    setLoader(true);
    const fileSize = Math.round(formattedValue / totalFileSizeQuotient);
    const formData = {
      AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
      AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      Size: fileSize === 0 ? '1' : fileSize,
      Version: version,
      Filename:
        fileName !== ''
          ? fileName
          : TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]
            ?.File_Name,
      Full_Name: fullName,
      Email: email,
    };
    let azureResponse = await uploadtoAzureStrContainer(azureFile, folderStructure);
    // await dispatch(uploadFileToAzureShare(azureFile));
    if (azureResponse) {
      await postSaveDesignIntent(formData);
      dispatch(getTaskDetails(TaskID, ProjectID));
      if (toast.current) {
        toast.current.show({
          severity: 'success',
          summary: 'File Uploaded',
          detail: 'Successfully!',
          life: 3000,
        });
      }
      setLoader(false);
      setAzureFileUploadMsg(false);
    } else {
      if (
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === 'In-Progress' &&
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]
          ?.File_Name !== ''
      ) {
        await postSaveDesignIntent(formData);
        dispatch(getTaskDetails(TaskID, ProjectID));
        if (toast.current) {
          toast.current.show({
            severity: 'success',
            summary: 'File Uploaded',
            detail: 'Successfully!',
            life: 3000,
          });
        }
        setLoader(false);
        setAzureFileUploadMsg(false);
      } else {
        setAzureFileUploadMsg(true);
        setLoader(false);
      }
    }
  };

  const onSubmit = async () => {
    setLoader(true);
    const fileSize = Math.round(formattedValue / totalFileSizeQuotient);
    const headers = {
      key: 'If-Match',
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };
    const formData = {
      caseTypeID: 'PG-AAS-Work-UploadApprovedDesignIntent',
      content: {
        AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
        Size: fileSize === 0 ? '1' : fileSize,
        Version: version,
        Filename:
          fileName !== ''
            ? fileName
            : TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]
              ?.FileMetaDataList?.[0]?.File_Name,
        Full_Name: fullName,
        Email: email,
        TypeOfFile: fileName ? fileName?.split(".")?.pop() : TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]
        ?.FileMetaDataList?.[0]?.File_Name?.split(".")?.pop(),
        JobID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
        JobName: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_Name,
      },
    };

    let azureResponse = await uploadtoAzureStrContainer(azureFile, folderStructure);
    if (azureResponse) {
      await submitUploadApproveDesignIntent(formData, id, headers);
      if (toast.current) {
        toast.current.show({
          severity: 'success',
          summary: 'File Uploaded',
          detail: 'Successfully!',
          life: 3000,
        });
      }
      setLoader(false);
      setAzureFileUploadMsg(false);
      redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
    } else {
      if (
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === 'In-Progress' &&
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]
          ?.File_Name !== ''
      ) {
        await submitUploadApproveDesignIntent(formData, id, headers);
        if (toast.current) {
          toast.current.show({
            severity: 'success',
            summary: 'File Uploaded',
            detail: 'Successfully!',
            life: 3000,
          });
        }
        setLoader(false);
        setAzureFileUploadMsg(false);
        redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
      } else {
        setAzureFileUploadMsg(true);
        setLoader(false);
      }
    }
  };
  return (
    <PageLayout>
      <Toast ref={toast} />
      <DesignHeader
        breadcrumb={breadcrumb}
        headerName={headerName}
        disabled={true}
        label={shortName}
        checkReadWriteAccess={checkReadWriteAccess}
        actionButtonsFlag={true}
        azureFileUploadMsg={azureFileUploadMsg}
        {...data}
      />
      <div className="task-details">
        {
          <AddNewDesign
            {...data}
            checkReadWriteAccess={checkReadWriteAccess}
            TaskDetailsData={TaskDetailsData}
          />
        }
        <LoadingOverlay active={loading || loader || designIntent === null} spinner text="">
          {designIntent && (
            <ApproveDesignIntentContent
              {...designIntent}
              designIntent={designIntent}
              upload={true}
              setformattedValue={setformattedValue}
              setAzureFile={setAzureFile}
              setFileName={setFileName}
              fileName={fileName}
              item={data}
              roleName={roleName}
              ArtworkAgilityPage={TaskDetailsData?.ArtworkAgilityPage}
              version={version}
              setVersion={setVersion}
              setFullName={setFullName}
              setEmail={setEmail}
              date={date}
              checkReadWriteAccess={checkReadWriteAccess}
              subFolder="DI"
              BU={BU}
              projectName={projectName}
              {...data}
            />
          )}
        </LoadingOverlay>
        <div style={{ marginLeft: 30 }}>
          {azureFileUploadMsg && (
            <Message
              severity="error"
              text="Failed to upload file via azure storage please connect pulse VPN and retry"
            />
          )}
        </div>
      </div>

      <FooterButtons
        hideSaveButton={true}
        handleCancel={handleCancel}
        onSaveAsDraft={onSaveAsDraft}
        onSubmit={onSubmit}
        checkReadWriteAccess={checkReadWriteAccess}
        bottomFixed={true}
        formValid={
          (fileName === '') ||
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === 'Complete'
        }
        {...data}
      />
    </PageLayout>
  );
};
export default UADI;