import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import _ from 'lodash';
import { Button } from "react-bootstrap";
import searchMyProjects from '../../assets/images/searchMyProjects.svg';
import ExportSelectedRows from '../ExportCSV';
import filter from '../../assets/images/filter.svg';
import BlueFilterIcon from '../../assets/images/BlueFilterIcon.svg';
import exapandCollapseAll from '../../assets/images/expandCollapseAll.svg';

const ReworkHeader = (props) => {
  const {
    clearFilter,
    expandedKeys,
    toggleApplications,
    onSearchClick,
    allData,
    headers,
    filename,
    selectedRows,
    isFilterEnabled,
    setVisible
  } = props;

  const location = useLocation();
  const [state, updateState] = useState([]);
  const { ProjectID, id } = useParams();
  let currentUrl = '';
  const items = [];

  const getUrl = (e) => {
    switch (e) {
      case 'allProjects':
        return (currentUrl = `${currentUrl}/${e}`);
      case 'myProjects':
        return (currentUrl = `${currentUrl}/${e}`);
      case 'projectPlan':
        return (currentUrl = `${currentUrl}/${e}/${ProjectID}`);
      case 'rework':
        return (currentUrl = `${currentUrl}/${e}`);
      case 'task':
        return (currentUrl = `${currentUrl}/${e}/${id}`);
      default:
        currentUrl = `${currentUrl}/${e}`;
    }
  };

  useEffect(() => {
    location.pathname
      ?.split('/')
      .filter((home) => {
        return home !== '' && home !== ProjectID && home !== id;
      })
      .forEach((ele, i, arr) => {
        const obj = {};

        if (arr.length - 1 === i) {
          items.push({
            ...obj,
            label: _.startCase(ele),
            url: getUrl(ele),
            template: () => {
              return <span className="lastElement">{_.startCase(ele)}</span>;
            },
          });
        } else {
          items.push({ ...obj, label: _.startCase(ele), url: getUrl(ele) });
        }
      });
      updateState(items);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);



  return (
    <div className="actions-rework">
      <BreadCrumb model={state} separatorIcon={<i className="pi pi-angle-right"></i>} />
      <div className="action-button-rework">
      <Button
            variant="secondary"
            className="button-layout"
            onClick={setVisible}
          >
            Customize View
          </Button>
        <img
          src={isFilterEnabled ? BlueFilterIcon : filter}
          alt="filter logo"
          onClick={clearFilter}
          className="header-icons"
          hidden={''}
        />
        <img
          src={searchMyProjects}
          alt="search field"
          onClick={onSearchClick}
          className="header-icons"
        />
        <img
          className={`expand-collapse ${
            expandedKeys === null ? 'nonInvertedIcon' : 'invertedIcon'
          }`}
          alt=""
          data-pr-tooltip="Expand/Collapse All"
          src={exapandCollapseAll}
          onClick={() => toggleApplications()}
        ></img>
        <>
          <ExportSelectedRows
            selectedRows={selectedRows}
            allData={!selectedRows?.lenght && allData}
            headers={headers}
            filename={filename}
          />
        </>
      </div>
    </div>
  );
};
export default ReworkHeader;
