import React from "react";
import { CSVLink } from "react-csv";
import { changeDateFormat } from "../../utils";
import export2excel from "../../assets/images/export2excel.svg";
import { AcpService } from "../../service/ACPService";

export const ExportData = ({ allData, selectedRows, headers, filename, disabled = false }) => {
  let updatedData = {};
  let selectedUpdatedData = {};

  const formattedAllData =
    allData !== undefined &&
    allData?.map((data) => {
      updatedData = {
        ...data,
      };

      if (
        data &&
        (data["Slack"] !== undefined ||
          data["PmName"] !== undefined ||
          data["EndDate"] !== undefined ||
          data["Assignee"] !== undefined ||
          data["Duration"] !== undefined ||
          data["TaskName"] !== undefined ||
          data["StartDate"] !== undefined ||
          data["ProjectName"] !== undefined ||
          data["AWMProjectID"] !== undefined ||
          data["BufferToWork"] !== undefined ||
          data["NewStartDate"] !== undefined ||
          data["ProjectState"] !== undefined ||
          data["AwRedinessDate"] !== undefined ||
          data["RemainingBuffer"] !== undefined ||
          data["EstimatedOfPOAs"] !== undefined ||
          data["AWMRemainingBuffer"] !== undefined ||
          data["EstimatedAWPrinter"] !== undefined ||
          data["AWMTaskRemainingWork"] !== undefined ||
          data["ArtworkReadinessDate"] !== undefined ||
          data["PM"] !== undefined ||
          data["AWMTaskRemainingBuffer"] !== undefined)
      ) {
        if (filename === "Task_List") {
          updatedData["Buffer"] = data["RemainingBuffer"];
          updatedData["New Start Date"] = changeDateFormat(data["NewStartDate"]);
          updatedData["Owner"] = data["PM"];
        } else if (filename === "Project_List") {
          updatedData["Buffer"] = data["AWMRemainingBuffer"];
          updatedData["New Readiness"] = changeDateFormat(data["NewStartDate"]);
          updatedData["Owner"] = data["PM"];
        }

        if (filename !== "Task_List" && filename !== "Project_List") {
          updatedData["Buffer"] = data["AWMTaskRemainingBuffer"];
          updatedData["Buffer:Work"] = data["BufferToWork"];
          updatedData["Owner"] = data["Owner"] ? data["Owner"] : data["Assignee"];
        }

        updatedData["Slack"] = data["Slack"];
        updatedData["PM Name"] = data["PmName"]?.name ? data["PmName"]?.name : data["PmName"];
        updatedData["B:W"] = data["BufferToWork"];
        updatedData["Task Name"] = data["TaskName"];
        updatedData["Task Duration"] = data["Duration"];
        updatedData["Project ID"] = data["AWMProjectID"];
        updatedData["Project Name"] = data["ProjectName"];
        updatedData["#of POAs"] = data["EstimatedOfPOAs"];
        updatedData["Work"] = data["AWMTaskRemainingWork"];
        updatedData["Work"] = data["AWMTaskRemainingWork"];
        updatedData["Project State"] = data["ProjectState"];
        updatedData["End Date"] = AcpService.checkIfValidDate(data["EndDate"])
          ? data["EndDate"]
          : changeDateFormat(data["EndDate"]);
        updatedData["Artwork Readiness"] = AcpService.checkIfValidDate(data["ArtworkReadinessDate"])
          ? data["ArtworkReadinessDate"]
          : data["ArtworkReadinessDate"];
        updatedData["Start Date"] = AcpService.checkIfValidDate(data["StartDate"])
          ? data["StartDate"]
          : changeDateFormat(data["StartDate"]);
        updatedData["Current Start Date"] = AcpService.checkIfValidDate(data["StartDate"])
          ? data["StartDate"]
          : changeDateFormat(data["StartDate"]);
        updatedData["AW Rediness Date"] = AcpService.checkIfValidDate(data["AwRedinessDate"])
          ? data["AwRedinessDate"]
          : changeDateFormat(data["AwRedinessDate"]);
        updatedData["Art@Printer Date"] = AcpService.checkIfValidDate(data["EstimatedAWPrinter"])
          ? data["EstimatedAWPrinter"]
          : changeDateFormat(data["EstimatedAWPrinter"]);
      }

      return updatedData;
    });
  const formattedSelectedRows =
    selectedRows !== undefined &&
    selectedRows?.map((data) => {
      selectedUpdatedData = {
        ...data,
      };

      if (
        data &&
        (data["Slack"] !== undefined ||
          data["PmName"] !== undefined ||
          data["EndDate"] !== undefined ||
          data["Assignee"] !== undefined ||
          data["Duration"] !== undefined ||
          data["TaskName"] !== undefined ||
          data["StartDate"] !== undefined ||
          data["ProjectID"] !== undefined ||
          data["ProjectName"] !== undefined ||
          data["TaskDuration"] !== undefined ||
          data["AWMProjectID"] !== undefined ||
          data["BufferToWork"] !== undefined ||
          data["NewStartDate"] !== undefined ||
          data["ProjectState"] !== undefined ||
          data["AwRedinessDate"] !== undefined ||
          data["RemainingBuffer"] !== undefined ||
          data["EstimatedOfPOAs"] !== undefined ||
          data["EstimatedAWPrinter"] !== undefined ||
          data["AWMRemainingBuffer"] !== undefined ||
          data["AWMTaskRemainingWork"] !== undefined ||
          data["ArtworkReadinessDate"] !== undefined ||
          data["AWMTaskRemainingBuffer"] !== undefined)
      ) {
        if (filename === "Task_List") {
          selectedUpdatedData["Buffer"] = data["RemainingBuffer"];
          selectedUpdatedData["New Start Date"] = changeDateFormat(data["NewStartDate"]);
          selectedUpdatedData["Owner"] = data["PM"];
        } else if (filename === "Project_List") {
          selectedUpdatedData["Buffer"] = data["AWMRemainingBuffer"];
          selectedUpdatedData["New Readiness"] = changeDateFormat(data["NewStartDate"]);
          selectedUpdatedData["Owner"] = data["PM"];
        }

        if (filename !== "Task_List" && filename !== "Project_List") {
          selectedUpdatedData["Buffer"] = data["AWMTaskRemainingBuffer"];
          selectedUpdatedData["Owner"] = data["Owner"] ? data["Owner"] : data["Assignee"];
        }

        selectedUpdatedData["Slack"] = data["Slack"];
        selectedUpdatedData["PM Name"] = data["PmName"]?.name ? data["PmName"]?.name : data["PmName"];
        selectedUpdatedData["B:W"] = data["BufferToWork"];
        selectedUpdatedData["Task Name"] = data["TaskName"];
        selectedUpdatedData["Project ID"] = data["ProjectID"];
        selectedUpdatedData["Task Duration"] = data["Duration"];
        selectedUpdatedData["Project ID"] = data["AWMProjectID"];
        selectedUpdatedData["Project Name"] = data["ProjectName"];
        selectedUpdatedData["#of POAs"] = data["EstimatedOfPOAs"];
        selectedUpdatedData["Buffer:Work"] = data["BufferToWork"];
        selectedUpdatedData["Work"] = data["AWMTaskRemainingWork"];
        selectedUpdatedData["Work"] = data["AWMTaskRemainingWork"];
        selectedUpdatedData["Project State"] = data["ProjectState"];
        selectedUpdatedData["Task Duration"] = data["TaskDuration"];
        selectedUpdatedData["AW Rediness Date"] = data["AwRedinessDate"];
        selectedUpdatedData["End Date"] = AcpService.checkIfValidDate(data["EndDate"])
          ? data["EndDate"]
          : changeDateFormat(data["EndDate"]);
        selectedUpdatedData["Artwork Readiness"] = AcpService.checkIfValidDate(
          data["ArtworkReadinessDate"]
        )
          ? data["ArtworkReadinessDate"]
          : changeDateFormat(data["ArtworkReadinessDate"]);
        updatedData["Start Date"] = AcpService.checkIfValidDate(data["StartDate"])
          ? data["StartDate"]
          : changeDateFormat(data["StartDate"]);
        selectedUpdatedData["Current Start Date"] = AcpService.checkIfValidDate(data["StartDate"])
          ? data["StartDate"]
          : changeDateFormat(data["StartDate"]);
        selectedUpdatedData["Art@Printer Date"] = AcpService.checkIfValidDate(
          data["EstimatedAWPrinter"]
        )
          ? data["EstimatedAWPrinter"]
          : changeDateFormat(data["EstimatedAWPrinter"]);
      }

      return selectedUpdatedData;
    });

  const modifiedHeaders = headers?.map((str) => {
    if (
      str?.startsWith("Artwork_") ||
      str?.startsWith("Project_region") ||
      str?.startsWith("Project_Scale")
    ) {
      return str?.substring(8); // Remove the first 8 characters ("Artwork_")
    } else {
      return str; // Keep the original string
    }
  });

  const newFormattedHeaderValues = modifiedHeaders?.map((header) =>
    header === "ProjectID"
      ? "Project ID"
      : header === "AWMProjectID"
      ? "Project ID"
      : header === "ProjectName"
      ? "Project Name"
      : header === "PmName"
      ? "PM Name"
      : header === "AwRedinessDate"
      ? "AW Rediness Date"
      : header === "Slack"
      ? "Slack"
      : header === "StartDate"
      ? filename === "Task_List" || filename === "Project_List"
        ? "Current Start Date"
        : "Start Date"
      : header === "BufferToWork"
      ? filename === "Task_List" || filename === "Project_List"
        ? "B:W"
        : "BufferToWork"
      : header === "PM"
      ? "Owner"
      : header === "TaskName"
      ? "Task Name"
      : header === "RemainingBuffer"
      ? "Buffer"
      : header === "ProjectState"
      ? "Project State"
      : header === "EstimatedOfPOAs"
      ? "#of POAs"
      : header === "ArtworkReadinessDate"
      ? "Artwork Readiness"
      : header === "AWMRemainingBuffer"
      ? filename === "Project_List"
        ? "Buffer"
        : "AWMRemainingBuffer"
      : header === "NewStartDate" && filename === "Project_List"
      ? "New Readiness"
      : header === "EstimatedAWPrinter" &&
        !(filename === "Task_List" || filename === "Project_List")
      ? "Art@Printer Date"
      : header === "Art@PrinterDate" && !(filename === "Task_List" || filename === "Project_List")
      ? "Art@Printer Date"
      : header === "Duration" && !(filename === "Task_List" || filename === "Project_List")
      ? "Task Duration"
      : header === "TaskDuration" && !(filename === "Task_List" || filename === "Project_List")
      ? "Task Duration"
      : header === "Assignee" && !(filename === "Task_List" || filename === "Project_List")
      ? "Owner"
      : header
  );

  if (!selectedRows || selectedRows.length === 0) {
    if (allData !== null && allData !== undefined) {
      const csvReport = {
        data: formattedAllData.map((row) => {
          const newRow = { ...row };
          if (newRow.hasOwnProperty("ProjectID")) {
            newRow["Project ID"] = newRow["ProjectID"];
          } else if (newRow.hasOwnProperty("AWMProjectID")) {
            newRow["Project ID"] = newRow["AWMProjectID"];
          }
          return newRow;
        }),
        headers: newFormattedHeaderValues,
        filename: filename ? `${filename}.csv` : "Project_Records.csv",
      };
      return <>{allData !== null && allData !== undefined && csvTag(csvReport, disabled)}</>;
    }
  } else if (selectedRows.length > 0) {
    const csvReport = {
      data: formattedSelectedRows,
      headers: newFormattedHeaderValues,
      filename: filename ? `${filename}_Selected_Rows.csv` : "Selected_Rows.csv",
    };
    return <>{csvTag(csvReport, disabled)}</>;
  }
};

const csvTag = (data, disabled) => {
  return (
    <CSVLink {...data}>
      <img src={export2excel} alt="Export to csv" disabled={disabled} />
    </CSVLink>
  );
};

export default ExportData;