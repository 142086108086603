import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import MassupdateDialog from './MassupdateDialog';

function ActionButton({setShowManageDependency,getProjectPlanApi,clearFilters, disabled}) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Dropdown className="dropdown-button-custom">
        <Dropdown.Toggle id="dropdown-basic" disabled={disabled}>
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setVisible(true)}>Mass Update</Dropdown.Item>
          <Dropdown.Item onClick={() => setShowManageDependency(true)}>
            Dependency
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <MassupdateDialog visible={visible} setVisible={setVisible} getProjectPlanApi={getProjectPlanApi} clearFilters={clearFilters}  />
    </>
  );
}

export default ActionButton;
