import Api from ".";
import { DEVURL, SITURL, PRODURL } from "./envUrl";

export const postSaveAsDraftUploadCIC = async (
  formData,
  headers = {
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Origin": "http://localhost:3000/",
    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
  }
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/saveCICUploadTask`;
  const designIntent = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  console.log("response", designIntent, formData);
  return true;
};

export const submitUploadCIC = async (formData, id, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;
  apiURL = `${DEVURL}/uploadCIC/${id}`;
  const submitUploadCICMatrix = await axiosInstance({
    url: apiURL,
    method: "PATCH",
    data: formData,
  });
  return submitUploadCICMatrix;
};

//to be removed once proofscope handles approval--start
export const submitProofScopeApproval = async (
  formData,
  taskKey,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  // let apiURL = `${DEVURL}/approveCIC/${taskKey}`;
  // let apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/assignments/ASSIGN-WORKLIST ${taskKey}!APPROVECIC_FLOW/actions/ApproveCIC`;
  let apiURL = `${DEVURL}/submitTaskApproveRDTProofscope`;
  // let apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/SubmitTask`;

  const response = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });

  console.log("response", response);

  return true;
};
//to be removed once proofscope handles approval--end
