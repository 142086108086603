import * as types from "../types/types";

const initialState = {
  manageDependencyScreenData: [],
  loading: true,
  error: null,
};

const manageDependencyReducer = (state = initialState, action) => {
  // console.log("manageDependencyReducer.action.payload: ", action);
  switch (action.type) {
    case types.UPDATE_MANAGE_DEPENDENCY:
      return {
        ...state,
        manageDependencyScreenData: action.payload,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
export default manageDependencyReducer;
