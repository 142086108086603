import React from "react";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { usePriorityNew } from "../../../Utils/GetRolePriority";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";

const FooterButtons = ({
  onSaveAsDraft,
  onSubmit,
  handleCancel,
  approve,
  formValid,
  bottomFixed,
  hideSaveButton,
  submitAndSave,
  saveAsDraftEnable,
  Assignee,
  manageDependencyFlag,
  loader,
  FooterComponent,
  isCancelDisabled=false,
}) => {
  const location = useLocation();
  const locationPath = location?.pathname;
  const url = locationPath?.split("/");
  const pathName = url[url?.length - 4];
  const checkReadWriteAccess1 = usePriorityNew(url[1]);
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);
  const isUserAccess = Assignee === userInformation?.userid;
  const accessSecurityMatrix = usePriorityNew(url[1]);
  const finalPermission = isUserAccess ? isUserAccess : accessSecurityMatrix;
  useEffect(() => {
    if (manageDependencyFlag) {
      setCheckReadWriteAccess(checkReadWriteAccess1);
    } else {
      if (User && Assignee) {
        if (userInformation?.userid === Assignee) {
          setCheckReadWriteAccess(true);
        } else {
          setCheckReadWriteAccess(false);
        }
      }
    }
  }, [User, Assignee]);

  return (
    <>

      <div
        className={`${bottomFixed ? "form-buttons footerbutton-display bottom-fixed" : "form-buttons footerbutton-display"} footer-button`}
        style={{ background: "#FAFAFA" }}
      >
        <div>  {FooterComponent && <FooterComponent />}</div>
        <div className="footerbutton-flex">
          <Button className="button-layout" variant="secondary" onClick={() => handleCancel()} disabled={isCancelDisabled}>
            {url[url?.length - 2] === "dependencyMapping" || url[url?.length - 3] === "artworkAlignment"
              ? "Cancel" : "Back"}
          </Button>
          {!approve && (
            <>
              {hideSaveButton ||
                (pathName !== "CNIQ" && pathName !== "CCD" && pathName !== "CPT" && (
                  <Button
                    className={
                      finalPermission ? "button-layout" : "button-layout btn btn-disabled"
                    }
                    variant="secondary"
                    onClick={() => onSaveAsDraft()}
                    disabled={!finalPermission || saveAsDraftEnable}
                  >
                    Save as Draft
                  </Button>
                ))}
            </>
          )}
          <Button
            className="button-layout"
            type="submit"
            onClick={() => onSubmit()}
            disabled={
              (finalPermission !== undefined ? formValid || !finalPermission : formValid) ||
              loader
            }
          >
            {submitAndSave === "Save" ? "Save" : "Submit"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default FooterButtons;
