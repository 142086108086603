// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#printer,
#substrate,
#printers {
  box-shadow: none !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px solid #acacac !important;
}
#printer .p-inputtext,
#substrate .p-inputtext,
#printers .p-inputtext {
  background: #ffffff;
  border: 0;
  width: 100%;
  font-size: 12px;
}
#printer input,
#substrate input,
#printers input {
  border-radius: 5px;
  box-shadow: none !important;
}
#printer .p-button.p-button-icon-only,
#substrate .p-button.p-button-icon-only,
#printers .p-button.p-button-icon-only {
  background: transparent;
  color: #acacac;
  border: 0;
  height: 33px;
}

.label-header {
  display: flex;
  justify-content: flex-start;
}
.label-header label {
  font-size: 12px;
}
.label-header .pi {
  font-size: 12px;
}
.label-header .gap-col {
  margin-left: 30px !important; /* Adjust the value to set the desired gap */
}
.label-header .p-multiselect .p-multiselect-trigger,
.label-header .p-multiselect .p-multiselect-label {
  font-size: 12px;
}
.label-header .p-multiselect-label-container {
  height: 34px;
}

.print-trial {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.print-trial:nth-child(1) {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/AWMJobs/CCD/index.scss"],"names":[],"mappings":"AAAA;;;EAGE,2BAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,oCAAA;AACF;AACE;;;EACE,mBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;AAGJ;AADE;;;EACE,kBAAA;EACA,2BAAA;AAKJ;AAHE;;;EACE,uBAAA;EACA,cAAA;EACA,SAAA;EACA,YAAA;AAOJ;;AAJA;EACE,aAAA;EACA,2BAAA;AAOF;AANE;EACE,eAAA;AAQJ;AANE;EACE,eAAA;AAQJ;AANE;EACE,4BAAA,EAAA,4CAAA;AAQJ;AANE;;EAEE,eAAA;AAQJ;AANE;EACE,YAAA;AAQJ;;AALA;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;AAQF;;AANA;EACE,gBAAA;AASF","sourcesContent":["#printer,\n#substrate,\n#printers {\n  box-shadow: none !important;\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  border: 1px solid #acacac !important;\n  // width: 150px;\n  .p-inputtext {\n    background: #ffffff;\n    border: 0;\n    width: 100%;\n    font-size: 12px;\n  }\n  input {\n    border-radius: 5px;\n    box-shadow: none !important;\n  }\n  .p-button.p-button-icon-only {\n    background: transparent;\n    color: #acacac;\n    border: 0;\n    height: 33px;\n  }\n}\n.label-header {\n  display: flex;\n  justify-content: flex-start;\n  label {\n    font-size: 12px;\n  }\n  .pi {\n    font-size: 12px;\n  }\n  .gap-col {\n    margin-left: 30px !important; /* Adjust the value to set the desired gap */\n  }\n  .p-multiselect .p-multiselect-trigger,\n  .p-multiselect .p-multiselect-label {\n    font-size: 12px;\n  }\n  .p-multiselect-label-container {\n    height: 34px;\n  }\n}\n.print-trial {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n.print-trial:nth-child(1) {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
