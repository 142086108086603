import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import MassupdateDialog from "./MassupdateDialog";
import { UnsavedChangesDialog } from "../../common/NavigationPrompt";

function ActionButton({
  setShowManageDependency,
  getProjectPlanApi,
  clearFilters,
  disabled,
  pageEdited,
  setPageEdited,
  onHide
}) {
  const [visible, setVisible] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [tempActionType, setTempActionType] = useState("");

  // this function will be called if we click Action type Actions, or Dependency
  // page is not Edited, then else will be executed as earlier based on type, but
  // if page is edited , then will show dialog true, and setTempActionType,
  // based on action type we send in function call
  // if confirm yes in warning dailog then
  // confirmNavigation will be called for pending action
  const handleActionDropdownsClick = (type) => {
    if (pageEdited) {
      setShowDialog(true);
      setTempActionType(type === "MassUpdate" ? "MassUpdate" : "Dependency");
    } else {
      if (type === "MassUpdate") {
        setVisible(true);
      } else if (type === "Dependency") {
        setShowManageDependency(true);
      }
    }
  };

  // if a warning has been shown and clicked yes, that you confiremed to loose your changes
  // then confirmNavigation will be used to remove sidebar warning with markClean();
  // set temp index with setTabPanelList(tempTabIndex); and redirect to required place
  const confirmNavigation = () => {
    setShowDialog(false);
    if (tempActionType === "MassUpdate") {
      setVisible(true);
    } else if (tempActionType === "Dependency") {
      setShowManageDependency(true);
    }
  };

  const cancelNavigation = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Dropdown className="dropdown-button-custom">
        <Dropdown.Toggle id="dropdown-basic" disabled={disabled}>
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            // onClick={() => setVisible(true)}
            onClick={() => handleActionDropdownsClick("MassUpdate")}
          >
            Mass Update
          </Dropdown.Item>
          <Dropdown.Item
            // onClick={() => setShowManageDependency(true)}
            onClick={() => handleActionDropdownsClick("Dependency")}
          >
            Dependency
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <MassupdateDialog
        visible={visible}
        setVisible={setVisible}
        setPageEdited={setPageEdited}
        getProjectPlanApi={getProjectPlanApi}
        clearFilters={clearFilters}
        onHide={onHide}
      />
      {/* this block will used to show warning on same page */}
      <UnsavedChangesDialog
        visible={showDialog}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
      />
    </>
  );
}

export default ActionButton;
