import { useCallback, useEffect, useState } from "react";
import { getUserNotification } from "../apis/userNotificationsApi";
import workerFunction from "../components/Headers/workerFunction";

const useWebWorker = ({ userId }) => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const memoizedWorkerFunction = useCallback(workerFunction, [userId]);

  useEffect(() => {
    setError(null);

    try {
      const fetchData = async () => {
        try {
          const result = await getUserNotification(userId); // Perform the fetch operation in the main thread
          setResult(result);
        } catch (error) {
          setError(error.message);
        }
      };

      fetchData();
      const intervalId = setInterval(fetchData, 300000);

      const code = memoizedWorkerFunction.toString();
      const blob = new Blob([`(${code})()`], { type: "application/javascript" });
      const workerScriptUrl = URL.createObjectURL(blob);
      const worker = new Worker(workerScriptUrl);

      worker.onmessage = (e) => {
        setResult(e.data);
      };

      worker.onerror = (e) => {
        setError(e.message);
      };

      worker.postMessage({}); // Pass any necessary data to the worker

      return () => {
        clearInterval(intervalId);
        worker.terminate();
        URL.revokeObjectURL(workerScriptUrl);
      };
    } catch (e) {
      setError(e.message);
    }
  }, [memoizedWorkerFunction, userId]);

  return { result, error };
};

export default useWebWorker;
