// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IQCD .upload-row {
  margin-top: 1px !important;
}

.supplier-file-list-heading {
  font-weight: 700;
  font-size: 12px;
  padding: 0.5rem 0 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/AWMJobs/DesignJobs/UploadFile.scss"],"names":[],"mappings":"AACE;EAEE,0BAAA;AADJ;;AAKA;EACE,gBAAA;EACA,eAAA;EACA,sBAAA;AAFF","sourcesContent":[".IQCD {\n  .upload-row {\n    // margin-top: 160px !important;\n    margin-top: 1px !important;\n  }\n}\n\n.supplier-file-list-heading {\n  font-weight: 700;\n  font-size: 12px;\n  padding: 0.5rem 0 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
