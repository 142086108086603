import { getAllFilesDetails } from '../../../apis/sharedFolderApi';
import { ProjectService } from '../../../service/PegaService';
import LoadingOverlay from 'react-loading-overlay-ts';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'primeicons/primeicons.css';
import JobList from './JobList';
import './index.scss';

function convertJobList(FileDetails) {
  let keyCounter = 1;

  function traverse(job) {
    const newObj = {
      key: keyCounter++,
      data: job?.Data,
      children: [],
    };

    job?.JobNameList?.forEach((jobName, index) => {
      const childObj = {
        key: newObj?.key * 10 + index + 1,
        data: jobName?.Data,
        children: [],
      };

      jobName?.FileList?.forEach((file, i) => {
        childObj?.children?.push({
          parentKey: newObj?.key,
          key: childObj?.key * 10 + i + 1,
          keyCode: childObj?.key,
          data: { ...file?.Data, keyCode: childObj?.key },
        });
      });

      newObj?.children?.push(childObj);
    });

    return newObj;
  }

  return FileDetails?.map(traverse);
}

function FilesFolderCompo(props) {
  let columnNamesJson = localStorage.getItem('filesAllColumnNames');
  const [JobListData, setJobListData] = useState([]);
  const [ProjectDetalis, setProjectDetalis] = useState({});
  const columnNames = JSON.parse(columnNamesJson);
  const { view, loader, setLoader } = props;
  let { ProjectID } = useParams();
  if (columnNames === null) {
    const columnNames = ProjectService.getFilesAllColumnNames();
    localStorage.setItem('filesAllColumnNames', JSON.stringify(columnNames));
  }

  useEffect(() => {
    fetchDependencyMappingTabDetails(ProjectID);
  }, []);

  const fetchDependencyMappingTabDetails = async (projectId) => {
    setLoader(true);
    const { response } = await getAllFilesDetails(projectId);
    const data = convertJobList(response?.JobList);
    setProjectDetalis(response?.ProjectDetails);
    setJobListData(data);
    setLoader(false);
  };

  return (
    <>
      {loader ? (
        <LoadingOverlay active={true} spinner text="" />
      ) : (
        <>
          <JobList
            {...props}
            view={view}
            JobListData={JobListData}
            ProjectDetalis={ProjectDetalis}
          />
        </>
      )}
    </>
  );
}

export default FilesFolderCompo;
