// Function to remove duplicate field names
const removeDuplicates = (data) => {
  const uniqueFields = {};
  const uniqueData = [];

  data.forEach((item) => {
    if (!uniqueFields[item.Field]) {
      uniqueFields[item.Field] = true;
      uniqueData.push(item);
    }
  });

  return uniqueData;
};
/**
 * function to group all the list of changes of cic fa and poa changes into single array.
 *
 * @changesDataList
 * @param {Object} snapshotChanges - api response.
 * @returns {Array} returns the listofchanges array.
 */
export const changesDataList = (snapshotChanges) => {
  let ListofChanges = [];
  if (snapshotChanges?.CICList) {
    if (snapshotChanges?.CICList?.PMPList?.[0]?.ListOfChanges) {
      ListofChanges.push(...snapshotChanges.CICList.PMPList[0].ListOfChanges);
    }
    if (snapshotChanges?.CICList?.PMPFPCList?.[0]?.ListOfChanges) {
      ListofChanges.push(...snapshotChanges.CICList.PMPFPCList[0].ListOfChanges);
    }
  }
  if (snapshotChanges?.FAList) {
    if (snapshotChanges?.FAList?.PMPList?.[0]?.ListOfChanges) {
      ListofChanges.push(...snapshotChanges.FAList.PMPList[0].ListOfChanges);
    }
    if (snapshotChanges?.FAList?.PMPFPCList?.[0]?.ListOfChanges) {
      ListofChanges.push(...snapshotChanges.FAList.PMPFPCList[0].ListOfChanges);
    }
  }
  if (snapshotChanges?.POAList) {
    if (snapshotChanges?.POAList?.PMPList?.[0]?.ListOfChanges) {
      ListofChanges.push(...snapshotChanges.POAList.PMPList[0].ListOfChanges);
    }
    if (snapshotChanges?.POAList?.PMPFPCList?.[0]?.ListOfChanges) {
      ListofChanges.push(...snapshotChanges.POAList.PMPFPCList[0].ListOfChanges);
    }
  }

  const uniqueListofChanges = removeDuplicates(ListofChanges);
  return uniqueListofChanges;
};

/**
 * function to group all the POA list of changes of cic fa and poa changes into single array.
 *
 * @poaChangesList
 * @param {Object} snapshotChanges - api response.
 * @returns {Array} returns the listofchanges array.
 */
export const poaChangesList = (snapshotChanges) => {
  let ListofPOAChanges = [];
  if (snapshotChanges?.POAList) {
    if (snapshotChanges?.POAList?.PMPList?.[0]?.ListOfChanges) {
      ListofPOAChanges.push(...snapshotChanges.POAList.PMPList[0].ListOfChanges);
    }
    if (snapshotChanges?.POAList?.PMPFPCList?.[0]?.ListOfChanges) {
      ListofPOAChanges.push(...snapshotChanges.POAList.PMPFPCList[0].ListOfChanges);
    }
  }
  const uniqueListofPOAChanges = removeDuplicates(ListofPOAChanges);
  return uniqueListofPOAChanges;
};
