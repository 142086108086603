import {
    fileSizeErrorMessage,
    fileTypeErrorMessage,
    defaultAllowedFileType,
    defaultAllowedFileSize,
} from '../constants/FileUploadConstant';
import { formatBytes } from './FileUploadService';

export const isFileValid = (selectedFile, accept, maxSize, acceptType = defaultAllowedFileType, errorType = fileTypeErrorMessage) => {
    let isValid = true;
    let errorMessage = '';
    let acceptedFileType = accept || acceptType;
    if (Array.isArray(acceptedFileType)) {
        acceptedFileType = acceptedFileType.join(', ');
    }
    const maxFileSize = maxSize || defaultAllowedFileSize;
    if (selectedFile) {
        if (
            acceptedFileType === '*' ||
            acceptedFileType.includes(selectedFile.type.split('/')[1].toLowerCase())
        ) {
            if (selectedFile.size <= maxFileSize) {
                if (!validateFileName(selectedFile.name)) {
                    isValid = false;
                    errorMessage = 'filename should not contain following patterns : file(), &&,  is not,  ( )( ),  Special Character like /\:*?"<>|&'; 
                }
                else {
                    isValid = true;
                    errorMessage = '';
                }
                return [isValid, errorMessage];
            } else {
                isValid = false;
                errorMessage = fileSizeErrorMessage(formatBytes(maxFileSize));
                return [isValid, errorMessage];
            }
        } else {
            isValid = false;
            errorMessage = errorType;
            return [isValid, errorMessage];
        }
    } else {
        isValid = false;
        errorMessage = "";
        return [isValid, errorMessage];
    }
};

const validateFileName = (fileName) => {
    let isFileNameValid = true;
    const fileNamealtr = fileName?.replace(/\s+/g, "")?.toLowerCase();
    const regexPatterns = [
        /file\(.*\)/,      // Match filename with "file(1),file(1)(2),file(1)-"
        /\(.*\)\(.*\)/,   // Match filename with "()(), abc()(), abc(1)(2)abc , (abc)(xy)"
        /&&/,            // Match filename with "&&"
        /\/|\\|\:|\*|\?|\"|\<|\>|\||&/, // Match filename with Special Character /\:*?"<>|&
    ];
    const regexMatch1 = regexPatterns.some((regex) => regex.test(fileNamealtr));

    const fileNamealtrWithSpace = fileName?.toLowerCase();
    const regexPatternwithSpace=[
        /.+ is not .+/,    //Match filename with "anything is not anything"
        /.+ is not/        //Match filename with "anything is not"
    ]
    const regexMatch2 = regexPatternwithSpace.some((regex) => regex.test(fileNamealtrWithSpace));

    isFileNameValid = (regexMatch1 || regexMatch2) ? false : true
    return isFileNameValid
}
