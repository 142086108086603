import axios from "axios";
// import {pf} from "../../../configSSO";

export const CreateSession = async (
  strProofScopeURL
) => {
  try {
    const decodedPassword = "admin";
    const json = JSON.stringify({
      method: "auth.create_session",
      user_name: "admin",
      options: {
        password: decodedPassword,
        expiry: 120,
      },
    });

    const response = await axios.post(strProofScopeURL, json, {
      headers: {
        "Content-Type": "application/json",
        "Content-Language": "en-US",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "*",
      },
    });
    // let headers = {
    //   "Content-Type": "application/json",
    //   "Content-Language": "en-US",
    //   "Access-Control-Allow-Origin": "*",
    //   "Access-Control-Allow-Credentials": "true",
    //   "Access-Control-Allow-Headers": "*",
    //   "Access-Control-Allow-Methods": "*",
    // };
    // const api = new Api();
    // const axiosInstance = await api.init({ headers });

    // const response = await axiosInstance({
    //   url: strProofScopeURL,
    //   method: "POST",
    //   data: json,
    // });

    const strSession = response.data;
    return strSession;
  } catch (error) {
    console.error(error);
    return null;
  }
};
