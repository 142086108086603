import React from 'react';

function CustomHeader({ tabHeaderDetails, index, handleDelete }) {
  return (
    <>
      <div className="custom-tab-header">
        <div className="p-tabview-title">
          <>
            {index === 0 ? (
              <span>{'Dependency Mapping'} </span>
            ) : (
              <span>
                {tabHeaderDetails?.description?.DSBP_PMP_PIMaterialNumber +
                  ' | ' +
                  tabHeaderDetails?.description?.DSBP_PMP_PIMaterialDescription}
              </span>
            )}
          </>
        </div>
        {index !== 0 && (
          <button className="close-button" onClick={() => handleDelete(index)}>
            &#x2715;
          </button>
        )}
      </div>
    </>
  );
}

export default CustomHeader;
