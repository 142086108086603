import React from 'react';
import PageLayout from '../PageLayout';

function UnAuthorizedComponent({ nowrapper }) {
  return (
    <>
      {nowrapper ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <h1>You don't have right AWM role to access this page</h1>
          <div>Please connect with your line manager to get right AWM role assigned </div>
        </div>
      ) : (
        <PageLayout>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '500px',
            }}
          >
            <h1>You don't have right AWM role to access this page</h1>
            <div>Please connect with your line manager to get right AWM role assigned </div>
          </div>
        </PageLayout>
      )}
    </>
  );
}

export default UnAuthorizedComponent;
