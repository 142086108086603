import React, { useRef, useState, Suspense, useEffect } from "react";
import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import useWebWorker from "../../hooks/useWebWorker";
import Notificaitons from "../../assets/images/notification.svg";
import blueNotifications from "../../assets/images/blueNotifications.svg";
import { userNotificationActions } from "../../store/actions/UserNotificationAction";
import { getUserNotification, storeUserNotifications } from "../../apis/userNotificationsApi";
import { locationTo } from "../Notifications/util";
import { UnsavedChangesDialog,useUnsavedChangesWarning } from "../common/NavigationPrompt";

const NotificationsDropDown = React.lazy(() => import("../Notifications/NotificationsDropdown"));

const Header = () => {
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const {
    userDetails: { AccessGroup },
  } = useSelector((state) => state.UserDetailsReducer);
  const [notificationCount, setNotificationCount] = useState("");
  const [userNotifications, setUserNotifications] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [loader, setLoader] = useState(false);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const locationPath = location?.pathname;
  const url = locationPath?.split("/");
  const userId = localStorage.getItem("userId");
  const { result } = useWebWorker({ userId });
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [pendingArg, setPendingArg] = useState(null);
  const [pendingNotification, setPendingNotification] = useState(null);
  const { markDirty, markClean } = useUnsavedChangesWarning();

  const roleFormat = () => {
    return AccessGroup?.map((role) => {
      return role.AccessGroupNames.substr(4);
    }).join(", ");
  };
  const handleNotifications = (e) => {
    setOpenNotification(!openNotification);
    notificationRef.current.toggle(e);
  };

  useEffect(() => {
    if (result?.UserNotification || result?.pyCount) {
      dispatch(userNotificationActions(result));
    }
  }, [result]);

  const userNotificationsDetails = useSelector(
    (state) => state.UserNotificationReducer.userNotificationsDetails
  );
  useEffect(() => {
    if (notificationCount !== userNotificationsDetails?.pyCount) {
      setNotificationCount(userNotificationsDetails?.pyCount);
      if (userNotificationsDetails?.UserNotification?.length >= 1) {
        setUserNotifications([...userNotificationsDetails.UserNotification]);
      }
    }
  }, [userNotificationsDetails]);
  const handleUserNotifications = async (arg, notification) => {
    let formData = {};
    if (arg === "ClearAll") {
      setLoader(true);
      formData = {
        User: userId,
        ClearAll: true,
      };
    } else if (arg === "MarkAllRead") {
      setLoader(true);
      formData = {
        User: userId,
        MarkAllRead: true,
      };
    } else {
      notification.IsRead = true;
       formData = {
        User: userId,
        MarkAllRead: false,
        ClearAll: false,
        UserNotification: [{ ...notification }],
      };
      const { NotificationType, AWMTaskID, AWMProjectID, LinksTo } = notification;
      if (NotificationType === "Change") {
        const [DSBP_InitiativeID, DSBP_PMP_PIMaterialID, DSBP_PMP_PIMaterialNumber] =
          AWMTaskID.split("/");
        navigate(locationTo(NotificationType, AWMTaskID, AWMProjectID, userId, LinksTo), {
          state: {
            from: "artworkalignmentpage",
            assignee: userId,
            InitiativeID: DSBP_InitiativeID,
            PIMaterialID: DSBP_PMP_PIMaterialID,
            PIMaterialNumber: DSBP_PMP_PIMaterialNumber,
          },
        });
      } else {
        navigate(locationTo(NotificationType, AWMTaskID, AWMProjectID, userId, LinksTo));
      }
    }
    await storeUserNotifications(formData);
    let result = await getUserNotification(userId);
    setUserNotifications(result?.UserNotification);
    setNotificationCount(result?.pyCount);
    dispatch(userNotificationActions(result));
    setLoader(false);
  };

  //by doing this will call the popup/handleUserNotifications depending on unsavedChanges
  const handleUserNotificationsCall = (arg, notification) => {
    const unsavedChanges = JSON.parse(localStorage.getItem("unsavedChanges"));
    if (unsavedChanges) {
      setPendingArg(arg);
      setPendingNotification(notification);
      setShowDialog(true);
    } else {
      handleUserNotifications(arg, notification);
    }
  };
  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  }
  // by doing setShowDialog(false) and will call handleUserNotifications ;
  const confirmNavigation = () => {
    handleUserNotifications(pendingArg,pendingNotification);
    markClean();
    setShowDialog(false);
  }

  return (
    <div className="header">
      <div>
        {/* {JSON.stringify([])} */}
        <h1>
          Welcome Back, {userInformation?.firstName} ({roleFormat()})!
        </h1>
        {/* <div className="user-date-time">
          <p>{formattedDate(userInformation.loginTime)}</p>
          <p>{formattedTime(userInformation.loginTime)}</p>
        </div> */}
      </div>
      <div className="user-profile">
        <img
          src={openNotification ? blueNotifications : Notificaitons}
          alt="notificaitons"
          className="notificaitons"
          onClick={(e) => handleNotifications(e)}
        />
        {(notificationCount>0 && userInformation?.username) && (
          <span className="notification-badge">
            {notificationCount > 10 ? "10+" : notificationCount}
          </span>
        )}
        <Suspense fallback={<div></div>}>
          <NotificationsDropDown
            notificationRef={notificationRef}
            userNotifications={userNotifications}
            userId={userInformation?.userid}
            setUserNotifications={setUserNotifications}
            setNotificationCount={setNotificationCount}
            handleUserNotifications={handleUserNotificationsCall}
            loader={loader}
          />
        </Suspense>
        <div>
          <p>
            {userInformation?.firstName}
            <span>{userInformation.userid}</span>
          </p>
        </div>

        {/* UnsavedChangesDialog popup*/}   
        <UnsavedChangesDialog
          visible={showDialog}
          onCancel={cancelNavigation}
          onConfirm={confirmNavigation}
        />
        {/* <img src={user} alt="user profile" className="userProfile" /> */}
      </div>
    </div>
  );
};
export default Header;
