// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigateLink {
  text-decoration: none;
  color: #003da5;
  cursor: pointer;
  font-weight: 400;
}
.navigateLink:hover {
  text-decoration: none;
  color: #003da5;
  font-weight: 400;
}

.custom-overlay {
  background: #003da5;
  color: white;
  padding: "10px";
  border-radius: "5px";
  box-shadow: "0 0 10px rgba(0, 0, 0, 0.8)";
}

.selected-row-class {
  background-color: rgba(204, 225, 255, 0.5019607843) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/TreeTable/index.scss"],"names":[],"mappings":"AACA;EACI,qBAAA;EACA,cAAA;EAEA,eAAA;EACA,gBAAA;AADJ;AAEI;EACE,qBAAA;EACA,cAAA;EACA,gBAAA;AAAN;;AAIE;EACE,mBAAA;EACA,YAAA;EACA,eAAA;EACA,oBAAA;EACA,yCAAA;AADJ;;AAIA;EACE,8DAAA;AADF","sourcesContent":["\n.navigateLink {\n    text-decoration: none;\n    color: #003da5;\n     \n    cursor: pointer;\n    font-weight: 400;\n    &:hover{\n      text-decoration: none;\n      color:#003da5;\n      font-weight: 400;\n    }\n  }\n\n  .custom-overlay{\n    background:#003da5;\n    color: white;\n    padding: '10px';\n    border-radius: '5px';\n    box-shadow: '0 0 10px rgba(0, 0, 0, 0.8)';\n  }\n\n.selected-row-class{\n  background-color:#CCE1FF80 !important; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
