import {
  onSortData,
  bufferToWork,
  multiFilterChange,
  getModifiedProjectHeaderNames,
} from "../../../utils";
import moment from "moment";
import _, { isArray, isObject } from "lodash";
import { Tag } from "primereact/tag";
import { Column } from "primereact/column";
// import CustomisedView from "./CustomisedView";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import ProjectListHeader from "./ProjectListHeader";
import ProjectNameHeader from "./ProjectNameHeader";
import ProjectListFilter from "../ProjectListFilter";
import LoadingOverlay from "react-loading-overlay-ts";
// import { generateUniqueKey } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
// import CustomizeView from "../MyProjects/CustomizeView";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ProjectService } from "../../../service/PegaService";
import CustomizeView from "../../common/CustomizeView";
//const CustomisedView = React.lazy(() => import("./CustomisedView"));
import { setAlignFrozenColumn } from "../../../Utils/leftAlignFrozenColumn";
import { selectedProject, SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
// import CustomizeViewDialog from "../../DSBP/CustomizeView/CustomizeViewDialog";
import Footer from "../../common/tableFooter/tableFooter";
import ACPFilter from "../../ACP/common/ACPFilter";
import { AcpService } from "../../../service/ACPService";
import { AcpCommonService } from "../../../service/AcpCommonService";

const ProjectList = (props) => {
  const flag = props.flag;
  const op = useRef(null);
  const dt = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const filterArray = useRef([]);
  const colAllSelected = useRef([]);
  const currentUrl = location.pathname;
  const selectedFieldsRef = useRef([]);
  const selectedColumnList = useRef([]);
  const [filters, setFilters] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sortData, setSortData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pegadata, setPegaData] = useState(null);
  const [isSearch, isSearchSet] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [isColWidthSet, setColWidth] = useState(null);
  const [frozenCoulmns, setFrozenColumn] = useState([]);
  const [tableRender, setTableRender] = useState(false);
  const [selectedFields, setSelectedFields] = useState({});
  const [allColumnNames, setAllColumnNames] = useState([]);
  const [ProjectFrozen, setProjectFrozen] = useState(false);
  const [filterCleared, setFilterCleared] = useState(false);
  const [initialPegaData, setInitialPegaData] = useState(null);
  const [ProjectData, setProjectData] = useState(0);
  //let columnWidth = localStorage.getItem("columnWidthMyProject");
  const [isReorderedColumn, setReorderedColumn] = useState(false);
  const [projectColumnName, setProjectColumnNames] = useState([]);
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const [customizeViewFields, setCustomizeViewFields] = useState([]);
  const [updatedAllColumnNames, setUpdatedAllColumnNames] = useState([]);
  const [initialColumnLenght, setinitialColumnLenght] = useState({});
  const [resizeMode, setResizeMode] = useState("expand");
  const StorageData = localStorage.getItem("customizeViewFieldsMyProjects");
  const customizeViewFieldsStorage = StorageData && JSON.parse(StorageData);
  const [customViewIsAddedMyProject, isCustomViewIsAddedMyProject] = useState(null);
  const user_group = useSelector((state) => state?.UserDetailsReducer?.userDetails?.UserGroup);

  const checkIsFilterApplied = () => {
    let isSelectedVal = false;
    if (selectedFields && Object.keys(selectedFields)?.length > 0) {
      isSelectedVal = Object.values(selectedFields)?.some((value) => value.length > 0);
    }
    return isSelectedVal;
  };
  const isFilterEnabled = isSearch ||
    customViewIsAddedMyProject ||
    checkIsFilterApplied();

  const isResetEnabled = isFilterEnabled || customViewIsAddedMyProject || frozenCoulmns.length > 0;
  const [conditionMatched, setConditionMatched] = useState(false);

  const myProjectList = useSelector((state) => state.myProject);
  const User = useSelector((state) => state?.UserReducer);
  const [searchHeader, setSearchHeader] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  // const jsonColumnWidth = JSON.parse(columnWidth);
  useEffect(() => {
    let sortingData = JSON.parse(localStorage.getItem("sortingData"));
    if (sortingData && sortingData?.length) {
      onSort(sortingData[0], sortingData[1]);
    }
  }, []);

  useEffect(() => {
    let temp = projectColumnName?.reduce((acc, curr) => {
      let matchMode =
        selectedFields[curr] && selectedFields[curr]?.length > 0
          ? FilterMatchMode.IN
          : FilterMatchMode.CONTAINS;
      return {
        ...acc,
        [curr]: {
          value:
            selectedFields[curr] && selectedFields[curr]?.length > 0 ? selectedFields[curr] : null,
          matchMode: matchMode,
        },
      };
    }, {});

    if (globalFilterValue !== "") {
      temp["global"] = { value: globalFilterValue, matchMode: FilterMatchMode.CONTAINS };
    }
    setSearchHeader(temp);
  }, [selectedFields, globalFilterValue, projectColumnName]);

  const onGlobalFilterKeyword = (e) => {
    setGlobalFilterValue(e.target.value);
  };

  const userInformation = User?.userInformation;
  const { loading } = myProjectList;

  useEffect(() => {
    if (projectColumnName.length < 6) {
      setResizeMode("fit");
    } else {
      setResizeMode("expand");
    }
  }, [projectColumnName]);

  useEffect(() => {
    const ProjectData = _.cloneDeep(myProjectList.myProject);
    let allCol = [];
    if (ProjectData?.length) {
      allCol = Object.keys(ProjectData[0]);
      allCol.push("Artwork_Status");
    }
    let columnWidthMyProject = {};

    let getJsonStoredWidthColumns = localStorage.getItem("columnWidthMyProject");
    let getStoredWidthColumns = JSON.parse(getJsonStoredWidthColumns);
    const checkEmptyObject = _.isEmpty(getStoredWidthColumns);

    if (checkEmptyObject) {
      localStorage.setItem("columnWidthMyProject", JSON.stringify(columnWidthMyProject));
    }

    let jsonColWidth = localStorage.getItem("isColWidthSetMyProject");
    let isColWidthSetFlag = JSON.parse(jsonColWidth);
    if (isColWidthSetFlag) {
      setColWidth(true);
    }

    let availableFields;
    const selectedFeilds = ProjectService.getMyProjectData();
    availableFields = allColumnNames?.filter(function (objFromA) {
      return !selectedFeilds.find(function (objFromB) {
        return objFromA === objFromB;
      });
    });
    setinitialColumnLenght({
      availableFields: availableFields.length,
      freezedColumns: 0,
      selectedFields: selectedFeilds.length,
    });
  }, []);

  useEffect(() => {
    getCustomizedView();
  }, [allColumnNames]);

  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        // below is json call
        // const ProjectData = await ProjectService.getProjectData();

        //below is api call
        const ProjectData = _.cloneDeep(myProjectList.myProject);
        if (ProjectData && ProjectData.length) {
          ProjectData.filter((field) => {
            if (field.Artwork_Category) {
              let categoryNames = field?.Artwork_Category?.map((item) => item.Category_Name).join(
                ", "
              );
              field.Artwork_Category = categoryNames;
            }

            if (field.Artwork_SMO) {
              let SMOName = field?.Artwork_SMO?.map((item) => item.SMO_Name).join(", ");
              field.Artwork_SMO = SMOName;
            }

            if (field.Artwork_Brand) {
              let brandName = field?.Artwork_Brand?.map((item) => item.Brand_Name).join(", ");
              field.Artwork_Brand = brandName;
            }

            return field;
          });
        }

        if (ProjectData?.length) {
          let allCol = Object.keys(ProjectData[0]);
          allCol.push("Artwork_Status");
          setAllColumnNames(reorderColumns(allCol));
        }

        let filteredPegaDataJson = localStorage.getItem("columnWiseFilterData");
        const filteredPegaData = JSON.parse(filteredPegaDataJson);

        if (filteredPegaData && filteredPegaData.length) {
          setFilters(filteredPegaData);
          setSelectedFields(filteredPegaData);
          setPegaData(ProjectData);
        } else setPegaData(ProjectData);
        if (!initialPegaData) {
          setInitialPegaData(ProjectData);
        }

        // according to pathname we need to call api and store column name in local storage
        let columnNamesJson = localStorage.getItem("allColumnNames");
        let columnNames = JSON.parse(columnNamesJson);
        if (columnNames && columnNames?.length) {
          columnNames = AcpService?.removeCommonValues(columnNames, frozenCoulmns);
          columnNames = [...frozenCoulmns, ...columnNames];
          setProjectColumnNames(columnNames);
          setUpdatedAllColumnNames(columnNames);
        } else {
          let columnNames = ProjectService.getMyProjectData();
          columnNames = AcpService?.removeCommonValues(columnNames, frozenCoulmns);
          columnNames = [...frozenCoulmns, ...columnNames];
          setUpdatedAllColumnNames(columnNames);
          setProjectColumnNames(columnNames);
          localStorage.setItem("allColumnNames", JSON.stringify(columnNames));
        }

        // get sort data from local storage and add in state
        let jsonSortingData1 = localStorage.getItem("sortingData");
        const sortingData = JSON.parse(jsonSortingData1);

        if (sortingData && sortingData.length) {
          const sortedData = onSortData(sortingData[0], sortingData[1], ProjectData);
          setPegaData(sortedData);
          setSortData([sortingData[0], sortingData[1]]);
        }
      } catch (err) {
        console.log("error", err);
      }
    })();
    setLoader(false);
  }, [myProjectList?.myProject]);

  const onSearchClick = () => {
    isSearchSet(!isSearch);
  };

  const addFrozenColumns = (name) => {
    if (!frozenCoulmns.includes(name)) {
      frozenCoulmns.push(name);
    } else {
      let columnIndex = frozenCoulmns.indexOf(name);
      frozenCoulmns.splice(columnIndex, 1);
    }
    setFrozenColumn(frozenCoulmns);
    const arr = getCustomizedView(true);
    setCustomizeViewFields(arr);
    localStorage.setItem("customizeViewFieldsMyProjects", JSON.stringify(arr));
  };

  const reorderColumns = (columns) => {
    const requiredColumnOrderArray = [
      "Project_ID",
      "Project_Name",
      "Initiative_Group_Name",
      "Project_Description",
      "BU",
      "Artwork_Category",
      "Artwork_Brand",
      "Project_region",
      "Artwork_SMO",
      "Cluster",
      "Project_Scale",
      "Project_State",
      "Buffer_To_Work",
      "Estimated_No_Of_DI",
      "Estimated_No_Of_DT",
      "Estimated_No_Of_NPF",
      "Estimated_No_Of_IQ",
      "Estimated_No_Of_PRA",
      "Estimated_No_Of_CICs",
      "Estimated_No_Of_POAs",
      "Estimated_SOS",
      "Estimated_SOP",
      "Estimated_AW_Printer",
      "Estimated_AW_Readiness",
      "IL",
      "Comments",
      "Project_Type",
      "Production_Strategy",
      "Tier",
      "Company",
      "Artwork_Status",
    ];

    let reorderedColumns = [];
    requiredColumnOrderArray.forEach((rcl) => {
      columns.forEach((cl) => {
        if (rcl === cl) {
          reorderedColumns.push(cl);
        }
      });
    });
    return reorderedColumns;
  };

  // const resetToPgDefault = async () => {
  //   const allColumnNames = [
  //     "Project_ID",
  //     "Project_Name",
  //     "Artwork_Category",
  //     "Artwork_SMO",
  //     "Project_State",
  //     "Artwork_Brand",
  //     "Buffer_To_Work",
  //     "Estimated_AW_Printer",
  //     "Artwork_Status",
  //   ];

  //   localStorage.setItem("allColumnNames", JSON.stringify(allColumnNames));
  //   localStorage.setItem("allColumnNames", JSON.stringify({}));
  //   localStorage.removeItem("isCustomViewIsAddedMyProject");
  //   setProjectColumnNames(allColumnNames);
  //   isCustomViewIsAddedMyProject(true);
  //   setVisible(false);
  // };

  const projectNameOnClick = (e, options) => {
    op.current.toggle(e);
    setSelectedColumnName(options);
  };

  const sortDataSet = (column, direction, data) => {
    const sortedData = filters?.length ? filters : pegadata;
    const sortedDataSet = AcpService.sortDataFirstStringThenNumeric(sortedData, column, direction);
    //setSelectedProdSrchList(sortedDataSet);
    setSortData([column, direction]);
  };

  const onSort = (column, direction, filterData) => {
    const sortedData = filterData?.length ? filterData : filters?.length ? filters : pegadata;
    const sortedDataSet = AcpService.sortDataFirstStringThenNumeric(sortedData, column, direction);

    setSortData([column, direction]);
    localStorage.setItem("sortingData", JSON.stringify(true));
    if (filterData?.length) {
      setFilters(sortedDataSet);
      selectedColumnList.current = sortedDataSet;
    }
    // else {
    //   setSelectedProdSrchList(sortedDataSet);
    // }
  };

  const fullKitReadinessBody = (options, rowData) => {
    let field = rowData.field;
    let projectId = options["Project_ID"];
    return (
      <>
        {field === "Project_ID" && (
          <span
            style={{ color: "#003DA5", cursor: "pointer" }}
            onClick={() => {
              if (field && field.length) {
                let option =
                  isArray(myProjectList.myProject) &&
                  myProjectList.myProject.find((project) => project.Project_ID === projectId);
                dispatch(selectedProject(option, "My Projects"));
                dispatch(SpecialProjectAction(options?.SpecialProjectFlag));

                if (option.Action === "Draft") {
                  navigate(`${currentUrl}/projectSetup/${projectId}`);
                } else {
                  navigate(`${currentUrl}/projectPlan/${projectId}`);
                }
              }
            }}
          >
            {" "}
            {options[field]}{" "}
          </span>
        )}

        {field === "Project_Name" && (
          <span
            title={options[field]}
            style={{ color: "#003DA5", cursor: "pointer" }}
            onClick={() => {
              if (field && field.length) {
                let option =
                  isArray(myProjectList.myProject) &&
                  myProjectList.myProject.find((project) => project.Project_ID === projectId);
                dispatch(SpecialProjectAction(options?.SpecialProjectFlag));

                dispatch(selectedProject(option, "My Projects"));
                if (option.Action === "Draft") {
                  navigate(`${currentUrl}/projectSetup/${projectId}`);
                } else {
                  navigate(`${currentUrl}/projectPlan/${projectId}`);
                }
              }
            }}
          >
            {options[field]}
          </span>
        )}

        {field === "Project State" && (
          <Tag
            value=""
            style={{
              color: "#003DA5",
              border: "1px solid",
              backgroundColor: "#DFEBFF",
            }}
          >
            Active
          </Tag>
        )}

        {field === "Artwork_Status" && (
          <Tag
            value="view"
            style={{
              color: "gray",
              margin: "2px 0",
              cursor: "pointer",
              border: "1px solid",
              backgroundColor: "white",
              padding: "0.15rem 0.4rem",
            }}
            onClick={() => navigate(`${currentUrl}/artworkStatus/${projectId}`)}
          ></Tag>
        )}

        {field === "Estimated_SOP" && <span title={options[field]}>{options[field]}</span>}
        {field === "Estimated_AW_Printer" && <span title={options[field]}>{options[field]}</span>}
        {field === "Buffer_To_Work" && options[field] !== "" && bufferToWork(options[field])}

        {field === "Estimated_AW_Readiness" && <span title={options[field]}>{options[field]}</span>}
        {field === "Estimated_SOS" && <span title={options[field]}>{options[field]}</span>}

        {field !== "Estimated_AW_Readiness" &&
          field !== "Estimated_AW_Printer" &&
          field !== "Artwork_Status" &&
          field !== "Buffer_To_Work" &&
          field !== "Estimated_SOP" &&
          field !== "Estimated_SOS" &&
          field !== "Project_Name" &&
          field !== "Project_ID" && (
            <>
              {" "}
              <span title={options[field]}>{options[field]}</span>
            </>
          )}
      </>
    );
  };

  // const saveAsPersonaliDefault = (selectedCategories) => {
  //   const columnNames = JSON.stringify(reorderColumns(selectedCategories));
  //   localStorage.setItem("allColumnNames", columnNames);
  //   if (frozenCoulmns.length > 0) {
  //     const columnNames = reorderColumns(selectedCategories);
  //     setAlignFrozenColumn(
  //       frozenCoulmns,
  //       setReorderedColumn,
  //       setProjectColumnNames,
  //       setTableRender,
  //       op,
  //       columnNames
  //     );
  //   } else {
  //     setProjectColumnNames(reorderColumns(selectedCategories));
  //   }
  //   if (selectedCategories.length > 9) {
  //     localStorage.setItem("isCustomViewIsAddedMyProject", JSON.stringify(true));
  //     isCustomViewIsAddedMyProject(true);
  //   }
  //   if (selectedCategories.length === 0) {
  //     isCustomViewIsAddedMyProject(true);
  //   }
  //   setVisible(false);
  // };

  const getUpdatedAllColumnFormat = (column) => {
    const finalData = column.map((el) => {
      return {
        width: 250,
        Sequence: "",
        freeze: false,
        Field_Name: el,
        reorder: false,
        Column_Name: el,
        sortAtoZ: false,
        sortZtoA: false,
        Attribute_Type: "Free Text",
      };
    });
    const data = getModifiedProjectHeaderNames(finalData);
    return data;
  };
  const onGlobalFilterChange = (e, colName) => {
    const { value, multiplesFlag, arr } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      pegadata,
      filterArray,
      selectedColumnList,
      colAllSelected,
      selectedFieldsRef
    );
    const _selectedFieldsRef =
      selectedFieldsRef?.current.length > 0 &&
      selectedFieldsRef?.current.map((el, i) => {
        if (Object.keys(arr)?.[0] === Object.keys(el)?.[0]) {
          return {
            ...el,
            [`${selectedColumnName}`]: arr[selectedColumnName],
          };
        } else {
          return el;
        }
      });
    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      localStorage.removeItem("_selectedFieldsRefCurrentMyProjects");
    }
    if (filterArray.current?.length) {
      const selectedColRes = _.uniqBy(colAllSelected?.current);
      if ((value?.length > 0 && !multiplesFlag) || selectedColRes?.length >= 1) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const filterData = AcpService.filterDataByfilters(
          pegadata,
          _selectedFieldsRefWithoutEmptyArray,
          setConditionMatched,
          "myProjects"
        );
        const convertedObj = AcpCommonService.convertArrayToObject(
          _selectedFieldsRefWithoutEmptyArray
        );
        const uniqueObj = AcpCommonService.getUniqueValuesObject(convertedObj);
        setSelectedFields(uniqueObj);
        if (filterData?.length && sortData?.length) {
          onSort(sortData[0], sortData[1], filterData);
        } else {
          setFilters(filterData);
          selectedColumnList.current = filterData;
        }
      } else {
        selectedColumnList.current = [];
        selectedFieldsRef.current = [];
        setSelectedFields({});
        setFilters([]);
      }
    } else {
      selectedColumnList.current = [];
      selectedFieldsRef.current = [];
      setSelectedFields({});
      setFilters([]);
    }
  };

  const formCustomizeObject = (availableFields, freezedColumns, selectedFeilds) => {
    const customizeViewFieldsUpdated = {
      availableFields: {
        id: "availableFields",
        title: "Available Fields",
        fieldsData: availableFields,
      },
      freezedColumns: {
        id: "freezedColumns",
        title: "freezed Columns",
        fieldsData: freezedColumns,
      },
      selectedFields: {
        id: "selectedFields",
        title: "selected Fields",
        fieldsData: selectedFeilds,
      },
    };

    return customizeViewFieldsUpdated;
  };

  const setToInitialColumns = () => {
    let availableFields;
    const selectedFeilds = ProjectService.getMyProjectData();
    availableFields = allColumnNames?.filter(function (objFromA) {
      return !selectedFeilds.find(function (objFromB) {
        return objFromA === objFromB;
      });
    });
    const customizeViewFieldsUpdated = formCustomizeObject(availableFields, [], selectedFeilds);
    setCustomizeViewFields(customizeViewFieldsUpdated);
    localStorage.setItem(
      "customizeViewFieldsMyProjects",
      JSON.stringify(customizeViewFieldsUpdated)
    );
  };

  const getCustomizedView = (isFrozen) => {
    const selectedData = customizeViewFieldsStorage?.selectedFields?.fieldsData || [];
    const freezedData = customizeViewFieldsStorage?.freezedColumns?.fieldsData || [];
    const availableData = customizeViewFieldsStorage?.availableFields?.fieldsData || [];
    let availableFields;
    if (availableData?.length > 0) {
      availableFields = availableData;
    } else {
      let updatedColumnFinal = allColumnNames?.filter(function (objFromA) {
        return !projectColumnName.find(function (objFromB) {
          return objFromA === objFromB;
        });
      });
      const intermidaiteColumn = AcpService?.removeCommonValues(
        updatedColumnFinal,
        freezedData?.length ? freezedData : frozenCoulmns
      );
      const availableColumnToSelect = AcpService?.removeCommonValues(
        intermidaiteColumn,
        selectedData?.length ? selectedData : projectColumnName
      );
      availableFields = availableColumnToSelect?.length
        ? availableColumnToSelect
        : updatedColumnFinal;
    }

    const freezedColumns = isFrozen
      ? frozenCoulmns
      : freezedData?.length
        ? freezedData
        : frozenCoulmns;

    let selectedFields = selectedData?.length ? selectedData : projectColumnName;
    selectedFields = AcpService?.removeCommonValues(selectedFields, freezedColumns);

    const customizeViewFieldsUpdated = formCustomizeObject(
      availableFields,
      freezedColumns,
      selectedFields
    );

    const column = AcpService?.removeCommonValues(
      selectedFields,
      freezedData?.length ? freezedData : frozenCoulmns
    );
    const projectColumnNamesUpdated = [...freezedColumns, ...column];
    if (!isFrozen) {
      setFrozenColumn(freezedColumns);
      setProjectColumnNames(projectColumnNamesUpdated);
      setCustomizeViewFields(customizeViewFieldsUpdated);
    }
    return customizeViewFieldsUpdated;
  };

  // const exportCSV = (selectionOnly) => {
  //   dt.current.exportCSV({ selectionOnly });
  // };

  const handleCustomizedView = (data) => {
    if (data !== undefined && data?.length !== 0) {
      const parsedData = data?.selectedFields?.fieldsData?.length
        ? data?.selectedFields?.fieldsData
        : data && JSON.parse(data);

      const freezedData = parsedData?.freezedColumns?.fieldsData || [];
      let selectedData = parsedData?.selectedFields?.fieldsData || [];
      localStorage.setItem("customizeViewFieldsMyProjects", JSON.stringify(parsedData));
      selectedData = AcpService?.removeCommonValues(selectedData, frozenCoulmns);
      selectedData = [...freezedData, ...selectedData];
      if (initialColumnLenght?.selectedFields !== selectedData?.length)
        isCustomViewIsAddedMyProject(true);
      setProjectColumnNames(selectedData);
      setCustomizeViewFields(parsedData);
      freezedData?.length > 0 && setFrozenColumn([...freezedData]);
    }
  };

  const storeReorderedColumns = (e) => {
    const dragColumnName = projectColumnName[e?.dragIndex];
    const index = projectColumnName.indexOf(dragColumnName);
    if (index > -1) {
      setReorderedColumn(true);
      projectColumnName.splice(index, 1);
      projectColumnName.splice(e?.dropIndex, 0, dragColumnName);
    }
    localStorage.setItem("allColumnNames", JSON.stringify(projectColumnName));
    setProjectColumnNames(projectColumnName);
    setTableRender(false);
  };

  const onColumnResizeEnd = (event) => {
    let jsonColumnWidthMyProject = localStorage.getItem("columnWidthMyProject");
    let columnWidthMyProject = {};
    if (jsonColumnWidthMyProject) {
      columnWidthMyProject = JSON.parse(jsonColumnWidthMyProject);
    }
    // let saveColumnWidth = {};
    // const updatedColumns = [...projectColumnName];
    // const resizedColumn = updatedColumns.find(
    //   (col) => col === event.column.props.field
    // );
    columnWidthMyProject[event.column.props.field] = event.element.offsetWidth;

    localStorage.setItem("columnWidthMyProject", JSON.stringify(columnWidthMyProject));
    localStorage.setItem("isColWidthSetMyProject", JSON.stringify(true));
    setProjectColumnNames(projectColumnName);
    setFilterCleared(false);
    setTableRender(false);
    setColWidth(true);
    setVisible(false);
  };

  const clearColumnWiseFilter = () => {
    let jsonFrozenItem = localStorage.getItem("frozenData");
    const frozenItem = JSON.parse(jsonFrozenItem);
    let _selectedColumnName = selectedColumnName;

    if (frozenItem && frozenItem?.length && frozenItem.includes(_selectedColumnName)) {
      const index = frozenItem.indexOf(_selectedColumnName);
      frozenItem.splice(index, 1);
      localStorage.setItem("frozenData", JSON.stringify(frozenItem));
      setFrozenColumn(frozenItem);
    }
    if (frozenCoulmns.includes(_selectedColumnName)) {
      const index = frozenCoulmns.indexOf(_selectedColumnName);
      frozenCoulmns.splice(index, 1);
      setFrozenColumn(frozenCoulmns);
      setProjectFrozen(!ProjectFrozen);
    }
    let jsonSortItem = localStorage.getItem("sortingData");
    const sortItem = JSON.parse(jsonSortItem);
    if (sortItem && sortItem.length && sortItem[0] === selectedColumnName) {
      localStorage.setItem("sortingData", JSON.stringify([]));
    }
    if (sortData && sortData?.length && sortData[0] === _selectedColumnName) {
      localStorage.removeItem("sortedColumn");
      //setClearAllFilter(true);
      setSortData([]);
    }

    const selectedFields2 = _.cloneDeep(selectedFields);
    const _selectedFields = AcpService.emptyProperty(selectedFields2, _selectedColumnName);
    const __selectedFields = AcpCommonService.removeEmptyArraysWithNoData(_selectedFields);
    selectedFieldsRef.current = __selectedFields;
    setSelectedFields(__selectedFields);
    if (AcpService.areAllPropertiesEmptyArrays(__selectedFields)) {
      localStorage.removeItem("_selectedFieldsRefCurrentMyProjects");
    }
    let data = [...(pegadata || [])];
    if (Object.keys(__selectedFields).length > 0) {
      Object.keys(__selectedFields).forEach((el) => {
        if (__selectedFields[el]?.length) {
          data = data.filter((ele) => __selectedFields[el].includes(ele[el]));
        }
      });
    } else {
      data = [];
    }

    if (data?.length && Object.keys(__selectedFields)?.length) {
      setFilters(data);
    } else {
      setFilters([]);
    }
    setConditionMatched(false);
  };
  const [resetKey, setResetKey] = useState(0);

  const handleChildClick = (isClicked) => {
    if (!isClicked) {
      clearFilter();
      setResetKey((prev) => prev + 1);
    }
  };
  const dynamicColumns = () => {
    if (projectColumnName.length) {
      return projectColumnName.map((ele, i) => {
        let jsonColumnWidthMyProject = localStorage.getItem("columnWidthMyProject");
        const columnWidthMyProject = JSON.parse(jsonColumnWidthMyProject);
        let checkWidth = [];
        if (columnWidthMyProject) {
          checkWidth = Object.keys(columnWidthMyProject);
        }

        return (
          <Column
            filter
            key={ele}
            field={ele}
            filterField={ele}
            alignFrozen="left"
            columnKey={ele || i}
            showFilterMenu={false}
            filterPlaceholder={ele}
            body={fullKitReadinessBody}
            frozen={frozenCoulmns.includes(ele)}
            // {... checkWidth.includes(ele) && }
            className={frozenCoulmns.includes(ele) ? "font-bold frozen-width" : ""}
            header={ProjectNameHeader(
              ele,
              frozenCoulmns,
              sortData,
              projectNameOnClick,
              selectedFields
            )}
          />
        );
      });
    }
  };

  const clearFilters = () => {
    let allCol = [];
    const allColumnNames = [
      "Project_ID",
      "Project_Name",
      "Artwork_Category",
      "Artwork_Brand",
      "Artwork_SMO",
      "Project_State",
      "Buffer_To_Work",
      "Estimated_AW_Printer",
      "Artwork_Status",
    ];
    const ProjectData = _.cloneDeep(myProjectList.myProject);
    if (ProjectData.length) {
      allCol = Object.keys(ProjectData[0]);
      allCol.push("Artwork_Status");
    }
    // let columnWidthMyProject = {};

    // localStorage.setItem("columnWidthMyProject", JSON.stringify(columnWidthMyProject));
    localStorage.setItem("customizeViewFieldsMyProjects", JSON.stringify({}));
    // localStorage.setItem("allColumnNames", JSON.stringify(allColumnNames));
    localStorage.setItem("columnWiseFilterData", JSON.stringify({}));
    // localStorage.setItem("sortingData", JSON.stringify({}));
    // localStorage.setItem("frozenData", JSON.stringify({}));
    // localStorage.removeItem("isColWidthSetMyProject");
    setProjectColumnNames(allColumnNames);
    isCustomViewIsAddedMyProject(false);
    setPegaData(initialPegaData);
    // setReorderedColumn(false);
    setFilterCleared(true);
    setSelectedFields({});
    // setTableRender(true);
    setFrozenColumn([]);
    // setColWidth(false);
    isSearchSet(false);
    setVisible(false);
    // setSortData([]);
    setFilters([]);
    setToInitialColumns();
    setResizeMode("expand");
  };

  const saveSettings = () => {
    localStorage.setItem("sortingData", JSON.stringify(sortData));
    localStorage.setItem("frozenData", JSON.stringify(frozenCoulmns));
    localStorage.setItem("columnWiseFilterData", JSON.stringify(filters));
    localStorage.setItem("allColumnNames", JSON.stringify(projectColumnName));
  };

  const clearFilter = () => {
    localStorage.setItem("customizeViewFieldsMyProjects", JSON.stringify({}));
    // localStorage.setItem("columnWiseFilterData", JSON.stringify({}));
    // localStorage.setItem("sortingData", JSON.stringify({}));
    // localStorage.setItem("frozenData", JSON.stringify({}));
    setPegaData(initialPegaData);
    setSelectedFields({});
    // setFrozenColumn([]);
    isSearchSet(false);
    // setSortData([]);
    setFilters([]);
    selectedFieldsRef.current = [];
  };
  return (
    <>
      <div className="projectTable myProjectAnddAllProjectList">
        {/* <Suspense fallback={ <Loading />}> */}
        <LoadingOverlay active={loader || loading || pegadata === null} spinner text="">
          <>
            {pegadata !== undefined && (
              <ProjectListHeader
                filterFLag={false}
                allData={pegadata}
                header={props.header}
                setVisible={setVisible}
                isTreeTableFlag={false}
                // exportCSV={exportCSV}
                headers={projectColumnName}
                CustomizeViewFlag={false}
                clearFilter={clearFilter}
                ResetToDefaultFlag={false}
                clearFilters={clearFilters}
                saveSettings={saveSettings}
                filename="My_Project_Records"
                onSearchClick={onSearchClick}
                isResetEnabled={isResetEnabled}
                isFilterEnabled={isFilterEnabled}
                onGlobalFilterKeyword={onGlobalFilterKeyword}
                selected={filters}
                selectedFields={selectedFields}
                frozenCoulmns={frozenCoulmns}
                sortData={sortData}
                onChildClick={handleChildClick}
              />
            )}
            <CustomizeView
              availableFields={[]}
              showTaskDialog={visible}
              headerName={"My Projects"}
              onClose={() => setVisible(false)}
              customizeViewFields={customizeViewFields}
              setCustomizeViewFields={handleCustomizedView}
              resetToPgDefault={() => clearFilters()}
              allColumns={getUpdatedAllColumnFormat(allColumnNames)}
            />

            {/* <ProjectListFilter
              op={op}
              onSort={onSort}
              filters={filters}
              sortData={sortData}
              pageName="My Project"
              projectData={pegadata}
              setFilters={setFilters}
              setSortData={setSortData}
              saveSettings={saveSettings}
              ProjectFrozen={ProjectFrozen}
              frozenCoulmns={frozenCoulmns}
              selectedFields={selectedFields}
              setTableRender={setTableRender}
              setFrozenColumn={setFrozenColumn}
              setProjectFrozen={setProjectFrozen}
              addFrozenColumns={addFrozenColumns}
              projectColumnName={projectColumnName}
              setReorderedColumn={setReorderedColumn}
              selectedColumnName={selectedColumnName}
              onGlobalFilterChange={onGlobalFilterChange}
              setAlignFrozenColumn={setAlignFrozenColumn}
              clearColumnWiseFilter={clearColumnWiseFilter}
              setProjectColumnNames={setProjectColumnNames}
            /> */}
            <ACPFilter
              op={op}
              onSort={onSort}
              pageName={flag}
              filters={filters}
              sortData={sortData}
              setFilters={setFilters}
              tableRender={tableRender}
              setSortData={setSortData}
              saveSettings={saveSettings}
              ProjectFrozen={ProjectFrozen}
              frozenCoulmns={frozenCoulmns}
              selectedFields={selectedFields}
              setTableRender={setTableRender}
              setFrozenColumn={setFrozenColumn}
              setProjectFrozen={setProjectFrozen}
              addFrozenColumns={addFrozenColumns}
              projectColumnName={projectColumnName}
              isReorderedColumn={isReorderedColumn}
              selectedColumnName={selectedColumnName}
              setReorderedColumn={setReorderedColumn}
              onGlobalFilterChange={onGlobalFilterChange}
              setProjectColumnNames={setProjectColumnNames}
              setSelectedColumnName={setSelectedColumnName}
              clearColumnWiseFilter={clearColumnWiseFilter}
              // acpBookingFrozenColumn={acpBookingFrozenColumn}
              projectData={pegadata}
            />

            <DataTable
              ref={dt}
              scrollable
              resizableColumns
              className="mt-3"
              reorderableColumns
              dataKey="Project_ID"
              filters={searchHeader}
              responsiveLayout="scroll"
              columnResizeMode={resizeMode}
              filterDisplay={isSearch && "row"}
              onColReorder={storeReorderedColumns}
              onColumnResizeEnd={onColumnResizeEnd}
              // key={generateUniqueKey("Project_ID")}
              onResize={(e) => console.log("resize", e)}
              value={filters.length ? filters : pegadata}
              onResizeCapture={(e) => console.log("e", e)}
              key={tableRender ? `"Project_ID" + timestamp+${resetKey}` : `${resetKey}`}
              emptyMessage={loader || loading || pegadata === null ? " " : "No records found"}
            // onValueChange={obj =>setFilteredData(obj)}
            >
              {dynamicColumns()}
            </DataTable>
          </>
        </LoadingOverlay>
        {/* </Suspense> */}
      </div>

      {!loader && pegadata && (
        <Footer
          className="footer"
          totalObjects={filters?.length ? filters?.length : pegadata?.length}
          isSelected={false}
        />
      )}
    </>
  );
};
export default ProjectList;
