import "./AcpPlanningDialog.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Form } from "react-bootstrap";
import { Calendar } from "primereact/calendar";
import _ from "lodash";
import CalendarIcon from "../../../common/CalendarIcon/CalendarIcon";
import Container from "react-bootstrap/Container";
import { updateStoreAsEmpty } from "../../../../apis/acpApi";
import AcpCloseDialog from "../AcpCloseDialog";
import {
  PlnMassTxt,
  PlnMassDateTxt,
  popupCloseMessageText,
  PlnMassCloseTxt,
  Cancel,
  SaveChnages,
  draftLabel,
  projectLabel,
  InvalidDateMsg,
  invalidReadinessDate,
} from "../../constant";
import { planningCloseProject } from "../../../../apis/acpApi";
import { AcpService } from "../../../../service/ACPService";
import { AcpCommonService } from "../../../../service/AcpCommonService";

function AcpPlanningDialog({
  showAcpPlanDialog,
  setShowAcpPlanDialog,
  selectedProject,
  selectedTask,
  setSelectedTask,
  setSelectedProject,
  setSaveChangesOption,
  setFormValid,
  setLoadTaskTable,
  setLoadProjectTable,
  setIsTaskData,
  setIsProjectData,
  activeTable,
  saveFlag,
  setSaveFlag,
}) {
  const [selectedDate, setSelectedDate] = useState("");
  const [tasks, setTasks] = useState(
    selectedTask && selectedTask?.length ? _.cloneDeep(selectedTask) : []
  );
  const [selectDropDown, setSelectDropDown] = useState("");
  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const [projectData, setProjectData] = useState(["Select Readiness Date", "Close Project"]);
  const [updatedProjectData, setUpdateProjectData] = useState(
    selectedProject && selectedProject?.length ? _.cloneDeep(selectedProject) : []
  );
  //const [readinessDates, setReadinessDates] = useState(selectedProject && selectedProject?.length ? [...selectedProject] : []);
  const [formInValid, setFormInValid] = useState(true);
  const [minDate, setMinDate] = useState();

  // const newC
  const handleSelectChnage = (e) => {
    setSelectedDate("");
    const selectVal = e?.target?.value;
    setSelectDropDown(selectVal);
    setUpdateProjectData(_.cloneDeep(selectedProject));
  };

  useEffect(() => {
    return () => {
      if (!showAcpPlanDialog) {
        setSelectedDate("");
        setUpdateProjectData([]);
        setSelectDropDown("");
        setTasks([]);
        setProjectData([]);
        setFormInValid(true);
      }
    };
  }, [showAcpPlanDialog]);

  const handleDateChangeInSummary = (options, field, value) => {
    //check if selected date is a weekend
    options.isSelected = true;
    const updatedOptions = AcpService.slackDataCalculation(options, field, value);
    const temp = structuredClone(updatedOptions);

    //tempNewStartDate to get fromDate
    //NewStartDate to get toDate

    //update isTaskData and isProjectData correctly
    if (activeTable === "TaskList") {
      setIsTaskData((prev) => {
        if (!updatedOptions.tempNewStartDate) {
          updatedOptions.tempNewStartDate = temp.NewStartDate;
          // updatedOptions.NewStartDate = value;
        } else {
          if (!updatedOptions.fromDate) {
            updatedOptions.fromDate = temp.NewStartDate;
          } else {
            updatedOptions.tempNewStartDate = updatedOptions.fromDate;
            updatedOptions.fromDate = temp.NewStartDate;
          }
        }

        if (prev.length > 0) {
          //need to test for RTA tasks as AWMTaskID is "" for RTA and valid AWMTaskID value for AWM tasks
          if (updatedOptions.AWMTaskID === "") {
            //RTA task
            if (prev.findIndex((p) => p.POANumber === updatedOptions.POANumber) !== -1) {
              const updatedData = prev.map((p) =>
                p.POANumber === updatedOptions.POANumber ? { ...p, ...updatedOptions } : p
              );

              return updatedData;
            } else {
              return [...prev, updatedOptions];
            }
          } else {
            //AWM task
            if (prev.findIndex((p) => p.AWMTaskID === updatedOptions.AWMTaskID) !== -1) {
              const updatedData = prev.map((p) =>
                p.AWMTaskID === updatedOptions.AWMTaskID ? { ...p, ...updatedOptions } : p
              );

              return updatedData;
            } else {
              return [...prev, updatedOptions];
            }
          }
        } else {
          return [updatedOptions];
        }
      });
    } else {
      setIsProjectData((prev) => {
        if (!updatedOptions.tempNewStartDate) {
          updatedOptions.tempNewStartDate = updatedOptions.NewStartDate;
          updatedOptions.NewStartDate = value;
        }
        if (prev.length > 0) {
          if (prev.find((p) => p.AWMProjectID === updatedOptions.AWMProjectID)) {
            const updatedData = prev.map((p) =>
              p.AWMProjectID === updatedOptions.AWMProjectID ? { ...p, ...updatedOptions } : p
            );
            return updatedData;
          } else {
            return [...prev, updatedOptions];
          }
        } else {
          return [updatedOptions];
        }
      });
    }
  };

  const handleClickPro = () => {
    selectedProject?.forEach((element) => {
      handleDateChangeInSummary(element, "NewStartDate", selectedDate);
    });
    if (!saveFlag) {
      setSaveFlag(true);
    }
    //resetting selectedItems to disable Action dropdown
    setSelectedProject([]);
    setShowAcpPlanDialog(false);
  };
  const handleClickTask = () => {
    selectedTask?.forEach((element) => {
      handleDateChangeInSummary(element, "NewStartDate", selectedDate);
    });
    if (!saveFlag) {
      setSaveFlag(true);
    }
    //resetting selectedItems to disable Action dropdown
    setSelectedTask([]);
    setShowAcpPlanDialog(false);
  };
  useEffect(() => {
    AcpCommonService.checkMinDatePlanning(selectedTask, setMinDate);
    setTasks(() => selectedTask?.map((item) => ({ ...item, allow: true })));
  }, [selectedTask]);

  useEffect(() => {
    setUpdateProjectData(selectedProject);
    if (selectDropDown === PlnMassCloseTxt) {
      setFormInValid(false);
      setUpdateProjectData(selectedProject.filter((el) => el.ProjectState === "Draft"));
      let drafts = selectedProject.filter((el) => el.ProjectState === "Draft");
      if (drafts.length === 0) setFormInValid(true);
    } else if (selectDropDown === PlnMassDateTxt) {
      if (selectedDate !== "") setFormInValid(false);
      else setFormInValid(true);
      setUpdateProjectData(selectedProject);
    } else {
      setFormInValid(true);
    }
  }, [selectDropDown, selectedProject, selectedDate, selectedTask]);

  useEffect(() => {
    if (selectedDate) {
      setFormInValid(false);
      if (tasks && tasks?.length > 0) {
        AcpCommonService.handleAllowDatePlanning({
          selectedDate,
          setData: setTasks,
          startDate: "StartDate",
          setSataus: setFormInValid,
        });
      } else if (updatedProjectData && updatedProjectData?.length > 0) {
        const oldprojectData = _.cloneDeep(updatedProjectData);
        const checkValidDate = AcpCommonService.checkValidProjectReadinessDate(
          selectedDate,
          oldprojectData
        );
        setFormInValid(checkValidDate.formValid);
        setUpdateProjectData(checkValidDate.projectData);
      }
    }
  }, [selectedDate]);

  const handleDateChange = (e) => {
    const selectedDate = e.value;
    setSelectedDate(selectedDate);
  };

  // EstimatedAWReadiness
  const handleSaveChanges = () => {
    setLoadTaskTable(false);
    setLoadProjectTable(false);
    setFormValid(true);
    setSelectedDate("");
    setSaveChangesOption(true);
    if (selectedProject.length > 0) {
      if (selectDropDown === PlnMassCloseTxt) {
        setShowCloseDialog(true);
        setFormValid(false);
        setSaveFlag(false);
      } else handleClickPro();
    } else {
      handleClickTask();
    }
  };

  const handleNavigationTab = async () => {
    await planningCloseProject(updatedProjectData);
    setUpdateProjectData([]);
    setLoadProjectTable(true);
    setSaveChangesOption(false);
    setFormValid(false);
    setShowAcpPlanDialog(false);
    setShowCloseDialog(false);
  };

  return (
    <Dialog
      className="acp-dialog"
      visible={showAcpPlanDialog}
      onHide={() => {
        setShowAcpPlanDialog(false);
        updateStoreAsEmpty();
      }}
      header={<div className="p-dialog-acp">{PlnMassTxt}</div>}
    >
      <Container>
        {selectedTask?.length > 0 && (
          <Row className="popup-details-planning">
            <Col sm={8}>
              <label htmlFor="taskName" className="tasksname">
                Task:
              </label>
              <div className="tasksname">
                <ul>
                  {tasks?.map((msg) => (
                    <li className="active-taskname" key={msg.TaskName}>
                      {msg.TaskName}
                      {!msg.allow && <span className="warning-text"> {InvalidDateMsg}</span>}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col sm={4}>
              <h6>New Start Date</h6>
              <div className="acp-calendar">
                <Calendar
                  showIcon={true}
                  dateFormat="d-M-y"
                  disabledDays={[0, 6]}
                  value={selectedDate}
                  minDate={minDate}
                  placeholder="New Start Date"
                  onChange={handleDateChange}
                  icon={<CalendarIcon />}
                />
              </div>
            </Col>
          </Row>
        )}
        {selectedProject?.length > 0 && (
          <Row className="popup-details-planning">
            <Col sm={8}>
              <label htmlFor="taskName" className="tasksname">
                {selectDropDown === PlnMassCloseTxt ? draftLabel : projectLabel}
              </label>

              <div className="tasksname">
                <ul>
                  {updatedProjectData?.length === 0 && <p>No Draft Projects</p>}
                  {updatedProjectData?.map((msg) => (
                    <li className="active-taskname" key={msg?.ProjectName}>
                      {msg?.ProjectName}
                      {msg?.isAllow === false && (
                        <span className="warning-text"> {invalidReadinessDate}</span>
                      )}
                      <br />
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col sm={4}>
              <h6>Select</h6>
              <div className="acp-select-box">
                <Form.Select
                  value={selectDropDown}
                  className="select-drop"
                  placeholder="Select"
                  onChange={handleSelectChnage}
                >
                  <option value="" className="select-drop-down">
                    Select
                  </option>
                  {projectData?.map((bve, index) => (
                    <option key={index} value={bve} className="select-drop-down">
                      {bve}
                    </option>
                  ))}
                </Form.Select>
              </div>
              {selectDropDown === PlnMassDateTxt && (
                <div className="acp-calendar">
                  <h6>New Readiness Date</h6>
                  <Calendar
                    showIcon={true}
                    dateFormat="d-M-y"
                    disabledDays={[0, 6]}
                    value={selectedDate}
                    placeholder="New Readiness Date"
                    //minDate={new Date()}
                    onChange={handleDateChange}
                    icon={<CalendarIcon />}
                  />
                </div>
              )}
            </Col>
          </Row>
        )}

        <div className="col-sm-12 mt-3">
          <Row className="justify-content-end">
            <Button
              type="btn"
              className="btn btn-secondary"
              onClick={() => setShowAcpPlanDialog(false)}
            >
              {Cancel}
            </Button>

            <Button
              type="btn"
              onClick={handleSaveChanges}
              disabled={formInValid}
              className="btn btn-primary ms-3"
            >
              {SaveChnages}
            </Button>
          </Row>
        </div>
      </Container>
      {showCloseDialog && (
        <AcpCloseDialog
          setShowCloseDialog={setShowCloseDialog}
          popupCloseMessageText={popupCloseMessageText}
          showCloseDialog={showCloseDialog}
          handleNavigationTab={handleNavigationTab}
        />
      )}
    </Dialog>
  );
}

export default AcpPlanningDialog;
