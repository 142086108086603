import Api from ".";
import { DEVURL } from "./envUrl";


export const submitReworkData = async (reWorkData, headers = {}) => {
    const api = new Api();
    const axiosInstance = await api.init({ headers });
    let apiURL;
    try {
        apiURL = `${DEVURL}/submitRework`;
        const reworkResponse = await axiosInstance({
            url: apiURL,
            method: "POST",
            data: reWorkData,
        });
        return reworkResponse;
    }
    catch (error) {
        console.log(error.message)
    }
};

export const updateInputReworkTask = async (payLoad, Taskkey, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
 
  let apiURL = `${DEVURL}/inputReworkTask/${Taskkey}`;
   try {
    const inputReworkResponse = await axiosInstance({
      url: apiURL,
      method: 'PATCH',
      data: payLoad,
    });
    return inputReworkResponse;
  } catch (error) {
   console.error(error.message)
  }
 
};
