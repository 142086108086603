import React from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import "./ConfirmationDialogBox.scss";

const ConfirmationDialogBox = ({
  visible,
  onHide,
  message,
  header,
  icon,
  handleContinue,
  handleCancel,
}) => {
  return (
    <ConfirmDialog
      className="p-dialog-confirm-box"
      visible={visible}
      onHide={() => onHide(false)}
      message={message}
      header={header}
      icon={icon}
      footer={
        <div>
          <Button label="Cancel" onClick={handleCancel} className="p-button-danger" />
          <Button label="Confirm" onClick={handleContinue} className="p-button-success" />
        </div>
      }
    />
  );
};

export default ConfirmationDialogBox;
