import Api from ".";
import { DEVURL, SITURL, PRODURL } from "./envUrl";

export const saveDefineRegionalDesignTemplate = async (
  formData,
  headers = {
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Origin": "http://localhost:3000/",
    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
  }
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/updateDesignTemplateJob`;
  const defineRegionalDesignTemplate = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });

  console.log("response", defineRegionalDesignTemplate);

  return true;
};

export const submitDefineRegionalDesignTemplate = async (
  formData,
  id,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;

  apiURL = `${DEVURL}/designTemplate/${id}`;

  const submitDefineRegionalDesignTemplateData = await axiosInstance({
    url: apiURL,
    method: "PATCH",
    data: formData,
  });

  return submitDefineRegionalDesignTemplateData;
};

//to be removed once proofscope handles approval--start
export const submitProofScopeApproval = async (
  formData,
  taskKey,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  // let apiURL = `${DEVURL}/approveRegionalDesignTemplate/${taskKey}`;
  // let apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/assignments/ASSIGN-WORKLIST ${taskKey}!APPROVEREGIONALDESIGNTEMPLATE_FLOW/actions/ApproveRegionalDesignTemplate`;
  let apiURL = `${DEVURL}/submitTaskApproveRDTProofscope`;
  // let apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/SubmitTask`;

  const response = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });

  console.log("response", response);

  return true;
};
//to be removed once proofscope handles approval--end
