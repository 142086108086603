import ArrowDownImg from '../../assets/images/sort.svg';
import AllProjects1 from '../../assets/images/AllProjects1.svg';
import AllTask from '../../assets/images/AllTask.svg';
import MyTaskMP from '../../assets/images/MyTaskMP.svg';
import MyProject from '../../assets/images/MyProject.svg';
import LogoutImg from '../../assets/images/logout.svg';

import plusCollapseImg from '../../assets/images/plusCollapse.svg';
// import myprojectActive from "../../assets/images/myprojectActive.svg";
import CapacityManagement from '../../assets/images/CapacityManagement.svg';

// [ 'ProjectManager', 'TaskOwner', 'ExternalTaskOwner', 'CapacityManager' ]

export const getUrl = (param) => {
  switch (param.toLowerCase()) {
    case 'myproject':
      return '/myProjects';
    case 'allproject':
      return '/allProjects';
    case 'capacitymanagement':
      return '/capacityManagement/dashboard';
    case 'alltask':
      return '/allTasks';
    case 'mytask':
      return '/myTasks';
    case 'createproject':
      return '/projectSetup';
    default:
      return '';
  }
};
export const getImage = (param) => {
  switch (param.toLowerCase()) {
    case 'myproject':
      return MyProject;
    case 'allproject':
      return AllProjects1;
    case 'capacitymanagement':
      return CapacityManagement;
    case 'alltask':
      return AllTask;
    case 'mytask':
      return MyTaskMP;
    case 'createproject':
      return plusCollapseImg;
    default:
      return '';
  }
};

// we are not using this
export const navItems = {
  CapacityManager: [
    {
      name: 'All Projects',
      img: AllProjects1,
      activeImg: AllProjects1,
      accessRoles: [],
      arrowUp: ArrowDownImg,
      url: '/allProjects',
      items: [{ name: 'All Projects', url: '/allProjects' }],
      sequence: 1,
    },
    {
      name: 'Capacity Management',
      img: CapacityManagement,
      activeImg: CapacityManagement,
      accessRoles: [],
      arrowUp: ArrowDownImg,
      url: '/capacityManagement/dashboard',
      items: [{ name: 'capacityManagement', url: '/capacityManagement/dashboard' }],
      sequence: 3,
    },
  ],
  ProjectManager: [
    {
      name: 'My Projects',
      img: MyProject,
      activeImg: AllProjects1,
      accessRoles: [],
      arrowUp: ArrowDownImg,
      url: '/myProjects',
      items: [{ name: 'My Projects', url: '/myProjects' }],
      sequence: 1,
    },
    {
      name: 'All Projects',
      img: AllProjects1,
      activeImg: AllProjects1,
      accessRoles: [],
      arrowUp: ArrowDownImg,
      url: '/allProjects',
      items: [{ name: 'All Projects', url: '/allProjects' }],
      sequence: 2,
    },
    {
      name: 'Capacity Management',
      img: CapacityManagement,
      activeImg: CapacityManagement,
      accessRoles: [],
      arrowUp: ArrowDownImg,
      url: '/capacityManagement/dashboard',
      items: [{ name: 'capacityManagement', url: '/capacityManagement/dashboard' }],
      sequence: 3,
    },

    {
      name: 'All Task',
      img: AllTask,
      activeImg: AllProjects1,
      accessRoles: [],
      arrowUp: ArrowDownImg,
      url: '/allTasks',
      items: [{ name: 'AllTask', url: '/allTasks' }],
      sequence: 5,
    },

    {
      name: 'Create Project',
      img: plusCollapseImg,
      activeImg: AllProjects1,
      accessRoles: [],
      arrowUp: ArrowDownImg,
      url: '/projectSetup',
      items: [{ name: 'Create Project', url: 'projectSetup' }],
      sequence: 5,
    },
  ],
  TaskOwner: [
    {
      name: 'All Projects',
      img: AllProjects1,
      activeImg: AllProjects1,
      accessRoles: [],
      arrowUp: ArrowDownImg,
      url: '/allProjects',
      items: [{ name: 'All Projects', url: '/allProjects' }],
      sequence: 1,
    },
    {
      name: 'Capacity Management',
      img: CapacityManagement,
      activeImg: CapacityManagement,
      accessRoles: [],
      arrowUp: ArrowDownImg,
      url: '/capacityManagement/dashboard',
      items: [{ name: 'capacityManagement', url: '/capacityManagement/dashboard' }],
      sequence: 3,
    },
    {
      name: 'My Task',
      img: MyProject,
      activeImg: AllProjects1,
      accessRoles: [],
      arrowUp: ArrowDownImg,
      url: '/myTasks',
      items: [{ name: 'My Tasks', url: '/myTasks' }],
      sequence: 4,
    },
  ],
  ExternalTaskOwner: [
    {
      name: 'My Task',
      img: MyProject,
      activeImg: AllProjects1,
      accessRoles: [],
      arrowUp: ArrowDownImg,
      url: '/myTasks',
      items: [{ name: 'My Tasks', url: '/myTasks' }],
      sequence: 5,
    },
    {
      name: 'All Task',
      img: AllTask,
      activeImg: AllProjects1,
      accessRoles: [],
      arrowUp: ArrowDownImg,
      url: '/allTasks',
      items: [{ name: 'AllTask', url: '/allTasks' }],
      sequence: 5,
    },
  ],
};

export const allMenu = [
  {
    name: 'My Projects',
    img: MyProject,
    activeImg: AllProjects1,
    accessRoles: [],
    arrowUp: ArrowDownImg,
    url: '/myProjects',
    items: [{ name: 'My Projects', url: '/myProjects' }],
    sequence: 1,
  },
  {
    name: 'All Projects',
    img: AllProjects1,
    activeImg: AllProjects1,
    accessRoles: [],
    arrowUp: ArrowDownImg,
    url: '/allProjects',
    items: [{ name: 'All Projects', url: '/allProjects' }],
    sequence: 2,
  },
  {
    name: 'Capacity Management',
    img: CapacityManagement,
    activeImg: CapacityManagement,
    accessRoles: [],
    arrowUp: ArrowDownImg,
    url: '/capacityManagement/dashboard',
    items: [{ name: 'capacityManagement', url: '/capacityManagement/dashboard' }],
    sequence: 3,
  },
  {
    name: 'My Task',
    img: MyProject,
    activeImg: AllProjects1,
    accessRoles: [],
    arrowUp: ArrowDownImg,
    url: '/myTasks',
    items: [{ name: 'My Tasks', url: '/myTasks' }],
    sequence: 4,
  },
  {
    name: 'All Task',
    img: AllTask,
    activeImg: AllProjects1,
    accessRoles: [],
    arrowUp: ArrowDownImg,
    url: '/allTasks',
    items: [{ name: 'AllTask', url: '/allTasks' }],
    sequence: 5,
  },
  {
    name: 'Create Project',
    img: plusCollapseImg,
    activeImg: AllProjects1,
    accessRoles: [],
    arrowUp: ArrowDownImg,
    url: '/projectSetup',
    items: [{ name: 'Create Project', url: 'projectSetup' }],
    sequence: 5,
  },
];

export const commonMenu = [
  {
    name: 'Log Out',
    img: LogoutImg,
    activeImg: AllProjects1,
    accessRoles: [],
    arrowUp: ArrowDownImg,
    url: '/',
    items: [{ name: 'logOut', url: '/logOut' }],
  },
];
