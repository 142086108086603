import { AcpService } from "../../service/ACPService";
import _ from "lodash";
import { formCustomizeObject } from "../../utils";
import { dsbpConstant } from "./constant";

export const notLockedPMPMessage =
  "The following PMP(s) have either incorrect AW lock gate status or PMP is already added to project.";
export const poaTriggeredMessage =
  "The following PMP(s) have either incorrect AW lock gate status, POA-A is already triggered for the selected PMP or PMP is not added to project.";

export const shellOwnerMistmach =
  "POA creation request was not sent. Please select POA’s with only one owner and submit the request again.";

export const NumberConversion = (number) => {
  const convertedNumber = String(number).padStart(2, "0");
  return convertedNumber;
};

export const generateTaskName = (Artwork_Brand, Artwork_Category, Project_Name, Artwork_SMO) => {
  let clubBrandName =
    Artwork_Brand?.length && Artwork_Brand.map((item) => item.Brand_Name).join(", ");
  let clubCategory =
    Artwork_Category?.length && Artwork_Category.map((item) => item.Category_Name).join(", ");
  let clubSMO = Artwork_SMO?.length && Artwork_SMO.map((item) => item.SMO_Name).join(", ");
  const task_Name = `${clubBrandName}_${clubCategory}_${Project_Name}${
    clubSMO ? `_${clubSMO}` : ""
  }`;
  return task_Name;
};

// AWM-701 show alerticon based on attributes values

export const checkIfAlertIcon = (item) => {
  return (
    (item && item["AWM_SnapChange_CIC"] === true) ||
    (item && item["AWM_SnapChange_FA"] === true) ||
    (item && item["AWM_SnapChange_POACreate"] === true)
  );
};

export const checkIfRedIcon = (item) => {
  return (
    (item && item["AWM_SnapChange_CIC"] === true) ||
    (item && item["AWM_SnapChange_FA"] === true) ||
    (item && item["AWM_SnapChange_POACreate"] === true)
  );
};

export const checkIfGreyIcon = (item) => {
  return (
    ((item && item["AWM_SnapChange_CIC"] === true) ||
      (item && item["AWM_SnapChange_FA"] === true) ||
      (item && item["AWM_SnapChange_POACreate"] === true)) &&
    item &&
    item["POAStatus"] === "Awaiting Response" &&
    item &&
    (item["POARequestType"] === "Update Request" || item["POARequestType"] === "Create Request")
  );
};

export const getRequestText = (
  cic,
  matrixFullkit,
  CICfullkit,
  matrix,
  requested,
  xmlRequestStatus
) => {
  switch (true) {
    //request already raised
    case cic === "Yes" && matrixFullkit && matrix && requested:
      return { name: "CIC Matrix Requested", type: "text", severity: "info", disabled: false };

    case cic === "Yes" && CICfullkit && !matrix && requested:
      return { name: "CIC Requested", type: "text", severity: "info", disabled: false };

    //request already raised but full kit condition  changed (red color text)
    case cic === "Yes" && !matrixFullkit && matrix && requested:
      return { name: "CIC Matrix Requested", type: "text", severity: "danger", disabled: false };

    case cic === "Yes" && !CICfullkit && !matrix && requested:
      return { name: "CIC Requested", type: "text", severity: "danger", disabled: false };

    //request is not raised and full kit is ready
    case cic === "Yes" && matrixFullkit && matrix && !requested && !xmlRequestStatus:
      return {
        name: "Request CIC Matrix",
        type: "raised",
        severity: "success",
        disabled: false,
        reqId: 1,
      };

    case cic === "Yes" && CICfullkit && !matrix && !requested && !xmlRequestStatus:
      return {
        name: "Request CIC",
        type: "raised",
        severity: "success",
        disabled: false,
        reqId: 2,
      };

    // request is not raised but full kit is not ready
    case cic === "Yes" && !matrixFullkit && matrix && !requested:
      return { name: "Request CIC Matrix", type: "raised", severity: "secondary", disabled: true };

    case cic === "Yes" && !CICfullkit && !matrix && !requested:
      return { name: "Request CIC", type: "raised", severity: "secondary", disabled: true };

    // Copy_XML_Request raised but RTA response not come (disabled button)
    case cic === "Yes" && matrixFullkit && matrix && !requested && xmlRequestStatus:
      return {
        name: "Request CIC Matrix",
        type: "raised",
        severity: "secondary",
        disabled: true,
        reqId: 1,
      };

    case cic === "Yes" && CICfullkit && !matrix && !requested && xmlRequestStatus:
      return {
        name: "Request CIC",
        type: "raised",
        severity: "secondary",
        disabled: true,
        reqId: 2,
      };

    default:
      return { name: "OtherCase" };
  }
};
export const deleteCicPayload = (
  deleteCICFormData,
  ProjectID,
  GABriefData,
  CDPTPageData,
  RDTData,
  IQData
) => {
  let deleteItem = {};
  deleteItem["CICMatrix"] = "";
  deleteItem["AWM_CICMatrix"] = "";
  deleteItem["Action"] = "DELETE";
  deleteItem["Task_Name"] = "Request_CIC_" + deleteCICFormData?.DSBP_PMP_PIMaterialNumber;
  deleteItem["AWM_CICNeeded"] = deleteCICFormData?.AWM_CIC_Needed;
  deleteItem["AWMProjectID"] = ProjectID;
  deleteItem["AWM_GABrief"] = deleteCICFormData?.AWM_GA_Brief;
  const AWM_GABrief_Data = GABriefData.filter(
    (data) => data.File_Name === deleteCICFormData?.AWM_GA_Brief
  );
  deleteItem["AWM_GABrief_ID"] = AWM_GABrief_Data?.[0]?.AWM_Task_ID;
  deleteItem["AWM_SupportingPMPLayout"] = deleteCICFormData.AWM_Supporting_PMP_Layout
    ? deleteCICFormData.AWM_Supporting_PMP_Layout
    : "";
  deleteItem["AWM_SupportingPMPDesign"] = deleteCICFormData.AWM_Supporting_PMP_Design
    ? deleteCICFormData.AWM_Supporting_PMP_Design
    : "";
  deleteItem["AWM_OtherReference"] = deleteCICFormData.AWM_Other_Reference
    ? deleteCICFormData.AWM_Other_Reference
    : "";

  deleteItem["DSBP_PMP_PIMaterialID"] = deleteCICFormData?.DSBP_PMP_PIMaterialID;
  deleteItem["DSBP_PMP_PIMaterialNumber"] = deleteCICFormData?.DSBP_PMP_PIMaterialNumber;
  deleteItem["DSBP_InitiativeID"] = deleteCICFormData?.DSBP_InitiativeID;

  let DSBP_CDPT_Page = [];
  let DSBP_CDPT_Page_data = [];
  if (deleteCICFormData.Color_Development_and_Print_Trial) {
    DSBP_CDPT_Page =
      CDPTPageData?.length &&
      CDPTPageData.filter(
        (cdptData) =>
          deleteCICFormData.Color_Development_and_Print_Trial.includes(
            cdptData.AWM_Design_Job_ID
          ) && cdptData
      );
    DSBP_CDPT_Page_data =
      DSBP_CDPT_Page?.length &&
      DSBP_CDPT_Page.map((item) => ({
        Design_Job_Name: item.AWM_Design_Job_Name,
        Design_Job_ID: item.AWM_Design_Job_ID,
      }));
  }
  deleteItem.DSBP_CDPT_Page = DSBP_CDPT_Page_data || [];
  //rdt
  let DSBP_RDT_Page = [];
  let DSBP_RDT_Page_data = [];
  if (deleteCICFormData.Regional_Design_Template) {
    DSBP_RDT_Page =
      RDTData?.length &&
      RDTData.filter((rdtData) => {
        if (deleteCICFormData.Regional_Design_Template.includes(rdtData.AWM_Design_Job_ID))
          return rdtData;
      });
    DSBP_RDT_Page_data =
      DSBP_RDT_Page?.length &&
      DSBP_RDT_Page.map((item) => ({
        Design_Job_Name: item.AWM_Design_Job_Name,
        Design_Job_ID: item.AWM_Design_Job_ID,
      }));
  }
  deleteItem.DSBP_RDT_Page = DSBP_RDT_Page_data || [];

  //IQ
  let DSBP_IQ_Page = [];
  let DSBP_IQ_Page_data = [];
  if (deleteCICFormData.Ink_Qualification) {
    DSBP_IQ_Page =
      IQData?.length &&
      IQData.filter((iqData) => {
        if (deleteCICFormData.Ink_Qualification.includes(iqData.AWM_Design_Job_ID)) return iqData;
      });
    DSBP_IQ_Page_data =
      DSBP_IQ_Page?.length &&
      DSBP_IQ_Page.map((item) => ({
        Design_Job_Name: item.AWM_Design_Job_Name,
        Design_Job_ID: item.AWM_Design_Job_ID,
      }));
  }
  deleteItem.DSBP_IQ_Page = DSBP_IQ_Page_data || [];

  return [deleteItem];
};

export const createPMPObject = (inputString) => {
  if (AcpService.isUBN(inputString)) {
    return {
      PIMaterialNumber: "",
      PIMaterialID: "",
      DSBP_InitiativeID: "",
    };
  } else {
    const processedArray = inputString?.split("-")?.map((item) => item?.trim());
    return {
      PIMaterialNumber: processedArray[0],
      PIMaterialID: processedArray[1],
      DSBP_InitiativeID: processedArray[2],
    };
  }
};

export const isObjectSame = (oldcols, newCols) => {
  const getfields = oldcols?.map((item) => item?.field);
  const getkeysFromCols = Object?.values(newCols);
  const getkeysFromOldCols = Object?.values(getfields);

  if (isArrayValuesSame(getkeysFromCols, getkeysFromOldCols)) {
    return true;
  } else {
    return false;
  }
};

const isArrayValuesSame = (arr1, arr2) => {
  // Step 1: Quick check - if lengths differ, arrays are not equal
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  // Step 2: Create a frequency map for the first array using Map
  const frequencyMap = new Map();
  for (let item of arr1) {
    frequencyMap.set(item, (frequencyMap.get(item) || 0) + 1);
  }

  // Step 3: Decrease frequency for each element in the second array
  for (let item of arr2) {
    if (!frequencyMap.has(item)) {
      return false; // Item not found in the map
    }
    let count = frequencyMap.get(item);
    if (count === 1) {
      frequencyMap.delete(item); // Remove item if count drops to 0
    } else {
      frequencyMap.set(item, count - 1);
    }
  }

  // If map is empty, arrays were equal
  return frequencyMap.size === 0;
};

export const removeDuplicatesFromArray = (arr1, arr2) => {
  // Step 1: Create a Set from the second array for O(1) lookups
  const set2 = new Set(arr2);

  // Step 2: Filter elements from arr1 that do not exist in arr2
  return arr1?.filter((item) => !set2.has(item));
};

// Function to find the Attribute_List for a specific Region_Name and BU_Name
export const getAttributesForBU = (data, regionName, buName) => {
  // Step 1: Find the region with the specified name
  const region = data?.Artwork_Region?.find((r) => r?.Region_Name === regionName);

  if (region) {
    // Step 2: Find the BU under the selected region
    const bu = region?.Artwork_BU?.find((bu) => bu?.BU_Name === buName);

    if (bu && bu?.Attribute_List) {
      return bu?.Attribute_List;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

//code fix 3355 : Refactored the code to display the column names in both tabular and tab by tab view
export const getRequestCICMatrixUpdatedData=(
  AWM_CIC_Matrix,
  AWM_CIC_Requested,
  AWM_CIC_Matrix_Requested,
  FullKit_Condition_Checking_Page,
  Copy_XML_Request_Status,
  AWM_CIC_Needed)=>{

  let isCICMatrix = AWM_CIC_Matrix !== "" ? true : false;
  let isalreadyRequested =
    AWM_CIC_Matrix_Requested === "Yes" || AWM_CIC_Requested === "Yes";
  // change this condition for development to check fullkit is ready or not
  let isCICRequestFullKitSatisfied =
    FullKit_Condition_Checking_Page?.[0]?.CIC_FullKit_Satisfied === "Yes" ||
    FullKit_Condition_Checking_Page?.[1]?.CIC_FullKit_Satisfied === "Yes"
      ? true
      : false;
  // change this condition for development to check fullkit is ready or not
  let isCICMatrixRequestFullKitSatisfied =
    FullKit_Condition_Checking_Page?.[1]?.CICMatrix_FullKit_Satisfied === "Yes" ||
   FullKit_Condition_Checking_Page?.[0]?.CICMatrix_FullKit_Satisfied === "Yes"
      ? true
      : false;

  //Copy_XML_Request_Status
  let xmlRequestStatus =
    Copy_XML_Request_Status?.toLowerCase() === dsbpConstant?.xml_awaiting_response;
  let resp = getRequestText(
    AWM_CIC_Needed,
    isCICMatrixRequestFullKitSatisfied,
    isCICRequestFullKitSatisfied,
    isCICMatrix,
    isalreadyRequested,
    xmlRequestStatus
  );
  return resp;
}
export const transformDependencyData = (
  dependencyItem,
  isRDTData,
  isCDPTData,
  isIQData,
  isGABrifData
) => {
  if (!dependencyItem) {
    return {}; // Return empty object if no dependency item
  }

  let transformedItem = {
    DSBP_InitiativeID: dependencyItem?.DSBP_InitiativeID,
    DSBP_PMP_PIMaterialDescription: dependencyItem?.DSBP_PMP_PIMaterialDescription,
    DSBP_PMP_PIMaterialID: dependencyItem?.DSBP_PMP_PIMaterialID,
    DSBP_PMP_PIMaterialNumber: dependencyItem?.DSBP_PMP_PIMaterialNumber,
    Copy_XML_Request_Status: dependencyItem?.Copy_XML_Request_Status,
    Copy_XML_ResponseCode: dependencyItem?.Copy_XML_ResponseCode,
    Copy_XML_ResponseMessage: dependencyItem?.Copy_XML_ResponseMessage,
    Copy_XML_UserSuggestions: dependencyItem?.Copy_XML_UserSuggestions,
    POAStatus: dependencyItem?.POAStatus,
    POARequestType: dependencyItem?.POARequestType,
  };

  // Regional Design Template
  if (dependencyItem?.Preselected_AWM_RDT_Page || (isRDTData && isRDTData?.length)) {
    transformedItem.Regional_Design_Template =
      dependencyItem?.Preselected_AWM_RDT_Page?.map((item) => item) || [];
  }

  // Color Development and Print Trial
  if (dependencyItem?.Preselected_AWM_CDPT_Page || (isCDPTData && isCDPTData?.length)) {
    transformedItem.Color_Development_and_Print_Trial =
      dependencyItem?.Preselected_AWM_CDPT_Page?.map((item) => item) || [];
  }

  // Ink Qualification
  if (dependencyItem?.Preselected_AWM_IQ_Page || (isIQData && isIQData?.length)) {
    transformedItem.Ink_Qualification =
      dependencyItem?.Preselected_AWM_IQ_Page?.map((item) => item) || [];
  }

  // GA Brief
  if (dependencyItem?.Preselected_GABrief_Page || (isGABrifData && isGABrifData?.length)) {
    transformedItem.AWM_GA_Brief =
      dependencyItem?.Preselected_GABrief_Page?.[0]?.AWM_GABrief || " ";
  }
   const updatedAWM_CIC_Matrix_Requested=getRequestCICMatrixUpdatedData(
    dependencyItem?.AWM_CIC_Page?.[0]?.AWM_CIC_Matrix || "",
    dependencyItem?.AWM_CIC_Page?.[0]?.AWM_CIC_Requested || "",
    dependencyItem?.AWM_CIC_Page?.[0]?.AWM_CIC_Matrix_Requested || "",
    dependencyItem?.FullKit_Condition_Checking_Page,
    dependencyItem?.Copy_XML_Request_Status,
    dependencyItem?.AWM_CIC_Page?.[0]?.AWM_CIC_Needed || ""
    )
     const finalAWM_CIC_Matrix_RequestedValue= (dependencyItem?.AWM_CIC_Page?.[0]?.AWM_CIC_Needed==="" || 
     updatedAWM_CIC_Matrix_Requested?.name === "OtherCase") ? "" :updatedAWM_CIC_Matrix_Requested?.name
  transformedItem = {
    ...transformedItem,
    AWM_CIC_Needed: dependencyItem?.AWM_CIC_Page?.[0]?.AWM_CIC_Needed || "",
    AWM_CIC_Needed1: dependencyItem?.AWM_CIC_Page?.[0]?.AWM_CIC_Needed || "",
    AWM_Supporting_PMP_Layout: dependencyItem?.AWM_CIC_Page?.[0]?.AWM_Supporting_PMP_Layout || "",
    AWM_Supporting_PMP_Design: dependencyItem?.AWM_CIC_Page?.[0]?.AWM_Supporting_PMP_Design || "",
    AWM_Other_Reference: dependencyItem?.AWM_CIC_Page?.[0]?.AWM_Other_Reference || "",
    AWM_CIC_Matrix: dependencyItem?.AWM_CIC_Page?.[0]?.AWM_CIC_Matrix || "",
    AWM_CIC_Matrix_Requested: finalAWM_CIC_Matrix_RequestedValue,
    AWM_CIC_Requested: dependencyItem?.AWM_CIC_Page?.[0]?.AWM_CIC_Requested || "",
    AWM_CIC_Matrix_Toggle: dependencyItem?.AWM_CIC_Page?.[0]?.AWM_CIC_Matrix_Toggle || "",
    AWM_SnapChange_FA: dependencyItem?.AWM_SnapChange_FA,
    AWM_SnapChange_CIC: dependencyItem?.AWM_SnapChange_CIC,
    AWM_SnapChange_Editable: dependencyItem?.AWM_SnapChange_Editable,
    AWM_SnapChange_POACreate: dependencyItem?.AWM_SnapChange_POACreate,
    AWM_SnapChange_POAUpdate: dependencyItem?.AWM_SnapChange_POAUpdate,
    PICountry_Countries: dependencyItem?.PICountry_Countries,
    DSM_PMP_PMPPackagingComponentType: dependencyItem?.DSM_PMP_PMPPackagingComponentType,
    DSM_PMP_PackagingSize: dependencyItem?.DSM_PMP_PackagingSize,
    DSM_PMP_PrinterPrimary: dependencyItem?.DSM_PMP_PrinterPrimary,
    DSM_PMP_PrinterSecondary: dependencyItem?.DSM_PMP_PrinterSecondary,
    DSM_PMP_PrintingProcess: dependencyItem?.DSM_PMP_PrintingProcess,
    DSM_PMP_SubstrateColor: dependencyItem?.DSM_PMP_SubstrateColor,
    DSM_PMP_TD_TDStatus: dependencyItem?.DSM_PMP_TD_TDStatus,
    DSM_PMP_TD_TDValue: dependencyItem?.DSM_PMP_TD_TDValue,
    DSM_PO_PMP_poMaterialNumber: dependencyItem?.DSM_PO_PMP_poMaterialNumber,
    FullKit_Condition_Checking_Page: dependencyItem?.FullKit_Condition_Checking_Page,
    RTA_POANumber: dependencyItem?.RTA_POANumber,
    RTA_POADescription: dependencyItem?.RTA_POADescription,
    CICTaskName: dependencyItem?.CICTaskName,
    CICTaskAssignee: dependencyItem?.CICTaskAssignee,
    CICTaskAssigneeCompany: dependencyItem?.CICTaskAssigneeCompany,
    DSBP_PMP_FPC_SubBrand: dependencyItem?.FPCStagingPage?.[0]?.DSBP_FPC_SubBrand,
    DSBP_PMP_FPC_Category: dependencyItem?.FPCStagingPage?.[0]?.Category,
    DSBP_PMP_FPC_flavorScentDet: dependencyItem?.FPCStagingPage?.[0]?.DSBP_FPC_flavorScentDet,
    DSM_FPP_ProductForm: dependencyItem?.FPCStagingPage?.[0]?.DSM_FPP_ProductForm,
    DSBP_FPC_packagingSite: dependencyItem?.FPCStagingPage?.[0]?.DSBP_FPC_packagingSite,
    PILanguage_Languages: dependencyItem?.PILanguage_Languages,
    DSBP_PMP_FPC_CrossSell: dependencyItem?.FPCStagingPage?.[0]?.DSBP_FPC_crossSell,
    DSBP_PMP_FPC_ConsumerBenefitOne: dependencyItem?.FPCStagingPage?.[0]?.ConsumerBenefitOne,
    DSBP_PMP_FPC_ConsumerBenefitTwo: dependencyItem?.FPCStagingPage?.[0]?.ConsumerBenefitTwo,
    DSBP_PMP_FPC_ConsumerBenefitThree: dependencyItem?.FPCStagingPage?.[0]?.ConsumerBenefitThree,
    DSBP_PMP_FPC_ConsumerBenefitSeven: dependencyItem?.FPCStagingPage?.[0]?.ConsumerBenefitSeven,
    DSBP_PMP_syntheticPerfume: dependencyItem?.FPCStagingPage?.[0]?.SyntheticPerfumeFlag,
    DSBP_PMP_DPP: dependencyItem?.FPCStagingPage?.[0]?.Formula_DPP,
    AWM_CIC_Matrix_Requested1: dependencyItem?.AWM_CIC_Page?.[0]?.AWM_CIC_Matrix_Requested || "",
    //AWM_CIC_Matrix_Requested  value is copied to AWM_CIC_Matrix_Requested1
    AWM_CICDetails: dependencyItem?.CICDetails || {},
    updated: false,
  };

  return transformedItem;
};

export const updateTableData = (tableData, editedData, uniqueKey) => {
  editedData?.forEach((editedItem) => {
    // Iterate over each item in tableData
    tableData?.forEach((item, index) => {
      // Check if the UniqueID matches
      if (item?.[uniqueKey] === editedItem?.[uniqueKey]) {
        // Update each property of the matching item
        Object.keys(editedItem)?.forEach((key) => {
          if (
            key === "ReasonforRejection" ||
            key === "AWM_ReasonforRejection" ||
            key === "RejectionComment" ||
            key === "AWM_RejectionNotes"
          ) {
            if (editedItem?.AWM_AddedToProject !== "Reject") {
              tableData[index]["AWM_ReasonforRejection"] = "";
              tableData[index]["AWM_RejectionNotes"] = "";
            } else {
              tableData[index]["AWM_ReasonforRejection"] = editedItem?.ReasonforRejection;
              tableData[index]["AWM_RejectionNotes"] = editedItem?.RejectionComment;
            }
          } else {
            tableData[index][key] = editedItem?.[key];
          }
        });
      }
    });
  });

  return tableData; // return the updated tableData
};

export function overrideFilteredData(data, filteredData) {
  const dataMap = new Map();

  // Create a map with a composite key from `data`
  data?.forEach(item => {
    const key = `${item?.DSBP_PMP_PIMaterialNumber}_${item?.DSBP_PMP_PIMaterialID}_${item?.DSBP_InitiativeID}`;
    dataMap.set(key, item);
  });

  // Replace objects in `filteredData` if a match is found in `data`
  return filteredData?.map(item => {
    const key = `${item?.DSBP_PMP_PIMaterialNumber}_${item?.DSBP_PMP_PIMaterialID}_${item?.DSBP_InitiativeID}`;
    return dataMap.has(key) ? dataMap.get(key) : item;
  });
}
export const pgbuildDefaultColumns=(projectData,dsbpPmpData,allBUAttributes)=>{
    let buWiseSortedColumnNames = [];
    if (projectData?.BU && projectData?.Region) {
      let regionData = getAttributesForBU(
        allBUAttributes?.ArtWorkProjectSetupPage,
        projectData?.Region,
        projectData?.BU
      );
      let attributeList = [...regionData];
      if (attributeList && attributeList.length) {
        buWiseSortedColumnNames = [...attributeList].sort((a, b) => {
          return a.Sequence - b.Sequence;
        });
      }
    }
    let availableField = dsbpPmpData
      ? Object.keys(dsbpPmpData[0] || {})
          .filter((key) => buWiseSortedColumnNames?.every((item) => item?.Field_Name !== key))
          .map((fieldName) => ({
            Field_Name: fieldName,
          }))
      : [];
    if (!projectData?.BU === "Home Care" && availableField && availableField?.length) {
      let availableFieldsUpdated = [];
      availableField?.forEach((field) => {
        if (
          field?.Field_Name !== "AWM_Biocide" &&
          field?.Field_Name !== "AWM_AISE" &&
          field?.Field_Name !== "AWM_Sellable"
        ) {
          availableFieldsUpdated.push(field);
        }
      });
      availableField = availableFieldsUpdated?.length > 0 && availableFieldsUpdated;
    }
    const availableColumnNamesData = availableField
      ? availableField.map((item) => item.Field_Name)
      : [];
    const selectedFieldsNames = buWiseSortedColumnNames
      ? buWiseSortedColumnNames.map((item) => item.Field_Name)
      : [];

    const customizeViewFieldsUpdated = formCustomizeObject(
      availableColumnNamesData,
      [],
      selectedFieldsNames
    );
    return customizeViewFieldsUpdated
  }
export const dependencyDefaultColumns=(groupedColumnNames)=>{
    let availableField = [];
    const selectedFieldsNames = groupedColumnNames
      ? groupedColumnNames.map((item) => item.field)
      : [];

    const customizeViewFieldsUpdated = formCustomizeObject(
      availableField,
      [],
      selectedFieldsNames
    );
    return customizeViewFieldsUpdated
  }