import Api from ".";
import { DEVURL } from "./envUrl";


export const submitReworkData = async (reWorkData, headers = {}) => {
    const api = new Api();
    const axiosInstance = await api.init({ headers });
    let apiURL;
    try {
        apiURL = `${DEVURL}/submitRework`;
        const reworkResponse = await axiosInstance({
            url: apiURL,
            method: "POST",
            data: reWorkData,
        });
        return reworkResponse;
    }
    catch (error) {
        console.log(error.message)
    }
};

export const updateInputReworkTask = async (payLoad, Taskkey, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
 
  let apiURL = `${DEVURL}/inputReworkTask/${Taskkey}`;
   try {
    const inputReworkResponse = await axiosInstance({
      url: apiURL,
      method: 'PATCH',
      data: payLoad,
    });
    return inputReworkResponse;
  } catch (error) {
   console.error(error.message)
  }
 
};

export const getReworkData = async (
  projectId,
  headers = {
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
  }
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/rework/${projectId}`;
  try {
    const response = await axiosInstance({
      url: apiURL,
      method: "GET",
    });
    // Check for successful response status, e.g., 200 OK
    if (response?.status === 200) {
      const reworkData = response?.data?.ArtworkAgilityProjects;
      const reworkHistoryData=response?.data?.ReasonForReworkList;
      // return projectPlanData;
      return {reworkData,reworkHistoryData, projectState: response?.data?.ProjectState, responseStatus: response?.status};
    }
  } catch (error) {
    if (error.message.includes('net::ERR_CONNECTION_TIMED_OUT')) {
      // Handle the server being down
      return { error: 'The server is currently unavailable. Please try again later.' };
    } else {
      console.error("Error fetching project plan:", error);
      throw error; // Rethrow the error for other types of errors
    }
  }
};