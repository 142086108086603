export const hallowGreyCircle = "Fullkit not ready, task start date > today";
export const hallowRedCircle = "Fullkit not ready, task start date =< today";
export const inProgressLate = "In-progress - late";
export const redCross = "Job rejected - until Rework is initiated";
export const greenV = "Job completed";
export const hallowGreenCircle = "Fullkit ready";
export const solidGreenCircle = "In-progress (fullkit was already ready)";
export const NA = "Design: No design stage for this FA [or] CIC: CIC Needed = 'No' or 'NA'";

export const UnallocatedHallowGreyCircle = "Stage not started";
export const UnallocatedSolidGreenCircle = "Stage in progress";
export const UnallocatedNA = "Not Applicable";

export const exportExcelColumnNames = [
  "DSBP InitiativeID",
  "DSBP Initiative Name",
  "RTA ProjectID",
  "RTA Project Name",
  // 'SI.No',
  "PIMaterialNumber",
  "PIMaterialID",
  "PIMaterialDescription",
  "POA Number",
  "POA Description",
  "B:W",
  "B|W",
  "ETA",
  "Req",
  "Group",
  "Design",
  "CIC",
  "Copy",
  "FA",
  "POA Status",
  "TD State",
  "PMP State",
  "Country Of Sales",
  "FA Assembly",
  "FA Approval (A/A/R)",
];
