export const columns = [
  { field: "ProjectName", header: "Project Name" },
  { field: "TaskName", header: "Task Name" },
  { field: "TaskType", header: "Task Type" },
  { field: "Dependency", header: "Dependency" },
  { field: "FullKit", header: "Full Kit" },
  { field: "FullkitState", header: "Fullkit State" },
  { field: "StartDate", header: "Start Date" },
  { field: "EndDate", header: "End Date" },
  { field: "Duration", header: "Task Duration" },
  { field: "Assignee", header: "Owner" },
  { field: "Supplier", header: "Supplier" },
  { field: "PlannedRelease", header: "Planned Release" },
  { field: "PrinterDate", header: "Art@Printer Date" },
  { field: "Buffer", header: "Buffer:Work" },
  { field: "BufferToWork ", header: "Buffer To Work" },
  { field: "RemainingWork ", header: "Remaining Work " },
  { field: "BVELoopResponse", header: "BVE Loop Response" },
];

export const planningColumns = [
  { field: "AWMProjectID", header: "Project ID" },
  { field: "ProjectName", header: "Project Name" },
  { field: "Assignee", header: "Owner" },
  { field: "TaskName", header: "Task Name" },
  { field: "StartDate", header: "Current Start Date" },
  { field: "EndDate", header: "End Date" },
  { field: "Slack", header: "Slack" },
  { field: "Buffer", header: "Buffer:Work" },
  { field: "BufferToWork ", header: "Buffer To Work" },
];

export const bveColumns = [
  { field: "TaskName", header: "Task Name" },
  { field: "ProjectName", header: "Project Name" },
  { field: "TaskType", header: "Task Type" },
  { field: "Status", header: "Status" },
  { field: "FullKit", header: "Full Kit" },
  { field: "StartDate", header: "Start Date" },
  { field: "Assignee", header: "Assignee" },
  { field: "EndDate", header: "End Date" },
  { field: "BVETaskEndDate", header: "BVE End Date" },
  { field: "BVETaskStatus", header: "BVE Task Status" },
  { field: "BVEComment", header: "BVE Comment" },
  { field: "Role", header: "Role" },
  { field: "Duration", header: "Task Duration" },
  { field: "BVE", header: "BVE" },
  { field: "PlannedRelease", header: "Planned Release" },
  { field: "PrinterDate", header: "Art@Printer Date" },
  { field: "Buffer", header: "Buffer" },
  { field: "Buffer", header: "Buffer:Work" },
];

export const rowGroups = [
  { order: 1, id: "ReadytoBeSent", name: "Ready to be Sent" },
  { order: 2, id: "Planned", name: "Planned" },
  { order: 3, id: "Backlog", name: "Backlog" },
  { order: 4, id: "Reworks", name: "Reworks" },
  { order: 5, id: "PendingStudioResponse", name: "Pending Studio Response" },
];

export const rowGroupsForBVELoop = [
  { order: 1, id: "On Track", name: "On Track" },
  { order: 2, id: "Off Track", name: "Off Track" },
];

export const AWM_Biocide = "AWM_Biocide";
export const AWM_AISE = "AWM_AISE";
export const AWM_Sellable = "AWM_Sellable";

export const exportExcelColumnNames = [
  "Project Name",
  "Task Name",
  "Task Type",
  "Dependency",
  "Full Kit",
  "Fullkit State",
  "Start Date",
  "End Date",
  "Task Duration",
  "Owner",
  "Supplier",
  "Planned Release",
  "Art@Printer Date",
  "Buffer:Work",
  "Buffer To Work",
  "Remaining Work",
  "BVE Loop Response",
];

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const monthsMap = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};
export const monthsMapFullName = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  Septemper: "09",
  October: "10",
  November: "11",
  December: "12",
};

export const ranges = [{ "1D": 1 }, { "3D": 3 }, { "7D": 7 }, { "14D": 14 }];
export const popupCloseMessageText =
  "You are about to close the projects. This action is irreversible. Do you want to continue?";
export const PlnMassTxt = "Mass Update";
export const PlnMassDateTxt = "Select Readiness Date";
export const PlnMassCloseTxt = "Close Project";
export const Yes = "Yes";
export const No = "No";
export const Close = "Close";
export const Cancel = "Cancel";
export const SaveChnages = "Save Changes";
export const chartClearSelectionButtonLabel = "You are viewing : ";
export const SaveChangesConfirmation =
  "If you leave this section, your changes will not be saved. Do you want to continue?";
export const defaultCapacity = 0;
export const newStartDate = "New Start Date";
export const massUpdate = "Mass Update";
export const ProjectName = "Project Name:";
export const SaveChanges = "Save Changes";
export const clearAllfilter = "Clear all filter";
export const sortZtoA = "Sort z to a";
export const SortAtoZ = "Sort a to z";
export const Frozen = "Frozen";
export const Freeze = "Freeze";
export const NewStartDatesSaved = localStorage.getItem("NewStartDatesSaved");
export const draftLabel = "Draft Project Names";
export const projectLabel = "Project Names";
export const warningMsg =
  "If you leave this section, your changes will not be saved. Do you want to continue?";
export const CustomizeView = "Customize View";
export const AcceptBVEEndDate = "Accept BVE End Date";
export const NoOfActiveTasks = "No. of Active Tasks";
export const SelectSupplier = "Select Supplier";
export const CD = "C/D";

export const rowGroupsForDashboard = [
  { order: 1, id: "BookingAlerts2weeks", name: "Booking Alerts (14days)" },
  { order: 2, id: "MediumTerm2to8weeks", name: "Medium Term (2-8 weeks)" },
  { order: 3, id: "LongTermMoreThanTwoMonths", name: "Long Term (>2 Months)" },
  { order: 4, id: "RowWithoutSubHeader", name: "Row Without Sub Header" },
]

export const UpdatePM = "Update PM";
export const Configuration = "Configuration";
export const ManageCapacity = "Manage Capacity";
export const UploadLongTermPlan = "Upload Long Term Plan";
export const ProjectsRequiringPMAssignment = "Projects Requiring PM Assignment";
export const CapacityToDemandAlertsExternal = "Capacity to Demand Alerts - External";
export const PmName = "PM Name"
export const Import = "Import"
export const clearColumnfilter = "Clear Column filter";
export const Records = "Records";
export const Selected = "Selected";
export const InvalidDateMsg = "Selected New Start date Is Invalid For This Task";

