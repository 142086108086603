/* eslint-disable import/no-anonymous-default-export */
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes.js";
import { publicRoutes, renderProtectedRoutes } from "./routes.js";
import { hasPublicRoutes, hasRequiredAuthData } from "../Utils/helpers.js";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { getUserDetails } from "../apis/getUserDetails.js";
import { navigateBasedOnRole } from "../utils";
export default () => {
  const [isNavigating, setIsNavigating] = useState(false);
  const navigate = useNavigate();
  const isValid = hasRequiredAuthData();
  const location = useLocation();

  const { REACT_APP_AUTHORIZED_URL } = process.env;

  if (isEmpty(REACT_APP_AUTHORIZED_URL)) {
    throw new Error("Missing env file");
  }

  useEffect(() => {
    if (!hasPublicRoutes() && location.pathname !== "/") {
      localStorage.setItem("currentRoute", location.pathname);
    }
    // //Removing the current Route when user closes the browser
    const handleBeforeUnload = () => {
      localStorage.removeItem("currentRoute");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location?.pathname]);

  //3240 - blank page after login to application
  //worked by Gautam and Asha
  const redirectUser = async (navigate) => {
    const userid = localStorage.getItem("userId");
    const userDetails = await getUserDetails(userid);
    if (!isEmpty(userDetails)) {
      let navigateUrl = navigateBasedOnRole(userDetails.AccessGroup);
      localStorage.setItem("navigateUrlBasedOnRole", navigateUrl);
      localStorage.setItem("currentRoute", navigateUrl);
      navigate(navigateUrl);
    }
  };

  useEffect(() => {
    let currentRoute = localStorage.getItem("currentRoute");
    const navigateUrlBasedOnRole = localStorage.getItem("navigateUrlBasedOnRole");
    currentRoute =
      !isEmpty(currentRoute) && currentRoute !== "/undefined"
        ? currentRoute
        : navigateUrlBasedOnRole;

    if (currentRoute?.includes("capacityManagement")) {
      currentRoute = "/capacityManagement/dashboard";
    }

    // console.info('%cHave a valid data in localStorage:', 'color: blue;', isValid)
    // console.info("%cCurrent route is:", currentRoute, isValid);

    if (!hasPublicRoutes() && !isEmpty(currentRoute) && isValid) {
      setIsNavigating(true);
      navigate(currentRoute);
    } else {
      if (!hasPublicRoutes() && isValid && !currentRoute) {
        setIsNavigating(true);
        redirectUser(navigate);
      }
    }
  }, [isValid]);

  return (
    <Routes>
      <Route path="/*" element={<ProtectedRoutes />} />

      {publicRoutes?.map((item, key) => (
        <Route path={item.path} element={item.element} key={`other-routes-${key}`} />
      ))}

      {isValid && isNavigating && renderProtectedRoutes()}
    </Routes>
  );
};
