import React, { useState, useEffect } from "react";
import "../CapacityManagementPlaningHeader.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../index.scss";
import { defaultCapacity, monthsMap } from "../../constant";
import { AcpService } from "../../../../service/ACPService";
import { fetchBookingCapacities, getPlanningProjectLists } from "../../../../apis/acpApi";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { createDraftChanges } from "../../../../service/ACPplanningService";

const CdNavigation = ({
  filters,
  activeTable,
  isTaskData,
  isProjectData,
  task,
  supplierValue,
  PM,
  UserGroup,
  reset,
  setIsTaskData,
  setIsProjectData,
}) => {
  const [activeSection, setActiveSection] = useState("C-D");
  const [capacityTableValues, setCapacityTableValues] = useState([]);
  const [demandTableValues, setDemandTableValues] = useState([]);
  const [cdTableValues, setCDTableValues] = useState([]);

  const [newHeaderValue, setNewHeaderValue] = useState([]);
  const [localRanges, setLocalRanges] = useState([]);
  const [capacityData, setCapacityData] = useState([]);
  const [demandData, setDemandData] = useState([]);
  const [applyFilters, setApplyFilters] = useState(false);
  const [initialDemand, setInitialDemand] = useState([]);

  // states keeping old data for Task List
  const [demandDataUpdated, setDemandDataUpdated] = useState([]);
  const [duplicateData, setDuplicateData] = useState([]);

  // states keeping old data for project List
  const [demandDataProjectUpdated, setDemandDataProjectUpdated] = useState([]);
  const [duplicateDataProject, setDuplicateDataProject] = useState([]);

  const { acpPlanningData, acpPlanningProjectListData, taskDraftChanges, projectDraftChanges } =
    useSelector((state) => state?.AcpPlanningReducer);

  const query = AcpService.useQuery();
  const { endDateQueryParams, dateTypeQueryParams, startDateQueryParams } =
    AcpService.decodedParams(query);

  const applyTaskFilter = (filteredData) => {
    let res;
    if (task) {
      if (task !== "All Tasks") {
        if (task?.toLowerCase()?.includes("cic")) {
          res = filteredData?.filter((ele) => ele?.TaskType?.toLowerCase()?.includes("cic"));
        } else if (task?.toLowerCase()?.includes("assembly")) {
          res = filteredData?.filter((ele) => ele?.TaskType?.toLowerCase()?.includes("assembly"));
        }
      } else {
        res = filteredData;
      }
    } else {
      // no task assigned yet
      res = filteredData;
    }
    if (res) {
      return res;
    }
  };

  const applyTaskAndSupplierFilter = (data) => {
    let filteredData;
    if (supplierValue) {
      //if supplier exists
      if (supplierValue !== "Select Supplier") {
        if (supplierValue === "NA") {
          filteredData = data?.filter((ele) => ele.Supplier?.toLowerCase() === "");
          return applyTaskFilter(filteredData);
        } else {
          filteredData = data?.filter(
            (ele) => ele.Supplier?.toLowerCase() === supplierValue?.toLowerCase()
          );
          return applyTaskFilter(filteredData);
        }
      } else {
        // value of Select Supplier
        return applyTaskFilter(data);
      }
    } else {
      // no supplier set yet
      return applyTaskFilter(data);
    }
  };
  const setDemandDataValues = () => {
    let taskListdemands = acpPlanningData?.filter(
      (taskDetails) => !AcpService.isWeekendUTC(taskDetails?.Date)
    );
    let projectListDemands = acpPlanningProjectListData?.filter(
      (project) => !AcpService.isWeekendUTC(project?.EstimatedAWReadiness)
    );
    setDemandData(
      activeTable === "TaskList" ? applyTaskAndSupplierFilter(taskListdemands) : projectListDemands
    );
    setDemandDataUpdated([]);
  };

  //use effect to set demand data as per changing task/project data, and supplier and task values
  useEffect(() => {
    setDemandDataValues();
  }, [acpPlanningData, activeTable, acpPlanningProjectListData]);

  useEffect(() => {
    if (task || supplierValue) {
      setDemandData(
        applyTaskAndSupplierFilter(
          acpPlanningData?.filter((taskDetails) => !AcpService.isWeekendUTC(taskDetails?.Date))
        )
      );
      setApplyFilters(true);
    }
  }, [task, supplierValue]);

  //need to consider past values of demandTableValues to update the demand values with resepect to isTaskData and isProjectData -- done
  // need to check for change of already changed new start dates, increase where new, decrease where old -- done
  //need to change the summary table for type of task,supplier in filter -- done

  useEffect(() => {
    if (isProjectData.length === 0) {
      let draftData = [];
      if (projectDraftChanges?.length > 0) {
        draftData = createDraftChanges(projectDraftChanges);
        if (draftData.length > 0) {
          setIsProjectData(draftData);
        }
      }
    }
  }, [isProjectData]);

  //to update summary table demand values on change of task data, ALSO check for RTA tasks as AWMTaskID is ""
  useEffect(() => {
    //to update the summary table when there is no isTaskData but data in taskDraftChanges
    if (isTaskData.length === 0) {
      let draftData = [];
      if (taskDraftChanges?.length > 0) {
        draftData = createDraftChanges(taskDraftChanges);
        if (draftData.length > 0) {
          setIsTaskData(draftData);
        }
      }
    }

    if (isTaskData.length > 0) {
      const filteredIsTask = applyTaskAndSupplierFilter(isTaskData);
      let filteredData = [];
      let temp = [];
      //demandDataUpdated to keep track of previous changes
      if (demandDataUpdated.length > 0) {
        filteredData = filteredIsTask.filter((el) => {
          let index = demandDataUpdated.findIndex((ele) =>
            el.AWMTaskID === "" ? ele.POANumber === el.POANumber : ele.AWMTaskID === el.AWMTaskID
          );
          if (index !== -1) {
            return 0;
            //will be dealt in updatedTemp section
          } else {
            return 1;
          }
        });
      } else {
        filteredData = filteredIsTask;
      }

      //to get records where same record is updated again in fresh data
      if (filteredIsTask.length > 0) {
        filteredIsTask.forEach((el) => {
          if (el.tempNewStartDate !== el.NewStartDate) {
            let updatedRow = {};
            updatedRow.AWMTaskID = el.AWMTaskID;
            updatedRow.TaskType = el.TaskType;
            updatedRow.oldDate = el.tempNewStartDate;
            updatedRow.NewStartDate = el.NewStartDate;
            updatedRow.POANumber = el.POANumber;
            temp.push(updatedRow);
          }
        });
      }

      //new record updation
      let localTable = demandTableValues;
      if (filteredData.length > 0) {
        //changing demand for task from StartDate to NewStartDate in isTaskData
        filteredData?.forEach((task) => {
          let NewStartDate = AcpService.checkIfValidDate(task?.NewStartDate)
            ? AcpService.convertShortDateToGMT(task.NewStartDate)
            : AcpService.getConvertedDate(task.NewStartDate);
          localRanges?.forEach((range, index) => {
            if (activeTable === "TaskList") {
              //add demand where NewStartDate is
              if (AcpService.isDateInRange(NewStartDate, range.start, range.end)) {
                if (task.TaskType?.toLowerCase().includes("cic")) {
                  if (localTable[0][`${filters.selectedOption}${index}`]) {
                    localTable[0][`${filters.selectedOption}${index}`] += 1;
                    const isMatched = AcpService.getMatchDemand(
                      initialDemand,
                      localTable[0][`${filters.selectedOption}${index}`],
                      task.TaskType,
                      `${filters.selectedOption}${index}`
                    );
                    localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                  } else {
                    localTable[0][`${filters.selectedOption}${index}`] = 1;
                    const isMatched = AcpService.getMatchDemand(
                      initialDemand,
                      localTable[0][`${filters.selectedOption}${index}`],
                      task.TaskType,
                      `${filters.selectedOption}${index}`
                    );
                    localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                  }
                } else if (task.TaskType?.toLowerCase().includes("assembly")) {
                  if (localTable[1][`${filters.selectedOption}${index}`]) {
                    localTable[1][`${filters.selectedOption}${index}`] += 1;
                    const isMatched = AcpService.getMatchDemand(
                      initialDemand,
                      localTable[1][`${filters.selectedOption}${index}`],
                      task.TaskType,
                      `${filters.selectedOption}${index}`
                    );
                    localTable[1][`${filters.selectedOption}${index}highlight`] = !isMatched;
                  } else {
                    localTable[1][`${filters.selectedOption}${index}`] = 1;
                    const isMatched = AcpService.getMatchDemand(
                      initialDemand,
                      localTable[1][`${filters.selectedOption}${index}`],
                      task.TaskType,
                      `${filters.selectedOption}${index}`
                    );
                    localTable[1][`${filters.selectedOption}${index}highlight`] = !isMatched;
                  }
                }
              }
              //remove demand where StartDate is
              if (AcpService.checkIfValidDate(task?.StartDate))
                task.StartDate = AcpService.convertShortDateToGMT(task.StartDate);
              if (AcpService.isDateInRange(task.StartDate, range.start, range.end)) {
                if (task.TaskType?.toLowerCase().includes("cic")) {
                  if (localTable[0][`${filters.selectedOption}${index}`]) {
                    localTable[0][`${filters.selectedOption}${index}`] -= 1;
                    const isMatched = AcpService.getMatchDemand(
                      initialDemand,
                      localTable[0][`${filters.selectedOption}${index}`],
                      task.TaskType,
                      `${filters.selectedOption}${index}`
                    );
                    localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                  } else {
                    localTable[0][`${filters.selectedOption}${index}`] = -1;
                    if (localTable[0][`${filters.selectedOption}${index}`] === 0) {
                      localTable[0][`${filters.selectedOption}${index}highlight`] = false;
                    } else {
                      const isMatched = AcpService.getMatchDemand(
                        initialDemand,
                        localTable[0][`${filters.selectedOption}${index}`],
                        task.TaskType,
                        `${filters.selectedOption}${index}`
                      );
                      localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                    }
                  }
                } else if (task.TaskType?.toLowerCase().includes("assembly")) {
                  if (localTable[1][`${filters.selectedOption}${index}`]) {
                    localTable[1][`${filters.selectedOption}${index}`] -= 1;
                    const isMatched = AcpService.getMatchDemand(
                      initialDemand,
                      localTable[1][`${filters.selectedOption}${index}`],
                      task.TaskType,
                      `${filters.selectedOption}${index}`
                    );
                    localTable[1][`${filters.selectedOption}${index}highlight`] = !isMatched;
                  } else {
                    localTable[1][`${filters.selectedOption}${index}`] = -1;
                    if (localTable[1][`${filters.selectedOption}${index}`] === 0) {
                      localTable[1][`${filters.selectedOption}${index}highlight`] = false;
                    } else {
                      const isMatched = AcpService.getMatchDemand(
                        initialDemand,
                        localTable[1][`${filters.selectedOption}${index}`],
                        task.TaskType,
                        `${filters.selectedOption}${index}`
                      );
                      localTable[1][`${filters.selectedOption}${index}highlight`] = !isMatched;
                    }
                  }
                }
              }
            }
          });
        });
      }

      //to get records which are having new start dates from duplicateData, i.e. records where date is again changed
      let updatedTemp = [];
      if (duplicateData.length > 0) {
        updatedTemp = temp?.filter((el) => {
          let index = duplicateData.findIndex((ele) =>
            el.AWMTaskID === "" ? ele.POANumber === el.POANumber : ele.AWMTaskID === el.AWMTaskID
          );

          if (index !== -1) {
            if (duplicateData[index]?.NewStartDate) {
              if (
                duplicateData[index]?.NewStartDate.toLocaleString() ===
                el?.NewStartDate?.toLocaleString()
              ) {
                return 0;
              } else {
                return 1;
              }
            }
          } else {
            return 1;
          }
        });
      } else {
        updatedTemp = temp;
      }

      //code to update old rows with New Start Dates
      updatedTemp?.forEach((task) => {
        let NewStartDate = AcpService.checkIfValidDate(task?.NewStartDate)
          ? AcpService.convertShortDateToGMT(task.NewStartDate)
          : AcpService.getConvertedDate(task.NewStartDate);
        localRanges?.forEach((range, index) => {
          if (activeTable === "TaskList") {
            //add demand where NewStartDate is
            if (AcpService.isDateInRange(NewStartDate, range.start, range.end)) {
              if (task.TaskType?.toLowerCase().includes("cic")) {
                if (localTable[0][`${filters.selectedOption}${index}`]) {
                  localTable[0][`${filters.selectedOption}${index}`] += 1;
                  const isMatched = AcpService.getMatchDemand(
                    initialDemand,
                    localTable[0][`${filters.selectedOption}${index}`],
                    task.TaskType,
                    `${filters.selectedOption}${index}`
                  );
                  localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                } else {
                  localTable[0][`${filters.selectedOption}${index}`] = 1;
                  const isMatched = AcpService.getMatchDemand(
                    initialDemand,
                    localTable[0][`${filters.selectedOption}${index}`],
                    task.TaskType,
                    `${filters.selectedOption}${index}`
                  );
                  localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                }
              } else if (task.TaskType?.toLowerCase().includes("assembly")) {
                if (localTable[1][`${filters.selectedOption}${index}`]) {
                  localTable[1][`${filters.selectedOption}${index}`] += 1;
                  const isMatched = AcpService.getMatchDemand(
                    initialDemand,
                    localTable[1][`${filters.selectedOption}${index}`],
                    task.TaskType,
                    `${filters.selectedOption}${index}`
                  );
                  localTable[1][`${filters.selectedOption}${index}highlight`] = !isMatched;
                } else {
                  localTable[1][`${filters.selectedOption}${index}`] = 1;
                  const isMatched = AcpService.getMatchDemand(
                    initialDemand,
                    localTable[1][`${filters.selectedOption}${index}`],
                    task.TaskType,
                    `${filters.selectedOption}${index}`
                  );
                  localTable[1][`${filters.selectedOption}${index}highlight`] = !isMatched;
                }
              }
            }
            //remove demand where oldStartDate is
            if (
              AcpService.isDateInRange(
                AcpService.getConvertedDate(task.oldDate),
                range.start,
                range.end
              )
            ) {
              if (task.TaskType?.toLowerCase().includes("cic")) {
                if (localTable[0][`${filters.selectedOption}${index}`]) {
                  localTable[0][`${filters.selectedOption}${index}`] -= 1;
                  if (localTable[0][`${filters.selectedOption}${index}`] === 0) {
                    localTable[0][`${filters.selectedOption}${index}highlight`] = false;
                  } else {
                    const isMatched = AcpService.getMatchDemand(
                      initialDemand,
                      localTable[0][`${filters.selectedOption}${index}`],
                      task.TaskType,
                      `${filters.selectedOption}${index}`
                    );
                    localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                  }
                } else {
                  localTable[0][`${filters.selectedOption}${index}`] = -1;
                  if (localTable[0][`${filters.selectedOption}${index}`] === 0) {
                    localTable[0][`${filters.selectedOption}${index}highlight`] = false;
                  } else {
                    const isMatched = AcpService.getMatchDemand(
                      initialDemand,
                      localTable[0][`${filters.selectedOption}${index}`],
                      task.TaskType,
                      `${filters.selectedOption}${index}`
                    );
                    localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                  }
                }
              } else if (task.TaskType?.toLowerCase().includes("assembly")) {
                if (localTable[1][`${filters.selectedOption}${index}`]) {
                  localTable[1][`${filters.selectedOption}${index}`] -= 1;
                  if (localTable[1][`${filters.selectedOption}${index}`] === 0) {
                    localTable[1][`${filters.selectedOption}${index}highlight`] = false;
                  } else {
                    const isMatched = AcpService.getMatchDemand(
                      initialDemand,
                      localTable[1][`${filters.selectedOption}${index}`],
                      task.TaskType,
                      `${filters.selectedOption}${index}`
                    );
                    localTable[1][`${filters.selectedOption}${index}highlight`] = !isMatched;
                  }
                } else {
                  localTable[1][`${filters.selectedOption}${index}`] = -1;
                  if (localTable[1][`${filters.selectedOption}${index}`] === 0) {
                    localTable[1][`${filters.selectedOption}${index}highlight`] = false;
                  } else {
                    const isMatched = AcpService.getMatchDemand(
                      initialDemand,
                      localTable[1][`${filters.selectedOption}${index}`],
                      task.TaskType,
                      `${filters.selectedOption}${index}`
                    );
                    localTable[1][`${filters.selectedOption}${index}highlight`] = !isMatched;
                  }
                }
              }
            }
          }
        });
      });

      setDuplicateData(cloneDeep(temp));
      setDemandDataUpdated(cloneDeep(isTaskData));
      setDemandTableValues(cloneDeep(localTable));
    }
  }, [isTaskData]);

  useEffect(() => {
    // set isTask Data in the table once demandData is set, when task or supplier changes
    if (applyFilters) {
      const filteredIsTask = applyTaskAndSupplierFilter(isTaskData);
      if (filteredIsTask.length > 0) {
        let taskDropdown = task;
        let filteredData = filteredIsTask;
        let temp = [];

        //to get records where same record is updated again in fresh data
        if (filteredData.length > 0) {
          filteredData.forEach((el) => {
            if (el.tempNewStartDate !== el.NewStartDate) {
              let updatedRow = {};
              updatedRow.AWMTaskID = el.AWMTaskID;
              updatedRow.TaskType = el.TaskType;
              updatedRow.oldDate = el.tempNewStartDate;
              updatedRow.NewStartDate = el.NewStartDate;
              updatedRow.POANumber = el.POANumber;
              temp.push(updatedRow);
            }
          });
        }

        //new record updation
        let localTable = demandTableValues;

        if (filteredData.length > 0) {
          //changing demand for task from StartDate to NewStartDate in isTaskData
          filteredData?.forEach((task) => {
            let NewStartDate = AcpService.checkIfValidDate(task?.NewStartDate)
              ? AcpService.convertShortDateToGMT(task.NewStartDate)
              : AcpService.getConvertedDate(task.NewStartDate);
            localRanges?.forEach((range, index) => {
              if (activeTable === "TaskList") {
                //add demand where NewStartDate is
                if (AcpService.isDateInRange(NewStartDate, range.start, range.end)) {
                  if (taskDropdown === "Upload CIC") {
                    if (task.TaskType?.toLowerCase().includes("cic")) {
                      if (localTable[0][`${filters.selectedOption}${index}`]) {
                        localTable[0][`${filters.selectedOption}${index}`] += 1;
                      } else localTable[0][`${filters.selectedOption}${index}`] = 1;
                    }
                  } else if (taskDropdown === "FA Assembly") {
                    if (task.TaskType?.toLowerCase().includes("assembly")) {
                      if (localTable[1][`${filters.selectedOption}${index}`]) {
                        localTable[1][`${filters.selectedOption}${index}`] += 1;
                      } else localTable[1][`${filters.selectedOption}${index}`] = 1;
                    }
                  } else {
                    //case of All tasks
                    if (task.TaskType?.toLowerCase().includes("cic")) {
                      if (localTable[0][`${filters.selectedOption}${index}`]) {
                        localTable[0][`${filters.selectedOption}${index}`] += 1;
                      } else localTable[0][`${filters.selectedOption}${index}`] = 1;
                    } else if (task.TaskType?.toLowerCase().includes("assembly")) {
                      if (localTable[1][`${filters.selectedOption}${index}`]) {
                        localTable[1][`${filters.selectedOption}${index}`] += 1;
                      } else localTable[1][`${filters.selectedOption}${index}`] = 1;
                    }
                  }
                }
                //remove demand where StartDate is

                if (AcpService.isDateInRange(task.StartDate, range.start, range.end)) {
                  if (taskDropdown === "Upload CIC") {
                    if (task.TaskType?.toLowerCase().includes("cic")) {
                      if (localTable[0][`${filters.selectedOption}${index}`]) {
                        localTable[0][`${filters.selectedOption}${index}`] -= 1;
                      } else localTable[0][`${filters.selectedOption}${index}`] = -1;
                    }
                  } else if (taskDropdown === "FA Assembly") {
                    if (task.TaskType?.toLowerCase().includes("assembly")) {
                      if (localTable[1][`${filters.selectedOption}${index}`]) {
                        localTable[1][`${filters.selectedOption}${index}`] -= 1;
                      } else localTable[1][`${filters.selectedOption}${index}`] = -1;
                    }
                  } else {
                    if (task.TaskType?.toLowerCase().includes("cic")) {
                      if (localTable[0][`${filters.selectedOption}${index}`]) {
                        localTable[0][`${filters.selectedOption}${index}`] -= 1;
                      } else localTable[0][`${filters.selectedOption}${index}`] = -1;
                    } else if (task.TaskType?.toLowerCase().includes("assembly")) {
                      if (localTable[1][`${filters.selectedOption}${index}`]) {
                        localTable[1][`${filters.selectedOption}${index}`] -= 1;
                      } else localTable[1][`${filters.selectedOption}${index}`] = -1;
                    }
                  }
                }
              }
            });
          });
        }

        //to get records which are having new start dates from duplicateData, i.e. records where date is again changed
        let updatedTemp = [];
        if (duplicateData.length > 0) {
          updatedTemp = temp?.filter((el) => {
            let index = duplicateData.findIndex((ele) =>
              el.AWMTaskID === "" ? ele.POANumber === el.POANumber : ele.AWMTaskID === el.AWMTaskID
            );

            if (index !== -1) {
              if (duplicateData[index]?.NewStartDate) {
                if (
                  duplicateData[index]?.NewStartDate.toLocaleString() ===
                  el.NewStartDate?.toLocaleString()
                ) {
                  return 0;
                } else {
                  return 1;
                }
              }
            } else {
              return 1;
            }
          });
        } else {
          updatedTemp = temp;
        }

        //code to update old rows with New Start Dates
        updatedTemp?.forEach((task) => {
          let NewStartDate = AcpService.checkIfValidDate(task?.NewStartDate)
            ? AcpService.convertShortDateToGMT(task.NewStartDate)
            : AcpService.getConvertedDate(task.NewStartDate);
          localRanges?.forEach((range, index) => {
            if (activeTable === "TaskList") {
              //add demand where NewStartDate is
              if (AcpService.isDateInRange(NewStartDate, range.start, range.end)) {
                if (task.TaskType?.toLowerCase().includes("cic")) {
                  if (localTable[0][`${filters.selectedOption}${index}`]) {
                    localTable[0][`${filters.selectedOption}${index}`] += 1;
                  } else localTable[0][`${filters.selectedOption}${index}`] = 1;
                } else if (task.TaskType?.toLowerCase().includes("assembly")) {
                  if (localTable[1][`${filters.selectedOption}${index}`]) {
                    localTable[1][`${filters.selectedOption}${index}`] += 1;
                  } else localTable[1][`${filters.selectedOption}${index}`] = 1;
                }
              }
              //remove demand where oldStartDate is
              if (
                AcpService.isDateInRange(
                  AcpService.getConvertedDate(task.oldDate),
                  range.start,
                  range.end
                )
              ) {
                if (task.TaskType?.toLowerCase().includes("cic")) {
                  if (localTable[0][`${filters.selectedOption}${index}`]) {
                    localTable[0][`${filters.selectedOption}${index}`] -= 1;
                  } else localTable[0][`${filters.selectedOption}${index}`] = -1;
                } else if (task.TaskType?.toLowerCase().includes("assembly")) {
                  if (localTable[1][`${filters.selectedOption}${index}`]) {
                    localTable[1][`${filters.selectedOption}${index}`] -= 1;
                  } else localTable[1][`${filters.selectedOption}${index}`] = -1;
                }
              }
            }
          });
        });

        setDuplicateData(cloneDeep(temp));
        setDemandDataUpdated(cloneDeep(isTaskData));
        setDemandTableValues(cloneDeep(localTable));
        setApplyFilters(false);
      }
    }
  }, [applyFilters]);

  //to update summary table demand values on change of project list data
  useEffect(() => {
    if (isProjectData && isProjectData.length) {
      let filteredData = [];
      let temp = [];

      if (demandDataProjectUpdated.length > 0) {
        filteredData = isProjectData.filter((el) => {
          let index = demandDataProjectUpdated.findIndex(
            (ele) => ele.AWMProjectID === el.AWMProjectID
          );
          if (index !== -1) {
            if (demandDataProjectUpdated[index]?.NewStartDate) {
              if (
                demandDataProjectUpdated[index]?.NewStartDate.toLocaleString() ===
                el.NewStartDate.toLocaleString()
              ) {
                return 0;
              } else {
                //will be dealt in updatedTemp section
                return 0;
              }
            }
          } else {
            return 1;
          }
        });
      } else {
        filteredData = isProjectData;
      }

      if (isProjectData.length > 0) {
        isProjectData.forEach((el) => {
          if (el.tempNewStartDate !== el.NewStartDate) {
            let updatedRow = {};
            updatedRow.AWMProjectID = el.AWMProjectID;
            updatedRow.oldDate = el.tempNewStartDate;
            updatedRow.NewStartDate = el.NewStartDate;
            updatedRow.EstimatedOfPOAs = el.EstimatedOfPOAs;
            temp.push(updatedRow);
          }
        });
      }

      //new record updation
      let localTable = demandTableValues;

      if (filteredData.length > 0) {
        //changing demand for task from StartDate to NewStartDate in isTaskData
        filteredData?.forEach((task) => {
          let NewStartDate = AcpService.checkIfValidDate(task?.NewStartDate)
            ? AcpService.convertShortDateToGMT(task.NewStartDate)
            : AcpService.getConvertedDate(task.NewStartDate);
          localRanges?.forEach((range, index) => {
            if (activeTable === "ProjectList") {
              //add demand
              if (AcpService.isDateInRange(NewStartDate, range.start, range.end)) {
                if (localTable[0][`${filters.selectedOption}${index}`]) {
                  localTable[0][`${filters.selectedOption}${index}`] += task.EstimatedOfPOAs;
                  const isMatched = AcpService.getMatchDemand(
                    initialDemand,
                    localTable[0][`${filters.selectedOption}${index}`],
                    "Artwork Scope Alignment",
                    `${filters.selectedOption}${index}`
                  );
                  localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                } else {
                  localTable[0][`${filters.selectedOption}${index}`] = task.EstimatedOfPOAs;
                  if (localTable[0][`${filters.selectedOption}${index}`] === 0) {
                    localTable[0][`${filters.selectedOption}${index}highlight`] = false;
                  } else {
                    const isMatched = AcpService.getMatchDemand(
                      initialDemand,
                      localTable[0][`${filters.selectedOption}${index}`],
                      "Artwork Scope Alignment",
                      `${filters.selectedOption}${index}`
                    );
                    localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                  }
                }
              }
              //remove demand
              if (AcpService.isDateInRange(task.EstimatedAWReadiness, range.start, range.end)) {
                if (localTable[0][`${filters.selectedOption}${index}`]) {
                  localTable[0][`${filters.selectedOption}${index}`] -= task.EstimatedOfPOAs;
                  const isMatched = AcpService.getMatchDemand(
                    initialDemand,
                    localTable[0][`${filters.selectedOption}${index}`],
                    "Artwork Scope Alignment",
                    `${filters.selectedOption}${index}`
                  );
                  localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                } else {
                  localTable[0][`${filters.selectedOption}${index}`] = 0 - task.EstimatedOfPOAs;
                  if (localTable[0][`${filters.selectedOption}${index}`] === 0) {
                    localTable[0][`${filters.selectedOption}${index}highlight`] = false;
                  } else {
                    const isMatched = AcpService.getMatchDemand(
                      initialDemand,
                      localTable[0][`${filters.selectedOption}${index}`],
                      "Artwork Scope Alignment",
                      `${filters.selectedOption}${index}`
                    );
                    localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                  }
                }
              }
            }
          });
        });
      }

      //to get records which are having new start dates from duplicateData, records where date is again changed
      let updatedTemp = [];
      if (duplicateDataProject.length > 0) {
        updatedTemp = temp?.filter((el) => {
          let index = duplicateDataProject.findIndex((ele) => ele.AWMProjectID === el.AWMProjectID);

          if (index !== -1) {
            if (duplicateDataProject[index]?.NewStartDate) {
              if (
                duplicateDataProject[index]?.NewStartDate.toLocaleString() ===
                el.NewStartDate.toLocaleString()
              ) {
                return 0;
              } else {
                return 1;
              }
            }
          } else {
            return 1;
          }
        });
      } else {
        updatedTemp = temp;
      }

      //code to update old rows with New Start Dates
      updatedTemp?.forEach((task) => {
        let NewStartDate = AcpService.checkIfValidDate(task?.NewStartDate)
          ? AcpService.convertShortDateToGMT(task.NewStartDate)
          : AcpService.getConvertedDate(task.NewStartDate);
        localRanges?.forEach((range, index) => {
          //add demand where NewStartDate is
          if (activeTable === "ProjectList") {
            //add demand
            if (AcpService.isDateInRange(NewStartDate, range.start, range.end)) {
              if (localTable[0][`${filters.selectedOption}${index}`]) {
                localTable[0][`${filters.selectedOption}${index}`] += task.EstimatedOfPOAs;
                const isMatched = AcpService.getMatchDemand(
                  initialDemand,
                  localTable[0][`${filters.selectedOption}${index}`],
                  "Artwork Scope Alignment",
                  `${filters.selectedOption}${index}`
                );
                localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
              } else {
                localTable[0][`${filters.selectedOption}${index}`] = task.EstimatedOfPOAs;
                if (localTable[0][`${filters.selectedOption}${index}`] === 0) {
                  localTable[0][`${filters.selectedOption}${index}highlight`] = false;
                } else {
                  const isMatched = AcpService.getMatchDemand(
                    initialDemand,
                    localTable[0][`${filters.selectedOption}${index}`],
                    "Artwork Scope Alignment",
                    `${filters.selectedOption}${index}`
                  );
                  localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                }
              }
            }
            //remove demand
            if (
              AcpService.isDateInRange(
                AcpService.getConvertedDate(task.oldDate),
                range.start,
                range.end
              )
            ) {
              if (localTable[0][`${filters.selectedOption}${index}`]) {
                localTable[0][`${filters.selectedOption}${index}`] -= task.EstimatedOfPOAs;
                if (localTable[0][`${filters.selectedOption}${index}`] === 0) {
                  localTable[0][`${filters.selectedOption}${index}highlight`] = false;
                } else {
                  const isMatched = AcpService.getMatchDemand(
                    initialDemand,
                    localTable[0][`${filters.selectedOption}${index}`],
                    "Artwork Scope Alignment",
                    `${filters.selectedOption}${index}`
                  );
                  localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                }
              } else {
                localTable[0][`${filters.selectedOption}${index}`] = 0 - task.EstimatedOfPOAs;
                if (localTable[0][`${filters.selectedOption}${index}`] === 0) {
                  localTable[0][`${filters.selectedOption}${index}highlight`] = false;
                } else {
                  const isMatched = AcpService.getMatchDemand(
                    initialDemand,
                    localTable[0][`${filters.selectedOption}${index}`],
                    "Artwork Scope Alignment",
                    `${filters.selectedOption}${index}`
                  );
                  localTable[0][`${filters.selectedOption}${index}highlight`] = !isMatched;
                }
              }
            }
          }
        });
      });

      setDuplicateDataProject(cloneDeep(temp));
      setDemandDataProjectUpdated(cloneDeep(isProjectData));
      setDemandTableValues((prev) => {
        prev = localTable;
        return [...prev];
      });
    }
  }, [isProjectData]);

  const generateDefaultHeaders = () => {
    let headers = [];
    if (filters) {
      if (filters?.selectedOption === "Daily") {
        let dailyRanges = getDailyRanges(filters?.dailyStartDate, filters?.dailyEndDate);
        fillDailyRangeOb(filters?.dailyStartDate, filters?.dailyEndDate);
        dailyRanges?.forEach((date, index) => {
          headers.push(
            <Column
              key={`header_${index}_daily`}
              field={`Daily${index}`}
              header={() => thisBodyContent(date)}
              body={(rowData) => (
                <span className={rowData[`Daily${index}highlight`] ? "row-capacity-highlight" : ""}>
                  {rowData[`Daily${index}`] !== undefined
                    ? rowData[`Daily${index}`]
                    : defaultCapacity}
                </span>
              )}
              bodyClassName={(rowData) =>
                AcpService.getCellClassName(rowData, { field: `Daily${index}` })
              }
            />
          );
        });
      } else if (filters?.selectedOption === "Weekly") {
        let weekRanges = getWeekRanges(
          filters?.weekStartRange,
          filters?.weekEndRange,
          filters?.startDate,
          filters?.endDate
        );
        weekRanges?.forEach((date, index) => {
          headers.push(
            <Column
              key={`header_${index}_weekly`}
              field={`Weekly${index}`}
              header={() => thisBodyContent(date)}
              body={(rowData) => (
                <>
                  <span
                    className={rowData[`Weekly${index}highlight`] ? "row-capacity-highlight" : ""}
                  >
                    {rowData[`Weekly${index}`] !== undefined
                      ? rowData[`Weekly${index}`]
                      : defaultCapacity}
                  </span>
                </>
              )}
              bodyClassName={(rowData) =>
                AcpService.getCellClassName(rowData, { field: `Weekly${index}` })
              }
            />
          );
        });
      } else if (filters?.selectedOption === "Monthly") {
        const startDate = new Date(filters?.monthStart);
        const endDate = new Date(filters?.monthEnd);
        let monthRanges = getMonthRanges(startDate, endDate);
        monthRanges?.forEach((date, index) => {
          headers.push(
            <Column
              key={`header_${index}_monthly`}
              field={`Monthly${index}`}
              header={() => thisBodyContent(date)}
              body={(rowData) => (
                <span
                  className={rowData[`Monthly${index}highlight`] ? "row-capacity-highlight" : ""}
                >
                  {rowData[`Monthly${index}`] !== undefined
                    ? rowData[`Monthly${index}`]
                    : defaultCapacity}
                </span>
              )}
              bodyClassName={(rowData) =>
                AcpService.getCellClassName(rowData, { field: `Monthly${index}` })
              }
            />
          );
        });
      }
      setNewHeaderValue(headers);
    }
  };

  const getMonthRanges = (startDate, endDate) => {
    let monthRanges = [];
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();
    const totalMonths = (endYear - startYear) * 12 + (endMonth - startMonth) + 1;
    let currentDate = new Date(startDate);
    let arr = [];
    for (let i = 0; i < totalMonths; i++) {
      monthRanges.push(currentDate.toLocaleString("default", { month: "long" }));
      arr.push(AcpService.getMonthDates(currentDate.toLocaleString("default", { month: "long" })));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    setLocalRanges(arr);
    return monthRanges;
  };

  const getWeekRanges = (startRange, endRange, _startDate, _endDate) => {
    let weekRanges = [];

    const getDateFromWeekRange = (weekRange) => {
      if (!weekRange) {
        return { startDate: null, endDate: null };
      }
      const [monthStart, dayStart] = weekRange.split("-")[0].trim().split(" ");
      const [monthEnd, dayEnd] = weekRange.split("-")[1].trim().split(" ");
      const currentStartYear = new Date(AcpService.formatDate(_startDate))?.getFullYear();
      const currentEndYear = new Date(AcpService.formatDate(_endDate))?.getFullYear();

      return {
        startDate: new Date(`${monthStart} ${dayStart}, ${currentStartYear}`),
        endDate: new Date(`${monthEnd} ${dayEnd}, ${currentEndYear}`),
      };
    };

    const startDate = getDateFromWeekRange(startRange).startDate;
    const endDate = getDateFromWeekRange(endRange).endDate;

    let currentDate = new Date(startDate);
    let arr = [];
    while (currentDate <= endDate) {
      const startOfWeek = new Date(currentDate);
      const endOfWeek = new Date(currentDate);
      endOfWeek.setDate(endOfWeek.getDate() + 6);
      if (endOfWeek <= endDate) {
        let start = startOfWeek.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        let end = endOfWeek.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        start = start.split(" ");
        let monthS = start[0];
        let dayS =
          start[1].split(",")[0] < 10 ? "0" + start[1].split(",")[0] : start[1].split(",")[0];
        let yearS = start[2];

        end = end.split(" ");
        let monthE = end[0];
        let dayE = end[1].split(",")[0] < 10 ? "0" + end[1].split(",")[0] : end[1].split(",")[0];
        let yearE = end[2];

        start = monthS + " " + dayS;
        end = monthE + " " + dayE;

        if (
          yearS === undefined ||
          yearE === undefined ||
          monthS === undefined ||
          monthE === undefined
        )
          return;
        let ob = {};
        let weekStartFull = yearS + monthsMap[monthS] + dayS;
        let weekEndFull = yearE + monthsMap[monthE] + dayE;
        ob["start"] = weekStartFull;
        ob["end"] = weekEndFull;

        arr.push(ob);
        weekRanges.push({ start, end });
      }
      currentDate.setDate(currentDate.getDate() + 7);
    }
    setLocalRanges(arr);
    return weekRanges;
  };
  const getDailyRanges = (start, end) => {
    let dailyRanges = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      dailyRanges.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    dailyRanges = dailyRanges.filter((date) => {
      const day = date.getDay();
      return day !== 0 && day !== 6;
    });
    return dailyRanges;
  };

  const fillDailyRangeOb = (startDate, endDate) => {
    let start = new Date(startDate);
    let end = new Date(endDate);

    let currentDate = start;
    let formattedDate;
    let arr = [];
    while (currentDate <= end) {
      let year = currentDate.getFullYear();
      let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      let day = ("0" + currentDate.getDate()).slice(-2);

      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        formattedDate = year + month + day;
        let ob = {};
        ob["start"] = formattedDate;
        ob["end"] = formattedDate;
        arr.push(ob);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setLocalRanges(arr);

    return {
      start: formattedDate,
      end: formattedDate,
    };
  };

  const thisBodyContent = (date) => {
    let formattedDate;
    if (filters?.selectedOption === "Daily") {
      formattedDate = AcpService.formatDateForDailyRange(date);
    }

    return (
      <div>
        {filters?.selectedOption === "Daily" && (
          <span className="tableSubHeader">{formattedDate}</span>
        )}
        {filters?.selectedOption === "Weekly" && (
          <span className="tableSubHeader">{`${date.start}-${date.end}`}</span>
        )}
        {filters?.selectedOption === "Monthly" && (
          <span className="tableSubHeader">{`${date}`}</span>
        )}
      </div>
    );
  };

  const setCapacityDataValues = (payload) => {
    if (filters?.startDate && filters?.endDate) {
      generateDefaultHeaders();
      let localTable =
        activeTable === "TaskList"
          ? [{ taskType: "Upload CIC" }, { taskType: "FA Assembly" }]
          : [{ taskType: "Artwork Scope Alignment" }];

      setCapacityTableValues(localTable);
      setCDTableValues(localTable);
      setDemandTableValues(localTable);
      setDemandDataUpdated([]);

      if (activeTable === "TaskList") {
        fetchBookingCapacities(payload).then((data) => {
          let cap = data?.CapacityForBooking;
          cap = cap?.filter((cp) => !AcpService.isWeekend(cp.Date));
          setCapacityData(applyTaskAndSupplierFilter(cap));
        });
      } else if (activeTable === "ProjectList") {
        getPlanningProjectLists(payload).then((res) => {
          let cap = res?.data?.CapacityForProject;
          cap = cap?.filter((cp) => !AcpService.isWeekend(cp.StartDate));
          setCapacityData(cap);
        });
      }
    }
  };

  //use effect to set Capacity data on change of start date, end date , supplier and task value
  useEffect(() => {
    if (startDateQueryParams && endDateQueryParams) {
      const payload2 = {
        ArtworkAgilityPage: {
          PM: PM,
          UserGroup: UserGroup,
          End_Date: endDateQueryParams,
          Start_Date: startDateQueryParams,
        },
      };
      filters.endDate = endDateQueryParams;
      filters.startDate = startDateQueryParams;
      filters.selectedOption = dateTypeQueryParams;
      filters.dailyEndDate = new Date(AcpService.convertToIST(endDateQueryParams));
      filters.dailyStartDate = new Date(AcpService.convertToIST(startDateQueryParams));
      setCapacityDataValues(payload2);
    } else if (
      filters?.startDate &&
      filters?.endDate &&
      !startDateQueryParams &&
      !endDateQueryParams
    ) {
      const payload1 = {
        ArtworkAgilityPage: {
          PM: PM,
          UserGroup: UserGroup,
          End_Date: filters?.endDate,
          Start_Date: filters?.startDate,
        },
      };
      setCapacityDataValues(payload1);
    }
    setDemandDataValues();
  }, [
    task,
    reset,
    activeTable,
    supplierValue,
    filters,
    filters?.endDate,
    filters?.startDate,
    endDateQueryParams,
    startDateQueryParams,
  ]);

  useEffect(() => {
    if (capacityData?.length > 0 && localRanges?.length > 0) {
      let localTable =
        activeTable === "TaskList"
          ? [{ taskType: "Upload CIC" }, { taskType: "FA Assembly" }]
          : [{ taskType: "Artwork Scope Alignment" }];

      capacityData?.forEach((task) => {
        localRanges?.forEach((range, index) => {
          if (activeTable === "TaskList") {
            if (AcpService.isDateInRange(task.Date, range.start, range.end)) {
              if (task.TaskType?.toLowerCase().includes("cic")) {
                if (localTable[0][`${filters.selectedOption}${index}`]) {
                  localTable[0][`${filters.selectedOption}${index}`] += task.Capacity;
                } else localTable[0][`${filters.selectedOption}${index}`] = task.Capacity;
              } else if (task.TaskType?.toLowerCase().includes("assembly")) {
                if (localTable[1][`${filters.selectedOption}${index}`]) {
                  localTable[1][`${filters.selectedOption}${index}`] += task.Capacity;
                } else localTable[1][`${filters.selectedOption}${index}`] = task.Capacity;
              }
            }
          } else {
            if (filters.selectedOption === "Weekly") {
              if (task.StartDate === range.start && task.EndDate === range.end) {
                if (localTable[0][`${filters.selectedOption}${index}`]) {
                  localTable[0][`${filters.selectedOption}${index}`] += task.Capacity;
                } else localTable[0][`${filters.selectedOption}${index}`] = task.Capacity;
              }
            } else {
              if (AcpService.isDateInRange(task.StartDate, range.start, range.end)) {
                if (localTable[0][`${filters.selectedOption}${index}`]) {
                  localTable[0][`${filters.selectedOption}${index}`] += task.Capacity;
                } else localTable[0][`${filters.selectedOption}${index}`] = task.Capacity;
              }
            }
          }
        });
      });
      setCapacityTableValues(localTable);
    } else {
      let localTable =
        activeTable === "TaskList"
          ? [{ taskType: "Upload CIC" }, { taskType: "FA Assembly" }]
          : [{ taskType: "Artwork Scope Alignment" }];
      setCapacityTableValues(localTable);
    }
  }, [capacityData, filters]);

  useEffect(() => {
    if (demandData?.length > 0 && localRanges?.length > 0) {
      let localTable =
        activeTable === "TaskList"
          ? [{ taskType: "Upload CIC" }, { taskType: "FA Assembly" }]
          : [{ taskType: "Artwork Scope Alignment" }];
      demandData?.forEach((task) => {
        if (AcpService.checkIfValidDate(task?.StartDate))
          task.StartDate = AcpService.convertShortDateToGMT(task?.StartDate);
        localRanges?.forEach((range, index) => {
          if (activeTable === "TaskList") {
            //changes for Awm-3250
            const startDate = task?.NewStartDate ? task?.NewStartDate : task?.StartDate; 
            if (AcpService.isDateInRange(startDate, range.start, range.end)) {
              if (task?.TaskType?.toLowerCase().includes("cic")) {
                if (localTable[0][`${filters.selectedOption}${index}`]) {
                  localTable[0][`${filters.selectedOption}${index}`] += 1;
                } else localTable[0][`${filters.selectedOption}${index}`] = 1;
              } else if (task?.TaskType?.toLowerCase().includes("assembly")) {
                if (localTable[1][`${filters.selectedOption}${index}`]) {
                  localTable[1][`${filters.selectedOption}${index}`] += 1;
                } else localTable[1][`${filters.selectedOption}${index}`] = 1;
              }
            }
          } else if (activeTable === "ProjectList") {
            if (AcpService.isDateInRange(task.EstimatedAWReadiness, range.start, range.end)) {
              if (localTable[0][`${filters.selectedOption}${index}`]) {
                localTable[0][`${filters.selectedOption}${index}`] += task.EstimatedOfPOAs;
              } else {
                localTable[0][`${filters.selectedOption}${index}`] = task.EstimatedOfPOAs;
              }
            }
          }
        });
      });
      setInitialDemand(cloneDeep(localTable));
      setDemandTableValues(cloneDeep(localTable));//changes for Awm-3250
    }
  }, [demandData]);

  const calculateCDData = () => {
    if (demandTableValues.length > 0 && localRanges.length > 0 && capacityTableValues.length > 0) {
      let localTable =
        activeTable === "TaskList"
          ? [{ taskType: "Upload CIC" }, { taskType: "FA Assembly" }]
          : [{ taskType: "Artwork Scope Alignment" }];
      // demandTableValues?.forEach((task) => {
      localRanges?.forEach((range, index) => {
        if (
          capacityTableValues[0][`${filters.selectedOption}${index}`] &&
          demandTableValues[0][`${filters.selectedOption}${index}`]
        ) {
          localTable[0][`${filters.selectedOption}${index}`] =
            capacityTableValues[0][`${filters.selectedOption}${index}`] -
            demandTableValues[0][`${filters.selectedOption}${index}`];
        } else {
          if (capacityTableValues[0][`${filters.selectedOption}${index}`])
            localTable[0][`${filters.selectedOption}${index}`] =
              capacityTableValues[0][`${filters.selectedOption}${index}`];
          else if (demandTableValues[0][`${filters.selectedOption}${index}`])
            localTable[0][`${filters.selectedOption}${index}`] =
              0 - demandTableValues[0][`${filters.selectedOption}${index}`];
        }
        if (activeTable === "TaskList") {
          if (
            capacityTableValues[1] &&
            demandTableValues[1] &&
            capacityTableValues[1][`${filters.selectedOption}${index}`] &&
            demandTableValues[1][`${filters.selectedOption}${index}`]
          ) {
            localTable[1][`${filters.selectedOption}${index}`] =
              capacityTableValues[1][`${filters.selectedOption}${index}`] -
              demandTableValues[1][`${filters.selectedOption}${index}`];
          } else {
            if (
              capacityTableValues[1] &&
              capacityTableValues[1][`${filters.selectedOption}${index}`]
            )
              localTable[1][`${filters.selectedOption}${index}`] =
                capacityTableValues[1][`${filters.selectedOption}${index}`];
            else if (
              demandTableValues[1] &&
              demandTableValues[1][`${filters.selectedOption}${index}`]
            )
              localTable[1][`${filters.selectedOption}${index}`] =
                0 - demandTableValues[1][`${filters.selectedOption}${index}`];
          }
        }
      });
      setCDTableValues(localTable);
    }
  };

  useEffect(() => {
    calculateCDData();
  }, [localRanges, demandTableValues, capacityTableValues]);

  // useEffect(() => {
  //   if (demandTableValues) {
  //     calculateCDData();
  //   }
  // }, [demandTableValues]);

  const handleClick = (buttonName) => {
    if (buttonName === "C-D") {
      setActiveSection("C-D");
    } else if (buttonName === "Capacity") {
      setActiveSection("Capacity");
    } else if (buttonName === "Demand") {
      setActiveSection("Demand");
    }
  };

  return (
    <>
      <div className="navigationButtonWrapper">
        <div className={`navigationButton`}>
          <p className="navigationText">RCCP&nbsp;:</p>
        </div>
        <button
          onClick={() => handleClick("C-D")}
          className={`${activeSection === "C-D" ? "activeSection" : ""}  navigationButton `}
        >
          <p className="navigationText">C-D</p>
        </button>
        <button
          onClick={() => handleClick("Capacity")}
          className={`${activeSection === "Capacity" ? "activeSection" : ""} navigationButton`}
        >
          <p className="navigationText">Capacity</p>
        </button>
        <button
          onClick={() => handleClick("Demand")}
          className={`${activeSection === "Demand" ? "activeSection" : ""} navigationButton`}
        >
          <p className="navigationText">Demand</p>
        </button>
      </div>

      <div className="dataTableWrapper summary-table">
        {newHeaderValue.length > 0 ? (
          <DataTable
            scrollable
            responsiveLayout="scroll"
            value={
              (activeSection === "C-D" && cdTableValues) ||
              (activeSection === "Capacity" && capacityTableValues) ||
              (activeSection === "Demand" && demandTableValues)
            }
            tableStyle={{
              width: "max-content",
              minWidth: "100%",
            }}
          >
            <Column
              field="taskType"
              frozen
              alignFrozen="left"
              header=""
              className="tableSubHeader columnDataText"
            />
            {newHeaderValue}
          </DataTable>
        ) : (
          <div className="p-3">
            No {activeTable === "TaskList" ? "Tasks" : "Project"} Data Available for selected date
            range!
          </div>
        )}
      </div>
    </>
  );
};

export default CdNavigation;
