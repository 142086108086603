import React, { useState } from "react";
import ManageCapacityHeader from "./ManageCapacityHeader";
import ExternalCapacityTable from "./ExternalCapacityTable";
import InternalCapacityTable from "./InternalCapacityTable";
import { AcpService } from "../../../service/ACPService";
import "./ManageCapacity.scss";

function CapacityTable({
  supplierValues,
  setTableEdited,
  setEditedRows,
  PM,
  userGroup,
  capacityTableType,
  setCapacityTableType,
  setLoader,
  setLoadTable,
  loadTable,
}) {
  const [enableEdit, setEnableEdit] = useState(true);
  const [supplierSelected, setSupplierSelected] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  return (
    <div>
      <div className="manage-cap-header">
        <ManageCapacityHeader
          capacityTableType={capacityTableType}
          setCapacityTableType={setCapacityTableType}
          supplierValues={supplierValues}
          enableEdit={enableEdit}
          setSelectedMonth={setSelectedMonth}
          setEnableEdit={setEnableEdit}
          setTableEdited={setTableEdited}
          setSupplierSelected={setSupplierSelected}
          userGroup={userGroup}
          setLoader={setLoader}
          loadTable={loadTable}
          setLoadTable={setLoadTable}
        />
      </div>

      <div className="main-cont">
        {capacityTableType === "External Capacity" && AcpService.isUBN(supplierSelected) ? (
          <div className="no-data">
            <p>Select a Supplier to Begin!</p>
          </div>
        ) : capacityTableType === "External Capacity" && !AcpService.isUBN(supplierSelected) ? (
          <ExternalCapacityTable
            supplierSelected={supplierSelected}
            setTableEdited={setTableEdited}
            setEditedRows={setEditedRows}
            selectedMonth={selectedMonth}
            enableEdit={enableEdit}
            PM={PM}
            userGroup={userGroup}
            setLoader={setLoader}
            loadTable={loadTable}
          />
        ) : (
          <InternalCapacityTable
            setTableEdited={setTableEdited}
            setEditedRows={setEditedRows}
            selectedMonth={selectedMonth}
            enableEdit={enableEdit}
            PM={PM}
            userGroup={userGroup}
            setLoader={setLoader}
            loadTable={loadTable}
          />
        )}
      </div>
    </div>
  );
}

export default CapacityTable;
