import * as types from './../types/types';
import { DEVURL } from '../../apis/envUrl';
import Api from '../../apis';
import { sortColumns } from '../../utils';

export const getTasks = (payLoad) => async (dispatch) => {
  // let userid = userInformation?.userid;
  // let userName = sessionStorage.getItem("username");
  dispatch({ type: 'GET_TASKS_REQUEST' });
  try {
    //here need to add url and pass PM name
    // const res = await axios.get(`${DEVURL}/mytasks/${userid}`);
    let headers = {
      'Content-Type': 'application/json',
      'Content-Language': 'en-US',
      'Access-Control-Allow-Origin': '*',
    };
    const api = new Api();
    const axiosInstance = await api.init({ headers });
    let urlString = `${DEVURL}/myTasks`;

    const res = await axiosInstance({
      url: urlString,
      method: 'POST',
      data: payLoad,
    });

    if (res?.data === null) {
      dispatch({
        type: types.GET_MY_TASKS_ERROR,
        payload: 'No records found',
      });
    } else {
      const orderByData = sortColumns('Buffer_To_Work','asc', res.data.ArtworkAgilityTasks);
      if (res.status === 200) {
        dispatch({
          type: types.GET_MY_TASKS_SUCCESS,
          payload: orderByData,
        });
      } else {
        dispatch({
          type: types.GET_MY_TASKS_ERROR,
          payload: 'Project not found',
        });
      }
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: types.GET_MY_TASKS_ERROR, payload: err });
  }
};

export const getAllTasks = (payload) => async (dispatch) => {
  // let region = userInformation?.region;
  // let bu = userInformation?.bu;
  dispatch({ type: 'GET_ALL_TASKS_REQUEST' });
  try {
    //here need to add url and pass PM name
    // const res = await axios.get(`${DEVURL}/alltasks/${bu}/${region}`);
    let headers = {
      'Content-Type': 'application/json',
      'Content-Language': 'en-US',
      'Access-Control-Allow-Origin': '*',
    };
    const api = new Api();
    const axiosInstance = await api.init({ headers });
    let urlString = `${DEVURL}/allTasks`;

    const res = await axiosInstance({
      url: urlString,
      method: 'POST',
      data: payload,
    });
    if (res?.data === null) {
      dispatch({
        type: types.GET_ALL_TASKS_ERROR,
        payload: 'No records found',
      });
    } else {
      const orderByData = sortColumns('Buffer_To_Work','asc', res.data.ArtworkAgilityTasks);
      if (res.status === 200) {
        dispatch({
          type: 'GET_ALL_TASKS_SUCCESS',
          payload: orderByData,
        });
      } else {
        dispatch({
          type: 'GET_ALL_TASKS_ERROR',
          payload: 'Project not found',
        });
      }
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: 'GET_ALL_TASKS_ERROR', payload: err });
  }
};

export const getAllUsersByRole = (payLoad) => async (dispatch) => {
  // debugger;
  let url = `${DEVURL}/getOperatorsList`;

  try {
    const api = new Api();
    const axiosInstance = await api.init({
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
      },
    });
    const res = await axiosInstance.post(url, payLoad);

    if (res.status === 200) {
      dispatch({
        type: types.USERS_LIST,
        payload: res.data,
      });
    }
    return res?.data;
  } catch (error) {
    console.log(error.message);
  }
};
