import React from "react";
import ColumnData from "./columnData";

const ACPDashBoardHeaderColumn = ({
  op,
  col,
  onSort,
  filters,
  sortData,
  tableData,
  setFilters,
  tableRender,
  setSortData,
  saveSettings,
  ProjectFrozen,
  frozenCoulmns,
  selectedFields,
  setTableRender,
  allColumnNames,
  setFrozenColumn,
  handleSelectAll,
  setProjectFrozen,
  addFrozenColumns,
  selectAllChecked,
  projectColumnName,
  isReorderedColumn,
  projectNameOnClick,
  setReorderedColumn,
  selectedColumnName,
  availableColumnNames,
  onGlobalFilterChange,
  setProjectColumnNames,
  setSelectedColumnName,
  clearColumnWiseFilter,
  acpBookingFrozenColumn,
  accessSecurityMatrix
}) => {
  let splittedCol = col?.split("_").join(" ");

  return (
    <div style={{ minWidth: splittedCol === "SelectAll" ? "" : "" }}>
      <>
        {splittedCol === "SelectAll" ? (
          <>
            <div className="flex align-items-center">
              <input
                type="checkbox"
                checked={selectAllChecked}
                onChange={handleSelectAll}
                disabled={!accessSecurityMatrix}
                className="p-checkbox-box p-highlight"
              />
            </div>
          </>
        ) : (
          <>
            <ColumnData
              op={op}
              options={col}
              onSort={onSort}
              filters={filters}
              sortData={sortData}
              setFilters={setFilters}
              projectData={tableData}
              tableRender={tableRender}
              setSortData={setSortData}
              splittedCol={splittedCol}
              allColumns={allColumnNames}
              saveSettings={saveSettings}
              ProjectFrozen={ProjectFrozen}
              frozenCoulmns={frozenCoulmns}
              selectedFields={selectedFields}
              setTableRender={setTableRender}
              setFrozenColumn={setFrozenColumn}
              setProjectFrozen={setProjectFrozen}
              addFrozenColumns={addFrozenColumns}
              projectColumnName={projectColumnName}
              isReorderedColumn={isReorderedColumn}
              availableFields={availableColumnNames}
              setReorderedColumn={setReorderedColumn}
              projectNameOnClick={projectNameOnClick}
              selectedColumnName={selectedColumnName}
              onGlobalFilterChange={onGlobalFilterChange}
              setProjectColumnNames={setProjectColumnNames}
              setSelectedColumnName={setSelectedColumnName}
              clearColumnWiseFilter={clearColumnWiseFilter}
              acpBookingFrozenColumn={acpBookingFrozenColumn}
            />
          </>
        )}
      </>
    </div>
  );
};

export default ACPDashBoardHeaderColumn;
