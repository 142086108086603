import React from "react";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const UBDFooterButtons = ({
  accessSecurityMatrix,
  onSaveAsDraft,
  onSubmit,
  handleCancel,
  approve,
  formValid,
  checkReadWriteAccess,
  bottomFixed,
  cptFormValid,
  checkTaskISComplete,
  hideSaveButton,
  submitAllowed,
  taskStatus,
}) => {
  const location = useLocation();
  const locationPath = location?.pathname;
  const url = locationPath?.split("/");
  const pathName = url[2];

  return (
    <div
      className={bottomFixed ? "form-buttons bottom-fixed" : "form-buttons"}
      style={{ background: "#FAFAFA" }}
    >
      <Button
        className={"button-layout"}
        variant="secondary"
        onClick={() => handleCancel()}
      >
        Back
      </Button>
      {!approve && (
        <>
          {hideSaveButton ||
            (pathName !== "CNIQ" &&
              pathName !== "CCD" &&
              pathName !== "CPT" && (
                <Button
                  className={
                    !checkTaskISComplete ||checkReadWriteAccess
                      ? "button-layout"
                      : "button-layout btn btn-disabled"
                  }
                  variant="secondary"
                  onClick={() => onSaveAsDraft()}
                   disabled={
                    taskStatus === "Complete" ||
                    checkTaskISComplete ||
                    formValid ||!checkReadWriteAccess
                  }
                >
                  Save as Draft
                </Button>
              ))}
          <Button
            className="button-layout"
            type="submit"
            onClick={() => onSubmit()}
            disabled={formValid || taskStatus === "Complete" ||!checkReadWriteAccess}

          >
            Submit
          </Button>
        </>
      )}
    </div>
  );
};

export default UBDFooterButtons;
