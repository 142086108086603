import { DataTable } from "primereact/datatable";
import React from "react";
import "./DataTableGrid.scss";

function DataTableGrid({ children, data, isSearch, frozenValue,resetKey }) {
  return (
    <>
      <DataTable
        value={data}
        scrollable
        stripedRows
        showGridlines
        size={"small"}
        resizableColumns
        reorderableColumns
        rows={50}
        paginator={data?.length}
        paginatorPosition="top"
        frozenValue={frozenValue}
        tableStyle={{ width: "max-content", minWidth: "100%"}}
        filterDisplay={isSearch && "row"}
        rowClassName={(rowData) =>
          rowData.isSelected
            ? "highlight-row-planning"
            : rowData.isDraft
            ? "highlight-row-draft-planning"
            : ""
        }
        key={resetKey}
      >
        {children}
      </DataTable>
    </>
  );
}

export default DataTableGrid;
