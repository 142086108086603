import ACPFilter from "../common/ACPFilter";
import React, { useState, useEffect } from "react";
import filter from "../../../assets/images/filter.svg";
import { AcpService } from "../../../service/ACPService";

function ColumnData({
  op,
  onSort,
  options,
  filters,
  sortData,
  setFilters,
  allColumns,
  splittedCol,
  projectData,
  tableRender,
  setSortData,
  saveSettings,
  frozenCoulmns,
  ProjectFrozen,
  selectedFields,
  setTableRender,
  availableFields,
  setFrozenColumn,
  setProjectFrozen,
  resetMultiFilter,
  addFrozenColumns,
  projectColumnName,
  isReorderedColumn,
  setReorderedColumn,
  selectedColumnName,
  projectNameOnClick,
  onGlobalFilterChange,
  setProjectColumnNames,
  setSelectedColumnName,
  clearColumnWiseFilter,
  acpBookingFrozenColumn,
}) {
  const [Toggle, setToggle] = useState(false);
  useEffect(() => {
    if (Toggle) {
      document.getElementsByClassName("tab-content booking-tabs")?.[0] &&
        document
          .getElementsByClassName("tab-content booking-tabs")?.[0]
          .addEventListener("scroll", () => {
            if (!AcpService.isUBN(op?.current) || op?.current != null) {
              op?.current?.hide();
            }
          });
      document.getElementsByClassName("p-datatable-wrapper")?.[0] &&
        document
          .getElementsByClassName("p-datatable-wrapper")?.[0]
          .addEventListener("scroll", () => {
            if (!AcpService.isUBN(op?.current) || op?.current != null) {
              op?.current?.hide();
            }
          });
    }
  }, [Toggle]);
  return (
    <div className="flex-display" style={{ position: "relative" }}>
      <img
        src={filter}
        alt="Column Filter"
        onClick={(e) => {
          setToggle(!Toggle);
          projectNameOnClick(e, options);
        }}
        className={
          sortData?.includes(splittedCol) ||
            selectedFields[splittedCol]?.[0] ||
            frozenCoulmns?.includes(splittedCol)
            ? "columnFilterIcon filter-color-change"
            : "columnFilterIcon"
        }
      />
      <span
        className={
          sortData?.includes(splittedCol) ||
            selectedFields[splittedCol]?.[0] ||
            frozenCoulmns?.includes(splittedCol)
            ? "filter-color-change"
            : ""
        }
      >
        {splittedCol === "EstimatedAWPrinter" && "Art@Printer Date"}
        {splittedCol === "ArtPrinterDate" && "Art@Printer Date"}
        {splittedCol === "RemainingBuffer" && "Remaining Buffer"}
        {splittedCol === "PlannedRelease" && "Planned Release"}
        {splittedCol === "FullKitState" && "Full Kit State"}
        {splittedCol === "TaskDuration" && "Task Duration"}
        {splittedCol === "ProjectName" && "Project Name"}
        {splittedCol === "BufferToWork" && "Buffer:Work"}
        {splittedCol === "AWMProjectID" && "Project ID"}
        {splittedCol === "Duration" && "Task Duration"}
        {splittedCol === "StartDate" && "Start Date"}
        {splittedCol === "ProjectID" && "Project ID"}
        {splittedCol === "TaskType" && "Task Type"}
        {splittedCol === "TaskName" && "Task Name"}
        {splittedCol === "EndDate" && "End Date"}
        {splittedCol === "FullKit" && "Full Kit"}
        {splittedCol === "Slack" && "Slack"}

        {splittedCol !== "EstimatedAWPrinter" &&
          splittedCol !== "RemainingBuffer" &&
          splittedCol !== "ArtPrinterDate" &&
          splittedCol !== "PlannedRelease" &&
          splittedCol !== "BufferToWork" &&
          splittedCol !== "ProjectName" &&
          splittedCol !== "AWMProjectID" &&
          splittedCol !== "FullKitState" &&
          splittedCol !== "AWMProjectID" &&
          splittedCol !== "TaskDuration" &&
          splittedCol !== "StartDate" &&
          splittedCol !== "ProjectID" &&
          splittedCol !== "TaskName" &&
          splittedCol !== "Duration" &&
          splittedCol !== "TaskType" &&
          splittedCol !== "FullKit" &&
          splittedCol !== "EndDate" &&
          splittedCol !== "Slack" &&
          splittedCol}
      </span>

      <ACPFilter
        op={op}
        onSort={onSort}
        filters={filters}
        pageName="booking"
        sortData={sortData}
        setFilters={setFilters}
        allColumns={allColumns}
        splittedCol={splittedCol}
        projectData={projectData}
        tableRender={tableRender}
        setSortData={setSortData}
        saveSettings={saveSettings}
        ProjectFrozen={ProjectFrozen}
        frozenCoulmns={frozenCoulmns}
        selectedFields={selectedFields}
        setTableRender={setTableRender}
        setFrozenColumn={setFrozenColumn}
        availableFields={availableFields}
        resetMultiFilter={resetMultiFilter}
        setProjectFrozen={setProjectFrozen}
        addFrozenColumns={addFrozenColumns}
        projectColumnName={projectColumnName}
        isReorderedColumn={isReorderedColumn}
        selectedColumnName={selectedColumnName}
        setReorderedColumn={setReorderedColumn}
        onGlobalFilterChange={onGlobalFilterChange}
        setProjectColumnNames={setProjectColumnNames}
        setSelectedColumnName={setSelectedColumnName}
        clearColumnWiseFilter={clearColumnWiseFilter}
        acpBookingFrozenColumn={acpBookingFrozenColumn}
      />
    </div>
  );
}

export default ColumnData;
