import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { Toast } from "primereact/toast";
import AddProject from "../Projects/CreateProject";
import { DropdownButton, Dropdown } from "react-bootstrap";
import "primeicons/primeicons.css";
import "./index.scss";
import ConfirmationDialog from "./confirmationDialog";
import TabsComponent from "./tabsComponent";
import { hasAllAccess } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import ArtworkAlignment from "../DSBP/ArtworkAlignmentPage";
import ArtworkStatus from "../ArtworkStatus";
import ProjectPlan from "../Projects/ProjectPlan/index";
import FilesAndFolder from "../Projects/FilesAndFolder"
import { useUnsavedChangesWarning } from "../common/NavigationPrompt";

const DependencyMapping = lazy(() => import('../DSBP/DependencyMappingPage'));
let renderCount = 0

function ProjectSetup() {
  console.log('renderCount ProjectSetup', renderCount++)

  const toast = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const locationPath = location?.pathname;
  const url = locationPath?.split('/');
  const [option, setOption] = useState('');
  const [visible, setVisible] = useState(false);
  const { markDirty, markClean } = useUnsavedChangesWarning();
  const [tabName, setTabName] = useState(url[2] !== undefined ? url[2] : url[1]);

  useEffect(()=>{
    markClean();
    // writing this line because on loading the pages we need to clear unsaveChanges localstorage
  },[])

  const getData = (option) => {
    setVisible(true);
  };

  const accept = () => {
    return navigate('/myProjects');
  };

  const reject = () => {
    console.log('reject');
  };

  const isNoAccess = hasAllAccess();

  const itemsData = [
    {
      name: 'projectSetup',
      tabNameForDisplay: 'Project Setup',
      component:
        tabName === 'projectSetup' && (
          <div className="projectSetupParent project-setup-wrapper">
            <AddProject />
          </div>
        ),
    },
    {
      name: 'projectPlan',
      tabNameForDisplay: 'Project Plan',
      component: tabName === 'projectPlan' && <ProjectPlan />,
    },
    {
      name: 'artworkAlignment',
      tabNameForDisplay: 'Artwork Scope Alignment',
      component: tabName === 'artworkAlignment' && <ArtworkAlignment />,
    },
    {
      name: 'dependencyMapping',
      tabNameForDisplay: 'Dependency Mapping',
      component: tabName === 'dependencyMapping' && (
        <Suspense fallback={<LoadingOverlay active={true} spinner text="" />}>
          <DependencyMapping />
        </Suspense>
      ),
    },
    {
      name: 'artworkStatus',
      tabNameForDisplay: 'Artwork Status',
      component: tabName === 'artworkStatus' && <ArtworkStatus />,
    },
    {
      name: "files",
      tabNameForDisplay: "Files",
      component: tabName === "files" && (
        <FilesAndFolder />
      ),
    },
  ];
  const actionButton = (
    <DropdownButton
      id={!isNoAccess && 'projectActions'}
      title="Actions"
      disabled={isNoAccess}
      // data-popper-placement="bottom-end"
      // drop="down-end"
      align="end"
    >
      <Dropdown.Item
        onClick={() => getData('On Hold')}
        className="dropdownItemPaddingLeft dropdownItemColor"
      >
        On Hold
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => getData('Cancel')}
        className="dropdownItemPaddingLeft dropdownItemColor1"
      >
        Cancel
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => getData('Previous State')}
        className="dropdownItemPaddingLeft dropdownItemColor2"
      >
        Previous State
      </Dropdown.Item>
    </DropdownButton>
  );

  return (
    <div className="content-layout">
      <Toast ref={toast} />
      {visible && (
        <ConfirmationDialog
          visible={visible}
          onHide={setVisible}
          message={
            <>
              Are you sure to <span style={{ color: 'red' }}>{option}</span> the project?
            </>
          }
          header="Confirmation"
          icon="pi"
          accept={accept}
          reject={reject}
        />
      )}

      <div className="tabular-view">
        <TabsComponent
          tabName={tabName}
          items={itemsData}
          actionButton={actionButton}
          setTabName={setTabName}
          basePage={url[1]}
        />
      </div>
    </div>
  );
}

export default ProjectSetup;
