import React from "react";
import ArtworkAlignment from "./ArtworkAlignmentPage";

const DSBP = () => {

  return (
    (
      <ArtworkAlignment />
    )
  );
};

export default DSBP;
