import Api from '.';
import { DEVURL } from './envUrl';
const headers = {
  'Access-Control-Allow-Headers': 'Content-Type',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
};

export const getRoleOwnerDetailsapi = async (projectId, BU,Region) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;
  try {
    apiURL = `${DEVURL}/getRoleOwnerDetails/${BU}/${Region}/${projectId}`;
    const roleOwnerList = await axiosInstance({
      url: apiURL,
      method: 'GET',
    });
    return roleOwnerList;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateRoleAndOwnerApi = async (payload) => {
    const api = new Api();
    const axiosInstance = await api.init({ headers });
    let apiURL
    try {
        apiURL = `${DEVURL}/storeTaskOwnerDetails`;
        const storeRoleOwner = await axiosInstance({
            url: apiURL,
            method: "POST",
            data: payload,
        });
        return storeRoleOwner;
    }
    catch (error) {
        console.log(error.message)
    }
};
