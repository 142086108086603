import "./index.scss";
import { optionList } from "../../../utils";
import React, { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import filter from "../../../assets/images/filter.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import { AcpCommonService } from "../../../service/AcpCommonService";
import BlueFilterIcon from "../../../assets/images/BlueFilterIcon.svg";
import { Freeze, Frozen, SortAtoZ, clearColumnfilter, sortZtoA } from "../constant";

const ACPFilter = ({
  op,
  onSort,
  filters,
  pageName,
  sortData,
  splittedCol,
  projectData,
  saveSettings,
  ProjectFrozen,
  frozenCoulmns,
  setTableRender,
  selectedFields,
  setProjectFrozen,
  resetMultiFilter,
  addFrozenColumns,
  projectColumnName,
  selectedColumnName,
  setReorderedColumn,
  onGlobalFilterChange,
  clearColumnWiseFilter,
  setProjectColumnNames,
  acpBookingFrozenColumn,
}) => {
  const [optionListFinal, setOptionListFinal] = useState([]);
  const [columnWiseSelectedFields, setColumnWiseSelectedFields] = useState([]);
  const isFilterActivated =
    (frozenCoulmns && frozenCoulmns.length >= 2 && frozenCoulmns?.includes(selectedColumnName)) ||
    (selectedFields &&
      Object.keys(selectedFields)?.includes(selectedColumnName) &&
      selectedFields[selectedColumnName]?.length > 0) ||
    (sortData && sortData.length && sortData?.includes(selectedColumnName));

  let dateFormattedColNames = [
    "Estimated_AW_Printer",
    "Estimated_SOP",
    "Estimated_AW_Readiness",
    "Estimated_SOS",
  ];

  useEffect(() => {
    if (resetMultiFilter) {
      setColumnWiseSelectedFields([]);
    }
  }, [resetMultiFilter]);

  useEffect(() => {
    if (selectedFields && selectedColumnName) {
      const _selectedFieldsRefWithoutEmptyArray =
        AcpCommonService.removeEmptyArraysWithNoData(selectedFields);
      setColumnWiseSelectedFields(_selectedFieldsRefWithoutEmptyArray[selectedColumnName]);
      let finalFilter = [...(projectData?.length ? projectData : [])];
      for (const [key, value] of Object.entries(_selectedFieldsRefWithoutEmptyArray)) {
        if (key !== selectedColumnName) {
          finalFilter = finalFilter?.filter((el) => value?.includes(el[key]));
        }
      }
      const optionListModified = optionList(
        AcpCommonService.isFieldBlankOrSingle(
          _selectedFieldsRefWithoutEmptyArray,
          selectedColumnName
        ) || Object.keys(_selectedFieldsRefWithoutEmptyArray)?.[0] === selectedColumnName
          ? projectData
          : finalFilter,
        selectedColumnName
      )?.filter((item) => item !== "");
      setOptionListFinal(optionListModified);
    }
  }, [selectedFields, selectedColumnName]);

  const mutilSelectTemplate = (option) => {
    return <div>{dateFormattedColNames?.includes(selectedColumnName) ? option : option}</div>;
  };

  const handleToggleFreeze = () => {
    const tempSelectedColumn = selectedColumnName;
    localStorage.setItem("BookingFreezedColumnClicked", tempSelectedColumn);
    addFrozenColumns(selectedColumnName);
    setProjectFrozen(!ProjectFrozen);
    saveSettings();
    projectColumnName &&
      acpBookingFrozenColumn(
        frozenCoulmns,
        setReorderedColumn,
        setProjectColumnNames,
        setTableRender,
        op,
        projectColumnName,
        pageName,
        selectedFields,
        "bookings"
      );
  };

  const confirmPopData = () => {
    return (
      <>
        {selectedColumnName?.length && selectedColumnName !== "SelectAll" ? (
          <div className="acp-filters">
            <div className="clearAllFilterMainDiv">
              <div
                id="clearAllFilter"
                className={isFilterActivated ? "clearAllFilter" : ""}
                onClick={() => (isFilterActivated ? clearColumnWiseFilter() : null)}
              >
                {clearColumnfilter}
              </div>
              <div className="clearAllFilterDiv">
                {isFilterActivated ? (
                  <img
                    alt="filter logo"
                    src={BlueFilterIcon}
                    className="header-icons"
                    onClick={() => clearColumnWiseFilter()}
                  />
                ) : (
                  <img
                    src={filter}
                    alt="filter logo"
                    // className="header-icons"
                    // onClick={() => clearColumnWiseFilter()}
                  />
                )}
              </div>
            </div>
            <div
              id="sortZtoA"
              className="sortAndFrozen"
              onClick={() => onSort(selectedColumnName, "desc")}
            >
              {sortData &&
              sortData.length &&
              sortData[0] === selectedColumnName &&
              sortData[1] === "desc" ? (
                <div className="text-color"> {sortZtoA} </div>
              ) : (
                <div> {sortZtoA}</div>
              )}
            </div>
            <div
              id="sortAtoZ"
              className="sortAndFrozen"
              onClick={() => onSort(selectedColumnName, "asc")}
            >
              {sortData &&
              sortData.length &&
              sortData[0] === selectedColumnName &&
              sortData[1] === "asc" ? (
                <div className="text-color"> {SortAtoZ} </div>
              ) : (
                <div> {SortAtoZ}</div>
              )}
            </div>
            {/* {pageName!=='planning'&&(
              <div
                id="frozen"
                className="sortAndFrozen"
                onClick={() => {
                  handleToggleFreeze();
                }}
              >
                {frozenCoulmns?.includes(selectedColumnName) ? (
                  <div className="text-color">{Frozen} </div>
                ) : (
                  <div> {Freeze}</div>
                )}
  
              </div>
            )} */}
            <div className="multiSelect">
              <MultiSelect
                value={columnWiseSelectedFields}
                onChange={(e) => onGlobalFilterChange(e, selectedColumnName)}
                options={optionListFinal}
                itemTemplate={mutilSelectTemplate}
                filter
                placeholder={`Select ${selectedColumnName}`}
                maxSelectedLabels={3}
                className="p-column-filter"
                panelClassName="filterPopoverWidth"
              />
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className="filter-inner">
      <OverlayPanel ref={op}>{confirmPopData()}</OverlayPanel>
    </div>
  );
};
export default ACPFilter;
