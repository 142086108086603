import * as types from '../types/types';

const initialState = {
  reworkTableData: [],
  reworkMarkup: [],
  reasonRework: []
};
const ReworkMarkupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REWORK_TABLE_DATA:
      return {
        ...state,
        reworkTableData: action.payload,
      };
    case types.REWORK_MARKUPS:
      return {
        ...state,
        reworkMarkup: action.payload,
      };
    case types.REASON_REWORK:
      return {
        ...state,
        reasonRework: action.payload,
      };
    default:
      return state;
  }
};

export default ReworkMarkupReducer;

// const updateMarkUps = (arr, markUp) => {
//   const index = arr.findIndex((el) => el.Task_ID === markUp.Task_ID);
//   if (index) {
//     return [...arr[index] = markUp];
//   } else {
//     return arr.push(markUp);
//   }
// };
