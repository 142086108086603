import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import CalendarIcon from "../../common/CalendarIcon/CalendarIcon";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { changeDateFormat, bufferStyle } from "../../../utils";
import { AcpService } from "../../../service/ACPService";
import AcpCloseDialog from "../common/AcpCloseDialog";
import { popupCloseMessageText, errorMessages } from "../constant";
import { planningCloseProject } from "../../../apis/acpApi";
import { Tooltip } from "primereact/tooltip";
import acpInfo from "../../../assets/images/acpInfo.svg";
import acpError from "../../../assets/images/acpError.svg";

const TaskbodyTemplate = ({
  optionsVal,
  rowDataVal,
  handleSelect,
  selected,
  handleDateChange,
  setSaveChangesOption,
  activeSection,
  setLoadProjectTable,
  showToastMesssage,
  checkReadWriteAccess
}) => {
  const field = rowDataVal?.field;
  const baseUrl = "/allProjects/artworkStatus"; //TODO: Need to change to dynamicurl
  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const accessSecurityMatrix = checkReadWriteAccess;
  const getMinDate = () => {
    const minDate = AcpService.getMinDate(optionsVal);
    return minDate;
  };

  const handleCloseProject = (e, optionsVal) => {
    if (e.target.value === "Close") {
      setShowCloseDialog(true);
      setSelectedRowData([...selectedRowData, optionsVal]);
    } else {
      return;
    }
  };

  const handleNavigationTab = async () => {
    const response = await planningCloseProject(selectedRowData);
    if (response?.message?.toLowerCase().includes("error")) {
      showToastMesssage(response?.message, "error");
    }
    setLoadProjectTable(true);
    setShowCloseDialog(false);
  };

  const isValidProjectPrinterDate = (item) => {
    if (item?.EstimatedAWPrinter) {
      return AcpService.formatDate(optionsVal?.EstimatedAWPrinter) < new Date();
    }
    return true;
  };

  const getCorrectFormat = (value) => {
    return !AcpService.isUBN(value) && !AcpService.checkIfValidDate(value)
    ? AcpService.formatDate(value) : new Date(value)
  }

  return (
    <>
      {field === "SelectAll" && (
        <div className="select-all-checkbox align-items-center">
          <input
            type="checkbox"
            className="p-checkbox-box p-highlight"
            checked={selected?.includes(optionsVal)}
            onChange={() => handleSelect(optionsVal)}
            disabled={
              optionsVal?.isDraft === true
                ? true
                : !accessSecurityMatrix || optionsVal?.RequestStatus === "Awaiting Response"
            }
          />
        </div>
      )}
      {field === "ProjectState" && (
        <Form.Select
          value={null}
          className="select-drop"
          placeholder="Select"
          onChange={(e) => handleCloseProject(e, optionsVal)}
          disabled={optionsVal?.isDraft === true ? true : optionsVal.ProjectState === "Active"}
        >
          <option value="" className="select-drop-down">
            {optionsVal.ProjectState}
          </option>
          {optionsVal.ProjectState === "Draft" && (
            <option value="Close" className="select-drop-down">
              Close
            </option>
          )}
          {showCloseDialog && (
            <AcpCloseDialog
              setShowCloseDialog={setShowCloseDialog}
              popupCloseMessageText={popupCloseMessageText}
              showCloseDialog={showCloseDialog}
              handleNavigationTab={handleNavigationTab}
            />
          )}
        </Form.Select>
      )}

      {field === "AWMProjectID" && (
        <Link className={`task-link`} to={`${baseUrl}/${optionsVal[field]}`}>
          {optionsVal[field]}
        </Link>
      )}
      {(field === "StartDate" || field === "EstimatedAWReadiness") && (
        <>
          {!AcpService.isUBN(optionsVal[field]) && !AcpService.checkIfValidDate(optionsVal[field])
            ? changeDateFormat(optionsVal[field])
            : optionsVal[field]}
        </>
      )}
      {(field === "AWMRemainingBuffer" || field === "RemainingBuffer") &&
        bufferStyle(optionsVal[field])}
      {field === "BufferToWork" && bufferStyle(optionsVal[field])}
      {field === "NewStartDate" && activeSection === "ProjectList" && (
        <>
          {isValidProjectPrinterDate(optionsVal) ? (
            <div className="start-date-awaiting">
              <Tooltip target=".infoIcon" mouseTrack mouseTrackLeft={10} />
              <img
                className="infoIcon"
                data-pr-tooltip={errorMessages.readinessDateMsg}
                src={acpInfo}
                alt="info"
              />
            </div>
          ) : (
            <>
              <Calendar
                className="acp-calendar select-calender calendar-icon"
                value={optionsVal[field]  ? getCorrectFormat(optionsVal[field]) : null}
                onChange={(e) => {
                  handleDateChange(optionsVal, field, e.target.value);
                  setSaveChangesOption(true);
                }}
                dateFormat="d-M-y"
                disabledDays={[0, 6]}
                showIcon={true}
                locale="en"
                minDate={AcpService.getProjectMinDate(optionsVal)}
                icon={<CalendarIcon />}
                disabled={optionsVal?.isDraft === true ? true : !accessSecurityMatrix}
                maxDate={
                  optionsVal?.EstimatedAWPrinter
                    ? AcpService.formatDate(optionsVal?.EstimatedAWPrinter)
                    : null
                }
              />
            </>
          )}
        </>
      )}
      {field === "NewStartDate" && activeSection === "TaskList" && (
        <>
          {optionsVal?.RequestStatus === "Awaiting Response" ? (
            <div className="start-date-awaiting">
              {/* <span title={optionsVal["UpdatedStartDate"]}>{optionsVal["UpdatedStartDate"]}</span>  */}
              {changeDateFormat(optionsVal["UpdatedStartDate"])}
              <Tooltip target=".infoIcon" mouseTrack mouseTrackLeft={10} />
              <img
                className="infoIcon"
                data-pr-tooltip={errorMessages.enoviaMsg}
                src={acpInfo}
                alt="info"
              />
            </div>
          ) : optionsVal?.RequestStatus === "FAIL" ? (
            <div className="error-info-icon">
              <Calendar
                showIcon={true}
                dateFormat="d-M-y"
                disabledDays={[0, 6]}
                minDate={getMinDate()}
                icon={<CalendarIcon />}
                className="acp-calendar select-calender calendar-icon"
                // placeholder="Select Start Date"
                disabled={!accessSecurityMatrix}
                value={optionsVal[field]  ? getCorrectFormat(optionsVal[field]) : null}
                onChange={(e) => {
                  // options.RequestStatus = true;
                  // options[field] = convertToGMT(e.target.value);
                  handleDateChange(optionsVal, field, e.target.value);
                  setSaveChangesOption(true);
                }}
                // maxDate={AcpService.formatDate(options.PlannedRelease)}
              />
              <Tooltip target=".errorIcon" mouseTrack mouseTrackLeft={10} />
              {!optionsVal.edited && (
                <img
                  className="errorIcon"
                  // data-pr-tooltip={options.RTAStatusMessage || "Rejected"}
                  data-pr-tooltip={`Enovia update Failed. Error Code ${optionsVal?.StatusCode}.`}
                  src={acpError}
                  alt="error"
                />
              )}
            </div>
          ) : (
            <Calendar
              showIcon={true}
              dateFormat="d-M-y"
              minDate={getMinDate()}
              icon={<CalendarIcon />}
              className="acp-calendar select-calender calendar-icon"
              // placeholder="Select Start Date"
              disabledDays={[0, 6]}
              locale="en"
              disabled={optionsVal?.isDraft === true ? true : !accessSecurityMatrix}
              value={optionsVal[field]  ? getCorrectFormat(optionsVal[field]) : null}
              onChange={(e) => {
                // options[field] = convertToGMT(e.target.value);
                handleDateChange(optionsVal, field, e.target.value);
                setSaveChangesOption(true);
              }}
              // maxDate={AcpService.formatDate(optionsVal.PlannedRelease)}
            />
          )}
        </>
      )}
      {field !== "SelectAll" &&
        field !== "NewStartDate" &&
        field !== "ProjectState" &&
        field !== "AWMProjectID" &&
        field !== "StartDate" &&
        field !== "BufferToWork" &&
        field !== "RemainingBuffer" &&
        field !== "AWMRemainingBuffer" &&
        field !== "EstimatedAWReadiness" && (
          <span title={optionsVal[field]}>{optionsVal[field]}</span>
        )}
    </>
  );
};

export default TaskbodyTemplate;
