import * as types from "../types/types";
const initialState = {
  projectPlan: [],
  loading: true,
  error: null,
  projectPlanDesign: [],
  reworkHistory:[],
  projectState: "Draft",
  mode: "",
};

const projectPlanReducer = (state = initialState, action) => {
  // console.log("projectPlanReducer.action.payload: ", action);
  switch (action.type) {
    case types.UPDATE_PROJECT_PLAN:
      return {
        ...state,
        projectPlan: action.payload,
        mode: getProjectMode(action.payload),
        loading: false,
        error: null,
      };
    case types.UPDATE_PROJECT_PLAN_DESIGN:
      return {
        ...state,
        projectPlanDesign: action.payload,
        mode: action.payload,
      };
      case types.UPDATE_REWORK_HISTORY:
        return {
          ...state,
          reworkHistory: action.payload,
        };
    case types.UPDATE_PROJECT_STATE:
      return {
        ...state,
        projectState: action.payload
      };
    default:
      return state;
  }
};

const getProjectMode = (payload) => {
  if (
    payload["Project_Name"] &&
    payload["BU"] &&
    payload["Artwork_Brand"]?.length &&
    payload["Project_region"] &&
    payload["Artwork_SMO"]?.length &&
    payload["Estimated_AW_Printer"] &&
    payload["Estimated_AW_Readiness"]
  ) {
    return "design";
  } else {
    return "edit";
  }
};

export default projectPlanReducer;
