import Api from ".";
import { sanitizeFileName } from "../utils";
import { AZUREURL } from "./envUrl";

export const getSupplierFileInfo = async (
    folderStructure,
    headers = {}
) => {
    const api = new Api();
    const axiosInstance = await api.init({ headers });
    let apiURL;
    apiURL = `${AZUREURL}/versionFiles/?path=/${sanitizeFileName(folderStructure)}`;
    try {
        const response = await axiosInstance({
            url: apiURL,
            method: "POST",
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching file info", error);
        return false; // Failed download
    }
};
export const getCopyXmlSupplierFileInfo = async (
    formData,
    company,
    headers = {}
) => {
    const api = new Api();
    const axiosInstance = await api.init({ headers });
    let apiURL;
    apiURL = `${AZUREURL}/copyXmlFiles/?path=/${company}`;
    try {
        const response = await axiosInstance({
            url: apiURL,
            method: "POST",
            data: formData
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching file info", error);
        return false; // Failed download
    }
};
