import * as types from "../types/types";

export const updateToken = (data) => ({
  payload: data,
  type: types.UPDATE_TOKEN,
});

export const clearToken = () => ({
  type: types.CLEAR_TOKEN,
});
