import React, { useState, useEffect } from 'react';
import errorAlertIcon from '../../../assets/images/error-alert-icon.svg';
import errorAlertIconGreyOut from '../../../assets/images/error-alert-icon-grey-out.svg';
import './index.scss';
import { useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

function AlertIcon({
  onClick,
  iconType,
  editable,
  tooltipText,
  tooltipClass,
  showAlertIcon,
  tooltipPosition,
}) {
  const target = useRef(null);
  const [show, setShow] = useState(false);
  const [ShowIcon, setShowIcon] = useState(false);

  useEffect(() => {
    setShowIcon(showAlertIcon);
  }, [showAlertIcon]);

  return (
    <>
      {iconType === 'error' && ShowIcon ? (
        <>
          <span ref={target} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
            <img
              alt="alert"
              onClick={onClick}
              data-pr-tooltip={tooltipText}
              data-pr-position={tooltipPosition}
              className={`alert_logo ${editable ? 'grey-out' : ''}`}
              src={editable ? errorAlertIconGreyOut : errorAlertIcon}
            />
          </span>
          <Overlay target={target.current} show={show} placement="top">
            {(props) => (
              <Tooltip
                id="overlay-example"
                className={`${editable ? 'overlay-tooltip' : 'overlay-tooltip-grey-out'}`}
                {...props}
              >
                {tooltipText}
              </Tooltip>
            )}
          </Overlay>
        </>
      ) : null}
    </>
  );
}

export default AlertIcon;
