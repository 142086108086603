import Api from ".";
import { sanitizeFileName } from "../utils";
import { AZUREURL } from "./envUrl";

export const uploadtoAzureStrContainer = async (
  file,
  folderStructure,
  headers = {}
) => {

  const api = new Api();
  const axiosInstance = await api.init({ headers });

  // Determine the appropriate API endpoint based on the file extension
  let apiURL;

  apiURL = `${AZUREURL}/uploadFileInContainerPath?path=/${sanitizeFileName(folderStructure)}`;

  try {
    const formData = new FormData();
    formData.append("file", file, file.name);

    const response = await axiosInstance({
      url: apiURL,
      method: "POST",
      data: formData,
    });

    console.log("File upload response:", response);

    if (response.status === 200) {
      console.log("File uploaded successfully");
      return true; // Successful upload
    } else {
      console.error("File upload failed with status code:", response.status);
      return false; // Failed upload
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    return false; // Failed upload
  }
};
