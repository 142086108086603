import React, { useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import info from "../../../../src/assets/images/info.svg";
import { dipsalyStateValues as statusStyle } from "../../Projects/ProjectPlan/util";
import { Link, useLocation } from "react-router-dom";
import { popUpConst } from "../../../constants/reworkPageTasks";
import "./index.scss";
import { stringFormat } from "../../../Utils/GridService";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { insertMaxWords } from "../../../utils";

const ElementTemplate = ({
  optionsVal,
  rowDataVal,
  selectedNodes,
  accessSecurityMatrix,
  getMarkUps,
  onDropdownChange,
  autoVal,
  getDDoptions,
  onDurationChange,
  durationEnabled,
  className,
  RolesOwners
}) => {
  const calssNameVal = selectedNodes?.some((node) => node?.key === optionsVal?.key)
    ? "checked-row"
    : "";
  const currentUrl = useLocation().pathname;
  const field = rowDataVal?.field;
  const optionsData = optionsVal?.data;
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const constructInputUrl = (opt) => {
    const reworkUrl = window.location.href.split('/')
    const projectId = reworkUrl?.length > 0 && reworkUrl[reworkUrl?.length - 2]
    reworkUrl?.splice(reworkUrl?.length - 2, 2);//remove rework and projectID
    return `${reworkUrl?.join('/')}/RWK/${opt["Task_ID"]}/${projectId}`
  }


  const getTemplate = (opt, optionsVal) => {
    switch (field) {
      case "Task":
        if (opt["Task"].includes("Input")) {
          return (
            <Link
              className={`navigateLink  ${calssNameVal}`}
              to={constructInputUrl(opt)}
            >
              {optionsVal?.selectable === false && (
                <Checkbox className={"disabled-checkbox"} disabled></Checkbox>
              )}
              {opt[field]}
            </Link>
          );
        } else {
          return (
            <>
              <span
                className={calssNameVal}
                ref={target}
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
              >
                {optionsVal?.selectable === false && (
                  <Checkbox className={"disabled-checkbox"} disabled></Checkbox>
                )}
                {optionsData["Task"]}
              </span>

              <Overlay
                target={target.current}
                show={show}
                placement="auto"
                className="custom-overlay "
              >
                {(props) => (
                  <Tooltip id="overlay-example" {...props}>
                    <span style={{ color: "#003da5" }}> {optionsData["Task"]}</span>
                  </Tooltip>
                )}
              </Overlay>
            </>
          );
        }
      default:
        return <span></span>;
    }
  };

  return (
    <>
      {field === "Task" && getTemplate(optionsData, optionsVal)}
      {field === "Task" &&
        optionsData["code"] &&
        stringFormat(optionsData?.code) !== popUpConst.approveRTD &&
        optionsData["State"] === "Complete-Rejected" ? (
        <>
          <img
            className="infoImage"
            src={info}
            alt="info"
            onClick={() => {
              getMarkUps(optionsVal);
            }}
          />
        </>
      ) : (
        ""
      )}

      {(field === "Role" || field === "Owner") && optionsData?.Task_ID && (
        <>
          <div className="d-flex">
            <Dropdown
              value={autoVal(field, optionsVal)}
              onChange={(e) => onDropdownChange(optionsVal, e, field)}
              options={getDDoptions(field, optionsVal,RolesOwners)}
              placeholder={`Select ${field}`}
              filter
              disabled={
                optionsData.State?.includes?.("Awaiting") ||
                optionsData["Task_ID"]?.includes("CPC_") ||
                stringFormat(optionsData?.code) === popUpConst.inputRework ||
                !accessSecurityMatrix
              }
              className={`${className} ${calssNameVal}`}
            />
          </div>
        </>
      )}
      {field === "State" && statusStyle(optionsData[field])}
      {field === "TaskDuration" && optionsVal?.data[field] !== "" && optionsData?.Task_ID && (
        <InputNumber
          className={calssNameVal}
          inputId="integeronly"
          value={optionsVal?.data[field]}
          onValueChange={(e) => onDurationChange(optionsVal, e, field)}
          disabled={
            durationEnabled(optionsData.State, optionsData?.code) || !accessSecurityMatrix
          }
          min={1}
        />
      )}
      {field !== "Task" &&
        field !== "State" &&
        field !== "Role" &&
        field !== "Owner" &&
        field !== "TaskDuration" && (
          <span className={calssNameVal} title={insertMaxWords(optionsData[field])}>
            {optionsData[field]}
          </span>
        )}
    </>
  );
};

export default ElementTemplate;
