import Api from ".";
import { DEVURL } from "./envUrl";

/**
 * Change notification get api.
 *
 * @getChangeNotification
 * @param {string} InitiativeID - Initiative ID.
 * @param {string} PIMaterialID - PI material Id.
 * @param {string} PIMaterialNumber - PI material Number.
 * @param {string} ProjectID - Project Id.
 * @returns {Object} returns the response.
 */
export const getChangeNotification = async (InitiativeID,PIMaterialID,PIMaterialNumber,ProjectID, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/snapshotChanges/${InitiativeID}/${PIMaterialID}/${PIMaterialNumber}/${ProjectID}`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "GET",
  });
  let response = addResponse?.data;
  return response;
};
/**
 * api while submiting cic and fa changes.
 *
 * @onSubmitChangeManagement
 * @param {Object} formData - holds the payload data.
 * @returns {Object} returns the response.
 */
export const onSubmitChangeManagement = async (
  formData,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/confirmChangeManagement`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  let response = addResponse;
  return response;
};
/**
 * api while submiting POA changes.
 *
 * @onSubmitChangeManagement
 * @param {Object} formData - holds the payload data.
 * @returns {Object} returns the response.
 */
export const onSubmitChangeManagementPOA = async (
  formData,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/updatePOA`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  let response = addResponse;
  return response;
};

