import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import './index.scss';

const TabsComponent = ({ items, tabName, setTabName, basePage }) => {
  const projectPlan = useSelector((state) => state.ProjectPlanReducer);
  const projectSetupData = useSelector((state) => state.ProjectSetupReducer);

  const assignee = projectPlan?.projectPlanDesign?.find((item) => (item.AWM_Task_ID.includes('SAA') && item.GroupName === 'Input'))?.Assignee || null;

  const navigate = useNavigate();
  let { ProjectID } = useParams();

  const {
    userDetails: { AccessGroup },
  } = useSelector((state) => state.UserDetailsReducer);

  const handleClick = (item) => {
    if (ProjectID !== undefined) {
      if (item === 'artworkAlignment') {
        navigate(`/${basePage}/${item}/${assignee}/${ProjectID}`);
      } else {
        navigate(`/${basePage}/${item}/${ProjectID}`);
      }
    } else {
      navigate(`/${item}`);
    }
    setTabName(item);
  };

  const isProjectSetupAccessible = () => {
    const findManager = AccessGroup?.find((ele) => ele.AccessGroupNames.includes('Manager'));
    return Boolean(findManager);
  };

  let specialProject = false;
  if (projectSetupData?.specialProjectFlag) {
    specialProject = projectSetupData?.specialProjectFlag;
  }

  return (
    <>
      <div className="tabComponent">
        <div className="tabs-wrapper">
          <ul id="scroller" className="nav nav-tabs">
            {items.map((obj, index) =>
              obj.tabNameForDisplay === 'Project Setup' ? (
                isProjectSetupAccessible() &&
                specialProject === 'No' && (
                  <li key={index + 1} className={`${obj.name === tabName ? 'active' : ''}`}>
                    <a data-toggle="tab" onClick={() => handleClick(obj.name)}>
                      {obj.tabNameForDisplay}
                    </a>
                  </li>
                )
              ) : obj.tabNameForDisplay === 'Artwork Status' && specialProject === 'Yes' ? (
                <li key={index + 1} className={`${obj.name === tabName ? 'active' : ''}`}>
                  <a data-toggle="tab" onClick={() => handleClick(obj.name)}>
                    {obj.tabNameForDisplay}
                  </a>
                </li>
              ) : (
                specialProject === 'No' && (
                  <li key={index + 1} className={`${obj.name === tabName ? 'active' : ''}`}>
                    <a data-toggle="tab" onClick={() => handleClick(obj.name)}>
                      {obj.tabNameForDisplay}
                    </a>
                  </li>
                )
              )
            )}
          </ul>
        </div>
      </div>
      <div className="tab-content">
        {items.map((obj, index) => (
          <div
            key={index + 1}
            id={`${obj.name}`}
            className={`tab-pane fade ${obj.name === tabName ? 'in active' : ''}`}
          >
            {obj.component}
          </div>
        ))}
      </div>
    </>
  );
};

export default TabsComponent;
