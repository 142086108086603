import Api from ".";
import { DEVURL } from "./envUrl";
import { store } from "../store/store";
import { userDetailsActions } from "../store/actions/UserDetailsActions";

export const getUserDetails = async (
  userEmailId,
  headers = {
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
  }
) => {
  // debugger;
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  // expecting api changes meanwhile i am using local mockJson 
   let apiURL = `${DEVURL}/getuserdetails/${userEmailId}`;
  // let apiURL = getUserData;

  try {
      const response = await axiosInstance({
        url: apiURL,
        method: "GET",
      });
    // Check for successful response status, e.g., 200 OK
    // const response = await getUserData;
    
    if (response?.status === 200) {
      const getUserDetailsData = response?.data?.ArtworkAgilityPage;
      store.dispatch(userDetailsActions(getUserDetailsData));
      return getUserDetailsData;
    }
  } catch (error) {
    if (error.message.includes('net::ERR_CONNECTION_TIMED_OUT')) {
      // Handle the server being down
      return { error: 'The server is currently unavailable. Please try again later.' };
    } else {
      console.error("Error fetching project plan:", error);
      throw error; // Re throw the error for other types of errors
    }
  }

};
