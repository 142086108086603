import {
  hallowGreyCircle,
  hallowRedCircle,
  inProgressLate,
  redCross,
  greenV,
  hallowGreenCircle,
  solidGreenCircle,
  NA,
  UnallocatedNA,
  UnallocatedHallowGreyCircle,
  UnallocatedSolidGreenCircle,
} from './constant';

export const returnColumnWidth = (columnName, ele, columnWidthArtworkStatus) => {
  switch (ele) {
    case 'DesignFlag':
      return 'Design';

    default:
      return columnName;
  }
};

export const cicImage = (imageName) => {
  switch (imageName) {
    case 'HallowGreyCircle':
      return 'pi pi-circle-off footerIconAll footerIcons7';

    case 'HallowRedCircle':
      return 'pi pi-circle-off footerIconAll footerIcons6';

    case 'SolidRedCircle':
      return 'pi pi-circle-on footerIconAll footerIcons9';

    case 'RedCross':
      return 'pi pi-times footerIconAll footerIcons4';

    case 'GreenV':
      return 'pi pi-check footerIconAll footerIcons3';

    case 'HallowGreenCircle':
      return 'pi pi-circle-off footerIconAll footerIcons5';

    case 'SolidGreenCircle':
      return 'pi pi-circle-on footerIconAll footerIcons8';
    
    case 'Awaiting':
      return 'pi pi-circle-off footerIconAll footerIcons7';

    case 'In Progress':
      return 'pi pi-circle-on footerIconAll footerIcons8';

    case 'Complete':
      return 'pi pi-check footerIconAll footerIcons3';

    default:
      return '';
  }
};

export const cicOptions = (option) => {
  switch (option) {
    case 'HallowGreyCircle':
      return 'Fullkit not ready, task start date > today';

    case 'HallowRedCircle':
      return 'Fullkit not ready, task start date =< today has context menu';

    case 'SolidRedCircle':
      return 'In-progress - late';

    case 'RedCross':
      return 'Job rejected - until Rework is initiated';

    case 'GreenV':
      return 'Job completed';

    case 'HallowGreenCircle':
      return 'Fullkit ready';

    case 'SolidGreenCircle':
      return 'In-progress (fullkit was already ready)';

    case 'Awaiting':
      return 'Stage not started';

    case 'In Progress':
      return 'Stage in progress';

    case 'Complete':
      return 'Job completed';

    default:
      return option;
  }
};

export const findRCICTaskId = (taskDetails) => {
  const taskWithRCIC = taskDetails?.filter((item) => item?.AWMTaskID?.includes('RCIC'));
  return taskWithRCIC?.[0]?.AWMTaskID;
};

export const artworkStatusExportCSVFlags = (imageName, isSpecialProject=false) => {
  switch (imageName) {
    case 'HallowGreyCircle':
      return hallowGreyCircle;

    case 'HallowRedCircle':
      return hallowRedCircle;

    case 'SolidRedCircle':
      return inProgressLate;

    case 'RedCross':
      return redCross;

    case 'GreenV':
      return greenV;

    case 'HallowGreenCircle':
      return hallowGreenCircle;
      
    case 'SolidGreenCircle':
      return solidGreenCircle;

    case 'NA':
      if(isSpecialProject){
        return UnallocatedNA;
      }else{
        return NA;
      }

    case 'Awaiting':
      return UnallocatedHallowGreyCircle;

    case 'In Progress':
      return UnallocatedSolidGreenCircle;

    case 'Complete':
      return greenV;

    default:
      return imageName;
  }
};

export const getStatusFlags = ({ state }) => {
  let statusFlags, text;

  switch (state) {
    case 'Active':
      statusFlags = 'active-status';
      text = 'Active';
      break;
    case 'Complete':
      statusFlags = 'completed-status';
      text = 'Complete';
      break;

    default:
      statusFlags = 'not-started-status';
      text = 'Not Started';
  }

  return { statusFlags, text }; // Return as an object, not an array
};
