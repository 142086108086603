import { AcpService } from "../../service/ACPService";
import * as types from "../types/types";

const initialState = {
  acpPlanningData: [],
  acpPlanningProjectCapacityValuesData: [],
  acpPlanningTaskCapacityValuesData: [],
  acpPlanningProjectListData: [],
  RTATasksUpdateResponse: [],
  RTATasksUpdateError: "null",
  rootBreadCrumb: "Capacity Management",
  acpPlanningFilters: {},
  taskDraftChanges: [],
  projectDraftChanges: [],
};

const acpPlanning = (state = initialState, action) => {
  switch (action.type) {
    case types.ACP_PLANNING_SET_DEFAULT_FILTERS:
      // let defaultFilters = Object.assign(state, {...AcpService.getInitialFilterState()});
      return {
        ...state,
        // ...defaultFilters,
        acpPlanningFilters : AcpService.getInitialFilterState(),
      };
      
    case types.ACP_PLANNING_SET_FILTERS:
      let newFilters = Object.assign(state, {...action.payload});
      return{
        ...state,
        ...newFilters,
      };  
    case types.ACP_PLANNING_TASK_DATA:
      return {
        ...state,
        acpPlanningData: action.payload,
        RTATasksUpdateResponse: [],
        RTATasksUpdateError:"null",
      };
    case types.ACP_PLANNING_PROJECT_CAPACITY_VALUES_DATA:
      return {
        ...state,
        acpPlanningProjectCapacityValuesData: action.payload,
      };
    case types.ACP_PLANNING_TASK_CAPACITY_VALUES_DATA:
        return {
          ...state,
          acpPlanningTaskCapacityValuesData: action.payload,
        };  
    case types.ACP_PLANNING_UPDATE_RTA_TASKS_RESPONSE:
      return {
        ...state,
        RTATasksUpdateResponse: action.payload,
        RTATasksUpdateError:getErrorsfromResponse(action.payload)
      };
     
    case types.ACP_PLANNING_PROJECT_DATA:
      return {
        ...state,
        acpPlanningProjectListData: action.payload,
      }; 
    
    case types.ACP_PLANNING_SET_TASK_DRAFT:
      return {
        ...state,
        taskDraftChanges: action.payload,
      }; 

    case types.ACP_PLANNING_SET_PROJECT_DRAFT:
        return {
          ...state,
          projectDraftChanges: action.payload,
        };   

    case types.ACP_PLANNING_EMPTY_ERROR_DATA:
      return {
        ...state,
        RTATasksUpdateResponse: [],
        RTATasksUpdateError:"null",
      }

    default:
      return state;
  }
};
export default acpPlanning;



const getErrorsfromResponse = (response) => {
  let errorMsgs = [];
  let RTATasksUpdateResponse = [];
  if(Object.keys(response).length && (response?.RTATasksPage || response?.ErrorMessage)){
    RTATasksUpdateResponse = response?.RTATasksPage || [];
    response?.ErrorMessage && errorMsgs.push(response?.ErrorMessage)
  }
    if (RTATasksUpdateResponse && RTATasksUpdateResponse.length) {
      RTATasksUpdateResponse.forEach((resObj) => {
        if (resObj?.RTATaskUpdateStatus?.includes("Failed")) {
          errorMsgs.push(resObj.TaskName + " : " + resObj.RTATaskUpdateMessage);
        }
      });
    }

    return errorMsgs;
}
