/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, useState, useEffect, useRef, Suspense, useMemo, useCallback } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProjectService } from '../../service/PegaService';
import ProjectListFilter from '../Projects/ProjectListFilter';
import { FilterMatchMode } from 'primereact/api';
import ArtworkStatusListHeader from './ArtworkStatusListHeader';
import { getArtworkStatusList } from '../../apis/artworkStatusApi';
// import { getArtworkStatusList } from "../../store/actions/ArtworkStatusActions";
import { onSortData } from '../../utils';
import _ from 'lodash';
import ArtworkStatusProjectNameHeader from './ArtworkStatusProjectNameHeader';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { cicImage, returnColumnWidth, artworkStatusExportCSVFlags, getStatusFlags } from './utils';
import { getProjectValues } from '../../store/actions/ProjectSetupActions';
import AlertIcon from '../common/AlertIcon/AlertIcon';
import { dsbpConstant } from '../DSBP/constant';
import { checkIfGreyIcon, checkIfRedIcon } from '../DSBP/utils';
import Footer from "../common/tableFooter/tableFooter";
import {
  hallowGreyCircle,
  hallowRedCircle,
  inProgressLate,
  redCross,
  greenV,
  hallowGreenCircle,
  solidGreenCircle,
} from "./constant";

const FullKitConditionPopUp = lazy(() => import('./FullKitConditionPopUp'));

const ArtworkStatusList = (props) => {
  const [pegadata, setPegaData] = useState(null);
  const [isCICAddded, setIsCICAddded] = useState(false);
  const [initpegadata, setInitPegaData] = useState(null);
  const [ProjectFrozen, setProjectFrozen] = useState(false);
  const [frozenCoulmns, setFrozenColumn] = useState([]);
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const [projectColumnName, setProjectColumnNames] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [filters, setFilters] = useState([]);
  const [visible, setVisible] = useState(false);
  const [sortData, setSortData] = useState([]);
  const [allColumnNamesArtworkStatus, setAllColumnNames] = useState([]);
  const [updatedAllColumnNames, setUpdatedAllColumnNames] = useState([]);
  const [isSearch, isSearchSet] = useState(false);
  const [isReorderedColumn, setReorderedColumn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isColWidthSet, setColWidth] = useState(null);
  const [customViewIsAddedMyProject, isCustomViewIsAddedArtworkStatus] = useState(false);
  const [openFullKitpopUp, setOpenFullkitPopUp] = useState(false);
  const [filterCleared, setFilterCleared] = useState(false);
  const [rowFullkitData, setRowFullkitData] = useState(null);
  const [fieldName, setFieldName] = useState(null);
  const [pmpList, setPMPList] = useState([]);
  const [artworkStatus, artworkStatusList] = useState([]);
  const [initialColumnLength, setinitialColumnLength] = useState({});
  const [customizeViewFields, setCustomizeViewFields] = useState(
    localStorage.getItem('customizeViewArtworkStatusFields')
  );

  const headerName = 'Artwork Status';

  const { loading } = false;
  let { ProjectID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filterArray = useRef([]);
  const selectedColumnList = useRef([]);
  const currentUrl = useLocation().pathname.split('/');
  const { userInformation } = useSelector((state) => state.UserReducer);

  let isCIC = isCICAddded;

  const searchHeader = projectColumnName.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.Field_Name]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    }),
    {}
  );

  const onSearchClick = () => {
    isSearchSet(!isSearch);
  };

  const op = useRef(null);
  const dt = useRef(null);

  const onSort = (column, direction) => (event) => {
    const sortedData = onSortData(column, direction, pegadata);
    let sortedColName = JSON.stringify([column]);
    localStorage.setItem('sortingDataArtworkStatus', sortedColName);
    setPegaData(sortedData);
    setSortData([column, direction]);
  };

  useEffect(() => {
    dispatch(getProjectValues(ProjectID));
    const columnNamesInitial = ProjectService.getAllColumnNames();
    setinitialColumnLength({ availableFields: 0, freezedColumns: 0, selectedFields: columnNamesInitial.length })
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const updatedUsers = await getArtworkStatusList(ProjectID);
        setIsCICAddded(updatedUsers?.CICs);
        const pmpList = updatedUsers?.PMPList || [];
        setPMPList(pmpList);
        artworkStatusList(updatedUsers);
        setPegaData(pmpList);
        setInitPegaData(pmpList);
      } catch (error) {
        console.error(error);
        // Handle errors here if needed
      }
      setLoader(false);
    };

    fetchData();
  }, [ProjectID]);

  useEffect(() => {
    const ProjectData = _.cloneDeep(pmpList);
    let allCol = [];
    if (ProjectData.length) {
      allCol = Object.keys(ProjectData[0]);
    }
    if (!isCIC) {
      allCol = allCol.filter((item) => item !== 'AWM_CICNeeded');
    }
    if (isCIC && !allCol.includes('AWM_CICNeeded')) allCol.splice(10, 0, 'AWM_CICNeeded');

    let columnWidthArtworkStatus = {
      PIMaterialDescription: 200,
      POADescription: 200,
    };

    // if (allCol.length) {
    //   allCol.forEach((column) => {
    //     columnWidthArtworkStatus[column] = 200;
    //   });
    // }

    let getJsonStoredWidthColumns = localStorage.getItem('columnWidthArtworkStatus');
    let getStoredWidthColumns = JSON.parse(getJsonStoredWidthColumns);
    const checkEmptyObject = _.isEmpty(getStoredWidthColumns);

    if (checkEmptyObject) {
      if (!isCIC) {
        getStoredWidthColumns = getStoredWidthColumns?.filter((item) => item !== 'AWM_CICNeeded');
      }
      if (isCIC && !getStoredWidthColumns?.includes('AWM_CICNeeded'))
        getStoredWidthColumns?.splice(10, 0, 'AWM_CICNeeded');

      localStorage.setItem('columnWidthArtworkStatus', JSON.stringify(columnWidthArtworkStatus));
    }
    let jsonColWidth = localStorage.getItem('isColWidthSetArtworkStatus');
    let isColWidthSetFlag = JSON.parse(jsonColWidth);
    if (isColWidthSetFlag) {
      setColWidth(true);
    }

  }, [pmpList]);

  const reorderColumns = (columns) => {
    const requiredColumnOrderArray = ProjectService.getAllColumnNames();
    let reorderedColumns = [];
    requiredColumnOrderArray.forEach((rcl) => {
      columns.forEach((cl) => {
        if (rcl.Field_Name === cl) {
          reorderedColumns.push(cl);
        }
      });
    });
    return reorderedColumns;
  };

  useEffect(() => {
    setCustomizeViewFields(customizeViewFields);
  }, [customizeViewFields]);

  useEffect(() => {
    (async () => {
      try {
        //below is api call
        const ProjectData = _.cloneDeep(pmpList);

        if (ProjectData.length) {
          let allCol = Object.keys(ProjectData[0]);
          if (!isCIC) {
            allCol = allCol.filter((item) => item !== 'AWM_CICNeeded');
          }
          if (isCIC && !allCol.includes('AWM_CICNeeded')) allCol.splice(10, 0, 'AWM_CICNeeded');

          if (!allCol.includes('Design')) allCol.splice(9, 0, 'Design');

          if (!allCol.includes('FA')) allCol.splice(12, 0, 'FA');

          setAllColumnNames(reorderColumns(allCol));
        }

        let filteredPegaDataJson = localStorage.getItem('columnWiseFilterDataArtworkStatus');
        const filteredPegaData = JSON.parse(filteredPegaDataJson);

        if (filteredPegaData && filteredPegaData.length) {
          setFilters(filteredPegaData);
          setSelectedFields(filteredPegaData);
          setPegaData(ProjectData);
          setInitPegaData(ProjectData);
        } else setPegaData(ProjectData);

        // according to pathname we need to call api and store column name in local storage
        let columnNamesJson = localStorage.getItem('allColumnNamesArtworkStatus');
        const columnNames = JSON.parse(columnNamesJson);
        const fieldToAdd = { Field_Name: 'AWM_CICNeeded' };
        if (columnNames != null && columnNames.length) {
          if (isCIC && !columnNames.some((item) => item.Field_Name === fieldToAdd.Field_Name)) {
            columnNames.splice(15, 0, fieldToAdd);
          }
          // columnNames.splice(10, 0, "AWM_CICNeeded");
          setProjectColumnNames(columnNames);
          setUpdatedAllColumnNames(columnNames);
        } else {
          let columnNames = ProjectService.getAllColumnNames();
          if (!isCIC) {
            columnNames = columnNames.filter((item) => item.Field_Name !== 'AWM_CICNeeded');
          }
          // columnNames.splice(10, 0, "AWM_CICNeeded");
          if (isCIC && !columnNames.some((item) => item.Field_Name === fieldToAdd.Field_Name)) {
            columnNames.splice(15, 0, fieldToAdd);
          }

          localStorage.setItem('allColumnNamesArtworkStatus', JSON.stringify(columnNames));
          setProjectColumnNames(columnNames);
          setUpdatedAllColumnNames(columnNames);
        }
        // get sort data from local storage and add in state
        let jsonSortingData1 = localStorage.getItem('sortingDataArtworkStatus');
        const sortingDataArtworkStatus = JSON.parse(jsonSortingData1);

        if (sortingDataArtworkStatus && sortingDataArtworkStatus.length) {
          const sortedData = [...ProjectData].sort((a, b) => {
            return a[sortingDataArtworkStatus[0]] > b[sortingDataArtworkStatus[0]] ? 1 : -1;
          });

          if (sortingDataArtworkStatus[1] === 'desc') {
            sortedData.reverse();
          }
          setPegaData(sortedData);
          setSortData([sortingDataArtworkStatus[0], sortingDataArtworkStatus[1]]);
        }


        //get frozen data from local storage and add in state
        let jsonFrozenrData1 = localStorage.getItem('frozenDataArtworkStatus');
        const frozenDataArtworkStatus = JSON.parse(jsonFrozenrData1);
        if (frozenDataArtworkStatus && frozenDataArtworkStatus.length) {
          setFrozenColumn(frozenDataArtworkStatus);
        }
      } catch (err) {
        console.log('error', err);
      }
    })();
  }, [pmpList]);

  const addFrozenColumns = (name) => {
    if (!frozenCoulmns.includes(name)) {
      frozenCoulmns.push(name);
      setFrozenColumn(frozenCoulmns);
    } else {
      let columnIndex = frozenCoulmns.indexOf(name);
      frozenCoulmns.splice(columnIndex, 1);
      setFrozenColumn(frozenCoulmns);
    }
  };

  const projectNameOnClick = (e, options) => {
    setFilterCleared(false);
    op.current.toggle(e);
    setSelectedColumnName(options);
  };

  const StatusFlags = ({ state }) => {
    const { statusFlags, text } = getStatusFlags({ state }); // Pass state as an object
    return (
      <div>
        <span
          className={statusFlags}
          style={{
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '8rem',
            fontSize: '1rem',
            textAlign: 'center',
            borderRadius: '4px',
          }}
        >
          {text}
        </span>
      </div>
    );
  };

  const fullKitReadinessBody = (options, rowData) => {
    let field = rowData.field;

    const showRedIcon = checkIfRedIcon(options);
    const showGreyIcon = checkIfGreyIcon(options);
    const showAlertIcon = showRedIcon || showGreyIcon;
    return (
      <>
        {/* {field === 'SI.No' && <span title={rowData.rowIndex + 1}>{rowData.rowIndex + 1}</span>} */}
        {field === 'FAAssemblyState' && <StatusFlags state={options.FAAssemblyState} />}
        {field === 'FAApproval' && <span>{options.FAApproval}</span>}
        {field === 'CountryOfSales' && (
          <span title={options.CountryOfSales}>{options.CountryOfSales}</span>
        )}
        {field === 'ApprovedCopyElements' && (
          <span title={options.ApprovedCopyElements + '/' + options.TotalCopyElements}>
            {options.ApprovedCopyElements + '/' + options.TotalCopyElements}
          </span>
        )}
        {field === 'POAReleaseDate' && (
          <span title={options[field]}>{options[field]}</span>
        )}
        {field === 'AWPrinterDate' && (
          <span title={options[field]}>{options[field]}</span>
        )}
        {field === 'FA' && (
          <div
            className="cic-tooltip-target"
            title={artworkStatusExportCSVFlags(options['FAFlag'])}
          >
            {options['FAFlag'] === 'NA' ? (
              <div>{options['FAFlag']}</div>
            ) : options['FAFlag']?.includes('|') ? (
              <>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setOpenFullkitPopUp(true);
                    setRowFullkitData(options);
                    setFieldName('fa');
                  }}
                >
                  {options['FAFlag']}
                </div>
              </>
            ) : (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpenFullkitPopUp(true);
                  setRowFullkitData(options);
                  setFieldName('fa');
                }}
              >
                <div
                  onClick={() => {
                    if (options['FAFlag'] === 'GreenV') {
                      navigate(`/${currentUrl[1]}/projectPlan/${ProjectID}`)
                    } else {
                      setOpenFullkitPopUp(true);
                      setRowFullkitData(options);
                      setFieldName('fa');
                    }
                  }}
                >
                  <div className="footerIconsMain">
                    <div>
                      <i className={`footerIconAll ${cicImage(options['FAFlag'])}`}></i>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {field === 'Design' && (
          <div
            className="cic-tooltip-target"
            title={artworkStatusExportCSVFlags(options['DesignFlag'])}
          >
            {options['DesignFlag'] === 'NA' ? (
              <div title={artworkStatusExportCSVFlags(options['DesignFlag'])}> {options['DesignFlag']}</div>
            ) : (
              <div className="footerIconsMain">
                <div title={artworkStatusExportCSVFlags(options['DesignFlag'])}>
                  <i className={`${cicImage(options['DesignFlag'])}`}></i>
                </div>
              </div>
            )}
          </div>
        )}

        {field === 'AWM_CICNeeded' && (
          <div className="cic-img">
            <div
              className="cic-tooltip-target"
              title={artworkStatusExportCSVFlags(options['CICFlag'])}
            >
              {options['CICFlag'] === 'NA' || options['CICFlag'] === '' || !options['CICFlag'] ? (
                <div title={artworkStatusExportCSVFlags(options['CICFlag'])}> {options['CICFlag']}</div>
              ) : options['CICFlag']?.includes('|') ? (
                <>
                  <div
                    title={artworkStatusExportCSVFlags(options['CICFlag'])}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setOpenFullkitPopUp(true);
                      setRowFullkitData(options);
                      setFieldName('cic');
                    }}
                  >
                    {options['CICFlag']}
                  </div>
                </>
              ) : (
                <>
                  <div
                    title={artworkStatusExportCSVFlags(options['CICFlag'])}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setOpenFullkitPopUp(true);
                      setRowFullkitData(options);
                      setFieldName('cic');
                    }}
                  >
                    <div className="footerIconsMain">
                      <div title={artworkStatusExportCSVFlags(options['CICFlag'])}>
                        <i className={`${cicImage(options['CICFlag'])}`}></i>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {field === 'PIMaterialNumber' && (
          <>
            <div className="snapshot-icons" disabled={props?.userHasAccess}>
              <span title={options[field]} className="text-option">
                {options[field]}
              </span>
            </div>
            <AlertIcon
              iconType="error"
              showAlertIcon={showAlertIcon}
              onClick={() => {
                navigate(`/${currentUrl[1]}/artworkStatus/ChangeNotification/${ProjectID}`, {
                  state: { from: 'artworkstatusPage', assignee: userInformation?.userid, InitiativeID: options.DSBP_InitiativeID, PIMaterialID: options.PIMaterialID, PIMaterialNumber: options.PIMaterialNumber },
                });
              }}
              editable={showGreyIcon}
              tooltipClass="tooltip-white"
              tooltipText={
                showGreyIcon
                  ? dsbpConstant.xml_awaiting_response_enovia
                  : dsbpConstant.xml_view_changes
              }
              tooltipPosition={'top'}
            />
          </>
        )}

        {field === "POAStatus" && <span>{options.RTA_RTAPOAStatus}</span>}

        {field !== 'Artwork_Status' &&
          field !== 'ApprovedCopyElements' &&
          field !== 'POAReleaseDate' &&
          field !== 'AWM_CICNeeded' &&
          field !== 'AWPrinterDate' &&
          field !== 'CICFlag' &&
          field !== 'FAFlag' &&
          field !== 'FAAssemblyState' &&
          field !== 'CountryOfSales' &&
          field !== 'FAApproval' &&
          field !== 'PIMaterialNumber' &&
          field !== 'POAStatus' &&
          field !== 'DesignFlag' && (
            <>
              {' '}
              <span title={options[field]}>{options[field]}</span>{' '}
            </>
          )}
      </>
    );
  };

  const costomizeFrozenData = () => {
    let jsonValue
    if (customizeViewFields !== undefined && customizeViewFields?.length !== 0) {
      jsonValue = customizeViewFields ? JSON.parse(customizeViewFields) : null;
    }
    let freezedData;
    if (jsonValue && Object.keys(jsonValue)?.length !== 0) {
      freezedData = jsonValue?.freezedColumns?.fieldsData || [];
      freezedData && setFrozenColumn(freezedData);
      localStorage.setItem('frozenDataArtworkStatus', JSON.stringify(freezedData));
    }
    return freezedData;
  };

  useEffect(() => {
    costomizeFrozenData();
    if (customizeViewFields && customizeViewFields === '[]') {
      setFrozenColumn([]);
      localStorage.setItem('frozenDataArtworkStatus', JSON.stringify({}));
    }
    const parsedData = customizeViewFields !== "" && customizeViewFields?.length !== 0 ? JSON.parse(customizeViewFields) : []
    let selectedData = parsedData?.selectedFields?.fieldsData;
    if (Object.keys(initialColumnLength).length > 0 && selectedData?.length > 0 && (initialColumnLength?.selectedFields !== selectedData?.length)) { isCustomViewIsAddedArtworkStatus(true) }
    else {
      isCustomViewIsAddedArtworkStatus(false)
    }

  }, [customizeViewFields]);

  const dynamicColumns = useMemo(() => {
    let jsonColumnWidthMyProject = localStorage.getItem('columnWidthArtworkStatus');
    const columnWidthArtworkStatus = JSON.parse(jsonColumnWidthMyProject);
    let checkWidth = [];
    if (columnWidthArtworkStatus) {
      checkWidth = Object.keys(columnWidthArtworkStatus);
    }

    const uniqueArray = [...new Set(projectColumnName)];

    let jsonValue
    if (customizeViewFields !== undefined && customizeViewFields?.length !== 0) {
      jsonValue = customizeViewFields ? JSON.parse(customizeViewFields) : null;
    }

    if (jsonValue && Object.keys(jsonValue).length !== 0) {
      let selectedData = jsonValue?.selectedFields?.fieldsData || [];
      let freezedData = jsonValue?.freezedColumns?.fieldsData || [];
      const mergedData = [...freezedData, ...selectedData];

      const filteredColumns = [];
      // Add freezedData columns in the specified order
      // freezedData && setFrozenColumn(freezedData);
      mergedData?.forEach((fieldName) => {
        const column = uniqueArray?.find((col) => col.Field_Name === fieldName);
        if (column) {
          filteredColumns.push(column);
        }
      });
      if (filteredColumns && filteredColumns.length) {

        return filteredColumns?.map((ele, i) => {
          return (
            <Column
              key={ele.Field_Name}
              field={ele.Field_Name}
              filterField={ele.Field_Name}
              header={ArtworkStatusProjectNameHeader(
                ele.Field_Name,
                frozenCoulmns,
                sortData,
                projectNameOnClick
              )}
              columnKey={ele.Field_Name || i}
              frozen={frozenCoulmns?.includes(ele.Field_Name)}
              alignFrozen="left"
              className={frozenCoulmns?.includes(ele.Field_Name) ? 'font-bold' : ''}
              filter
              showFilterMenu={false}
              filterPlaceholder={ele.Field_Name}
              body={fullKitReadinessBody}
              // {... checkWidth.includes(ele.Field_Name) && }
              style={{
                width: returnColumnWidth(
                  columnWidthArtworkStatus[ele.Field_Name],
                  ele.Field_Name,
                  columnWidthArtworkStatus
                ),
              }}
            />
          );
        });
      }
    } else {
      if (uniqueArray && uniqueArray?.length) {
        return uniqueArray?.map((ele, i) => {
          if (!isCIC && ele.Field_Name === 'AWM_CICNeeded') {
            // Skip rendering the column
            return null;
          }

          return (
            <Column
              key={ele.Field_Name}
              field={ele.Field_Name}
              filterField={ele.Field_Name}
              header={ArtworkStatusProjectNameHeader(
                ele.Field_Name,
                frozenCoulmns,
                sortData,
                projectNameOnClick
              )}
              columnKey={ele.Field_Name || i}
              frozen={frozenCoulmns?.includes(ele.Field_Name)}
              alignFrozen="left"
              className={frozenCoulmns?.includes(ele.Field_Name) ? 'font-bold' : ''}
              filter
              showFilterMenu={false}
              filterPlaceholder={ele.Field_Name}
              body={fullKitReadinessBody}
              // {... checkWidth.includes(ele.Field_Name) && }
              style={{
                width: returnColumnWidth(
                  columnWidthArtworkStatus[ele.Field_Name],
                  ele.Field_Name,
                  columnWidthArtworkStatus
                ),
              }}
            />
          );
        });
      }
    }
  }, [projectColumnName, frozenCoulmns, sortData, projectNameOnClick]);



  const clearFilters = () => {
    const allColumnNamesArtworkStatus = ProjectService.getAllColumnNames();
    // const ProjectData = _.cloneDeep(pmpList);
    // let allCol = [];
    // if (ProjectData.length) {
    //   allCol = Object.keys(ProjectData[0]);
    // }
    // let columnWidthArtworkStatus = {};
    let columnWidthArtworkStatus = {
      PIMaterialDescription: 200,
      POADescription: 200,
    };
    // if (allCol.length) {
    //   allCol.forEach((column) => {
    //     columnWidthArtworkStatus[column] = 200;
    //   });
    // }
    setProjectColumnNames(allColumnNamesArtworkStatus);
    localStorage.setItem(
      'allColumnNamesArtworkStatus',
      JSON.stringify(allColumnNamesArtworkStatus)
    );
    localStorage.setItem('columnWiseFilterDataArtworkStatus', JSON.stringify({}));
    const costomizeFrozenData1 = costomizeFrozenData();

    if (costomizeFrozenData1 === undefined) {
      setFrozenColumn([]);
      localStorage.setItem('frozenDataArtworkStatus', JSON.stringify({}));
    }
    localStorage.setItem('sortingDataArtworkStatus', JSON.stringify({}));
    localStorage.setItem('columnWidthArtworkStatus', JSON.stringify(columnWidthArtworkStatus));
    localStorage.removeItem('isColWidthSetArtworkStatus');
    localStorage.setItem('frozenDataArtworkStatus', JSON.stringify({}));
    setColWidth(false);
    setSelectedFields([]);
    setSortData([]);
    setReorderedColumn(false);
    setFilters([]);
    setVisible(false);
    setFilterCleared(true);
    setPegaData(initpegadata);
    isSearchSet(false);
    setCustomizeViewFields([])
    setFrozenColumn([]);
    setProjectFrozen(false)
  };

  const onGlobalFilterChange = useCallback(
    (e, colName) => {
      const value = e.value;
      let temp = cloneDeep(selectedFields);
      temp[colName] = value;

      setSelectedFields(temp);

      let allValues = [];
      let keys = Object.keys(temp);
      keys.forEach((key) => {
        allValues = [...allValues, ...temp[key]];
      });
      filterArray.current.push(value[0]);
      const artworkCategories = allValues;
      if (selectedColumnList.current.length === 0 || artworkCategories.length > 1) {
        selectedColumnList.current = pegadata;
      }
      if (filterArray.current.length) {
        let filterProjectState = selectedColumnList.current.filter((item) => {
          if (item) {
            // Check if the selected values include the item directly (for text values)
            // const hasValue = artworkCategories?.includes(item[colName]);
            let hasValue;
            if (colName === "ApprovedCopyElements") {
              hasValue = artworkCategories?.includes(
                `${item[colName]}/${item["TotalCopyElements"]}`
              );
            } else {
              hasValue = artworkCategories?.includes(item[colName]);
            }

            // If it's not a direct match for text, format the date and check for matches
            if (!hasValue) {
              const formattedDate = moment(item[colName], 'YYYYMMDDTHHmmss.SSS [GMT]').format(
                'DD-MMM-YY'
              );
              return artworkCategories?.includes(formattedDate);
            }

            return hasValue;
          }
          return false;
        });
        selectedColumnList.current = filterProjectState;
        setFilters(filterProjectState);
      } else {
        selectedColumnList.current = [];
        setSelectedFields([]);
        setFilters([]);
      }
    },
    [selectedFields, pegadata]
  );

  const onColumnResizeEnd = (event) => {
    let columnWidthArtworkStatus = {};
    let jsonColumnWidthMyProject = localStorage.getItem('columnWidthArtworkStatus');
    if (jsonColumnWidthMyProject) {
      columnWidthArtworkStatus = JSON.parse(jsonColumnWidthMyProject);
    }
    columnWidthArtworkStatus[event.column.props.field] = event.element.offsetWidth;

    localStorage.setItem('columnWidthArtworkStatus', JSON.stringify(columnWidthArtworkStatus));
    localStorage.setItem('isColWidthSetArtworkStatus', JSON.stringify(true));
    setColWidth(true);
    setProjectColumnNames(projectColumnName);
    setVisible(false);
  };

  const saveSettings = () => {
    localStorage.setItem('columnWiseFilterDataArtworkStatus', JSON.stringify(filters));
    localStorage.setItem('frozenDataArtworkStatus', JSON.stringify(frozenCoulmns));
    localStorage.setItem('sortingDataArtworkStatus', JSON.stringify(sortData));
    localStorage.setItem('allColumnNamesArtworkStatus', JSON.stringify(projectColumnName));
  };
  // not used currently
  // const saveAsPersonaliDefault = (selectedCategories) => {
  //   const columnNames = JSON.stringify(reorderColumns(selectedCategories));
  //   localStorage.setItem('allColumnNamesArtworkStatus', columnNames);
  //   setProjectColumnNames(reorderColumns(selectedCategories));
  //   if (selectedCategories.length > 9) {
  //     localStorage.setItem('isCustomViewIsAddedArtworkStatus', JSON.stringify(true));
  //     isCustomViewIsAddedArtworkStatus(true);
  //   }
  //   if (selectedCategories.length === 0) {
  //     isCustomViewIsAddedArtworkStatus(true);
  //   }
  //   setVisible(false);
  // };

  // const resetToPgDefault = async () => {
  //   const allColumnNamesArtworkStatus = ProjectService.getAllColumnNames();
  //   localStorage.setItem('allColumnNamesArtworkStatus', JSON.stringify({}));
  //   localStorage.setItem(
  //     'allColumnNamesArtworkStatus',
  //     JSON.stringify(allColumnNamesArtworkStatus)
  //   );
  //   localStorage.removeItem('isCustomViewIsAddedArtworkStatus');
  //   isCustomViewIsAddedArtworkStatus(true);
  //   setProjectColumnNames(allColumnNamesArtworkStatus);
  //   setVisible(false);
  //   // setTableRender(true);
  // };

  const storeReorderedColumns = (e) => {
    const dragColumnName = projectColumnName[e?.dragIndex];
    const index = projectColumnName.indexOf(dragColumnName);
    if (index > -1) {
      // only splice array when item is found
      projectColumnName.splice(index, 1); // 2nd parameter means remove one item only
      projectColumnName.splice(e?.dropIndex, 0, dragColumnName);
      setReorderedColumn(true);
    }
    localStorage.setItem('allColumnNamesArtworkStatus', JSON.stringify(projectColumnName));
    setProjectColumnNames(projectColumnName);
    // setTableRender(!tableRender);
  };

  const clearFilter = () => {
    const costomizeFrozenData1 = costomizeFrozenData();
    localStorage.setItem('columnWiseFilterDataArtworkStatus', JSON.stringify({}));
    localStorage.setItem('sortingDataArtworkStatus', JSON.stringify({}));
    if (costomizeFrozenData1 === undefined) {
      setFrozenColumn([]);
      localStorage.setItem('frozenDataArtworkStatus', JSON.stringify({}));
    }
    setSelectedFields([]);
    setSortData([]);
    setFilters([]);
    setPegaData(initpegadata);
    isSearchSet(false);
    // setTableRender(!tableRender);
  };

  const clearColumnWiseFilter = () => {
    let jsonFrozenItem = localStorage.getItem('frozenDataArtworkStatus');
    const frozenItem = JSON.parse(jsonFrozenItem);
    if (frozenItem && frozenItem.length && frozenItem.includes(selectedColumnName)) {
      const index = frozenItem.indexOf(selectedColumnName);
      frozenItem.splice(index, 1);
      localStorage.setItem('frozenDataArtworkStatus', JSON.stringify(frozenItem));
      setFrozenColumn(frozenItem);
    }
    if (frozenCoulmns.includes(selectedColumnName)) {
      const index = frozenCoulmns.indexOf(selectedColumnName);
      frozenCoulmns.splice(index, 1);
      setFrozenColumn(frozenCoulmns);
      setProjectFrozen(!ProjectFrozen);
    }
    let jsonSortItem = localStorage.getItem('sortingDataArtworkStatus');
    const sortItem = JSON.parse(jsonSortItem);
    if (sortItem && sortItem.length && sortItem[0] === selectedColumnName) {
      localStorage.setItem('sortingDataArtworkStatus', JSON.stringify([]));
      setPegaData(initpegadata);
    }
    if (sortData && sortData.length && sortData[0] === selectedColumnName) {
      setSortData([]);
    }
    if (filters && filters.length) {
      localStorage.setItem('columnWiseFilterDataArtworkStatus', JSON.stringify({}));
      setSelectedFields([]);
      setFilters([]);
    }
  };

  const isFilterEnabled =
    (customizeViewFields && customizeViewFields === '[]' && frozenCoulmns?.length) ||
    filters?.length ||
    sortData?.length ||
    isSearch;
  // let columnWidth = localStorage.getItem("columnWidthArtworkStatus");
  // const jsonColumnWidth = JSON.parse(columnWidth);

  const isResetEnabled =
    isReorderedColumn || isFilterEnabled || isColWidthSet || customViewIsAddedMyProject;

  const timestamp = new Date().getTime();

  const artworkstatusfooter = (
    <div>
      <div className="footerIcons">
        <div className="footerIconsMain">
          <div>
            <i className="pi pi-info footerIconAll footerIcons2"></i>
          </div>
          <div className="footerIconsContent1">Snapchange alert is true</div>
        </div>
        <div className="footerIconsMain">
          <div>
            <i className="pi pi-check footerIconAll footerIcons3"></i>
          </div>
          <div className="footerIconsContent1">{greenV}</div>
        </div>

        <div className="footerIconsMain">
          <div>
            <i className="pi pi-times footerIconAll footerIcons4"></i>
          </div>
          <div className="footerIconsContent1">{redCross}</div>
        </div>

        <div className="footerIconsMain">
          <div>
            <i className="pi pi-circle-off footerIconAll footerIcons5"></i>
          </div>
          <div className="footerIconsContent1">{hallowGreenCircle}</div>
        </div>

        <div className="footerIconsMain">
          <div>
            <i className="pi pi-circle-off footerIconAll footerIcons6"></i>
          </div>
          <div className="footerIconsContent1">
            <span> {hallowRedCircle}</span>
          </div>
        </div>

        <div className="footerIconsMain ">
          <div>
            <i className="pi pi-circle-off footerIconAll footerIcons7"></i>
          </div>
          <div className="footerIconsContent1">
            <span>{hallowGreyCircle}</span>
          </div>
        </div>

        <div className="footerIconsMain">
          <div>
            <i className="pi pi-circle-on footerIconAll footerIcons8"></i>
          </div>
          <div className="footerIconsContent1">
            <span style={{ paddingTop: "10px" }}>{solidGreenCircle}</span>
          </div>
        </div>

        <div className="footerIconsMain">
          <div>
            <i className="pi pi-circle-on footerIconAll footerIcons9"></i>
          </div>
          <div className="footerIconsContent1">
            <span>{inProgressLate}</span>
          </div>
        </div>

        <div className="footerIconsMain">
          <div className="footerIcons10 footerIconAll">
            <span>2/1/0</span>
          </div>
          <div className="footerIconsContent1">
            <span>Approvers / Approved / Rejected</span>
          </div>
        </div>

        <div className="footerIconsMain">
          <div className="footerIcons11 footerIconAll">
            <span>N/A</span>
          </div>
          <div className="footerIconsContent1">
            <span>
              Design: No design stage for this FA [or] CIC: CIC Needed = 'No' or
              'NA'
            </span>
          </div>
        </div>
      </div>
      <div className='recordfooter'>
        {!loader && pegadata && (
          <Footer className="footer" totalObjects={pegadata.length} isSelected={false} />)}
      </div>
    </div>
  );

  return (
    <div className="artworkStatus">
      {loader || loading || pegadata === null ? (
        <LoadingOverlay active={true} spinner text="" />
      ) : (
        <Suspense fallback={<LoadingOverlay active={true} spinner text="" />}>
          {pegadata !== undefined && (
            <ArtworkStatusListHeader
              header={artworkStatus?.ProjectName}
              clearFilters={clearFilters}
              clearFilter={clearFilter}
              setVisible={setVisible}
              saveSettings={saveSettings}
              onSearchClick={onSearchClick}
              isFilterEnabled={isFilterEnabled}
              isResetEnabled={isResetEnabled}
              allData={pegadata}
              headers={allColumnNamesArtworkStatus}
              filterFLag={false}
              CustomizeViewFlag={false}
              ResetToDefaultFlag={false}
              isTreeTableFlag={false}
              AWPrinterDate={artworkStatus?.AWPrinterDate}
              projectColumnName={projectColumnName}
              headerName={headerName}
              customizeViewFields={customizeViewFields}
              setCustomizeViewFields={setCustomizeViewFields}
            />
          )}
          <ProjectListFilter
            onSort={onSort}
            setProjectFrozen={setProjectFrozen}
            saveSettings={saveSettings}
            projectData={pegadata}
            addFrozenColumns={addFrozenColumns}
            onGlobalFilterChange={onGlobalFilterChange}
            selectedColumnName={selectedColumnName}
            ProjectFrozen={ProjectFrozen}
            selectedFields={selectedFields}
            setFrozenColumn={setFrozenColumn}
            frozenCoulmns={frozenCoulmns}
            sortData={sortData}
            setSortData={setSortData}
            setFilters={setFilters}
            filters={filters}
            op={op}
            clearColumnWiseFilter={clearColumnWiseFilter}
          />

          <DataTable
            resizableColumns
            dataKey="PIMaterialID"
            // key={generateUniqueKey()}
            key={filterCleared ? `"PIMaterialID" + ${timestamp}` : ''}
            reorderableColumns
            onColReorder={storeReorderedColumns}
            value={filters.length ? filters : pegadata}
            scrollable
            // responsiveLayout="scroll"
            className="mt-3"
            columnResizeMode="expand"
            onColumnResizeEnd={onColumnResizeEnd}
            filters={searchHeader}
            filterDisplay={isSearch && 'row'}
            ref={dt}
            tableStyle={{
              width: 'max-content',
              minWidth: '100%',
            }}
            footer={artworkstatusfooter}
          >
            {dynamicColumns}
          </DataTable>
          <div hidden={filters?.length ? filters?.length === 0 : pegadata?.length === 0}>
            <FullKitConditionPopUp
              openFullKitpopUp={openFullKitpopUp}
              setOpenFullkitPopUp={setOpenFullkitPopUp}
              rowFullkitData={rowFullkitData}
              setRowFullkitData={setRowFullkitData}
              fieldName={fieldName}
              artworkStatus={artworkStatus}
            />
          </div>
        </Suspense>
      )}
    </div>
  );
};
export default ArtworkStatusList;