import React, { useState, useEffect } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { SuggestionsMatch } from "./helpers";
import { isObject } from "lodash";

const CommonAutocomplete = ({
  suggestions,
  value,
  onChange,
  onSelect,
  placeholder,
  field,
  disabled,
  columnName,
  className,
  width,
  setDropDownMatch = () => { }
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const setFilteredSuggestionsFun = (inputValues) => {
    const updatedInputValue = inputValues && isObject(inputValues) ? inputValues[field] : inputValues
    const filtered = suggestions?.filter((item) =>
      item[field]?.toLowerCase()?.includes(updatedInputValue?.toLowerCase())
    );
    if (filtered !== null && filtered !== undefined && filtered !== "")
      setFilteredSuggestions(filtered);
  }
  const completeMethod = (e) => {
    const inputValues = e.query;
    setFilteredSuggestionsFun(inputValues)
  };
  const handleInputClick = (e) => {
    if (field === "Person") {
      const inputValues = e?.target?.value;
      if (!filteredSuggestions?.length && inputValues) {
        setFilteredSuggestionsFun(inputValues)//AWM-3710
      }
    }
  }
  const handleInputChange = (e) => {
    const inputValues = e?.target?.value;
    if (inputValues === null) {
      setInputValue("");
    } else {
      setInputValue(inputValues);
    }
    if (columnName === "Role" || columnName === "Owner" || columnName === "Assignee") {
      if (
        inputValues && isObject(inputValues) &&
        filteredSuggestions?.every((item) => item?.[field]?.toLowerCase() !== inputValues?.[field]?.toLowerCase())
      ) {
        onChange({ target: { value: "" } });
      } else if (
        inputValues && !isObject(inputValues) &&
        filteredSuggestions?.every((item) => item?.[field]?.toLowerCase() !== inputValues?.toLowerCase())
      ) {
        onChange({ target: { value: "" } });
      } else {
        onChange(e);
      }
    } else {
      onChange(e);
    }
    if (e?.value && e?.value !== null) {
      if (SuggestionsMatch(suggestions, e?.value)) {
        setDropDownMatch(true);
      } else {
        setDropDownMatch(false);
      }
    }
    else {
      setDropDownMatch(true);
    }
  };

  return (
    <AutoComplete
      value={inputValue}
      suggestions={filteredSuggestions}
      completeMethod={completeMethod}
      field={field}
      onSelect={onSelect}
      onChange={handleInputChange}
      onClick={handleInputClick}//AWM-3710
      placeholder={placeholder}
      dropdown
      forceSelection
      disabled={disabled}
      className={className}
      panelStyle={{ borderRadius: "4px", width: width }}
    />
  );
};

export default CommonAutocomplete;
