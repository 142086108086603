import React, { useEffect, useRef, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { DataScroller } from 'primereact/datascroller';
import { Col, Row } from 'react-bootstrap';
import { MultiSelect } from 'primereact/multiselect';
import FooterButtons from '../AWMJobs/DesignJobs/FooterButtons';
import _ from 'lodash';
import deleteIcon from '../../assets/images/deleteIcon.svg';
import plusCollapseImg from '../../assets/images/plusCollapse.svg';
import { getManageDependencyData, saveManageDependencyData } from '../../apis/manageDependencyApi';
import { useSelector } from 'react-redux';
import { usePriorityNew } from '../../Utils/GetRolePriority';
import { useLocation } from 'react-router-dom';
import { showBackgroundProcess } from '../../Utils/helpers';
import { Toast } from "primereact/toast";

const jobsOrders = ['DI', 'DT', 'IQ', 'NPF', 'CIC', 'CICMatrix', 'PMP', 'Artwork'];

function ManageDependencyTable({
  handleCancel,
  ProjectID,
  setShowManageDependency,
  setShowErrorMessage,
  getApiParentPage,
  Assignee,
  finalPermission
}) {
  const toast = useRef(null);
  const [loader, setLoader] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [dropdownlist, setDropdownList] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [manageDependencyData, setManageDependencyData] = useState([]);
  const [saveData, setSaveData] = useState({});
  const { manageDependencyScreenData, loading, error } = useSelector(
    (state) => state.ManageDependencyReducer
  );
  const location = useLocation();
  const currentUrl = location.pathname;
  let currentUrlBasePage = currentUrl?.split('/')[1];
  // const accessSecurityMatrix = usePriorityNew(currentUrlBasePage);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    //call GET API to fetch MD response data and set it in redux as apiResponse
    setLoader(true);
    await getManageDependencyData(ProjectID);
    setLoader(false);
  };

  useEffect(() => {
    // console.log("Redux:manageDependencyScreenData", manageDependencyScreenData);
    setDropdownList(manageDependencyScreenData.DropdownList);
    setResponseData(manageDependencyScreenData.SuccessorPredecessorList);
  }, [manageDependencyScreenData]);

  useEffect(() => {
    responseData?.forEach((obj) => {
      obj.dropdownlist = dropdownlist.filter((item) => {
        return item.JobId !== obj.Job.JobId;
      });
    });
    let orderedData = orderSuccessorJobs(responseData);
    setManageDependencyData(orderedData);
  }, [responseData]); // eslint-disable-line react-hooks/exhaustive-deps

  const orderSuccessorPredeccessorsByJobId = (array, type) =>{
    let sortedData = array?.sort((a, b) => {
      let jobA = type === "Successor" ? a?.Job?.JobId : a?.JobId;
      let jobB = type === "Successor" ? b?.Job?.JobId : b?.JobId;
      const numA = parseInt(jobA?.split('Job-')[1], 10);
      const numB = parseInt(jobB?.split('Job-')[1], 10);
      return numA - numB;
    });
    return sortedData
  }

  const orderSuccessorJobs = (data) => {
    const orderedData = [];
    jobsOrders.forEach((ordItem) => {
      let filteredData = data?.filter((dt) => {
        return dt.Job.JobId.split('_')[0] === ordItem;
      });
      filteredData?.length && orderSuccessorPredeccessorsByJobId(filteredData, "Successor")
      filteredData && orderedData.push(...filteredData);
    });

    orderedData.forEach((data) => {
      data.dropdownlist =
        data.PrecedecessorList.length && Object.keys(data.PrecedecessorList[0]).includes('Action')
          ? orderByJobs(data.dropdownlist)
          : orderOptionsbySelectionsAndJobs(data.PrecedecessorList, data.dropdownlist);
    });
    return orderedData;
  };

  const orderOptionsbySelectionsAndJobs = (dependencies, dropdownlist) => {
    //logic for rearranging the options inside dropdown
    // console.log("dependencies, dropdownlist", dependencies, dropdownlist);
    const orderedDropdownList = [];

    // let selectedDefinedOptionsOrdered = orderByJobs(dependencies);
    let selectedDefinedOptionsFilteredData = [];
    dropdownlist.forEach((item) => {
      let foundDependency = dependencies.find((dependency) => {
        return dependency.JobId === item.JobId;
      });
      if (foundDependency) {
        selectedDefinedOptionsFilteredData.push(item);
      }
    });

    let nonSelectedDefinedFilteredData = [];
    let nonSelectedAwaitingFilteredData = [];
    dropdownlist.forEach((item) => {
      let foundDependency = dependencies.find((dependency) => {
        return dependency.JobId === item.JobId;
      });
      if (
        !foundDependency &&
        (item.Status === 'Approved' || item.Status === 'Defined' || item.Status === 'CIC MATRIX AND CIC'|| item.Status === 'CIC MATRIX ONLY' || item.Status === 'Draft')
      ) {
        nonSelectedDefinedFilteredData.push(item);
      }
      if (!foundDependency && (item.Status === 'Awaiting' || item.Status === 'In-Progress')) {
        nonSelectedAwaitingFilteredData.push(item);
      }
    });

    let selectedDefinedOptionsOrdered = orderByJobs(selectedDefinedOptionsFilteredData);
    let nonSelectedDefinedOptionsOrdered = orderByJobs(nonSelectedDefinedFilteredData);
    let nonSelectedAwaitingOptionsOrdered = orderByJobs(nonSelectedAwaitingFilteredData);

    orderedDropdownList.push(
      ...[
        ...selectedDefinedOptionsOrdered,
        ...nonSelectedDefinedOptionsOrdered,
        ...nonSelectedAwaitingOptionsOrdered,
      ]
    );
    return orderedDropdownList;
  };
  const orderByJobs = (dependencies) => {
    const orderedDependencies = [];
    jobsOrders.forEach((ordItem) => {
      let filteredData =
        dependencies.length &&
        !Object.keys(dependencies[0]).includes('Action') &&
        dependencies?.filter((dep) => {
          return dep.JobId.split('_')[0] === ordItem;
        });
      filteredData?.length && orderSuccessorPredeccessorsByJobId(filteredData, "Predecessor")
      filteredData && orderedDependencies.push(...filteredData);
    });
    return orderedDependencies;
  };

  const multiSelectOnChange = (updatedPredecessor, successorId) => {
    // console.log("onChangeMultiselect", updatedPredecessor, successorId);
    const clonedData = _.cloneDeep(manageDependencyData);
    clonedData.forEach((obj) => {
      if (obj.Job.JobId === successorId) {
        obj.PrecedecessorList = updatedPredecessor;
      }
    });
    setManageDependencyData(clonedData);
  };

  const createDataForSave = (successorId, updatedPredecessor, userAction) => {
    const clonedUpdatedPredecessor = _.cloneDeep(updatedPredecessor);
    if (saveData.SuccessorPredecessorList?.length) {
      let tempSaveData = _.cloneDeep(saveData);
      const successorIdDataFound = tempSaveData.SuccessorPredecessorList.filter((obj) => {
        return obj.Job.JobId === successorId;
      });
      if (successorIdDataFound?.length) {
        tempSaveData.SuccessorPredecessorList.forEach((obj) => {
          if (obj.Job.JobId === successorId) {
            const foundData = obj.PrecedecessorList.filter((pl) => {
              return pl.JobId == clonedUpdatedPredecessor.JobId;
            });
            // console.log(
            //   "foundData:",
            //   obj,
            //   clonedUpdatedPredecessor.JobId,
            //   foundData
            // );
            if (foundData?.length) {
              obj.PrecedecessorList.forEach((pl, index) => {
                if (pl.JobId === clonedUpdatedPredecessor.JobId) {
                  if (!pl.UserAction) {
                    delete clonedUpdatedPredecessor.Status;
                    pl.UserAction = userAction;
                  } else {
                    obj.PrecedecessorList.splice(index, 1);
                  }
                }
              });
            } else {
              delete clonedUpdatedPredecessor.Status;
              clonedUpdatedPredecessor.UserAction = userAction;
              obj.PrecedecessorList.push(clonedUpdatedPredecessor);
            }
          }
        });
      } else {
        let filteredData = manageDependencyData.filter((obj) => {
          return obj.Job.JobId === successorId;
        });
        // console.log("filteredData", filteredData);
        let temp = {};
        temp.Job = filteredData[0].Job;
        temp.PrecedecessorList = [];
        delete clonedUpdatedPredecessor.Status;
        clonedUpdatedPredecessor.UserAction = userAction;
        temp.PrecedecessorList.push(clonedUpdatedPredecessor);
        tempSaveData.SuccessorPredecessorList.push(temp);
        // setSaveData(tempSaveData);
      }

      setSaveData(tempSaveData);
    } else {
      let filteredData = manageDependencyData.filter((obj) => {
        return obj.Job.JobId === successorId;
      });
      // console.log("filteredData", filteredData);
      let tempSaveData = {};
      tempSaveData.SuccessorPredecessorList = [];
      let temp = {};
      temp.Job = filteredData[0].Job;
      temp.PrecedecessorList = [];
      delete clonedUpdatedPredecessor.Status;
      clonedUpdatedPredecessor.UserAction = userAction;
      temp.PrecedecessorList.push(clonedUpdatedPredecessor);
      tempSaveData.SuccessorPredecessorList.push(temp);
      setSaveData(tempSaveData);
    }
  };

  useEffect(() => {
    // console.log("saveData", saveData);
    const filteredData = saveData?.SuccessorPredecessorList?.filter((obj) => {
      return obj.PrecedecessorList.length > 0;
    });
    if (filteredData?.length > 0) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [saveData]);

  const addDependency = (successorId, addedPredecessor) => {
    const clonedData = _.cloneDeep(manageDependencyData);
    clonedData.forEach((obj) => {
      if (obj.Job.JobId === successorId) {
        const filteredData = obj.PrecedecessorList.filter((dep) => {
          return dep.JobId === addedPredecessor.JobId;
        });
        if (!filteredData.length) {
          obj.PrecedecessorList.push(addedPredecessor);
        }
      }
      obj.dropdownlist =
        obj.PrecedecessorList.length && Object.keys(obj.PrecedecessorList[0]).includes('Action')
          ? orderByJobs(obj.dropdownlist)
          : orderOptionsbySelectionsAndJobs(obj.PrecedecessorList, obj.dropdownlist);
    });
    setManageDependencyData(clonedData);
    createDataForSave(successorId, addedPredecessor, 'ADD');
  };

  const removeDependency = (successorId, removedPredecessor) => {
    const clonedData = _.cloneDeep(manageDependencyData);
    clonedData.forEach((obj) => {
      if (obj.Job.JobId === successorId) {
        const updatedPredecessor = obj.PrecedecessorList.filter((dep) => {
          return dep.JobId !== removedPredecessor.JobId;
        });
        obj.PrecedecessorList = updatedPredecessor;
      }
      obj.dropdownlist =
        obj.PrecedecessorList.length && Object.keys(obj.PrecedecessorList[0]).includes('Action')
          ? orderByJobs(obj.dropdownlist)
          : orderOptionsbySelectionsAndJobs(obj.PrecedecessorList, obj.dropdownlist);
    });
    setManageDependencyData(clonedData);
    createDataForSave(successorId, removedPredecessor, 'DELETE');
  };

  const checkDisabled = (option, successorId, action) => {
    // if (option.Status !== 'Awaiting') {
      if (action === 'add') {
        return manageDependencyData
          .filter((obj) => {
            return obj.Job.JobId === successorId;
          })[0]
          .PrecedecessorList.filter((obj) => {
            return obj.JobId === option.JobId;
          }).length;
      } else if (action === 'delete') {
        return !manageDependencyData
          .filter((obj) => {
            return obj.Job.JobId === successorId;
          })[0]
          .PrecedecessorList.filter((obj) => {
            return obj.JobId === option.JobId;
          }).length;
      }
    // } else {
      else if (action === 'name') {
        return !manageDependencyData
          .filter((obj) => {
            return obj.Job.JobId === successorId;
          })[0]
          .PrecedecessorList.filter((obj) => {
            return obj.JobId === option.JobId;
          }).length;
      } else {
        return 1;
      }
    // }
  };

  const predecessorOptionTemplate = (option, successorId) => {
    // console.log(option, successorId);
    return (
      <div className="dependency-options" onClick={(e) => e.stopPropagation()}>
        <div
          className={`option-name ${checkDisabled(option, successorId, 'name') && 'disable-icons'}`}
          onClick={(e) => e.stopPropagation()}
        >
          {option.JobName}
        </div>
        <div>
          <img
            src={plusCollapseImg}
            className={checkDisabled(option, successorId, 'add') && 'disable-icons'}
            onClick={(e) => {
              // console.log("predecessorOptionTemplate", option, e, successorId);
              addDependency(successorId, option);
            }}
            alt=""
            style={{ height: 12, marginRight: 12 }}
          />

          <img
            src={deleteIcon}
            alt="delete"
            onClick={(e) => {
              e.stopPropagation();
              removeDependency(successorId, option);
            }}
            className={checkDisabled(option, successorId, 'delete') && 'disable-icons'}
            style={{ height: 12 }}
          />
        </div>{' '}
      </div>
    );
  };

  const onSubmit = async () => {
    const filteredData = saveData?.SuccessorPredecessorList?.filter((obj) => {
      return obj.PrecedecessorList.length > 0;
    });
    if (filteredData?.length > 0) {
      const submitData = { SuccessorPredecessorList: filteredData };
      // console.log("SubmitData:", submitData);
      setLoader(true);
      const response = await saveManageDependencyData(ProjectID, submitData);
      // alert(responseStatus);
      
      if (response.status === 200) {
        if (response?.data?.pyMessage === 'Fail') {
          setShowErrorMessage(true);
          setFormValid(false);
          setLoader(false);
        } else {          
          showBackgroundProcess(toast);
          setTimeout(()=>{
            setShowManageDependency(false);
            setLoader(false);
            getApiParentPage();
          },4000)          
          
          
        }
      }
    }
  };

  const getJobStatusFromDropdownList = (jobId) => {
    let job = dropdownlist.find((obj) => {
      return obj.JobId === jobId;
    });
    return job ? job?.Status : '';
  };

  const itemTemplate = (data) => {
    return (
      <div>
        <Row className="jobs">
          <Col className="successor">
            <div className="successor-text">{data.Job.JobName}</div>
          </Col>
          <Col className="predecessor">
            {data.PrecedecessorList.length &&
            Object.keys(data.PrecedecessorList[0]).includes('Action') ? (
              <div className="predecessor-text">{'Null'}</div>
            ) : (
              <>
                <MultiSelect
                  className="predecessor-select"
                  value={data.PrecedecessorList}
                  onChange={(e) => multiSelectOnChange(e.value, data.Job.JobId)}
                  options={data.dropdownlist}
                  optionLabel="JobName"
                  // disabled={data.Job.Status !== "Awaiting"}
                  //if a design job is of CICMatrix/Artwork(Status for CICMatrix / Artwork can be anything) or other DesignJobID status is draft/in-progress/complete/approved then the user cannot change its dependency                  
                  disabled={
                    (getJobStatusFromDropdownList(data.Job.JobId) !== 'Awaiting' &&
                    getJobStatusFromDropdownList(data.Job.JobId) !== 'Defined' &&
                    getJobStatusFromDropdownList(data.Job.JobId) !== 'Create Failed' &&
                    getJobStatusFromDropdownList(data.Job.JobId) !== 'Created'&&
                    getJobStatusFromDropdownList(data.Job.JobId) !== 'CIC MATRIX ONLY'&&
                    getJobStatusFromDropdownList(data.Job.JobId) !== 'CIC MATRIX AND CIC') ||
                    data?.Job?.JobId?.split("_")[0] === "CICMatrix" ||
                    data?.Job?.JobId?.split("_")[0] === "Artwork" ||
                    !finalPermission
                  }
                  placeholder="Please Select"
                  filter
                  itemTemplate={(option) => predecessorOptionTemplate(option, data.Job.JobId)}
                  maxSelectedLabels={0}
                  panelClassName="md-multiselect-dropdown"
                />
                {orderByJobs(data.PrecedecessorList).map((obj) => {
                  return (
                    <div className="selected-dependency">
                      <div className="dependency-name">{obj.JobName}</div>
                      {finalPermission &&
                       getJobStatusFromDropdownList(data.Job.JobId) !== 'Draft' &&
                       getJobStatusFromDropdownList(data.Job.JobId) !== 'In-Progress' &&
                       getJobStatusFromDropdownList(data.Job.JobId) !== 'Complete' &&
                       getJobStatusFromDropdownList(data.Job.JobId) !== 'Approved' &&
                       data?.Job?.JobId?.split("_")[0] !== "CICMatrix" &&
                       data?.Job?.JobId?.split("_")[0] !== "Artwork" && (
                        <div>
                          <img
                            src={deleteIcon}
                            alt="delete"
                            className="sel-dep-del"
                            onClick={(e) => {
                              e.stopPropagation();
                              // console.log("on delete", data.Job.JobId, obj);
                              removeDependency(data.Job.JobId, obj);
                            }}
                            style={{ height: 12 }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <LoadingOverlay active={loader || loading} spinner text="">
      <Toast ref={toast} />
      <div>
        <Row className="datascroller-header">
          <Col className="ds-headers">Task</Col>
          <Col className="ds-headers">Predecessor</Col>
        </Row>
        <Row>
          <Col className="ds-subheaders">Job Name</Col>
          <Col className="ds-subheaders">Job Name</Col>
        </Row>
        <Row className="data-scroller" disabled={!finalPermission}>
          <DataScroller value={manageDependencyData} itemTemplate={itemTemplate} rows={100} />
        </Row>
        <Row>
          <FooterButtons
            hideSaveButton={true}
            submitAndSave="Save"
            handleCancel={handleCancel}
            checkReadWriteAccess={true}
            formValid={!formValid}
            onSubmit={onSubmit}
            manageDependencyFlag={true}
            Assignee={Assignee}
          />
        </Row>
      </div>
    </LoadingOverlay>
  );
}

export default ManageDependencyTable;
