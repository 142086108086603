export const timeAgo = (timestamp) => {
  // Extract individual components from the timestamp string
  const year = parseInt(timestamp.substr(0, 4));
  const month = parseInt(timestamp.substr(4, 2)) - 1; // Months are 0-indexed in JavaScript
  const day = parseInt(timestamp.substr(6, 2));
  const hour = parseInt(timestamp.substr(9, 2));
  const minute = parseInt(timestamp.substr(11, 2));
  const second = parseInt(timestamp.substr(13, 2));

  // Create a new Date object with the extracted components
  const date = new Date(Date.UTC(year, month, day, hour, minute, second));
  const now = new Date();

  const elapsed = now - date;

  // Convert elapsed time to days, hours, and minutes
  const days = Math.floor(elapsed / (1000 * 60 * 60 * 24));
  const hours = Math.floor((elapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));

  // Construct the time ago string
  let timeAgoStr = "";
  if (days > 0) {
    timeAgoStr = `${days} Day(s) ago`;
  } else if (hours > 0) {
    timeAgoStr = `${hours} hour(s) ago`;
  } else {
    timeAgoStr = `${minutes} min(s) ago`;
  }

  return timeAgoStr;
};

export const dateFormat = (timestamp) => {
  const year = parseInt(timestamp.substr(0, 4));
  const month = parseInt(timestamp.substr(4, 2)) - 1; // Months are 0-indexed in JavaScript
  const day = parseInt(timestamp.substr(6, 2));
  const hour = parseInt(timestamp.substr(9, 2));
  const minute = parseInt(timestamp.substr(11, 2));
  const second = parseInt(timestamp.substr(13, 2));

  // Create a new Date object with the extracted components
  const date = new Date(Date.UTC(year, month, day, hour, minute, second));
  return date;
};

export const locationTo = (type, taskId, projectId, userId, LinksTo) => {
  if (type === "Task" && LinksTo === "Project_Plan") {
    return `/myTasks/projectPlan/${projectId}`;
  } else if (type === "Task" && LinksTo === "Dependency_Mapping") {
    return `/myTasks/dependencyMapping/${projectId}`;
  } else if (type === "Task" || type === "Reject job") {
    const projectType = taskId.split("_")[0];
    if (projectType === "TPOA" || projectType === "SAA") {
      return `/myTasks/artworkAlignment/${userId}/${projectId}`;
    } else if (projectType === "RCIC" || projectType === "DM" || projectType === "RCICMatrix") {
      return `/myTasks/dependencyMapping/${projectId}`;
    } else if (projectType === "CPPFA") {
      return `/myTasks`;
    } else {
      return `/myTasks/${projectType}/${taskId}/${projectId}`;
    }
  } else if (type === "POA-A") {
    return `/myTasks/artworkAlignment/${userId}/${projectId}`;
  } else if (type === "Change") {
    return `/myTasks/artworkAlignment/ChangeNotification/${projectId}`;
  } else if(type === "PM_Change"){
    return `/myProjects/projectSetup/${projectId}`;
  }
}; 
