import Api from ".";
import { updateManageDependencyAction } from "../store/actions/ManageDependencyActions";
import { store } from "../store/store";
import { DEVURL, SITURL, PRODURL } from "./envUrl";

export const getManageDependencyData = async (
  projectId,
  headers = {
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
  }
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/dependencyManageScreen/${projectId}`;
  // let apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/V1/DependencyManageScreen/${projectId}`;

  try {
    const response = await axiosInstance({
      url: apiURL,
      method: "GET",
    });
    // Check for successful response status, e.g., 200 OK
    if (response?.status === 200) {
      const manageDependencydata = response?.data;
      store.dispatch(updateManageDependencyAction(manageDependencydata));
      return manageDependencydata;
    }
  } catch (error) {
    if (error.message.includes("net::ERR_CONNECTION_TIMED_OUT")) {
      // Handle the server being down
      return {
        error: "The server is currently unavailable. Please try again later.",
      };
    } else {
      console.error("Error fetching manage dependency data:", error);
      throw error; // Rethrow the error for other types of errors
    }
  }
};

export const saveManageDependencyData = async (ProjectID, formData) => {
  let headers = {
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
  };
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  // let apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v1/StoreManageDependencyChange/${ProjectID}`;
  let apiURL = `${DEVURL}/storeManageDependencyChange/${ProjectID}`;
  try {
    const response = await axiosInstance({
      url: apiURL,
      method: "POST",
      data: formData,
    });
    // if (response?.status === 200) {
    console.log("Response", response, formData);
    return response;
    // }
  } catch (error) {
    if (error.message.includes("net::ERR_CONNECTION_TIMED_OUT")) {
      // Handle the server being down
      return {
        error: "The server is currently unavailable. Please try again later.",
      };
    } else {
      console.error("Error saving manage dependency data:", error);
      throw error; // Rethrow the error for other types of errors
    }
  }
};
