import Api from ".";
import { store } from "../store/store";
import { DEVURL } from "./envUrl";
import {
  acpBookingCapacityValuesData,
  acpBookingData,
  updateRTATasksResponse,
  emptyErrorData,
  acpDashboardProjectsRequiringPM,
} from "../store/actions/AcpBookingActions";
import {
  acpPlanningData,
  acpPlanningProjectData,
  setAcpPlanningProjectCapacityValuesData,
} from "../store/actions/AcpPlanningActions";
import { AcpService } from "../service/ACPService";
import { AcpCommonService } from "../service/AcpCommonService";

export const getAcpBookingData = async (
  userInformation,
  user_group,
  bookingTableFilters,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  const payLoad = {
    ArtworkAgilityPage: {
      UserGroup: user_group,
      PM: userInformation?.userid,
      Start_Date: bookingTableFilters?.Date && AcpService?.convertDate(bookingTableFilters?.Date),
      End_Date:
        bookingTableFilters?.endDate && AcpService?.convertDate(bookingTableFilters?.endDate),
    },
  };
  let apiURL = `${DEVURL}/fetchACPBooking`;
  const response = await axiosInstance({
    url: apiURL,
    data: payLoad,
    method: "POST",
  });
  let AcpBookingResponse = !AcpService.isUBN(response?.data) ? response.data : [];
  let AcpBookingCapacityValuesResponse = response?.data?.CapacityValues;
  if (Object.keys(AcpBookingResponse)?.length) {
    let ArtworkAgilityProjects = [AcpBookingResponse];
    let arr = ArtworkAgilityProjects.map((obj) => {
      const { pxObjClass, ...rest } = obj;
      return rest;
    });
    store.dispatch(acpBookingData(arr));
  }
  if (AcpBookingCapacityValuesResponse?.length) {
    store.dispatch(acpBookingCapacityValuesData(AcpBookingCapacityValuesResponse));
  } else store.dispatch(acpBookingCapacityValuesData([]));

  return AcpBookingResponse;
};
export const getACPDashboardProjectsRequiringPM = async (user_group, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  const payLoad = {
    ArtworkAgilityPage: {
      UserGroup: user_group,
    },
  };
  let apiURL = `${DEVURL}/fetchACPDashboardDetails`;
  try {
    const response = await axiosInstance({
      url: apiURL,
      data: payLoad,
      method: "POST",
    });
    let AcpDashboardPMTableResponse = !AcpService.isUBN(response?.data?.ACPDashboard)
      ? response?.data?.ACPDashboard
      : {};
    if (
      !AcpService.isUBN(AcpDashboardPMTableResponse) &&
      Object.keys(AcpDashboardPMTableResponse)?.length
    ) {
      store.dispatch(acpDashboardProjectsRequiringPM(AcpDashboardPMTableResponse));
    } else store.dispatch(acpDashboardProjectsRequiringPM({}));
    return AcpDashboardPMTableResponse;
  } catch (error) {
    console.log(error);
  }
};
export const fetchInternalCapacityData = async (payLoad, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/fetchInternalCapacityDetails`;
  try {
    const response = await axiosInstance({
      url: apiURL,
      data: payLoad,
      method: "POST",
    });
    let acpInternalCapacityResponse = response?.data;
    if (!AcpService.isUBN(acpInternalCapacityResponse)) {
      return acpInternalCapacityResponse;
    } else return {};
  } catch (error) {
    console.log(error);
  }
};

export const getACPDashboardAlerts = async (user_group, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  const payLoad = {
    ArtworkAgilityPage: {
      UserGroup: user_group,
    },
  };
  let apiURL = `${DEVURL}/fetchACPDashboardAlerts`;
  try {
    const response = await axiosInstance({
      url: apiURL,
      data: payLoad,
      method: "POST",
    });
    return !AcpService.isUBN(response) ? response : {};
  } catch (error) {
    console.log(error);
  }
};

export const fetchExternalCapacityData = async (payLoad, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/fetchExternalCapacityDetails`;
  try {
    const response = await axiosInstance({
      url: apiURL,
      data: payLoad,
      method: "POST",
    });
    let acpExternalCapacityResponse = response?.data;
    if (!AcpService.isUBN(acpExternalCapacityResponse)) {
      return acpExternalCapacityResponse;
    } else return {};
  } catch (error) {
    console.log(error);
  }
};
export const importUpdateExternalCapacityData = async (payLoad, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/updateExternalCapacityData`;
  try {
    const response = await axiosInstance({
      url: apiURL,
      data: payLoad,
      method: "POST",
    });
    let acpExternalCapacityResponse = response?.data;
    if (!AcpService.isUBN(acpExternalCapacityResponse)) {
      return acpExternalCapacityResponse;
    } else return {};
  } catch (error) {
    console.log(error);
  }
};
export const importUpdateInternalCapacityData = async (payLoad, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/updateInternalCapacity`;
  try {
    const response = await axiosInstance({
      url: apiURL,
      data: payLoad,
      method: "POST",
    });
    let acpInternalCapacityResponse = response?.data;
    if (!AcpService.isUBN(acpInternalCapacityResponse)) {
      return acpInternalCapacityResponse;
    } else return {};
  } catch (error) {
    console.log(error);
  }
};

export const getPMPicklistData = async (user_group, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  const payLoad = {
    ArtworkAgilityPage: {
      UserGroup: user_group,
    },
  };
  let apiURL = `${DEVURL}/fetchPM`;
  try {
    const response = await axiosInstance({
      url: apiURL,
      data: payLoad,
      method: "POST",
    });
    let AcpDashboardPMTableResponse = response?.data?.PMList;
    AcpDashboardPMTableResponse = !AcpService.isUBN(response?.data?.PMList)
      ? AcpService.filterPMObjects(response?.data?.PMList)
      : [];
    return AcpDashboardPMTableResponse;
  } catch (error) {
    console.log(error);
  }
};

export const getAcpBookingBacklogData = async (userInformation, user_group, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  const payLoad = {
    ArtworkAgilityPage: {
      UserGroup: user_group,
      PM: userInformation?.userid,
    },
  };
  let apiURL = `${DEVURL}/fetchACPBookingBacklog`;
  const response = await axiosInstance({
    url: apiURL,
    data: payLoad,
    method: "POST",
  });
  let AcpBookingResponse = response?.data;
  return !AcpService.isUBN(AcpBookingResponse?.Backlog) ? AcpBookingResponse?.Backlog : [];
};

export const updateRTATasks = async (formData) => {
  let headers = {};
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  // let apiURL = `https://pegasit.pg.com/prweb/api/ArtworkAgilityFile/v1/UpdateRTATasks`;
  let apiURL = `${DEVURL}/updateRTATasks`;
  const RTATasksResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });

  if (RTATasksResponse?.data?.RTATasksPage?.length) {
    store.dispatch(updateRTATasksResponse(RTATasksResponse.data.RTATasksPage));
  }

  return RTATasksResponse?.data?.RTATasksPage;
};

export const getBVELoopData = async (userInformation, user_group, headers = {}) => {
  const payLoad = {
    ArtworkAgilityPage: {
      UserGroup: user_group,
      PM: userInformation.userid,
    },
  };
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/fetchBVELoop`;

  // let apiURL = `https://pegasit.pg.com/prweb/api/ArtworkAgilityFile/v1/FetchBVELoop/Baby Care/EUROPE`;

  const response = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: payLoad,
  });
  let offTractBVELoop = response?.data?.OffTrackBVE || "";
  let onTractBVELoop = response?.data?.OnTrackBVE || "";
  let AcpBookingCapacityValuesResponse = response?.data?.CapacityValues;

  console.log("response bve booking ----->", offTractBVELoop, onTractBVELoop);

  if (AcpBookingCapacityValuesResponse?.length) {
    store.dispatch(acpBookingCapacityValuesData(AcpBookingCapacityValuesResponse));
  }

  if (response?.status === 200) {
    return { offTractBVELoop, onTractBVELoop };
  } else return { offTractBVELoop, onTractBVELoop };
};

/**
 * ACP Planning Task Get api.
 *
 * @getPlanningTaskLists
 * @param {Object} headers - holds the empty object.
 * @returns {Object} returns the response.
 */
export const getPlanningTaskLists = async (payload, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let endpoint = "getACPTaskListPlanDetails";
  let apiURL = AcpService.getUrl(DEVURL, endpoint);
  const endDate = payload?.ArtworkAgilityPage?.End_Date;
  const startDate = payload?.ArtworkAgilityPage?.Start_Date;
  const endDateObj = AcpCommonService.parseCustomDate(endDate);
  const startDateObj = AcpCommonService.parseCustomDate(startDate);
  if (endDateObj >= startDateObj) {
    try {
      const res = await axiosInstance({
        url: apiURL,
        method: "POST",
        data: payload,
      });

      let AcpPlanningResponse = !AcpService.isUBN(res?.data?.TaskList) ? res?.data?.TaskList : [];
      //filter FA assembly & Upload CIC
      let filteredData = AcpPlanningResponse?.filter(
        (dataSet) =>
          dataSet?.TaskType?.toLowerCase().includes("assembly") ||
          dataSet?.TaskType?.toLowerCase().includes("cic")
      );

      const validFilteredData = filteredData?.filter(
        (ele) => ele.StartDate && !isNaN(new Date(AcpService.formatDate(ele.StartDate)))
      );
      const sortedData = validFilteredData?.sort((a, b) =>
        AcpService.getDateDifference(a.StartDate, b.StartDate)
      );

      if (sortedData?.length) {
        store.dispatch(acpPlanningData(sortedData));
      } else store.dispatch(acpPlanningData([]));

      return AcpPlanningResponse;
    } catch (error) {
      console.log(error.message);
    }
  }
};

export const getPlanningProjectLists = async (payload, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let endpoint = "getACPProjectListDetails";
  let apiURL = AcpService.getUrl(DEVURL, endpoint);

  try {
    const res = await axiosInstance({
      url: apiURL,
      method: "POST",
      data: payload,
    });

    let AcpProjectCapacityResponse = res?.data?.CapacityForProject;
    let AcpProjectListResponse = res?.data?.ProjectListACP;
    // filter Draft and Active projects only
    store.dispatch(acpPlanningProjectData([]));
    store.dispatch(setAcpPlanningProjectCapacityValuesData([]));
    let filteredData = AcpProjectListResponse?.filter(
      (dataSet) => dataSet?.ProjectState === "Draft" || dataSet?.ProjectState === "Active"
    );
    const sortedData = filteredData?.sort((a, b) =>
      AcpService.getDateDifference(a.EstimatedAWReadiness, b.EstimatedAWReadiness)
    );
    if (sortedData?.length) {
      store.dispatch(acpPlanningProjectData(sortedData));
    }
    if (AcpProjectCapacityResponse?.length) {
      store.dispatch(setAcpPlanningProjectCapacityValuesData(AcpProjectCapacityResponse));
    }

    return res;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateStoreAsEmpty = () => {
  store.dispatch(updateRTATasksResponse([]));
  return null;
};

export const updateEmptyErrorData = () => {
  store.dispatch(emptyErrorData());
};
export const fetchBookingCapacities = async (payload, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/getCapacityForACPBooking`;
  const endDate = payload?.ArtworkAgilityPage?.End_Date;
  const startDate = payload?.ArtworkAgilityPage?.Start_Date;
  const endDateObj = AcpCommonService.parseCustomDate(endDate);
  const startDateObj = AcpCommonService.parseCustomDate(startDate);
  if (endDateObj >= startDateObj) {
    try {
      const res = await axiosInstance({
        url: apiURL,
        method: "POST",
        data: payload,
      });

      let capacityResponse = !AcpService.isUBN(res?.data) ? res?.data : [];
      return capacityResponse;
    } catch (error) {
      console.log(error.message);
    }
  }
};

export const planningMassUpdate = async (formData, type) => {
  let data = [];
  formData.forEach((el) => {
    let ob = {};
    if (type === "Task") {
      ob["AWMTaskID"] = el.AWMTaskID;
      ob["Start_Date"] = AcpService.changeDateFromISTtoUTC(el.NewStartDate);
    } else {
      ob["AWMProjectID"] = el.AWMProjectID;
      ob["Estimated_AWReadiness"] = AcpService.changeDateFromISTtoUTC(el.NewStartDate);
    }
    data.push(ob);
  });

  let finalData = {};
  if (type === "Task") {
    finalData = {
      NewStartDates: data,
    };
  } else {
    finalData = {
      NewReadinessDates: data,
    };
  }

  try {
    let headers = {};
    const api = new Api();
    const axiosInstance = await api.init({ headers });
    let apiURL =
      type === "Task"
        ? `${DEVURL}/massUpdateACPPlanningBooking`
        : `${DEVURL}/massUpdateACPPlanningProjList`;
    const massUpdateResponse = await axiosInstance({
      url: apiURL,
      method: "POST",
      data: finalData,
    });
    return massUpdateResponse;
  } catch (error) {
    console.log(error.message);
  }
};

export const planningCloseProject = async (data) => {
  let _data = [];
  data.forEach((el) => {
    let ob = {};
    ob["AWMProjectID"] = el.AWMProjectID;
    _data.push(ob);
  });

  let finalData = {
    TaskList: _data,
  };

  try {
    let headers = {};
    const api = new Api();
    const axiosInstance = await api.init({ headers });
    let apiURL = `${DEVURL}/acpPlanningCloseProject`;
    const closeProjectResponse = await axiosInstance({
      url: apiURL,
      method: "POST",
      data: finalData,
    });
    return closeProjectResponse;
  } catch (error) {
    console.log(error.message);
  }
};
export const massUpdateACPPlanningBooking = async (formData, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/massUpdateACPPlanningBooking`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  return { response: addResponse };
};

export const updateLongTermFile = async (payload, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/uploadLongTermPlan`;

  try {
    const res = await axiosInstance({
      url: apiURL,
      method: "POST",
      data: payload,
    });
    return res;
  } catch (error) {
    console.log(error.message);
  }
};
export const updatePMPicklistData = async (payload, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/updatePM`;
  try {
    const response = await axiosInstance({
      url: apiURL,
      data: payload,
      method: "POST",
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
