import "./index.scss";
import ProjectList from "./ProjectList";
import PageLayout from "../../PageLayout";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { ProjectService } from "../../../service/PegaService";
import { isRoleMatch } from "../../../utils";
import LoadingOverlay from "react-loading-overlay-ts";
// import { getPriorityNew } from "../../../Utils/GetRolePriority";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";
import { getMyProject } from "../../../store/actions/ProjectActions";
import { useUnsavedChangesWarning } from "../../common/NavigationPrompt";

const MyProjects = () => {
  const User = useSelector((state) => state?.UserReducer);
  const [pegadata, setPegaData] = useState(null);
  const userInformation = User?.userInformation;
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const { markDirty, markClean } = useUnsavedChangesWarning();
  const user_group = useSelector((state) => state?.UserDetailsReducer?.userDetails?.UserGroup);
  // 3109 security matrix
  const {
    userDetails: { AccessGroup },
  } = useSelector((state) => state?.UserDetailsReducer);
  const [checkReadWriteAccess, setUserAccess] = useState(false);

  useEffect(()=>{
    markClean();
    // writing this line because on loading the pages we need to clear unsaveChanges localstorage
  },[])

  useEffect(() => {
    //load the api only when user has access as per 3109 security matrix
    if (user_group?.length && userInformation?.userid && checkReadWriteAccess) {
      const payLoad = {
        ArtworkAgilityPage: {
          UserGroup: user_group,
          PM: userInformation?.userid,
        },
      };
      dispatch(getMyProject(payLoad));
      setLoader(false);
    } else {
      setLoader(false);
    }
  }, [dispatch, user_group?.length, userInformation?.userid, checkReadWriteAccess]);

  useEffect(() => {
    (async () => {
      try {
        const ProjectData = ProjectService.getProjectData();
        if (ProjectData?.length) {
          setPegaData(ProjectData);
          setLoader(false);
        }
      } catch (err) {
        console.log("error", err);
      }
    })();
  }, []);
  // 3109 security matrix
  useEffect(() => {
    const isUserAccess = isRoleMatch(AccessGroup, ["ProjectManager"]);
    setUserAccess(isUserAccess);
  }, [AccessGroup]);
  return (
    <>
      {/* {JSON.stringify(getPriorityNew(AccessGroup, location[1]))} */}
      <PageLayout>
        <div className="content-layout" id="tableDiv">
          <div className="tabular-view">
            {
              /* - lets wait for to Load all the data */
              loader ? (
                <LoadingOverlay active={loader} spinner text="" />
              ) : /* 3109- Verify whether the user has access to this page*/
              checkReadWriteAccess ? (
                <ProjectList pegadata={pegadata} header="My Projects" />
              ) : (
                AccessGroup && AccessGroup.length && <UnAuthorizedComponent nowrapper={true} />
              )
            }
          </div>
        </div>
      </PageLayout>
    </>
  );
};
export default MyProjects;
