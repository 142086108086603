import * as types from "../types/types";

export const reworkTableDataUpdate = (data) => ({
  payload: data,
  type: types.REWORK_TABLE_DATA,
});

export const reworkMarkUpUpdate = (data) => ({
  payload: data,
  type: types.REWORK_MARKUPS,
});
export const reasonForRework = (data) => ({
  payload: data,
  type: types.REASON_REWORK,
});