import Api from ".";
import { DEVURL } from "./envUrl";

/**
 * User notification Get api.
 *
 * @getUserNotification
 * @param {String} UserId - holds the UserId.
 * @returns {Object} returns the response.
 */
export const getUserNotification = async (UserId, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/getUserNotifications/${UserId}`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "GET",
  });
  let response = addResponse?.data;
  return response;
};

/**
 * User notification post api.
 *
 * @storeUserNotifications
 * @param {Object} formData - holds the payload data.
 * @returns {Object} returns the response.
 */
export const storeUserNotifications = async (
  formData,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/storeUserNotifications`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  let response = addResponse;
  return response;
  };