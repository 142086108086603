import React ,{useEffect} from "react";
import { useSelector,useDispatch } from "react-redux";
import PageLayout from "../../PageLayout";
import AllProjectList from "./AllProjectList";
import { getAllProject } from "../../../store/actions/ProjectActions";

function AllProjects() {
  const User = useSelector((state) => state?.UserReducer);
  const userInformation = User?.userInformation;
  const dispatch = useDispatch();
  const user_group = useSelector((state) => state?.UserDetailsReducer?.userDetails?.UserGroup);
  
  useEffect(() => {    
    if(user_group?.length && userInformation?.userid) {
      const payLoad = {
        ArtworkAgilityPage: {
          UserGroup: user_group,
          PM: userInformation?.userid,
        },
      };
      dispatch(getAllProject(payLoad));
    }
  }, [dispatch, user_group?.length, userInformation?.userid]);
  return (
    <>
      <PageLayout>
        <div className="content-layout" id="tableDiv">
          <div className="tabular-view">
            <AllProjectList header="All Projects" />
          </div>
        </div>
      </PageLayout>
    </>
  );
}

export default AllProjects;
