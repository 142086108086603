import * as types from "../types/types";

export const acpPlanningData = (data) => ({
  payload: data,
  type: types.ACP_PLANNING_TASK_DATA
});

export const setAcpPlanningTaskCapacityValuesData = (data) => ({
  payload: data,
  type: types.ACP_PLANNING_TASK_CAPACITY_VALUES_DATA
});

export const acpPlanningProjectData = (data) => ({
  payload: data,
  type: types.ACP_PLANNING_PROJECT_DATA,
});

export const setAcpPlanningProjectCapacityValuesData = (data) => ({
  payload: data,
  type: types.ACP_PLANNING_PROJECT_CAPACITY_VALUES_DATA
});

export const updateRTATasksResponse = (data) => ({
  payload: data,
  type: types.ACP_PLANNING_UPDATE_RTA_TASKS_RESPONSE
});

export const emptyErrorData = (data) => ({
  payload: data,
  type: types.ACP_PLANNING_EMPTY_ERROR_DATA
});

export const acpPlanningSetDefaultFilters = () => ({
  type: types.ACP_PLANNING_SET_DEFAULT_FILTERS,
});

export const setAcpPlanningFilters = () => ({
  type: types.ACP_PLANNING_SET_FILTERS,
});

export const setAcpPlanningTaskDraftData = (data)=>({
  type: types.ACP_PLANNING_SET_TASK_DRAFT,
  payload: data,
});

export const setAcpPlanningProjectDraftData = (data)=>({
  type: types.ACP_PLANNING_SET_PROJECT_DRAFT,
  payload: data,
});
