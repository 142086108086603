import * as types from "../types/types";

const initialState = {
  projects: [],
  selectedProject: {},
  mode: "",
  rootBreadCrumb: "MyProjects",
  projectData: {},
  loading: false,
  status: 0,
  specialProjectFlag: "No"
};

const projectSetup = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PROJECT_VALUES_REQUEST":
      return { ...state, loading: true };
    case "GET_PROJECT_VALUES_SUCCESS":
      return {
        ...state,
        projectData: action.payload.data,
        loading: false,
        error: null,
        status: action.payload.status
      };
    case "GET_PROJECT_VALUES_FAILURE":
      return { ...state, error: action.payload, loading: false };
    case types.SPECIAL_PROJECT:
        return {
          ...state,
          specialProjectFlag: action.payload
        };
    default:
      return state;
  }
};

export default projectSetup;

const getProjectMode = (payload) => {
  if (
    payload["Project_Name"] &&
    payload["BU"] &&
    payload["Artwork_Brand"]?.length &&
    payload["Project_region"] &&
    payload["Artwork_SMO"]?.length &&
    payload["Estimated_AW_Printer"] &&
    payload["Estimated_AW_Readiness"]
  ) {
    return "design";
  } else {
    return "edit";
  }
};
