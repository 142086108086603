import { Dialog } from "primereact/dialog";
import React from "react";
import { updateStoreAsEmpty } from "../../../apis/acpApi";

function AcpDialog({ showErrorDialog, setShowErrorDialog, errorMsgArr }) {
  //const listItems = errorMsgArr?.map((msg) => <li>{msg}</li>);

  return (
    <Dialog
      visible={showErrorDialog}
      className="acp-dialog"
      onHide={() => {
        setShowErrorDialog(false);
        updateStoreAsEmpty();
      }}
      header={<>
      <div className="p-dialog-acp-error">Error:</div>
      <div className="p-dialog-acp">Please deselect invalid tasks and try again.</div>
      </>}
    >
      <ul>
        {errorMsgArr?.map((msg) => (
          <li>{msg}</li>
        ))}
      </ul>
    </Dialog>
  );
}

export default AcpDialog;
