import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import PageLayout from "../../PageLayout";
import AddNewDesign from "../DesignJobs/TaskHeader";
import DesignHeader from "../DesignJobs/DesignHeader";
import AddNewDesignContent from "../DesignJobs/AddNewDesignContent";
import FooterButtons from "../DesignJobs/FooterButtons";
import { saveDesignIntent, submitDesignIntent } from "../../../apis/designIntentApi";
import "../DesignJobs/index.scss";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  checkFormValidity,
  checkAllDesignJobsAreDefined,
  redirectToProjectPlanOrTaskPage,
  areAllSelectsTrue,
  createDIName,
  sortedDataAsPerJobID,
  redirectUnallocatedLinksToArtworkStatus,
} from "../../../utils";
import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";
import { getReadWriteAccessForTask, getUserAccessForTask } from "../../../Utils/helpers";
import _, { cloneDeep } from "lodash";

const breadcrumb = [{ label: "My Tasks", url: "/myTasks" }, { label: "Define Design Intent" }];

const roleName = "DI";

function DDI() {
  const [data, setData] = useState(null);
  const [designIntent, setDesignIntent] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [submittedDI, setSubmittedDI] = useState([]);
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [enableCheckBox, setEnableCheckBox] = useState(true);
  const [checked, setChecked] = useState(false);
  const [loader, setLoader] = useState(true);
  const [deletedItems, setDeletedItems] = useState([]);
  let { TaskID, page1, page2, pageType, ProjectID } = useParams();
  const [InitialDesignIntent, setInitialDesignIntent] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [inputValid,setInputValid]=useState({});


  const currentUrl = location?.pathname;
  const { TaskDetailsData, loading } = useSelector((state) => state.TaskDetailsReducer);

  const id = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Key}`;
  const headerName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.TaskPlaceholderName;
  const shortName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Type;
  // 3109 security matrix
  const {
    userDetails: { AccessGroup, UserGroup },
  } = useSelector((state) => state?.UserDetailsReducer);
  const { userInformation } = useSelector((state) => state.UserReducer);

  const [accessType, setAccessType] = useState("Write");
  const [accessSecurityMatrix, setUserAccess] = useState(true);
  const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);
  let checkTaskIsComplete =
    TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Complete" ||
    TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Cancelled";

  const [pageEdited, setPageEdited] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { markDirty, markClean } = useUnsavedChangesWarning();

  useEffect(() => {
    if (pageEdited) {
      markDirty();
    } else {
      markClean();
    }
  }, [pageEdited]);

  useEffect(() => {
    markClean();
    setPageEdited(false);
  }, []);

  useEffect(() => {
    setUserAccess(getUserAccessForTask(TaskDetailsData));
    setCheckReadWriteAccess(getReadWriteAccessForTask(TaskDetailsData));
    setAccessType(TaskDetailsData?.AccessType);
  }, [TaskDetailsData?.AccessType]);

  // 3109 security matrix
  const userAccessPayLoad = {
    ArtworkAgilityPage: {
      UserId: userInformation?.userid,
      ProjectID: ProjectID,
      UserGroup: UserGroup,
    },
  };
  useEffect(() => {
    if(userInformation?.userid && UserGroup?.length){
      setLoader(false)
      dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
    }
  }, [dispatch, TaskID, ProjectID, userInformation?.userid, UserGroup?.length]);

  const BU = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU;
  const Project_Name = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;
  const Region = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region;

  useEffect(() => {
    if (TaskDetailsData) {
      let allDesignJobs = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || [];
      let sortedData = sortedDataAsPerJobID(allDesignJobs, "Design_Job_ID");
      setInitialDesignIntent(sortedData);
      setDesignIntent(sortedData);
    }
  }, [TaskDetailsData]);

  useEffect(() => {
    let allDesignJobs = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || [];

    if (TaskDetailsData) {
      let sortedData = sortedDataAsPerJobID(allDesignJobs, "Design_Job_ID");
      setDesignIntent(sortedData);
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);

      const specialProjectFlag = TaskDetailsData?.SpecialProject;
      dispatch(SpecialProjectAction(specialProjectFlag));
      redirectUnallocatedLinksToArtworkStatus(currentUrl, specialProjectFlag, navigate);
    }

    if (allDesignJobs && allDesignJobs?.length) {
      const checkAllJobsAreDefined = checkAllDesignJobsAreDefined(allDesignJobs);
      setEnableCheckBox(!checkAllJobsAreDefined);
      checkFormValidity(allDesignJobs, setEnableSubmit);
      const setCheboxValue = areAllSelectsTrue(allDesignJobs);
      setChecked(setCheboxValue);
    }
  }, [TaskDetailsData]);

  const handleCancel = () => {
    redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
  };

  const handleCancelClick = () => {
    if (pageEdited) {
      setShowDialog(true);
    } else {
      handleCancel();
    }
  };

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  };
  // it will navigate to previously saved url navigate(pendingNavigation);
  // by doing setShowDialog(false) and setPendingNavigation("");
  const confirmNavigation = () => {
    markClean();
    handleCancel();
    setPageEdited(false);
    setShowDialog(false);
  };

  const handleDelete = (index) => {
    const updatedDesignIntent = _.cloneDeep(designIntent); // Create a copy of the original array
    const deletedItem = updatedDesignIntent[index];
    deletedItem.Action = "delete"; // Set the Action property to "delete" for the specified item
    deletedItem.Design_Job_Status = "delete";
    // Update the deleted items state
    setDeletedItems([...deletedItems, deletedItem]);
    // Remove the deleted item from the designIntent array
    updatedDesignIntent.splice(index, 1);
    setDesignIntent(updatedDesignIntent);
    // Update the state with the modified array
    const checkAllJobsAreDefined = checkAllDesignJobsAreDefined(updatedDesignIntent);
    setEnableCheckBox(!checkAllJobsAreDefined);
    const deletedDesignIntent = designIntent.filter((item) => item.Action !== "delete");
    checked && setChecked(deletedDesignIntent?.length !== 0);
  };

  useEffect(() => {
    if (designIntent.length === 0) setEnableSubmit(true);
    else {
      checkFormValidity(designIntent, setEnableSubmit);
    }
    const isObjectEqual = JSON.stringify(InitialDesignIntent) === JSON.stringify(designIntent);
    if (!isObjectEqual) {
      setPageEdited(true);
    } else {
      setPageEdited(false);
    }
  }, [designIntent]);

  const addNewEmptyDesign = () => {
    const clonedDesignIntent = _.cloneDeep(designIntent)
    clonedDesignIntent.push({
      Design_Job_ID: clonedDesignIntent.length + 1,
      isNew: true,
      Design_Job_Name: "",
      Agency_Reference: "",
      Cluster: "",
      Additional_Info: "",
      Select: false,
      Design_Job_Status: "",
    });
    setEnableCheckBox(true);
    setDesignIntent(clonedDesignIntent);
    setUpdated(!updated);
    setChecked(false);
  };

  const addData = (fieldName, index, value, name) => {
    let initialDesignIntent = _.cloneDeep(InitialDesignIntent);
    let initialData = initialDesignIntent[index];
    const clonedDesignIntent = _.cloneDeep(designIntent)
    const updatedItem = clonedDesignIntent[index]
    updatedItem[fieldName] = value;
    const di_name = (updatedItem?.Agency_Reference || updatedItem?.Cluster || updatedItem?.Additional_Info) ?
    createDIName(
      updatedItem?.Agency_Reference,
      updatedItem?.Cluster,
      updatedItem?.Additional_Info,
      Project_Name,
      Brand,
      Category,
      roleName
    ):
    initialData?.Design_Job_Name || "";
    updatedItem["Design_Job_Name"] = di_name;
    let tempSubmittedDI = cloneDeep(submittedDI);
    tempSubmittedDI.push(updatedItem); // Add the updated item to submittedDI
    setSubmittedDI([...tempSubmittedDI]);
    setDesignIntent(clonedDesignIntent)
    const setCheboxValue = areAllSelectsTrue(clonedDesignIntent);
    setChecked(setCheboxValue);
  };

  const onSelectAll = (checked) => {
    designIntent.map((task) => {
      if (task?.Event !== "submit") {
        task.Select = checked;
      }
      return task;
    });
    checkFormValidity(designIntent, setEnableSubmit);
    setDesignIntent(designIntent);
    setUpdated(!updated);
  };

  const handleDeleteJob = async (Design_Job_ID) => {
    setLoader(true);
    let updatedData = {};
    let updatedDataList = [];
    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };

    // Combine the designIntent and deletedItems arrays for submission
    const combinedData = [...designIntent, ...deletedItems];
    let submitOnlySelectedData = combinedData.filter(
      (task) => Design_Job_ID === task?.Design_Job_ID
    );
    if (submitOnlySelectedData?.length > 0) {
      updatedData = {};
      updatedData.DesignJobName = submitOnlySelectedData[0]?.Design_Job_Name;
      updatedData.DesignJobID = submitOnlySelectedData[0]?.Design_Job_ID;
      updatedData.AgencyReference = submitOnlySelectedData[0]?.Agency_Reference;
      updatedData.Cluster = submitOnlySelectedData[0]?.Cluster;
      updatedData.AdditionalInfo = submitOnlySelectedData[0]?.Additional_Info;
      updatedData.Select = `${
        submitOnlySelectedData[0]?.Select ? submitOnlySelectedData[0]?.Select : false
      }`;
      updatedData.Action = "delete";

      updatedDataList.push({
        instruction: "APPEND",
        target: "DesignIntentList",
        content: updatedData,
      });
    }

    let formData = {
      caseTypeID: "PG-AAS-Work-DefineDesignIntent",
      content: {
        AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      },
      pageInstructions: updatedDataList,
    };

    await submitDesignIntent(formData, id, headers);
    dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
    setLoader(false);
  };

  const onSubmit = async () => {
    setLoader(true);
    let updatedData = {};
    let updatedDataList = [];
    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };

    // Combine the designIntent and deletedItems arrays for submission
    const combinedData = [...designIntent, ...deletedItems];
    let submitOnlySelectedData = combinedData.filter(
      (task) =>
        task?.Select === true &&
        (task?.Design_Job_Status === "" ||
          task?.Design_Job_Status === "Awaiting" ||
          task?.isNew === true)
    );
    submitOnlySelectedData.map((task) => {
      updatedData = {};
      if (task?.isNew) {
        task.Design_Job_ID = "";
      }
      if (task?.Action === "delete") {
        task.Action = "delete";
      } else if (task?.Action !== "delete" && task?.Design_Job_ID) {
        task.Action = "update";
      } else if (task?.Action !== "delete" && task?.isNew === true) task.Action = "add";

      updatedData.DesignJobName = task.Design_Job_Name;
      updatedData.DesignJobID = task.Design_Job_ID;
      updatedData.AgencyReference = task.Agency_Reference;
      updatedData.Cluster = task.Cluster;
      updatedData.AdditionalInfo = task.Additional_Info;
      updatedData.Select = `${task.Select ? task.Select : false}`;
      updatedData.Action = task.Action;

      updatedDataList.push({
        instruction: "APPEND",
        target: "DesignIntentList",
        content: updatedData,
      });
    });
    let formData = {
      caseTypeID: "PG-AAS-Work-DefineDesignIntent",
      content: {
        AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      },
      pageInstructions: updatedDataList,
    };
    await submitDesignIntent(formData, id, headers);
    setDeletedItems([]);
    setLoader(false);
    redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
  };

  const onSaveAsDraft = async () => {
    setLoader(true);
    let updatedData = [];
    const combinedData = [...designIntent, ...deletedItems];
    combinedData.filter((task) => {
      if (task?.isNew) {
        task.Design_Job_ID = "";
      }
      if (task?.Action === "delete") {
        task.Action = "delete";
      } else if (task?.Action !== "delete" && task?.Design_Job_ID) {
        task.Action = "update";
      } else if (task?.Action !== "delete" && task?.isNew === true) task.Action = "add";

      updatedData.push({
        Design_Job_Name: task.Design_Job_Name,
        Design_Job_ID: task.Design_Job_ID,
        AWM_Project_ID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
        Agency_Reference: task.Agency_Reference,
        Cluster: task.Cluster,
        Additional_Info: task.Additional_Info,
        Select: task.Select ? task.Select : false,
        Action: task.Action,
      });
    });

    let formData = {
      AWM_Project_ID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      AWM_Task_ID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
      Project_Name: Project_Name,
      BU: BU,
      Region: Region,
      DesignIntentList: updatedData,
    };
    await saveDesignIntent(formData);
    dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
    setDeletedItems([]);
    setLoader(false);
  };

  const loadOnClearButton = () => {
    dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
  };

  let Brand = [];
  let Category = [];

  if (TaskDetailsData?.ArtworkAgilityPage) {
    Brand = TaskDetailsData.ArtworkAgilityPage.Artwork_Brand;
    Category = TaskDetailsData.ArtworkAgilityPage.Artwork_Category;
  }

  return (
    <PageLayout>
      {loader ? (
        <LoadingOverlay active={true} spinner text="" />
      ) : accessSecurityMatrix ? (
        <>
          <DesignHeader
            setAddNewDesign={addNewEmptyDesign}
            onSelectAll={onSelectAll}
            breadcrumb={breadcrumb}
            headerName={headerName}
            label={shortName}
            lastLabel={"DDI"}
            checked={checked}
            pageEdited={pageEdited}
            setPageEdited={setPageEdited}
            setChecked={setChecked}
            enableCheckBox={enableCheckBox}
            checkReadWriteAccess={checkReadWriteAccess}
            checkTaskISComplete={checkTaskIsComplete}
            {...data}
          />
          <div className="task-details">
            {
              <AddNewDesign
                {...data}
                TaskDetailsData={TaskDetailsData}
                pageEdited={pageEdited}
                setPageEdited={setPageEdited}
                checkReadWriteAccess={checkReadWriteAccess}
              />
            }
            <LoadingOverlay active={loading || loader || designIntent === null} spinner text="" className="overlay-loader">
              {designIntent &&
                designIntent.length > 0 &&
                designIntent.map((item, index) => {
                  if (item && item?.Action !== "delete") {
                    return (
                      <AddNewDesignContent
                        key={index}
                        {...data}
                        item={item}
                        AWMProjectID={TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID}
                        AWMTaskID={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID}
                        Bu={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU}
                        Region={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region}
                        isTaskComplete={checkTaskIsComplete}
                        hideClearButton={true}
                        handleDeleteJob={handleDeleteJob}
                        loadOnClearButton={loadOnClearButton}
                        onSaveAsDraft={onSaveAsDraft}
                        Brand={Brand}
                        Category={Category}
                        pageEdited={pageEdited}
                        setPageEdited={setPageEdited}
                        index={index}
                        addData={addData}
                        handleDelete={handleDelete}
                        roleName={roleName}
                        taskName="DI_Agency Ref_Brand_Category_Project name_Cluster_Additional Info"
                        checkReadWriteAccess={checkReadWriteAccess}
                        setInputValid={setInputValid}
                      />
                    );
                  }
                })}
            </LoadingOverlay>
          </div>
          <FooterButtons
            accessType={accessType}
            handleCancel={handleCancelClick}
            onSaveAsDraft={onSaveAsDraft}
            onSubmit={onSubmit}
            formValid={(pageEdited ? enableSubmit : true)|| !Object.values(inputValid).every((valid) => valid)}
            bottomFixed={true}
            hideSaveButton={true}
            {...data}
          />
        </>
      ) : (
        AccessGroup && AccessGroup.length && <UnAuthorizedComponent nowrapper={true} />
      )}
      {/* UnsavedChangesDialog popup*/}
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </PageLayout>
  );
}

export default DDI;
