import React, { useEffect, useRef, useState } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import "chart.js/auto";
import { ranges, chartClearSelectionButtonLabel } from "../constant";
import { AcpService } from "../../../service/ACPService";
import { AcpCommonService } from "../../../service/AcpCommonService";
import { months, defaultCapacity } from "../constant";
import { fetchBookingCapacities } from "../../../apis/acpApi";
import { useNavigate, useLocation } from "react-router-dom";

function ACPBookingChart({
  bookingTableFilters,
  chartData,
  selectedBarData,
  setChartClicked,
  setRange,
  setCapacity,
  PM,
  UserGroup,
  reset,
}) {
  const [selectedBar, setSelectedBar] = useState(0);
  const [selectedBars, setSelectedBars] = useState([]);
  const [highlightedBar, setHighlightedBar] = useState(false);
  const [highlightedBars, setHighlightedBars] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [selectedTimeRange, setSelectedTimeRange] = useState(3);
  const chartRef = useRef(null);
  const _capacities = useRef([]);
  const _overCapacities = useRef([]);
  const capacityColors = useRef([]);
  const demandColors = useRef([]);
  const _demands = useRef([]);
  // 3248 -  Removing Backlog UI
  // const _pendingStatus = useRef([]);
  const [localChartData, setLocalChartData] = useState({});
  const [closeClicked, setCloseClicked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const query = AcpService.useQuery();
  const { endDateQueryParams, startDateQueryParams } = AcpService.decodedParams(query);

  let startDate = bookingTableFilters.Date ? bookingTableFilters.Date : "";
  let endDate = bookingTableFilters.endDate ? bookingTableFilters.endDate : "";

  let selectedDateRange = null;
  selectedDateRange =
    startDate !== "" &&
    endDate !== "" &&
    AcpService.getDatesInRange(startDate.toString(), endDate.toString());

  const labels = selectedDateRange;

  let capacities = [];
  let overCapacities = [];
  let demands = [];
  let dColors = [];
  let cColors = [];
  // let pendingStatus = [];

  let dailyData = [];

  const generateChart = (capacitiesData, _chartData) => {
    for (let i = 0; i < labels.length; i++) {
      // 3248 -  Removing Backlog UI
      // let data = _chartData[labels[i]];
      // let pendingTasks = [];
      // data?.forEach((el, inx) => {
      //   if (el.Group === "Backlog") {
      //     pendingTasks.push(el);
      //   }
      // });
      if (capacitiesData[labels[i]]) {
        capacities[i] = capacitiesData[labels[i]];
      }
      if (_chartData[labels[i]] && capacitiesData[labels[i]] === undefined) {
        // Backlog tasks UI
        // 3248 -  Removing Backlog UI
        // pendingStatus[i] = pendingTasks.length;
        demands[i] = 0;
        overCapacities[i] = _chartData[labels[i]].length;
      } else {
        if (_chartData[labels[i]] && _chartData[labels[i]].length > capacitiesData[labels[i]]) {
          // Backlog tasks UI
          // 3248 -  Removing Backlog UI
          // pendingStatus[i] = 0;
          overCapacities[i] = _chartData[labels[i]]?.length - capacitiesData[labels[i]];
          demands[i] = capacitiesData[labels[i]];
        } else if (_chartData[labels[i]]) {
          // Backlog tasks UI

          // 3248 - Removing Backlog UI
          // pendingStatus[i] = pendingTasks?.length;
          demands[i] = _chartData[labels[i]]?.length;
        }
      }

      overCapacities.push(0);
      capacities.push(defaultCapacity);
      demands.push(0);
      // Backlog tasks UI
      // pendingStatus.push(0);
      dColors.push("#99ccff");
      cColors.push("#FF9999");
    }
    _capacities.current = capacities;
    _overCapacities.current = overCapacities;
    _demands.current = demands;
    demandColors.current = dColors;
    capacityColors.current = cColors;

    // Backlog tasks UI
    // Removing Backlog UI
    // _pendingStatus.current = pendingStatus;
    // 3246 - After table interaction if selections are present highlight them
    if (highlightedBar !== null) {
      highlightChart(highlightedBar, false);
    } else if (highlightedBars?.length) {
      highlightedBars.forEach((highlightedBar) => {
        highlightChart(highlightedBar, true);
      });
    }
  };

  useEffect(() => {
    setClicked(false);
    // 3246 - Clear selections on cancel!
    setHighlightedBar(null);
    setHighlightedBars([]);
    setSelectedBars([]);
    setSelectedBar(null);
  }, [reset]);

  useEffect(() => {
    if (
      bookingTableFilters &&
      bookingTableFilters.Date &&
      bookingTableFilters.endDate &&
      UserGroup?.length &&
      PM
    ) {
      const payload = {
        ArtworkAgilityPage: {
          UserGroup: UserGroup,
          PM: PM,
          Start_Date: startDateQueryParams
            ? startDateQueryParams
            : AcpService.changeDateFromISTtoUTC(bookingTableFilters.Date),
          End_Date: endDateQueryParams
            ? endDateQueryParams
            : AcpService.changeDateFromISTtoUTC(bookingTableFilters.endDate),
        },
      };
      fetchBookingCapacities(payload).then((data) => {
        let ob = {};
        let obb = {};

        const taskFilteredCap =
          data &&
          data.CapacityForBooking &&
          data.CapacityForBooking.length > 0 &&
          data.CapacityForBooking.filter((el) => {
            if (bookingTableFilters.FAValue === "FA") {
              return el?.TaskType.includes("FA");
            } else if (bookingTableFilters.FAValue === "CIC") {
              return el.TaskType.includes("CIC");
            } else {
              return el;
            }
          });

        const supplierFilteredCap =
          taskFilteredCap &&
          taskFilteredCap.length > 0 &&
          taskFilteredCap.filter((el) => {
            if (
              bookingTableFilters.SupplierValue !== "" &&
              bookingTableFilters.SupplierValue !== "NA"
            ) {
              return el?.Supplier.includes(bookingTableFilters.SupplierValue);
            } else {
              return el;
            }
          });

        supplierFilteredCap &&
          supplierFilteredCap.length > 0 &&
          supplierFilteredCap.forEach((el) => {
            const getdayMonth = AcpCommonService.getMonthDay(el.Date);
            let month = months[getdayMonth?.month - 1];
            let day = getdayMonth?.day;
            let str = day + "-" + month;
            if (ob[str] !== undefined) {
              ob[str] = ob[str] + el.Capacity;
              if (!AcpService.checkWeekend(el.Date)) obb[el.Date] = obb[el.Date] + el.Capacity;
            } else {
              ob[str] = el.Capacity;
              if (!AcpService.checkWeekend(el.Date)) obb[el.Date] = el.Capacity;
            }
          });
        const aggregate = Object.values(obb).reduce((acc, val) => acc + val, 0);

        let _chartData = {};
        chartData.forEach((chart) => {
          if (AcpService.checkIfValidDate(chart.StartDate)) {
            chart.StartDate = AcpService.convertShortDateToGMT(chart.StartDate);
          }
          if (!_chartData[AcpService.formatTableDate(chart.StartDate)]) {
            dailyData = [];
            dailyData.push(chart);
            _chartData[AcpService.formatTableDate(chart.StartDate)] = dailyData;
            setLocalChartData(_chartData);
          } else {
            let localDaily = _chartData[AcpService.formatTableDate(chart.StartDate)];
            localDaily.push(chart);
            _chartData[AcpService.formatTableDate(chart.StartDate)] = localDaily;
            setLocalChartData(_chartData);
          }
        });

        setCapacity(aggregate);
        generateChart(ob, _chartData);
      });
    } else {
      setCapacity(0);
      _capacities.current = 0;
      _demands.current = 0;
      _overCapacities.current = 0;
    }
  }, [bookingTableFilters?.callAPi, chartData, UserGroup?.length, PM]);

  let data = {
    labels,
    datasets: [
      {
        label: "Capacity",
        data: _capacities.current,
        backgroundColor: "rgb(68 114 196)",
        type: "line",
        color: "rgb(68 114 196)",
        borderColor: "rgb(68 114 196)",
        pointRadius: "2",
        borderWidth: "3",
      },
      {
        label: "Planned",
        data: _demands.current,
        backgroundColor: demandColors.current,
        color: "rgb(68 114 196)",
        type: "bar",
      },
      // Backlog tasks UI
      // 3248 - We wanted to show overcapacity above all types of data sets. so replaced order of pending and overcapacity set
      // {
      //   label: "Backlog Tasks",
      //   data: _pendingStatus.current,
      //   backgroundColor: "#181818",
      //   color: "rgb(68 114 196)",
      //   type: "bar",
      // },
      {
        label: "Over Capacity",
        data: _overCapacities.current,
        backgroundColor: capacityColors.current,
        color: "rgb(68 114 196)",
        type: "bar",
      },
    ],
  };
  let __data = {
    labels,
    datasets: [
      {
        label: "Capacity",
        data: _capacities.current,
        backgroundColor: "rgb(68 114 196)",
        type: "line",
        color: "rgb(68 114 196)",
        borderColor: "rgb(68 114 196)",
        pointRadius: "2",
        borderWidth: "3",
      },
      {
        label: "Planned",
        data: 0,
        backgroundColor: demandColors.current,
        color: "rgb(68 114 196)",
        type: "bar",
      },
      {
        label: "Over Capacity",
        data: 0,
        backgroundColor: capacityColors.current,
        color: "rgb(68 114 196)",
        type: "bar",
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
      },
    },
    colors: {
      forceOverride: true,
    },
  };

  const grayOutChart = (demandColors, capacityColors) => {
    for (let i = 0; i < demandColors.current.length; i++) {
      demandColors.current[i] = "#efefef";
      capacityColors.current[i] = "#efefef";
    }
  };

  const highlightChart = (highlightedBar, multiple) => {
    if (highlightedBar && !AcpService.isUBN(highlightedBar.index)) {
      let barIndex = highlightedBar.index;

      grayOutChart(demandColors, capacityColors);

      if (multiple) {
        selectedBars?.forEach((el) => {
          demandColors.current[el] = "#99ccff";
          capacityColors.current[el] = "#FF9999";
        });
      } else {
        demandColors.current[barIndex] = "#99ccff";
        capacityColors.current[barIndex] = "#FF9999";
      }
    }
  };

  useEffect(() => {
    !AcpService.isUBN(selectedBar) && selectedBarData(localChartData[labels[selectedBar]], clicked);
  }, [selectedBar, clicked]);

  useEffect(() => {
    highlightChart(highlightedBar, false);
  }, [highlightedBar]);

  useEffect(() => {
    setSelectedBar([]);
    setSelectedBars([]);
    // 3246 - clear highlights on clearing selections
    setHighlightedBar(null);
    setClicked(false);
    setHighlightedBars([]);
  }, [closeClicked, bookingTableFilters]);

  useEffect(() => {
    let multiSelected = [];
    if (selectedBars && selectedBars.length > 0) {
      selectedBars.forEach((bar, inx) => {
        let data = localChartData[labels[bar]];
        data &&
          data.length > 0 &&
          data.forEach((el) => {
            multiSelected.push(el);
          });
      });
      let multiSelectedSorted = multiSelected?.sort((a, b) => a?.GroupOrder - b?.GroupOrder);
      selectedBarData(multiSelectedSorted, clicked);
    }
  }, [selectedBars, clicked]);

  useEffect(() => {
    if (highlightedBars && highlightedBars.length > 0) {
      highlightedBars.forEach((highlightedBar) => {
        highlightChart(highlightedBar, true);
      });
    }
  }, [highlightedBars]);

  const changeRange = (data) => {
    // AWM-3164 update query params if it is available to update while changing ranges
    if (startDateQueryParams) {
      let endDate;
      const startDate = new Date(AcpService.convertToIST(startDateQueryParams));
      endDate = AcpService.addBusinessDays(startDate, Object.values(data)[0]);
      endDate.setMinutes(0);
      endDate.setHours(0);
      endDate.setSeconds(0);
      AcpCommonService.updateQueryParams(
        "EndDate",
        AcpService.changeDateFromISTtoUTC(endDate),
        location,
        navigate
      );
    }
    setRange(Object.values(data)[0]);
  };

  const handleBarClick = (element, event) => {
    if (element && element.length > 0 && !AcpService.isUBN(element?.[0]?.index)) {
      if (element?.[0]?.element?.options?.pointStyle === "circle") return;
      setClicked(true);
      if (event.ctrlKey) {
        setSelectedBar(null);
        if (selectedBars?.includes(element[0]?.index)) return;
        setSelectedBars([...selectedBars, element[0].index]);
        setHighlightedBars([...highlightedBars, element[0]]);
      } else {
        setSelectedBars([]);
        if (selectedBar === element[0]?.index) return;
        setSelectedBar(element[0].index);
        setHighlightedBar(element[0]);
      }
    } else {
      setClicked(false);
      setHighlightedBars([]);
      setHighlightedBar([]);
    }
  };

  return (
    <>
      <div>
        <div className="selection-main">
          <div className="ranges-btn">
            {ranges.map((el, inx) => {
              return (
                <button
                  className={
                    inx === selectedTimeRange ? "time-period-btn selected" : "time-period-btn"
                  }
                  key={inx}
                  onClick={() => {
                    changeRange(el, inx);
                    setClicked(false);
                    setSelectedTimeRange(inx);
                  }}
                >
                  {Object.keys(el)[0]}
                </button>
              );
            })}
          </div>
          {clicked && !AcpService.isUBN(selectedBar) && selectedBars?.length === 0 && (
            <>
              <div className="selection-cont">
                <button
                  onClick={() => {
                    setClicked(false);
                    setCloseClicked(!closeClicked);
                  }}
                >
                  <i className="pi pi-times"></i>
                  <b>
                    {chartClearSelectionButtonLabel}
                    {labels[selectedBar]}
                  </b>
                </button>
              </div>
            </>
          )}
          {clicked && selectedBars.length > 0 && AcpService.isUBN(selectedBar) && (
            <>
              <div className="selection-cont">
                <button
                  onClick={() => {
                    setCloseClicked(!closeClicked);
                    setClicked(false);
                  }}
                >
                  <i className="pi pi-times"></i>
                  <b>
                    {chartClearSelectionButtonLabel}
                    {selectedBars.map((_selectedBar, inx) => {
                      return labels[_selectedBar] + (inx !== selectedBars.length - 1 ? ", " : "");
                    })}
                  </b>
                </button>
              </div>
            </>
          )}
        </div>

        <div className="chart-container">
          <Bar
            ref={chartRef}
            options={options}
            onClick={(event) => {
              const element = getElementAtEvent(chartRef.current, event);
              handleBarClick(element, event);
            }}
            data={chartData?.length ? data : __data}
          />
        </div>
      </div>
    </>
  );
}

export default ACPBookingChart;
