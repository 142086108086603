import React, { useEffect, lazy, Suspense } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useDispatch, useSelector } from "react-redux";
import { getAllTasks } from "../../../store/actions/TaskActions";
// import TaskList from "../TaskList";
import { useLocation } from "react-router-dom";
import { usePriorityNew } from "../../../Utils/GetRolePriority";
import { ALL_TASKS } from "../../../constants";


const TaskList = lazy(() => import("../TaskList"));

const AllTasks = () => {
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User?.userInformation;
  const dispatch = useDispatch();
  const { allTasks, loading } = useSelector((state) => state.TaskReducer);
  const {userDetails:{AccessGroup}} = useSelector(state => state.UserDetailsReducer);
  const location =useLocation().pathname.split('/');


const user_group = useSelector((state) => state.UserDetailsReducer.userDetails.UserGroup);
  
  const accessSecurityMatrix = usePriorityNew(location[1])
  const hasExternalTaskOwnerAccess = AccessGroup?.some(item => item.AccessGroupNames === "AAS:ExternalTaskOwner");
  const hasPMAccess = AccessGroup?.some(item => item.AccessGroupNames === "AAS:ProjectManager");

  useEffect(() => {
    if(user_group?.length && userInformation?.userid) {
      const payLoad =  {
        "ArtworkAgilityPage": {
          "PM": userInformation.userid,
          "UserGroup": user_group
        }
      }
      dispatch(getAllTasks(payLoad));
    }
  }, [dispatch, user_group?.length, userInformation?.userid]);
 
  const uniqueCompanies = new Set();

  if(allTasks){
    allTasks?.forEach(item => {
        if (item.Assignee === userInformation.userid && item.Company !== "") {
            uniqueCompanies.add(item.Company);
        }
    });
    // Filter the original data array based on unique company names
    // filteredData?.push(...allTasks?.filter(item => uniqueCompanies.has(item.Company)));
  }

  return (
    <>
        <Suspense fallback={<LoadingOverlay active={true} spinner text="" />}>
        <TaskList
          myTasks={allTasks}
          loading={loading}
          flag={ALL_TASKS}
          userInformation={userInformation}
          accessSecurityMatrix={accessSecurityMatrix}
          uniqueCompanies={uniqueCompanies}
          hasExternalTaskOwnerAccess={hasExternalTaskOwnerAccess}
          hasPMAccess={hasPMAccess}
        />
      </Suspense>
    </>
  );
};

export default AllTasks;
