import React, { useState, useEffect, useRef, Suspense, useCallback } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { InputNumber } from "primereact/inputnumber";

import ConfirmationPopUp from "../ConfirmationPopUp";
import filter from "../../../assets/images/filter.svg";
import { changeDateFormat, onSortData, bufferToWork } from "../../../utils";
import hyphen from "../../../assets/images/hyphen.svg";
import "./index.scss";
import TaskDialog from "../../TaskDialog";
import messageIcon from "../../../assets/images/Message.svg";
import ApproveDesignDialog from "./ApproveDesignDialog";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { ArtWorkTabValuesAction } from "../../../store/actions/ArtWorkTabValuesActions";
import CPPFA from "./../../AWMJobs/CPPFA";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import { Dialog } from "primereact/dialog";
import CommonAutocomplete from "../../../Utils/CommonAutocomplete";
import { dipsalyStateValues, extractData, formatIntoDatePicker } from "./util";
import { usePriorityNew } from "../../../Utils/GetRolePriority";
import { getFilterOptions } from "../../../Utils/GridService";
import ProjectPlanTable from "./ProjectPlanTable";

const ProjectPlanList = (props) => {
  const {
    view,
    pegadata,
    setPegaData,
    setUpdatedProjectPlanDesignData,
    setActiveSave,
    isAccessEmpty,
    getProjectPlanApi,
    isSearch,
    setColWidth,
    childFunc,
    fieldUpdated,
    setFieldUpdated,
    clearColumnWiseFilter,
    filters,
    setFilters,
    selectedFields,
    setSelectedFields,
    frozenUpdated,
    setFrozenUpdated,
    handleExapandCollapseAllFilter,
    columnWiseSelectedFields,
    setColumnWiseSelectedFields,
    projectDataData,
    setInitProjectPlanList,
    allColumns,
    projectState,
    isCustomizeViewActive
  } = props;
  const [ProjectFrozen, setProjectFrozen] = useState(false);
  const [frozenCoulmns, setFrozenColumn] = useState([]);
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const [sortData, setSortData] = useState([]);
  const [showTaskDialog, setShowTaskDialog] = useState(false);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [selectedTask, setSelectedTask] = useState([]);
  const [selectedTaskApproveDialog, setSelectedTaskApproveDialog] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  // State to manage the message content
  const [messageContent, setMessageContent] = useState([]);
  const [designTabPegaData, setDesignTabPegaData] = useState([]);
  const dispatch = useDispatch();
  const [flag, setFlag] = useState("");

  const ownerVal = useRef("");
  const inputRef = useRef(null);
  let { ProjectID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const accessSecurityMatrix = usePriorityNew(location.pathname.split("/")[1]);

  const User = useSelector((state) => state.UserReducer);
  const { projectPlan } = useSelector((state) => state.ProjectPlanReducer);
  const userInformation = User.userInformation;

  const op = useRef(null);
  let today = new Date();
  let minDate = new Date();
  minDate.setDate(today.getDate());

  useEffect(() => {
    fetchData();
  }, [projectPlan]);

  const autoVal = (field, options) => {
    let val;
    if (field === "Role") {
      val = options?.data["Role"];
    } else if (options?.data["Owner"]) {
      if (ownerVal.current === "") {
        val = options?.data["Owner"];
      } else {
        val = ownerVal.current;
      }
    } else {
      val = options?.data["Assignee"];
    }
    return val;
  };

  function fetchData() {
    try {
      setPegaData(projectPlan);
      setInitProjectPlanList(projectPlan);
      // according to pathname we need to call api and store column name in local storage
      // let columnNamesJson = localStorage.getItem('projectPlanAllColumnNames');
      // const storageColumnNames = JSON.parse(columnNamesJson);
      // const columnNames = ProjectService.getProjectPlanAllColumnNames();
      // if (storageColumnNames != null && storageColumnNames.length) {
      //   // storageColumnNames.length === columnNames.length
      //   // isEqual(columnNames, storageColumnNames)
      //   if (storageColumnNames.length === columnNames.length) {
      //     let sortedData = [];
      //     sortedData = [...storageColumnNames].sort((a, b) => {
      //       return parseInt(a.sequence) - parseInt(b.sequence);
      //     });
      //   } else {
      //     let sortedData = [];
      //     sortedData = [...columnNames].sort((a, b) => {
      //       return parseInt(a.sequence) - parseInt(b.sequence);
      //     });
      //   }
      // }

      // get sort data from local storage and add in state
      let jsonSortingData1 = localStorage.getItem('projectPlanSortingData');
      const sortingData = JSON.parse(jsonSortingData1);
      if (sortingData && sortingData.length) {
        const sortedData = onSortData(sortingData[0], sortingData[1], projectPlan);
        setPegaData(sortedData);
        setSortData([sortingData[0], sortingData[1]]);
      }

      // get frozen data from local storage and add in state
      let jsonFrozenrData1 = localStorage.getItem('frozenDataProjectPlan');
      const frozenDataProjectPlan = JSON.parse(jsonFrozenrData1);
      if (frozenDataProjectPlan && frozenDataProjectPlan.length) {
        setFrozenColumn(frozenDataProjectPlan);
      }
    } catch (err) {
      console.log("error", err);
    }
  }

  const addFrozenColumns = (name) => {
    if (!frozenCoulmns.includes(name)) {
      frozenCoulmns.push(name);
      setFrozenColumn(frozenCoulmns);
    } else {
      let columnIndex = frozenCoulmns.indexOf(name);
      frozenCoulmns.splice(columnIndex, 1);
      setFrozenColumn(frozenCoulmns);
    }
  };

  const projectNameOnClick = (e, options) => {
    op.current.toggle(e);
    setSelectedColumnName(options);
  };

  const projectNameHeader = (options,columns, col) => {
    let isFilterActivated =
      col?.freeze === true || col?.sortAtoZ === true || col?.sortZtoA === true;

    const optionsCode = getFilterOptions(options);

    return (

      <span key={options}>
        <img
          src={filter}
          alt="Column Filter"
          key={options}
          onClick={(e) => projectNameOnClick(e, options)}
          className={
            isFilterActivated ? "columnFilterIcon filter-color-change" : "columnFilterIcon"
          }
        />
        <span className="columnHeader">
          {col?.field_Name === "Project_TaskID" || col?.field_Name === "Project_PredecessorID"
            ? columns
            : options}
        </span>
      </span>
    );
  };

  const flatPegadata = pegadata?.flatMap((obj) => extractData(obj));

  const onGlobalFilterChange = useCallback(
    (e, filterColumn) => {
      const value = e.value;
      let temp = cloneDeep(selectedFields);
      temp[filterColumn] = value;
      setSelectedFields(temp);

      let allValues = [];
      let keys = Object.keys(temp);
      keys.forEach((key) => {
        allValues = [...allValues, ...temp[key]];
      });

      const artworkValues = allValues;
      if (artworkValues?.length) {
        const filterRecursive = (data) => {
          if (!data.children || data.children.length === 0) {
            let condition = null;
            keys.forEach((itemVal) => {
              if (temp[itemVal]?.length > 0) {
                if (itemVal !== "Start_Date" && itemVal !== "End_Date") {
                  if (condition === null) {
                    condition = temp[itemVal]?.includes(data.data?.[itemVal]);
                  } else {
                    condition = condition && temp[itemVal]?.includes(data.data?.[itemVal]);
                  }
                } else {
                  // Custom logic for date columns
                  if (condition === null) {
                    condition = temp[itemVal]?.includes(data.data[itemVal]);
                  } else {
                    condition = condition && temp[itemVal]?.includes(data.data[itemVal]);
                  }
                }
              }
            });

            if (condition) {
              return data;
            } else {
              return null;
            }
          }

          // Recursive case: node with children
          const filteredChildren = data.children
            .map((child) => filterRecursive(child))
            .filter(Boolean);

          // Include the group if it has filtered children
          if (filteredChildren.length > 0) {
            return {
              ...data,
              children: filteredChildren,
            };
          }

          return null; // Exclude nodes without filtered children
        };

        const filteredData = pegadata?.map((group) => filterRecursive(group)).filter(Boolean);
        handleExapandCollapseAllFilter();
        setFilters(filteredData);
      } else {
        setFilters([]);
      }
    },
    [selectedFields, pegadata, handleExapandCollapseAllFilter]
  );

  // const saveSettings = () => {
  //   localStorage.setItem('columnWiseFilterData', JSON.stringify(filters));
  //   localStorage.setItem('frozenDataProjectPlan', JSON.stringify(frozenCoulmns));
  //   localStorage.setItem('sortingData', JSON.stringify(sortData));
  //   localStorage.setItem('projectPlanAllColumnNames', JSON.stringify(projectColumnName));
  // };

  const handleInfoIconClick = (message, reason) => {
    setMessageContent([message, reason]);
    setShowDialog(true);
  };

  const formatHelpNeeded = (rowData) => {
    let className = "";
    let helpNeeded = rowData["Help_Needed"];
    switch (helpNeeded) {
      case "Yes, In Process":
        className = "helpneeded_inprocess";
        helpNeeded = "Yes, In Process";
        rowData["Help_Needed"] = "Yes, In Process";
        return (
          <>
            <span
              className="helpneeded_inprocess"
              onClick={() =>
                handleInfoIconClick(`${rowData["Comments"]}`, `${rowData["Help_Needed_Reason"]}`)
              }
            >
              <img
                className="message_logo"
                src={messageIcon}
                alt="message logo"
                onClick={() =>
                  handleInfoIconClick(`${rowData["Comments"]}`, `${rowData["Help_Needed_Reason"]}`)
                }
              />
              {rowData?.Help_Needed}
            </span>
          </>
        );
        break;
      case "Yes, Done":
        className = "helpneeded_done";
        helpNeeded = "Yes, Done";
        break;
      case "N/A":
        className = "helpneeded_no";
        helpNeeded = "N/A";
        break;
      case "No":
        className = "helpneeded_no";
        helpNeeded = "No";
        break;
      default:
        break;
    }
    return <span className={className}>{helpNeeded}</span>;
  };

  const durationEnabled = (State) => {
    if (State === "In-Progress" || State === "Awaiting") {
      return false;
    } else {
      return true;
    }
  };

  const elementTemplate = (options, rowData) => {
    const field = rowData.field;
    const optionsData = options.data;
    const currentUrl = location.pathname;
    let currentUrlLastSeg = currentUrl?.split("/")[3];
    let currentUrlBasePage = currentUrl?.split("/")[1];
    const code = options?.code;
    const keyCode = code?.split("_");
    const locaiton = window.location.pathname;
    const url = `${locaiton.split("/")[1]}/${locaiton.split("/")[2]}/${keyCode?.[0]
      }/${code}/${currentUrlLastSeg}`;
    const daysLeftStyle =
      optionsData["Days_Left"] === 0 || optionsData["Days_Left"] === "N/A" ? "" : "red";
    const cursorStyle =
      optionsData["GroupName"]?.includes("Final Art") || optionsData["Task_ID"]?.includes("CPC_")
        ? "default"
        : "";

    const clickHandler = () => {
      if (field && field.length && keyCode[0] === "CPPFA") {
        handleApproveDialogCPPFA(options);
      } else if (
        optionsData["Task_ID"]?.includes("SAA_") ||
        optionsData["Task_ID"]?.includes("TPOA_")
      ) {
        dispatch(ArtWorkTabValuesAction([]));
        navigate(
          `/${currentUrlBasePage}/artworkAlignment/${optionsData["Assignee"]}/${projectDataData?.AWM_Project_ID}`
        );
      } else if (
        optionsData["Task_ID"]?.includes("DM_") ||
        optionsData["Task_ID"]?.includes("RCIC_") ||
        optionsData["Task_ID"]?.includes("RCICMatrix_")
      ) {
        navigate(`/${currentUrlBasePage}/dependencyMapping/${projectDataData?.AWM_Project_ID}`);
      } else if (optionsData["Task_ID"]?.includes("UBD_")) {
        navigate(
          `/${currentUrlBasePage}/projectPlan/UBD/${options.code}/${projectDataData?.AWM_Project_ID}`
        );
      } else if (
        optionsData["Task"] !== "Design" &&
        optionsData["Task"] !== "Input" &&
        !optionsData["GroupName"]?.includes("Final Art") &&
        !optionsData["Task_ID"]?.includes("CPC_") &&
        optionsData["Task"] !== "Final Art" &&
        !optionsData["Task"]?.includes("(X")
      ) {
        (options.redirect === true || optionsData.Task) &&
          optionsData.State !== "Awaiting" &&
          navigate(`../${url}`, { replace: true });
      }
    };

    return (
      <>
        {field === "Task" && (
          <span
            className={`${optionsData.State === "Awaiting"
              ? ""
              : options.children.length === 0
                ? "task-link"
                : "task"
              } ${field === "Task" && "custom-toggler-wrapper"}`}
            style={{ cursor: cursorStyle }}
            onClick={clickHandler}
            title={optionsData[field]}
          >
            {optionsData[field]}
          </span>
        )}
        {options.children && options.children.length > 0 ? (
          <>
            {(field === "Role" ||
              field === "Owner" ||
              field === "Assignee" ||
              field === "State" ||
              field === "TaskDuration" ||
              field === "StartDate" ||
              field === "TaskID" ||
              field === "Dependency" ||
              field === "Project_TaskID" ||
              field === "Project_PredecessorID" ||
              field === "DaysLeft" ||
              field === "EndDate") && (
              <img
                src={hyphen}
                alt="Hyphen"
                onClick={(e) => {
                  op.current.toggle(e);
                }}
              />
            )}
          </>
        ) : (
          <>
            {field === "Project_TaskID" && (
              <span
                title={optionsData["Project_TaskID"]}
                className={`${
                  optionsData.State === "Awaiting"
                    ? ""
                    : options.children.length === 0
                    ? "task-link"
                    : "task"
                }`}
                style={{ cursor: cursorStyle }}
                onClick={clickHandler}
              >
                {optionsData["Project_TaskID"]}
              </span>
            )}
            {(field === "Role" || field === "Owner" || field === "Assignee") && (
              <div disabled={!isAccessEmpty} className="d-flex">
                <CommonAutocomplete
                  suggestions={
                    field === "Role"
                      ? optionsData["RoleOptions"]
                      : field === "Owner" || field === "Assignee"
                      ? optionsData["RoleOptions"]?.find((obj) => optionsData["Role"] === obj.Name)
                          ?.OwnerOptionsNew
                      : []
                  }
                  value={autoVal(field, options)}
                  ref={inputRef}
                  onChange={(e) => {
                    onDDTyped(options, e, field);
                  }}
                  onSelect={(e) => {
                    onDropdownChange(options, e, field);
                  }}
                  placeholder={`Select ${field}`}
                  field="Name"
                  disabled={
                    optionsData.GroupName === "Final Art" ||
                    optionsData.State?.includes?.("Complete") ||
                    optionsData["Task_ID"]?.includes("CPC_") ||
                    projectState === 'Complete' ||
                    !accessSecurityMatrix
                  }
                />
              </div>
            )}
            {field === "State" && dipsalyStateValues(optionsData[field])}

            {field === "TaskDuration" && options?.data[field] !== "" && (
              <InputNumber
                className="input-duration"
                inputId="integeronly"
                value={options?.data[field]}
                onValueChange={(e) => onDurationChange(options, e, field)}
                disabled={
                  !isAccessEmpty ||
                  durationEnabled(optionsData.State) ||
                  !accessSecurityMatrix ||
                  projectState === "Complete"
                }
                min={1}
              />
            )}

            {field === "Start_Date" && (
              <div className="projectplan-calender">
                <Calendar
                  value={formatIntoDatePicker(optionsData["Start_Date"])}
                  onChange={(e) => {
                    dateChange(e);
                    onDate(options, e, field);
                  }}
                  dateFormat="d-M-y"
                  showIcon={true}
                  minDate={minDate}
                  disabled={
                    optionsData.State !== "Awaiting" ||
                    !accessSecurityMatrix ||
                    projectState === "Complete"
                  }
                />
              </div>
            )}

            {field === "End_Date" && <span>{optionsData["End_Date"]}</span>}
            {field === "Days_Left" && optionsData["TaskDuration"] !== "" && (
              <span style={{ color: daysLeftStyle }}>{optionsData["Days_Left"]}</span>
            )}
            {field === "Project_PredecessorID" && (
              <span title={optionsData[field]}> {optionsData[field]} </span>
            )}
            {field === "Remaining_Work" && optionsData["Remaining_Work"]}
            {field === "Buffer" && optionsData["Buffer"]}
            {field === "Buffer_To_Work" &&
              options[field] !== "" &&
              bufferToWork(optionsData["Buffer_To_Work"])}
          </>
        )}

        {field === "Help_Needed" && formatHelpNeeded(optionsData)}

        {field !== "Task" &&
          field !== "Task_ID" &&
          field !== "Role" &&
          field !== "Owner" &&
          field !== "Assignee" &&
          field !== "State" &&
          field !== "Start_Date" &&
          field !== "End_Date" &&
          field !== "TaskDuration" &&
          field !== "Help_Needed" &&
          field !== "Buffer" &&
          field !== "Dependency" &&
          field !== "Project_TaskID" &&
          field !== "Project_PredecessorID" &&
          field !== "Remaining_Work" &&
          field !== "Buffer_To_Work" &&
          field !== "Days_Left" && <>{optionsData[field]}</>}
      </>
    );
  };

  const updateProjectPlanDesign = () => {
    let arr = [];
    const prepareUpdatedData = (parent, child) => {
      if (!isEmpty(parent) && !isEmpty(child)) {
        arr.push({
          ...child,
          AWM_Project_ID: projectDataData?.AWM_Project_ID,
          AWM_Task_ID: parent.code,
          Task_Name: child.Task,
          Assignee: child.Owner ? child.Owner : child.Assignee
        })
      }
    }
    pegadata.forEach((obj) => {
      obj.children?.forEach((data) => {
        if (data.children.length === 0) {
          prepareUpdatedData(data, data.data);
        } else if (data.children.length > 0) {
          data.children.forEach((child) => {
            prepareUpdatedData(child, child.data);
            if (child.children.length > 0) {
              child.children.forEach((innerChild) => {
                prepareUpdatedData(innerChild, innerChild.data);
              });
            }
          });
        }
      });
    });
    setUpdatedProjectPlanDesignData(arr);
  };

  useEffect(() => {
    pegadata && updateProjectPlanDesign();
  }, [pegadata]);

  const onDropdownChange = (rowData, { value }, ele) => {
    if (typeof value === "string") {
      if (value.length > 2) {
        //rowData.data[ele] = value;
        if (ele === "Role") {
          if (rowData.data["Role"] !== value) {
            rowData.data["Assignee"] = "";
          }
        }
        if (ele === "Owner") {
          rowData.data["Assignee"] = value;
        } else {
          rowData.data[ele] = value;
        }

        const updatedPegadata = pegadata.map((obj) => {
          obj.children.map((data) => {
            if (data.code === rowData.code) {
              return {
                ...data,
                data: {
                  ...data.data,
                  //[ele]: value,
                  [ele === "Owner" ? "Assignee" : ele]: value,
                },
              };
            }
            return data;
          });
          return obj;
        });
        setPegaData(updatedPegadata);
        setInitProjectPlanList(updatedPegadata);
      }

      // Set the state with the updated array
    } else {
      if (ele === "Role") {
        if (rowData.data["Role"] !== value?.Name) {
          rowData.data["Assignee"] = "";
        }
      }
      if (ele === "Owner") {
        rowData.data["Assignee"] = value?.Name;
      } else {
        rowData.data[ele] = value?.Name;
      }

      // Create a new array with the updated data
      const updatedPegadata = pegadata.map((obj) => {
        obj.children.map((data) => {
          if (data.code === rowData.code) {
            return {
              ...data,
              data: {
                ...data.data,
                // [ele]: value?.Name,
                [ele === "Owner" ? "Assignee" : ele]: value?.Name,
              },
            };
          }
          return data;
        });
        return obj;
      });
      // Set the state with the updated array
      setPegaData(updatedPegadata);
      setInitProjectPlanList(updatedPegadata);
    }
    if (!isAccessEmpty) {
      setActiveSave(true);
    } else {
      setActiveSave(false);
    }
  };

  const onDDTyped = (rowData, val, ele) => {
    let value = val.value;
    if (typeof value === "string") {
      if (ele === "Role") {
        if (rowData.data["Role"] !== value) {
          rowData.data["Assignee"] = "";
        }
      }
      if (ele === "Owner") {
        rowData.data["Assignee"] = value;
      } else {
        rowData.data[ele] = value;
      }

      const updatedPegadata = pegadata.map((obj) => {
        obj.children.map((data) => {
          if (data.code === rowData.code) {
            return {
              ...data,
              data: {
                ...data.data,
                [ele === "Owner" ? "Assignee" : ele]: value,
              },
            };
          }
          return data;
        });
        return obj;
      });
      if (rowData.data.Task !== "Define Design Intent" && !rowData.data.Task.includes("1).")) {
        setPegaData(updatedPegadata);
        setInitProjectPlanList(updatedPegadata);
      }

      // Set the state with the updated array
    }
  };

  const onDurationChange = (rowData, { value }, ele) => {
    rowData.data[ele] = value < 1 ? "0" : value?.toString();
    setPegaData([...pegadata]);

    if (!isAccessEmpty) {
      setActiveSave(true);
    } else {
      setActiveSave(false);
    }
  };
  const onDate = (rowData, { value }, ele) => {
    rowData.data["Start_Date"] = changeDateFormat(value);

    setPegaData([...pegadata]);
    setActiveSave(false);
  };

  const dateChange = (value) => {};

  useEffect(() => {
    const ProjectData = cloneDeep(projectPlan);
    let allCol = [];
    if (ProjectData.length) {
      allCol = Object.keys(ProjectData[0]);
    }
    let columnWidthProjectPlan = {};
    if (allCol.length) {
      allCol.forEach((column) => {
        columnWidthProjectPlan[column] = 100;
      });
    }

    let getJsonStoredWidthColumns = localStorage.getItem("columnWidthProjectPlan");
    let getStoredWidthColumns = JSON.parse(getJsonStoredWidthColumns);
    const checkEmptyObject = isEmpty(getStoredWidthColumns);

    if (checkEmptyObject) {
      localStorage.setItem("columnWidthProjectPlan", JSON.stringify(columnWidthProjectPlan));
    }

    let jsonColWidth = localStorage.getItem("isColWidthSetMyProject");
    let isColWidthSetFlag = JSON.parse(jsonColWidth);
    if (isColWidthSetFlag) {
      setColWidth(true);
    }

    childFunc.current = clearColumnWiseFilter;
  }, []);

  const dynamicColumns = () => {
    if (!isEmpty(allColumns)) {
      return allColumns.map((ele, i) => {
        return (
          <Column
            key={ele.field_Name}
            field={ele.field_Name === "Owner" ? "Assignee" : ele.field_Name}
            filterField={ele.field_Name}
            filterMatchMode="contains"
            filter={isSearch}
            filterPlaceholder={ele.field_Name}
            header={projectNameHeader(ele.field_Name, ele.column_Name, ele)}
            expander={ele.field_Name === "Task"}
            columnKey={ele.field_Name || i}
            frozen={ele.freeze}
            alignFrozen="left"
            className={ele.freeze ? "fontBoldcolor" : "cursorMove"}
            showFilterMenu={false}
            body={elementTemplate}
            style={{
              width: ele?.freeze ?  "200px" : ele?.width,
              textOverflow: ele.field_Name != "Task" ? "ellipsis" : "",
            }}
          />
        );
      });
    }
  };

  const onSort = (column, direction) => {
    const sortedData = onSortData(column, direction, pegadata);
    setPegaData(sortedData);
    setSortData([column, direction]);
    localStorage.setItem("projectPlanSortingData", JSON.stringify([column, direction]));
  };

  const [showApproveDialogCPPFA, setShowApproveDialogCPPFA] = useState(false);
  const [selectedTaskApproveDialogCPPFA, setSelectedTaskApproveDialogCPPFA] = useState([]);

  const handleApproveDialogCPPFA = (options) => {
    setShowApproveDialogCPPFA(true);
    let task = { TaskID: options.code, ProjectID: ProjectID };
    setSelectedTaskApproveDialogCPPFA(task);
    dispatch(getTaskDetails(options.code, ProjectID));
  };

  const { TaskDetailsData } = useSelector((state) => state.TaskDetailsReducer);

  return (
    <div className="myProjectAnddAllProjectList custom-height">
      {showApproveDialog && (
        <ApproveDesignDialog
          onClose={() => setShowApproveDialog(!showApproveDialog)}
          showTaskDialog={showApproveDialog}
          selectedTaskData={selectedTaskApproveDialog}
        />
      )}
      {showApproveDialogCPPFA && (
        <CPPFA
          onClose={() => setShowApproveDialogCPPFA(!showApproveDialogCPPFA)}
          showTaskDialog={showApproveDialogCPPFA}
          selectedTaskData={selectedTaskApproveDialogCPPFA}
          pegadata={designTabPegaData}
          getProjectPlanApi={getProjectPlanApi}
          TaskDetailsData={TaskDetailsData}
          userInformation={userInformation}
        />
      )}
      <Suspense fallback={<LoadingOverlay active={true} spinner text="" />}>
        {view === "Tabular" && (
          <>
            <ConfirmationPopUp
              onSort={onSort}
              setProjectFrozen={setProjectFrozen}
              projectData={flatPegadata}
              addFrozenColumns={addFrozenColumns}
              onGlobalFilterChange={onGlobalFilterChange}
              selectedColumnName={selectedColumnName}
              ProjectFrozen={ProjectFrozen}
              selectedFields={selectedFields}
              setFrozenColumn={setFrozenColumn}
              frozenCoulmns={frozenCoulmns}
              sortData={sortData}
              setSortData={setSortData}
              setFilters={setFilters}
              filters={filters}
              op={op}
              clearColumnWiseFilter={clearColumnWiseFilter}
              frozenUpdated={frozenUpdated}
              setFrozenUpdated={setFrozenUpdated}
              fieldUpdated={fieldUpdated}
              setFieldUpdated={setFieldUpdated}
              columnWiseSelectedFields={columnWiseSelectedFields}
              setColumnWiseSelectedFields={setColumnWiseSelectedFields}
            />
            <ProjectPlanTable {...props} columns={dynamicColumns()} allColumns={allColumns} />
            {showTaskDialog && (
              <TaskDialog
                onClose={() => setShowTaskDialog(!showTaskDialog)}
                showTaskDialog={showTaskDialog}
                selectedTaskData={selectedTask}
                flag={flag}
              />
            )}
            <>
              {/* Dialog box to display the message */}
              <Dialog
                visible={showDialog}
                onHide={() => setShowDialog(false)}
                header="Help Needed"
                modal
                className="MassDailog"
              >
                <div>
                  <span className="helpneed_dialog">Reason:</span>
                  <p className="mb-5">
                    {messageContent[1] === "undefined" ? "" : messageContent[1]}
                  </p>
                  <span className="helpneed_dialog">Comments:</span>
                  <p className="mb-5">
                    {messageContent[0] === "undefined" ? "" : messageContent[0]}
                  </p>
                </div>
              </Dialog>
              {/* Rest of your component */}
            </>
          </>
        )}
      </Suspense>
    </div>
  );
};

export default ProjectPlanList;
