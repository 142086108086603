import React from 'react';

const PDFViewer = ({ path, width, height }) => {
  return (
    <div>
      <object class="pdf" data={path} width={width} height={height}></object>
    </div>
  );
};
export default PDFViewer;
