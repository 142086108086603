import { useState } from "react";
import FileList from "./FileList";
import VersionHistory from "../FileVersionHistory/VersionHistory";
import { changeDateFormat, replaceSpecialChars } from "../../../utils";
import { isArray } from "lodash";

const transformFileList = (data) => {
  return Object.keys(data)
    .filter((key) => isNaN(key))
    .map((key) => {
      return data?.[key]?.map((item) => {
        if (key === "GABriefFiles" || key.includes("GABDetails")) {
          return {
            FileID: item?.FileID,
            Version: item?.Version,
            File_Name: item?.File_Name,
            Timestamp: item?.Timestamp,
            ReferenceDocumentType: key,
            isCopyXMLFile: false,
            GroupName: "GA Brief Files",
            JobID: item?.JobID,
            JobName: item?.JobName,
          };
        } else if (key === "OtherReferenceDocuments" || key.includes("ORDDetails")) {
          return {
            FileID: item?.FileID,
            Version: item?.Version,
            File_Name: item?.File_Name,
            Timestamp: item?.Timestamp,
            ReferenceDocumentType: key,
            isCopyXMLFile: false,
            GroupName: "Other Reference Documents",
            JobID: item?.JobID,
            JobName: item?.JobName,
          };
        } else if (key.includes("RDTDetails")) {
          return {
            FileID: item?.FileID,
            Version: item?.Version,
            File_Name: item?.File_Name,
            Timestamp: item?.Timestamp,
            ReferenceDocumentType: key,
            isCopyXMLFile: false,
            GroupName: "RDT Files",
            JobID: item?.JobID,
            JobName: item?.JobName,
          };
        } else {
          return {
            FileID: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
            Version: item?.versionNumber,
            File_Name: item?.fileName,
            Timestamp: item?.createdDate,
            ReferenceDocumentType: key,
            isCopyXMLFile: item?.isCopyXMLFile,
            GroupName: item?.isCopyXMLFile ? "Copy XML" : key,
            JobID: "",
            JobName: "",
          };
        }
      });
    });
};

const FileListContainer = (props) => {
  const { supplierFileList } = props;
  //   const [versionIndex, setVersionIndex] = useState(-1);
  const [versionFiles, setVersionFiles] = useState([]);

  const fileList = transformFileList(supplierFileList);

  //AWM-3472
  let filteredFileList = fileList.filter((arr)=>{
    return arr?.length>0
  })

  //AWM-3472
  let latestVersionFileList = filteredFileList.map(group => {
    if (group.length !== 0){
      // Find the highest version in the group
      const latestVersion = Math.max(...group.map(file => parseInt(file.Version)));
      // Filter files with the latest version
      const latestFiles = group.filter(file => parseInt(file.Version) === latestVersion);
      // If all files have the same version, return the whole group as an array of objects
      if (latestFiles.length>1 && latestFiles.length === group.length) {
        return latestFiles;
      }else{
        return latestFiles[0]
      }
    }     
  });

  // const latestVersionFileList = fileList
  //   .map((innerFileList) => {
  //     if (innerFileList?.[0]) {
  //       return innerFileList[0];
  //     } else {
  //       return undefined;
  //     }
  //   })
  //   .filter((innerFileList) => innerFileList !== undefined);

  function showVersionPopUp(files, rowData) {
    // setVersionIndex(index);
    if (
      rowData?.GroupName === "GA Brief Files" ||
      rowData?.GroupName === "Other Reference Documents"
    ) {
      let file = [];
      files?.forEach((obj) => {
        if (obj?.FileID === rowData?.FileID) {
          file.push(rowData);
        }
      });
      setVersionFiles(file);
    } else {
      setVersionFiles(files);
    }
  }

  const latestVersionGroupedFiles = {};

  latestVersionFileList.forEach((fileInfo) => {
    if(isArray(fileInfo)){    //AWM-3472
      fileInfo.forEach((innerFileInfo)=>{
        const groupName = innerFileInfo.GroupName;
        if (!latestVersionGroupedFiles[groupName]) {
          latestVersionGroupedFiles[groupName] = [];
        }
        latestVersionGroupedFiles[groupName].push(innerFileInfo);
        })
    }else{
      const groupName = fileInfo.GroupName;
      if (!latestVersionGroupedFiles[groupName]) {
        latestVersionGroupedFiles[groupName] = [];
      }
      latestVersionGroupedFiles[groupName].push(fileInfo);
    }
  });
  const groupKeys = Object.keys(latestVersionGroupedFiles);

  const groupedFiles = {};

  fileList?.forEach((group) => {
    if (group?.length) {
      const groupName =
        props.subFolder === "CICMATRIX"
          ? group[0].ReferenceDocumentType
          : group[0].GroupName === "RDT Files"
          ? group[0].ReferenceDocumentType
          : group[0].GroupName;
      groupedFiles[groupName] = group;
    }
  });

  const fileVersionList = versionFiles?.map((versionFileList) => {
    return {
      Version: versionFileList.Version,
      FileID: versionFileList.FileID,//AWM-3472
      Filename: replaceSpecialChars(versionFileList.File_Name),
      Owner: (
        <div>
          {versionFileList.Full_Name}
          <br />
          {versionFileList.Email}
        </div>
      ),
      Date: changeDateFormat(versionFileList.Timestamp),
      ReferenceDocumentType: versionFileList?.ReferenceDocumentType,
      isCopyXMLFile: versionFileList?.isCopyXMLFile,
      JobID: versionFileList?.JobID,
      JobName: versionFileList?.JobName,
    };
  });

  return (
    <div>
      {groupKeys?.map((key) => {
        let files;
        //AWM-3472: commented below code as it is not required
        // if (key === "GA Brief Files" || key === "Other Reference Documents") {
        //   if (groupedFiles[key]) {
        //     files = groupedFiles[key];
        //   } else {
        //     for (const property in groupedFiles) {
        //       if (property.includes("GABDetails") || property.includes("ORDDetails")) {
        //         files = groupedFiles[property];
        //       }
        //     }
        //   }
        //   if (!Array.isArray(files)) {
        //     files = [];
        //   }
        // }
        // console.log("files",files)
        return (
          <>
            <span>&#10022;{`${key}`}</span>
            <FileList
              {...props}
              subFolder={props.subFolder}
              groupedFiles={groupedFiles}
              latestVersionGroupedFiles={latestVersionGroupedFiles}
              fileList={files?.length ? files : latestVersionGroupedFiles[key]}
              showVersionPopUp={showVersionPopUp}
              toShowOwner={false}
              toShowDownloadOption={true}
              toShowViewOption={false}
              toShowDeleteOption={false}
              toShowUploadOption={false}
              companyName={props.companyName}
            />
          </>
        );
      })}
      <VersionHistory
        showVersionList={versionFiles?.length > 0}
        fileList={fileVersionList}
        onHide={() => setVersionFiles([])}
        subFolder={props.subFolder}
        toShowOwner={false}
        toShowDownloadOption={true}
        toShowViewOption={false}
        toShowDeleteOption={false}
        toShowUploadOption={false}
        companyName={props.companyName}
      ></VersionHistory>
    </div>
  );
};

export default FileListContainer;
