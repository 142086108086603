import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const useAzureUploadFolderStructure = (subFolder, version, toSendWithVersion = true) => {
    const { ProjectID } = useParams();
    const { TaskDetailsData } = useSelector((state) => state.TaskDetailsReducer);

    const BU = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU;
    const projectName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;
    const jobName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.Design_Job_Name
        ? TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.Design_Job_Name
        : subFolder;

    const regionName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region;
    const Project = ProjectID + " " + projectName;
    let folderStructure = `${BU}/${regionName}/${Project}/${subFolder}/${jobName}`;

    if (toSendWithVersion) {
        return `${folderStructure}/${version}/`;
    }

    return folderStructure
};

export default useAzureUploadFolderStructure;
