import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import { confirmHeader, Cancel, Yes, changehandingHeader } from './ChangeNotificationConstant';

const ConfirmChangesPopUp = ({
  handleConfirm,
  PIMaterialNumber,
  POA,
  RTAStatus,
  listOfChangesData,
  handleSubmit,
}) => {
  const footerContent = (
    <div>
      <Button variant="secondary" onClick={handleConfirm}>
        {Cancel}
      </Button>
      <Button variant="secondary" onClick={handleSubmit}>
        {Yes}
      </Button>
    </div>
  );

  return (
    <div className="card flex justify-content-center dsbp-action-dialog">
      <Dialog
        header={confirmHeader}
        visible={true}
        footer={footerContent}
        className="actionDialog dsbpCommonPopup poaconfirmpopup"
        onHide={handleConfirm}
      >
        <div className="popfield padding-5">
          <div>
            {' '}
            {changehandingHeader.PIMaterialNumber} -{' '}
            <span className="font-color"> {PIMaterialNumber}</span>{' '}
          </div>
          <div>
            {' '}
            {changehandingHeader.POARta} - <span className="font-color"> {POA}</span>{' '}
          </div>
          <div>
            {' '}
            {changehandingHeader.RTAPOAStatus} - <span className="font-color"> {RTAStatus}</span>{' '}
          </div>
        </div>
        <div className="">
          <div className="project-name  padding-5">Updates</div>
          <div className="padding-5 updates">
            {listOfChangesData?.map((item) => (
              <div className="updatesData">
                {item?.Label} : <span> {item?.NewValue}</span>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default ConfirmChangesPopUp;
