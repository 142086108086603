import Api from ".";
import { DEVURL } from "./envUrl";

export const clearDefinedJobs = async (data, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;
  try {
    apiURL = `${DEVURL}/submitClearButton`;
    const clearResponse = await axiosInstance({
      url: apiURL,
      method: "POST",
      data: data,
    });
    return clearResponse;
  } catch (error) {
    console.log(error.message);
  }
};
