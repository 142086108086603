import { cloneDeep } from "lodash";
import { AcpService } from "./ACPService";

export const createDraftData = (data) => {
  let draft = cloneDeep(data);
  let draftData = draft?.map((el) => {
    el.isSelected = false;
    el.isDraft = true;
    return el;
  });
  return draftData;
};

export const createDraftChanges = (draftChanges) => {
  let draftData = [];
  draftChanges?.forEach((el) => {
    let temp = cloneDeep(el);
    temp.isSelected = true;
    temp.isDraft = false;
    draftData.push(temp);
  });
  return draftData;
};

export const findDraftTaskData = (acpPlanningData, taskDraftChanges, isTaskData) => {
  let initialtaskData = cloneDeep(acpPlanningData);
  let updatedTasksdata = cloneDeep(acpPlanningData);
  if (taskDraftChanges?.length > 0) {
    //replace records in acpPlanningData where draft matches are found
    let draftData = [];
    taskDraftChanges?.forEach((el) => {
      let temp = cloneDeep(el);

      temp.isSelected = true;
      temp.isDraft = false;
      draftData.push(temp);
    });
    let allRecordsChanged =
      isTaskData?.length > 0 && isTaskData?.length > draftData?.length ? isTaskData : draftData;
    allRecordsChanged?.forEach((el) => {
      if (el.AWMTaskID === "") {
        //case of RTA task
        initialtaskData = initialtaskData?.map((ele) => {
          if (ele?.POANumber === el?.POANumber) {
            return el;
          } else {
            return ele;
          }
        });
      } else {
        //case of AWM task
        initialtaskData = initialtaskData?.map((ele) => {
          if (ele?.AWMTaskID === el?.AWMTaskID) {
            return el;
          } else {
            return ele;
          }
        });
      }
    });
    let updatedtasks = initialtaskData.filter((item) => !AcpService.isUBN(item?.NewStartDate));
    let emptydatetasks = initialtaskData.filter((item) => AcpService.isUBN(item?.NewStartDate));
    updatedTasksdata = updatedtasks?.length ? updatedtasks.concat(emptydatetasks) : allRecordsChanged.concat(emptydatetasks);
  }
  const decided = taskDraftChanges?.length > 0 ? updatedTasksdata : acpPlanningData;
  return decided;
};

export const findDraftProjectData = (
  acpPlanningProjectListData,
  projectDraftChanges,
  isProjectData
) => {
  let initialProjectData = cloneDeep(acpPlanningProjectListData);
  let updatedProjectData = cloneDeep(acpPlanningProjectListData);
  if (projectDraftChanges?.length > 0) {
    //replace records in acpPlanningData where draft matches are found
    let draftData = [];
    projectDraftChanges?.forEach((el) => {
      let temp = cloneDeep(el);

      temp.isSelected = true;
      temp.isDraft = false;
      draftData.push(temp);
    });
    let allRecordsChanged =
      isProjectData?.length > 0 && isProjectData?.length > draftData?.length
        ? isProjectData
        : draftData;
        const updatedArray = initialProjectData?.map(item => {
          const diffItem = allRecordsChanged.find(diff => diff.AWMProjectID === item.AWMProjectID);
          return diffItem ? { ...item, ...diffItem } : item;
        });
        
        // Add new elements from diffArray that aren't in originalArray
        const newElements = allRecordsChanged.filter(diff => !initialProjectData.some(item => item.AWMProjectID === diff.AWMProjectID));
        
        const resultArray = [...updatedArray, ...newElements];

    let updatedprojects = resultArray.filter((item) => !AcpService.isUBN(item?.NewStartDate));
    let emptydatetProjects = resultArray.filter((item) => AcpService.isUBN(item?.NewStartDate));
    updatedProjectData = updatedprojects?.length ?  updatedprojects.concat(emptydatetProjects) : allRecordsChanged.concat(emptydatetProjects);
  }
  let datawithDraftChanges =
    projectDraftChanges?.length > 0 ? updatedProjectData : acpPlanningProjectListData;
  return datawithDraftChanges;
};
