import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import PageLayout from "../../PageLayout";
import TaskHeader from "../DesignJobs/TaskHeader";
import { useDispatch, useSelector } from "react-redux";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import "../DesignJobs/index.scss";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CloneJobs from "../DesignJobs/CloneJobs";
import "./index.scss";
import CDHeader from "../DesignJobs/CDHeader";
import { saveColorDevelopment, submitColorDevelopment } from "../../../apis/colorDevelopmentApi";
import { CheckReadOnlyAccess, createDesignJobName } from "../../../utils";
import IQCDFooterButtons from "../DesignJobs/IQCDFooterButtons";
import { cloneDeep } from "lodash";

const breadcrumb = [{ label: "Define Color Development & Print Trial" }];

const headerName = "Define Color Development  & Print Trial";
const jobName = "CD_";

function DNPF() {
  const dispatch = useDispatch();
  const { TaskDetailsData, loading } = useSelector((state) => state.TaskDetailsReducer);
  const [data, setData] = useState(null);
  const [CD, setCD] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [formValidForSaveAsDraft, setFormValidForSaveAsDraft] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [submittedDI, setSubmittedDI] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  let { page1, page2, pageType, TaskID, ProjectID } = useParams();
  const navigate = useNavigate();
  const checkReadWriteAccess = CheckReadOnlyAccess();

  let Brand = [];
  let Category = [];

  if (TaskDetailsData?.ArtworkAgilityPage) {
    Brand = TaskDetailsData.ArtworkAgilityPage.Artwork_Brand;
    Category = TaskDetailsData.ArtworkAgilityPage.Artwork_Category;
  }

  useEffect(() => {
    let taskId;
    if (TaskID) {
      taskId = TaskID.split("_")[1];
    }
    dispatch(getTaskDetails(TaskID, ProjectID));
  }, [dispatch, TaskID, ProjectID]);

  useEffect(() => {
    if (TaskDetailsData) {
      setCD(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || []);
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);
    }
  }, [TaskDetailsData]);

  const handleCancel = () => {
    if (page2 && page2 === "projectPlan") {
      navigate(`/${page1}/${page2}/${TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID}`);
    } else if (pageType) {
      navigate(`/${pageType}`);
    }
  };

  const handleDelete = (index) => {
    const sub = CD?.map((item, i) => {
      if (i === index) {
        item.Action = "delete";
      }
      return item;
    });

    const notDeletedItems = CD.filter((item) => {
      return item.Action !== "delete";
    });
    if (notDeletedItems.length === 0) {
      setSelectAllCheckbox(false);
    }
    setCD(sub);
  };

  useEffect(() => {
    checkFormValidity(CD);
    checkFormValidityForSaveAsDraft(CD);
  }, [data]);

  const addNewEmptyDesign = () => {
    const newDesignIntent = [
      ...CD,
      {
        Design_Job_ID: CD.length + 1,
        isNew: true,
        Print_Trial_Done: null,
        Tier: "",
        Cluster: "",
        Agency_Reference: "",
        Printer: [],
        Printing_Process: "",
        Design_Job_Name: "",
        Substrate: "",
        Additional_Info: "",
        CD_Approved: null,
        Select: null,
        Print_Trial_Needed: null,
        Design_Job_Status: "",
      },
    ];
    setCD(newDesignIntent);
    setUpdated(!updated);
  };

  const addData = (fieldName, index, value, Design_Intent_Name) => {
    let Project_Name = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;
    let CDdata = cloneDeep(CD);
    let data = CDdata[index];
    data[fieldName] = value;
    let designJobName = createDesignJobName(
      data.Printer,
      data.Pantone,
      data.Printing_Process,
      data.Substrate,
      data.Additional_Info,
      Project_Name,
      Brand,
      Category,
      jobName
    );
    data["Design_Job_Name"] = designJobName;
    if (fieldName === "Select") {
      let checkedLineItems = CDdata.filter((obj) => {
        return obj.Select === true;
      });
      if (checkedLineItems.length === CDdata.length) {
        setSelectAllCheckbox(true);
      } else {
        setSelectAllCheckbox(false);
      }
    }
    setCD(CDdata);

    setSubmittedDI(Math.random());
    checkFormValidity(CDdata);
    checkFormValidityForSaveAsDraft(CDdata);
  };

  useEffect(() => {
    let count = 0;
    CD?.forEach((obj) => {
      if (obj.Select) {
        count++;
      }
    });
    if (CD.length === count) {
      setSelectAllCheckbox(true);
    } else {
      setSelectAllCheckbox(false);
    }
  }, [submittedDI]);

  const checkFormValidity = (CDdata) => {
    const validTasks = CDdata?.filter((task) => {
      return (
        task?.Printing_Process &&
        task?.Substrate &&
        task?.Select &&
        (task.Design_Job_Status === "Awaiting" || task.Design_Job_Status === "") &&
        task?.Printer?.length >= 1
      );
    });
    const selectedTasks = CDdata?.filter((task) => {
      return (
        task?.Select && (task.Design_Job_Status === "Awaiting" || task.Design_Job_Status === "")
      );
    });

    if (
      validTasks?.length > 0 &&
      selectedTasks?.length > 0 &&
      validTasks?.length === selectedTasks?.length
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  const checkFormValidityForSaveAsDraft = (CDdata) => {
    const lineItems = CDdata?.filter((task) => {
      return task?.Design_Job_Status === "Awaiting";
    });

    if (lineItems?.length > 0) {
      setFormValidForSaveAsDraft(true);
    } else {
      setFormValidForSaveAsDraft(false);
    }
  };

  const onSelectAll = (checked) => {
    let CDdata = cloneDeep(CD);
    CDdata?.forEach((task) => {
      if (task?.Design_Job_Status === "Awaiting" || task?.Design_Job_Status === "") {
        task.Select = checked;
      }
    });
    setCD(CDdata);
    setUpdated(!updated);
  };

  const handleDeleteJob = async (Design_Job_ID) => {
    setLoader(true);
    let pageInstructions = [];

    let submitOnlySelectedData = CD?.filter((task) => Design_Job_ID === task?.Design_Job_ID);
    if (submitOnlySelectedData?.length > 0) {
      let temp = {};
      temp["instruction"] = "APPEND";
      temp["target"] = "NewPrintFeasibilityList";
      temp["content"] = {
        DesignJobName: submitOnlySelectedData[0]?.Design_Job_Name,
        DesignJobID: submitOnlySelectedData[0]?.Design_Job_ID,
        PrintingProcess: submitOnlySelectedData[0]?.Printing_Process,
        Substrate: submitOnlySelectedData[0]?.Substrate,
        Select: submitOnlySelectedData[0]?.Select?.toString(),
        PrintTrialNeeded: submitOnlySelectedData[0]?.Print_Trial_Needed?.toString(),
        AdditionalInfo: submitOnlySelectedData[0]?.Additional_Info,
        Action: "delete",
        // Printer: submitOnlySelectedData[0]?.Printer ? submitOnlySelectedData[0].Printer : [],
      };
      pageInstructions.push(temp);
    }

    let formData = {
      caseTypeID: "PG-AAS-Work-DefineNewPrintFeasibilityScope",
      content: {
        AWMTaskID: data.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      },
      pageInstructions: pageInstructions,
    };

    let id = data.Task_Key;
    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };

    await submitColorDevelopment(formData, id, headers);
    dispatch(getTaskDetails(TaskID, ProjectID));
    setLoader(false);
  };

  const onSubmit = async () => {
    setLoader(true);
    let pageInstructions = [];

    let submitOnlySelectedData = CD?.filter(
      (task) =>
        task?.Select === true &&
        (task?.Design_Job_Status === "" ||
          task?.Design_Job_Status === "Awaiting" ||
          task?.isNew === true)
    );
    submitOnlySelectedData.forEach((task) => {
      let taskAction = "update";
      if (task?.Action !== "delete" && task?.Design_Job_ID) {
        taskAction = "update";
      }
      if (task?.Action !== "delete" && task?.isNew === true) {
        taskAction = "add";
      }
      if (task?.Action === "delete") {
        taskAction = "delete";
      }
      let taskDesignJobID = task?.Design_Job_ID;
      if (task?.isNew) {
        taskDesignJobID = "";
      }
      let temp = {};
      temp["instruction"] = "APPEND";
      temp["target"] = "NewPrintFeasibilityList";
      temp["content"] = {
        DesignJobName: task?.Design_Job_Name,
        DesignJobID: taskDesignJobID,
        PrintingProcess: task?.Printing_Process,
        Substrate: task?.Substrate,
        Select: task?.Select?.toString(),
        PrintTrialNeeded: task?.Print_Trial_Needed?.toString(),
        AdditionalInfo: task?.Additional_Info,
        Action: taskAction,
        Printer: task?.Printer,
      };
      pageInstructions.push(temp);
    });
    let formData = {
      caseTypeID: "PG-AAS-Work-DefineNewPrintFeasibilityScope",
      content: {
        AWMTaskID: data.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      },
      pageInstructions: pageInstructions,
    };

    let id = data.Task_Key;
    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };

    await submitColorDevelopment(formData, id, headers);
    setLoader(false);

    if (page2 && page2 === "projectPlan") {
      navigate(`/${page1}/${page2}/${TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID}`);
    } else if (pageType) {
      navigate(`/${pageType}`);
    }
  };

  const onSaveAsDraft = async () => {
    setLoader(true);
    let counter = 0;
    let submitOnlySelectedData = CD?.filter(
      (task) => task?.Design_Job_Status === "Awaiting" || task?.Design_Job_Status === ""
    );

    submitOnlySelectedData.map((task) => {
      counter++;

      if (task?.Action !== "delete" && task?.Design_Job_ID) {
        task.Action = "update";
      }
      if (task?.Action !== "delete" && task?.isNew === true) {
        task.Action = "add";
      }
      if (task?.Action === "delete") {
        task.Action = "delete";
      }
      if (task?.isNew) {
        task.Design_Job_ID = "";
      }

      task.Design_Job_Name = task?.Design_Job_Name;

      return task;
    });

    let formData = {
      AWM_Project_ID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      AWM_Task_ID: data.Task_ID,
      Project_Name: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name,
      BU: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU,
      Region: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region,
      NewPrintFeasibilityList: submitOnlySelectedData,
    };

    await saveColorDevelopment(formData);
    dispatch(getTaskDetails(TaskID, ProjectID));
    setLoader(false);
  };

  const loadOnClearButton = () => {
    dispatch(getTaskDetails(TaskID, ProjectID));
  };
  console.log("CD", CD);
  return (
    <PageLayout>
      <LoadingOverlay active={loader || loading || CD === null} spinner text="">
        <CDHeader
          setAddNewDesign={addNewEmptyDesign}
          onSelectAll={onSelectAll}
          selectAllCheckbox={selectAllCheckbox}
          breadcrumb={breadcrumb}
          headerName={headerName}
          label="Define Color Development & Print Trial"
          checkReadWriteAccess={checkReadWriteAccess}
          data={data}
        />
        <div className="task-details">
          {<TaskHeader {...data} TaskDetailsData={TaskDetailsData} />}

          {CD &&
            CD.length > 0 &&
            CD.map((item, index) => {
              if (item && item?.Action !== "delete") {
                return (
                  <CloneJobs
                    key={index}
                    {...data}
                    data={data}
                    item={item}
                    AWMProjectID={TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID}
                    AWMTaskID={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID}
                    Bu={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU}
                    Region={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region}
                    hideClearButton={true}
                    handleDeleteJob={handleDeleteJob}
                    loadOnClearButton={loadOnClearButton}
                    onSaveAsDraft={onSaveAsDraft}
                    index={index}
                    addData={addData}
                    handleDelete={handleDelete}
                    jobName={jobName}
                    setFormValid={setFormValid}
                    checkReadWriteAccess={checkReadWriteAccess}
                    Artwork_Brand={TaskDetailsData?.ArtworkAgilityPage?.Artwork_Brand}
                    Artwork_Category={TaskDetailsData?.ArtworkAgilityPage?.Artwork_Category}
                    Taskdata={TaskDetailsData}
                  />
                );
              }
            })}
        </div>
        <IQCDFooterButtons
          handleCancel={handleCancel}
          onSaveAsDraft={onSaveAsDraft}
          onSubmit={onSubmit}
          formValid={!formValid}
          checkReadWriteAccess={checkReadWriteAccess}
          bottomFixed={true}
          data={data}
          formValidForSaveAsDraft={formValidForSaveAsDraft}
        />
      </LoadingOverlay>
    </PageLayout>
  );
}

export default DNPF;
