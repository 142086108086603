import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const IQCDFooterButtons = ({
  onSaveAsDraft,
  onSubmit,
  handleCancel,
  approve,
  formValid,
  bottomFixed,
  cptFormValid,
  data,
}) => {
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);
  useEffect(() => {
    if (User && data) {
      if (userInformation?.userid === data?.Assignee) {
        setCheckReadWriteAccess(true);
      } else {
        setCheckReadWriteAccess(false);
      }
    }
  }, [User, data]);

  let { TaskID } = useParams();
  const pathName = TaskID.split("_")[0];

  return (
    <div
      className={bottomFixed ? "form-buttons bottom-fixed" : "form-buttons"}
      style={{ padding: 15, background: "#FAFAFA" }}
    >
      <Button className="button-layout" variant="secondary" onClick={() => handleCancel()}>
        Back
      </Button>
      {!approve && (
        <>
          {pathName !== "CNIQ" &&
            pathName !== "CCD" &&
            pathName !== "CPT" &&
            pathName !== "DNPF" &&
            pathName !== "DNIQ" && (
              <Button
                className={checkReadWriteAccess ? "button-layout" : "btn btn-disabled"}
                variant="secondary"
                onClick={() => onSaveAsDraft()}
                disabled={!checkReadWriteAccess}
              >
                Save as Draft
              </Button>
            )}
          <Button
            className="button-layout"
            type="submit"
            onClick={() => onSubmit()}
            disabled={
              (pathName === "CPT" ? !cptFormValid : formValid) ||
              !checkReadWriteAccess ||
              ((pathName === "CNIQ" || pathName === "CCD" || pathName === "CPT") &&
                data?.Task_Status === "Complete") ||
              data?.Task_Status === "Cancelled"
            }
          >
            Submit
          </Button>
        </>
      )}
    </div>
  );
};

export default IQCDFooterButtons;
