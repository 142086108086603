import { Dialog } from "primereact/dialog";
import React from "react";

function CommonAPIErrorPopup({ onClose, apiError }) {
  let errorMessage = apiError?.message; // Default error message
  const regex = /{.*}/;
  const match = apiError?.status===423 && apiError?.response?.data?.match(regex);
  let errorCode="";
  let errorDescription="";
  if (match) {
    // Parse the matched JSON string
    const data = JSON.parse(match[0]);
    // Now access ErrorCode and ErrorDescription
    errorCode = data?.ErrorCode;
    errorDescription = data?.ErrorDescription;
  }

  // If the status code is 423, extract the error message from response.data
  if (errorCode==="Pega_API_031" && apiError?.response?.data) {
    try {
        errorMessage = errorDescription || errorMessage;
    } catch (parseError) {
      console.error("Error parsing API error data:", parseError);
    }
  }

  return (
    <Dialog
      visible={true}
      className="common-api-error-dialog"
      onHide={onClose}
      header={<div className="p-dialog-md">Error Message</div>}
    >
      {/* Show URL only if status is not 429 */}
      {!errorCode && <ul>{apiError?.config?.url}</ul>}

      <ul>{errorMessage}</ul>
    </Dialog>
  );
}

export default CommonAPIErrorPopup;
