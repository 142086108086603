import React from 'react';
import { TreeTable } from 'primereact/treetable';

function CommonTreeTable({
  scrollHeight,
  data,
  selectedNodeKeys,
  setSelectedNodeKeys,
  setExpandedKeys,
  expandedKeys,
  children,
  frozenWidth,
  onColumnResizeEnd,
  onSelectColumn
}) {

  return (
    <TreeTable
      value={data}
      selectionMode="checkbox"
      selectionKeys={selectedNodeKeys}
      onSelectionChange={(e) => setSelectedNodeKeys(e.value)}
      expanded
      onColumnResizeEnd={onColumnResizeEnd}
      expandedKeys={expandedKeys}
      frozenWidth={frozenWidth}
      onToggle={(e) => setExpandedKeys(e.value)}
      tableStyle={{
        minWidth: '90%',
        width: 'maxContent',
        minHeight: '30px',
      }}
      scrollWidth="100%"
      columnResizeMode="expand"
      resizableColumns
      reorderableColumns
      showGridlines
      scrollable
      rowClassName={onSelectColumn}
    >
      {children}
    </TreeTable>
  );
}

export default CommonTreeTable;
