import React, { useState } from 'react'
import './index.scss'
import { rearrangeByStatusOrderAndSortAlphabetically } from '../../../Utils/helpers';

function ShowMoreList({data, propertyName, sliceIndex, disabled}) {
    const [isShowMore, setIsShowMore] = useState(false);
    const toggleShowMore = () => {setIsShowMore(!isShowMore)};

  return ( 
    <div className={`show-more-list ${disabled && 'disabled-text'}`}>
        {data?.map((obj, index) => {
            return (
                <div className={`selected-list ${index<sliceIndex ? "show" : (index >=sliceIndex && isShowMore) ? "show" : "hide"}`} >
                    <div className={`list-name`} >{obj[propertyName]}</div>
                </div>
            );
        })}           
        {data?.length > sliceIndex && 
            <a onClick={toggleShowMore} className='show-more-less-text'>
                {!isShowMore ? '... Show more' : 'Show less'}
            </a>
        }
    </div>
  )
}

export default ShowMoreList
