import { useState } from "react";
import { textFieldMaxLength, spcialCharAlert } from "../../../constants";
import { validateInputSpecialChar } from "../../../utils";

const ControlledInput = ({
    placeholder,
    handleInputChange,
    setInputValid,
    id,
    disabled,
    maxLength,
    value
}) => {
const [errorAlert,setErrorAlert]=useState(false);

    const handleChange=(e)=>{
        const value=e.target.value;
        const validInput=validateInputSpecialChar(value);
        if(validInput){
            setErrorAlert(false);
            setInputValid && setInputValid((prevState) => ({
              ...prevState,
              [id]: true
            }));
        } else {
            setInputValid && setInputValid((prevState) => ({
              ...prevState,
              [id]: false
            }));
            setErrorAlert(true);

        }
        handleInputChange(value);

    }
  return (
    <>
      <input
        id={id}
        type="text"
        className={
          value?.length === 0
            ? "form-control font-size-10"
            : value?.length >= textFieldMaxLength
            ? "form-control font-size-10"
            : "form-control border-secondary font-size-10"
        }
        placeholder={placeholder}
        onChange={(e) => handleChange(e)}
        value={value}
        disabled={disabled}
        maxLength={maxLength}
        
      />
     {errorAlert && <div class="special_char_alert">{spcialCharAlert}</div>}
    </>
  );
};

export default ControlledInput;