export const SortAndFilterService = {
  multipleColumnFilter(data, obj) {
    // Helper function to check if an item matches all filters
    const matchesFilters = (item) => {
      return Object.keys(obj)?.every((key) => {
        if (Array.isArray(obj?.[key])) {
          return obj?.[key]?.includes(item?.data?.[key]);
        }
        return item?.data?.[key] === obj?.[key];
      });
    };

    // Recursive function to filter data and ensure inclusion of children and parents
    const recursiveFilter = (items) => {
      return items?.reduce((acc, item) => {
        // Recursively filter children
        const filteredChildren = recursiveFilter(item?.children || []);

        // If the item matches the filters or has any matching children, include it
        if (matchesFilters(item) || filteredChildren?.length > 0) {
          acc?.push({
            ...item,
            children: filteredChildren,
          });
        }

        return acc;
      }, []);
    };

    // Apply the filtering and ensure all relevant ancestors are included
    const filteredData = recursiveFilter(data);

    // Post-process to ensure all ancestors are included for matched children
    const includeAncestors = (items) => {
      const itemMap = new Map();

      // Create a map of items by their key
      const buildMap = (items) => {
        items?.forEach((item) => {
          itemMap?.set(item?.key, item);
          if (item?.children) {
            buildMap(item?.children);
          }
        });
      };

      buildMap(items);

      // Recursively check and include ancestors
      const addAncestors = (item) => {
        const parentKey = item?.parentKey;
        if (parentKey !== undefined) {
          const parentItem = itemMap?.get(parentKey);
          if (parentItem) {
            if (!parentItem?.children) {
              parentItem.children = [];
            }
            if (!parentItem?.children?.find((child) => child?.key === item?.key)) {
              parentItem?.children?.push(item);
            }
            addAncestors(parentItem);
          }
        }
      };

      items?.forEach((item) => {
        if (item?.children) {
          item?.children?.forEach((child) => addAncestors(child));
        }
      });

      return items;
    };

    return includeAncestors(filteredData);
  },
  applyMutilabelFilter(data, obj) {
    return data?.map((node) => multiLabelFiler(node, obj))?.filter((node) => node !== null);
  },
  updateAssigneeToOwner(data) {
    return addAssigneeToOwner(data);
  },
  sortMultiLabelData(data, columnName, direction = "asc") {
    // Helper function to get the value, either from the top level or nested
    function getValue(obj, key) {
      // Check if the key exists at the first level
      if (obj?.[key] !== undefined) {
        return obj?.[key]; // Return if key is directly present at the first level
      }

      // Otherwise, assume it's nested and try to find it in nested objects
      for (let prop in obj) {
        if (typeof obj?.[prop] === "object" && obj?.[prop] !== null) {
          if (obj?.[prop]?.[key] !== undefined) {
            return obj?.[prop]?.[key]; // Return if found in a nested object
          }
        }
      }
      return undefined; // Return undefined if not found
    }

    // Helper function to compare two values
    function compareValues(a, b, dir) {
      if (a === b) return 0;
      if (a === null || a === undefined) return dir === "asc" ? 1 : -1;
      if (b === null || b === undefined) return dir === "asc" ? -1 : 1;
      return dir === "asc" ? (a > b ? 1 : -1) : a < b ? 1 : -1;
    }

    // Recursive function to sort pegadata
    function sortRecursive(data) {
      // Sort current level
      data?.sort((a, b) => {
        const valA = getValue(a?.data, columnName);
        const valB = getValue(b?.data, columnName);
        return compareValues(valA, valB, direction);
      });

      // Recursively sort children
      data?.forEach((item) => {
        if (item?.children && item?.children?.length > 0) {
          sortRecursive(item?.children);
        }
      });
    }

    // Make a deep copy of the data to avoid mutating the original array
    const sortedData = JSON.parse(JSON.stringify(data));

    // Sort recursively
    sortRecursive(sortedData);

    return sortedData;
  },
  sortMultiColumnData(pegadata, columnName, direction) {
    // Compare function for sorting
    const compare = (a, b) => {
      let valueA = a?.data?.[columnName] || "";
      let valueB = b?.data?.[columnName] || "";

      if (typeof valueA === "string" && typeof valueB === "string") {
        // For alphanumeric comparison
        return valueA.localeCompare(valueB, undefined, { numeric: true, sensitivity: "base" });
      }
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    };

    // Recursively sort the data
    const recursiveSort = (data) => {
      // Sort the current level
      data?.sort((a, b) => {
        let result = compare(a, b);
        return direction === "desc" ? -result : result;
      });

      // Recursively sort children if they exist
      data?.forEach((item) => {
        if (item?.children && item?.children?.length > 0) {
          recursiveSort(item?.children);
        }
      });

      return data;
    };

    // Make a deep copy of the pegadata to avoid mutating the original array
    let sortedData = JSON.parse(JSON.stringify(pegadata));
    return recursiveSort(sortedData);
  },
  sortMutipleMultiLabel(node, column, direction = "asc") {
    const sortOrder = direction === "desc" ? -1 : 1;
    function sortChildren(children) {
      if (children) {
        return children?.sort((a, b) => {
          const valueA = a?.children?.[0]?.data?.[column]
            ? column === "Version"
              ? parseInt(a?.children?.[0]?.data?.[column])
              : a?.children?.[0]?.data?.[column]?.toLowerCase()
            : 0;
          const valueB = b?.children?.[0]?.data?.[column]
            ? column === "Version"
              ? parseInt(b?.children?.[0]?.data?.[column])
              : b?.children?.[0]?.data?.[column]?.toLowerCase()
            : 0;
          if (column === "Version") {
            return (valueA - valueB) * sortOrder;
          } else if (column === "Date") {
            const dateA = new Date(valueA);
            const dateB = new Date(valueB);
            if (dateA < dateB) return direction === "asc" ? -1 : 1;
            if (dateA > dateB) return direction === "asc" ? 1 : -1;
            return 0;
          } else {
            return sortOrder * (valueA > valueB ? 1 : valueA < valueB ? -1 : 0);
          }
        });
      }
      return children;
    }

    return {
      ...node,
      children: sortChildren(node.children),
    };
  },
  updateOwnerWithAssignee(pegadata) {
    pegadata.forEach((task) => {
      task.Owner = task.Assignee; // Add or replace the Owner with Assignee value
    });
    return pegadata;
  },
  removeKeyByColumn(obj, selectedColumnName) {
    // Create a new object excluding the selected key
    const updatedObj = Object.keys(obj).reduce((acc, key) => {
      if (key !== selectedColumnName) {
        acc[key] = obj[key]; // Add the key-value pair to the new object
      }
      return acc;
    }, {});

    return updatedObj;
  },
  removeOwnerFromTableData(data) {
    return removeOwnerFromData(data);
  },
};

function multiLabelFiler(node, obj) {
  // Check if the node matches the filters
  const matches = (data, obj) => {
    return Object.entries(obj)?.every(([key, values]) => {
      if (values?.length === 0) return true; // Skip if no filter value is provided
      return values?.includes(data?.[key]);
    });
  };

  let filteredChildren = [];

  // Recursively apply the filter to the children
  if (node.children) {
    filteredChildren = node?.children
      ?.map((child) => multiLabelFiler(child, obj))
      ?.filter((child) => child !== null);
  }

  // Determine if the current node matches
  const isCurrentNodeMatching = matches(node?.data, obj);

  // Include the node if it matches or has matching children
  if (isCurrentNodeMatching || filteredChildren?.length > 0) {
    return {
      ...node,
      children: filteredChildren?.length > 0 ? filteredChildren : node?.children,
    };
  }

  return null;
}

function addAssigneeToOwner(data) {
  return data?.map((item) => {
    // Check if 'data' object exists
    if (item?.data) {
      // If 'Assignee' is present and not empty, set 'Owner' to 'Assignee'; otherwise, set 'Owner' to an empty string
      item.data.Owner = item?.data?.Assignee ? item?.data?.Assignee : "";
    }

    // Recursively handle children if they exist and return updated children
    if (item?.children && item?.children?.length > 0) {
      item.children = addAssigneeToOwner(item?.children);
    }

    return item; // Return the updated item
  });
}

function removeOwnerFromData(data) {
  return data?.map((item) => {
    // Check if 'data' object exists and remove 'Owner' field
    if (item?.data && 'Owner' in item.data) {
      delete item.data.Owner;
    }

    // Recursively handle children if they exist and return updated children
    if (item?.children && item?.children?.length > 0) {
      item.children = removeOwnerFromData(item?.children);
    }

    return item; // Return the updated item
  });
}
