import "./index.scss";
import { Dialog } from "primereact/dialog";
import React, { useState, useEffect } from "react";
import DragAndDrop from "./DragAndDrop/DragAndDrop";

const CustomizeViewDialog = ({ showTaskDialog, onClose, selectedFields, setCustomizeViewFields, customizeViewFields, headerName,
   CDPTPageData, IQData, RDTData, availableFields,resetToPgDefault, setTableRender, tableRender,BU,region, reorderColumnsSetColumnOrder, setResetKey, pgDefaultColumns}) => {
  const [visible, setVisible] = useState(showTaskDialog);

  useEffect(() => {
    setVisible(showTaskDialog);
  }, [showTaskDialog]);

  const hideDialog = () => {
    setVisible(false);
    onClose();
  };

  return (
    (
      <Dialog
        visible={visible}
        onHide={hideDialog}
        className="customize-view-dialog"
        header={<div className="p-dialog-header1">Customize View</div>}
      >
        <div className="p-fluid popup-details">
          <DragAndDrop
            IQData={IQData}
            RDTData={RDTData}
            headerName={headerName}
            hideDialog={hideDialog}
            CDPTPageData={CDPTPageData}
            selectedFields={selectedFields}
            availableFields={availableFields}
            customizeViewFields={customizeViewFields}
            setCustomizeViewFields={setCustomizeViewFields}
            resetToPgDefault={resetToPgDefault}
            setTableRender={setTableRender}
            tableRender={tableRender}
            BU={BU}
            region={region}
            reorderColumnsSetColumnOrder={reorderColumnsSetColumnOrder}
            setResetKey={setResetKey}
            pgDefaultColumns={pgDefaultColumns}
          />
        </div>
      </Dialog>
    )
  );
};

export default CustomizeViewDialog;
