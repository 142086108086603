// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperRejectReason .rejectionTable {
  height: auto;
  overflow: auto;
  margin-bottom: 30px;
}
.wrapperRejectReason .rejectionTable table {
  width: 100%;
  border: 0.5px solid #ACACAC;
}
.wrapperRejectReason .rejectionTable table tr th {
  border: 0.5px solid #ACACAC;
  background-color: #EDF4FA;
  height: 32px;
  padding-left: 20px;
}
.wrapperRejectReason .rejectionTable table tr td {
  border: 0.5px solid #ACACAC;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  text-align: left;
  padding: 9px 0px 9px 32px;
}`, "",{"version":3,"sources":["webpack://./src/components/RejectionDialog/index.scss"],"names":[],"mappings":"AAKE;EACE,YAAA;EACA,cAAA;EACA,mBAAA;AAJJ;AAMI;EACE,WAAA;EACA,2BAAA;AAJN;AAOQ;EACE,2BAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;AALV;AAQQ;EACE,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,gBAAA;EACA,yBAAA;AANV","sourcesContent":["$primary: #003da5;\n$secondary: #acacac;\n\n.wrapperRejectReason {\n\n  .rejectionTable {\n    height: auto;\n    overflow: auto;\n    margin-bottom: 30px;\n\n    table {\n      width: 100%;\n      border: 0.5px solid #ACACAC;\n\n      tr {\n        th {\n          border: 0.5px solid #ACACAC;\n          background-color: #EDF4FA;\n          height: 32px;\n          padding-left: 20px;\n        }\n\n        td {\n          border: 0.5px solid #ACACAC;\n          font-size: 12px;\n          font-weight: 400;\n          line-height: 14.63px;\n          text-align: left;\n          padding: 9px 0px 9px 32px;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
