import ACPFilter from "../common/ACPFilter";
import React, { useState, useEffect } from "react";
import filter from "../../../assets/images/filter.svg";
import { AcpService } from "../../../service/ACPService";

function ColumnData({
  op,
  onSort,
  options,
  filters,
  sortData,
  setFilters,
  allColumns,
  splittedCol,
  projectData,
  tableRender,
  setSortData,
  saveSettings,
  frozenCoulmns,
  ProjectFrozen,
  selectedFields,
  setTableRender,
  availableFields,
  setFrozenColumn,
  setProjectFrozen,
  addFrozenColumns,
  projectColumnName,
  isReorderedColumn,
  setReorderedColumn,
  selectedColumnName,
  projectNameOnClick,
  onGlobalFilterChange,
  setProjectColumnNames,
  setSelectedColumnName,
  clearColumnWiseFilter,
  acpBookingFrozenColumn,
}) {
  const [Toggle, setToggle] = useState(false);
  useEffect(() => {
    if (Toggle) {
      document.getElementsByClassName("tab-content booking-tabs")?.[0] &&
        document
          .getElementsByClassName("tab-content booking-tabs")?.[0]
          .addEventListener("scroll", () => {
            if (!AcpService.isUBN(op?.current) || op?.current != null) {
              op?.current?.hide();
            }
          });
      document.getElementsByClassName("p-datatable-wrapper")?.[0] &&
        document
          .getElementsByClassName("p-datatable-wrapper")?.[0]
          .addEventListener("scroll", () => {
            if (!AcpService.isUBN(op?.current) || op?.current != null) {
              op?.current?.hide();
            }
          });
    }
  }, [Toggle]);
  return (
    <div className="dashboard-filter-wrapper">
      <img
        src={filter}
        alt="Column Filter"
        onClick={(e) => {
          setToggle(!Toggle);
          projectNameOnClick(e, options);
        }}
        className={
          sortData?.includes(splittedCol) ||
          selectedFields[splittedCol]?.[0] ||
          frozenCoulmns?.includes(splittedCol)
            ? "columnFilterIcon filter-color-change"
            : "columnFilterIcon"
        }
      />

      <span
        className={
          sortData?.includes(splittedCol) ||
          selectedFields[splittedCol]?.[0] ||
          frozenCoulmns?.includes(splittedCol)
            ? "filter-color-change"
            : ""
        }
      >
        {splittedCol === "EstimatedAWPrinter" && "AW@ PrinterDate"}
        {splittedCol === "Art@PrinterDate" && "Art@Printer Date"}
        {splittedCol === "AwRedinessDate" && "AW Readiness Date"}
        {splittedCol === "ArtPrinterDate" && "AW@ PrinterDate"}
        {splittedCol === "ProjectName" && "Project Name"}
        {splittedCol === "AWMProjectID" && "Project ID"}
        {splittedCol === "ProjectID" && "Project ID"}
        {splittedCol === "StartDate" && "Start Date"}
        {splittedCol === "PmName" && "PM Name"}

        {splittedCol !== "EstimatedAWPrinter" &&
          splittedCol !== "Art@PrinterDate" &&
          splittedCol !== "AwRedinessDate" &&
          splittedCol !== "ArtPrinterDate" &&
          splittedCol !== "ProjectName" &&
          splittedCol !== "AWMProjectID" &&
          splittedCol !== "StartDate" &&
          splittedCol !== "ProjectID" &&
          splittedCol !== "PmName" &&
          splittedCol}
      </span>

      <div className="dashboard-filter-wrapper">
        <ACPFilter
          op={op}
          onSort={onSort}
          filters={filters}
          pageName="booking"
          sortData={sortData}
          setFilters={setFilters}
          allColumns={allColumns}
          projectData={projectData}
          tableRender={tableRender}
          setSortData={setSortData}
          saveSettings={saveSettings}
          ProjectFrozen={ProjectFrozen}
          frozenCoulmns={frozenCoulmns}
          selectedFields={selectedFields}
          setTableRender={setTableRender}
          setFrozenColumn={setFrozenColumn}
          availableFields={availableFields}
          setProjectFrozen={setProjectFrozen}
          addFrozenColumns={addFrozenColumns}
          projectColumnName={projectColumnName}
          isReorderedColumn={isReorderedColumn}
          selectedColumnName={selectedColumnName}
          setReorderedColumn={setReorderedColumn}
          onGlobalFilterChange={onGlobalFilterChange}
          setProjectColumnNames={setProjectColumnNames}
          setSelectedColumnName={setSelectedColumnName}
          clearColumnWiseFilter={clearColumnWiseFilter}
          acpBookingFrozenColumn={acpBookingFrozenColumn}
        />
      </div>
    </div>
  );
}

export default ColumnData;
