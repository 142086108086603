import { Dialog } from "primereact/dialog";
import React, { useState } from "react";

function CommonAPIErrorPopup({ onClose, apiError }) {
  return (
    <Dialog
      visible={true}
      className="common-api-error-dialog"
      onHide={onClose}
      // header={<div className="p-dialog-md">{apiError?.name}</div>}
      header={<div className="p-dialog-md">{apiError?.message}</div>}
    >
      {/* <ul>{apiError?.code}</ul> */}
      <ul>{apiError?.config.url}</ul>
      <ul>{apiError?.message}</ul>
      {/* <ul>{apiError?.response.data}</ul> */}
    </Dialog>
  );
}

export default CommonAPIErrorPopup;
