import React from 'react';

function CustomHeaderComponent({ tabHeaderDetails, index, handleDelete }) {
  const isPOApresernt = () => {
    if (typeof tabHeaderDetails.description === 'object') {
      if (
        tabHeaderDetails.description.RTA_POANumber != '' &&
        tabHeaderDetails.description.RTA_POADescription != ''
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <div className="custom-tab-header">
        <div className="p-tabview-title">
          {!isPOApresernt() ? (
            <>
              <span>{index === 0 ? 'Artwork Alignment' : tabHeaderDetails.tabHeader} </span>
              <span className="pmpDes">
                {typeof tabHeaderDetails.description === 'object'
                  ? `| ${tabHeaderDetails.description.DSBP_PMP_PIMaterialDescription}`
                  : ''}
              </span>
            </>
          ) : (
            <>
              <span>
                {index === 0 ? 'Artwork Alignment' : tabHeaderDetails.description.RTA_POANumber}{' '}
              </span>
              <span className="pmpDes">
                {typeof tabHeaderDetails.description === 'object'
                  ? `| ${tabHeaderDetails.description.RTA_POADescription}`
                  : ''}
              </span>
            </>
          )}
        </div>
        {index !== 0 && (
          <button className="close-button" onClick={() => handleDelete(index)}>
            &#x2715;
          </button>
        )}
      </div>
    </>
  );
}

export default CustomHeaderComponent;
