import Api from ".";
import { AZUREURL } from "./envUrl";
import { getEnvironmentFromURL,replaceSpecialChars } from "../utils";

export const downloadAzureFileShare = async (
  fileName,
  BU,
  subfolder,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;
  const env = getEnvironmentFromURL();
  const folderStructure = `${env}/${BU}/${subfolder}`;
  apiURL = `${AZUREURL}/downloadFile?name=${folderStructure}&fileName=${fileName}`;

  try {
    // Make a GET request to download the file
    const response = await axiosInstance({
      url: apiURL,
      method: "GET",
      responseType: "blob", // Specify the response type as blob for file downloads
    });

    // Create a temporary anchor element to trigger the download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement("a");
    a.href = url;
    a.download = replaceSpecialChars(fileName); // Set the desired file name
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    return true; // Successful download
  } catch (error) {
    console.error("Error downloading file:", error);
    return false; // Failed download
  }
};
