import moment from "moment";
import { getCopyXmlSupplierFileInfo } from "../../../apis/getSupplierFileInfo";

export const getRestructuredData = (apiData) => {
  let mainTempArr = [];
  let tasks = [];
  const designGroup = {
    group: "Design",
    key: "0",
    code: "Design",
    data: {
      Task: "Design",
    },
    children: [],
  };

  const inputGroup = {
    group: "Input",
    key: "1",
    code: "Input",
    data: {
      Task: "Input",
    },
    children: [],
  };

  const FAAssemblyGroup = {
    group: "Final Art",
    key: "2",
    code: "Final Art",
    data: {
      Task: "Final Art",
    },
    children: [],
  };

  tasks = [
    {
      name: "Define Design Intent",
      code: "DDI",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("DDI_")),
      group: "Design",
    },
    {
      name: "Upload Approved Design Intent",
      code: "UADI",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("UADI_")),
      group: "Design",
    },
    {
      name: "Define Design Template",
      code: "DDT",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("DDT_")),
      group: "Design",
    },
    {
      name: "Upload Regional Design Template",
      code: "URDT",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("URDT_")),
      group: "Design",
    },
    {
      name: "Approve Regional Design Template",
      code: "ARDT",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("ARDT_")),
      group: "Design",
    },
    {
      name: "Define Production Ready Art",
      code: "DPRA",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("DPRA_")),
      group: "Design",
    },
    {
      name: "Upload Production Ready Art",
      code: "UPRA",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("UPRA_")),
      group: "Design",
    },
    {
      name: "Approve Production Ready Art",
      code: "APRA",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("APRA_")),
      group: "Design",
    },
    {
      name: "Confirm Preliminary print feasibility Assessment",
      code: "CPPFA",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("CPPFA_")),
      group: "Design",
    },
    {
      name: "Define New Print Feasibility Scope",
      code: "DNPF",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("DNPF_")),
      group: "Design",
    },
    {
      name: "Confirm Color Development",
      code: "CCD",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("CCD_")),
      group: "Design",
    },
    {
      name: "Confirm Print Trial",
      code: "CPT",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("CPT_")),
      group: "Design",
    },
    {
      name: "Define Ink Qualification",
      code: "DNIQ",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("DNIQ_")),
      group: "Design",
    },
    {
      name: "Confirm New Ink Qualification",
      code: "CNIQ",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("CNIQ_")),
      group: "Design",
    },
    {
      name: "Start Artwork Alignment",
      code: "SAA",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("SAA_")),
      group: "Input",
    },
    {
      name: "Dependency Mapping",
      code: "DM",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("DM_")),
      group: "Input",
    },
    {
      name: "Trigger POA shell creation",
      code: "TPOA",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("TPOA_")),
      group: "Input",
    },
    {
      name: "Copy Phase",
      code: "CPC",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("CPC_")),
      group: "Input",
    },
    {
      name: "Upload Briefing documents",
      code: "UBD",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("UBD_")),
      group: "Input",
    },
    {
      name: "Request CICs",
      code: "RCIC",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("RCIC_")),
      group: "Input",
    },
    {
      name: "Upload CIC",
      code: "UCIC",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("UCIC_")),
      group: "Input",
    },
    {
      name: "Approve CIC",
      code: "ACIC",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("ACIC_")),
      group: "Input",
    },
    {
      name: "Request CIC Matrix",
      code: "RCICMatrix",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("RCICMatrix_")),
      group: "Input",
    },
    {
      name: "Upload CIC Matrix",
      code: "UCICMatrix",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("UCICMatrix_")),
      group: "Input",
    },
    {
      name: "Approve CIC Matrix",
      code: "ACICMatrix",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("ACICMatrix_")),
      group: "Input",
    },
    {
      name: "Send POA to BVE",
      code: "SPB",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("SPB_")),
      group: "Final Art",
    },
    {
      name: "FA Assembly",
      code: "FAAS",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("FAAS_")),
      group: "Final Art",
    },
    {
      name: "FA approval",
      code: "FAAP",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("FAAP_")),
      group: "Final Art",
    },
    {
      name: "Dispatch FA to Printer",
      code: "CSP",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("CSP_")),
      group: "Final Art",
    },
  ];

  tasks.forEach((task, index) => {
    let tempObj = {};
    if (task.data?.length > 0) {
      if (
        task.data[0]?.AWM_Task_ID.includes("DDI_") ||
        task.data[0]?.AWM_Task_ID.includes("DDT_") ||
        task.data[0]?.AWM_Task_ID.includes("DPRA_") ||
        task.data[0]?.AWM_Task_ID.includes("CPPFA_") ||
        task.data[0]?.AWM_Task_ID.includes("DNPF_") ||
        task.data[0]?.AWM_Task_ID.includes("DNIQ_") ||
        task.data[0]?.AWM_Task_ID.includes("SAA_") ||
        task.data[0]?.AWM_Task_ID.includes("DM_")
      ) {
        // tempObj['key'] = index;
        tempObj["key"] =
          task.data[0]?.GroupName === "Design"
            ? `0-${index}`
            : task.data[0]?.GroupName === "Input"
            ? `1-${index}`
            : `2-${index}`;
        tempObj["code"] = task.data[0]?.AWM_Task_ID;

        let dataObj = {};
        dataObj["Task"] = task.data[0]?.AWM_Task_ID.includes("DNPF_")
          ? "Define New Print Feasibility Scope"
          : task.data[0]?.Task_Name;
        dataObj["Task_ID"] = task.data[0]?.AWM_Task_ID;
        dataObj["Dependency"] = task.data[0]?.Dependency;
        dataObj["Role"] = task.data[0]?.Role;
        dataObj["RoleOptions"] = task.data[0]?.RoleOptions;
        dataObj["Assignee"] = task.data[0]?.Assignee;
        dataObj["Owner"] = task.data[0]?.Owner;
        dataObj["State"] = task.data[0]?.State;
        dataObj["TaskDuration"] = task.data[0]?.TaskDuration;
        dataObj["Start_Date"] = task.data[0]?.Start_Date;
        dataObj["End_Date"] = task.data[0]?.End_Date;
        dataObj["Buffer_To_Work"] = task.data[0]?.Buffer_To_Work;
        dataObj["Buffer"] = task.data[0]?.Buffer;
        dataObj["Remaining_Work"] = task.data[0]?.Remaining_Work;
        dataObj["Help_Needed"] = task.data[0]?.Help_Needed;
        dataObj["GroupName"] = task.data[0]?.GroupName;
        dataObj["Days_Left"] = task.data[0]?.Days_Left;
        dataObj["Design_Job_ID"] = task.data[0]?.Design_Job_ID;
        dataObj["Comments"] = task.data[0]?.Comments;
        dataObj["Help_Needed_Reason"] = task.data[0]?.Help_Needed_Reason;
        tempObj["data"] = dataObj;
        tempObj["children"] = [];
        tempObj["redirect"] = true;
      } else {
        let tempArr = [];
        let pStartDate = "";
        let pEndDate = "";
        let startDateArr = [];
        let endDateArr = [];

        tempObj["code"] = task.code;
        // tempObj['key'] = index;
        tempObj["key"] =
          task.data[0]?.GroupName === "Design"
            ? `0-${index}`
            : task.data[0]?.GroupName === "Input"
            ? `1-${index}`
            : `2-${index}`;
        let dataObj = {};
        dataObj["Task"] = `${task.name} (X${task.data?.length})`;
        dataObj["Task_ID"] = task.data[0]?.AWM_Task_ID;
        dataObj["Dependency"] = task.data[0]?.Dependency;
        dataObj["Role"] = "";
        dataObj["RoleOptions"] = "";
        dataObj["Assignee"] = "";
        dataObj["Owner"] = "";
        dataObj["State"] = "";
        dataObj["TaskDuration"] = "";

        dataObj["Buffer_To_Work"] = task.data[0]?.Buffer_To_Work;
        dataObj["Remaining_Work"] = task.data[0]?.Remaining_Work;
        dataObj["Buffer"] = task.data[0]?.Buffer;
        dataObj["Help_Needed"] = false;
        dataObj["GroupName"] = task.data[0]?.GroupName;
        dataObj["Days_Left"] = task.data[0]?.Days_Left;
        dataObj["Design_Job_ID"] = task.data[0]?.Design_Job_ID;
        dataObj["Comments"] = task.data[0]?.Comments;
        dataObj["Help_Needed_Reason"] = task.data[0]?.Help_Needed_Reason;
        tempObj["data"] = dataObj;
        tempObj["redirect"] = true;

        //child array creation

        task.data.forEach((dt, index) => {
          dt.Start_Date && startDateArr?.push(dt.Start_Date);
          dt.End_Date && endDateArr?.push(dt.End_Date);
          pStartDate =
            startDateArr.length > 0 &&
            moment.min(startDateArr.map((date) => moment(date, "YYYYMMDDTHHmmss.SSS [GMT]")));
          pEndDate =
            endDateArr.length > 0 &&
            moment.max(endDateArr.map((date) => moment(date, "YYYYMMDDTHHmmss.SSS [GMT]")));
          let taskStartWith = dt.AWM_Task_ID.charAt(0);
          switch (taskStartWith) {
            case "D":
              taskStartWith = "Define_";
              break;
            case "U":
              taskStartWith = "Upload_";
              break;
            case "A":
              taskStartWith = "Approve_";
              break;
            case "C":
              taskStartWith = "Confirm_";
              break;
            default:
              taskStartWith = "";
              break;
          }
          let tempObj = {};
          tempObj["key"] = index;
          tempObj["code"] = dt.AWM_Task_ID;
          let dataObj = {};

          if (
            dt.AWM_Task_ID.includes("ARDT_") ||
            dt.AWM_Task_ID.includes("ACIC_") ||
            dt.AWM_Task_ID.includes("ACICMatrix_")
          ) {
            dataObj["Task"] = `${dt.Task_Name}`;
          } else {
            dataObj["Task"] = `${index + 1}). ${dt.Task_Name}`;
          }

          dataObj["Task_ID"] = dt.AWM_Task_ID;
          dataObj["Dependency"] = dt.Dependency;
          dataObj["Role"] = dt.Role;
          dataObj["RoleOptions"] = dt.RoleOptions;
          dataObj["Assignee"] = dt.Assignee;
          dataObj["Owner"] = dt.Owner;
          dataObj["State"] = dt.State;
          dataObj["TaskDuration"] = dt.TaskDuration;
          dataObj["Start_Date"] = dt.Start_Date;
          dataObj["End_Date"] = dt.End_Date;
          dataObj["Buffer_To_Work"] = dt.Buffer_To_Work;
          dataObj["Remaining_Work"] = dt.Remaining_Work;
          dataObj["Buffer"] = dt.Buffer;
          dataObj["Help_Needed"] = dt.Help_Needed;
          dataObj["GroupName"] = dt.GroupName;
          dataObj["Days_Left"] = dt.Days_Left;
          dataObj["Design_Job_ID"] = dt.Design_Job_ID;
          dataObj["Comments"] = dt.Comments;
          dataObj["Help_Needed_Reason"] = dt.Help_Needed_Reason;
          tempObj["data"] = dataObj;

          tempObj["children"] = [];
          tempArr.push(tempObj);
        });
        dataObj["Start_Date"] = pStartDate;
        dataObj["End_Date"] = pEndDate;

        if (
          task.data[0]?.AWM_Task_ID.includes("ARDT_") ||
          task.data[0]?.AWM_Task_ID.includes("ACICMatrix") ||
          task.data[0]?.AWM_Task_ID.includes("ACIC_")
        ) {
          const groupCount = countGroupOccurrences(tempArr);
          const count = Object.values(groupCount)[0];
          let subObj = [];
          let j = 0;
          tempArr.forEach((data, index) => {
            let code;
            code = data.data.Design_Job_ID;
            if (!subObj[code]) {
              let i = Object.keys(subObj).length;
              let name = {};
              name["Task"] = task.data[0]?.AWM_Task_ID.includes("ACICMatrix")
                ? `Approve CIC Matrix ${i + 1} (x${count})`
                : task.data[0]?.AWM_Task_ID.includes("ACIC_")
                ? `Approve CIC ${i + 1} (x${count})`
                : `Approve Regional Design Template ${i + 1} (x${count})`;

              let newKey = task.data[0]?.AWM_Task_ID.includes("ACICMatrix")
                ? tasks.findIndex((obj) => {
                    return obj.code === "ACICMatrix";
                  })
                : task.data[0]?.AWM_Task_ID.includes("ACIC_")
                ? tasks.findIndex((obj) => {
                    return obj.code === "ACIC";
                  })
                : tasks.findIndex((obj) => {
                    return obj.code === "ARDT";
                  });

              subObj[code] = {
                key: `0-${newKey}-${i}`,
                children: [],
                data: name,
                code: code,
              };
            }
            if (index % count === 0) {
              j = 1;
            }
            let arr = {};
            arr["key"] = j++;
            data.data["Task"] = `${j - 1}). ${data.data["Task"]}`;
            arr["data"] = data.data;
            arr["code"] = data.data.Task_ID;
            arr["children"] = [];
            subObj[code].children.push(arr);
          });
          const tempSubArray = Object.values(subObj);
          tempObj["children"] = tempSubArray;
        } else {
          tempObj["children"] = tempArr;
        }
      }
      // Add children to the corresponding group
      if (tempObj.data.GroupName === "Design") {
        designGroup.children.push(tempObj);
      } else if (tempObj.data.GroupName === "Input") {
        inputGroup.children.push(tempObj);
      } else {
        FAAssemblyGroup.children.push(tempObj);
      }
    }
  });
  // Add the Design and Input groups to the mainTempArr
  if (designGroup.children.length > 0) {
    mainTempArr.push(designGroup);
  }

  if (inputGroup.children.length > 0) {
    mainTempArr.push(inputGroup);
  }

  if (FAAssemblyGroup.children.length > 0) {
    mainTempArr.push(FAAssemblyGroup);
  }
  return mainTempArr; //toBeReplacedWithapiData;
};

export function extractData(obj, result = []) {
  if (obj.data) {
    const resObj = obj.data;
    resObj.key = obj.key; // adding key for child parent relationship
    result.push(resObj);
  }
  if (obj.children && obj.children.length > 0) {
    obj.children.forEach((child) => extractData(child, result));
  }
  return result;
}

export const countGroupOccurrences = (data) => {
  const dependencyCounts = {};
  data.forEach((item) => {
    let dependency;
    dependency = item.data.Design_Job_ID;

    if (dependencyCounts.hasOwnProperty(dependency)) {
      dependencyCounts[dependency]++;
    } else {
      dependencyCounts[dependency] = 1;
    }
  });

  return dependencyCounts;
};

//3504 fixed to display mulitple copy xml file
export const CopyXmlData = async (response) => {
  const updatedJobList = [];

  for (const item of response?.JobList) {
    if (item?.Data?.Filename === "CIC" || item?.Data?.Filename === "CICMATRIX") {
      for (const job of item?.JobNameList) {
        if (job?.Data?.CopyXMLDetails?.length) {
          // Loop through all CopyXMLDetails instead of just the first element
          for (const detail of job.Data.CopyXMLDetails) {
            if (detail.POANumber && detail.Company) {
              const copyXmlList = await getCopyXmlSupplierFileInfo(
                [detail.POANumber],
                detail.Company
              );
              if (copyXmlList) {
                let versionList = [];
                let versionData = copyXmlList[detail.POANumber] || [];

                for (let data of versionData) {
                  versionList.push({
                    Date: moment(data.createdDate).format("YYYYMMDDTHHmmss.SSS [GMT]"),
                    Email: "",
                    FileID: "",
                    Filename: data.fileName,
                    Filepath: "",
                    Owner: "",
                    Version: data.versionNumber,
                    Company: detail.Company, // Added Company field
                  });
                }

                const copxmlData = {
                  Data: {
                    Date: versionData[0]
                      ? moment(versionData[0].createdDate).format("YYYYMMDDTHHmmss.SSS [GMT]")
                      : "",
                    Email: "",
                    FileID: "",
                    Filename: versionData[0]?.fileName || "",
                    Filepath: "",
                    Owner: "",
                    Version: versionData[0]?.versionNumber || "",
                    Company: detail.Company, // Added Company field
                    VersionList: versionList,
                  },
                };

                job?.FileList?.push(copxmlData);
              }
            }
          }
        }
      }
    }
    updatedJobList.push(item);
  }

  return updatedJobList;
};

export const isEncoded = (str) => {
  // Check if the string matches URL encoding pattern (%XX)
  const isUrlEncoded = /%[0-9A-Fa-f]{2}/gi.test(str);

  // Check if the string matches Base64 encoding pattern (letters, numbers, +, /, and ending with = padding)
  const isBase64Encoded = /^[A-Za-z0-9+/=]+$/.test(str) && str?.length % 4 === 0;

  // Check if the string contains HTML entities (e.g., &amp;, &lt;)
  const hasHtmlEntities = /&[a-zA-Z0-9#]+;/g.test(str);

  // Try decoding the string with decodeURIComponent (URL-decoding)
  const tryUrlDecode = () => {
      try {
          const decoded = decodeURIComponent(str);
          return decoded !== str;  // If the decoded string is different from the original, it's URL encoded
      } catch (e) {
          return false;
      }
  };

  // Try decoding the string with atob (Base64 decoding)
  const tryBase64Decode = () => {
      try {
          const decoded = atob(str);
          return decoded !== str;  // If the decoded string is different from the original, it's Base64 encoded
      } catch (e) {
          return false;
      }
  };

  // If any of the checks return true, the string is encoded
  return isUrlEncoded || isBase64Encoded || hasHtmlEntities || tryUrlDecode() || tryBase64Decode();
}
