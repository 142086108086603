import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { AutoComplete } from "primereact/autocomplete";
import { InputTextarea } from "primereact/inputtextarea";
import { DelegateAction } from "../../store/actions/DelegateAction";
import { HelpNeededAction } from "../../store/actions/HelpNeededAction";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { getTasks, getAllTasks } from "../../store/actions/TaskActions";
import { saveProofscopeURL } from "../../apis/saveProofscopeURL";
import { CharacterLimit } from "../common/CharacterLimit/CharacterLimit";
import { textAreaMaxLength } from "../../constants/index";

const TaskDialog = (props) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(props.showTaskDialog);
  const [comment, setComment] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredItems, setFilteredItems] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(false);
  const DropDownValuesData = useSelector(
    (state) => state.DropDownValuesReducer
  );
  // useEffect(() => {
  const [prevSelectedItem, setPrevSelectedItem] = useState(null);

  useEffect(() => {
    setPrevSelectedItem(selectedItem);
  }, [selectedItem]);

  const options =
    DropDownValuesData?.DropDownValuesData?.ArtworkAgilityTasksPage
      ?.AWM_HelpNeeded;

  let userOptions;
  if (props.flag !== "help") {
    userOptions = props?.ITAccessEntitlementOwnerData?.map((user) => ({
      label: user?.Owner_Name,
      value: user?.Owner_Name,
    }));
  } else {
    userOptions = options?.map((item) => ({
      label: item.HelpNeededName,
      value: item.code,
    }));
  }

  const searchItems = (event) => {
    // Make a request to a remote URL with the query and return filtered results
    // For demo purposes, filter at the client side
    let query = event?.query;
    let _filteredItems = userOptions?.filter((item) =>
      item.label.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredItems(_filteredItems);
  };

  const requiredUser = {
    AccessGroupLists: [
      {
        AccessGroupName: "AAS:TaskOwner",
      },
      {
        AccessGroupName: "AAS:ExternalTaskOwner",
      },
    ],
  };

  useEffect(() => {
    setVisible(props.showTaskDialog);
  }, [props.showTaskDialog]);

  const handleSubmit = async () => {
    props?.setLoader(true);
    try {
      if (props.flag !== "help") {
        if (!selectedItem && filteredItems?.length === 0) {
          return;
        }
      } else {
        if (!selectedItem && filteredItems?.length === 0 && comment === "") {
          return;
        }
      }
      const helpNeededData = {
        ArtworkAgilityTasks: props?.selectedTaskData
          ?.map((task) => ({
            AWM_Task_ID: task.AWM_Task_ID,
            Help_Needed: "Yes, In Process",
            Comments: comment,
            Help_Needed_Reason: selectedItem?.value,
            AWM_Project_ID:task.AWM_Project_ID
          }))
          .filter((task) => task.AWM_Task_ID),
      };

      const delegateData = {
        Name: selectedItem?.value,
        code: selectedItem?.label,
        ArtworkAgilityTasks: props?.selectedTaskData
          ?.map((task) => ({
            AWM_Task_ID: task.AWM_Task_ID,
          }))
          .filter((task) => task.AWM_Task_ID),
      };
      if (props.flag === "help") {
        await dispatch(HelpNeededAction(helpNeededData));
        props.onClose();
        props.setSelected([]);
        if (props.path.toLowerCase() === "mytasks") {
          await dispatch(getTasks(props.payLoad));
        } else {
          await dispatch(getAllTasks(props.payLoad));
        }
      } else {
        props.onClose();
        props.setSelected([]);
        const assigneeName = selectedItem?.value;
        const tasksToProcess = props?.selectedTaskData?.filter(
          (task) =>
            (task.AWM_Task_ID.startsWith("ARDT") ||
              task.AWM_Task_ID.startsWith("ACIC")) &&
            task.Assignee !== assigneeName // Check if task assignee is different from selected assignee
        );
        if (tasksToProcess) {
          for (const task of tasksToProcess) {
            const requestData = {
              AWMProjectID: `${task?.AWM_Project_ID}`,
              AWMTaskID: `${task?.AWM_Task_ID}`, // Update task ID for each iteration
              PFFileURL: "",
              ApprovalID: "",
              ViewPFFileURL: "",
              Assignee: assigneeName,
            };
            saveProofscopeURL(requestData);
          }
        }

        await dispatch(DelegateAction(delegateData));
        props.onClose();
        // const response = await dispatch(DelegateAction(delegateData));
        // if (response.status === 200) {
        if (props.path.toLowerCase() === "mytasks") {
          await dispatch(getTasks(props.payLoad));
        } else {
          await dispatch(getAllTasks(props.payLoad));
        }
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const hideDialog = () => {
    setVisible(false);
    props.onClose();
  };

  return (
    <Dialog
      visible={visible}
      maximizable
      style={{ width: "50vw" }}
      onHide={hideDialog}
      header={`${props.flag === "help" ? "Help Needed" : "Delegate Task"}`}
    >
      <div className="p-fluid popup-details">
        <div className="row-fields">
          <div className="p-field">
            <label htmlFor="taskName" className="tasksname">
              Task:
              <ul>
                {props?.selectedTaskData?.map((task) => (
                  <li className="active-taskname" key={task.AWM_Task_ID}>
                    {task.Task_Name}
                    <br />
                  </li>
                ))}
              </ul>
            </label>
          </div>
          {props.flag === "help" && (
            <div className="p-field">
              <AutoComplete
                value={selectedItem}
                suggestions={filteredItems}
                completeMethod={searchItems}
                field="label"
                dropdown
                placeholder="Search reason"
                className={props.flag === "help" && "help-user"}
                onChange={(e) => {
                  setSelectedItem(e.value);
                  // setIsFormValid(!!e.value);
                }}
              />
              {filteredItems?.length === 0 && <div>No results found</div>}
            </div>
          )}
        </div>
        {props.flag === "help" && (
       <div className="p-field">
       <label htmlFor="comment">Add Comment *</label>
       <InputTextarea
         id="comment"
         maxLength = {textAreaMaxLength}
         value={comment}
         onChange={(e) => {
           setComment(e.target.value);
           if (e.target.value.trim().length > 0) {
             setIsFormValid(true);
           } else {
             setIsFormValid(false);
           }
         }}
         rows={3}
         className={comment?.length === 0 ? 'p-inputtext p-component' : comment?.length >= textAreaMaxLength ? 'p-inputtext p-component':'p-inputtext p-component'}
       />
       <div style={{paddingBottom:'10px',paddingRight:'10px'}}>            
       <CharacterLimit field={comment} maxLength={textAreaMaxLength} />
       </div>
       
         
     </div>
        )}

        {props.flag !== "help" && (
          <div className="p-field">
            <label htmlFor="priority">Delegate To *</label>

            <AutoComplete
              value={selectedItem}
              suggestions={filteredItems}
              completeMethod={searchItems}
              field="value"
              dropdown
              placeholder="Search user"
              onChange={(e) => {
                setSelectedItem(e.value);
                setIsFormValid(!!e.value);
              }}
              onSelect={(e) => {
                setSelectedItem(e.value);
                setIsFormValid(true);
                setIsUserSelected(true);
              }}
            />
            {filteredItems?.length === 0 && <div>No results found</div>}
          </div>
        )}
      </div>
      <div
        className="p-dialog-footer"
        id={props.flag === "help" ? "help-submit" : "delegate-submit"}
      >
        {props.flag === "help" && (
          <p className="comments-validation">
            * The message will be sent to PM once submit{" "}
          </p>
        )}
        <Button
          label="Submit"
          className="button-layout btn btn-primary"
          onClick={handleSubmit}
          disabled={
            !isFormValid ||
            (props.flag !== "help" &&
              (!isUserSelected || filteredItems?.length === 0))
          }
        />
      </div>
    </Dialog>
  );
};

export default TaskDialog;
