import './index.scss';
import _ from 'lodash';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { extractData } from './util';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { TreeTable } from 'primereact/treetable';
import ConfirmationPopUp from '../ConfirmationPopUp';
import LoadingOverlay from 'react-loading-overlay-ts';
import hyphen from '../../../assets/images/hyphen.svg';
import filter from '../../../assets/images/filter.svg';
import PDFViewer from '../../common/PDFViewer/PDFviewer';
import { ProjectService } from '../../../service/PegaService';
import { getFilterOptions } from '../../../Utils/GridService';
import { changeDateFormat, onSortData } from '../../../utils';
import downloadIcon from '../../../assets/images/downloadIcon.svg';
import downloadPdfIcon from '../../../assets/images/downloadPdfIcon.svg';
import Versionhistory from '../../common/FileUploadManager/VersionHistory';
import { downloadAzureContainer } from '../../../apis/downloadAzureContainer';
import React, { useRef, useState, Suspense, useEffect, useCallback } from 'react';

const JobList = ({
  view,
  loader,
  filters,
  pegadata,
  isSearch,
  childFunc,
  setFilters,
  setColWidth,
  JobListData,
  setPegaData,
  expandedKeys,
  fieldUpdated,
  frozenUpdated,
  ProjectDetalis,
  selectedFields,
  setExpandedKeys,
  setFieldUpdated,
  projectDataData,
  setFrozenUpdated,
  setSelectedFields,
  projectColumnName,
  clearColumnWiseFilter,
  setProjectColumnNames,
  setInitProjectPlanList,
  columnWiseSelectedFields,
  setColumnWiseSelectedFields,
  handleExapandCollapseAllFilter,
  setUpdatedProjectPlanDesignData,
}) => {
  let today = new Date();
  const op = useRef(null);
  let minDate = new Date();
  minDate.setDate(today.getDate());
  const [path, setPath] = useState('');
  const [RowData, setRowData] = useState({});
  const [sortData, setSortData] = useState([]);
  const [FileList, setFileList] = useState([]);
  const [ViewPDF, setViewPDF] = useState(false);
  const [headerName, setHeaderName] = useState('');
  const [frozenCoulmns, setFrozenColumn] = useState([]);
  const [frozenWidth, setFrozenWidth] = useState('0px');
  const [ViewModalPDF, setViewModalPDF] = useState(false);
  const [ProjectFrozen, setProjectFrozen] = useState(false);
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const [showApproveDialogCPPFA, setShowApproveDialogCPPFA] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const ProjectData = _.cloneDeep(JobListData);
    let allCol = [];
    if (ProjectData?.length) {
      allCol = Object.keys(ProjectData[0]);
    }
    let columnWidthProjectPlan = {};
    if (allCol?.length) {
      allCol.forEach((column) => {
        columnWidthProjectPlan[column] = 100;
      });
    }

    let getJsonStoredWidthColumns = localStorage.getItem('columnWidthProjectPlan');
    let getStoredWidthColumns = JSON.parse(getJsonStoredWidthColumns);
    const checkEmptyObject = _.isEmpty(getStoredWidthColumns);

    if (checkEmptyObject) {
      localStorage.setItem('columnWidthProjectPlan', JSON.stringify(columnWidthProjectPlan));
    }

    let jsonColWidth = localStorage.getItem('isColWidthSetMyProject');
    let isColWidthSetFlag = JSON.parse(jsonColWidth);
    if (isColWidthSetFlag) {
      setColWidth(true);
    }
  }, []);

  useEffect(() => {
    childFunc.current = clearColumnWiseFilter;
  }, []);

  useEffect(() => {
    pegadata && updateProjectPlanDesign();
  }, [pegadata]);

  useEffect(() => {
    setFrozenUpdated(frozenUpdated);
    frozenColumn();
  }, [frozenUpdated]);

  const saveSettings = () => {
    localStorage.setItem('sortingData', JSON.stringify(sortData));
    localStorage.setItem('columnWiseFilterData', JSON.stringify(filters));
    localStorage.setItem('frozenDataProjectPlan', JSON.stringify(frozenCoulmns));
    localStorage.setItem('filesAllColumnNames', JSON.stringify(projectColumnName));
  };

  async function fetchData() {
    try {
      let filteredPegaDataJson = localStorage.getItem('columnWiseFilterData');
      const filteredPegaData = JSON.parse(filteredPegaDataJson);
      if (filteredPegaData && filteredPegaData?.length) {
        setFilters(filteredPegaData);
        setPegaData(JobListData);
      } else {
        setPegaData(JobListData);
      }
      setInitProjectPlanList(JobListData);
      let columnNamesJson = localStorage.getItem('filesAllColumnNames');
      const storageColumnNames = JSON.parse(columnNamesJson);
      const columnNames = ProjectService.getFilesAllColumnNames();
      if (storageColumnNames != null && storageColumnNames?.length) {
        if (storageColumnNames.length === columnNames?.length) {
          let sortedData = [];
          sortedData = [...storageColumnNames].sort((a, b) => {
            return parseInt(a.sequence) - parseInt(b.sequence);
          });
          setProjectColumnNames(sortedData);
        } else {
          let sortedData = [];
          sortedData = [...columnNames].sort((a, b) => {
            return parseInt(a.sequence) - parseInt(b.sequence);
          });
          setProjectColumnNames(sortedData);
          localStorage.setItem('filesAllColumnNames', JSON.stringify(columnNames));
        }
      } else {
        const columnNames = ProjectService.getProjectPlanAllColumnNames();
        localStorage.setItem('filesAllColumnNames', JSON.stringify(columnNames));
        setProjectColumnNames(columnNames);
      }

      let jsonSortingData1 = localStorage.getItem('sortingData');
      const sortingData = JSON.parse(jsonSortingData1);

      if (sortingData && sortingData?.length) {
        const sortedData = [...JobListData].sort((a, b) => {
          return a[sortingData[0]] > b[sortingData[0]] ? 1 : -1;
        });

        if (sortingData[1] === 'desc') {
          sortedData.reverse();
        }
        setPegaData(sortedData);
        setSortData([sortingData[0], sortingData[1]]);
      }

      let jsonFrozenrData1 = localStorage.getItem('frozenDataProjectPlan');
      const frozenDataProjectPlan = JSON.parse(jsonFrozenrData1);
      if (frozenDataProjectPlan && frozenDataProjectPlan?.length) {
        setFrozenColumn(frozenDataProjectPlan);
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  const frozenColumn = () => {
    let jsonColumnWidth = localStorage.getItem('filesAllColumnNames');
    let allColumns = JSON.parse(jsonColumnWidth);
    const numFrozenColumns = allColumns.filter((col) => col?.freeze)?.length;
    const totalFrozenWidth = numFrozenColumns * 200;
    setFrozenWidth(`${totalFrozenWidth}px`);
  };

  const addFrozenColumns = (name) => {
    if (!frozenCoulmns?.includes(name)) {
      frozenCoulmns.push(name);
      setFrozenColumn(frozenCoulmns);
    } else {
      let columnIndex = frozenCoulmns.indexOf(name);
      frozenCoulmns.splice(columnIndex, 1);
      setFrozenColumn(frozenCoulmns);
    }
  };

  const storeReorderedColumns = (e) => {
    const dragColumnName = projectColumnName[e?.dragIndex];
    const index = projectColumnName.indexOf(dragColumnName);
    if (index > -1) {
      projectColumnName.splice(index, 1);
      projectColumnName.splice(e?.dropIndex, 0, dragColumnName);
    }
    projectColumnName.forEach((ele, index) => {
      ele.reorder = ele.field_Name === dragColumnName.field_Name;
      ele.sequence = index + 1;
    });

    localStorage.setItem('filesAllColumnNames', JSON.stringify(projectColumnName));
    setProjectColumnNames(projectColumnName);
  };

  const onColumnResizeEnd = (event) => {
    let columnWidth = [];
    let jsonColumnWidth = localStorage.getItem('filesAllColumnNames');

    if (jsonColumnWidth) {
      columnWidth = JSON.parse(jsonColumnWidth);
    }
    if (columnWidth) {
      columnWidth?.map((list) => {
        if (event.column.props.field === list.field_Name) {
          list.width = event.element.offsetWidth;
        }
      });
    }
    localStorage.setItem('filesAllColumnNames', JSON.stringify(columnWidth));
    setFieldUpdated(!fieldUpdated);
  };

  const updateProjectPlanDesign = () => {
    let arr = [];
    pegadata.forEach((obj) => {
      obj.children?.forEach((data) => {
        if (data?.children?.length === 0) {
          let tempObj = {};
          tempObj['AWM_Project_ID'] = projectDataData?.AWM_Project_ID;
          tempObj['AWM_Version'] = data.Version;
          tempObj['Date'] = data.data.Date;
          arr.push(tempObj);
        } else if (data?.children?.length > 0) {
          data.children.forEach((child) => {
            let tempObj = {};
            tempObj['AWM_Project_ID'] = projectDataData?.AWM_Project_ID;
            tempObj['AWM_Version'] = child.Version;
            tempObj['Date'] = child.data.Date;
            arr.push(tempObj);
            if (child?.children?.length > 0) {
              child.children.forEach((innerChild) => {
                let tempChildObj = {};
                tempChildObj['AWM_Project_ID'] = projectDataData?.AWM_Project_ID;
                tempChildObj['AWM_Version'] = innerChild.Version;
                tempChildObj['Date'] = innerChild.data.Date;
                arr.push(tempChildObj);
              });
            }
          });
        }
      });
    });
    setUpdatedProjectPlanDesignData(arr);
  };

  const onSort = (column, direction) => {
    const sortedData = onSortData(column, direction, pegadata);
    setSortData([column, direction]);
    setPegaData(sortedData);
  };

  const onGlobalFilterChange = useCallback(
    (e, filterColumn) => {
      const value = e.value;
      let temp = cloneDeep(selectedFields);
      temp[filterColumn] = value;
      setSelectedFields(temp);

      let allValues = [];
      let keys = Object.keys(temp);
      keys?.forEach((key) => {
        allValues = [...allValues, ...temp[key]];
      });

      const artworkValues = allValues;
      if (artworkValues?.length) {
        const filterRecursive = (data) => {
          if (!data.children || data?.children?.length === 0) {
            let condition = null;
            keys?.forEach((itemVal) => {
              if (temp[itemVal]?.length > 0) {
                if (itemVal !== 'Date') {
                  if (condition === null) {
                    condition = temp[itemVal]?.includes(data.data?.[itemVal]);
                  } else {
                    condition = condition && temp[itemVal]?.includes(data.data?.[itemVal]);
                  }
                } else {
                  const formattedDate = moment(
                    data.data[itemVal],
                    'YYYYMMDDTHHmmss.SSS [GMT]'
                  ).format('DD-MMM-YY');
                  if (condition === null) {
                    condition = temp[itemVal]?.includes(formattedDate);
                  } else {
                    condition = condition && temp[itemVal]?.includes(formattedDate);
                  }
                }
              }
            });

            if (condition) {
              return data;
            } else {
              return null;
            }
          }

          const filteredChildren = data?.children
            ?.map((child) => filterRecursive(child))
            .filter(Boolean);

          if (filteredChildren?.length > 0) {
            return {
              ...data,
              children: filteredChildren,
            };
          }

          return null;
        };

        const filteredData = pegadata?.map((group) => filterRecursive(group)).filter(Boolean);
        handleExapandCollapseAllFilter();
        setFilters(filteredData);
      } else {
        setFilters([]);
      }
    },
    [selectedFields, pegadata, handleExapandCollapseAllFilter]
  );

  const viewPdf = (options, ViewPFURL) => {
    const filteredChildren = JobListData.filter((el) => el.key === options.parentKey);
    const jobName = filteredChildren?.[0]?.children.filter((el) => el.key === options.keyCode)?.[0]
      ?.data?.Filename;
    setHeaderName(jobName);
    setPath(ViewPFURL);
    setViewPDF(true);
  };

  const projectNameOnClick = (e, options) => {
    op.current.toggle(e);
    setSelectedColumnName(options);
  };

  const projectNameHeader = (options, col) => {
    let isFilterActivated =
      col?.freeze === true || col?.sortAtoZ === true || col?.sortZtoA === true;

    const optionsCode = getFilterOptions(options);

    return (
      <span key={options}>
        <img
          src={filter}
          alt="Column Filter"
          key={options}
          onClick={(e) => projectNameOnClick(e, options)}
          className={
            isFilterActivated ? 'columnFilterIcon filter-color-change' : 'columnFilterIcon'
          }
        />
        <span className="columnHeader">{optionsCode}</span>
      </span>
    );
  };

  const flatPegadata = pegadata?.flatMap((obj) => extractData(obj));

  const downloadPdf = (event, options, fileUrl, folderStructure) => {
    event.preventDefault();
    downloadAzureContainer(fileUrl, folderStructure);
  };

  const handleClickVersion = (options) => {
    const filteredChildren = JobListData.filter((el) => el.key === options.parentKey);
    if (
      (filteredChildren?.[0]?.data?.Filename?.includes('RDT') &&
        !options?.data?.Filename?.includes('xfdf')) ||
      (filteredChildren?.[0]?.data?.Filename?.includes('CIC') &&
        !options?.data?.Filename?.includes('xfdf'))
    ) {
      setViewModalPDF(true);
    } else {
      setViewModalPDF(false);
    }
    const jobName = filteredChildren?.[0]?.children.filter((el) => el.key === options.keyCode)?.[0]
      ?.data?.Filename;
    const finalFileList = options?.data?.VersionList?.map((el) => {
      return {
        ...el,
        Date: changeDateFormat(el?.Date),
        Owner: (
          <div>
            {el?.Owner} <br /> {el?.Email}
          </div>
        ),
      };
    });
    setRowData(options);
    setHeaderName(jobName);
    setFileList(finalFileList);
    setShowApproveDialogCPPFA(true);
  };

  const dynamicColumns = () => {
    let jsonColumnWidth = localStorage.getItem('filesAllColumnNames');
    let allColumns = JSON.parse(jsonColumnWidth);

    if (allColumns && allColumns?.length) {
      return allColumns?.map((ele, i) => {
        return (
          <Column
            filter={isSearch}
            alignFrozen="left"
            frozen={ele.freeze}
            key={ele.field_Name}
            showFilterMenu={false}
            body={elementTemplate}
            field={ele.field_Name}
            filterMatchMode="contains"
            filterField={ele.field_Name}
            columnKey={ele.field_Name || i}
            filterPlaceholder={ele.field_Name}
            expander={ele.field_Name === 'Filename'}
            header={projectNameHeader(ele.field_Name, ele)}
            className={ele.freeze ? 'fontBoldcolor jobListTableHeader' : 'cursorMove jobListTableHeader'}
            style={{
              height: 30,
              width: ele.freeze ? '400px' : ele.width,
              textOverflow: ele.field_Name != 'Filename' ? 'ellipsis' : '',
            }}
          />
        );
      });
    }
  };

  const elementTemplate = (options, rowData) => {
    const field = rowData.field;
    const optionsData = options.data;
    const Version = options.data?.Version;
    const filename = options?.data?.Filename;
    const filteredChildren = JobListData.filter((el) => el.key === options.parentKey);
    const JobName = filteredChildren?.[0]?.children.filter((el) => el.key === options.keyCode)?.[0]
      ?.data?.Filename;
    const Subfolder = filteredChildren?.[0]?.data?.Filename;

    return (
      <>
        {field === 'Filename' && (
          <span
            className={`${
              optionsData.State === 'Awaiting'
                ? ''
                : options.children?.length === 0
                ? 'task-links'
                : 'task'
            } ${field === 'Filename' && 'custom-toggler-wrapper'}`}
            title={optionsData[field]}
          >
            {optionsData[field]}
          </span>
        )}
        {options.children && options?.children?.length > 0 ? (
          <>
            {(field == 'Filenames' ||
              field == 'Actions' ||
              field == 'Versions' ||
              field == 'Owners' ||
              field == 'Dates') && (
              <img
                src={hyphen}
                alt="Hyphen"
                onClick={(e) => {
                  op.current.toggle(e);
                }}
              />
            )}
          </>
        ) : (
          <>
            {field === 'Action' && (
              <div className="action-wrapper">
                {options?.key && options?.keyCode && options?.parentKey ? (
                  <>
                    <img
                      className="download_icon"
                      src={downloadIcon}
                      alt="downloadicon"
                      onClick={(e) =>
                        downloadPdf(
                          e,
                          options,
                          filename,
                          `${ProjectDetalis?.BU}/${ProjectDetalis?.Region}/${ProjectDetalis?.ProjectID} ${ProjectDetalis?.ProjectName}/${Subfolder}/${JobName}/${Version}/`
                        )
                      }
                    />
                    {(Subfolder?.includes('RDT') && !optionsData['Filename']?.includes('xfdf')) ||
                    (Subfolder?.includes('CIC') && !optionsData['Filename']?.includes('xfdf')) ? (
                      <img
                        alt="viewpdficon"
                        src={downloadPdfIcon}
                        className="view_pdf_icon"
                        onClick={() => viewPdf(options, options?.data?.ViewPFURL)}
                      />
                    ) : (
                      <div style={{ minWidth: '16px' }}> </div>
                    )}
                  </>
                ) : null}
              </div>
            )}
            {field === 'Version' && (
              <a
                title={optionsData['Version']}
                className={'version'}
                onClick={() => {
                  if (true) {
                    handleClickVersion(options);
                  }
                }}
              >
                {optionsData['Version']}
              </a>
            )}
            {field === 'Owner' && (
              <div className="owner">
                {optionsData['Owner']} <br /> {optionsData['Email']}
              </div>
            )}
            {field === 'Date' && (
              <div className="date">{changeDateFormat(optionsData['Date'])}</div>
            )}
          </>
        )}

        {field !== 'Filename' &&
          field !== 'Action' &&
          field !== 'Version' &&
          field !== 'Owner' &&
          field !== 'Date' && <>{optionsData[field]}</>}
      </>
    );
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }

    const expanded = options.expanded;
    const iconClassName = `p-treetable-toggler-icon pi pi-fw ${expanded ? 'pi-minus' : 'pi-plus'}`;

    return (
      <button
        type="button"
        tabIndex={-1}
        title
        onClick={options.onClick}
        style={{
          ...options.buttonStyle,
          border: '2px solid #ababab',
          borderRadius: '0%',
          padding: '7px',
          height: '0',
          width: '0',
        }}
        className="p-treetable-toggler p-link"
      >
        <span className={iconClassName} aria-hidden="true"></span>
      </button>
    );
  };

  return (
    <div className="myProjectAnddAllProjectList">
      <Dialog
        maximizable
        visible={ViewPDF}
        header={headerName}
        style={{ width: '80vw' }}
        onHide={() => setViewPDF(false)}
        className="version-history-dialog"
        headerClassName="version-history-dialog-header"
      >
        <div>
          <PDFViewer path={path} width={'100%'} height={'600px'} />
        </div>
      </Dialog>
      {showApproveDialogCPPFA && (
        <Versionhistory
          RowData={RowData}
          fileList={FileList}
          headerName={headerName}
          JobListData={JobListData}
          ViewModalPDF={ViewModalPDF}
          ProjectDetalis={ProjectDetalis}
          showVersionList={showApproveDialogCPPFA}
          onHide={() => setShowApproveDialogCPPFA(!showApproveDialogCPPFA)}
        />
      )}
      <Suspense fallback={<LoadingOverlay active={true} spinner text="" />}>
        {view === 'Tabular' && (
          <>
            <ConfirmationPopUp
              op={op}
              onSort={onSort}
              filters={filters}
              sortData={sortData}
              isFilesAndFolder={true}
              setFilters={setFilters}
              setSortData={setSortData}
              projectData={flatPegadata}
              saveSettings={saveSettings}
              fieldUpdated={fieldUpdated}
              frozenCoulmns={frozenCoulmns}
              frozenUpdated={frozenUpdated}
              ProjectFrozen={ProjectFrozen}
              selectedFields={selectedFields}
              setFieldUpdated={setFieldUpdated}
              setFrozenColumn={setFrozenColumn}
              setProjectFrozen={setProjectFrozen}
              addFrozenColumns={addFrozenColumns}
              setFrozenUpdated={setFrozenUpdated}
              selectedColumnName={selectedColumnName}
              onGlobalFilterChange={onGlobalFilterChange}
              clearColumnWiseFilter={clearColumnWiseFilter}
              columnWiseSelectedFields={columnWiseSelectedFields}
              setColumnWiseSelectedFields={setColumnWiseSelectedFields}
            />
            <TreeTable
              expanded
              //scrollable
              loading={loader}
              resizableColumns
              dataKey="Filename"
              scrollWidth="100%"
              reorderableColumns
              tableStyle={{
                minWidth: '90%',
                minHeight: '30px',
                width: 'maxContent',
              }}
              columnResizeMode="expand"
              frozenWidth={frozenWidth}
              expandedKeys={expandedKeys}
              togglerTemplate={togglerTemplate}
              onColReorder={storeReorderedColumns}
              onColumnResizeEnd={onColumnResizeEnd}
              onToggle={(e) => setExpandedKeys(e.value)}
              value={filters?.length ? filters : pegadata}
              className={`textAlignTreeTable ${isSearch ? 'searchWithTreeTable' : ''}`}
              >
              {dynamicColumns()}
            </TreeTable>
          </>
        )}
      </Suspense>
    </div>
  );
};

export default JobList;
