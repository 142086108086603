import React, { useEffect, useState, useRef } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import moment from "moment";
import PageLayout from "../../PageLayout";
import DesignHeader from "../DesignJobs/DesignHeader";
import FooterButtons from "../DesignJobs/FooterButtons";
import AddNewDesign from "../DesignJobs/TaskHeader";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import {
  postSaveAsDraft,
  submitUploadCICMatrix,
} from "../../../apis/uploadCICMatrixApi";
import { uploadtoAzureStrContainer } from "../../../apis/uploadAzuerContainer";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  viewThumbnail,
  redirectToProjectPlanOrTaskPage,
  sanitizeFileName,
  getEnvironmentFromURL,
} from "../../../utils";
import UploadDesignIntentProofscope from "../DesignJobs/UploadDesignIntentProofscope";
import { uploadtoAzurefileShare } from "../../../apis/uploadAzureFileShareApi";
import { Message } from "primereact/message";
import { useProofScopeURL } from "../../ProofScope/ViewFiles";
import { isEmpty } from "lodash";
import useAzureUploadFolderStructure from "../../../hooks/useAzureUploadFolderStructure";
import { totalFileSizeQuotient } from "../../../constants/FileUploadConstant";
import { AzureContainerFail, AzureContainerSuccess, AzureFileShareFail, AzureFileShareSuccess, ContainerAndFileShareFailMsg, ContainerFailMsg, FileShareFailMsg } from "../../../Utils/constants";

const breadcrumb = [
  { label: "My Tasks", url: "/tasks" },
  { label: "Upload CIC Matrix" },
];

const UCICMATRIX = () => {
  const toast = useRef(null);
  const [data, setData] = useState(null);
  const [date, setDate] = useState("");
  const [version, setVersion] = useState("V0");
  const [designIntent, setDesignIntent] = useState(null);
  const [formattedValue, setformattedValue] = useState(0);
  const [fileName, setFileName] = useState("");
  const [viewFileName, setViewFileName] = useState("");
  const [azureFile, setAzureFile] = useState("");
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState(null);
  const [azureFileUploadMsg, setAzureFileUploadMsg] = useState(false);
  const [thumbnailErrorMsg, setThumbnailErrorMsg] = useState("");
  const [fileDetails, setFileDetails] = useState(null);
  let { TaskID, page1, page2, pageType, ProjectID } = useParams();
  const { TaskDetailsData, loading } = useSelector(
    (state) => state.TaskDetailsReducer
  );
  const headerName = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.TaskPlaceholderName}`;
  const shortName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Type;
  const env = getEnvironmentFromURL();
  const BU = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU;
  const projectName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;

  const sanitizedBuName = sanitizeFileName(BU);
  const sanitizedTaskFolder = sanitizeFileName(`CICMATRIX`);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Key}`;
  const roleName = "DI_";
  const { viewProofScopeFile } = useProofScopeURL();
  const [readonlyUrl, setReadonlyUrl] = useState("");

  // const checkReadWriteAccess = CheckReadOnlyAccess();
  const checkReadWriteAccess = true;
  const folderStructure = useAzureUploadFolderStructure('CICMATRIX', version);

  useEffect(() => {
    dispatch(getTaskDetails(TaskID, ProjectID));
    setAzureFileUploadMsg(false);
    setThumbnailErrorMsg("");
  }, [dispatch, TaskID, ProjectID]);

  useEffect(() => {
    if (TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID === TaskID) {
      setDesignIntent(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || []);
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);
      const data =
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]
          ?.FileMetaDataList?.[0] || [];
      if (data) {
        data.Version !== "" && setVersion(data.Version);
        data.Timestamp !== "" &&
          setDate(
            moment(data.Timestamp, "YYYYMMDD[T]HHmmss.SSS [GMT]").format(
              "DD-MMMM-YYYY"
            )
          );
      }
    }
  }, [TaskDetailsData]);

  useEffect(() => {
    if (!isEmpty(readonlyUrl) && !isEmpty(formData)) {
      (async () => {
        const payload = { ...formData }
        if (!isEmpty(payload)) {
          payload.content['ViewPFURL'] = readonlyUrl
        }
        const headers = {
          key: "If-Match",
          value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
        };
        await submitUploadCICMatrix(payload, id, headers);
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "File Uploaded",
            detail: "Successfully!",
            life: 3000,
          });
        }
        await dispatch(getTaskDetails(TaskID, ProjectID));
        redirectToProjectPlanOrTaskPage(
          page1,
          page2,
          pageType,
          ProjectID,
          navigate
        );
        setLoader(false);
        setAzureFileUploadMsg(false);
        setThumbnailErrorMsg("");
      })()
    }
  }, [readonlyUrl, formData])

  const handleCancel = () => {
    redirectToProjectPlanOrTaskPage(
      page1,
      page2,
      pageType,
      ProjectID,
      navigate
    );
  };
  const filesexist =
    TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]
      ?.FileMetaDataList?.[0]?.File_Name;
  // Define a function to generate the formData object
  const generateSaveAsDraftFormData = (filePath, fileValue) => {
    const fileSize = Math.round(formattedValue / totalFileSizeQuotient);
    return {
      AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
      AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      Size: fileSize === 0 ? "1" : fileSize,
      Version: version,
      Filename: azureFile?.File_Name ? azureFile?.File_Name : fileValue,
      Filepath:
        filePath === ""
          ? TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]
            ?.FileMetaDataList?.[0]?.File_Path
          : filePath,
      Full_Name: fileDetails?.Full_Name,
      Email: fileDetails?.Email,
    };
  };
  const generateSubmitFormData = (filePath, fileValue) => {
    const fileSize = Math.round(formattedValue / totalFileSizeQuotient);
    return {
      caseTypeID: "PG-AAS-Work-UploadCICMatrix",
      content: {
        AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
        Filename: azureFile?.File_Name ? azureFile?.File_Name : fileValue,
        Version: version,
        Size: fileSize === 0 ? "1" : fileSize,
        Filepath:
          filePath === ""
            ? TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]
              ?.FileMetaDataList?.[0]?.File_Path
            : filePath,
        Full_Name: fileDetails?.Full_Name,
        Email: fileDetails?.Email,
        JobID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
        JobName: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_Name,
        TypeOfFile: azureFile?.File_Name ? azureFile?.File_Name?.split('.').pop() : fileValue?.split('.').pop()
      },
    };
  };
  const onSaveAsDraft = async () => {
    setLoader(true);

    try {
      if (!(filesexist === azureFile) && azureFile !== '') {
        await uploadtoAzureStrContainer(azureFile, folderStructure);
        let res = await uploadtoAzurefileShare(
          azureFile?.file ? azureFile?.file : azureFile,
          BU,
          `CICMATRIX`,
          ProjectID,
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
          version
        );
        // alert(res);
        if (res) {
          const fileurl =
            fileName !== "" && fileName?.endsWith(".zip")
              ? sanitizeFileName(res)
              : fileName !== "" && !fileName?.endsWith(".zip")
                ? sanitizeFileName(fileName)
                : sanitizeFileName(filesexist);
          const thumnailResponse = await viewThumbnail(
            env,
            sanitizedBuName,
            sanitizedTaskFolder,
            fileurl,
            TaskID,
            ProjectID,
            TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
            version
          );
          if (thumnailResponse.thumbnail) {
            const formData = generateSaveAsDraftFormData(
              thumnailResponse.thumbnail,
              fileurl
            );
            if (fileName?.endsWith(".zip")) {
              setFileName(res);
              setAzureFile(res);
            }
            await postSaveAsDraft(formData);
            if (toast.current) {
              toast.current.show({
                severity: "success",
                summary: "File Uploaded",
                detail: "Successfully!",
                life: 3000,
              });
            }
            // }
            await dispatch(getTaskDetails(TaskID, ProjectID));
            setLoader(false);
            setAzureFileUploadMsg(false);
          }
        } else {
          setLoader(false);
          setAzureFileUploadMsg(true);
        }
      } else {
        if (
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status ===
          "In-Progress" &&
          filesexist !== ""
        ) {
          if (toast.current) {
            toast.current.show({
              severity: "success",
              summary: "File Uploaded",
              detail: "Successfully!",
              life: 3000,
            });
          }

          const formData = generateSaveAsDraftFormData("", filesexist);
          await postSaveAsDraft(formData);
          await dispatch(getTaskDetails(TaskID, ProjectID));
          setLoader(false);
          setAzureFileUploadMsg(false);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setLoader(false);
    }
  };

  const onSubmit = async () => {
    setLoader(true);

    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };
    try {
      if (!(filesexist === azureFile) && azureFile !== '') {
        Promise.all(
          [uploadtoAzureStrContainer(azureFile, folderStructure),
            uploadtoAzurefileShare(
              azureFile?.file ? azureFile?.file : azureFile,
              BU,
              `CICMATRIX`,
              ProjectID,
              TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
              version
            )]
        ).then(function (responses){
          const azureRes = responses[0]
          const res = responses[1]
          if(azureRes){
            if (toast.current) {
              toast.current.show(AzureContainerSuccess);
            }
          } else {
            if (toast.current) {
              toast.current.show(AzureContainerFail);
            }
          }
          if(res){
            if (toast.current) {
              toast.current.show(AzureFileShareSuccess);
            }
          } else {
            if (toast.current) {
              toast.current.show(AzureFileShareFail);
            }
          }
          return responses
        }).then(async(data)=>{
          const azureRes = data[0]
          const res = data[1]
          if (azureRes && res) {          
            const fileurl =
              fileName !== "" && fileName?.endsWith(".zip")
                ? sanitizeFileName(res)
                : fileName !== "" && !fileName?.endsWith(".zip")
                  ? sanitizeFileName(fileName)
                  : sanitizeFileName(filesexist);
            const thumnailResponse = await viewThumbnail(
              env,
              sanitizedBuName,
              sanitizedTaskFolder,
              fileurl,
              TaskID,
              ProjectID,
              TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
              version
            );
            if (fileName?.endsWith(".zip")) {
              setFileName(res);
              setAzureFile(res);
            }
            if (thumnailResponse.thumbnail) {
              const designJobId = sanitizeFileName(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID)
              const fileVersion = version
              const assignee = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Assignee
              const filePath = `cloudflow://awm/${env}/${sanitizedBuName}/${sanitizedTaskFolder}/${ProjectID}/${designJobId}/${fileVersion}/${fileurl}`;
  
              // await viewProofScopeFile(
              //   ProjectID,
              //   id,
              //   TaskID,
              //   filePath,
              //   assignee,
              //   "UCICMatrixTemplate",
              //   "UCICMatrixTemplate_Flow",
              //   1
              // );
              setReadonlyUrl(thumnailResponse.readonly_url);
              const formData = generateSubmitFormData(thumnailResponse.thumbnail, fileurl);
              setFormData(formData)
            } else {
              setLoader(false);
              setAzureFileUploadMsg(true);
              setThumbnailErrorMsg(thumnailResponse?.contents?.message);
            }
          } else {
            const msg = (!azureRes && !res) 
            ? ContainerAndFileShareFailMsg 
            : !azureRes 
            ? ContainerFailMsg
            : !res 
            ? FileShareFailMsg
            : "File upload failed!  Please try after some time."
            setLoader(false);
            setAzureFileUploadMsg(true);
            setThumbnailErrorMsg(msg);
          }
        })
        
      } else {
        if (
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status ===
          "In-Progress" &&
          filesexist !== ""
        ) {
          const formData = generateSubmitFormData("", filesexist);
          await submitUploadCICMatrix(formData, id, headers);
          if (toast.current) {
            toast.current.show({
              severity: "success",
              summary: "File Uploaded",
              detail: "Successfully!",
              life: 3000,
            });
          }
          await dispatch(getTaskDetails(TaskID, ProjectID));
          redirectToProjectPlanOrTaskPage(
            page1,
            page2,
            pageType,
            ProjectID,
            navigate
          );
          setLoader(false);
          setAzureFileUploadMsg(false);
          setThumbnailErrorMsg("");
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setLoader(false);
    }
  };
  return (
    <PageLayout>
      <DesignHeader
        breadcrumb={breadcrumb}
        headerName={headerName}
        disabled={true}
        label={shortName}
        checkReadWriteAccess={checkReadWriteAccess}
        actionButtonsFlag={true}
        {...data}
      />
      <div className="task-details">
        {
          <AddNewDesign
            {...data}
            checkReadWriteAccess={checkReadWriteAccess}
            TaskDetailsData={TaskDetailsData}
          />
        }
        <LoadingOverlay active={loading || loader || designIntent === null} spinner text="">
          {designIntent && (
            <UploadDesignIntentProofscope
              {...designIntent}
              designIntent={designIntent}
              upload={true}
              setformattedValue={setformattedValue}
              setAzureFile={setAzureFile}
              azureFile={azureFile}
              setFileName={setFileName}
              item={data}
              roleName={roleName}
              ArtworkAgilityPage={TaskDetailsData?.ArtworkAgilityPage}
              version={version}
              setVersion={setVersion}
              date={date}
              checkReadWriteAccess={checkReadWriteAccess}
              fileName={fileName}
              buName={BU}
              taskFolder={`CICMATRIX`}
              TaskID={TaskID}
              projectName={projectName}
              ProjectID={ProjectID}
              viewFileName={viewFileName}
              setAzureFileUploadMsg={setAzureFileUploadMsg}
              setViewFileName={setViewFileName}
              setFileDetails={setFileDetails}
              toShowReferenceDocuments={true}
              loading={loading}
              azureFileUploadMsg={azureFileUploadMsg}
              thumbnailErrorMsg={thumbnailErrorMsg}
            />
          )
          }
        </LoadingOverlay>
      </div>

      <FooterButtons
        handleCancel={handleCancel}
        onSaveAsDraft={onSaveAsDraft}
        onSubmit={onSubmit}
        hideSaveButton={true}
        checkReadWriteAccess={checkReadWriteAccess}
        bottomFixed={true}
        formValid={
          (fileName === "") ||
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Complete" || TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Cancelled"
        }
        saveAsDraftEnable={
          (fileName === "" && viewFileName === "") ||
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Complete" || TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Cancelled"
        }
        {...data}
      />
    </PageLayout>
  );
};
export default UCICMATRIX;
