import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import PageLayout from "../../PageLayout";
import TaskHeader from "../DesignJobs/TaskHeader";

import { saveInkQualification, submitInkQualification } from "../../../apis/inkQualificationApi";
import { useDispatch, useSelector } from "react-redux";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import "../DesignJobs/index.scss";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CloneJobs from "../DesignJobs/CloneJobs";
import "./index.scss";

import IQHeader from "../DesignJobs/IQHeader";
import { CheckReadOnlyAccess, createDesignJobName } from "../../../utils";
import IQCDFooterButtons from "../DesignJobs/IQCDFooterButtons";
import _, { cloneDeep } from "lodash";
import { usePriorityNew } from "../../../Utils/GetRolePriority";

import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus } from "../../../utils";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";
import { getReadWriteAccessForTask, getUserAccessForTask } from "../../../Utils/helpers";

const breadcrumb = [{ label: "Define Ink Qualification" }];

const headerName = "Define Ink Qualification";
const jobName = "IQ_";

function DNIQ() {
  const dispatch = useDispatch();
  const { TaskDetailsData, loading } = useSelector((state) => state.TaskDetailsReducer);
  const [data, setData] = useState(null);
  const [initialIQ, setInitialIQ] = useState([]);
  const [cancelClicked, setCancelClicked] = useState(false)
  const [IQ, setIQ] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [formValidForSaveAsDraft, setFormValidForSaveAsDraft] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [submittedDI, setSubmittedDI] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  let { page1, page2, pageType, TaskID, ProjectID } = useParams();
  const navigate = useNavigate();
  // const checkReadWriteAccess = CheckReadOnlyAccess();
  const {
    userDetails: { UserGroup },
  } = useSelector((state) => state?.UserDetailsReducer);
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User?.userInformation;
  const userAccessPayLoad = {
    ArtworkAgilityPage: {
      UserId: userInformation?.userid,
      ProjectID: ProjectID,
      UserGroup: UserGroup
    }
  }
  const [accessSecurityMatrix, setUserAccess] = useState(false);
  const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);
  const location = useLocation();
  const currentUrl = location?.pathname;
  const [FormObjects, setFormObjects] = useState({
    Printer:[],
    Pantone:"",
    Select:false,
    Substrate:"",
    FileDetails:[],
    CD_Approved:false,
    Additional_Info:"",
    Printing_Process:"",
    Print_Trial_Done:false,
    IDDSampleApproved:false,
    Print_Trial_Needed:false,
    IDDSampleLabTestApproved:false,
  });
  let currentUrlBasePage = currentUrl?.split("/")[1];
  const [pageEdited, setPageEdited] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { markDirty, markClean } = useUnsavedChangesWarning();
  const [InitialFormObjects, setInitialFormObjects] = useState({
    Printer:[],
    Pantone:"",
    Select:false,
    Substrate:"",
    FileDetails:[],
    CD_Approved:false,
    Additional_Info:"",
    Printing_Process:"",
    Print_Trial_Done:false,
    IDDSampleApproved:false,
    Print_Trial_Needed:false,
    IDDSampleLabTestApproved:false,
  });
  const [inputValid,setInputValid]=useState({});

  useEffect(()=>{
    const updatedIQ = _.cloneDeep(IQ).filter((obj)=>{
        return obj.Action !== "delete" 
        // && (obj?.Design_Job_ID?.toString()?.includes("IQ_") || obj?.Select || obj?.Printer?.length || obj?.Pantone || obj?.Additional_Info)
    })
    const isObjectEqual = JSON.stringify(initialIQ) === JSON.stringify(updatedIQ)
    if(!isObjectEqual){
      setPageEdited(true);
    }else{
      setPageEdited(false);
    }
  },[IQ])

  useEffect(() => {
    if (pageEdited) {
      markDirty();
    } else {
      markClean();
    }
  }, [pageEdited]);

  useEffect(()=>{
    markClean();
    setPageEdited (false);
  },[]);

  // const accessSecurityMatrix = usePriorityNew(currentUrlBasePage);

  useEffect(()=>{
    setUserAccess(getUserAccessForTask(TaskDetailsData));
    setCheckReadWriteAccess(getReadWriteAccessForTask(TaskDetailsData));
  },[TaskDetailsData?.AccessType])

  let Brand = [];
  let Category = [];

  if (TaskDetailsData?.ArtworkAgilityPage) {
    Brand = TaskDetailsData.ArtworkAgilityPage.Artwork_Brand;
    Category = TaskDetailsData.ArtworkAgilityPage.Artwork_Category;
  }

  useEffect(() => {
    if(userInformation?.userid && UserGroup?.length){
    let taskId;
    if (TaskID) {
      taskId = TaskID.split("_")[1];
    }
    setLoader(false)
    dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
    }
  }, [dispatch, TaskID, ProjectID, userInformation?.userid, UserGroup?.length]);

  useEffect(() => {
    if (TaskDetailsData) {
      setInitialIQ(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || [])
      setIQ(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || []);
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);

      const specialProjectFlag = TaskDetailsData?.SpecialProject;
      dispatch(SpecialProjectAction(specialProjectFlag));
      redirectUnallocatedLinksToArtworkStatus(currentUrl, specialProjectFlag, navigate);
    }
  }, [TaskDetailsData]);

  // useEffect(() => {
  //   if (IQ.length < 1) {
  //     addNewEmptyDesign();
  //   }
  // }, [IQ]);

  const handleCancel = () => {
    if (page2 && page2 === "projectPlan") {
      navigate(`/${page1}/${page2}/${TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID}`);
    } else if (pageType) {
      navigate(`/${pageType}`);
    }
  };

  const handleCancelClick = () =>{
    setCancelClicked(true)
    if(pageEdited){
      setShowDialog(true);
    }else{
      handleCancel();
    }
  }

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  }
  // it will navigate to previously saved url navigate(pendingNavigation);
  // by doing setShowDialog(false) and setPendingNavigation("");
  const confirmNavigation = () => {
    markClean();
    handleCancel();
    setShowDialog(false);
  }

  const handleDelete = (index) => {
    const sub = IQ?.map((item, i) => {
      if (i === index) {
        item.Action = "delete";
      }
      return item;
    });

    const notDeletedItems = IQ.filter((item) => {
      return item.Action !== "delete";
    });
    if (notDeletedItems.length === 0) {
      setSelectAllCheckbox(false);
    }
    setIQ(sub);
  };

  useEffect(() => {
    checkFormValidity(IQ);
    checkFormValidityForSaveAsDraft(IQ);
  }, [IQ, data]);

  const addNewEmptyDesign = () => {
    const newDesignIntent = [
      ...IQ,
      {
        Design_Job_ID: IQ.length + 1,
        isNew: true,
        Pantone: "",
        // Printer: [],
        Design_Job_Name: "",
        Additional_Info: "",
        CD_Approved: null,
        Select: null,
        Design_Job_Status: "",
      },
    ];
    setIQ(newDesignIntent);
    setUpdated(!updated);
  };

  const addData = (fieldName, index, value, Design_Intent_Name) => {
    let Project_Name = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;
    let initialIQdata = cloneDeep(initialIQ);
    let initialData = initialIQdata[index];
    let IQdata = cloneDeep(IQ);
    let data = IQdata[index];
    data[fieldName] = value;
    if(fieldName === "Printer" && !value?.length){
      delete data[fieldName]
    }
    let designJobName = (data?.Printer?.length || data?.Pantone || data?.Additional_Info) ?
    createDesignJobName(
      data.Printer,
      data.Pantone,
      data.Printing_Process,
      data.Substrate,
      data.Additional_Info,
      Project_Name,
      Brand,
      Category,
      jobName
    ) : 
    initialData?.Design_Job_Name || "";
    data["Design_Job_Name"] = designJobName;
    if (fieldName === "Select") {
      let checkedLineItems = IQdata.filter((obj) => {
        return obj.Select === true;
      });
      if (checkedLineItems.length === IQdata.length) {
        setSelectAllCheckbox(true);
      } else {
        setSelectAllCheckbox(false);
      }
    }
    setIQ(IQdata);

    setSubmittedDI(Math.random());
    checkFormValidity(IQdata);
    checkFormValidityForSaveAsDraft(IQdata);
    // if(value){
      setFormObjects((prev)=>{
        return{
          ...prev,
          [fieldName]:value,
        }
      })
    // }
  };

  useEffect(() => {
    let count = 0;
    IQ?.forEach((obj) => {
      if (obj.Select) {
        count++;
      }
    });
    if (IQ.length === count) {
      setSelectAllCheckbox(true);
    } else {
      setSelectAllCheckbox(false);
    }
  }, [submittedDI]);

  const onSelectAll = (checked) => {
    let IQdata = cloneDeep(IQ);
    IQdata?.forEach((task) => {
      if (task?.Design_Job_Status === "Awaiting" || task?.Design_Job_Status === "") {
        task.Select = checked;
      }
    });
    setIQ(IQdata);
    setUpdated(!updated);
  };

  const handleDeleteJob = async (Design_Job_ID) => {
    setLoader(true);
    let pageInstructions = [];

    let submitOnlySelectedData = IQ?.filter((task) => Design_Job_ID === task?.Design_Job_ID);
    if (submitOnlySelectedData?.length > 0) {
      let temp = {};
      temp["instruction"] = "APPEND";
      temp["target"] = "IQList";
      temp["content"] = {
        DesignJobName: submitOnlySelectedData[0]?.Design_Job_Name,
        DesignJobID: submitOnlySelectedData[0]?.Design_Job_ID,
        AdditionalInfo: submitOnlySelectedData[0]?.Additional_Info,
        Pantone: submitOnlySelectedData[0]?.Pantone,
        // Printer: submitOnlySelectedData[0]?.Printer ? submitOnlySelectedData[0].Printer : [],
        Select: submitOnlySelectedData[0]?.Select?.toString(),
        Action: "delete",
      };
      pageInstructions.push(temp);
    }

    let formData = {
      caseTypeID: "PG-AAS-Work-DefineInkQualification",
      content: {
        AWMTaskID: data.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      },
      pageInstructions: pageInstructions,
    };

    let id = data.Task_Key;
    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };

    await submitInkQualification(formData, id, headers);
    dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
    setLoader(false);
  };

  const onSubmit = async () => {
    setLoader(true);
    let pageInstructions = [];

    let submitOnlySelectedData = IQ?.filter(
      (task) =>
        task?.Select === true &&
        (task?.Design_Job_Status === "" ||
          task?.Design_Job_Status === "Awaiting" ||
          task?.isNew === true)
    );
    submitOnlySelectedData.forEach((task) => {
      let taskAction = "update";
      if (task?.Action !== "delete" && task?.Design_Job_ID) {
        taskAction = "update";
      }
      if (task?.Action !== "delete" && task?.isNew === true) {
        taskAction = "add";
      }
      if (task?.Action === "delete") {
        taskAction = "delete";
      }
      let taskDesignJobID = task?.Design_Job_ID;
      if (task?.isNew) {
        taskDesignJobID = "";
      }
      let temp = {};
      temp["instruction"] = "APPEND";
      temp["target"] = "IQList";
      temp["content"] = {
        DesignJobName: task?.Design_Job_Name,
        DesignJobID: taskDesignJobID,
        AdditionalInfo: task?.Additional_Info,
        Pantone: task?.Pantone,
        Printer: task?.Printer,
        Select: task?.Select?.toString(),
        Action: taskAction,
      };
      pageInstructions.push(temp);
    });
    let formData = {
      caseTypeID: "PG-AAS-Work-DefineInkQualification",
      content: {
        AWMTaskID: data.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      },
      pageInstructions: pageInstructions,
    };

    let id = data.Task_Key;
    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };

    await submitInkQualification(formData, id, headers);
    setLoader(false);

    if (page2 && page2 === "projectPlan") {
      navigate(`/${page1}/${page2}/${TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID}`);
    } else if (pageType) {
      navigate(`/${pageType}`);
    }
  };

  const onSaveAsDraft = async () => {
    setLoader(true);

    let counter = 0;
    let submitOnlySelectedData = IQ?.filter(
      (task) => task?.Design_Job_Status === "Awaiting" || task?.Design_Job_Status === ""
    );

    submitOnlySelectedData.map((task) => {
      counter++;

      if (task?.Action !== "delete" && task?.Design_Job_ID) {
        task.Action = "update";
      }
      if (task?.Action !== "delete" && task?.isNew === true) {
        task.Action = "add";
      }
      if (task?.Action === "delete") {
        task.Action = "delete";
      }
      if (task?.isNew) {
        task.Design_Job_ID = "";
      }

      task.Design_Job_Name = task?.Design_Job_Name;

      return task;
    });

    let formData = {
      AWM_Project_ID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      AWM_Task_ID: data.Task_ID,
      Project_Name: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name,
      BU: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU,
      Region: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region,
      IQList: submitOnlySelectedData,
    };

    await saveInkQualification(formData);
    dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
    setLoader(false);
  };

  const checkFormValidity = (IQdata) => {
    const validTasks = IQdata?.filter((task) => {
      return (
        task?.Printer?.length > 0 &&
        task?.Select &&
        (task.Design_Job_Status === "Awaiting" || task.Design_Job_Status === "") &&
        (task?.Action !=="delete")
      );
    });
    const selectedTasks = IQdata?.filter((task) => {
      return (
        task?.Select && 
        (task.Design_Job_Status === "Awaiting" || task.Design_Job_Status === "") &&
        (task?.Action !=="delete")
      );
    });
    if (
      validTasks?.length > 0 &&
      selectedTasks?.length > 0 &&
      validTasks?.length === selectedTasks?.length
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };
  const checkFormValidityForSaveAsDraft = (IQdata) => {
    const lineItems = IQdata?.filter((task) => {
      return task?.Design_Job_Status === "Awaiting";
    });

    if (lineItems?.length > 0) {
      setFormValidForSaveAsDraft(true);
    } else {
      setFormValidForSaveAsDraft(false);
    }
  };

  const loadOnClearButton = () => {
    dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
  };

  return (
    <PageLayout>
      {loader || loading || IQ === null ? (
        <LoadingOverlay active={loader || loading || IQ === null} spinner text="" className="overlay-loader"/>
      ) : accessSecurityMatrix ?
        <>      
        <IQHeader
          setAddNewDesign={addNewEmptyDesign}
          onSelectAll={onSelectAll}
          selectAllCheckbox={selectAllCheckbox}
          breadcrumb={breadcrumb}
          headerName={headerName}
          pageEdited={pageEdited}
          setPageEdited={setPageEdited}
          label="Define Ink Qualification"
          showPage="DNIQ"
          lastLabel={"DNIQ"}
          checkReadWriteAccess={checkReadWriteAccess}
          data={data}
          accessSecurityMatrix={accessSecurityMatrix}
        />
        <div className="task-details">
          {<TaskHeader {...data} TaskDetailsData={TaskDetailsData} pageEdited={pageEdited} setPageEdited={setPageEdited} />}

          {IQ &&
            IQ.length > 0 &&
            IQ.map((item, index) => {
              if (item && item?.Action !== "delete") {
                return (
                  <CloneJobs
                    accessSecurityMatrix={accessSecurityMatrix}
                    key={index}
                    {...data}
                    data={data}
                    item={item}
                    pageEdited={pageEdited}
                    setPageEdited={setPageEdited}
                    setFormObjects={setFormObjects}
                    setInitialFormObjects={setInitialFormObjects}
                    AWMProjectID={TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID}
                    AWMTaskID={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID}
                    Bu={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU}
                    Region={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region}
                    hideClearButton={true}
                    handleDeleteJob={handleDeleteJob}
                    loadOnClearButton={loadOnClearButton}
                    onSaveAsDraft={onSaveAsDraft}
                    index={index}
                    addData={addData}
                    handleDelete={handleDelete}
                    jobName={jobName}
                    setFormValid={setFormValid}
                    checkReadWriteAccess={checkReadWriteAccess}
                    Artwork_Brand={TaskDetailsData?.ArtworkAgilityPage?.Artwork_Brand}
                    Artwork_Category={TaskDetailsData?.ArtworkAgilityPage?.Artwork_Category}
                    Taskdata={TaskDetailsData}
                    showDialog={showDialog}
                    setShowDialog={setShowDialog}
                    cancelClicked={cancelClicked}
                    setCancelClicked={setCancelClicked}
                    handleCancel={handleCancel}
                    setInputValid={setInputValid}
                  />
                );
              }
            })}
        </div>
        <IQCDFooterButtons
          accessSecurityMatrix={accessSecurityMatrix}
          handleCancel={handleCancelClick}
          onSaveAsDraft={onSaveAsDraft}
          onSubmit={onSubmit}
          pageEdited={pageEdited}
          setPageEdited={setPageEdited}
          formValid={!formValid|| !Object.values(inputValid).every((valid) => valid)}
          checkReadWriteAccess={checkReadWriteAccess}
          bottomFixed={true}
          data={data}
          formValidForSaveAsDraft={formValidForSaveAsDraft}
        />
      {/* UnsavedChangesDialog popup*/}   
      {/* <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      /> */}
      </>
      : <UnAuthorizedComponent nowrapper={true} />
      }
    </PageLayout>
  );
}

export default DNIQ;
