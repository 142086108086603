import Api from ".";
import { store } from "../store/store";
import { DEVURL, AZUREURL, SITURL, PRODURL } from "./envUrl";

export const getDropDownValues = async (
  page,
  projectId,
  headers = {
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
  }
) => {
  const api = new Api();
  // Shiva is added below code because api call was happening multiple times just check from which page function invoked 
  // alert(`I am inside the getDropDownValues ${page}`)
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/fetchdropdownvalues`;
  try {
    const response = await axiosInstance({
      url: apiURL,
      method: "GET",
    });
    // Check for successful response status, e.g., 200 OK
    // if (response?.status === 200) {
      return response?.data;
    // }
  } catch (error) {
    console.log(error.message)
    // if (error.message.includes("net::ERR_CONNECTION_TIMED_OUT")) {
        
    //   // Handle the server being down
    //   return {
    //     error: "The server is currently unavailable. Please try again later.",
    //   };
    // } else {
    //   console.error("Error fetching project plan:", error);
    //   throw error; // Rethrow the error for other types of errors
    // }
  }
};
