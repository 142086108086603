import React, { useState, useEffect } from "react";
import PageLayout from "../PageLayout";
import "./index.scss";
import ArtworkStatusList from "./ArtworkStatusList";
import { ProjectService } from "../../service/PegaService";
import { useSelector } from "react-redux";
import { hasAllAccess } from "../../utils";
import { useUnsavedChangesWarning } from "../common/NavigationPrompt";

const ArtworkStatus = (props) => {
  const [pegadata, setPegaData] = useState(null);
  const [loading, setLoading] = useState(false);
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const [clearAllFilter , setClearAllFilter ] = useState(false);
  const { markDirty, markClean } = useUnsavedChangesWarning();
  const { accessMatrix } = useSelector((state) => state?.accessMatrixReducer);
  // const accessDetails = getUnAuthoirzedAccess(
  //   userInformation.role,
  //   accessMatrix,
  //   window?.location?.pathname
  // );
  // // Check if access is empty for the user's role and page
  // const isAccessEmpty = accessDetails === null || accessDetails.length === 0;

  useEffect(() => {
    markClean();
    // writing this line because on loading the pages we need to clear unsaveChanges localstorage
    setLoading(true);
    (async () => {
      try {
        const ProjectData = await ProjectService.getProjectData();
        if (ProjectData.length) {
          setPegaData(ProjectData);
        }
      } catch (err) {
        console.log("error", err);
      }
      setLoading(false);
    })();
  }, []);

  return (
      <ArtworkStatusList  setClearAllFilter={setClearAllFilter} />
  );
};
export default ArtworkStatus;
