export const createGroupForBVELoop = (offTractBVELoop, onTractBVELoop) => {
  let tempOnTrack = [];
  let tempOffTrack = [];
  if (onTractBVELoop && onTractBVELoop.length) {
    onTractBVELoop.forEach((group) => {
      group["Group"] = "On Track";
      tempOnTrack.push(group);
    });
  }

  if (offTractBVELoop && offTractBVELoop.length) {
    offTractBVELoop.forEach((group) => {
      group["Group"] = "Off Track";
      tempOffTrack.push(group);
    });
  }

  return { tempOnTrack, tempOffTrack };
};
export const storeACPReorderedColumns = (e, localKeyWord,allColumnNames) => {
  let columnNamesJson = JSON.parse(localStorage?.getItem(localKeyWord));
  let projectColumnName=[];
  if(columnNamesJson){
     projectColumnName = columnNamesJson;
  }
  else{
    projectColumnName=allColumnNames;
  }

  if (projectColumnName?.length > 0) {
    const dragColumnName = projectColumnName[e?.dragIndex];
    const index = projectColumnName.indexOf(dragColumnName);
    if (index > -1) {
      // only splice array when item is found
      projectColumnName.splice(index, 1); // 2nd parameter means remove one item only
      projectColumnName.splice(e?.dropIndex, 0, dragColumnName);
    }
    projectColumnName.forEach((ele, index) => {
      ele.reorder = ele.field_Name === dragColumnName.field_Name;
      ele.sequence = index + 1;
    });
    localStorage.setItem(localKeyWord, JSON.stringify(projectColumnName));
    return projectColumnName;
  }
};
export const onACPColumnResizeEnd = (event,localKeyWord,allColumnNames) => {
  const _allColumns = allColumnNames?.map((list) => {
    if (event.column.props.field === list.Field_Name || event.column.props.field === list.field_Name ) {
      list.width = event.element.offsetWidth;
    }
    return list;
  });
  localStorage.setItem(localKeyWord, JSON.stringify(_allColumns));
  return _allColumns;

};
