import React, { useState, useEffect } from "react";
import { TreeTable } from "primereact/treetable";
import { isEmpty } from "lodash";

export default function ProjectPlanTable(props) {
  const {
    pegadata: data,
    columns,
    projectColumnName,
    fieldUpdated,
    expandedKeys,
    frozenUpdated,
    isSearch,
    allColumns,
    setProjectColumnNames,
    setFieldUpdated,
    setExpandedKeys,
    setFrozenUpdated,
    onSetAllColumns,
    filters,
    isCustomizeViewActive
  } = props;
  const [nodes, setNodes] = useState([]);
  const [frozenWidth, setFrozenWidth] = useState("0px");

  useEffect(() => {
    setNodes(data);
  }, [data]);

  //this use-effect is to call the tree table again to reset the width, if customize field is changes 
  useEffect(() => {
    const tempData=data
    setNodes([]);
    setTimeout(() => {
      setNodes(tempData);
    }, 100);
  }, [isCustomizeViewActive]);

  useEffect(() => {
    setFrozenUpdated(frozenUpdated);
    frozenColumn();
  }, [frozenUpdated, allColumns]);

  const frozenColumn = () => {
    const numFrozenColumns = allColumns?.filter((col) => col.freeze).length;
    const totalFrozenWidth =  numFrozenColumns * 200 
    if (totalFrozenWidth > 0) {
      setFrozenWidth(`${totalFrozenWidth}px`);
    } else {
      setFrozenWidth(`0px`);

    }
  };

  const onColumnResizeEnd = (event) => {
    const _allColumns = allColumns?.map((list) => {
      if (event.column.props.field === list.field_Name) {
        list.width = event.element.offsetWidth;
      }
      return list;
    });
    localStorage.setItem("projectPlanAllColumnNames", JSON.stringify(_allColumns));
    onSetAllColumns(_allColumns);
    setFieldUpdated(!fieldUpdated);
  };

  const storeReorderedColumns = (e) => {
    let columnNamesJson = localStorage.getItem('projectPlanAllColumnNames');
    const projectColumnName = JSON.parse(columnNamesJson);
    if (projectColumnName?.length > 0) {
      const dragColumnName = projectColumnName[e?.dragIndex];
      const index = projectColumnName.indexOf(dragColumnName);
      if (index > -1) {
        // only splice array when item is found
        projectColumnName.splice(index, 1); // 2nd parameter means remove one item only
        projectColumnName.splice(e?.dropIndex, 0, dragColumnName);
      }
      projectColumnName.forEach((ele, index) => {
        ele.reorder = ele.field_Name === dragColumnName.field_Name;
        ele.sequence = index + 1;
      });

      localStorage.setItem("projectPlanAllColumnNames", JSON.stringify(projectColumnName));
      setProjectColumnNames(projectColumnName);
    }
  };
  return (
    <>
      {data !== null && !isEmpty(columns) && !isEmpty(nodes) &&
       <TreeTable
        resizableColumns
        reorderableColumns
        scrollable
        expanded
        dataKey="Task"
        onColumnResizeEnd={onColumnResizeEnd}
        onColReorder={storeReorderedColumns}
        value={filters.length ? filters : nodes}
        className={isSearch ? "searchWithTreeTable textAlignTreeTable" : "textAlignTreeTable"}
        tableStyle={{
          minWidth: "90%",
          width: "maxContent",
          minHeight: "30px",
        }}
        //scrollWidth="100%"
        columnResizeMode="expand"
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
        frozenWidth={frozenWidth}
      >
        {columns}
      </TreeTable>}
    </>
  );
}
