// @flow
import "../index.scss";
import React from "react";
import Task from "./Task";
import memoizeOne from "memoize-one";
import { Droppable } from "react-beautiful-dnd";
import { replaceSpaceWithUnderscore } from "./Utils";

const getSelectedMap = memoizeOne((selectedTaskIds) =>
  selectedTaskIds.reduce((previous, current) => {
    previous[current] = true;
    return previous;
  }, {})
);

const Column = ({
  tasks,
  column,
  entities,
  droppableId,
  multiSelectTo,
  draggingTaskId,
  selectedTaskIds,
  toggleSelection,
  toggleSelectionInGroup,
}) => {


  const updatedSelectedTaskIds = selectedTaskIds.map((ele) => replaceSpaceWithUnderscore(ele))
  const updatedDraggingTaskId = replaceSpaceWithUnderscore(draggingTaskId)

  return (
    <>
      <div className="columnMain">
        <div className="dragAndDropColumnData">{column.title}</div>
        <Droppable droppableId={column.id}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              // isDraggingOver={snapshot.isDraggingOver}
              className={`dragAndDropColumn ${snapshot.isDraggingOver ? "draggingOver" : ""}`}
            >
              {tasks?.length !== 0 &&
                tasks?.map((task, index) => {
                  const isSelected = getSelectedMap(updatedSelectedTaskIds)[task?.Field_Name];
                  const isGhosting =
                    isSelected && Boolean(updatedDraggingTaskId) && updatedDraggingTaskId !== task?.Field_Name;
                  return (
                    <Task
                      index={index}
                      task={task}
                      entities={entities}
                      columnId={column.id}
                      isSelected={isSelected}
                      isGhosting={isGhosting}
                      droppableId={droppableId}
                      multiSelectTo={multiSelectTo}
                      key={task?.Field_Name + index}
                      selectedTaskIds={updatedSelectedTaskIds}
                      toggleSelection={toggleSelection}
                      selectionCount={updatedSelectedTaskIds?.length}
                      toggleSelectionInGroup={toggleSelectionInGroup}
                      singleDragFlag={updatedDraggingTaskId !== task?.Field_Name}
                    />
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </>
  );
};
export default Column;
