import { useState, useCallback } from "react";
import axios from "axios";
import { CreateSession } from "../useSession";
import { useSelector } from "react-redux";
import { DEVURL } from "../../../apis/envUrl";
import { saveProofscopeURL } from "../../../apis/saveProofscopeURL";

export const useProofScopeURL = () => {
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [loadProofscope, setLoadProofscope] = useState(false);
  const [readonlyUrl, setReadonlyUrl] = useState("");
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User?.userInformation;
  const userid = `${userInformation?.userid}@pg.com`;
  const username = userInformation?.username;

  const viewProofScopeFile = useCallback(
    async (
      ProjectID,
      TaskStatus,
      TaskKey,
      TaskID,
      fileUrl,
      Assignee,
      flowName,
      flowAction,
      numberOfFiles
    ) => {
      setLoadProofscope(true);

      try {
        const sessionCreate = await CreateSession(
          `${DEVURL}/createProofscopeSession`
          // "https://azw-aac-hyb1.xnp-cloud-pg.com",
        );
        const strSession = JSON.parse(JSON.stringify(sessionCreate)).session;

        // file_urls: fileUrl,
        const json = {
          method: "hub.process_from_whitepaper_with_options",
          whitepaper_name: "awm_approval",
          // whitepaper_name: numberOfFiles > 1 ? "awm_approval_multiple" : "awm_approval",
          input_name: "begin_approval",
          variables: {
            // can_delete_notes: true,
            cf_art_path: fileUrl,
            // cf_art_list: fileUrl,
            //View_only_Mode:Assignee !== username ? true:false
            // "cloudflow://PP_FILE_STORE/CIC_20169540_POA-00454657_Bounty_NA_Paper_Towel_Essentials_Select-a-Size_Double_Roll_2CT_White_SHIPPER.pdf",
            approver: `${TaskID}.${Assignee}@pg.com`,
            params: {
              AWMProjectID: ProjectID,
              AWMTaskID: TaskID,
              TaskKey: TaskKey,
              FlowAction: flowAction,
              FlowName: flowName,
              customer_name: Assignee,
              task_id: TaskID,
            },
          },
          session: strSession,
        };
        // numberOfFiles > 1 ? json.variables["cf_art_list"] = fileUrl : json.variables["cf_art_path"] = fileUrl;

        // const VPFUrl = numberOfFiles > 1 ? "viewProofscopeFile" : "viewProofscopeSingleFile";
        const VPFUrl = "viewProofscopeFile";
        const response = await axios.post(
          `${DEVURL}/${VPFUrl}`,
          // "https://azw-aac-hyb1.xnp-cloud-pg.com",
          json,
          {
            headers: {
              "Content-Type": "application/json",
              "Content-Language": "en-US",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        // let headers = {
        //   "Content-Type": "application/json",
        //   "Content-Language": "en-US",
        //   "Access-Control-Allow-Origin": "*",
        // };
        // const api = new Api();
        // const axiosInstance = await api.init({ headers });

        // const response = await axiosInstance({
        //   url: `${DEVURL}/viewProofscopeFile`, // "https://azw-aac-hyb1.xnp-cloud-pg.com",
        //   method: "POST",
        //   data: json,
        // });

        const data = response.data;
        if (data.status === 500) {
          setIsError(true);
          setMessage(data.contents.message);
        } else if (data.approval_url || data.readonly_url) {
          //  if (Assignee !== userInformation?.userid) {
          //   //   viewerURLOutput = data.readonly_url.replace(
          //   //     /http:\/\/localhost:9090/g,
          //   //     "https://azw-aac-hyb1.xnp-cloud-pg.com"
          //   //   );
          //   // } else {
          //   //   viewerURLOutput = data.approval_url.replace(
          //   //     /http:\/\/localhost:9090/g,
          //   //     "https://azw-aac-hyb1.xnp-cloud-pg.com"
          //   //   );
          //   // }
          let readURLOutput = decodeURIComponent(data.readonly_url);
          let viewerURLOutput = decodeURIComponent(data.approval_url);
          setReadonlyUrl(data?.readonly_url)
          const requestData = {
            AWMProjectID: `${ProjectID}`,
            AWMTaskID: `${TaskID}`,
            PFFileURL: `${viewerURLOutput}`,
            ViewPFFileURL: `${readURLOutput}`,
            ApprovalID: `${data?.approval_id}&${data?.task_id}&${Assignee}`,
            Assignee: `${Assignee}`,
          };

          const taskCodeMatch = /^[^_]+(?=_)/.exec(TaskID)
          const taskCode = taskCodeMatch ? taskCodeMatch[0] : null
          if (!["URDT", "UCIC", "UCICMATRIX"].includes(taskCode)) {
            saveProofscopeURL(requestData)
              .then((responseData) => {
                // Handle the response from the API
                console.log(
                  "Response from the save proofscoep API:",
                  responseData
                );
                if (Assignee !== userInformation?.userid || TaskStatus === "Cancelled") {
                  console.log(readURLOutput, "readonly user");
                  setFileContent(readURLOutput);
                  return readURLOutput;
                } else {
                  console.log(viewerURLOutput, "assigned users");
                  setFileContent(viewerURLOutput);
                  return viewerURLOutput;
                }
              })
              .catch((error) => {
                // Handle any errors that occur during the request
                console.error("Error:", error);
              });
          }

          // const uri = new URL(decodedURL);
          setIsError(false);
          setMessage("");

          // window.open(viewerURLOutput, "_blank");
        }
      } catch (error) {
        // Handle error...
      } finally {
        setLoadProofscope(false);
      }
    },
    [userid, username]
  );

  return {
    viewProofScopeFile,
    loadProofscope,
    isError,
    message,
    fileContent,
    setFileContent,
    readonlyUrl
  };
};
