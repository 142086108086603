
export const formatBytes = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const downloadFile = (fileOrBlob, fileName = "download") => {
    const url = window.URL.createObjectURL(new Blob([fileOrBlob]));
    const a = document.createElement("a");
    a.href = url;
    a.download = fileOrBlob?.name ? fileOrBlob?.name : fileName; // Set the desired file name
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}