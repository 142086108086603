import React from "react";
import "./index.scss";

export const CharacterLimit = (props) => {
    return(
      <span 
        className={
          props?.field?.length === 0 ? 'characterLimit text-black' : props?.field?.length >= props?.maxLength ? 'characterLimit text-danger' : 'characterLimit text-secondary'
        } 
        hidden={props?.field?.length === 0? 'hidden':''}>({props?.field?.length}/{props.maxLength})
      </span>
    );
  }