import React from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import "./AlertDialogBox.scss";

const AlertDialogBox = ({ visible, onHide, message, header, icon, handleContinue }) => {
  return (
    <ConfirmDialog
      className="p-alert-confirm-box"
      visible={visible}
      onHide={() => onHide(false)}
      message={<p className="infoText">{message}</p>}
      header={header}
      icon={icon}
      footer={
        <div className={"button-container"}>
          <Button label="OK" onClick={handleContinue} className="p-button-success" />
        </div>
      }
    />
  );
};

export default AlertDialogBox;
