import * as types from "../types/types";

export const userDetailsActions = (data)=>({
    payload:data,
    type: types.GET_USER_DETAILS
})

export const updateuserBU = (data) => ({
    payload: data,
    type: types.UPDATE_SELECTED_USER_BU
})
