import React, { useEffect, useState, useRef } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import moment from "moment";
import PageLayout from "../../PageLayout";
import DesignHeader from "../DesignJobs/DesignHeader";
import FooterButtons from "../DesignJobs/FooterButtons";
import AddNewDesign from "../DesignJobs/TaskHeader";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import { submitUploadRegionalDesignIntent } from "../../../apis/uploadSubmitAPIs";
import { postSaveDesignIntent } from "../../../apis/uploadSaveAsDraft";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uploadtoAzureStrContainer } from "../../../apis/uploadAzuerContainer";
import { Toast } from "primereact/toast";
import {
  viewThumbnail,
  redirectToProjectPlanOrTaskPage,
  sanitizeFileName,
  getEnvironmentFromURL,
} from "../../../utils";
import UploadDesignIntentProofscope from "../DesignJobs/UploadDesignIntentProofscope";
import { uploadtoAzurefileShare } from "../../../apis/uploadAzureFileShareApi";
import { Message } from "primereact/message";
import useAzureUploadFolderStructure from "../../../hooks/useAzureUploadFolderStructure";
import { useProofScopeURL } from "../../ProofScope/ViewFiles";
import { isEmpty, isString } from "lodash";
import { totalFileSizeQuotient } from "../../../constants/FileUploadConstant";
import {
  AzureContainerFail,
  AzureContainerSuccess,
  AzureFileShareFail,
  AzureFileShareSuccess,
  ContainerAndFileShareFailMsg,
  ContainerFailMsg,
  FileShareFailMsg,
} from "../../../Utils/constants";
import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus } from "../../../utils";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";
import { getReadWriteAccessForTask, getUserAccessForTask } from "../../../Utils/helpers";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";

const breadcrumb = [
  { label: "My Tasks", url: "/tasks" },
  { label: "Upload Regional Design Template" },
];

const URDT = () => {
  const toast = useRef(null);
  const [data, setData] = useState(null);
  const [date, setDate] = useState("");
  const [version, setVersion] = useState(0);
  const [designIntent, setDesignIntent] = useState(null);
  const [formattedValue, setformattedValue] = useState(0);
  const [fileName, setFileName] = useState("");
  const [viewFileName, setViewFileName] = useState("");
  const [azureFile, setAzureFile] = useState("");
  const [loader, setLoader] = useState(true);
  const [fileDetails, setFileDetails] = useState(null);
  const [formData, setFormData] = useState(null);
  const [azureFileUploadMsg, setAzureFileUploadMsg] = useState(false);
  const [thumbnailErrorMsg, setThumbnailErrorMsg] = useState("");
  let { TaskID, page1, page2, pageType, ProjectID } = useParams();
  const { TaskDetailsData, loading } = useSelector((state) => state.TaskDetailsReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Key}`;
  const headerName = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.TaskPlaceholderName}`;
  const shortName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Type;
  const roleName = "DI_";
  const location = useLocation();
  const currentUrl = location?.pathname;
  const folderStructure = useAzureUploadFolderStructure("RDT", version);
  // const checkReadWriteAccess = true;
  const {
    userDetails: { UserGroup },
  } = useSelector((state) => state?.UserDetailsReducer);
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User?.userInformation;
  const userAccessPayLoad = {
    ArtworkAgilityPage: {
      UserId: userInformation?.userid,
      ProjectID: ProjectID,
      UserGroup: UserGroup
    }
  }
  const [accessType, setAccessType] = useState("Write");
  const [accessSecurityMatrix, setUserAccess] = useState(false);
  const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);

  const env = getEnvironmentFromURL();
  const sanitizedBuName = sanitizeFileName(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU);
  const sanitizedTaskFolder = sanitizeFileName(`RDT`);
  const { viewProofScopeFile } = useProofScopeURL();
  const [readonlyUrl, setReadonlyUrl] = useState("");
  const [pageEdited, setPageEdited] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [FileUploaded, setFileUploaded] = useState(false);
  const { markDirty, markClean } = useUnsavedChangesWarning();
  const [FormObjects, setFormObjects] = useState({fileList:[]});
  const [InitialFormObjects, setInitialFormObjects] = useState({fileList:[]});

  useEffect(()=>{
    const isObjectEqual = JSON.stringify(InitialFormObjects) === JSON.stringify(FormObjects)
    if(!isObjectEqual && FileUploaded){
      setPageEdited(true);
    }else{
      setPageEdited(false);
      setFileUploaded(false);
    }
  },[FormObjects])

  useEffect(() => {
    if (pageEdited) {
      markDirty();
    } else {
      markClean();
    }
  }, [pageEdited]);

  useEffect(()=>{
    markClean();
    setPageEdited (false);
  },[]);

  useEffect(()=>{
    setUserAccess(getUserAccessForTask(TaskDetailsData));
    setCheckReadWriteAccess(getReadWriteAccessForTask(TaskDetailsData));
    setAccessType(TaskDetailsData?.AccessType);
  },[TaskDetailsData?.AccessType])

  useEffect(() => {
    if(userInformation?.userid && UserGroup?.length){
      setLoader(false)
      dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
      setAzureFileUploadMsg(false);
      setThumbnailErrorMsg("");
    }
  }, [dispatch, TaskID, ProjectID, userInformation?.userid, UserGroup?.length]);

  useEffect(() => {
    if (TaskDetailsData) {
      setDesignIntent(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || []);
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);
      const data =
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0] ||
        [];

      const specialProjectFlag = TaskDetailsData?.SpecialProject;
      dispatch(SpecialProjectAction(specialProjectFlag));
      redirectUnallocatedLinksToArtworkStatus(currentUrl, specialProjectFlag, navigate);
      if (data) {
        data.Version !== "" && setVersion(data.Version);
        data.Timestamp !== "" &&
          setDate(moment(data.Timestamp, "YYYYMMDD[T]HHmmss.SSS [GMT]").format("DD-MMMM-YYYY"));
      }
    }
  }, [TaskDetailsData]);

  useEffect(() => {
    if (!isEmpty(readonlyUrl) && !isEmpty(formData)) {
      (async () => {
        const payload = { ...formData };
        if (!isEmpty(payload.pageInstructions)) {
          payload.pageInstructions[0].content["ViewPFURL"] = readonlyUrl;
        }
        const headers = {
          key: "If-Match",
          value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
        };
        await submitUploadRegionalDesignIntent(payload, id, headers);
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "File Uploaded",
            detail: "Successfully!",
            life: 3000,
          });
        }
        await dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
        redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
        setLoader(false);
        setAzureFileUploadMsg(false);
        setThumbnailErrorMsg("");
      })();
    }
  }, [readonlyUrl, formData]);

  const handleCancel = () => {
    redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
  };

  const handleCancelClick = () =>{
    if(pageEdited){
      setShowDialog(true);
    }else{
      handleCancel();
    }
  }

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  }
  // it will navigate to previously saved url navigate(pendingNavigation);
  // by doing setShowDialog(false) and setPendingNavigation("");
  const confirmNavigation = () => {
    markClean();
    handleCancel();
    setShowDialog(false);
  }

  const BU = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU;
  const projectName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;
  const filesexist =
    TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]
      ?.File_Name;

  // Define a function to generate the formData object
  const generateSaveAsDraftFormData = (filePath, fileValue) => {
    const fileSize = Math.round(formattedValue / totalFileSizeQuotient);
    return {
      AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
      AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      Size: fileSize === 0 ? "1" : fileSize,
      Version: fileDetails?.Version,
      Filename: azureFile?.File_Name ? azureFile?.File_Name : fileValue,
      Filepath:
        filePath === ""
          ? TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0]
              ?.File_Path
          : filePath,
      Full_Name: fileDetails?.Full_Name,
      Email: fileDetails?.Email,
    };
  };
  const generateSubmitFormData = (filePath, fileValue) => {
    const fileSize = Math.round(formattedValue / totalFileSizeQuotient);
    return {
      caseTypeID: "PG-AAS-Work-UploadRegionalDesignTemplate",
      content: {
        AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      },
      pageInstructions: [
        {
          instruction: "APPEND",
          target: "UploadRDTList",
          content: {
            Action: "add",
            Filename: azureFile?.File_Name ? azureFile?.File_Name : fileValue,
            Size: fileSize === 0 ? "1" : fileSize,
            Filepath:
              filePath === ""
                ? TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]
                    ?.FileMetaDataList?.[0]?.File_Path
                : filePath,
            Version: fileDetails?.Version,
            Full_Name: fileDetails?.Full_Name,
            Email: fileDetails?.Email,
            JobID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
            JobName:
              TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_Name,
            TypeOfFile: azureFile?.File_Name
              ? azureFile?.File_Name?.split(".").pop()
              : fileValue?.split(".").pop(),
          },
        },
      ],
    };
  };

  const onSaveAsDraft = async () => {
    setLoader(true);
    try {
      if (!(filesexist === azureFile) && azureFile !== "") {
        await uploadtoAzureStrContainer(azureFile, folderStructure);

        let res = await uploadtoAzurefileShare(
          azureFile?.file ? azureFile?.file : azureFile ? azureFile : filesexist,
          BU,
          `RDT`,
          ProjectID,
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
          version
        );

        if (res) {
          // Use the response from uploadtoAzurefileShare as the file name
          // Replace with the actual property name from the response

          const fileurl =
            fileName !== "" && fileName?.endsWith(".zip")
              ? sanitizeFileName(res)
              : fileName !== "" && !fileName?.endsWith(".zip")
              ? sanitizeFileName(fileName)
              : sanitizeFileName(filesexist);

          const thumnailResponse = await viewThumbnail(
            env,
            sanitizedBuName,
            sanitizedTaskFolder,
            fileurl,
            TaskID,
            ProjectID,
            TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
            version
          );
          if (fileName?.endsWith(".zip")) {
            setFileName(res);
            setAzureFile(res);
          }
          if (thumnailResponse.thumbnail) {
            const formData = generateSaveAsDraftFormData(thumnailResponse.thumbnail, fileurl);
            await postSaveDesignIntent(formData);

            if (toast.current) {
              toast.current.show({
                severity: "success",
                summary: "File Uploaded",
                detail: "Successfully!",
                life: 3000,
              });
            }

            await dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
            setLoader(false);
            setAzureFileUploadMsg(false);
          } else {
            setLoader(false);
            setAzureFileUploadMsg(true);
          }
        }
      } else {
        if (
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "In-Progress" &&
          filesexist !== ""
        ) {
          if (toast.current) {
            toast.current.show({
              severity: "success",
              summary: "File Uploaded",
              detail: "Successfully!",
              life: 3000,
            });
          }

          const formData = generateSaveAsDraftFormData("", filesexist);
          await postSaveDesignIntent(formData);
          await dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
          setLoader(false);
          setAzureFileUploadMsg(false);
        } else {
          setLoader(false);
          setAzureFileUploadMsg(false);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setLoader(false);
    }
  };

  const onSubmit = async () => {
    setLoader(true);

    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };

    try {
      if (!(filesexist === azureFile) && azureFile !== "") {
        Promise.all([
          uploadtoAzureStrContainer(azureFile, folderStructure),
          uploadtoAzurefileShare(
            azureFile.file ? azureFile.file : azureFile ? azureFile : filesexist,
            BU,
            `RDT`,
            ProjectID,
            TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
            version
          ),
        ])
          .then(function (responses) {
            const azureRes = responses[0];
            const res = responses[1];
            if (azureRes) {
              if (toast.current) {
                toast.current.show(AzureContainerSuccess);
              }
            } else {
              if (toast.current) {
                toast.current.show(AzureContainerFail);
              }
            }
            if (res) {
              if (toast.current) {
                toast.current.show(AzureFileShareSuccess);
              }
            } else {
              if (toast.current) {
                toast.current.show(AzureFileShareFail);
              }
            }
            return responses;
          })
          .then(async (data) => {
            const azureRes = data[0];
            const res = data[1];
            if (azureRes && res) {
              const fileurl =
                fileName !== "" && fileName?.endsWith(".zip")
                  ? sanitizeFileName(res)
                  : fileName !== "" && !fileName?.endsWith(".zip")
                  ? sanitizeFileName(fileName)
                  : sanitizeFileName(filesexist);
              const thumnailResponse = await viewThumbnail(
                env,
                sanitizedBuName,
                sanitizedTaskFolder,
                fileurl,
                TaskID,
                ProjectID,
                TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID,
                version
              );

              if (fileName?.endsWith(".zip")) {
                setFileName(res);
                setAzureFile(res);
              }
              if (thumnailResponse.thumbnail) {
                const designJobId = sanitizeFileName(
                  TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0].Design_Job_ID
                );
                const fileVersion = isString(version)
                  ? version.substring(0, 1) + (parseInt(version.substring(1)) + 1)
                  : version;
                const assignee = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Assignee;
                const filePath = `cloudflow://awm/${env}/${sanitizedBuName}/${sanitizedTaskFolder}/${ProjectID}/${designJobId}/${fileVersion}/${fileurl}`;

                // await viewProofScopeFile(
                //   ProjectID,
                //   id,
                //   TaskID,
                //   filePath,
                //   assignee,
                //   "UploadRegionalDesignTemplate",
                //   "UploadRegionalDesignTemplate_Flow",
                //   1
                // );
                setReadonlyUrl(thumnailResponse.readonly_url);

                const formData = generateSubmitFormData(thumnailResponse.thumbnail, fileurl);
                setFormData(formData);
              } else {
                setLoader(false);
                setAzureFileUploadMsg(true);
                setThumbnailErrorMsg(thumnailResponse?.contents?.message);
              }
            } else {
              const msg =
                !azureRes && !res
                  ? ContainerAndFileShareFailMsg
                  : !azureRes
                  ? ContainerFailMsg
                  : !res
                  ? FileShareFailMsg
                  : "File upload failed!  Please try after some time.";
              setLoader(false);
              setAzureFileUploadMsg(true);
              setThumbnailErrorMsg(msg);
            }
          });
      } else {
        if (
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "In-Progress" &&
          filesexist !== ""
        ) {
          const formData = generateSubmitFormData("", filesexist);
          await submitUploadRegionalDesignIntent(formData, id, headers);
          if (toast.current) {
            toast.current.show({
              severity: "success",
              summary: "File Uploaded",
              detail: "Successfully!",
              life: 3000,
            });
          }
          await dispatch(getTaskDetails(TaskID, ProjectID, userAccessPayLoad));
          redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
          setLoader(false);
          setAzureFileUploadMsg(false);
          setThumbnailErrorMsg("");
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setLoader(false);
    }
  };

  return (
    <PageLayout>
      <Toast ref={toast} />
      {loading || loader || designIntent === null ? (
        <LoadingOverlay active={loading || loader || designIntent === null} spinner text="" className="overlay-loader"/>
      ) : accessSecurityMatrix ?
      <>
      <DesignHeader
        breadcrumb={breadcrumb}
        headerName={headerName}
        disabled={true}
        label={shortName}
        lastLabel={"URDT"}
        pageEdited={pageEdited}
        setPageEdited={setPageEdited}
        checkReadWriteAccess={checkReadWriteAccess}
        actionButtonsFlag={true}
        {...data}
      />
      <div className="task-details">
        {
          <AddNewDesign
            {...data}
            checkReadWriteAccess={checkReadWriteAccess}
            TaskDetailsData={TaskDetailsData}
            setPageEdited={setPageEdited}
            pageEdited={pageEdited}
          />
        }
          {designIntent && (
            <UploadDesignIntentProofscope
              {...designIntent}
              designIntent={designIntent}
              upload={true}
              setPageEdited={setPageEdited}
              pageEdited={pageEdited}
              setformattedValue={setformattedValue}
              setAzureFile={setAzureFile}
              azureFile={azureFile}
              setFileName={setFileName}
              item={data}
              roleName={roleName}
              setFormObjects={setFormObjects}
              setFileUploaded={setFileUploaded}
              ArtworkAgilityPage={TaskDetailsData?.ArtworkAgilityPage}
              version={version}
              setVersion={setVersion}
              date={date}
              checkReadWriteAccess={checkReadWriteAccess}
              fileName={fileName}
              buName={BU}
              taskFolder="RDT"
              TaskID={TaskID}
              projectName={projectName}
              ProjectID={ProjectID}
              viewFileName={viewFileName}
              setViewFileName={setViewFileName}
              setAzureFileUploadMsg={setAzureFileUploadMsg}
              setFileDetails={setFileDetails}
              toShowReferenceDocuments={true}
              loading={loading}
              azureFileUploadMsg={azureFileUploadMsg}
              thumbnailErrorMsg={thumbnailErrorMsg}
            />
          )}
      </div>

      <FooterButtons
        accessType={accessType}
        handleCancel={handleCancelClick}
        onSaveAsDraft={onSaveAsDraft}
        onSubmit={onSubmit}
        hideSaveButton={true}
        checkReadWriteAccess={checkReadWriteAccess}
        bottomFixed={true}
        formValid={
          fileName === "" ||
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Complete" ||
          TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Cancelled"
        }
        {...data}
      />
      {/* UnsavedChangesDialog popup*/}   
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
      </>
      : <UnAuthorizedComponent nowrapper={true} />
      }
    </PageLayout>
  );
};
export default URDT;
