import React, { useEffect, useState } from "react";
import ".././index.scss";
import { getStartEndDateFromWeek } from "./Utils/WeekHelper";
import moment from "moment";
import { AcpService } from "../../../service/ACPService";

const WeekRange = ({
  // setWeekStartRange,
  setWeekEndRange,
  identifier,
  weekRanges,
  setFilterSubmitButton,
  filters,
  setFilters,
  setOpenEndWeekRange,
  setOpenStartWeekRange,
}) => {
  const [localRanges, setLocalRanges] = useState([]);
  const selectWeekRange = (weekRangeValueWithYear, weekRangeValue) => {
    if (identifier == "start") {
      setFilterSubmitButton(false);
      let updatedValue = {};
      let weekRangeYearValue = weekRangeValueWithYear?.split("-")[0]?.split(" ")[2];
      const { startDate } = getStartEndDateFromWeek(weekRangeValue, weekRangeYearValue);
      const newStartDate = AcpService.getFormattedDate(startDate);

      updatedValue = {
        weekStartRange: weekRangeValue,
        startDate: moment(newStartDate).format("YYYYMMDDTHHmmss.SSS [GMT]"),
      };
      setFilters((prev) => {
        return { ...prev, ...updatedValue };
      });
      setOpenStartWeekRange(false);
      //setWeekStartRange(weekRangeValue);
    } else if (identifier == "end") {
      setFilterSubmitButton(false);
      let updatedValue = {};
      let weekRangeYearValue = weekRangeValueWithYear?.split("-")[1]?.split(" ")[2];
      const { endDate, startDate } = getStartEndDateFromWeek(weekRangeValue, weekRangeYearValue);
      const newEndDate = AcpService.getFormattedDate(endDate);
      const newStartDateForEndDate = AcpService.getFormattedDate(startDate);

      updatedValue = {
        weekEndRange: weekRangeValue,
        endDate: moment(newEndDate).format("YYYYMMDDTHHmmss.SSS [GMT]"),
        endStartDate: moment(newStartDateForEndDate).format("YYYYMMDDTHHmmss.SSS [GMT]"),
      };
      setFilters((prev) => {
        return { ...prev, ...updatedValue };
      });
      //setWeekEndRange(weekRangeValue);
      setOpenEndWeekRange(false);
    }
  };

  useEffect(() => {
    setLocalRanges(AcpService.removeYears(weekRanges));
  }, [weekRanges]);

  return (
    <>
      <div className="weekRangeDataWraper">
        {localRanges?.map((range, index) => (
          <p
            onClick={() => {
              selectWeekRange(weekRanges[index], range);
            }}
            key={index}
            className="weekRangeData"
          >
            {range}
          </p>
        ))}
      </div>
    </>
  );
};

export default WeekRange;
