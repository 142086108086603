import { useRef, useState } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import useAzureUploadFolderStructure from "../../../hooks/useAzureUploadFolderStructure";
import { Tooltip } from "primereact/tooltip";
import { downloadFile } from "../../../Utils/FileUploadService";
import { downloadAzureContainer } from "../../../apis/downloadAzureContainer";
import "./index.scss";
import openPDF from "../../../assets/images/openPDF.svg";
import trashbin from "../../../assets/images/trashbin.svg";
import download from "../../../assets/images/download.svg";
import uploadLight from "../../../assets/images/uploadLight.svg";
import CustomFileViewer from "../CustomFileViewer";
import { AZUREURL } from "../../../apis/envUrl";
import { isEmpty } from "lodash";
import { useProofScopeURL } from "../../ProofScope/ViewFiles";
import { Dialog } from "primereact/dialog";
import { replaceSpecialChars, sanitizeFileName } from "../../../utils";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const FileNameAndOptions = ({
  rowData,
  onDelete,
  onUpdate,
  accept,
  subFolder,
  toShowDownloadOption = true,
  toShowViewOption = true,
  toShowDeleteOption = true,
  toShowUploadOption = true,
  companyName = "",
}) => {
  const fileOptionMenuRef = useRef(null);
  const fileReuploadRef = useRef(null);
  const tooltipRef = useRef(null);
  const [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
  const [fileViewerData, setFileViewerData] = useState({});
  const [isPFFileOpen, setIsPFFileOpen] = useState(false);
  const { loadProofscope, fileContent, setFileContent } = useProofScopeURL();

  const { TaskDetailsData } = useSelector((state) => state.TaskDetailsReducer);
  let { ProjectID } = useParams();
  if (!ProjectID) {
    ProjectID = TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID;
  }

  const BU = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU;
  const projectName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;
  const regionName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region;
  const Project = ProjectID?.toUpperCase() + " " + projectName;
  //Added groupName based on task details for UBD and UCIC
  const groupName=TaskDetailsData?.ArtworkAgilityTasks?.[0]?.GABriefList?.[0]?.GroupName ?
  TaskDetailsData?.ArtworkAgilityTasks?.[0]?.GABriefList?.[0]?.GroupName:TaskDetailsData?.ArtworkAgilityTasks[0]?.CICDetails?.[0]?.GABriefFiles?.[0]?.GroupName

  //Passing groupName to useAzureUploadFolderStructure
  const folderStructure = useAzureUploadFolderStructure(subFolder, rowData?.Version,true,groupName);
  const folderStructureWithoutVersion = useAzureUploadFolderStructure(
    subFolder,
    rowData?.Version,
    false,
    groupName
  );

  const handleFileReplacement = function (event) {
    event.preventDefault();
    onUpdate(event, rowData);
  };

  const handleViewFile = async () => {
    setIsFileViewerOpen(true);

    const re = /(?:\.([^.]+))?$/;
    const fileType = re.exec(rowData.File_Name)[1]?.toLowerCase();

    const fileName = rowData?.File_Name;

    let filePath;
    // If proofscope file exists in taskdetails
    if (!isEmpty(rowData.ReadOnlyURL)) {
      setIsPFFileOpen(true);
      setFileContent(rowData.ReadOnlyURL);
    } else if (rowData.file) {
      filePath = window.URL.createObjectURL(rowData.file);
    } else if (rowData?.Info?.fileInfo) {
      filePath = window.URL.createObjectURL(rowData.Info.fileInfo);
    } else {
      const downloadFileUrl = `${AZUREURL}/downloadFileFromContainerPath?path=/${sanitizeFileName(
        folderStructure
      )}&file=${rowData.File_Name}`;
      const filePathRes = await fetch(downloadFileUrl);
      filePath = filePathRes.ok ? downloadFileUrl : null;
    }

    setFileViewerData({
      filePath,
      fileType,
      fileName,
    });
  };

  const onFileViewerHide = () => {
    setIsFileViewerOpen(false);
    setIsPFFileOpen(false);
  };

  const menuItems = [
    {
      label: "Download",
      icon: <img src={download} alt="View Icon" />,
      command: () => handleDownload(),
      className: "file-menu-options-listitem",
      visible: toShowDownloadOption,
    },
    {
      label: "Open in viewer",
      icon: <img src={openPDF} alt="View Icon" />,
      command: () => handleViewFile(),
      className: "file-menu-options-listitem",
      visible: toShowViewOption,
    },
    {
      label: "Delete",
      icon: <img src={trashbin} alt="Delete Icon" />,
      command: () => handleDelete(),
      className: "file-menu-options-listitem",
      disabled: rowData?.FileID ? true : false,
      visible: toShowDeleteOption,
    },
    {
      label: "Upload New File",
      icon: <img src={uploadLight} alt="View Icon" />,
      command: () => fileReuploadRef.current.click(),
      className: "file-menu-options-listitem",
      disabled: rowData?.FileID ? true : false,
      visible: toShowUploadOption,
    },
  ];

  const handleDownload = () => {
    if (rowData?.isCopyXMLFile) {
      downloadAzureContainer(replaceSpecialChars(rowData.File_Name), `${companyName}/Outbound/RTA_CopyXML`);
      return;
    }

    if (rowData.ReferenceDocumentType) {
      if (
        rowData.ReferenceDocumentType.includes("GABriefFiles") ||
        rowData.ReferenceDocumentType.includes("GABDetails") ||
        rowData.ReferenceDocumentType.includes("OtherReferenceDocuments") ||
        rowData.ReferenceDocumentType.includes("ORDDetails")
      ) {
          //Added groupName of GA brief 
        let folderStructure = `${BU}/${regionName}/${Project}/GA Brief/${groupName}/${rowData.Version}`;
        downloadAzureContainer(replaceSpecialChars(rowData.File_Name), folderStructure);
      } else {
        if (rowData.ReferenceDocumentType.includes("RDTDetails")) {
          let folderStructure = `${BU}/${regionName}/${Project}/RDT/${rowData.JobName}/${rowData.Version}`;
          downloadAzureContainer(replaceSpecialChars(rowData.File_Name), folderStructure);
        } else {
          downloadAzureContainer(
            replaceSpecialChars(rowData.File_Name),
            `${folderStructureWithoutVersion}/${rowData.ReferenceDocumentType}/${rowData.Version}`
          );
        }

      }

      return;
    }

    if (rowData.file) {
      downloadFile(rowData.file);
    } else if (rowData?.Info?.fileInfo) {
      downloadFile(rowData?.Info?.fileInfo);
    } else {
      downloadAzureContainer(replaceSpecialChars(rowData.File_Name), folderStructure);
    }
  };

  const handleDelete = () => {
    onDelete(rowData);
  };
  const fileName = replaceSpecialChars(rowData?.File_Name);
  // const truncatedFileName =
  //   fileName && fileName.length > 30 ? fileName.substring(0, 30) + "..." : fileName;

  return (
    <div className="filename-container">
      <Tooltip target={tooltipRef} position="top" content={fileName} />
      <span className="filename" ref={tooltipRef}>
        {fileName}
      </span>
      <Menu className="file-menu-options" model={menuItems} popup ref={fileOptionMenuRef} />
      <input
        type="file"
        ref={fileReuploadRef}
        onChange={handleFileReplacement}
        accept={accept}
        className="file-replace-input"
      />
      <Button
        icon="pi pi-ellipsis-v"
        className="file-options-icon-cta p-0"
        text
        aria-label="File Options"
        onClick={(event) => fileOptionMenuRef?.current?.toggle(event)}
      ></Button>
      <Dialog
        header={replaceSpecialChars(rowData?.File_Name)}
        visible={isPFFileOpen}
        maximizable
        style={{ width: loadProofscope ? "70vw" : "50vw" }}
        onHide={onFileViewerHide}
      >
        {loadProofscope && rowData?.ReadOnlyURL === "" ? (
          <h4>Please wait!!! Proofscope file loading....</h4>
        ) : (
          <iframe
            src={fileContent}
            title="File Content"
            style={{ width: "100%", height: "500px" }}
            onLoad={() => {
              // Content has loaded
              setIsPFFileOpen(true);
            }}
          />
        )}
      </Dialog>
      {!isPFFileOpen && !isEmpty(fileViewerData.filePath) && (
        <CustomFileViewer
          fileViewerData={fileViewerData}
          isFileViewerOpen={isFileViewerOpen}
          onFileViewerHide={onFileViewerHide}
        />
      )}
    </div>
  );
};

export default FileNameAndOptions;
