import "./index.scss";
import ProjectList from "./ProjectList";
import PageLayout from "../../PageLayout";
import { useSelector,useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { ProjectService } from "../../../service/PegaService";
// import { getPriorityNew } from "../../../Utils/GetRolePriority";
import { getMyProject } from "../../../store/actions/ProjectActions";

const MyProjects = () => {
  const User = useSelector((state) => state?.UserReducer);
  const [pegadata, setPegaData] = useState(null);
  const userInformation = User?.userInformation;
  const dispatch = useDispatch();
  const user_group = useSelector((state) => state?.UserDetailsReducer?.userDetails?.UserGroup);
  

  useEffect(() => {    
    if(user_group?.length && userInformation?.userid) {
      const payLoad = {
        ArtworkAgilityPage: {
          UserGroup: user_group,
          PM: userInformation?.userid,
        },
      };
      dispatch(getMyProject(payLoad));
    }
  }, [dispatch, user_group?.length, userInformation?.userid]);

  useEffect(() => {
    (async () => {
      try {
        const ProjectData = ProjectService.getProjectData();
        if (ProjectData?.length) {
          setPegaData(ProjectData);
        }
      } catch (err) {
        console.log("error", err);
      }
    })();
  }, []);

  return (
    <>
      {/* {JSON.stringify(getPriorityNew(AccessGroup, location[1]))} */}
      <PageLayout>
        <div className="content-layout" id="tableDiv">
          <div className="tabular-view">
            <ProjectList pegadata={pegadata} header="My Projects" />
          </div>
        </div>
      </PageLayout>
    </>
  );
};
export default MyProjects;
