import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PgLogo from '../../assets/images/logo.svg';
import { updateUser } from '../../apis/userApi';
import './index.scss';
import { processRedirectionAfterLogin } from '../../Utils/helpers';

function UserLogin() {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const user = await updateUser(formData.username, formData.password);
    if (user?.username) {
      setError(null);
      await processRedirectionAfterLogin(user, navigate);
    } else {
      setError('Invalid user! Please try again.');
    }
    setIsLoading(false);
  };

  return (
    <div className="login-screen">
      <div className="userList">
        <Row>
          <Col>
            <img src={PgLogo} alt="logo" />
          </Col>
          <Col>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formUsername">
                <h2>Welcome!</h2>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  name="username"
                  type="text"
                  value={formData.username}
                  required
                  className="mb-3"
                  placeholder="Enter your usename"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  value={formData.password}
                  required
                  className="mb-3"
                  placeholder="Enter your password"
                  onChange={handleChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mb-3" disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Login'}
              </Button>
              <p className="text-danger">{error}</p>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default UserLogin;
