import React from "react";
import { CSVLink } from "react-csv";
import export2excel from "../../assets/images/export2excel.svg";
import { changeDateFormat } from "../../utils";
import { extractData, formatDate } from "../Projects/ProjectPlan/util";
import { isString } from "lodash";

export const ExportSelectedRows = ({ allData, selectedRows, headers, filename}) => {
  // Helper function to format data rows
  const formatData = (data) => {
    const formattedData = {
      ...data,
    };
    formattedData.Start_Date = !isString(data.End_Date) ? formatDate(data.Start_Date) : data.Start_Date;
    formattedData.End_Date = !isString(data.End_Date) ? formatDate(data.End_Date) : data.End_Date;

    if (data) {
      if (
        data["Estimated_SOP"] !== undefined ||
        data["Estimated_SOS"] !== undefined ||
        data["Estimated_AW_Printer"] !== undefined ||
        data["Estimated_AW_Readiness"] !== undefined
      ) {
        formattedData.Estimated_SOP = changeDateFormat(data["Estimated_SOP"]);
        formattedData.Estimated_SOS = changeDateFormat(data["Estimated_SOS"]);
        formattedData.Category = data["Artwork_Category"];
        formattedData.Brand = data["Artwork_Brand"];
        formattedData.SMO = data["Artwork_SMO"];
        formattedData.region = data["Project_region"];
        formattedData.Scale = data["Project_Scale"];
      } else if (data["Assignee"] !== undefined) {
        formattedData.Owner = data["Assignee"];
      }
    }

    return formattedData;
  };

  const formattedAllData = allData ? allData.map(formatData) : [];

  const formattedSelectedRows = selectedRows ? selectedRows.map(formatData) : [];

  const modifiedHeaders = headers?.map((str) => {
    if (
      str?.startsWith("Artwork_") ||
      str?.startsWith("Project_region") ||
      str?.startsWith("Project_Scale")
    ) {
      return str?.substring(8); // Remove the first 8 characters ("Artwork_")
    }
    return str;
  });
  const generateCsvReport = (data, headers, fileName) => ({
    data,
    headers: headers?.map(header => header === "Company" ? "FA Supplier" : header),
    filename: fileName
  });

  if (!selectedRows || selectedRows.length === 0) {
    if (allData !== null && allData !== undefined) {
      const csvReport = generateCsvReport(
        formattedAllData.map(row => {
          const newRow = { ...row };
      
          // Modify the header name from "Company" to "FA Supplier" if present
          if (newRow.hasOwnProperty("Company")) {
            newRow["FA Supplier"] = newRow["Company"];
          }
      
          return newRow;
        }),
        modifiedHeaders?.map(header => header === "Company" ? "FA Supplier" : header),
        filename ? `${filename}.csv` : "Project_Records.csv"
      );
      return <>{csvTag(csvReport)}</>;
    }
  } else if (selectedRows.length > 0) {
    const csvReport = generateCsvReport(
      formattedSelectedRows,
      modifiedHeaders,
      filename ? `${filename}_Selected_Rows.csv` : "Selected_Rows.csv"
    );
    return <>{csvTag(csvReport)}</>;
  }

  return null;
};

const csvTag = (data) => {
  return (
    <CSVLink {...data}>
      <img src={export2excel} alt="Export to csv" />
    </CSVLink>
  );
};

export default ExportSelectedRows;
