// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-dialog-confirm-box .p-dialog-footer button {
  border-radius: 1.6rem;
}
.p-dialog-confirm-box .p-button-danger {
  background-color: #ffffff !important;
  border: 0.13rem solid #003da5 !important;
  color: #003da5 !important;
}
.p-dialog-confirm-box .p-button-success {
  background-color: #ffffff !important;
  border: 2px solid #003da5 !important;
  color: #003da5 !important;
}
.p-dialog-confirm-box .p-button-danger:hover {
  background-color: #003da5 !important;
  border: 2px solid #003da5 !important;
  color: #ffffff !important;
}
.p-dialog-confirm-box .p-button-success:hover {
  background-color: #003da5 !important;
  border: 2px solid #003da5 !important;
  color: #ffffff !important;
}
.p-dialog-confirm-box .p-dialog-content {
  width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ConfirmDialog/ConfirmationDialogBox.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;AAAJ;AAGE;EACE,oCAAA;EACA,wCAAA;EACA,yBAAA;AADJ;AAIE;EACE,oCAAA;EACA,oCAAA;EACA,yBAAA;AAFJ;AAKE;EACE,oCAAA;EACA,oCAAA;EACA,yBAAA;AAHJ;AAME;EACE,oCAAA;EACA,oCAAA;EACA,yBAAA;AAJJ;AAOE;EACE,sBAAA;AALJ","sourcesContent":[".p-dialog-confirm-box {\n  .p-dialog-footer button {\n    border-radius: 1.6rem;\n  }\n\n  .p-button-danger {\n    background-color: #ffffff !important;\n    border: 0.13rem solid #003da5 !important;\n    color: #003da5 !important;\n  }\n\n  .p-button-success {\n    background-color: #ffffff !important;\n    border: 2px solid #003da5 !important;\n    color: #003da5 !important;\n  }\n\n  .p-button-danger:hover {\n    background-color: #003da5 !important;\n    border: 2px solid #003da5 !important;\n    color: #ffffff !important;\n  }\n\n  .p-button-success:hover {\n    background-color: #003da5 !important;\n    border: 2px solid #003da5 !important;\n    color: #ffffff !important;\n  }\n\n  .p-dialog-content {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
