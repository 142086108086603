import React, { useEffect, lazy, Suspense, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useDispatch, useSelector } from "react-redux";
import { getAllTasks } from "../../../store/actions/TaskActions";
import { ALL_TASKS } from "../../../constants";

const TaskList = lazy(() => import("../TaskList"));

const AllTasks = () => {
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User?.userInformation;
  const dispatch = useDispatch();
  const { allTasks, loading } = useSelector((state) => state.TaskReducer);
  const {
    userDetails: { AccessGroup, UserGroup },
  } = useSelector((state) => state.UserDetailsReducer);
  const [clearAllFilter, setClearAllFilter] = useState(false);
  const [uniqueCompnies, setUniqueCompnies] = useState(new Set());


  const hasExternalTaskOwnerAccess = AccessGroup?.some(
    (item) => item.AccessGroupNames === "AAS:ExternalTaskOwner"
  );

  useEffect(() => {
    if (UserGroup?.length && userInformation?.userid) {
      const payLoad = {
        ArtworkAgilityPage: {
          PM: userInformation.userid,
          UserGroup: UserGroup,
        },
      };
      dispatch(getAllTasks(payLoad));
    }
  }, [dispatch, UserGroup?.length, userInformation?.userid]);

 
    // Filter the original data array based on unique company names
    // filteredData?.push(...allTasks?.filter(item => uniqueCompanies.has(item.Company)));
  

  useEffect(() => {
    if (allTasks && allTasks?.length) {
      const uniqueCompanies = new Set();
      allTasks?.forEach((item) => {
        if (item.Assignee === userInformation?.userid && item.Company !== "") {
          uniqueCompanies.add(item.Company);
        }
      });
      setUniqueCompnies(uniqueCompanies);
    }
  },[allTasks?.length])

  return (
    <>
      <Suspense fallback={<LoadingOverlay active={true} spinner text="" />}>
       {/* {allTasks && ( */}
       <TaskList
            myTasks={allTasks? allTasks : []}
            loading={loading}
            flag={ALL_TASKS}
            setClearAllFilter={setClearAllFilter}
            userInformation={userInformation}
            uniqueCompanies={uniqueCompnies}
            hasExternalTaskOwnerAccess={hasExternalTaskOwnerAccess}
          />
        {/* )} */}
      </Suspense>
    </>
  );
};

export default AllTasks;
