import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { getRoleOwnerDetailsapi, updateRoleAndOwnerApi } from "../../../apis/getRoleOwnerDetails";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay-ts";
import { Toast } from "primereact/toast";
import _, { isObject } from "lodash";
import CommonAutocomplete from "../../../Utils/CommonAutocomplete";
import { SuggestionsMatch, showBackgroundProcess } from "../../../Utils/helpers";

function MassupdateDialog(props) {
  const { visible, setVisible, getProjectPlanApi, clearFilters,  onHide } = props;
  const [roleOwnerList, setRoleOwnerList] = useState([]);
  const [owner, setOwner] = useState({});
  const [formDirty, setFormDirty] = useState(false);
  const projectDetails = useSelector((state) => state.ProjectPlanReducer);
  const [loader, setLoader] = useState(false);
  const [updateEnable, setUpdateEnable] = useState(false);
  const initialState = {
    isDefaultOwnersSelected: false,
    pm: "",
    selectedOwnerList: {},
    isDropdownSelected: false,
  };
  const [dropDownMatch, setDropDownMatch] = useState(true);
  const [ownerDetails, setOwnerDetails] = useState(initialState);
  const { Region, BU } = projectDetails?.projectPlanDesign[0] || {};
  const toast = useRef();
  const { ProjectID } = useParams();
  const [width, setWidth] = useState(0);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (dropdownRef?.current) {
      setWidth(dropdownRef?.current?.offsetWidth);
    }
  }, [roleOwnerList]);

  const setDefaultOwner = async () => {
    if (roleOwnerList && roleOwnerList?.RolesList?.length > 0) {
      const defaultObj = {};
      for (let [index, item] of roleOwnerList.RolesList.entries()) {
        if (item.DefaultOwner !== "") {
          defaultObj[index] = { pyName: item.DefaultOwner, Person: item.DefaultOwner };
        } else {
          defaultObj[index] = { pyName: "", Person: "" };
        }
      }
      setOwner(defaultObj);
    }
  };

  const getRoleOwnerDetails = async () => {
    setLoader(true);
    const response = await getRoleOwnerDetailsapi(ProjectID, BU, Region);
    setRoleOwnerList(response.data);

    if (response.data !== undefined) {
      const storedOwners = {};
      response?.data?.RolesList?.forEach((role, index) => {
        storedOwners[index] = { Person: role?.DefaultOwner };
      });

      setOwnerDetails({
        ...initialState,
        isDefaultOwnersSelected: response?.data?.IsDefaultOwnersSelected,
        pm: response.data?.PM,
        storedOwnerList: storedOwners,
      });
    }

    setLoader(false);
  };

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Record Update successfully",
      life: 3000,
    });
  };

  useEffect(() => {
    if (visible) {
      getRoleOwnerDetails();
    }
    return () => {
      setOwner({});
    };
  }, [visible]);

  useEffect(() => {
    if (!_.isEmpty(roleOwnerList)) {
      setDefaultOwner();
    }
  }, [roleOwnerList]);

  const payload = {
    BU: BU,
    Region: Region,
    AWMProjectID: ProjectID,
    PM: ownerDetails.pm,
    IsDefaultOwnersSelected: ownerDetails?.isDefaultOwnersSelected,
    RoleOwnerList: [],
  };

  //3531 changes
  const updateObject = (value, index, defaultOwner) => {
    const updatedSelectedOwnerList = { ...ownerDetails?.selectedOwnerList };

    // Check if the selected value is valid (not empty and not equal to the default)
    if (value?.Person && value.Person !== defaultOwner) {
      // If the selected value is valid, add it to the selectedOwnerList
      updatedSelectedOwnerList[index] = value;
    } else {
      // If the value is invalid (empty or default), remove it from the selectedOwnerList
      const filteredOwnerList = Object.keys(updatedSelectedOwnerList)
        .filter((key) => key !== index.toString()) // Remove the index key
        .reduce((acc, key) => {
          acc[key] = updatedSelectedOwnerList[key];
          return acc;
        }, {});

      // Update the state with the filtered list
      setOwnerDetails((prevState) => ({
        ...prevState,
        selectedOwnerList: filteredOwnerList,
      }));

      // Update the owner state for UI
      setOwner((prevOwner) => {
        const updatedOwner = { ...prevOwner };
        updatedOwner[index] = value;
        return updatedOwner;
      });

      // Enable the "Update" button only if there are valid changes
      setUpdateEnable(Object.keys(filteredOwnerList).length > 0);
      return; // Exit the function early
    }

    // Enable the "Update" button only if there are valid changes
    setUpdateEnable(Object.keys(updatedSelectedOwnerList).length > 0);

    // Update the state
    setOwnerDetails((prevState) => ({
      ...prevState,
      selectedOwnerList: updatedSelectedOwnerList,
    }));

    // Update the owner state for UI
    setOwner((prevOwner) => {
      const updatedOwner = { ...prevOwner };
      updatedOwner[index] = value;
      return updatedOwner;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    // Clear the payload RoleOwnerList to avoid duplicates
    payload.RoleOwnerList = [];

    // Iterate through the selectedOwnerList and only include roles where the owner is different from the default
    for (let key in ownerDetails?.selectedOwnerList) {
      const selectedOwner = ownerDetails?.selectedOwnerList?.[key]?.Person;
      const defaultOwner = roleOwnerList?.RolesList?.[key]?.DefaultOwner;

      // Only add to payload if the selected owner is different from the default owner
      if (selectedOwner && selectedOwner !== defaultOwner) {
        payload?.RoleOwnerList?.push({
          Role: roleOwnerList?.RolesList?.[key]?.Role,
          Owner: selectedOwner,
        });
      }
    }

    const response = await updateRoleAndOwnerApi(payload);
    if (response.status === 200) {
      // Clearing values if response comes to rerender the page
      props.setPageEdited(false);
      setVisible(false);
      showSuccess();
    }
    setUpdateEnable(false);
    setLoader(false);
    getProjectPlanApi();
    clearFilters("MassUpdate Clicked");
    showBackgroundProcess(toast);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Mass Update"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => {
          setVisible(false);
          setUpdateEnable(false);
          onHide();
        }}
      >
        <LoadingOverlay active={loader} spinner text="">
          <form id="massUpdateForm">
            <Container>
              <div className="formWrapper">
                <Row>
                  <Col>
                    <h5>Role</h5>
                    {roleOwnerList &&
                      roleOwnerList?.RolesList?.map((role, index) => {
                        return (
                          <>
                            <InputText
                              key={index}
                              className="col-sm-12 mb-3"
                              value={role.Role}
                              readOnly
                            />
                          </>
                        );
                      })}
                  </Col>
                  <Col>
                    <h5>Owner</h5>
                    {roleOwnerList &&
                      roleOwnerList?.RolesList?.map((dropDownList, index) => {
                        const defaultOwner = dropDownList.DefaultOwner;
                        const placeholder = defaultOwner ? `${defaultOwner}` : "Select Owner";
                        return (
                          <div ref={dropdownRef}>
                            <CommonAutocomplete
                              width={width}
                              suggestions={dropDownList.OwnerList}
                              value={owner[index]}
                              onChange={(e) => {
                                updateObject(e.value, index, defaultOwner);
                              }}
                              placeholder={placeholder}
                              field="Person"
                              className="col-sm-12 mb-3"
                              setDropDownMatch={setDropDownMatch}
                            />
                          </div>
                        );
                      })}
                  </Col>
                </Row>
              </div>
              <Row>
                <Col>
                  <div className="float-end ">
                    <div className="check-message">
                      <Checkbox
                        onChange={(e) => {
                          setUpdateEnable(true);
                          setOwnerDetails((prevState) => ({
                            ...prevState,
                            isDefaultOwnersSelected: e.checked,
                          }));
                        }}
                        checked={ownerDetails.isDefaultOwnersSelected}
                        name="confirmation"
                        value="Keep the setup for all future assignment"
                      />
                      <label htmlFor="remember" className=" ml-2">
                        Keep the setup for all future assignments in this project
                      </label>
                    </div>
                    <div className="massUpdateFooter">
                      <Button
                        type="btn"
                        onClick={(e) => {
                          e.preventDefault();
                          setVisible(false);
                          setUpdateEnable(false);
                          onHide();
                        }}
                        className="btn btn-secondary"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="btn"
                        className="btn btn-primary"
                        disabled={!updateEnable || !dropDownMatch}
                        onClick={handleSubmit}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </form>
        </LoadingOverlay>
      </Dialog>
    </>
  );
}

export default MassupdateDialog;
