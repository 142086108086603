import "../index.scss";
import React from "react";
import PanelTop from "./PanelTop";
import { forwardRef } from "react";
import PanelBottom from "./PanelBottom";

function AcpDashboard(
  {
    reset,
    loader,
    tabName,
    Headers,
    isSearch,
    rowGroups,
    fetchData,
    isBVEloop,
    editedRow,
    expandAll,
    setLoader,
    setHeaders,
    actionType,
    isSearchSet,
    selectedRows,
    setExpandAll,
    setChartData,
    chartClicked,
    setEditedRow,
    setActionType,
    setActionFlag,
    allEditedRows,
    setBookingData,
    clearAllFilter,
    setSelectedRows,
    setAllEditedRows,
    bookingTableData,
    setClearAllFilter,
    setNoOfActiveTasks,
    updatedBookingData,
    ShowCustomizedView,
    bookingTableFilters,
    setBookingTableData,
    customizeViewFields,
    checkReadWriteAccess,
    editedRowPanelBottom,
    setShowCustomizedView,
    setCustomizeViewFields,
    selectedRowsPanelBottom,
    setEditedRowPanelBottom,
    allEditedRowsPanelBottom,
    setSelectedRowsPanelBottom,
    setAllEditedRowsPanelBottom,
    pmPicklistData,
    dashboardAlerts,
  },
  ref
) {
  return (
    <>
      <div className="dashboard-panel-wrapper">
        {pmPicklistData?.length ? (
          <PanelTop
            pmPicklistData={pmPicklistData}
            ref={ref}
            reset={reset}
            loader={loader}
            tabName={tabName}
            Headers={Headers}
            isSearch={isSearch}
            rowGroups={rowGroups}
            fetchData={fetchData}
            isBVEloop={isBVEloop}
            editedRow={editedRow}
            expandAll={expandAll}
            setLoader={setLoader}
            actionType={actionType}
            setHeaders={setHeaders}
            isSearchSet={isSearchSet}
            selectedRows={selectedRows}
            setExpandAll={setExpandAll}
            setChartData={setChartData}
            chartClicked={chartClicked}
            setEditedRow={setEditedRow}
            setActionType={setActionType}
            setActionFlag={setActionFlag}
            allEditedRows={allEditedRows}
            setBookingData={setBookingData}
            clearAllFilter={clearAllFilter}
            setSelectedRows={setSelectedRows}
            setAllEditedRows={setAllEditedRows}
            bookingTableData={bookingTableData}
            setClearAllFilter={setClearAllFilter}
            setNoOfActiveTasks={setNoOfActiveTasks}
            updatedBookingData={updatedBookingData}
            ShowCustomizedView={ShowCustomizedView}
            bookingTableFilters={bookingTableFilters}
            setBookingTableData={setBookingTableData}
            customizeViewFields={customizeViewFields}
            checkReadWriteAccess={checkReadWriteAccess}
            setShowCustomizedView={setShowCustomizedView}
            setCustomizeViewFields={setCustomizeViewFields}
          />
        ) : (
          <></>
        )}
        <PanelBottom
          ref={ref}
          reset={reset}
          loader={loader}
          tabName={tabName}
          Headers={Headers}
          isSearch={isSearch}
          rowGroups={rowGroups}
          fetchData={fetchData}
          isBVEloop={isBVEloop}
          editedRow={editedRow}
          expandAll={expandAll}
          setLoader={setLoader}
          actionType={actionType}
          setHeaders={setHeaders}
          isSearchSet={isSearchSet}
          selectedRows={selectedRows}
          setExpandAll={setExpandAll}
          setChartData={setChartData}
          chartClicked={chartClicked}
          setEditedRow={setEditedRow}
          setActionType={setActionType}
          setActionFlag={setActionFlag}
          allEditedRows={allEditedRows}
          clearAllFilter={clearAllFilter}
          setBookingData={setBookingData}
          setSelectedRows={setSelectedRows}
          dashboardAlerts={dashboardAlerts}
          setAllEditedRows={setAllEditedRows}
          bookingTableData={bookingTableData}
          setClearAllFilter={setClearAllFilter}
          setNoOfActiveTasks={setNoOfActiveTasks}
          updatedBookingData={updatedBookingData}
          ShowCustomizedView={ShowCustomizedView}
          bookingTableFilters={bookingTableFilters}
          setBookingTableData={setBookingTableData}
          customizeViewFields={customizeViewFields}
          editedRowPanelBottom={editedRowPanelBottom}
          checkReadWriteAccess={checkReadWriteAccess}
          setShowCustomizedView={setShowCustomizedView}
          setCustomizeViewFields={setCustomizeViewFields}
          setEditedRowPanelBottom={setEditedRowPanelBottom}
          selectedRowsPanelBottom={selectedRowsPanelBottom}
          allEditedRowsPanelBottom={allEditedRowsPanelBottom}
          setSelectedRowsPanelBottom={setSelectedRowsPanelBottom}
          setAllEditedRowsPanelBottom={setAllEditedRowsPanelBottom}
        />
      </div>
    </>
  );
}

export default forwardRef(AcpDashboard);
