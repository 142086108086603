import { AcpService } from "./ACPService";

export const AcpCommonService = {
  removeEmptyArraysWithNoData(obj) {
    const result = {};

    for (let key in obj) {
      if (!(Array.isArray(obj[key]) && obj[key].length === 0)) {
        result[key] = obj[key];
      }
    }

    return result;
  },
  isFieldBlankOrSingle(selectedField, selectedColumnName) {
    // Check if selectedField is null, undefined, an empty string, or an empty object
    if (!selectedField || (selectedField && Object.keys(selectedField).length === 0)) {
      return true;
    }

    // Check if the object has exactly one key and if that key matches the selectedColumnName
    const keys = Object.keys(selectedField);
    if (keys && keys.length === 1 && keys[0] === selectedColumnName) {
      const value = selectedField[selectedColumnName];

      // Check if the value is an array (regardless of how many elements it has)
      return Array.isArray(value);
    }

    // If more than one key is present, or the key doesn't match selectedColumnName, return false
    return false;
  },
  handleAllowDate({ selectedDate, setData, startDate, setSataus }) {
    setData((prevData) => {
      let isFormValidOrNot = false;
      const allTasks = prevData?.map((item) => {
        const isAllow =
          new Date(AcpService.convertToIST(selectedDate)) <
          new Date(AcpService.convertToIST(item[startDate]))
            ? false
            : true;
        item.allow = isAllow;
        if (!isAllow) {
          isFormValidOrNot = true;
        }
        return item;
      });
      setSataus(isFormValidOrNot);
      return allTasks;
    });
  },
  checkMinDate(selectedData, setMinDate) {
    let minDate = new Date();
    if (selectedData.length > 0) {
      minDate = selectedData?.reduce((p, q) => {
        const PDate = new Date(AcpService.convertToIST(p?.StartDate));
        const QDate = new Date(AcpService.convertToIST(q?.StartDate));
        return PDate.getTime() < QDate.getTime() ? p : q;
      });
      minDate = new Date(AcpService.convertToIST(minDate?.StartDate));
    }
    setMinDate(minDate);
  },
  updateQueryParams(key, value, location, navigate) {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(key)) {
      searchParams.set(key, value);
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  },
  parseCustomDate(dateString) {
    // Extract components of the date string
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1; // JavaScript months are 0-based
    const day = dateString.substring(6, 8);
    const hours = dateString.substring(9, 11);
    const minutes = dateString.substring(11, 13);
    const seconds = dateString.substring(13, 15);

    return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
  },
};
