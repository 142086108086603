import React, { useState, useMemo, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { useDispatch, useSelector } from "react-redux";
import { Column } from 'primereact/column';
import { getAllChildern, getInitialStateValue, addNewInitialValues, deleteMarkUp, updateMarkUp } from '../../../src/Utils/GridService';
import { historyColumns, rejectionColumns, RejectionMessage } from '../../../src/constants/reworkPageTasks';
import AddNewReason from './AddNewReason';
import { Button } from 'primereact/button';
import './index.scss';
import { reasonForRework } from '../../store/actions/ReworkMarkUpsAction';


const ReasonForRework = ({ reworkHistoryData, selectedData, treedata, onSubmitReworkReason, onClosePopUp }) => {
    const {
        DropDownValuesData: {
            ArtworkAgilityTasksPage: { Defect_Type, Proofscope_Rejection, Artwork_Role },
        },
    } = useSelector((state) => state.DropDownValuesReducer);
    const dispatch = useDispatch();

    const [visibleTables, setVisibleTables] = useState({});

    const [allReason, setReasons] = useState([]);

    const selectedCICjobs = useMemo(() => {
        let jobs = [];
        const selectedRequestCIC = selectedData?.filter((ele) => ele?.code?.includes("RCIC"));
        selectedRequestCIC.forEach((item) => {
            const job = selectedData?.filter((ele) => ele.key === item.parent);
            jobs = [...jobs, ...job];
        });
        return jobs;
    }, [selectedData]);

    const getReworkDataByJob = (designJobID) => {
        const historyDataJob = reworkHistoryData?.filter((ele) => ele?.Design_Job_ID === designJobID);
        return historyDataJob?.length > 0 ? historyDataJob[0]?.MarkupList : []
    };
    const getAllMarkupList = (job) => {
        let allmarkup = [];
        const allChildren = getAllChildern(treedata, job);
        allChildren?.forEach((item) => {
            if (item.MarkupList && item.MarkupList.length > 0) {
                allmarkup = [...allmarkup, ...item.MarkupList];
            }
        });
        allmarkup = allmarkup.map((markup, index) => ({ ...markup, sNo: index + 1 }));
        return allmarkup;
    };


    useEffect(() => {
        const initialVisibility = {};
        selectedCICjobs?.forEach((item, index) => {
            initialVisibility[`rejection-${index}`] = true;
            initialVisibility[`history-${index}`] = false;
        });
        setVisibleTables(initialVisibility);
        const initialValue = getInitialStateValue(selectedCICjobs, Defect_Type, Proofscope_Rejection, Artwork_Role)
        setReasons(initialValue)
    }, [selectedCICjobs]);



    const toggleVisibility = (key) => {
        setVisibleTables(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    const setReasonsState = (updatedmarkUpList, selectedJob) => {
        setReasons(allReason?.map(reason =>
            reason?.Design_Job_ID === selectedJob?.DesignJobID ? { ...reason, MarkupList: updatedmarkUpList } : reason
        ));
    }
    const setInputNeeded = (inputValue, selectedJob) => {
        setReasons(allReason?.map(reason =>
            reason?.Design_Job_ID === selectedJob?.DesignJobID ? { ...reason, InputNeeded: inputValue.target.value } : reason
        ));
    }
    const addNewReason = (selectAdd) => {
        const updatedState = addNewInitialValues(allReason, selectAdd?.DesignJobID, Defect_Type, Proofscope_Rejection, Artwork_Role)
        setReasonsState(updatedState, selectAdd)
    }

    const getMarkupList = (item) => {
        const selectedJob = allReason?.filter((ele) => ele.Design_Job_ID === item?.DesignJobID)
        return selectedJob[0]
    }
    const handelDelete = (markUpObj, job) => {
        const updatedMarkupList = deleteMarkUp(allReason, job?.DesignJobID, markUpObj.marukUp)
        setReasonsState(updatedMarkupList, job)
    }
    const handleChange = (ele, markUpObj, job, feild) => {
        const updatedMarkupList = updateMarkUp(allReason, job?.DesignJobID, markUpObj.marukUp, feild, ele.target.value)
        setReasonsState(updatedMarkupList, job)
    }
    const onSubmitReason = () => {
        dispatch(reasonForRework(allReason))
        onSubmitReworkReason()
    }
    return (
        <div className="reason-rework-wrapper">
            <div className="header">
                <p className="rejection-reason">{RejectionMessage.reason}</p>
            </div>
            {selectedCICjobs?.map((item, index) => (
                <div key={index}>
                    <div className="header">
                        <h6>{item.Task}</h6>
                        <p className="rejection-add" onClick={() => addNewReason(item)}>+ {RejectionMessage.add}</p>
                    </div>
                    <div>
                        <div className="input-container">
                            <label htmlFor="Input">{RejectionMessage.input}</label>
                            <select name="Input" id="Input" onChange={(e) => setInputNeeded(e, item)}>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </select>
                        </div>
                        <div className="add-delete-buttons">
                            <div className="new-component">
                                {getMarkupList(item)?.MarkupList?.map((ele, index) => {
                                    return (<AddNewReason
                                        key={index}
                                        markUpObj={ele}
                                        job={item}
                                        handelDelete={handelDelete}
                                        handleChange={handleChange}
                                    />)
                                })
                                }
                            </div>

                        </div>
                        <div className="data-table">
                            <div>
                                <div className="header-row">
                                    <span><h6>{RejectionMessage.rejection}</h6></span>
                                    <Button
                                        icon={`pi ${visibleTables[`rejection-${index}`] ? 'pi-chevron-up' : 'pi-chevron-down'}`}
                                        onClick={() => toggleVisibility(`rejection-${index}`)}
                                        className="toggle"
                                    />
                                </div>
                                {visibleTables[`rejection-${index}`] && (
                                    <div className="datatable-container">
                                        <DataTable value={getAllMarkupList(item)} tableStyle={{ minWidth: RejectionMessage.popUpWidth }}>
                                            {rejectionColumns?.map((col, i) => (
                                                <Column key={col.field} field={col.field} header={col.header} />
                                            ))}
                                        </DataTable>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="data-table">
                            <div className="header-row">
                                <span><h6>{RejectionMessage.history}</h6></span>
                                <Button
                                    icon={`pi ${visibleTables[`history-${index}`] ? 'pi-chevron-up' : 'pi-chevron-down'}`}
                                    onClick={() => toggleVisibility(`history-${index}`)}
                                    className="toggle"
                                />
                            </div>
                            {visibleTables[`history-${index}`] && (
                                <div className="datatable-container">
                                    <DataTable value={getReworkDataByJob(item.DesignJobID)} tableStyle={{ minWidth: RejectionMessage.popUpWidth }}>
                                        {historyColumns?.map((col, i) => (
                                            <Column key={col.field} field={col.field} header={col.header} />
                                        ))}
                                    </DataTable>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))}
            <div className="button-container">
                <div className="button-wrapper">
                    <button className="rework-btn" onClick={onClosePopUp}>{RejectionMessage.back}</button>
                    <button className="rework-btn" onClick={onSubmitReason}>{RejectionMessage.submit}</button>
                </div>
            </div>
        </div>
    );
};

export default ReasonForRework;
