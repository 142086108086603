import _ from "lodash";
import { checkIsNoneOptionChanged, compareArrayOfObjects } from "../utils";

export const convertArraysIntoCSV = (DMData) => {
  const data=_.cloneDeep(DMData)
  data.forEach((newRow)=>{
    if(newRow?.Regional_Design_Template?.length){
      let temp=[];
      newRow?.Regional_Design_Template?.forEach((obj)=>{
        temp.push(obj?.AWM_Design_Job_Name==="None" ? "" : obj.AWM_Design_Job_Name)
      })
      
      newRow.Regional_Design_Template=temp.toString()
    }

    if(newRow?.Ink_Qualification?.length){
      let temp=[];
      newRow?.Ink_Qualification?.forEach((obj)=>{
        temp.push(obj?.AWM_Design_Job_Name==="None" ? "" : obj.AWM_Design_Job_Name)
      })
      
      newRow.Ink_Qualification=temp.toString()
    }

    if(newRow?.Color_Development_and_Print_Trial?.length){
      let temp=[];
      newRow?.Color_Development_and_Print_Trial.forEach((obj)=>{
        temp.push(obj?.AWM_Design_Job_Name==="None" ? "" : obj.AWM_Design_Job_Name)
      })
      
      newRow.Color_Development_and_Print_Trial=temp.toString()
    }
  })
  return data
}
  
export const createPredecessorList = (option) => {
  let precedecessorList =[]
  option.added.length && option.added.forEach((obj)=>{
    precedecessorList.push({
      JobName: obj.AWM_Design_Job_Name,
      JobId: obj.AWM_Design_Job_ID,
      UserAction: "ADD"
    })
  })
  option.deleted.length && option.deleted.forEach((obj)=>{
    precedecessorList.push({
      JobName: obj.AWM_Design_Job_Name,
      JobId: obj.AWM_Design_Job_ID,
      UserAction: "DELETE"
    })
  })
  return precedecessorList;  
}

export const createManageDependencySaveDataDM = (tableData, submittedData) => {
  const clonedTableData = _.cloneDeep(tableData)
  const clonedSubmittedData = _.cloneDeep(submittedData)
  let isNoneOptionChanged = false;
  const saveData = {
    ManageDependencyPage: {
      SuccessorPredecessorList: []
    }
  }
  
  clonedSubmittedData.forEach((sdObj)=>{
    clonedTableData.forEach((obj)=>{
      if(obj?.DSBP_PMP_PIMaterialID === sdObj?.DSBP_PMP_PIMaterialID){
        sdObj["CICDetails"]=obj?.CICDetails || {}
        sdObj["Preselected_AWM_RDT_Page"]=obj?.Preselected_AWM_RDT_Page || []
        sdObj["Preselected_AWM_CDPT_Page"]=obj?.Preselected_AWM_CDPT_Page || []
        sdObj["Preselected_AWM_IQ_Page"]=obj?.Preselected_AWM_IQ_Page || []
        
      }
    })
  })
  
  clonedSubmittedData.forEach((data)=>{
    let temp = {
      Job: {},
      PrecedecessorList: []
    }
    
    let tempCICDetails = {};
    tempCICDetails["JobId"] = data?.CICDetails?.AWM_Design_Job_ID || "";
    tempCICDetails["JobName"] = data?.CICDetails?.AWM_Design_Job_Name || "";
    tempCICDetails["DSBP_InitiativeID"] = data?.DSBP_InitiativeID;
    tempCICDetails["DSBP_PMP_PIMaterialID"] = data?.DSBP_PMP_PIMaterialID;
    tempCICDetails["DSBP_PMP_PIMaterialNumber"] = data?.DSBP_PMP_PIMaterialNumber;
    tempCICDetails["DT_None"] = data?.Regional_Design_Template?.filter((obj)=>obj.AWM_Design_Job_ID.includes("DT_None")).length ? true : false;
    tempCICDetails["NPF_None"] = data?.Color_Development_and_Print_Trial?.filter((obj)=>obj.AWM_Design_Job_ID.includes("NPF_None")).length ? true : false;
    tempCICDetails["IQ_None"] = data?.Ink_Qualification?.filter((obj)=>obj.AWM_Design_Job_ID.includes("IQ_None")).length ? true : false;
    temp.Job=tempCICDetails

    const updatedRDT = compareArrayOfObjects(data?.Preselected_AWM_RDT_Page, data?.Regional_Design_Template, "AWM_Design_Job_ID")
    const updatedCDPT = compareArrayOfObjects(data?.Preselected_AWM_CDPT_Page, data?.Color_Development_and_Print_Trial, "AWM_Design_Job_ID")
    const updatedIQ = compareArrayOfObjects(data?.Preselected_AWM_IQ_Page, data?.Ink_Qualification, "AWM_Design_Job_ID")
    temp.PrecedecessorList.push(...createPredecessorList(updatedRDT))
    temp.PrecedecessorList.push(...createPredecessorList(updatedCDPT))
    temp.PrecedecessorList.push(...createPredecessorList(updatedIQ))

    saveData.ManageDependencyPage.SuccessorPredecessorList.push(temp);   
    isNoneOptionChanged = checkIsNoneOptionChanged(saveData, data?.Preselected_AWM_RDT_Page, data?.Preselected_AWM_CDPT_Page, data?.Preselected_AWM_IQ_Page); 
  })
  if(
    saveData.ManageDependencyPage.SuccessorPredecessorList[0].PrecedecessorList.length || 
    isNoneOptionChanged
  ){
    return {
      CICDependencyPage: saveData,
      SuccessorPredecessor: true
    }
  }else{
    return {
      CICDependencyPage: {},
      SuccessorPredecessor: false
    }
  }
}

export const createManageDependencySaveDataDMTabByTab = (selectedTab, rdt, cdpt, iq) => {
  let isNoneOptionChanged = false;
  const clonedTab = _.cloneDeep(selectedTab?.description)
  clonedTab["updatedRegionalDesignTemplate"] = rdt;
  clonedTab["updatedColorDevelopmentPrintTrial"] = cdpt;
  clonedTab["updatedInkQualification"] = iq;
  const saveData = {
    ManageDependencyPage: {
      SuccessorPredecessorList: []
    }
  }   
  let temp = {
    Job: {},
    PrecedecessorList: []
  }

  let tempCICDetails = {};
  tempCICDetails["JobId"] = clonedTab?.AWM_CICDetails?.AWM_Design_Job_ID || "";
  tempCICDetails["JobName"] = clonedTab?.AWM_CICDetails?.AWM_Design_Job_Name || "";
  tempCICDetails["DSBP_InitiativeID"] = clonedTab?.DSBP_InitiativeID;
  tempCICDetails["DSBP_PMP_PIMaterialID"] = clonedTab?.DSBP_PMP_PIMaterialID;
  tempCICDetails["DSBP_PMP_PIMaterialNumber"] = clonedTab?.DSBP_PMP_PIMaterialNumber;
  tempCICDetails["DT_None"] = clonedTab?.updatedRegionalDesignTemplate?.filter((obj)=>obj.AWM_Design_Job_ID.includes("DT_None")).length ? true : false;
  tempCICDetails["NPF_None"] = clonedTab?.updatedColorDevelopmentPrintTrial?.filter((obj)=>obj.AWM_Design_Job_ID.includes("NPF_None")).length ? true : false;
  tempCICDetails["IQ_None"] = clonedTab?.updatedInkQualification?.filter((obj)=>obj.AWM_Design_Job_ID.includes("IQ_None")).length ? true : false;
  temp.Job=tempCICDetails

  const updatedRDT = compareArrayOfObjects(clonedTab?.Regional_Design_Template, clonedTab?.updatedRegionalDesignTemplate, "AWM_Design_Job_ID")
  const updatedCDPT = compareArrayOfObjects(clonedTab?.Color_Development_and_Print_Trial, clonedTab?.updatedColorDevelopmentPrintTrial, "AWM_Design_Job_ID")
  const updatedIQ = compareArrayOfObjects(clonedTab?.Ink_Qualification, clonedTab?.updatedInkQualification, "AWM_Design_Job_ID")
  temp.PrecedecessorList.push(...createPredecessorList(updatedRDT))
  temp.PrecedecessorList.push(...createPredecessorList(updatedCDPT))
  temp.PrecedecessorList.push(...createPredecessorList(updatedIQ))

  saveData.ManageDependencyPage.SuccessorPredecessorList.push(temp);
  isNoneOptionChanged = checkIsNoneOptionChanged(saveData, clonedTab?.Regional_Design_Template, clonedTab?.Color_Development_and_Print_Trial, clonedTab?.Ink_Qualification);      
  if(
    saveData?.ManageDependencyPage?.SuccessorPredecessorList[0]?.PrecedecessorList?.length || 
    isNoneOptionChanged
  ){
    return {
      CICDependencyPage: saveData,
      SuccessorPredecessor: true
    }
  }else{
    return {
      CICDependencyPage: {},
      SuccessorPredecessor: false
    }
  }
}
