import React, { useState } from "react";
import { RejectionReason } from "../../constants/reworkPageTasks"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './index.scss';

function RejectReason(props) {
    const {
        MarkupList,
    } = props.cicCommentList;
    const { headingText } = props;
    const [markups] = useState(MarkupList || []);

    return (
        <>
            <div className="wrapperRejectReason">
                <p>{headingText}</p>
                <div>
                    {markups === undefined ? (
                        <h3>{RejectionReason.nomarkup}</h3>
                    ) : (
                        <div className="rejectionTable">
                            <DataTable value={markups}>
                                <Column field="index" header={RejectionReason.sNo} body={(data, options) => options.rowIndex + 1} />
                                <Column field="Markup" header={RejectionReason.markup} />
                                <Column field="DefectType" header={RejectionReason.defectType} />
                                <Column field="Reason" header={RejectionReason.reason} />
                                <Column field="ResponsibleFunctionRole" header={RejectionReason.responsible} />
                                <Column field="Comment" header={RejectionReason.comment} />
                            </DataTable>
                        </div>
                    )}
                </div>
            </div>

        </>
    );
}

export default RejectReason;
