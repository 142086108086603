import React from 'react';
import { TreeTable } from 'primereact/treetable';

function CommonTreeTable({
  scrollHeight,
  data,
  selectedNodeKeys,
  setSelectedNodeKeys,
  setExpandedKeys,
  expandedKeys,
  children,
  frozenWidth,
  onColumnResizeEnd,
  onSelectColumn,
  className,
  searchFilters,
  resetKey,
  resizeMode
}) {

  return (
    <TreeTable
      className={className}
      value={data}
      selectionMode="checkbox"
      selectionKeys={selectedNodeKeys}
      onSelectionChange={(e) => setSelectedNodeKeys(e.value)}
      expanded
      onColumnResizeEnd={onColumnResizeEnd}
      expandedKeys={expandedKeys}
      frozenWidth={frozenWidth}
      onToggle={(e) => setExpandedKeys(e.value)}
      // tableStyle={{
      //   minWidth: '90%',
      //   width: 'maxContent',
      //   minHeight: '30px',
      // }}
      scrollWidth="100%"
      columnResizeMode={resizeMode}
      resizableColumns
      reorderableColumns
      showGridlines
      scrollable
      rowClassName={onSelectColumn}
      filters={searchFilters}
      key={resetKey}
    >
      {children}
    </TreeTable>
  );
}

export default CommonTreeTable;
