import * as types from "./../types/types";

const initialState = {
  artworkStatusData: {},
  loading: true,
  error: null,
};

const ArtworkStatusReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ARTWORK_STATUS_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        artworkStatusData: payload,
      };
    case types.GET_ARTWORK_STATUS_DETAILS_SUCCESS:
      return {
        // ...state,
        artworkStatusData: payload,
        loading: false,
        error: null,
      };
    case types.GET_ARTWORK_STATUS_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default ArtworkStatusReducer;
