import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Row, Col } from "react-bootstrap";
import { Checkbox } from "primereact/checkbox";
import deleteIcon from "../../../assets/images/deleteIcon.svg";

import { useSelector } from "react-redux";
import { textFieldMaxLength } from "../../../constants/index";
import { CharacterLimit } from "../../common/CharacterLimit/CharacterLimit";
import { ToggleButton } from "../../ToggleButton/ToggleButton";
import ConfirmationDialogBox from "../../ConfirmDialog/ConfirmationDialogBox";
import { clearDefinedJobs } from "../../../apis/clearApi";
import { clearPopupMessage, deletePopupMessage } from "../../../constants/DesignJobsConstant";
import { checkStatusForDisplay } from "../../../utils";
import ControlledInput from "../../common/ControlledInput";

const AddNewDesignContent = ({
  index,
  Brand,
  Category,
  Project_Name,
  handleDelete,
  item,
  addData,
  roleName,
  checkBU,
  taskName,
  checkReadWriteAccess,
  disabledDelete,
  Assignee,
  AWMProjectID,
  AWMTaskID,
  Bu,
  Region,
  hideClearButton,
  handleDeleteJob,
  loadOnClearButton,
  isTaskComplete = false,
  setPageEdited,
  setInputValid

}) => {
  const {
    Agency_Reference,
    Tier,
    Additional_Info,
    event,
    Select,
    Cluster,
    Design_Job_ID,
    Design_Job_Name,
    Design_Job_Status,
    ReworkCounter,
  } = item;
  const [checked, setChecked] = useState(false);
  const [agencyRef, setAgency] = useState(Agency_Reference);
  const [clusters, setCluster] = useState(Cluster);
  const [additionalInformation, setAdditionalInfo] = useState(Additional_Info);
  const [tier, setTier] = useState(Tier);

  const [visible, setVisible] = useState(false);
  const [clearChecked, setClearChecked] = useState(false);
  const [buttonStatus, setButtonStatus] = useState("");

  // const User = useSelector((state) => state.UserReducer);
  // const userInformation = User.userInformation;
  // const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);
  // useEffect(() => {
  //   if (User && Assignee) {
  //     if (userInformation?.userid === Assignee) {
  //       setCheckReadWriteAccess(true);
  //     } else {
  //       setCheckReadWriteAccess(false);
  //     }
  //   }
  // }, [User, Assignee])

  useEffect(() => {
    if (item) {
      setAgency(Agency_Reference);
      setCluster(Cluster);
      setAdditionalInfo(Additional_Info);
    }
  }, [item, Agency_Reference, Cluster, Additional_Info]);

  useEffect(() => {
    setChecked(Select);
  }, [Select]);

  const handleClearButton = () => {
    setButtonStatus("Clear");
    setPageEdited(true);
    setVisible(true);
  };

  const handleDeleteButton = (index) => {
    if (Design_Job_Status === "") {
      handleDelete(index);
    } else if (Design_Job_Status === "Awaiting") {
      handleDeleteJob && handleDeleteJob(Design_Job_ID);
      handleDeleteJob && handleDelete(index);
    } else {
      setButtonStatus("Delete");
      setVisible(true);
    }
    // 3492 - Removing this state update as we are managing pageEdited elsewhere
    // setPageEdited(true);
  };

  const DesignHeader = (di_name) => {
    return (
      <>
        <div
          style={{
            marginLeft: 20,
            padding: 5,
            width: "92%",
            wordWrap: "break-word",
          }}
          className="font-color"
        >
          {checkStatusForDisplay(Design_Job_Status)
            ? Design_Job_Name
            : !di_name
            ? `${taskName} ${index + 1}`
            : di_name}
        </div>
        <div>
          {hideClearButton && (
            <ToggleButton
              id={index}
              title="Clear"
              disabled={isTaskComplete || !checkReadWriteAccess}
              onClick={() =>
                Design_Job_Status === "Defined" &&
                (ReworkCounter === null || ReworkCounter === false) &&
                checkReadWriteAccess &&
                handleClearButton()
              }
              classname={
                Design_Job_Status === "Defined" &&
                (ReworkCounter === null || ReworkCounter === false) &&
                checkReadWriteAccess
                  ? "clear-btn active"
                  : "clear-btn inactive"
              }
            />
          )}
        </div>
        <div>
          <img
            src={deleteIcon}
            alt="filter logo"
            onClick={() => checkReadWriteAccess && handleDeleteButton(index)}
            className="header-icons"
            disabled={isTaskComplete || !checkReadWriteAccess}
          />
        </div>
      </>
    );
  };

  let di_name;
  let clubBrandName = Brand?.length && Brand.map((item) => item.Brand_Name).join(", ");
  let clubCategory = Category?.length && Category.map((item) => item.Category_Name).join(", ");

  if (clubBrandName === "" || Brand === undefined) clubBrandName = "Brand";

  if (clubCategory === "" || Category === undefined) clubCategory = "Category";

  if (agencyRef || clusters || additionalInformation || tier) {
    di_name = `${roleName}${agencyRef ? "_" + agencyRef : ""}${
      clubBrandName ? "_" + clubBrandName : ""
    }${clubCategory ? "_" + clubCategory : ""}_${Project_Name}${clusters ? "_" + clusters : ""}${
      tier ? "_" + tier : ""
    }${additionalInformation ? "_" + additionalInformation : "_Additional Info"}`;
  } else {
    di_name = `${roleName}_Agency Ref_${clubBrandName}_${clubCategory}_${Project_Name}_Cluster _${
      Tier ? Tier : "Tier"
    }_ Additional Info ${index + 1}`;
  }
  let designDisabled = !(Design_Job_Status === "Awaiting" || Design_Job_Status === "");

  const handleContinueClear = async () => {
    const payLoad = {
      AWMProjectID: AWMProjectID,
      AWMTaskID: AWMTaskID,
      BU: Bu,
      Region: Region,
      DesignJobID: Design_Job_ID,
    };

    await clearDefinedJobs(payLoad);

    setChecked(false);
    setAgency("");
    setCluster("");
    setTier("");
    setAdditionalInfo("");
    setVisible(false);
    setClearChecked(true);
    setPageEdited(true);
    loadOnClearButton && loadOnClearButton();
  };

  const handleJobDelete = async () => {
    handleDeleteJob && handleDeleteJob(Design_Job_ID);
    handleDeleteJob && handleDelete(index);
    // setPageEdited(true);
    setVisible(false);
  };

  return (
    <div>
      <div className="design-intent-header">{DesignHeader(di_name)}</div>
      <Row
        style={{
          marginLeft: 15,
          marginRight: 15,
          marginBottom: 15,
          marginTop: 5,
        }}
      >
        <Col sm={1}>
          <label htmlFor="select"> Select</label>
          <div>
            <Checkbox
              onChange={(e) => {
                setPageEdited(true);
                !clearChecked && addData("Select", index, e.checked, di_name);
                setChecked(e.checked);
              }}
              checked={!clearChecked && (designDisabled || event === "submit") ? true : checked}
              disabled={
                !clearChecked && (!checkReadWriteAccess || designDisabled || isTaskComplete)
              }
              className="margin-right"
            ></Checkbox>
          </div>
        </Col>
        <Col sm={2}>
          <div className="d-flex flex-column">
            <label htmlFor="agency">Agency Reference * </label>
            {/* <InputText
              id="agency"
              maxLength={textFieldMaxLength}
              value={agencyRef}
              onChange={(e) => {
                const trimmedValue = e?.target?.value?.trim();
                if (trimmedValue || e?.target?.value === "") {
                  setPageEdited(true);
                  !clearChecked && addData("Agency_Reference", index, e.target.value, di_name);
                  setAgency(e.target.value);
                }
              }}
              className={
                agencyRef?.length === 0
                  ? "form-control"
                  : agencyRef?.length >= textFieldMaxLength
                  ? "form-control"
                  : "form-control border-secondary"
              }
              disabled={
                !clearChecked && (!checkReadWriteAccess || designDisabled || isTaskComplete)
              }
              aria-describedby="agency-help"
            /> */}
            <ControlledInput
            id="agency"
            disabled={
              !clearChecked && (!checkReadWriteAccess || designDisabled || isTaskComplete)
            }
            handleInputChange={(value) => {
              const trimmedValue = value?.trim();
              if (trimmedValue || value === "") {
                setPageEdited(true);
                !clearChecked && addData("Agency_Reference", index, value, di_name);
                setAgency(value);
              }
            } }
            setInputValid={setInputValid}
            value={agencyRef}
            maxLength={textFieldMaxLength}
            />
          </div>
          {(agencyRef === "" || agencyRef === undefined) && (
            <div className="error-text-di">Field Remaining</div>
          )}
          <CharacterLimit field={agencyRef} maxLength={textFieldMaxLength} />
        </Col>
        <Col sm={2}>
          <div className="d-flex flex-column">
            <label htmlFor="cluster">Cluster * </label>
            {/* <InputText
              id="cluster"
              maxLength={textFieldMaxLength}
              value={clusters}
              onChange={(e) => {
                const trimmedValue = e?.target?.value.trim();
                if (trimmedValue || e?.target?.value === "") {
                  setPageEdited(true);
                  !clearChecked && addData("Cluster", index, e.target.value, di_name);
                  setCluster(e.target.value);
                }
              }}
              className={
                clusters?.length === 0
                  ? "form-control"
                  : clusters?.length >= textFieldMaxLength
                  ? "form-control"
                  : "form-control border-secondary"
              }
              disabled={
                !clearChecked && (!checkReadWriteAccess || designDisabled || isTaskComplete)
              }
              aria-describedby="cluster-help"
            /> */}
             <ControlledInput
            id="cluster"
            disabled={
              !clearChecked && (!checkReadWriteAccess || designDisabled || isTaskComplete)
            }
            handleInputChange={(value) => {
              const trimmedValue = value.trim();
              if (trimmedValue || value === "") {
                setPageEdited(true);
                !clearChecked && addData("Cluster", index, value, di_name);
                setCluster(value);
              }
            } }
            setInputValid={setInputValid}
            value={clusters}
            maxLength={textFieldMaxLength}
            />
          </div>
          {(clusters === "" || clusters === undefined) && (
            <span className="error-text-di">Field Remaining</span>
          )}{" "}
          <CharacterLimit field={clusters} maxLength={textFieldMaxLength} />
        </Col>
        {checkBU && (
          <Col sm={2}>
            <div className="d-flex flex-column">
              <label htmlFor="tier">Tier </label>
              {/* <InputText
                id="tier"
                maxLength={textFieldMaxLength}
                value={tier}
                onChange={(e) => {
                  setPageEdited(true);
                  !clearChecked && addData("Tier", index, e.target.value, di_name);
                  setTier(e.target.value);
                }}
                disabled={
                  !clearChecked && (!checkReadWriteAccess || designDisabled || isTaskComplete)
                }
                aria-describedby="tier-help"
              /> */}
               <ControlledInput
            id="tier"
            disabled={
              !clearChecked && (!checkReadWriteAccess || designDisabled || isTaskComplete)
            }
            handleInputChange={(value) => {
              setPageEdited(true);
              !clearChecked && addData("Tier", index, value, di_name);
              setTier(value);
              
            } }
            setInputValid={setInputValid}
            value={tier}
            maxLength={textFieldMaxLength}
            />
            </div>
          </Col>
        )}

        <Col sm={2}>
          <div className="d-flex flex-column">
            <label htmlFor="additional">Additional Info </label>
            {/* <InputText
              id="additional"
              maxLength={textFieldMaxLength}
              value={additionalInformation}
              onChange={(e) => {
                const trimmedValue = e?.target?.value.trim();
                if (trimmedValue || e?.target?.value === "") {
                  setPageEdited(true);
                  !clearChecked && addData("Additional_Info", index, e.target.value, di_name);
                  setAdditionalInfo(e.target.value);
                }
              }}
              className={
                additionalInformation?.length === 0
                  ? "form-control"
                  : additionalInformation?.length >= textFieldMaxLength
                  ? "form-control"
                  : "form-control border-secondary"
              }
              disabled={
                !clearChecked && (!checkReadWriteAccess || designDisabled || isTaskComplete)
              }
              aria-describedby="info-help"
            /> */}
              <ControlledInput
            id="additional"
            disabled={
              !clearChecked && (!checkReadWriteAccess || designDisabled || isTaskComplete)
            }
            handleInputChange={(value) => {
              const trimmedValue = value.trim();
                if (trimmedValue || value === "") {
                  setPageEdited(true);
                  !clearChecked && addData("Additional_Info", index, value, di_name);
                  setAdditionalInfo(value);
                }
              
            } }
            setInputValid={setInputValid}
            maxLength={textFieldMaxLength}
            value={additionalInformation}
            />
          </div>
          <CharacterLimit field={additionalInformation} maxLength={textFieldMaxLength} />
        </Col>
      </Row>
      {buttonStatus === "Clear" && visible && (
        <ConfirmationDialogBox
          visible={visible}
          onHide={setVisible}
          message={<>{clearPopupMessage}</>}
          icon="pi"
          handleContinue={() => handleContinueClear()}
          handleCancel={() => {
            setVisible(false);
          }}
        />
      )}
      {buttonStatus === "Delete" && visible && (
        <ConfirmationDialogBox
          visible={visible}
          onHide={setVisible}
          message={<>{deletePopupMessage}</>}
          icon="pi"
          handleContinue={() => {
            handleJobDelete();
          }}
          handleCancel={() => {
            setVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default AddNewDesignContent;
