// @flow


const Entities = (selectedFieldData, headerName, availableFieldData) => {
  const header = headerName === "Dependency Mapping" ? true : false;
 
  if(header && selectedFieldData){
    selectedFieldData = selectedFieldData?.map((item) => ({
      Field_Name: item.field,
      width: item.width,
      freeze: item.freeze,
      Sequence: item.Sequence,
      group: item.group,
    }));
  }
  const transformFieldsData = (originalFieldsData) => {
    // Use map to extract "Field_Name" values
    const transformedData = originalFieldsData?.map(field => field.Field_Name);
    return transformedData;
  };

  // const transformedFieldsData = transformFieldsData(selectedFieldData);
  
  let taskMap;
  if(availableFieldData && selectedFieldData && !header){
    const mergedData = [...availableFieldData, ...selectedFieldData];
    taskMap = mergedData?.reduce((previous, current) => {
      previous[current.Field_Name] = current;
      return previous;
    }, {});
  } else{
    taskMap = selectedFieldData?.reduce((previous, current) => {
      previous[current.Field_Name] = current;
      return previous;
    }, {});
  }

  

  const availableFields = {
    id: "availableFields",
    title: "Available Fields",
    // fieldsData: alphabeticalSort(selectedFields)
    // .map((task) => task.Field_Name).sort((a,b)=>{
    //   if(a.Field_Name < b.Field_Name){
    //     return -1
    //   }if(a.Field_Name > b.Field_Name){
    //     return 1
    //   }
    //   return 0
    // }),
    fieldsData: availableFieldData ? transformFieldsData(availableFieldData) : [],
  };

  const selectedFields = {
    id: "selectedFields",
    title: "Selected Fields",
    fieldsData: selectedFieldData && transformFieldsData(selectedFieldData)
  };
  const freezedColumns = {
    id: "freezedColumns",
    title: "Freezed Columns",
    fieldsData: [],
  };

  return {
    columnOrder: [availableFields.id, selectedFields.id, freezedColumns.id],
    columns: {
      [availableFields.id]: availableFields,
      [selectedFields.id]: selectedFields,
      [freezedColumns.id]: freezedColumns,
    },
    tasks: taskMap,
  };
};

export default Entities;
