// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  font-size: 12px;
  font-weight: 300;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/tableFooter/index.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ","sourcesContent":[".footer {\n    font-size: 12px;\n    font-weight: 300;\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
