import Api from ".";
import { DEVURL, SITURL, PRODURL } from "./envUrl";

export const postSaveAsDraft = async (
  formData,
  headers = {
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Origin": "http://localhost:3000/",
    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
  }
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/saveCICMatrixUploadTask`;
  console.log("api url", apiURL);
  const designIntent = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });

  console.log("response", designIntent, formData);

  return true;
};

export const submitUploadCICMatrix = async (formData, id, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;

  apiURL = `${DEVURL}/uploadCICMatrix/${id}`;

  const submitUploadCICMatrix = await axiosInstance({
    url: apiURL,
    method: "PATCH",
    data: formData,
  });
  return submitUploadCICMatrix;
};

//to be removed once proofscope handles approval--start
export const submitProofScopeApprovalCICMATRIX = async (
  formData,
  taskKey,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  // let apiURL = `${DEVURL}/approveCICMatrix/${taskKey}`;
  // let apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/assignments/ASSIGN-WORKLIST ${taskKey}!APPROVECICMATRIX_FLOW/actions/ApproveCICMatrix`;
  let apiURL = `${DEVURL}/submitTaskApproveRDTProofscope`;
  // let apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/SubmitTask`;

  const response = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });

  console.log("response", response);

  return true;
};
//to be removed once proofscope handles approval--end
