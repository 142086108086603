export function getNumberOfWeeks(year, month) {
    const firstDayOfMonth = new Date(year, month - 1, 1);
    const lastDayOfMonth = new Date(year, month, 0);
    const numberOfDays = lastDayOfMonth.getDate();
    const numberOfWeeks = Math.ceil((numberOfDays + firstDayOfMonth.getDay()) / 7);
    return numberOfWeeks;
  }
  
  export const getCurrentWeekRange = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(startOfWeek.getDate() - dayOfWeek + 1);
    const endOfWeek = new Date(today);
    endOfWeek.setDate(endOfWeek.getDate() - dayOfWeek + 7);
    const startDateFormatted = startOfWeek.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    const endDateFormatted = endOfWeek.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    return `${startDateFormatted}-${endDateFormatted}`;
  };
  
  export const getWeekRange = () => {
      const year = new Date().getFullYear();
      const currMonth = new Date().getMonth() + 1;

     
      function getNumberOfWeeks(year, month) {
        const firstDayOfMonth = new Date(year, month - 1, 1);
        const lastDayOfMonth = new Date(year, month, 0);
        const numberOfDays = lastDayOfMonth.getDate();
        const numberOfWeeks = Math.ceil((numberOfDays + firstDayOfMonth.getDay()) / 7);
        return numberOfWeeks;
      }
    
      const numberOfWeeks = getNumberOfWeeks(year, currMonth);
      const weekRanges = [];
      const twoWeekRange = [];
      const newWeekRange = numberOfWeeks + 4;
    
      let startDate = new Date(year, currMonth - 1, 1);
      let dateValue = startDate;
    
      for (let i = 0; i < numberOfWeeks; i++) {
        let startOfWeek = new Date(dateValue);
        startOfWeek.setDate(dateValue.getDate() - dateValue.getDay() + 1);
        let endOfWeek = new Date(dateValue);
        endOfWeek.setDate(dateValue.getDate() - dateValue.getDay() + 7);
    
        weekRanges.push(
          `${startOfWeek.toLocaleDateString("en-US", { day: "numeric", month: "short" })}-` +
          `${endOfWeek.toLocaleDateString("en-US", { day: "numeric", month: "short" })}`
        );
    
        twoWeekRange.push(
          `${startOfWeek.toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
          })}-${endOfWeek.toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
          })}`
        );
    
        dateValue.setDate(dateValue.getDate() + 7);
      }
    
      for (let i = 0; i < newWeekRange; i++) {
        if (i == 0) {
          let startOfWeek = new Date(dateValue);
          startOfWeek.setDate(
            dateValue.getDate() - dateValue.getDay() + (dateValue.getDay() === 0 ? -6 : 1)
          );
          let endOfWeek = new Date(dateValue);
          endOfWeek.setDate(dateValue.getDate() - dateValue.getDay() + 7);
        }
    
        let startOfWeek = new Date(dateValue);
        startOfWeek.setDate(dateValue.getDate() - dateValue.getDay() + 1);
        let endOfWeek = new Date(dateValue);
        endOfWeek.setDate(dateValue.getDate() - dateValue.getDay() + 7);
    
        twoWeekRange.push(
          `${startOfWeek.toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
          })}-${endOfWeek.toLocaleDateString("en-US", {
            day: "numeric",
            month: "short",
          })}`
        );
    
        dateValue = endOfWeek;
      }
    
      // current week range
      const getCurrentWeekRange = () => {
        const today = new Date();
        const dayOfWeek = today.getDay();
        const startOfWeek = new Date(today);
        startOfWeek.setDate(startOfWeek.getDate() - dayOfWeek + 1);
        const endOfWeek = new Date(today);
        endOfWeek.setDate(endOfWeek.getDate() - dayOfWeek + 7);
        const startDateFormatted = startOfWeek.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        });
        const endDateFormatted = endOfWeek.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        });
        return `${startDateFormatted}-${endDateFormatted}`;
      };
    // current week range
  
    const currentWeekRangeValue = getCurrentWeekRange();
    const index = twoWeekRange.indexOf(currentWeekRangeValue);
    return [twoWeekRange[index + 2], twoWeekRange[index + 8]];
  };
  
  export const getWeekRanges = ( currMonth, currYear) => {
    const year = currYear;
   // const currMonth = new Date().getMonth() + 1;
    const weekRanges = [];
    const numberOfWeeks = getNumberOfWeeks(year, currMonth);
  
    let startDate = new Date(year, currMonth - 1, 1);
    let dateValue = startDate;
  
    for (let i = 0; i < numberOfWeeks; i++) {
      let startOfWeek = new Date(dateValue);
      startOfWeek.setDate(dateValue.getDate() - dateValue.getDay() + 1);
      let endOfWeek = new Date(dateValue);
      endOfWeek.setDate(dateValue.getDate() - dateValue.getDay() + 7);
  
      weekRanges.push(
        `${startOfWeek.toLocaleDateString("en-US", { day: "numeric", month: "short" })}-` +
          `${endOfWeek.toLocaleDateString("en-US", { day: "numeric", month: "short" })}`
      );
  
      dateValue.setDate(dateValue.getDate() + 7);
    }
  
    return weekRanges;
  };
  
  export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getLongMonth = (shortMonth)=>{
    let month = "";
    switch (shortMonth.toLowerCase()) {
        case "jan": month = "January"; break;
        case "feb": month = "February"; break;
        case "mar": month = "March"; break;
        case "apr": month = "April"; break;
        case "may": month = "May"; break;
        case "jun": month = "June"; break;
        case "jul": month = "July"; break;
        case "aug": month = "August"; break;
        case "sep": month = "September"; break;
        case "oct": month = "October"; break;
        case "nov": month = "November"; break;
        case "dec": month = "December"; break;
        }
    return month;   
  }

  export const getStartEndDateFromWeek =(weekRange)=>{
    const dates = weekRange.split('-');

    //['Jun 17', 'Jun 23'] eg
    // for the first value
    const startMonth = getLongMonth(dates[0].split(" ")[0]); //June
    const startMonthIndex = months.findIndex((ele)=> ele == startMonth);
    const startDay = dates[0].split(" ")[1];//16

    const startDate = new Date();
    startDate.setDate(startDay);
    startDate.setMonth(startMonthIndex);
    // endDate
    // for the second value

    const endMonth = getLongMonth(dates[1].split(" ")[0]);
    const endMonthIndex = months.findIndex((ele)=> ele == endMonth);
    const endDay = dates[1].split(" ")[1];

    const endDate = new Date();
    endDate.setDate(endDay);
    endDate.setMonth(endMonthIndex);

    return { startDate, endDate };
  }

  
  