/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../index.scss";

const TabsComponent = ({ items, tabName, setTabName, basePage, className, disabled, checkDraftChanges }) => {
  const navigate = useNavigate();
  let { ProjectID } = useParams();

  const handleClick = (item) => {
    if (ProjectID !== undefined) {
      navigate(`/${basePage}/${item}/${ProjectID}`);
      setTabName(item);
    } else {
      if (item !== "planning") {
        let isDraft = checkDraftChanges(item);
        if (!isDraft) {
          navigate(`/capacityManagement/${item}`);
          setTabName(item);
        }
      } else {
        navigate(`/capacityManagement/${item}`);
        setTabName(item);
      }
    }
  };

  return (
    <>
      <div className="tabComponent">
        <div className="tabs-wrapper">
          <ul id="scroller" className="nav nav-tabs">
            {items.map((obj, index) => (
              <li key={index + 1} className={`${obj.name === tabName ? "active" : ""}`}>
                <a data-toggle="tab" className={disabled ? 'disabled' : ''} onClick={() => disabled ? '' : handleClick(obj.name)} disabled={disabled}>
                  {obj.tabNameForDisplay}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={className === "booking-tabs" ? "tab-content booking-tabs" : "tab-content"}>
        {items.map((obj, index) => (
          <div
            key={index + 1}
            id={`${obj.name}`}
            className={`tab-pane fade ${obj.name === tabName ? "in active" : ""}`}
          >
            {obj.component}
          </div>
        ))}
      </div>
    </>
  );
};

export default TabsComponent;
