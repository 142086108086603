import React from "react";
import { ConfirmDialog } from "primereact/confirmdialog";

const AcceptBVEEndDateDialog = ({ visible, setVisible, selectedRows, onSubmit }) => {
  const setMessage = () => {
    if (selectedRows && selectedRows?.length) {
      return (
        <React.Fragment>
          {selectedRows?.map((rowData, index) => {
            return (
              <div>
                {" "}
                <span>{index + 1}</span> . <span> {rowData?.POANumber} </span>
              </div>
            );
          })}
        </React.Fragment>
      );
    } else return <div> Please select record</div>;
  };

  return (
    <ConfirmDialog
      visible={visible}
      message={setMessage}
      header="Do you want to accept the BVE End Date for following tasks?"
      icon="pi"
      accept={onSubmit}
      reject={() => setVisible(!visible)}
    />
  );
};

export default AcceptBVEEndDateDialog;
