import React from "react";
import PageLayout from "./components/PageLayout";
import ProjectSetup from "./components/ProjectSetup";


const ProjectCreation = () => {
  return(
  <div className="projectPlanActionsStyle">
    <PageLayout>
      <ProjectSetup />
    </PageLayout>
  </div>
)};
export default ProjectCreation;
