import _ from 'lodash';
import { Toast } from 'primereact/toast';
import FilesFolderCompo from './FilesFolderCompo';
import {
  updateProjectPlanAction,
  updateProjectStateAction,
  updateProjectPlanDesignAction,
} from '../../../store/actions/ProjectPlanActions';
import FilesFolderHeader from './FilesFolderHeader';
import { getDaysLeft } from '../../../Utils/LeftDays';
import { useSelector, useDispatch } from 'react-redux';
import { getRestructuredData, extractData } from './util';
import React, { useState, useEffect, useRef } from 'react';
import { getProjectPlan } from '../../../apis/projectPlanApi';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { setOwnerList } from '../../../store/actions/OwnerListActions';
import { usePriorityNew } from '../../../Utils/GetRolePriority';
import UnAuthorizedComponent from '../../UnAuthorized/UnAuthorizedComponent';

const FilesAndFolder = () => {
  let isFilterActivated = [];
  const toast = useRef(null);
  const isAccessEmpty = true;
  const childFunc = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  let { ProjectID } = useParams();
  const headerName = 'Files And Folder';
  const hasRoleAndAssignee = (item) => {
    return item.Role && item.Role.length > 0 && item.Assignee && item.Assignee.length > 0;
  };
  const locationPath1 = location?.pathname;

  const url = locationPath1?.split('/');
  const [filters, setFilters] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pegadata, setPegaData] = useState(null);
  const [isSearch, isSearchSet] = useState(false);
  const [activeFlag, setActiveFlag] = useState(true);
  const [activeSave, setActiveSave] = useState(true);
  const [isColWidthSet, setColWidth] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [projectState, setProjectState] = useState('');
  const [fieldUpdated, setFieldUpdated] = useState(false);
  const [selectedFields, setSelectedFields] = useState({});
  const [frozenUpdated, setFrozenUpdated] = useState(false);
  const [projectDataData, setProjectDataData] = useState([]);
  const [toggleButtons, setToggleButtons] = useState('Tabular');
  const [updatedDataFlag, setUpdatedDataFlag] = useState(false);
  let jsonColumnWidth = localStorage.getItem('filesAllColumnNames');
  const flatPegadata = pegadata?.flatMap((obj) => extractData(obj));
  const [projectColumnName, setProjectColumnNames] = useState(['']);
  const [initProjectPlanList, setInitProjectPlanList] = useState(null);
  const [projectPlanDesignData, setProjectPlanDesignData] = useState([]);
  const { myProject, allProjects } = useSelector((state) => state.myProject);
  const [columnWiseSelectedFields, setColumnWiseSelectedFields] = useState([]);
  const [updatedProjectPlanDesignData, setUpdatedProjectPlanDesignData] = useState([]);
  const { projectPlan, projectPlanDesign, mode } = useSelector((state) => state.ProjectPlanReducer);

  const accessSecurityMatrix = usePriorityNew(location.pathname.split('/')[1]);

  let allColumns = JSON.parse(jsonColumnWidth);
  const designInputData = projectPlanDesign.filter(
    (data) => data.GroupName !== 'Final Art' && !data?.AWM_Task_ID?.includes('CPC_')
  );
  const activateButton = designInputData.every(hasRoleAndAssignee);
  const firstTime = designInputData.some((item) => item.Assignee !== '' || item.Role !== '');

  if (allColumns) {
    isFilterActivated = allColumns.filter((ele) => {
      let width =
        ele.field_Name === 'Filename'
          ? 280
          : ele.field_Name === 'Version' || ele.field_Name === 'Owner' || ele.field_Name === 'Date'
          ? 100
          : ele.field_Name === 'FilePath'
          ? 120
          : 140;
      if (
        ele.freeze === true ||
        ele.sortAtoZ === true ||
        ele.sortZtoA === true ||
        ele.width !== width ||
        ele?.reorder === true ||
        (columnWiseSelectedFields && columnWiseSelectedFields.length !== 0)
      ) {
        return ele;
      }
    });
  }

  useEffect(() => {
    setUpdatedProjectPlanDesignData(projectPlanDesign);
  }, []);

  useEffect(()=>{
    handleExapandCollapseAllButton();
  },[pegadata])

  useEffect(() => {
    getProjectPlanApi();
  }, [mode]);

  useEffect(() => {
    if (projectState) {
      setProjectState(projectState);
    }
  }, [projectState]);

  useEffect(() => {
    if (!isAccessEmpty) {
      setActiveSave(true);
    }
  }, [isAccessEmpty]);

  useEffect(() => {
    if (projectPlanDesign) {
      setProjectDataData(projectPlanDesign[0]);
    }
  }, [projectPlanDesign]);

  useEffect(() => {
    if (updatedProjectPlanDesignData) {
      setProjectPlanDesignData(updatedProjectPlanDesignData || []);
    }
  }, [updatedProjectPlanDesignData]);

  useEffect(() => {
    if (updatedDataFlag && projectState !== 'Active') {
      setActiveFlag(false);
    } else if (
      !isAccessEmpty ||
      !updatedDataFlag ||
      projectPlan.length === 0 ||
      projectState === 'Active'
    ) {
      setActiveFlag(true);
    }
  }, [updatedDataFlag, projectState]);

  useEffect(() => {
    if (pegadata !== null && pegadata?.length !== 0) {
      const DesignAndInputData = updatedProjectPlanDesignData?.filter(
        (data) =>
          data.GroupName !== undefined &&
          data.GroupName !== 'Final Art' &&
          !data?.AWM_Task_ID?.includes('CPC_')
      );
      const hasRoleAndAssignee = (item) => {
        return item.Role && item.Role.length > 0 && item.Assignee && item.Assignee.length > 0;
      };
      const result = DesignAndInputData.every(hasRoleAndAssignee);
      setUpdatedDataFlag(result);
    }
  }, [updatedProjectPlanDesignData, updatedDataFlag]);

  useEffect(() => {
    if (projectState === 'Draft' && !firstTime) {
      setActiveSave(false);
    } else if ((projectState === 'Draft' && firstTime) || projectState === 'Active') {
      setActiveSave(true);
    }
    projectState === 'Draft' && firstTime && activateButton && setActiveFlag(false);
  }, [myProject, allProjects, projectPlan, isAccessEmpty, projectPlanDesign]);

  const breadcrumb = (
    <div>
      <nav className="p-breadcrumb p-component ProjectPlanBreadCrum" aria-label="Breadcrumb">
        <ul>
          <li className="">
            <NavLink to={`/${url[1]}`} className="p-menuitem-link">
              <span className="p-menuitem-text">{_.startCase(url[1])}</span>
            </NavLink>
          </li>
          <li className="p-breadcrumb-chevron pi pi-chevron-right piChevronRightMargin"></li>
          <li className="">
            <a href="#" className="p-menuitem-link">
              <span className="p-menuitem-text">{headerName}</span>
            </a>
          </li>
          <li>
            {mode !== 'create' && (
              <div className="project-name">{!loader && projectDataData?.Project_Name}</div>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );

  const clearColumnWiseFilter = () => {
    setFilters([]);
    setSelectedFields([]);
    setPegaData(initProjectPlanList);
  };

  const getProjectPlanApi = async () => {
    setLoader(true);
    let apiData = [];
    let restructuredData = [];

    if ((mode === 'edit' || mode === 'design') && ProjectID) {
      const getProjectPlanData = await getProjectPlan(ProjectID);
      if (getProjectPlanData?.responseStatus === 200) {
        if (typeof getProjectPlanData === 'object') {
          apiData = getProjectPlanData.projectPlanData;
          setProjectState(getProjectPlanData.projectState);
          dispatch(updateProjectStateAction(getProjectPlanData.projectState));
        } else {
          apiData = [];
        }
      } else {
        apiData = [];
      }
    } else {
      apiData = [];
    }
    apiData = apiData?.map((item) => ({
      ...item,
      Buffer: item?.Buffer?.toString(),
      Remaining_Work: item?.Remaining_Work?.toString(),
      Days_Left: item.State === 'In-Progress' ? getDaysLeft(item?.Start_Date,item?.TaskDuration) : 'N/A',
    }));

    const updatedApiData = apiData?.map((item) => {
      const formattedItem = { ...item };
      if (item.RoleOptions && item.RoleOptions.length === 1) {
        formattedItem.Role = item.RoleOptions[0].Name;
      }
      if (
        item.RoleOptions &&
        item.RoleOptions.length === 1 &&
        item.RoleOptions[0].OwnerOptionsNew &&
        item.RoleOptions[0].OwnerOptionsNew.length === 1
      ) {
        formattedItem.Owner = item.RoleOptions[0].OwnerOptionsNew[0].Name;
      }
      return formattedItem;
    });
    apiData && dispatch(updateProjectPlanDesignAction(apiData));
    restructuredData = updatedApiData?.length > 0 ? getRestructuredData(updatedApiData) : [];
    dispatch(updateProjectPlanAction(restructuredData));
    setLoader(false);

    const uniqueNamesSet = new Set();
    updatedApiData?.map((e) => {
      if (e.RoleOptions !== undefined) {
        e.RoleOptions.forEach((subarray) => {
          if (subarray.Name) {
            const uniqueKey = `${subarray.Name.trim()}_${JSON.stringify(subarray.OwnerOptionsNew)}`;
            uniqueNamesSet.add(uniqueKey);
          }
        });
      }
    });

    const ownerList = Array.from(uniqueNamesSet.values())?.map((uniqueKey) => {
      const [Name, arrayProperty] = uniqueKey.split('_');
      try {
        var OwnerOptionsNew = JSON.parse(arrayProperty);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
      return { Name, OwnerOptionsNew };
    });
    if (ownerList?.length) {
      dispatch(setOwnerList(ownerList));
    }
  };

  const handleExapandCollapseAllButton = () => {
    const collapseData = pegadata;
    if (expandedKeys.length === 0) {
      const allChildKeys = {};
      const expandChildren = (nodes) => {
        nodes.forEach((node) => {
          allChildKeys[node.key]=true;
          if (node.children && node.children.length > 0) {
            expandChildren(node.children);
          }
        });
      };
      collapseData?.map((item) => expandChildren(collapseData));
      setExpandedKeys(allChildKeys);
    } else {
      setExpandedKeys([]);
    }
  };

  const handleExapandCollapseAllFilter = () => {
    const allChildKeys = {};

    const expandChildren = (nodes) => {
      nodes?.forEach((node, index) => {
        allChildKeys[node.key]=true;
        if (node?.children !== 0) {
          expandChildren(node?.children);
        }
      });
    };

    expandChildren(pegadata);
    setExpandedKeys(allChildKeys);
  };

  return (
    <>
      {accessSecurityMatrix ? (
        <div className="projectSetupParent project-plan-wrapper newdiv">
          <Toast ref={toast} />
          <div className="breadCrumbParent">
            <div className="row">
              <div className="col">{breadcrumb}</div>
              <div className="col" style={{ display: 'flex' }}>
                {projectDataData !== undefined && (
                  <FilesFolderHeader
                    header=""
                    allData={flatPegadata}
                    projectPlanView={true}
                    filename="files_Records"
                    expandedKeys={expandedKeys}
                    handleExapandCollapseAllButton={handleExapandCollapseAllButton}
                  />
                )}
              </div>
            </div>
          </div>

          {toggleButtons === 'Tabular' && (
            <>
              <div className="action-buttons AccodianBtn">
                <></>
              </div>

              <FilesFolderCompo
                loader={loader}
                filters={filters}
                pegadata={pegadata}
                isSearch={isSearch}
                view={toggleButtons}
                childFunc={childFunc}
                setLoader={setLoader}
                activeFlag={activeFlag}
                activeSave={activeSave}
                setFilters={setFilters}
                setColWidth={setColWidth}
                setPegaData={setPegaData}
                firstTime={updatedDataFlag}
                expandedKeys={expandedKeys}
                fieldUpdated={fieldUpdated}
                isAccessEmpty={isAccessEmpty}
                frozenUpdated={frozenUpdated}
                setActiveFlag={setActiveFlag}
                setActiveSave={setActiveSave}
                access={accessSecurityMatrix}
                selectedFields={selectedFields}
                projectDataData={projectDataData}
                setExpandedKeys={setExpandedKeys}
                setFieldUpdated={setFieldUpdated}
                setFrozenUpdated={setFrozenUpdated}
                setSelectedFields={setSelectedFields}
                getProjectPlanApi={getProjectPlanApi}
                projectColumnName={projectColumnName}
                setProjectColumnNames={setProjectColumnNames}
                clearColumnWiseFilter={clearColumnWiseFilter}
                setInitProjectPlanList={setInitProjectPlanList}
                columnWiseSelectedFields={columnWiseSelectedFields}
                setColumnWiseSelectedFields={setColumnWiseSelectedFields}
                updatedProjectPlanDesignData={updatedProjectPlanDesignData}
                handleExapandCollapseAllFilter={handleExapandCollapseAllFilter}
                setUpdatedProjectPlanDesignData={setUpdatedProjectPlanDesignData}
              />
            </>
          )}
        </div>
      ) : (
        <UnAuthorizedComponent nowrapper={true} />
      )}
    </>
  );
};
export default FilesAndFolder;
