import React, { useEffect, lazy, Suspense, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useDispatch, useSelector } from "react-redux";
import { getTasks } from "../../../store/actions/TaskActions";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";
import { isRoleMatch } from "../../../utils";
import { useUnsavedChangesWarning } from "../../common/NavigationPrompt";

const TaskList = lazy(() => import("../TaskList"));

const MyTask = () => {
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const dispatch = useDispatch();
  const { myTasks, loading } = useSelector((state) => state.TaskReducer);
  const {
    userDetails: { UserGroup, AccessGroup },
  } = useSelector((state) => state.UserDetailsReducer);
  const [clearAllFilter, setClearAllFilter] = useState(false);
  // 3109 security matrix
  const [checkReadWriteAccess, setUserAccess] = useState(false);
  const { markDirty, markClean } = useUnsavedChangesWarning();

  const filteredTasks = myTasks
    ? myTasks.filter((task) => {
        const firstPart = task.AWM_Task_ID.split("_")[0];
        const excludedPrefixes = ["URDT", "UDAI", "UPRA"];
        const isExcludedTask = excludedPrefixes.includes(firstPart) && task.Status === "Complete";

        return (
          // (task.Status === "Available" || task.Status === "Complete") &&
          !isExcludedTask
        );
      })
    : [];

  useEffect(()=>{
    markClean();
    // writing this line because on loading the pages we need to clear unsaveChanges localstorage
  },[])  
  // 3109 security matrix
  useEffect(() => {
    const isUserAccess = isRoleMatch(AccessGroup, ["TaskOwner", "ExternalTaskOwner"]);
    setUserAccess(isUserAccess);
  }, [AccessGroup]);

  useEffect(() => {
    //load the api only when user has access as per 3109 security matrix

    if (UserGroup?.length && userInformation?.userid && checkReadWriteAccess) {
      const payLoad = {
        ArtworkAgilityPage: {
          PM: userInformation.userid,
          UserGroup: UserGroup,
        },
      };
      dispatch(getTasks(payLoad));
    }
  }, [dispatch, UserGroup?.length, userInformation?.userid, checkReadWriteAccess]);

  return (
    <>
      {checkReadWriteAccess ? (
        <Suspense fallback={<LoadingOverlay active={true} spinner text="" />}>
          <TaskList
            myTasks={filteredTasks}
            loading={loading}
            flag="myTasks"
            userInformation={userInformation}
            setClearAllFilter={setClearAllFilter}
          />
        </Suspense>
      ) : (
        AccessGroup && AccessGroup?.length && <UnAuthorizedComponent nowrapper={true} />
      )}
    </>
  );
};

export default MyTask;
