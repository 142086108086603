import { useSearchParams } from "react-router-dom";
import { hasRequiredAuthData, isOAuth2Disabled, redirectToAuth2Url } from "../Utils/helpers";
import { isEmpty } from "lodash";
import UnAuthorizedComponent from "../components/UnAuthorized/UnAuthorizedComponent";
import Login from "../auth/Login";

const ProtectedRoutes = () => {
  const isValid = hasRequiredAuthData()
  const [searchParams] = useSearchParams()

  const redirected = searchParams.get('redirected')
  const oauth2Error = searchParams.get('error')
  const access = searchParams.get('access')
  const isAuth = searchParams.get('auth')

  if (!isValid && isOAuth2Disabled()) {
    console.info('%c Oauth2 is disabled', 'background: #222; color: #bada55')
    return <Login />
  } if ((!isValid && isEmpty(oauth2Error)) || isAuth === false) {
    console.info('Redirecting to the auth url')
    return redirectToAuth2Url()
  } else if (access === 'denied' && redirected === 'true') {
    console.info('Redirected due to access denied')
    return <UnAuthorizedComponent />
  }
};

export default ProtectedRoutes;