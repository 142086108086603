import React from "react";
import "./ToggleButton.scss";

export const ToggleButton = ({ title, onClick, classname }) => {
  return (
    <div className="toggleButton">
      <button className={classname} onClick={onClick}>
        {title}
      </button>
    </div>
  );
};
