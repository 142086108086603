import { cloneDeep } from "lodash";

export const createDraftData = (data) => {
  let draft = cloneDeep(data);
  let draftData = draft?.map((el) => {
    el.isSelected = false;
    el.isDraft = true;
    return el;
  });
  return draftData;
};

export const createDraftChanges = (draftChanges) => {
  let draftData = [];
  draftChanges?.forEach((el) => {
    let temp = cloneDeep(el);
    temp.isSelected = true;
    temp.isDraft = false;
    draftData.push(temp);
  });
  return draftData;
};

export const findDraftTaskData = (acpPlanningData, taskDraftChanges, isTaskData) => {
  let initialtaskData = cloneDeep(acpPlanningData);
  if (taskDraftChanges?.length > 0) {
    //replace records in acpPlanningData where draft matches are found
    let draftData = [];
    taskDraftChanges?.forEach((el) => {
      let temp = cloneDeep(el);

      temp.isSelected = true;
      temp.isDraft = false;
      draftData.push(temp);
    });
    let allRecordsChanged =
      isTaskData?.length > 0 && isTaskData?.length > draftData?.length ? isTaskData : draftData;
    allRecordsChanged?.forEach((el) => {
      if (el.AWMTaskID === "") {
        //case of RTA task
        initialtaskData = initialtaskData?.map((ele) => {
          if (ele?.POANumber === el?.POANumber) {
            return el;
          } else {
            return ele;
          }
        });
      } else {
        //case of AWM task
        initialtaskData = initialtaskData?.map((ele) => {
          if (ele?.AWMTaskID === el?.AWMTaskID) {
            return el;
          } else {
            return ele;
          }
        });
      }
    });
  }
  const decided = taskDraftChanges?.length > 0 ? initialtaskData : acpPlanningData;
  return decided;
};

export const findDraftProjectData = (
  acpPlanningProjectListData,
  projectDraftChanges,
  isProjectData
) => {
  let initialProjectData = cloneDeep(acpPlanningProjectListData);
  if (projectDraftChanges?.length > 0) {
    //replace records in acpPlanningData where draft matches are found
    let draftData = [];
    projectDraftChanges?.forEach((el) => {
      let temp = cloneDeep(el);

      temp.isSelected = true;
      temp.isDraft = false;
      draftData.push(temp);
    });
    let allRecordsChanged =
      isProjectData?.length > 0 && isProjectData?.length > draftData?.length
        ? isProjectData
        : draftData;
    allRecordsChanged?.forEach((el) => {
      //project data
      initialProjectData = initialProjectData?.map((ele) => {
        if (ele.AWMProjectID === el.AWMProjectID) {
          return el;
        } else {
          return ele;
        }
      });
    });
  }
  let datawithDraftChanges =
    projectDraftChanges?.length > 0 ? initialProjectData : acpPlanningProjectListData;
  return datawithDraftChanges;
};
