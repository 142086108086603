import "./index.scss";
import _ from "lodash";
import { cloneDeep } from "lodash";
import { extractData } from "./util";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { TreeTable } from "primereact/treetable";
import ACPFilter from "../../ACP/common/ACPFilter";
import LoadingOverlay from "react-loading-overlay-ts";
import hyphen from "../../../assets/images/hyphen.svg";
import filter from "../../../assets/images/filter.svg";
import PDFViewer from "../../common/PDFViewer/PDFviewer";
import { AcpService } from "../../../service/ACPService";
import { ProjectService } from "../../../service/PegaService";
import { getFilterOptions } from "../../../Utils/GridService";
import downloadIcon from "../../../assets/images/downloadIcon.svg";
import { AcpCommonService } from "../../../service/AcpCommonService";
import { changeDateFormat, multiFilterChange, replaceSpecialChars } from "../../../utils";
import downloadPdfIcon from "../../../assets/images/downloadPdfIcon.svg";
import Versionhistory from "../../common/FileUploadManager/VersionHistory";
import { SortAndFilterService } from "../../../service/SortAndFilterService";
import { downloadAzureContainer } from "../../../apis/downloadAzureContainer";
import React, { useRef, useState, Suspense, useEffect, useCallback } from "react";

const JobList = ({
  view,
  loader,
  filters,
  pegadata,
  isSearch,
  sortData,
  childFunc,
  setFilters,
  setColWidth,
  JobListData,
  setPegaData,
  setSortData,
  expandedKeys,
  fieldUpdated,
  frozenUpdated,
  ProjectDetalis,
  selectedFields,
  setExpandedKeys,
  setFieldUpdated,
  projectDataData,
  setFrozenUpdated,
  clearGlobalFilter,
  setSelectedFields,
  projectColumnName,
  setProjectColumnNames,
  setInitProjectPlanList,
  isGlobalFilterActivated,
  columnWiseSelectedFields,
  setGlobalFilterActivated,
  setColumnWiseSelectedFields,
  handleExapandCollapseAllFilter,
  setUpdatedProjectPlanDesignData,
  resetKey
}) => {
  let today = new Date();
  const op = useRef(null);
  let minDate = new Date();
  const filterArray = useRef([]);
  minDate.setDate(today.getDate());
  const colAllSelected = useRef([]);
  const selectedFieldsRef = useRef([]);
  const [path, setPath] = useState("");
  const selectedColumnList = useRef([]);
  const [RowData, setRowData] = useState({});
  const [FileList, setFileList] = useState([]);
  const [ViewPDF, setViewPDF] = useState(false);
  const [headerName, setHeaderName] = useState("");
  const [frozenCoulmns, setFrozenColumn] = useState([]);
  const [frozenWidth, setFrozenWidth] = useState("0px");
  const [ViewModalPDF, setViewModalPDF] = useState(false);
  const [ProjectFrozen, setProjectFrozen] = useState(false);
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const [showApproveDialogCPPFA, setShowApproveDialogCPPFA] = useState(false);

  useEffect(() => {
    fetchData();
    saveSettings();
  }, []);

  useEffect(() => {
    const ProjectData = _.cloneDeep(JobListData);
    let allCol = [];
    if (ProjectData?.length) {
      allCol = Object.keys(ProjectData[0]);
    }
    let columnWidthProjectPlan = {};
    // if (allCol?.length) {
    //   allCol.forEach((column) => {
    //     columnWidthProjectPlan[column] = 100;
    //   });
    // }

    let getJsonStoredWidthColumns = localStorage.getItem("columnWidthProjectPlan");
    let getStoredWidthColumns = JSON.parse(getJsonStoredWidthColumns);
    const checkEmptyObject = _.isEmpty(getStoredWidthColumns);

    if (checkEmptyObject) {
      localStorage.setItem("columnWidthProjectPlan", JSON.stringify(columnWidthProjectPlan));
    }

    let jsonColWidth = localStorage.getItem("isColWidthSetMyProject");
    let isColWidthSetFlag = JSON.parse(jsonColWidth);
    if (isColWidthSetFlag) {
      setColWidth(true);
    }
  }, []);

  useEffect(() => {
    childFunc.current = clearColumnWiseFilter;
  }, []);

  useEffect(() => {
    pegadata && updateProjectPlanDesign();
  }, [pegadata]);

  useEffect(() => {
    setFrozenUpdated(frozenUpdated);
    frozenColumn();
  }, [frozenUpdated]);

  useEffect(() => {
    if (clearGlobalFilter) {
      saveSettings();
    }
  }, [clearGlobalFilter]);

  const saveSettings = () => {
    setFilters([]);
    setSelectedFields({});
    selectedFieldsRef.current = [];
    setGlobalFilterActivated(false);
    selectedColumnList.current = [];
    localStorage.setItem("sortingData", JSON.stringify(sortData));
    localStorage.setItem("columnWiseFilterData", JSON.stringify(filters));
    localStorage.setItem("frozenDataProjectPlan", JSON.stringify(frozenCoulmns));
    localStorage.setItem("filesAllColumnNames", JSON.stringify(projectColumnName));
  };

  async function fetchData() {
    try {
      let filteredPegaDataJson = localStorage.getItem("columnWiseFilterData");
      const filteredPegaData = JSON.parse(filteredPegaDataJson);
      if (filteredPegaData && filteredPegaData?.length) {
        setFilters(filteredPegaData);
        setPegaData(JobListData);
      } else {
        setPegaData(JobListData);
      }
      setInitProjectPlanList(JobListData);
      let columnNamesJson = localStorage.getItem("filesAllColumnNames");
      const storageColumnNames = JSON.parse(columnNamesJson);
      const columnNames = ProjectService.getFilesAllColumnNames();
      if (storageColumnNames != null && storageColumnNames?.length) {
        if (storageColumnNames.length === columnNames?.length) {
          let sortedData = [];
          sortedData = [...storageColumnNames].sort((a, b) => {
            return parseInt(a.sequence) - parseInt(b.sequence);
          });
          setProjectColumnNames(sortedData);
        } else {
          let sortedData = [];
          sortedData = [...columnNames].sort((a, b) => {
            return parseInt(a.sequence) - parseInt(b.sequence);
          });
          setProjectColumnNames(sortedData);
          localStorage.setItem("filesAllColumnNames", JSON.stringify(columnNames));
        }
      } else {
        const columnNames = ProjectService.getProjectPlanAllColumnNames();
        localStorage.setItem("filesAllColumnNames", JSON.stringify(columnNames));
        setProjectColumnNames(columnNames);
      }

      let jsonSortingData1 = localStorage.getItem("sortingData");
      const sortingData = JSON.parse(jsonSortingData1);

      if (sortingData && sortingData?.length) {
        const sortedData = [...JobListData].sort((a, b) => {
          return a[sortingData[0]] > b[sortingData[0]] ? 1 : -1;
        });

        if (sortingData[1] === "desc") {
          sortedData.reverse();
        }
        setPegaData(sortedData);
        setSortData([sortingData[0], sortingData[1]]);
      }

      let jsonFrozenrData1 = localStorage.getItem("frozenDataProjectPlan");
      const frozenDataProjectPlan = JSON.parse(jsonFrozenrData1);
      if (frozenDataProjectPlan && frozenDataProjectPlan?.length) {
        setFrozenColumn(frozenDataProjectPlan);
      }
    } catch (err) {
      console.log("error", err);
    }
  }

  const frozenColumn = () => {
    let jsonColumnWidth = localStorage.getItem("filesAllColumnNames");
    let allColumns = JSON.parse(jsonColumnWidth);
    const numFrozenColumns = allColumns.filter((col) => col?.freeze)?.length;
    const totalFrozenWidth = numFrozenColumns * 200;
    setFrozenWidth(`${totalFrozenWidth}px`);
  };

  const addFrozenColumns = (name) => {
    if (!frozenCoulmns?.includes(name)) {
      frozenCoulmns.push(name);
      setFrozenColumn(frozenCoulmns);
    } else {
      let columnIndex = frozenCoulmns.indexOf(name);
      frozenCoulmns.splice(columnIndex, 1);
      setFrozenColumn(frozenCoulmns);
    }
  };

  const storeReorderedColumns = (e) => {
    const dragColumnName = projectColumnName[e?.dragIndex];
    const index = projectColumnName.indexOf(dragColumnName);
    if (index > -1) {
      projectColumnName.splice(index, 1);
      projectColumnName.splice(e?.dropIndex, 0, dragColumnName);
    }
    projectColumnName.forEach((ele, index) => {
      ele.reorder = ele.field_Name === dragColumnName.field_Name;
      ele.sequence = index + 1;
    });

    localStorage.setItem("filesAllColumnNames", JSON.stringify(projectColumnName));
    setProjectColumnNames(projectColumnName);
  };

  const onColumnResizeEnd = (event) => {
    let columnWidth = [];
    let jsonColumnWidth = localStorage.getItem("filesAllColumnNames");

    if (jsonColumnWidth) {
      columnWidth = JSON.parse(jsonColumnWidth);
    }
    if (columnWidth) {
      columnWidth?.map((list) => {
        if (event.column.props.field === list.field_Name) {
          list.width = event.element.offsetWidth;
        }
      });
    }
    localStorage.setItem("filesAllColumnNames", JSON.stringify(columnWidth));
    setFieldUpdated(!fieldUpdated);
  };

  const updateProjectPlanDesign = () => {
    let arr = [];
    pegadata.forEach((obj) => {
      obj.children?.forEach((data) => {
        if (data?.children?.length === 0) {
          let tempObj = {};
          tempObj["AWM_Project_ID"] = projectDataData?.AWM_Project_ID;
          tempObj["AWM_Version"] = data.Version;
          tempObj["Date"] = data.data.Date;
          arr.push(tempObj);
        } else if (data?.children?.length > 0) {
          data.children.forEach((child) => {
            let tempObj = {};
            tempObj["AWM_Project_ID"] = projectDataData?.AWM_Project_ID;
            tempObj["AWM_Version"] = child.Version;
            tempObj["Date"] = child.data.Date;
            arr.push(tempObj);
            if (child?.children?.length > 0) {
              child.children.forEach((innerChild) => {
                let tempChildObj = {};
                tempChildObj["AWM_Project_ID"] = projectDataData?.AWM_Project_ID;
                tempChildObj["AWM_Version"] = innerChild.Version;
                tempChildObj["Date"] = innerChild.data.Date;
                arr.push(tempChildObj);
              });
            }
          });
        }
      });
    });
    setUpdatedProjectPlanDesignData(arr);
  };

  const onSort = (column, direction, filterData) => {
    const sortedData = filterData?.length ? filterData : filters?.length ? filters : pegadata;
    let sortedDataSet = [];
    if (column === "Filename") {
      sortedDataSet = SortAndFilterService.sortMultiLabelData(sortedData, column, direction);
    } else {
      sortedDataSet = sortedData.map((el) => {
        const sortedElement = SortAndFilterService.sortMutipleMultiLabel(el, column, direction);
        return sortedElement;
      });
    }
    setSortData([column, direction]);
    localStorage.setItem("sortingDataFilesAndFolders", JSON.stringify(true));
    if (filterData?.length) {
      setFilters(sortedDataSet);
      selectedColumnList.current = sortedDataSet;
    } else {
      setFilters(sortedDataSet);
    }
  };

  const onGlobalFilterChange = (e, colName) => {
    const { value, multiplesFlag, arr } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      pegadata,
      filterArray,
      selectedColumnList,
      colAllSelected,
      selectedFieldsRef
    );
    const _selectedFieldsRef = selectedFieldsRef?.current?.map((el, i) => {
      if (Object.keys(arr)?.[0] === Object.keys(el)?.[0]) {
        return {
          ...el,
          [`${selectedColumnName}`]: arr[selectedColumnName],
        };
      } else {
        return el;
      }
    });
    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      localStorage.removeItem("_selectedFieldsRefCurrentFilesAndFolders");
    }
    if (filterArray?.current?.length) {
      if ((value?.length > 0 && !multiplesFlag) || colAllSelected?.current?.length >= 1) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const _selectedFieldsRefWithUniqueObjects = AcpService.getUniqueObjects(
          _selectedFieldsRefWithoutEmptyArray
        );
        const convertedObj = AcpCommonService.convertArrayToObject(
          _selectedFieldsRefWithUniqueObjects
        );
        const uniqueObj = AcpCommonService.getUniqueValuesObject(convertedObj);
        if (Object.entries(uniqueObj)?.length) {
          setGlobalFilterActivated(true);
        } else {
          setGlobalFilterActivated(false);
        }
        let { Filename, ...newObj } = uniqueObj;
        let filteredData2 = [];
        let filteredData = [];
        if (Filename?.length) {
          const Filename2 = {
            Filename: Filename,
          };
          filteredData = SortAndFilterService.applyMutilabelFilter(pegadata, Filename2, filters);
        }
        if (Object.entries(newObj)?.length) {
          filteredData2 = SortAndFilterService.multipleColumnFilter(
            filteredData?.length ? filteredData : pegadata,
            newObj,
            filters
          );
        }
        setSelectedFields(uniqueObj);
        const finalFilteredData = filteredData2?.length ? filteredData2 : filteredData;
        if (finalFilteredData?.length && sortData?.length) {
          onSort(sortData?.[0], sortData?.[1], finalFilteredData);
        } else {
          setFilters(finalFilteredData);
          selectedColumnList.current = finalFilteredData;
        }
      } else {
        setFilters([]);
        setSelectedFields({});
        selectedFieldsRef.current = [];
        setGlobalFilterActivated(false);
        selectedColumnList.current = [];
      }
    } else {
      setFilters([]);
      setSelectedFields({});
      selectedFieldsRef.current = [];
      setGlobalFilterActivated(false);
      selectedColumnList.current = [];
    }
  };

  const clearColumnWiseFilter = () => {
    let _selectedColumnName = selectedColumnName;
    if (sortData && sortData?.length && sortData[0] === _selectedColumnName) {
      localStorage.removeItem("sortingDataArtworkStatus");
      setSortData([]);
    }

    const selectedFields2 = _.cloneDeep(selectedFields);
    const uniqueObj = AcpCommonService.getUniqueValuesObject(selectedFields2);
    const _selectedFields = AcpService.emptyProperty(uniqueObj, _selectedColumnName);
    const __selectedFields = AcpCommonService.removeEmptyArraysWithNoData(_selectedFields);
    selectedFieldsRef.current = __selectedFields;
    setSelectedFields(__selectedFields);
    if (AcpService.areAllPropertiesEmptyArrays(__selectedFields)) {
      localStorage.removeItem("_selectedFieldsRefCurrentFilesAndFolders");
    }
    if (Object.entries(__selectedFields)?.length) {
      setGlobalFilterActivated(true);
    } else {
      setGlobalFilterActivated(false);
    }
    let { Filename, ...newObj } = __selectedFields;
    let filteredData2 = [];
    let filteredData = [];
    if (Filename?.length) {
      const Filename2 = {
        Filename: Filename,
      };
      filteredData = SortAndFilterService.applyMutilabelFilter(pegadata, Filename2, filters);
    }
    if (Object.entries(newObj)?.length) {
      filteredData2 = SortAndFilterService.multipleColumnFilter(
        filteredData?.length ? filteredData : pegadata,
        newObj,
        filters
      );
    }
    const finalFilteredData = filteredData2?.length ? filteredData2 : filteredData;
    if (finalFilteredData?.length && Object.keys(__selectedFields)?.length) {
      setFilters(finalFilteredData);
    } else {
      setFilters([]);
    }
  };

  const viewPdf = (options, ViewPFURL) => {
    const filteredChildren = JobListData.filter((el) => el.key === options.parentKey);
    const jobName = filteredChildren?.[0]?.children.filter((el) => el.key === options.keyCode)?.[0]
      ?.data?.Filename;
    setHeaderName(jobName);
    setPath(ViewPFURL);
    setViewPDF(true);
  };

  const projectNameOnClick = (e, options) => {
    op?.current?.toggle(e);
    setSelectedColumnName(options);
  };

  const projectNameHeader = (options, col) => {
    const optionsCode = getFilterOptions(options);
    return (
      <div className="flex-display" key={options}>
        <img
          src={filter}
          alt="Column Filter"
          key={options}
          onClick={(e) => projectNameOnClick(e, options)}
          className={
            sortData?.includes(options) ||
              selectedFields?.[options]?.[0] ||
              selectedFields?.[options]?.[0] === false ||
              frozenCoulmns?.includes(options)
              ? "columnFilterIcon filter-color-change"
              : "columnFilterIcon"
          }
        />
        <span
          className={
            sortData?.includes(options) ||
              selectedFields?.[options]?.[0] ||
              selectedFields?.[options]?.[0] === false ||
              frozenCoulmns?.includes(options)
              ? "columnFilterIcon filter-color-change"
              : "columnFilterIcon"
          }
        >
          {optionsCode}
        </span>
      </div>
    );
  };

  const flatPegadata = pegadata?.flatMap((obj) => extractData(obj));

  const downloadPdf = (event, options, fileUrl, folderStructure) => {
    event.preventDefault();
    downloadAzureContainer(fileUrl, folderStructure);
  };

  const handleClickVersion = (options) => {
    const filteredChildren = JobListData.filter((el) => el.key === options.parentKey);
    if (
      (filteredChildren?.[0]?.data?.Filename?.includes("RDT") &&
      (options?.data?.Filename?.includes("pdf") || options?.data?.Filename?.includes("ai"))) ||
      (filteredChildren?.[0]?.data?.Filename?.includes("CIC") &&
      (options?.data?.Filename?.includes("pdf") || options?.data?.Filename?.includes("ai")))
    ) {
      setViewModalPDF(true);
    } else {
      setViewModalPDF(false);
    }
    const jobName = filteredChildren?.[0]?.children.filter((el) => el.key === options.keyCode)?.[0]
      ?.data?.Filename;
    const finalFileList = options?.data?.VersionList?.map((el) => {
      return {
        ...el,
        Date: changeDateFormat(el?.Date),
        Owner: (
          <div>
            {el?.Owner} <br /> {el?.Email}
          </div>
        ),
      };
    });
    setRowData(options);
    setHeaderName(jobName);
    setFileList(finalFileList);
    setShowApproveDialogCPPFA(true);
  };

  const dynamicColumns = () => {
    let jsonColumnWidth = localStorage.getItem("filesAllColumnNames");
    let allColumns = JSON.parse(jsonColumnWidth);

    if (allColumns && allColumns?.length) {
      return allColumns?.map((ele, i) => {
        return (
          <Column
            filter={isSearch}
            alignFrozen="left"
            frozen={ele.freeze}
            key={ele.field_Name}
            showFilterMenu={false}
            body={elementTemplate}
            field={ele.field_Name}
            filterMatchMode="contains"
            filterField={ele.field_Name}
            columnKey={ele.field_Name || i}
            filterPlaceholder={ele.field_Name}
            expander={ele.field_Name === "Filename"}
            header={projectNameHeader(ele.field_Name, ele)}
            className={ele?.field_Name === "Filename" ? "" : "cursorMove non-task-width"}
            style={{
              minWidth: "100px"
            }}
          />
        );
      });
    }
  };

  const elementTemplate = (options, rowData) => {
    const field = rowData.field;
    const optionsData = options.data;
    const Version = options.data?.Version;
    const filename = options?.data?.Filename;
    const filteredChildren = JobListData.filter((el) => el.key === options.parentKey);
    const JobName = filteredChildren?.[0]?.children.filter((el) => el.key === options.keyCode)?.[0]
      ?.data?.Filename;
    const Subfolder = filteredChildren?.[0]?.data?.Filename;
    // Folders to check

    // API response filepath for checking
    const filePath = optionsData?.Filepath; // No need to lowercase or trim, since it's used only in folder checking
    const targetFolders = ["TechSpecXML", "CICMappingXML", "TD", "TasKXML", "XFDF"];

    // Check if the file has an `.xfdf` extension
    const isXfdfFile = filename?.toLowerCase().endsWith(".xfdf");

    // Find the folder that exists in the file path
    const matchedFolder =
      targetFolders.find((folder) => {
        const folderPattern = new RegExp(`\\b${folder}\\b`, "i");
        return folderPattern.test(filePath);
      }) || "";

    // Set the folder to XFDF if the file has the XFDF extension
    const finalFolder = isXfdfFile ? "XFDF" : matchedFolder;

    // Dynamically append the folder path if condition matches
    const dynamicPath = finalFolder
      ? `${ProjectDetalis?.BU}/${ProjectDetalis?.Region}/${ProjectDetalis?.ProjectID?.toUpperCase()} ${ProjectDetalis?.ProjectName}/${Subfolder}/${Subfolder === "CPPFA" ? Subfolder : JobName}/${finalFolder}/${Version}/`
      : `${ProjectDetalis?.BU}/${ProjectDetalis?.Region}/${ProjectDetalis?.ProjectID?.toUpperCase()} ${ProjectDetalis?.ProjectName}/${Subfolder}/${Subfolder === "CPPFA" ? Subfolder : JobName}/${Version}/`;

    return (
      <>
        {field === "Filename" && (
          <span
            className={`${optionsData?.State === "Awaiting"
              ? ""
              : options?.children?.length === 0
                ? "task-links"
                : "task"
              } ${field === "Filename" && "custom-toggler-wrapper"}`}
            title={replaceSpecialChars(optionsData?.[field])}
          >
            {replaceSpecialChars(optionsData[field])}
          </span>
        )}
        {options.children && options?.children?.length > 0 ? (
          <>
            {(field === "Filenames" ||
              field === "Actions" ||
              field === "Versions" ||
              field === "Owners" ||
              field === "Dates") && (
                <img
                  src={hyphen}
                  alt="Hyphen"
                  onClick={(e) => {
                    op.current.toggle(e);
                  }}
                />
              )}
          </>
        ) : (
          <>
            {field === "Action" && (
              <div className="action-wrapper">
                {options?.key && options?.keyCode && options?.parentKey ? (
                  <>
                    <img
                      className="download_icon"
                      src={downloadIcon}
                      alt="downloadicon"
                      onClick={(e) => downloadPdf(e, options, filename, dynamicPath)}
                    />
                    {(Subfolder?.includes("RDT") && (optionsData?.["Filename"]?.includes("pdf") || optionsData?.["Filename"]?.includes("ai"))) ||
                      (Subfolder?.includes("CIC") && (optionsData?.["Filename"]?.includes("pdf") || optionsData?.["Filename"]?.includes("ai"))) ? (
                      <img
                        alt="viewpdficon"
                        src={downloadPdfIcon}
                        className="view_pdf_icon"
                        onClick={() => viewPdf(options, options?.data?.ViewPFURL)}
                      />
                    ) : (
                      <div style={{ minWidth: "16px" }}> </div>
                    )}
                  </>
                ) : null}
              </div>
            )}
            {field === "Version" && (
              <a
                title={optionsData["Version"]}
                className={"version"}
                onClick={() => {
                  if (true) {
                    handleClickVersion(options);
                  }
                }}
              >
                {optionsData["Version"]}
              </a>
            )}
            {field === "Owner" && (
              <div className="owner">
                {optionsData["Owner"]} <br /> {optionsData["Email"]}
              </div>
            )}
            {field === "Date" && (
              <div className="date">{changeDateFormat(optionsData["Date"])}</div>
            )}
          </>
        )}

        {field !== "Filename" &&
          field !== "Action" &&
          field !== "Version" &&
          field !== "Owner" &&
          field !== "Date" && <>{optionsData[field]}</>}
      </>
    );
  };
  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }

    const expanded = options.expanded;
    const iconClassName = `p-treetable-toggler-icon pi pi-fw ${expanded ? "pi-minus" : "pi-plus"}`;

    return (
      <button
        type="button"
        tabIndex={-1}
        title
        onClick={options.onClick}
        style={{
          ...options.buttonStyle,
          border: "2px solid #ababab",
          borderRadius: "0%",
          padding: "7px",
          height: "0",
          width: "0",
        }}
        className="p-treetable-toggler p-link"
      >
        <span className={iconClassName} aria-hidden="true"></span>
      </button>
    );
  };

  const sortedFileList = FileList?.sort((a, b) => b?.Version - a?.Version);

  const sanitizeFileName = (fileName) => {
    return replaceSpecialChars(fileName);
  }

  const splitFileList = sortedFileList?.map((file) => ({
    ...file,
    Filename: file?.Filename
      ? sanitizeFileName(file?.Filename?.split("\\")?.pop())
      : undefined,
  }));


  return (
    <div className=" jobListTable myProjectAnddAllProjectList">
      <Dialog
        maximizable
        visible={ViewPDF}
        header={headerName}
        style={{ width: "80vw" }}
        onHide={() => setViewPDF(false)}
        className="version-history-dialog"
        headerClassName="version-history-dialog-header"
      >
        <div>
          <PDFViewer path={path} width={"100%"} height={"600px"} />
        </div>
      </Dialog>
      {showApproveDialogCPPFA && (
        <Versionhistory
          RowData={RowData}
          fileList={splitFileList}
          headerName={headerName}
          JobListData={JobListData}
          ViewModalPDF={ViewModalPDF}
          ProjectDetalis={ProjectDetalis}
          showVersionList={showApproveDialogCPPFA}
          onHide={() => setShowApproveDialogCPPFA(!showApproveDialogCPPFA)}
        />
      )}
      <Suspense fallback={<LoadingOverlay active={true} spinner text="" />}>
        {view === "Tabular" && (
          <>
            <ACPFilter
              op={op}
              onSort={onSort}
              isTreeTable={true}
              sortData={sortData}
              pegadata={pegadata}
              setFilters={setFilters}
              setSortData={setSortData}
              projectData={flatPegadata}
              saveSettings={saveSettings}
              pageName={"FilesAndFolders"}
              frozenCoulmns={frozenCoulmns}
              selectedFields={selectedFields}
              selectAllColumnNotIncluded={true}
              selectedColumnName={selectedColumnName}
              onGlobalFilterChange={onGlobalFilterChange}
              clearColumnWiseFilter={clearColumnWiseFilter}
            />
            <TreeTable
              expanded
              //scrollable
              loading={loader}
              resizableColumns
              dataKey="Filename"
              scrollWidth="100%"
              reorderableColumns
              columnResizeMode="fit"
              frozenWidth={frozenWidth}
              expandedKeys={expandedKeys}
              togglerTemplate={togglerTemplate}
              onColReorder={storeReorderedColumns}
              onColumnResizeEnd={onColumnResizeEnd}
              onToggle={(e) => setExpandedKeys(e.value)}
              value={filters?.length ? filters : pegadata}
              className={`textAlignTreeTable ${isSearch ? "searchWithTreeTable" : ""}`}
              key={resetKey}
            >
              {dynamicColumns()}
            </TreeTable>
          </>
        )}
      </Suspense>
    </div>
  );
};

export default JobList;
