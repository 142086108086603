import React from 'react';
import filter from '../../assets/images/filter.svg';

const ArtworkStatusProjectNameHeader = (options, frozenCoulmns, sortData, projectNameOnClick) => {
  let splittedCol = options?.split('_').join(' ');
  const isFilterActivated =
    (frozenCoulmns && frozenCoulmns.length && frozenCoulmns.includes(options)) ||
    (sortData && sortData.length && sortData[0] === options);

  return (
    <div style={{ paddingRight: 5 }}>
      <>
        <img
          src={filter}
          alt="Column Filter"
          onClick={(e) => projectNameOnClick(e, options)}
          className={
            isFilterActivated ? 'columnFilterIcon filter-color-change' : 'columnFilterIcon'
          }
        />
        <span className={isFilterActivated && 'filter-color-change'}>
          {splittedCol === 'PIMaterialNumber' && 'PIMaterialNumber'}
          {splittedCol === 'CountryOfSales' && 'Country Of Sales'}
          {splittedCol === 'FAAssemblyState' && 'FA Assembly'}
          {splittedCol === 'FAApproval' && 'FA Approval (A/A/R)'}
          {splittedCol === 'PIMaterialID' && 'PIMaterialID'}
          {splittedCol === 'PIMaterialDescription' && 'PIMaterialDescription'}
          {splittedCol === 'POANumber' && 'POA Number'}
          {splittedCol === 'POADescription' && 'POA Description'}
          {splittedCol === 'BufferToWorkRatio' && 'B:W'}
          {splittedCol === 'BufferToWork' && 'B|W'}
          {splittedCol === 'POAReleaseDate' && 'ETA'}
          {splittedCol === 'AWPrinterDate' && 'Req'}
          {splittedCol === 'AWM GroupPMP' && 'Group'}
          {splittedCol === 'AWM CICNeeded' && 'CIC'}
          {splittedCol === 'ApprovedCopyElements' && 'Copy'}
          {/* FA */}
          {splittedCol === 'POAStatus' && 'POA Status'}
          {splittedCol === 'TDState' && 'TD State'}
          {splittedCol === 'PMPState' && 'PMP State'}

          {splittedCol !== 'PIMaterialNumber' &&
            splittedCol !== 'PIMaterialID' &&
            splittedCol !== 'PIMaterialDescription' &&
            splittedCol !== 'POANumber' &&
            splittedCol !== 'POADescription' &&
            splittedCol !== 'BufferToWorkRatio' &&
            splittedCol !== 'BufferToWork' &&
            splittedCol !== 'POAReleaseDate' &&
            splittedCol !== 'AWPrinterDate' &&
            splittedCol !== 'AWM GroupPMP' &&
            splittedCol !== 'AWM CICNeeded' &&
            splittedCol !== 'CountryOfSales' &&
            splittedCol !== 'FAAssemblyState' &&
            splittedCol !== 'FAApproval' &&
            splittedCol !== 'ApprovedCopyElements' &&
            splittedCol !== 'POAStatus' &&
            splittedCol !== 'TDState' &&
            splittedCol !== 'PMPState' &&
            splittedCol}
        </span>
      </>
    </div>
  );
};

export default ArtworkStatusProjectNameHeader;
