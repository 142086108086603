/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { uploadtoAzureStrContainer } from "../../../apis/uploadAzuerContainer";
import { submitCPPFA } from "../../../store/actions/taskDetailAction";
import { changeDateFormat } from "../../../utils";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getTasks } from "../../../store/actions/TaskActions";
import "./index.scss";
import { getDaysLeft } from "../../../Utils/LeftDays";
import FileUploadManager from "../../common/FileUploadManager/FileUploadManager";
import useAzureUploadFolderStructure from "../../../hooks/useAzureUploadFolderStructure";
import { totalFileSizeQuotient } from "../../../constants/FileUploadConstant";
import { COMPLETE, INPROGRESS, LEVEL } from "../../../constants/index";
import { AzureContainerFail, AzureContainerSuccess } from "../../../Utils/constants";

import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus } from "../../../utils";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";
import { getReadWriteAccessForTask, getUserAccessForTask } from "../../../Utils/helpers";


const CPPFA = ({
  showTaskDialog,
  selectedTaskData,
  onClose,
  pegadata,
  TaskDetailsData,
  userInformation,
  getProjectPlanApi,
  isProjectComplete = false,
}) => {
  const toast = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const locationPath = location?.pathname;

  const url = locationPath?.split("/");
  const [loader, setLoader] = useState(true);

  const [visible, setVisible] = useState(showTaskDialog);
  const [taskDetailsDataObj, setTaskDetailsDataObj] = useState(null);
  const [newPrintFeasibilityFlag, setNewPrintFeasibilityFlag] = useState(null);
  const [version, setVersion] = useState(0);
  const [azureFileUploadMsg, setAzureFileUploadMsg] = useState(false);
  const [leftDays, setDaysLeft] = useState();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [pageEdited, setPageEdited] = useState(false);
  const [daysLeftStyle, setDaysLeftStyle] = useState();
  const User = useSelector((state) => state.UserReducer);
  // const userInformation = User.userInformation;

  const [accessSecurityMatrix, setUserAccess] = useState(false);
  const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);

  const folderStructure = useAzureUploadFolderStructure("CPPFA", version);

  useEffect(()=>{
    if(TaskDetailsData){
      setUserAccess(getUserAccessForTask(TaskDetailsData));
      setCheckReadWriteAccess(getReadWriteAccessForTask(TaskDetailsData));
      setLoader(false);
    }
   
  },[TaskDetailsData])

  useEffect(() => {
    if (User && taskDetailsDataObj) {
      if (userInformation?.userid === taskDetailsDataObj?.Assignee && !isProjectComplete) {
        setCheckReadWriteAccess(true);
      } else {
        setCheckReadWriteAccess(false);
      }
    }
  }, [User, taskDetailsDataObj, isProjectComplete]);

  useEffect(() => {
    if (
      taskDetailsDataObj?.ReworkCounter !== null &&
      taskDetailsDataObj?.Task_Status === "In-Progress"
    )
      setRiskLevelFunc("");
    setNPFremove("");
    setNeedCppfa("");
  }, [taskDetailsDataObj?.ReworkCounter]);

  // shivu

  const user_group = useSelector((state) => state.UserDetailsReducer.userDetails.UserGroup);
  const payLoad = {
    ArtworkAgilityPage: {
      PM: userInformation.userid,
      UserGroup: user_group,
    },
  };

  const fullpageInfo = useSelector((state) => state.TaskDetailsReducer.TaskDetailsData);

  // let allAccess = hasAllAccess();
  const allAccess = true;
  let isAccessEmpty = !allAccess;

  const dispatch = useDispatch();

  const { ProjectID } = selectedTaskData;
  const [cppfaDialogFlag, setCppfaDialogFlag] = useState(false);

  useEffect(()=>{
    if(taskDetailsDataObj){
      setLoader(false);
    }
  },[taskDetailsDataObj])

  useEffect(() => {
    setAzureFileUploadMsg(false);
    if (TaskDetailsData) {
      setTaskDetailsDataObj(TaskDetailsData?.ArtworkAgilityTasks?.[0] || {});
      setNewPrintFeasibilityFlag(
        TaskDetailsData?.ArtworkAgilityPage?.New_Print_Feasibility || false
      );
    }
    if (taskDetailsDataObj) {
      taskDetailsDataObj?.FileMetaDataList?.find((el) => {
        if (el.Version !== "" && el.Version !== null) {
          setVersion(el.Version);
        }
      });
      pegadata.find((el) => {
        if (
          (el.AWM_Project_ID === ProjectID &&
            el.Task === "Define Color Development & Print Trial") ||
          (el.AWM_Project_ID === ProjectID &&
            (el.Task_Name === "Define New Print Feasibility scope" ||
              el.Task_Name === "Confirm Preliminary Print Feasibility Assessment"))
        ) {
          setCppfaDialogFlag(true);
        }
      });
    }
  }, [TaskDetailsData]);

  useEffect(() => {
    let date =
      taskDetailsDataObj?.Task_Status === INPROGRESS && taskDetailsDataObj?.Duration !== ""
        ? taskDetailsDataObj?.Start_Date !== "" &&
          getDaysLeft(taskDetailsDataObj?.Start_Date, taskDetailsDataObj?.Duration)
        : taskDetailsDataObj?.Duration !== ""
        ? "N/A"
        : "";
    setDaysLeft(date);
    calculateDaysLeftStyle();
  }, [taskDetailsDataObj?.Start_Date, taskDetailsDataObj?.Duration, daysLeftStyle, leftDays]);

  const calculateDaysLeftStyle = () => {
    const leftDaysStyle = leftDays <= 0 ? "red" : "";
    setDaysLeftStyle(leftDaysStyle);
  };

  const hideDialog = () => {
    setVisible(false);
    onClose();
  };
  const [riskLevel, setRiskLevel] = useState("");
  const [highRiskYesOrNo, setHighRiskYesOrNo] = useState("");
  const [yesOrNo, setYesOrNo] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [needCppfa, setNeedCppfa] = useState("");
  const [npfremove, setNPFremove] = useState("");
  const [isError, setIsError] = useState(false);

  const setRiskLevelFunc = (level) => {
    setIsError(false);
    setRiskLevel(level);
    const data = { ...taskDetailsDataObj };
    data.RiskLevel = level;
    setTaskDetailsDataObj(data);
    setHighRiskYesOrNo("");
    setYesOrNo("");
    setNPFremove("");
    setNeedCppfa(""); // Reset needCppfa state when the risk level changes
    setAzureFileUploadMsg(false);
  };

  const [fileName, setFileName] = useState(null);
  const [formattedValue, setFormattedValue] = useState(null);
  const [azureFile, setAzureFile] = useState(null);

  const toggle = (e) => {
    let selectedVal = "";
    if (e.target.innerText === "Yes") {
      setYesOrNo("Yes");
      if (newPrintFeasibilityFlag) {
        setNeedCppfa("");
        setNPFremove(true);
      } else {
        setNeedCppfa(true);
        setNPFremove("");
      }
      selectedVal = "true";
      setIsError(false);
    } else if (e.target.innerText === "No") {
      setYesOrNo("No");
      setNeedCppfa("");
      setNPFremove("");
      selectedVal = "false";
      setIsError(false);
    } else {
      setYesOrNo("");
      setNeedCppfa("");
      setNPFremove("");
      selectedVal = "";
    }
  };

  const styles = {
    activeBg: { background: "#003da5", color: "#fff" },
    disabledBlock: {
      opacity: "0.5",
      pointerEvents: "none",
    },
  };
  const onFileUpload = (e, FileDetails) => {
    setFileName(e?.files?.[0]?.name);
    setFormattedValue(e?.files?.[0]?.size);
    setAzureFile(e?.files?.[0]);
    setVersion(FileDetails?.Version);
    setFullName(FileDetails?.Full_Name);
    setEmail(FileDetails?.Email);
  };

  const onFileClear = () => {
    setFileName(null);
    setFormattedValue(null);
    setAzureFile(null);
    setFullName("");
    setEmail("");
  };

  const [flag, setFlag] = useState(false);
  useEffect(() => {
    if (pegadata !== undefined) {
      pegadata?.forEach((obj) => {
        if (
          obj?.data?.Task === "Define New Print Feasibility Scope" ||
          obj?.data?.Task === "Define Color Development & Print Trial"
        ) {
          setFlag(true);
        }
      });
    } else return;
  }, [pegadata]);

  useEffect(() => {
    if (taskDetailsDataObj?.RiskLevel !== undefined) {
      setRiskLevel(taskDetailsDataObj?.RiskLevel?.toLowerCase());
    }

    if (TaskDetailsData?.ArtworkAgilityPage?.New_Print_Feasibility) {
      if (
        (riskLevel === "low" && yesOrNo !== "") ||
        riskLevel === "high" ||
        riskLevel === "medium"
      ) {
        setEnableSubmit(true);
      } else {
        setEnableSubmit(false); // Reset enableSubmit if conditions are not met
      }
    } else {
      if (
        riskLevel === "low" ||
        ((riskLevel === "high" || riskLevel === "medium") && yesOrNo !== "")
      ) {
        setEnableSubmit(true);
      } else {
        setEnableSubmit(false); // Reset enableSubmit if conditions are not met
      }
    }
  }, [taskDetailsDataObj, flag, showTaskDialog, riskLevel, yesOrNo]);

  const handleSubmit = async (event) => {
    setLoader(true);

    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };
    const fileSize = Math.round(formattedValue / totalFileSizeQuotient);

    const formData = {
      caseTypeID: "PG-AAS-Work-ConfirmPreliminaryPrintFeasibilityAssessment",
      content: {
        RiskLevel: riskLevel.charAt(0).toUpperCase() + riskLevel.slice(1),
        NPFNeeded:
          (riskLevel === "high" || riskLevel === "medium" || riskLevel === "low") && needCppfa,
        NPFRemove:
          (riskLevel === "high" || riskLevel === "medium" || riskLevel === "low") && npfremove,
        // cppfaDialogFlag ? String(false) : String(yesOrNo === "yes"),
        AWMTaskID: selectedTaskData.TaskID,
        AWMProjectID: ProjectID,
        Size: azureFile?.File_Name || fileName ? (fileSize === 0 ? "1" : fileSize) : null,
        Version: version,
        Filename: fileName,
        Filepath: "",
        Full_Name: fullName,
        Email: email,
        TypeOfFile: fileName
          ? fileName?.split(".")?.pop()
          : azureFile?.File_Name?.split(".")?.pop(),
        JobID: "",
        JobName: "",
      },
    };
    let res = "";
    if (azureFile) {
      res = await uploadtoAzureStrContainer(azureFile, folderStructure);
      if (res) {
        if (toast.current) {
          toast.current.show(AzureContainerSuccess);
        }
      } else {
        if (toast.current) {
          toast.current.show(AzureContainerFail);
        }
      }
    }

    if (res === "" || res === true) {
      await submitCPPFA(
        formData,
        `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Key}`,
        headers
      );
      setFileName(null);
      if (url[2] === "projectPlan") {
        getProjectPlanApi();
      } else if (url[1] === "myTasks") {
        await dispatch(getTasks(payLoad));
      }
      setAzureFileUploadMsg(false);
      setLoader(false);
    } else {
      setAzureFileUploadMsg(true);
      setLoader(false);
    }
    hideDialog();
  };
  const newPrintFeasibility = taskDetailsDataObj?.New_Print_Feasibility;

  const showPopupValidation =
    (riskLevel === "low" && newPrintFeasibility) ||
    ((riskLevel === "high" || riskLevel === "medium") && !newPrintFeasibility);

  return (
    <Dialog
      visible={visible}
      className="ppfaDialog"
      onHide={hideDialog}
      header={
        <div>
          <div>
            <nav className="p-breadcrumb p-component" aria-label="Breadcrumb">
              <ul>
                <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>
                <li className="">
                  <NavLink to={`/${url[1]}`} className="p-menuitem-link">
                    <span className="p-menuitem-text">
                      {url[1].toLowerCase() === "myprojects"
                        ? "My Projects"
                        : url[1].toLowerCase() === "mytasks"
                        ? "My Tasks"
                        : url[1].toLowerCase() === "alltasks"
                        ? "All Tasks"
                        : "All Projects"}
                    </span>
                  </NavLink>
                </li>
                <li className="p-breadcrumb-chevron pi pi-chevron-right piChevronRightMargin"></li>
                {url.length > 2 ? (
                  <>
                    <li className="">
                      <NavLink to={`/${url[1]}/${url[2]}/${ProjectID}`} className="p-menuitem-link">
                        <span className="p-menuitem-text">
                          {url[2].toLowerCase() === "projectplan" ? "Project Plan" : "All Projects"}
                        </span>
                      </NavLink>
                    </li>
                    <li className="p-breadcrumb-chevron pi pi-chevron-right piChevronRightMargin"></li>
                  </>
                ) : (
                  ""
                )}
                <li className="">
                  <a href="#" className="p-menuitem-link">
                    <span className="p-menuitem-text">
                      Preliminary Print Feasibility Assessment
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="p-dialog-header1">{taskDetailsDataObj?.Project_Name}</div>
        </div>
      }
    >
      {loader?
      (<LoadingOverlay active={true} spinner text="" className="overlay-loader popup-details"/>):
        accessSecurityMatrix?
        <>
          <div className="p-fluid popup-details ppfaDialogBorder">
            <div className="p-field">
              <Row>
                <Col>Duration (Days)</Col>
                <Col>Start Date</Col>
                <Col>End Date</Col>
                <Col>Left Days</Col>
              </Row>
              <Row>
                <Col>{taskDetailsDataObj?.Duration}</Col>
                <Col>{changeDateFormat(taskDetailsDataObj?.Start_Date)}</Col>
                <Col>{changeDateFormat(taskDetailsDataObj?.End_Date)}</Col>
                <Col style={{ color: daysLeftStyle }}>{leftDays}</Col>
              </Row>
              <br />
              <Row>
                <Col>Risk Level*</Col>
              </Row>
              <Row>
                <Col className="col-3 select-one">
                  <div className="cppfa-radio-buttons">
                    <input
                      type="radio"
                      id="html"
                      name="fav_language"
                      value="low"
                      checked={riskLevel === "low"}
                      onChange={(e) => setRiskLevelFunc(e.target.value)}
                      disabled={
                        isAccessEmpty ||
                        taskDetailsDataObj?.Task_Status === COMPLETE ||
                        !checkReadWriteAccess || isProjectComplete
                      }
                    />
                    <label className="radioLabel">Low Risk</label>
                  </div>
                  <div className="cppfa-radio-buttons">
                    <input
                      type="radio"
                      id="html"
                      name="fav_language"
                      value="medium"
                      checked={riskLevel === "medium"}
                      onChange={(e) => setRiskLevelFunc(e.target.value)}
                      disabled={
                        isAccessEmpty ||
                        taskDetailsDataObj?.Task_Status === COMPLETE ||
                        !checkReadWriteAccess || isProjectComplete
                      }
                    />
                    <label className="radioLabel">Medium Risk</label>
                  </div>
                  <div className="cppfa-radio-buttons">
                    <input
                      type="radio"
                      id="html"
                      name="fav_language"
                      value="high"
                      checked={riskLevel === "high"}
                      onChange={(e) => setRiskLevelFunc(e.target.value)}
                      disabled={
                        isAccessEmpty ||
                        taskDetailsDataObj?.Task_Status === COMPLETE ||
                        !checkReadWriteAccess || isProjectComplete
                      }
                    />
                    <label className="radioLabel">High Risk</label>
                  </div>
                  {showPopupValidation ? (
                    <Col
                      style={
                        taskDetailsDataObj?.Task_Status === COMPLETE ? styles.disabledBlock : {}
                      }
                    >
                      <div className="highRiskDataColor highRiskData">
                        <div>
                          Print Feasibility Assessment is{" "}
                          <b>{riskLevel.charAt(0).toUpperCase() + riskLevel.slice(1)}</b> Risk, but
                          there is {newPrintFeasibilityFlag ? "" : "no"} Color Development in the
                          project scope. Do you want to {newPrintFeasibilityFlag ? "remove" : "add"}{" "}
                          Color Development from the project scope?
                        </div>
                        <div className="cpp-fa-button">
                          <button
                            type="button"
                            className={`btn btn-sm ${
                              yesOrNo === "No" ? "btn-primary" : "btn-secondary"
                            }`}
                            onClick={(event) => toggle(event)}
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className={`btn btn-sm ${
                              yesOrNo === "Yes" ? "btn-primary" : "btn-secondary"
                            }`}
                            onClick={(event) => toggle(event)}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </Col>
                  ) : null}
                </Col>
                <Col className="col-9">
                  <FileUploadManager
                    taskdata={
                      taskDetailsDataObj?.DesignJobDetails?.[0]?.FileMetaDataList !== undefined
                        ? taskDetailsDataObj?.DesignJobDetails?.[0]?.FileMetaDataList
                        : ""
                    }
                    onFileUpload={onFileUpload}
                    onFileClear={onFileClear}
                    subFolder="CPPFA"
                    pageEdited={pageEdited}
                    setPageEdited={setPageEdited}
                    taskStatus={taskDetailsDataObj?.Task_Status}
                    checkReadWriteAccess={checkReadWriteAccess || isProjectComplete}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="highRiskError" hidden={!isError ? true : false}>
                  *Please select Yes/No in order to proceed further.
                </Col>
              </Row>
            </div>
          </div>

          <div className="p-dialog-footer confirmPPFA">
            <ul>
              {LEVEL?.map((item, index) => (
                <li key={index}>
                  <b>{item.LEVEL}</b>
                  {item.DESCRIPTION}
                </li>
              ))}
            </ul>
            {taskDetailsDataObj?.Task_Status === COMPLETE ? (
              <Button label="Confirm PPFA" onClick={(event) => handleSubmit(event)} disabled />
            ) : (
              <Button
                label="Confirm PPFA"
                onClick={(event) => handleSubmit(event)}
                disabled={
                  isAccessEmpty || azureFileUploadMsg || riskLevel === "" || !enableSubmit // Add this condition to disable the button when neither Yes nor No is selected
                }
              />
            )}
          </div>
        </> : ( <UnAuthorizedComponent nowrapper={true}/>)}
    </Dialog>
  );
};

export default CPPFA;
