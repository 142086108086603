import Api from '.';
import { DEVURL, SITURL, PRODURL } from './envUrl';

export const submitUploadApproveDesignIntent = async (formData, id, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;
  //https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/assignments/ASSIGN-WORKLIST PG-AAS-WORK U-5708!UPLOADAPPROVEDESIGNINTENT_FLOW/actions/UploadApproveDesignIntent

  apiURL = `${DEVURL}/uploadApproveDesignIntent/${id}`;

  const submitUploadApproveDesignIntentData = await axiosInstance({
    url: apiURL,
    method: 'PATCH',
    data: formData,
  });
  // if (submitUploadApproveDesignIntentData?.data?.ID) {
  //   App.dispatchToStore(editProjectAction(formData));
  // }
  return submitUploadApproveDesignIntentData;
};

export const submitUploadRegionalDesignIntent = async (formData, id, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;

  apiURL = `${DEVURL}/uploadRegionalDesignTemplate/${id}`;

  const submitUploadRegionalDesignIntentData = await axiosInstance({
    url: apiURL,
    method: 'PATCH',
    data: formData,
  });
  // if (submitUploadApproveDesignIntentData?.data?.ID) {
  //   App.dispatchToStore(editProjectAction(formData));
  // }
  return submitUploadRegionalDesignIntentData;
};

export const submitRejectionReasonsARDT = async (formData, id, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;

  // apiURL = `${DEVURL}/rejectionReasons/${id}`;
  // apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/assignments/ASSIGN-WORKLIST ${id}!REJECTREGIONALDESIGNTEMPLETE_FLOW/actions/RejectionReasons`;
  // apiURL = `${DEVURL}/submitTaskApproveRDTProofscope`;
  apiURL = `${DEVURL}/submitTask`;
  // apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/SubmitTask`;

  const rejectionReasonsARDTResponse = await axiosInstance({
    url: apiURL,
    method: 'POST',
    data: formData,
  });

  return rejectionReasonsARDTResponse;
};

export const submitRejectionReasonsACIC = async (formData, id, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;

  // apiURL = `${DEVURL}/rejectionReasonsApproveCIC/${id}`;
  // apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/assignments/ASSIGN-WORKLIST ${id}!RejectCIC_Flow/actions/RejectionReasons`;
  apiURL = `${DEVURL}/submitTask`;
  // apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/SubmitTask`;

  const rejectionReasonsCICResponse = await axiosInstance({
    url: apiURL,
    method: 'POST',
    data: formData,
  });

  return rejectionReasonsCICResponse;
};

export const submitRejectionReasonsACICMATRIX = async (formData, id, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;

  // apiURL = `${DEVURL}/rejectionReasonsApproveCICMatrix/${id}`;
  // apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/assignments/ASSIGN-WORKLIST ${id}!RejectCICMatrix_Flow/actions/RejectionReasons`;
  apiURL = `${DEVURL}/submitTask`;
  // apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/SubmitTask`;

  const rejectionReasonsCICResponse = await axiosInstance({
    url: apiURL,
    method: 'POST',
    data: formData,
  });

  return rejectionReasonsCICResponse;
};

export const submitUploadProductionReadyArt = async (formData, id, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;

  apiURL = `${DEVURL}/uploadPRA/${id}`;

  const submitUploadProductionReadyArtData = await axiosInstance({
    url: apiURL,
    method: 'PATCH',
    data: formData,
  });
  // if (submitUploadApproveDesignIntentData?.data?.ID) {
  //   App.dispatchToStore(editProjectAction(formData));
  // }
  return submitUploadProductionReadyArtData;
};
