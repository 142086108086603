import Api from '.';
import { changeDateFormat } from '../utils';
import { DEVURL } from './envUrl';

export const getArtworkStatusList = async (ProjectID,payload, headers = {}) => {
  let apiURL = `${DEVURL}/getArtworkStatus/${ProjectID}`;

  const api = new Api();
  const axiosInstance = await api.init({ headers });

  const addResponse = await axiosInstance({
    url: apiURL,
    method: 'POST',
    data: payload
  });

  if (addResponse.status === 200){
    if(addResponse.data?.PMPList?.length) {
      addResponse.data.PMPList = addResponse.data.PMPList?.map((item) => ({
        ...item,
        AWPrinterDate: changeDateFormat(item.AWPrinterDate),
        POAReleaseDate: changeDateFormat(item.POAReleaseDate),
        Design: item?.DesignFlag,
        CIC: item?.CICFlag,
        FA: item?.FAFlag,
        Copy: item?.ApprovedCopyElements + "/" + item?.TotalCopyElements
      }));
    }
  }

  let response = addResponse?.data;
  return response;
};

//API call to show  CIC Full Kit data
export const getEachFullKitConditionValidation = async (
  ProjectID,
  BU,
  Region,
  DSBP_InitiativeID,
  PIMaterialID,
  PIMaterialNumber,
  taskId,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/eachFullKitConditionValidation/${ProjectID}/${BU}/${Region}/${DSBP_InitiativeID}/${PIMaterialID}/${PIMaterialNumber}/${taskId}`;

  const addResponse = await axiosInstance({
    url: apiURL,
    method: 'GET',
  });
  let response = addResponse?.data?.FullkitChecklist;
  return response;
};
//API call to show  FA Full Kit data
export const getEachFAFullKitConditionValidation = async (
  ProjectID,
  BU,
  Region,
  DSBP_InitiativeID,
  PIMaterialID,
  PIMaterialNumber,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/getEachFAFullKitConditionValidation/${ProjectID}/${BU}/${Region}/${DSBP_InitiativeID}/${PIMaterialID}/${PIMaterialNumber}`;

  const addResponse = await axiosInstance({
    url: apiURL,
    method: 'GET',
  });
  let response = addResponse?.data?.FullkitChecklist;
  return response;
};
