import React, { useEffect, useState } from "react";
import filter from "../../assets/images/filter.svg";
import { MultiSelect } from "primereact/multiselect";
import { OverlayPanel } from "primereact/overlaypanel";
import { optionList } from "../../utils";
import BlueFilterIcon from "../../assets/images/BlueFilterIcon.svg";

const ProjectListFilter = ({
  op,
  onSort,
  filters,
  pageName,
  sortData,
  projectData,
  saveSettings,
  frozenCoulmns,
  ProjectFrozen,
  selectedFields,
  setTableRender,
  setYCoordinate,
  setXCoordinate,
  setProjectFrozen,
  addFrozenColumns,
  projectColumnName,
  selectedColumnName,
  setReorderedColumn,
  onGlobalFilterChange,
  setAlignFrozenColumn,
  clearColumnWiseFilter,
  setProjectColumnNames,
}) => {
  const optionList1 = optionList(projectData, selectedColumnName);
  const optionTotalCopy = optionList(projectData, "TotalCopyElements");

  if (selectedColumnName === "ApprovedCopyElements") {
    optionList1[0] = `${optionList1[0]}/${optionTotalCopy[0]}`;
    optionList1[1] = `${optionList1[1]}/${optionTotalCopy[1]}`;
  }
  const [columnWiseSelectedFields, setColumnWiseSelectedFields] = useState([]);

  const isFilterActivated =
    (frozenCoulmns && frozenCoulmns.length && frozenCoulmns.includes(selectedColumnName)) ||
    (sortData && sortData.length) ||
    (filters && filters.length);

  let dateFormattedColNames = [
    "Estimated_AW_Printer",
    "Estimated_SOP",
    "Estimated_AW_Readiness",
    "Estimated_SOS",
  ];

  useEffect(() => {
    if (selectedFields && selectedColumnName) {
      setColumnWiseSelectedFields(selectedFields[selectedColumnName]);
    }
  }, [selectedFields, selectedColumnName]);

  // useEffect(() => {
  //   setYCoordinate = setYCoordinate + 8;
  //   const root = document.documentElement;
  //   root.style.setProperty("--setXCoordinate", `${setXCoordinate}px`);
  //   root.style.setProperty("--setYCoordinate", `${setYCoordinate}px`);
  // }, [setXCoordinate, setXCoordinate]);

  const mutilSelectTemplate = (option) => {
    return <div>{dateFormattedColNames.includes(selectedColumnName) ? option : option}</div>;
  };

  const confirmPopData = () => {
    return (
      <div>
        <div className="clearAllFilterMainDiv">
          <div
            id="clearAllFilter"
            className="clearAllFilter"
            onClick={() => clearColumnWiseFilter()}
          >
            Clear all filter
          </div>
          <div className="clearAllFilterDiv">
            {isFilterActivated ? (
              <img
                alt="filter logo"
                src={BlueFilterIcon}
                className="header-icons"
                onClick={() => clearColumnWiseFilter()}
              />
            ) : (
              <img
                src={filter}
                alt="filter logo"
                className="header-icons"
                onClick={() => clearColumnWiseFilter()}
              />
            )}
          </div>
        </div>
        <div id="sortZtoA" className="sortAndFrozen" onClick={onSort(selectedColumnName, "desc")}>
          {sortData &&
          sortData.length &&
          sortData[0] === selectedColumnName &&
          sortData[1] === "desc" ? (
            <div style={{ color: "#003DA5" }}> Sort z to a </div>
          ) : (
            <div> Sort z to a</div>
          )}
        </div>
        <div
          id="sortAtoZ"
          className="sortAndFrozen"
          onClick={
            // saveSettings();
            // changeColor("sortAtoZ");
            onSort(selectedColumnName, "asc")
          }
        >
          {sortData &&
          sortData.length &&
          sortData[0] === selectedColumnName &&
          sortData[1] === "asc" ? (
            <div style={{ color: "#003DA5" }}> Sort a to z </div>
          ) : (
            <div> Sort a to z</div>
          )}
        </div>
        {/* <div
          id="frozen"
          className="sortAndFrozen"
          onClick={() => {
            addFrozenColumns(selectedColumnName);
            setProjectFrozen(!ProjectFrozen);
            // changeColor("frozen");
            saveSettings();
            projectColumnName &&
              setAlignFrozenColumn(
                frozenCoulmns,
                setReorderedColumn,
                setProjectColumnNames,
                setTableRender,
                op,
                projectColumnName,
                pageName
              );
          }}
        >
          {frozenCoulmns.includes(selectedColumnName) ? (
            <div style={{ color: "#003DA5" }}>Frozen </div>
          ) : (
            <div> Freeze</div>
          )}
        </div> */}
        <div className="multiSelect">
          <MultiSelect
            filter
            maxSelectedLabels={3}
            className="p-column-filter"
            itemTemplate={mutilSelectTemplate}
            panelClassName="filterPopoverWidth"
            placeholder={`Select ${selectedColumnName}`}
            options={optionList1?.length === 0 ? "" : optionList1}
            onChange={(e) => onGlobalFilterChange(e, selectedColumnName)}
            value={optionList1?.length === 0 ? "" : columnWiseSelectedFields}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <OverlayPanel
        ref={op}
        // className="overlay"
      >
        {confirmPopData()}
      </OverlayPanel>
    </div>
  );
};
export default ProjectListFilter;
