import { mutliDragAwareReorder, multiSelectTo as multiSelect } from "./Utils";
import { DragDropContext } from "react-beautiful-dnd";
import { dependancyMappingFields } from "../DragAndDrop/Utils";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import entities1 from "./Data";
import Column from "./Column";
import { replaceSpaceWithUnderscore } from "./Utils";
import _, { isEmpty } from "lodash";
import { deepCompare, reorderSecondArraySameAsFirstArray } from "../../../../utils";

const getTasks = (entities, columnId) => {
  return entities?.columns?.[columnId]?.fieldsData?.map((taskId) => entities?.tasks?.[taskId]);
}

const DragAndDrop = (props) => {
  const setDependencyMappingColumnNames = localStorage.getItem("setDependencyMappingColumnNames")
  const parsedSetDependencyMappingColumnNames = setDependencyMappingColumnNames && JSON.parse(setDependencyMappingColumnNames)

  const pgDefaultDependencyMappingColumnNames = localStorage.getItem("pgDefaultDependencyMappingColumnNames")
  const parsedPgDefaultDependencyMappingColumnNames = pgDefaultDependencyMappingColumnNames && JSON.parse(pgDefaultDependencyMappingColumnNames)

  const customizeViewDependancyFields = localStorage.getItem("customizeViewDependancyFields")
  const parsedCustomizeViewDependancyFields = customizeViewDependancyFields && JSON.parse(customizeViewDependancyFields)

  const pgDefaultCustomizeViewDependancyFields = localStorage.getItem("pgDefaultCustomizeViewDependancyFields")
  const parsedPgDefaultCustomizeViewDependancyFields = pgDefaultCustomizeViewDependancyFields && JSON.parse(pgDefaultCustomizeViewDependancyFields)

  let dependencyColumnNames1 = parsedSetDependencyMappingColumnNames;
  const { selectedProject } = useSelector((state) => state.ProjectSetupReducer);
  const header = props?.headerName === "Dependency Mapping" ? true : false;
  const [localDestination, setLocalDestination] = useState(null);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [draggingTaskId, setDraggingTaskId] = useState(null);
  const [disable, setDisable] = useState(true);
  const [disableSave, setDisableSave] = useState(true);
  const CICs = selectedProject?.CICs;
  const dependencyColumnNames = dependancyMappingFields(
    dependencyColumnNames1,
    props.CDPTPageData,
    props.RDTData,
    props.IQData,
    CICs,
    props?.headerName
  );

  const [entities, setEntities] = useState(
    entities1(
      dependencyColumnNames && header ? dependencyColumnNames : props?.selectedFields !== null ? props?.selectedFields : [],
      props?.headerName,
      props?.headerName === "Artwork Scope Alignment" && (props?.availableFields !== null ? props?.availableFields : [])
    )
  );
  useEffect(() => {
    if (
      props?.customizeViewFields &&
      props?.customizeViewFields !== "[]" &&
      props?.customizeViewFields !== null &&
      props?.customizeViewFields?.length !== 0 &&
      !isEmpty(props?.customizeViewFields)
    ) {
      const data = (props?.customizeViewFields?.availableFields?.fieldsData?.length > 0
        || props?.customizeViewFields?.selectedFields?.fieldsData?.length > 0
        || props?.customizeViewFields?.freezedColumns?.fieldsData?.length > 0)
        ? props?.customizeViewFields
        : JSON.parse(props?.customizeViewFields);
      const updatedData = {
        ...entities,
        columns: data,
      };
      if (updatedData !== undefined) setEntities(updatedData);
    } else {
      setEntities(
        entities1(
          header ? dependencyColumnNames : props?.selectedFields,
          props?.headerName,
          props?.headerName === "Artwork Scope Alignment" && props?.availableFields
        )
      );
    }
  }, [props.customizeViewFields]);

  useEffect(() => {
    if (props?.headerName === "Dependency Mapping") {
      const isDifferent = deepCompare(parsedCustomizeViewDependancyFields, parsedPgDefaultCustomizeViewDependancyFields)
      setDisable(!isDifferent)
    }
  }, [customizeViewDependancyFields, pgDefaultCustomizeViewDependancyFields])

  useEffect(() => {
    const onWindowClick = (event) => {
      if (!event.defaultPrevented) {
        unselectAll();
      }
    };

    const onWindowKeyDown = (event) => {
      if (!event.defaultPrevented && event.key === "Escape") {
        unselectAll();
      }
    };

    const onWindowTouchEnd = (event) => {
      if (!event.defaultPrevented) {
        unselectAll();
      }
    };

    window.addEventListener("click", onWindowClick);
    window.addEventListener("keydown", onWindowKeyDown);
    window.addEventListener("touchend", onWindowTouchEnd);

    return () => {
      window.removeEventListener("click", onWindowClick);
      window.removeEventListener("keydown", onWindowKeyDown);
      window.removeEventListener("touchend", onWindowTouchEnd);
    };
  }, []);

  const onDragStart = (start) => {
    const id = start.draggableId;
    const selected = selectedTaskIds.find((taskId) => taskId === id);
    if (!selected) {
      unselectAll();
    }
    const updatedId = replaceSpaceWithUnderscore(id);
    setDraggingTaskId(updatedId);
  };

  const onDragEnd = (result) => {
    const destination = result.destination;
    const source = result.source;
    const draggableId = result.draggableId;

    if (!destination || result.reason === "CANCEL") {
      setDraggingTaskId(null);
      setLocalDestination(null);
      return;
    }

    const processed = mutliDragAwareReorder({
      draggableId,
      entities,
      selectedTaskIds,
      source,
      destination,
    });
    processed !== undefined && setEntities(processed?.entities);
    setDraggingTaskId(null);
    setLocalDestination(destination);
    setDisable(false);
    setDisableSave(false);
  };

  const onWindowKeyDown = (event) => {
    if (event.defaultPrevented) {
      return;
    }
    if (event.key === "Escape") {
      unselectAll();
    }
  };

  const onWindowClick = (event) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const onWindowTouchEnd = (event) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const toggleSelection = (taskId) => {
    const wasSelected = selectedTaskIds.includes(taskId);
    const newTaskIds = (() => {
      if (!wasSelected) {
        return [taskId];
      }
      if (selectedTaskIds.length > 1) {
        return [taskId];
      }
      return [];
    })();
    setSelectedTaskIds(newTaskIds);
  };

  const toggleSelectionInGroup = (taskId) => {
    const index = selectedTaskIds.indexOf(taskId);
    if (index === -1) {
      setSelectedTaskIds([...selectedTaskIds, taskId]);
    } else {
      const shallow = [...selectedTaskIds];
      shallow.splice(index, 1);
      setSelectedTaskIds(shallow);
    }
  };

  const multiSelectTo = (newTaskId) => {
    const updated = multiSelect(entities, selectedTaskIds, newTaskId);
    if (updated == null) {
      return;
    }
    setSelectedTaskIds(updated);
  };

  const unselectAll = () => {
    setSelectedTaskIds([]);
  };

  const resetToPGDefault = () => {
    let obj = entities.columns.freezedColumns.fieldsData;
    let selectedObj = entities.columns.selectedFields.fieldsData;
    let data = [];
    let freezeData = [];
    if (obj.length > 0 && selectedObj.length > 0) {
      freezeData = obj.splice(0, obj.length);
      data = selectedObj.splice(0, selectedObj.length);
    } else if (selectedObj.length > 0) {
      data = selectedObj.splice(0, selectedObj.length);
    }

    const updatedData = {
      ...entities,
      columns: {
        ...entities.columns,
        selectedFields: {
          ...entities.columns.selectedFields,
          fieldsData: [...data, ...freezeData, ...entities.columns.selectedFields.fieldsData],
        },
      },
    };
    setEntities(updatedData);

    if (props?.headerName === "My Projects") {
      localStorage.setItem("customizeViewFieldsMyProjects", []);
      props.setCustomizeViewFields([]);
      props.resetToPgDefault();
      props.hideDialog();
    } else if (props?.headerName === "All Projects") {
      localStorage.setItem("customizeViewFieldsAllProjects", []);
      props.setCustomizeViewFields([]);
      props.resetToPgDefault();
      props.hideDialog();
    } else if (props?.headerName === "ACPBooking") {
      localStorage.removeItem("customizeViewFieldsBooking");
      props.setCustomizeViewFields(null);
      props.hideDialog();
    }
    else if (props?.headerName === "Project Plan") {
      localStorage.setItem("customizeViewFieldsProjectPlan", []);
      props.setCustomizeViewFields(null);
      props.resetToPgDefault();
      props.hideDialog();
    }
    else if (props?.headerName === "Artwork Status") {
      localStorage.setItem("customizeViewArtworkStatusFields", []);
      props.setCustomizeViewFields([]);
      props.resetToPgDefault();
      props.hideDialog();
    }
    else if (props?.headerName === "Dependency Mapping") {
      props.setCustomizeViewFields(pgDefaultCustomizeViewDependancyFields);
      localStorage.setItem("customizeViewDependancyFields", pgDefaultCustomizeViewDependancyFields)
      localStorage.setItem("setDependencyMappingColumnNames", pgDefaultDependencyMappingColumnNames);
      props.setTableRender(!props.tableRender)
      props.hideDialog();
    }
    else if (props?.headerName === "Rework") {
      localStorage.setItem("customizeViewFieldsRework", []);
      props.setCustomizeViewFields(null);
      props.resetToPgDefault();
      props.hideDialog();
    }
    else {//Artwork Alignment 
      localStorage.setItem("customizeViewFields", []);
      props.setCustomizeViewFields([]);
      props.hideDialog();

    }
    // props.setCustomizeViewFields([]);
    // props.hideDialog();
  };
  const setLocalStorage = (localStorageName) => {
    localStorage.setItem(localStorageName, JSON.stringify(entities.columns));
    if (props?.headerName === "Dependency Mapping") {
      //add a logic to reorder setDependencyMappingColumnNames same as selected fields of setCustomizeViewFields
      const reorderedSecondArray = reorderSecondArraySameAsFirstArray(entities?.columns?.selectedFields?.fieldsData, parsedSetDependencyMappingColumnNames)
      localStorage.setItem("setDependencyMappingColumnNames", JSON.stringify(reorderedSecondArray));
      props.setTableRender(!props.tableRender)
    }
    else if (props?.headerName === "Artwork Status" || props?.headerName === "Artwork Scope Alignment") {
      props.setTableRender(!props.tableRender)
    }
  }

  useEffect(() => {
    if (props?.headerName === "Dependency Mapping") {
      if (customizeViewDependancyFields) {
        let temp = _.cloneDeep(entities);
        temp.columns = parsedCustomizeViewDependancyFields
        setEntities(temp)
      }
    }
  }, [customizeViewDependancyFields])

  const handleSubmit = async () => {
    switch (props?.headerName) {
      case "Dependency Mapping": setLocalStorage("customizeViewDependancyFields")
        break;
      case "Artwork Status": setLocalStorage("customizeViewArtworkStatusFields")
        break;
      case "ACPBooking": setLocalStorage("customizeViewFieldsBooking")
        break;
      case "All Projects": setLocalStorage("customizeViewFieldsAllProjects")
        break;
      case "My Projects": setLocalStorage("customizeViewFieldsMyProjects")
        break;
      case "Project Plan": setLocalStorage("customizeViewFieldsProjectPlan")
        break;
      case "Rework": setLocalStorage("customizeViewFieldsRework")
        break;
      default: setLocalStorage("customizeViewFields")
        break
    }
    props.setCustomizeViewFields(JSON.stringify(entities.columns));
    props.hideDialog();
  };
  const selected = selectedTaskIds;
  useEffect(() => {
    if (!customizeViewDependancyFields && props?.headerName === "Dependency Mapping") {
      localStorage.setItem("customizeViewDependancyFields", JSON.stringify(entities?.columns));
      const reorderedSecondArray = reorderSecondArraySameAsFirstArray(parsedPgDefaultDependencyMappingColumnNames, entities?.columns?.selectedFields?.fieldsData);
      const temp = entities?.columns;
      temp.selectedFields.fieldsData = reorderedSecondArray
      localStorage.setItem("pgDefaultCustomizeViewDependancyFields", JSON.stringify(temp))
    }
  }, [entities])

  return (
    <>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <div style={{ display: "flex" }}>
          {entities?.columnOrder.map((columnId) => (
            <Column
              key={columnId}
              entities={entities}
              selectedTaskIds={selected}
              multiSelectTo={multiSelectTo}
              draggingTaskId={draggingTaskId}
              toggleSelection={toggleSelection}
              column={entities?.columns[columnId]}
              tasks={getTasks(entities, columnId)}
              toggleSelectionInGroup={toggleSelectionInGroup}
              droppableId={localDestination ? localDestination.droppableId : null}
            />
          ))}
        </div>
      </DragDropContext>
      <div className="form-buttons dsbp-form-buttons">
        <Button className="button-layout" variant="secondary" onClick={() => props.hideDialog()}>
          Cancel
        </Button>

        {props?.headerName === "Dependency Mapping" ? (
          <Button className="button-layout" variant="secondary" onClick={resetToPGDefault} disabled={disable}>
            Reset to P&G Default
          </Button>
        ) : (
          <Button className="button-layout" variant="secondary" onClick={resetToPGDefault}>
            Reset to P&G Default
          </Button>
        )}

        <Button className="button-layout" variant="primary" onClick={handleSubmit} disabled={disableSave}>
          Save
        </Button>
      </div>
    </>
  );
};

export default DragAndDrop;
