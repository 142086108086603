import { useEffect, useState,useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import PageLayout from '../PageLayout';
import ChangeNotificationFooter from './ChangeNotificationFooter';
import ChangeNotificationHeader from './ChangeNotificationHeader';
import { changehandingHeader, Changes, tableHeader, messages } from './ChangeNotificationConstant';
import {
  getChangeNotification,
  onSubmitChangeManagement,
  onSubmitChangeManagementPOA,
} from '../../apis/changeNotificationApi';
import './index.scss';
import ConfirmChangesPopUp from './ConfirmChangesPopUp';
import { changesDataList, poaChangesList } from '../../Utils/ChangeNotificationService';
import { useSelector } from 'react-redux';
import DsbpCommonPopup from '../DSBP/DsbpCommonPopup';

const initialState = {
  changePOAAcknowledgDialog: false,
  changePOAResponseData: '',
};

const ChangeNotification = () => {
  const [apiData, setApiData] = useState({
    snapshotChangesData: {},
    listOfChangesData: []
  });
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  let { ProjectID } = useParams();
  const location = useLocation();
  const [locationStateValues] = useState(location.state);
  const [listOfPOAChanges, setPOAChangesList] = useState([]);
  const [poaAcknowledgement, setPoaAcknowledgement] = useState(initialState);
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const { userInformation } = useSelector((state) => state.UserReducer);


  // fetchChangeNotificationData to get the poa changes
  const fetchChangeNotificationData = useCallback(async () => {
    const { InitiativeID, PIMaterialID, PIMaterialNumber } = locationStateValues;
    let snapshotChanges = await getChangeNotification(
      InitiativeID,
      PIMaterialID,
      PIMaterialNumber,
      ProjectID
    );
    setLoader(false);
    let ListofChanges = changesDataList(snapshotChanges);
    let ListofPOAChanges = poaChangesList(snapshotChanges);
    setPOAChangesList([...ListofPOAChanges]);
    setApiData({
      ...apiData,
      snapshotChangesData: snapshotChanges,
      listOfChangesData: ListofChanges,
    });
  }, [locationStateValues, ProjectID]);

  const { snapshotChangesData, listOfChangesData } = apiData;

  // to ge the poa change related information
  useEffect(() => {
    if (locationStateValues) {
      fetchChangeNotificationData();
    } else {
      navigate('/');
    }
  }, [locationStateValues, fetchChangeNotificationData, navigate]);

  // handle confirm button function
  const handleConfirm = () => {
    if (snapshotChangesData.AWM_SnapChange_POACreate) {
      setConfirmPopUp((prevState) => !prevState);
    } else {
      handleSubmit();
    }
  };

  // handle back button function
  const handleCancel = () => {
    navigate(-1);
  };

  // show api error/success response
  const showApiResponse = (...args) => {
    setPoaAcknowledgement((prev) => ({...prev, changePOAAcknowledgDialog: args[0], changePOAResponseData:args[1]}))
  }


  //find the success status
  const getCreationStatus = (list) => {
    const getStatus = list.find((item) => item.POACreationStatus.includes('Success'));
    return getStatus;
  }

  // Confirm changes for poa updates (copy flag)
  const confirmPoaChanges = async () => {
      setConfirmPopUp((prevState) => !prevState);
      setLoader(true);
      const formData = {
        ArtworkAgilityPage: {
          AWM_Project_ID: ProjectID,
          Assignee: userInformation?.userid,
          POAPackageName: '',
        },
        ArtworkAgilityPMPs: [
          {
            DSBP_InitiativeID: locationStateValues?.InitiativeID,
            DSBP_PMP_PIMaterialID: snapshotChangesData?.PIMaterialID,
            DSBP_PMP_PIMaterialNumber: snapshotChangesData?.PIMaterialNumber,
            IsPMPRemoved: 'false',
          },
        ],
      };
      let response = await onSubmitChangeManagementPOA(formData);
      const isSuccess = getCreationStatus(response?.data?.PMPList);
      // need to change below 
      if (isSuccess && isSuccess.POACreationStatus) {
        setLoader(false);
        showApiResponse(true, messages.successMessage);
        await fetchChangeNotificationData();
      } else {
        setLoader(false);
        showApiResponse(true, messages.errorMessage);
      }
  }

  // confirm changes for FA,CIC
  const confirmChanges = async () => {
    setLoader(true);
    const formData = {
      AWMProjectID: ProjectID,
      PIMaterialID: snapshotChangesData?.PIMaterialID,
      PIMaterialNumber: snapshotChangesData?.PIMaterialNumber,
      InitiativeID: locationStateValues?.InitiativeID,
      CIC: snapshotChangesData?.AWM_SnapChange_CIC,
      POA: snapshotChangesData?.AWM_SnapChange_POACreate,
      FA: snapshotChangesData?.AWM_SnapChange_FA,
    };
    let response = await onSubmitChangeManagement(formData);
    if (response.status === 200) {
      await fetchChangeNotificationData();
      setLoader(false);
    } else {
      setLoader(false);
    }
  }
  
  // handle confirm changes function
  const handleSubmit = () => {
    if (snapshotChangesData.AWM_SnapChange_POACreate) {
      confirmPoaChanges();
    } else {
      confirmChanges();
    }
  };

  return (
    <PageLayout>
      <div className="container padding-20 changeNotification">
        <ChangeNotificationHeader
          AWM_SnapChange_Editable={snapshotChangesData?.AWM_SnapChange_Editable}
          AWM_SnapChange_CIC={snapshotChangesData?.AWM_SnapChange_CIC}
          AWM_SnapChange_FA={snapshotChangesData?.AWM_SnapChange_FA}
          AWM_SnapChange_POACreate={snapshotChangesData?.AWM_SnapChange_POACreate}
          assignee={locationStateValues?.assignee}
          loader={loader}
        />
        {loader ? (
          <LoadingOverlay active={true} spinner text="" />
        ) : (
          <div className='margin-t-10'>
            <div className="project-name padding-5">{changehandingHeader.ReviewChanges}</div>
            <div className=" field-details padding-5">
              {/* dynamic data will be here */}
              <div>
                {' '}
                {changehandingHeader.InitiativeId} -{' '}
                <span className="font-color" title={snapshotChangesData.PIMaterialID}>
                  {' '}
                  {snapshotChangesData.DSBP_InitiativeID}
                </span>{' '}
              </div>
              <div>
                {' '}
                {changehandingHeader.POARta} -{' '}
                <span className="font-color" title={snapshotChangesData.POA_Number}>
                  {' '}
                  {snapshotChangesData.POA_Number}
                </span>{' '}
              </div>
              <div>
                {' '}
                {changehandingHeader.POADescription} -{' '}
                <span className="font-color" title={snapshotChangesData.POA_Description}>
                  {' '}
                  {snapshotChangesData.POA_Description}
                </span>{' '}
              </div>
              <div>
                {' '}
                {changehandingHeader.PIMaterialNumber} -{' '}
                <span className="font-color" title={snapshotChangesData.PIMaterialNumber}>
                  {' '}
                  {snapshotChangesData.PIMaterialNumber}
                </span>{' '}
              </div>
              <div>
                {' '}
                {changehandingHeader.PIDescriptionDSBP} -{' '}
                <span className="font-color" title={snapshotChangesData.PIMaterialDescription}>
                  {' '}
                  {snapshotChangesData.PIMaterialDescription}
                </span>{' '}
              </div>
              <div>
                {' '}
                {changehandingHeader.PIDescriptionDSM} -{' '}
                <span className="font-color" title={snapshotChangesData.DSMPIMaterialDescription}>
                  {' '}
                  {snapshotChangesData.DSMPIMaterialDescription}
                </span>{' '}
              </div>
              <div>
                {' '}
                {changehandingHeader.ActiveCopyMatrix} -{' '}
                <span className="font-color" title={snapshotChangesData.ActiveCopyMatrix}>
                  {' '}
                  {snapshotChangesData.ActiveCopyMatrix}
                </span>{' '}
              </div>
              <div>
                {' '}
                {changehandingHeader.RTAArtworkProject} -{' '}
                <span className="font-color" title={snapshotChangesData.RTA_Artwork_Project}>
                  {' '}
                  {snapshotChangesData.RTA_Artwork_Project}
                </span>{' '}
              </div>
              <div>
                {' '}
                {changehandingHeader.RTAPOAStatus} -{' '}
                <span className="font-color" title={snapshotChangesData.RTA_POA_Status}>
                  {' '}
                  {snapshotChangesData.RTA_POA_Status}
                </span>{' '}
              </div>
            </div>
            <div className="tabsCompo">
              <div className="project-name padding-5">{Changes}</div>
              <div className="changesTable">
                <div>
                  <table className="table table-sm table-hover table-bordered changetable">
                    <tbody>
                      <tr>
                        <th>{tableHeader.Attributes}</th>
                        <th>{tableHeader.NewValue}</th>
                        <th>{tableHeader.OldValue}</th>
                      </tr>
                      {listOfChangesData?.map((item) => (
                        <tr>
                          <td title={item.Label}>{item.Label}</td>
                          <td className="newvalue" title={item.NewValue}>
                            {item.NewValue}
                          </td>
                          <td title={item.OldValue}>{item.OldValue}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <ChangeNotificationFooter
              handleCancel={handleCancel}
              handleConfirm={handleConfirm}
              editableFlag={snapshotChangesData?.AWM_SnapChange_Editable}
              PIMaterialNumber={snapshotChangesData?.PIMaterialNumber}
              copyFlag={snapshotChangesData?.AWM_SnapChange_POACreate}
              cicFlag={snapshotChangesData?.AWM_SnapChange_CIC}
              faFlag={snapshotChangesData?.AWM_SnapChange_FA}
              POARequestType={snapshotChangesData?.POARequestType}
            />
          </div>
        )}
        {confirmPopUp && (
          <ConfirmChangesPopUp
            handleConfirm={handleConfirm}
            PIMaterialNumber={snapshotChangesData.PIMaterialNumber}
            POA={snapshotChangesData.POA_Number}
            RTAStatus={snapshotChangesData.RTA_POA_Status}
            listOfChangesData={listOfPOAChanges}
            handleSubmit={handleSubmit}
          />
        )}

        {poaAcknowledgement.changePOAAcknowledgDialog && (
          <DsbpCommonPopup
            actionHeader={messages.acknowledgment}
            dasbpDialog={poaAcknowledgement.changePOAAcknowledgDialog}
            setDasbpDialog={(value) =>
              setPoaAcknowledgement({
                ...poaAcknowledgement,
                changePOAAcknowledgDialog: value
              })
            }
            okButtonShow={true}
            deleteButtonShow={false}
            showCancel={true}
            submitButtonShow={false}
            yesButtonShow={true}
            disconnectButtonShow={true}
          >
            {poaAcknowledgement.changePOAResponseData}
          </DsbpCommonPopup>
        )}
      </div>
    </PageLayout>
  );
};
export default ChangeNotification;
