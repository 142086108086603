import { isString } from 'lodash';
import React, { useState } from 'react';

const Showmore = ({text, sliceIndex}) => {
  const [isShowMore, setIsShowMore] = useState(true);
  const toggleShowMore = () => {setIsShowMore(!isShowMore)};

  return (
    <div className='show-more'>
    {isString(text) ?
      <>    
        <div>
            {isShowMore ? text?.slice(0, sliceIndex): text }      
        </div>
        {text?.length > sliceIndex &&
        <a onClick={toggleShowMore} className='show-more-less-text'>
            {isShowMore ? '...Show more' : ' Show less'}
        </a>
        }
      </>
    : text}
    </div>
  )
}

export default Showmore;