import React, { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import plusCollapseImg from "../../../assets/images/plusCollapse.svg";
import filter from "../../../assets/images/filter.svg";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "primeicons/primeicons.css";
import _ from "lodash";
import { usePriorityNew } from "../../../Utils/GetRolePriority";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { CustomBreadCrum } from "../../common/CustomBreadcrum";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";

const DesignHeader = ({
  setAddNewDesign,
  onSelectAll,
  label,
  headerName,
  disabled,
  checkTaskISComplete = false,
  closeFlag,
  actionButtonsFlag,
  checked,
  setChecked,
  enableCheckBox,
  enableSelectAllCheckBox,
  Assignee,
  pageEdited,
  setPageEdited,
  lastLabel,
  checkReadWriteAccess,
  rejected,
  taskName,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationPath = location?.pathname;
  let { page1, page2, pageType, TaskID, ProjectID } = useParams();
  const url = locationPath?.split("/");
  // const checkReadWriteAccess = usePriorityNew(url[1]);
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const [showDialog, setShowDialog] = useState(false);
  const { markDirty, markClean } = useUnsavedChangesWarning();
  // const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);
  // useEffect(() => {
  //   if (User && Assignee) {
  //     if (userInformation?.userid === Assignee) {
  //       setCheckReadWriteAccess(true);
  //     } else {
  //       setCheckReadWriteAccess(false);
  //     }
  //   }
  // }, [User, Assignee]);

  const mytasks = url[1];

  const handleCrossIconClick = () => {
    if (pageEdited) {
      setShowDialog(true);
    } else {
      handleCrossIcon();
    }
  };

  const handleCrossIcon = () => {
    if (page2 && page2 === "projectPlan") {
      navigate(`/${page1}/${page2}/${ProjectID}`);
    } else if (pageType) {
      navigate(`/${pageType}`);
    }
  };

  const confirmNavigation = () => {
    markClean();
    handleCrossIcon();
    setPageEdited(false);
    setShowDialog(false);
  };

  const cancelNavigation = () => {
    setShowDialog(false);
  };

  return (
    <div className="content-layout task-layout">
      <i
        hidden={closeFlag}
        className="pi pi-times"
        // onClick={() => {
        //   if (page2 && page2 === "projectPlan") {
        //     navigate(`/${page1}/${page2}/${ProjectID}`);
        //   } else if (pageType) {
        //     navigate(`/${pageType}`);
        //   }
        // }}
        onClick={handleCrossIconClick}
      ></i>
      <div className={`actions ${actionButtonsFlag ? "actionsPaddingForUBD" : ""}`}>
        <div>
          {/* <BreadCrumb model={breadcrumb} /> */}
          <nav className="p-breadcrumb p-component" aria-label="Breadcrumb">
            <ul>
              <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>
              {url[2] === "projectPlan" ? (
                <>
                  <CustomBreadCrum
                    lastLabel={lastLabel}
                    iconColorBlack={true}
                    taskName={taskName}
                    rejected={rejected}
                    lastChildSecondChild={true}
                    items={[
                      { label: _.startCase(mytasks), url: `/${mytasks}` },
                      { label: _.startCase(url[2]), url: `/${mytasks}/${url[2]}/${url[5]}` },
                    ]}
                  />
                  {/* <li className="">
                    <NavLink to={`/${mytasks}`} className="p-menuitem-link">
                      <span className="p-menuitem-text">{_.startCase(mytasks)}</span>
                    </NavLink>{" "}
                  </li>{" "} */}
                  {/* <li className="p-breadcrumb-chevron pi pi-chevron-right"></li> */}
                  {/* <li className="">
                    <NavLink to={`/${mytasks}/${url[2]}/${url[5]}`} className="p-menuitem-link">
                      <span className="p-menuitem-text">{_.startCase(url[2])}</span>
                    </NavLink>{" "}
                  </li> */}
                </>
              ) : (
                <>
                  <CustomBreadCrum
                    rejected={rejected}
                    lastLabel={lastLabel}
                    taskName={taskName}
                    iconColorBlack={true}
                    lastChildSecondChild={false}
                    items={[{ label: _.startCase(mytasks), url: `/${mytasks}` }]}
                  />
                  {/* <li className="">
                    <NavLink to={`/${mytasks}`} className="p-menuitem-link">
                      <span className="p-menuitem-text">{_.startCase(mytasks)}</span>
                    </NavLink>{" "}
                  </li> */}
                </>
              )}

              <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>
              {/* <li className="">
                <a href="#" className="p-menuitem-link">
                  <span className="p-menuitem-text">{label}</span>
                </a>
              </li> */}
              <CustomBreadCrum 
                lastLabel={lastLabel} 
                iconColorBlack={true}
                rejected={rejected}
                taskName={taskName}
                lastChildSecondChild={false} 
                items={[{ label: label, url: `` }]} 
                customStyle={{color:"#003da5", fontWeight:500}}
              />
            </ul>
          </nav>
          <div className="project-title">{headerName}</div>
        </div>

        <div className="action-buttons" hidden={actionButtonsFlag}>
          <div className="icon-items">
            <Checkbox
              onChange={(e) => {
                setChecked(e.checked);
                onSelectAll(e.checked);
              }}
              checked={checked}
              className="margin-right"
              disabled={(!onSelectAll && true) || !enableCheckBox || !checkReadWriteAccess || checkTaskISComplete}
            ></Checkbox>
            <div className="icon-label">
              <label className={disabled && "disable-buttons"} disabled={enableSelectAllCheckBox}>
                {" "}
                Select All
              </label>
            </div>
          </div>
          <div className="icon-items">
            <img
              src={filter}
              alt="filter logo"
              className="filter-icon filter-color-change"
              disabled
              style={{ cursor: "unset", opacity: "0.6" }}
            />
            <div>
              <label className="icon-label">filter</label>
            </div>
          </div>
          <div className={checkTaskISComplete ? "icon-items disabled" : "icon-items"}>
            <img
              src={plusCollapseImg}
              onClick={() => checkReadWriteAccess && setAddNewDesign && setAddNewDesign()}
              className="add-new-design-intent-icon"
              alt=""
              disabled={(!setAddNewDesign && true) || !checkReadWriteAccess || checkTaskISComplete}
            />
            <div className="icon-label">
              <label className={disabled && "disable-buttons"}>Add {label}</label>
            </div>
          </div>
        </div>
      </div>
      {/* UnsavedChangesDialog popup*/}
      <UnsavedChangesDialog
        rejected={rejected} 
        taskName={taskName}
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </div>
  );
};

export default DesignHeader;
