// leftAlignFrozenColumn.js
import {basedOnFreezeLocalStorageUpdate} from './basedOnFreezeLocalStorageUpdate';

export const setAlignFrozenColumn = (frozenColumns, setReorderedColumn, setProjectColumnNames, setTableRender, op, columnNames, pageName) => {
  frozenColumns?.forEach(item => {
    const index = columnNames.indexOf(item);
    if (index > -1) {
      columnNames.splice(index, 1);
      columnNames.splice(0, 0, item);
      setReorderedColumn(true);
    }
    basedOnFreezeLocalStorageUpdate(pageName, columnNames);    
    setProjectColumnNames(columnNames);
    setTableRender(false);
    op.current.toggle(false);
  });
};
