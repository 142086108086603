import React, { useState, useEffect, useRef } from 'react';
import { Column } from 'primereact/column';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import PageLayout from '../../PageLayout';
import './index.scss';
import ReworkHeader from '../../ReworkPage/ReworkHeader';
import ReasonForRework from '../../ReasonForRework/ReasonForRework';
import CommonTreeTable from '../../common/TreeTable/TreeTable';
import filter from '../../../assets/images/filter.svg';
import ElementTemplate from '../../common/TreeTable/ElementTemplate';
import { RejectionReason } from "../../../constants/reworkPageTasks"
import { popUpConst } from '../../../constants/reworkPageTasks';
import CustomizeView from "../../common/CustomizeView";
import { AcpService } from "../../../service/ACPService";
import { sortBasedOnSelectedValue } from "../../Projects/ProjectPlan/util";
import { cloneDeep } from "lodash";
import {
  filterData,
  onGlobalFilterChange,
  expandAll,
  getSelectedValues,
  clearAllFilterValues,
  getformattedResponse,
  getdeSelectedValues,
  getDDoptions,
  autoVal,
  durationEnabled,
  getDropdownChangedValues,
  addDisableByState,
  filterDataForSelection,
  onSelectColumn,
  getUpdatedAllColumnFormat
} from '../../../Utils/GridService';
import ConfirmationPopUp from '../../Projects/ConfirmationPopUp';
import { extractData } from '../../Projects/ProjectPlan/util';
import { ProjectService } from '../../../service/PegaService';
import { usePriorityNew } from '../../../Utils/GetRolePriority';
import RejectReason from '../../RejectionDialog/RejectReason';
import SelectionPopUp from '../../../components/SelectionPopUp/SelectionPopUp';
import { reworkTableDataUpdate } from '../../../store/actions/ReworkMarkUpsAction';


const stateValues = {
  enableFilter: false,
  selectedColumn: "",
  projectFrozen: false,
  selectedFields: {},
  sortData: [],
  filters: [],
  frozenColumns: [],
  columnWiseSelectedFields: [],
  selectedColumnName: null,
  selectedNodeKeys: null,
  expandedKeys: null,
  breadCrumbs: [],
  cicCommentList: {},
  cicHeader: "",
  visible: false,
  showSelctionPopUp: false,
  showReasonReworkPopUp: false,
  tableHeight: '65vh',
};

function Rework() {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState(stateValues);
  const [isSearch, isSearchSet] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [frozenUpdated, setFrozenUpdated] = useState(false);
  const [fieldUpdated, setFieldUpdated] = useState(false);
  const [frozenWidth, setFrozenWidth] = useState("0px");
  const [visible, setVisible] = useState(false);
  const [customizeViewFields, setCustomizeViewFields] = useState({});
  const [frozenCoulmns, setFrozenColumn] = useState([]);
  const [allSelectedColumns, setAllSelectedColumns] = useState([]);
  const [isGlobalFilterActivated, setGlobalFilterActivated] = useState(false);
  const [isCustomizeViewActive, setCustomizeViewActive] = useState(false)

  const op = useRef(null);
  const deSelValRef = useRef([]);
  const { projectPlanDesign, reworkHistory } = useSelector((state) => state.ProjectPlanReducer);
  const { reworkTableData } = useSelector((state) => state.ReworkMarkupReducer);

  const itemsProjectPlan = getformattedResponse(projectPlanDesign);
  const items = addDisableByState(itemsProjectPlan);
  const flatPegadata = items?.flatMap((obj) => extractData(obj));
  const localStorageName = "ReworkAllColumnNames";
  let jsonColumnWidth = localStorage.getItem(localStorageName);

  const location = useLocation();
  var splitString = location.pathname.split("/");
  splitString.pop();
  const projectPlanUrl = splitString.join("/");
  const accessSecurityMatrix = usePriorityNew(location.pathname.split("/")[1]);

  // initially set data to local storage
  const reworkColumnNames = jsonColumnWidth ? JSON.parse(jsonColumnWidth) : null;
  if (reworkColumnNames === null) {
    const columnNamesRW = ProjectService.getreworkAllColumnNames();
    localStorage.setItem(localStorageName, JSON.stringify(columnNamesRW));
  }

   //this use-effect is to call the tree table again to reset the width, if customize field is changes 
  useEffect(() => {
    const tempData = reworkTableData
    setTableData([]);
    setTimeout(() => {
      tempData?.length > 0 && setTableData(tempData)
    }, 100);
  }, [isCustomizeViewActive]);

  let columnNames = ProjectService.getreworkAllColumnNames()?.map((item) => item.field_Name) || [];
  //Keeping this useEffect too make table data filterable and set table data on initilize
  useEffect(() => {
    if (
      _.isEmpty(state.selectedFields) ||
      Object.values(state.selectedFields)?.every((ele) => ele.length === 0)
    ) {
      setTableData(items);
    } else {
      //if filter is selected
      const filteredData = filterData(items, state.selectedFields);
      setTableData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedFields]);

  useEffect(() => {
    dispatch(reworkTableDataUpdate(tableData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    handleGlobalFilter()
  }, [tableData]);

  useEffect(() => {
    const expandedKeys = expandAll(items, {});
    setState((prev) => ({
      ...prev,
      expandedKeys,
    }));

    // set initial column values if its empty 
    const StorageData = localStorage.getItem("customizeViewFieldsRework");
    const customizeViewFieldsStorage = StorageData && JSON.parse(StorageData);
    if (customizeViewFieldsStorage === null) {
      setToInitialColumns()
    }
    else {
      setCustomizeViewFields(customizeViewFieldsStorage);
    }
    handleColumns(reworkColumnNames)

  }, []);

  useEffect(() => {
    setFrozenUpdated(frozenUpdated);
    frozenColumn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frozenUpdated]);

  useEffect(() => {
    handleGlobalFilter()
  }, [allSelectedColumns]);

  const handleGlobalFilter = () => {
    const GlobalFilter = (state.columnWiseSelectedFields && state.columnWiseSelectedFields.length !== 0) ||
      isSearch ||
      isCustomizeViewEnabled()
      ? true
      : false;
    setGlobalFilterActivated(GlobalFilter)
  }

  const getMarkUps = (rowInfo) => {
    setState((prev) => ({
      ...prev,
      visible: true,
      cicCommentList: rowInfo,
      cicHeader: rowInfo.data.Task,
    }));
  };
  const handleColumns = (allColumnsname) => {
    const StorageData = localStorage.getItem("customizeViewFieldsRework");
    const customizeViewFieldsStorage = StorageData && JSON.parse(StorageData);
    if (customizeViewFieldsStorage !== undefined && customizeViewFieldsStorage !== null && customizeViewFieldsStorage?.length !== 0) {
      const parsedData = customizeViewFieldsStorage?.selectedFields?.fieldsData?.length
        ? customizeViewFieldsStorage
        : customizeViewFieldsStorage && JSON.parse(customizeViewFieldsStorage);
      const freezedData = parsedData?.freezedColumns?.fieldsData;
      let selectedData = parsedData?.selectedFields?.fieldsData;
      const availableData = parsedData?.availableFields?.fieldsData;
      if (freezedData?.length > 0) { selectedData = [...freezedData, ...selectedData]; }
      if (freezedData?.length > 0 || availableData?.length > 0) {
        setCustomizeViewActive(!isCustomizeViewActive)
      }
      let tempallColumnsname = cloneDeep(allColumnsname);
      tempallColumnsname?.forEach((ele) => {
        if (freezedData?.some(str => ele.field_Name === str)) {
          return ele['freeze'] = true
        }
        else {
          return ele['freeze'] = false
        }
      })
      const alteredColumns = tempallColumnsname?.filter(column => {
        if (selectedData.some(str => column.field_Name === str)) {
          return column
        }
      })
      const sortedalteredColumns = sortBasedOnSelectedValue(alteredColumns, selectedData)
      setAllSelectedColumns(sortedalteredColumns)
      frozenColumn()

    }
  }
  const getCustomizeViewLocalStorage = () => {
    const StorageData = localStorage.getItem("customizeViewFieldsRework");
    const customizeViewFieldsStorage = StorageData && JSON.parse(StorageData);
    return customizeViewFieldsStorage
  }

  const frozenColumn = () => {
    const customizeViewFieldsStorage = getCustomizeViewLocalStorage()
    let freezedDataLen
    if (customizeViewFieldsStorage !== undefined && customizeViewFieldsStorage !== null && customizeViewFieldsStorage?.length !== 0) {
      freezedDataLen = customizeViewFieldsStorage?.freezedColumns?.fieldsData?.length;
    }
    const totalFrozenWidth = freezedDataLen * 200
    if (totalFrozenWidth > 0) {
      setFrozenWidth(`${totalFrozenWidth}px`);
    } else {
      setFrozenWidth(`0px`);

    }
  };
  const onColumnResizeEnd = (event) => {
    let columnWidth = [];
    if (jsonColumnWidth) {
      columnWidth = allSelectedColumns; // columns from customize view
    }
    if (columnWidth) {
      columnWidth.forEach((list) => {
        if (event.column.props.field === list.field_Name && list.freeze == false) {
          list.width = event.element.offsetWidth;
        }
      });
    }
    localStorage.setItem(localStorageName, JSON.stringify(columnWidth));
    setFieldUpdated(!fieldUpdated);
  };
  const isCustomizeViewEnabled = () => {
    const customizeViewFieldsStorage = getCustomizeViewLocalStorage()
    let freezedDataLen
    let availableDataLen
    if (customizeViewFieldsStorage !== undefined && customizeViewFieldsStorage !== null && customizeViewFieldsStorage?.length !== 0) {
      freezedDataLen = customizeViewFieldsStorage?.freezedColumns?.fieldsData?.length;
      availableDataLen = customizeViewFieldsStorage?.availableFields?.fieldsData?.length;
    }
    return (freezedDataLen > 0 || availableDataLen > 0) ? true : false
  }
  // expand collapse the table data
  const toggleApplications = () => {
    if (state.expandedKeys === null) {
      const _expandedKeys = {};
      const keys = expandAll(items, _expandedKeys);
      updateStateValue("expandedKeys", keys);
    } else {
      updateStateValue("expandedKeys", null);
    }
  };
  const onSearchClick = () => {
    isSearchSet(!isSearch);
  };

  const onDropdownChange = (rowData, { value }, ele) => {
    const updatedTreeData = getDropdownChangedValues(rowData, value, ele, reworkTableData);
    setTableData(updatedTreeData);
  };

  const onDurationChange = (rowData, { value }, ele) => {
    rowData.data[ele] = value < 1 ? "0" : value?.toString();
    setTableData([...reworkTableData]);
  };

  // body template of columns
  const elementTemplate = (options, rowData) => {
    return (
      <ElementTemplate
        optionsVal={options}
        rowDataVal={rowData}
        selectedNodes={selectedNodes}
        accessSecurityMatrix={accessSecurityMatrix}
        getMarkUps={getMarkUps}
        onDropdownChange={onDropdownChange}
        autoVal={autoVal}
        getDDoptions={getDDoptions}
        onDurationChange={onDurationChange}
        durationEnabled={durationEnabled}
      />
    );
  };
  const toggleDialog = () => {
    setState((prev) => ({
      ...prev,
      visible: false,
    }));
  };
  // crate dynamic columns
  const dynamicColumns = () => {
    let allColumnsdata = allSelectedColumns; // columns from customize view
    if (allColumnsdata && allColumnsdata?.length) {
      return allColumnsdata?.map((ele, i) => {
        return (
          <Column
            key={ele?.field_Name}
            field={ele?.field_Name}
            filterField={ele?.field_Name}
            filterMatchMode="contains"
            filter={isSearch}
            filterPlaceholder={ele?.field_Name}
            header={columnHeader(ele?.field_Name, ele?.field_Name, ele)}
            expander={ele?.field_Name === "Task"}
            columnKey={ele?.field_Name || i}
            frozen={ele?.freeze}
            body={elementTemplate}
            // alignFrozen="left"
            className={ele?.freeze ? "fontBoldcolor" : "cursorMove"}
            showFilterMenu={false}
            style={{
              height: 30,
              width: ele?.freeze ? "200px" : ele?.width,
              textOverflow: ele?.field_Name !== "Task" ? "ellipsis" : "",
              textAlign: ele?.field_Name === "Rework" && "center",
            }}
          />
        );
      });
    }
  };
  const columnHeader = (options, keyId, col) => {
    let isFilterActivated = col?.freeze === true;
    return (
      <span key={keyId}>
        <img
          src={filter}
          alt="Column Filter"
          key={keyId}
          onClick={(e) => columnHeaderClick(e, keyId)}
          className={
            isFilterActivated ? "columnFilterIcon filter-color-change" : "columnFilterIcon"
          }
        />
        <span className="columnHeader">{options}</span>
      </span>
    );
  };

  // update column name on click of column header
  const columnHeaderClick = (e, options) => {
    op.current.toggle(e);
    setState((prev) => ({ ...prev, selectedColumnName: options }));
  };

  // clear the filters
  const clearColumnWiseFilter = () => {
    setState((prev) => ({ ...prev, selectedFields: {}, filters: [] }));
  };

  const onClickClearFilter = () => {
    let allColumns = reworkColumnNames;
    localStorage.setItem(localStorageName, JSON.stringify(clearAllFilterValues(allColumns)));
    setFieldUpdated(!fieldUpdated);
    setFrozenUpdated(!frozenUpdated);
    clearColumnWiseFilter();
    setVisible(false);
    setToInitialColumns()
    setAllSelectedColumns(allColumns)
    setGlobalFilterActivated(false)
    setCustomizeViewActive(false)
  };

  //update filter value based on selection
  const onFilterChange = (e, filterColumn) => {
    const { value, temp } = onGlobalFilterChange(e, filterColumn, state.selectedFields);
    setState((prev) => ({ ...prev, selectedFields: temp, columnWiseSelectedFields: [...value] }));
  };

  const removePartialSelected = (keyvalues) => {
    return (
      keyvalues &&
      Object.keys(keyvalues)?.filter((innerobj) => keyvalues[innerobj]?.checked === true)
    );
  };
  const storeDeselecVal = (deSelVal) => {
    deSelValRef.current.push(...deSelVal);
  };

  //update state value
  const updateStateValue = (key, val) => {
    // separating selection and deslection logic
    if (key === "selectedNodeKeys") {
      const nodeKeyValues = removePartialSelected(state.selectedNodeKeys) || [];
      const changeStatusValues = removePartialSelected(val) || [];
      const nodeKeyLen = nodeKeyValues?.length || 0;
      const changeStatusLen = changeStatusValues?.length;
      const dataforSelection = filterDataForSelection(flatPegadata);
      //for selection always the onSelectionChange value will be grater then perious selected value
      if (changeStatusLen >= nodeKeyLen) {
        // >= because we are removing partialSelection
        const selectedKey = _.difference(changeStatusValues, nodeKeyValues);
        const checkedValues = getSelectedValues(dataforSelection, selectedKey, val, selectedNodes);
        const keyValue = checkedValues.value;
        setSelectedNodes(checkedValues.selectionChangeList);
        setState((prev) => ({ ...prev, selectedNodeKeys: keyValue }));
      }
      //for DeSelection always the onSelectionChange value will be lesser then perious selected value
      else if (changeStatusLen < nodeKeyLen) {
        //deselection
        const unselectedKey = _.difference(nodeKeyValues, changeStatusValues);
        const deselectedValues = getdeSelectedValues(
          dataforSelection,
          unselectedKey,
          state.selectedNodeKeys,
          selectedNodes,
          reworkTableData
        );
        const keyValue = deselectedValues.value;
        storeDeselecVal(deselectedValues?.allDeselectionList);
        setSelectedNodes(deselectedValues.updatedSelectedNode);
        setState((prev) => ({ ...prev, selectedNodeKeys: keyValue }));
      }
    } else {
      setState((prev) => ({ ...prev, [key]: val }));
    }
  };
  const headerContent = (
    <div>
      {RejectionReason.rejectionHeader}
    </div>
  );
  /*code for customize feild*/
  const formCustomizeObject = (availableFields, freezedColumns, selectedFeilds) => {
    const customizeViewFieldsUpdated = {
      availableFields: {
        id: "availableFields",
        title: "Available Fields",
        fieldsData: availableFields,
      },
      freezedColumns: {
        id: "freezedColumns",
        title: "freezed Columns",
        fieldsData: freezedColumns,
      },
      selectedFields: {
        id: "selectedFields",
        title: "selected Fields",
        fieldsData: selectedFeilds,
      },
    };

    return customizeViewFieldsUpdated
  }


  const setToInitialColumns = () => {
    let selectedFeilds = reworkColumnNames
    selectedFeilds = selectedFeilds.map((ele) => {
      return ele.field_Name
    })
    const customizeViewFieldsUpdated = formCustomizeObject([], [], selectedFeilds)
    setCustomizeViewFields(customizeViewFieldsUpdated);
    localStorage.setItem("customizeViewFieldsRework", JSON.stringify(customizeViewFieldsUpdated));
  }
  const handleCustomizedView = (data) => {
    if (data !== undefined && data !== null && data?.length !== 0) {
      const parsedData = data?.selectedFields?.fieldsData?.length
        ? data?.selectedFields?.fieldsData
        : data && JSON.parse(data);
      const freezedData = parsedData?.freezedColumns?.fieldsData;
      let selectedData = parsedData?.selectedFields?.fieldsData;
      localStorage.setItem("customizeViewFieldsRework", JSON.stringify(parsedData));
      handleColumns(reworkColumnNames)
      selectedData = AcpService?.removeCommonValues(selectedData, frozenCoulmns);
      if (freezedData?.length > 0) {
        selectedData = [...freezedData, ...selectedData];
        setFrozenColumn([...freezedData]);
      }
      setCustomizeViewFields(parsedData);

    }
  };

  const onSubmitReworkReasonFun = () => {
    updateStateValue('showSelctionPopUp', true)
    updateStateValue('showReasonReworkPopUp', false)
  }

  const onReworkSubmit = () => {
    const checkCICJob = selectedNodes?.some((item) => item?.Task_Type?.includes("Request CIC"));
    if (checkCICJob) {
      updateStateValue('showReasonReworkPopUp', true)
    } else {
      updateStateValue('showSelctionPopUp', true)
    }
  }

  return (
    <PageLayout>
      <div className="rework-wrapper">
        <CustomizeView
          availableFields={[]}
          showTaskDialog={visible}
          headerName={"Rework"}
          onClose={() => setVisible(false)}
          customizeViewFields={customizeViewFields}
          setCustomizeViewFields={handleCustomizedView}
          resetToPgDefault={() => onClickClearFilter()}
          allColumns={getUpdatedAllColumnFormat(reworkColumnNames)}
        />
        <ReworkHeader
          expandedKeys={state.expandedKeys}
          toggleApplications={toggleApplications}
          onSearchClick={onSearchClick}
          selectedRows={selectedNodes}
          allData={flatPegadata}
          headers={columnNames}
          filename={`Rework_Records`}
          clearFilter={onClickClearFilter}
          isFilterEnabled={isGlobalFilterActivated}
          setVisible={() => setVisible(true)}
        />
        <div className="myProjectAnddAllProjectList">
          <div className="card">
            {tableData && tableData.length ? (
              <>
                <ConfirmationPopUp
                  setProjectFrozen={false}
                  projectData={flatPegadata}
                  onGlobalFilterChange={(e, value) => onFilterChange(e, value)}
                  selectedColumnName={state.selectedColumnName}
                  ProjectFrozen={state.projectFrozen}
                  selectedFields={state.selectedFields}
                  frozenCoulmns={state.frozenColumns}
                  filters={state.filters}
                  op={op}
                  clearColumnWiseFilter={() => clearColumnWiseFilter()}
                  frozenUpdated={frozenUpdated}
                  setFrozenUpdated={setFrozenUpdated}
                  fieldUpdated={fieldUpdated}
                  setFieldUpdated={setFieldUpdated}
                  columnWiseSelectedFields={state.columnWiseSelectedFields}
                  setColumnWiseSelectedFields={(value) =>
                    updateStateValue("columnWiseSelectedFields", value)
                  }
                  showSort={false}
                  isRework={true}
                />
                <CommonTreeTable
                  data={reworkTableData}
                  selectedNodeKeys={state.selectedNodeKeys}
                  setSelectedNodeKeys={(value) => updateStateValue("selectedNodeKeys", value)}
                  setExpandedKeys={(node) => updateStateValue("expandedKeys", node)}
                  expandedKeys={state.expandedKeys}
                  frozenWidth={frozenWidth}
                  onColumnResizeEnd={onColumnResizeEnd}
                  scrollHeight={state.tableHeight}
                  onSelectColumn={(rowData) => onSelectColumn(selectedNodes, rowData)}
                >
                  {dynamicColumns()}
                </CommonTreeTable>
              </>
            ) : (
              "No Records Found"
            )}
          </div>
          <Dialog
            visible={state.visible}
            onHide={toggleDialog}
            showHeader={true}
            header={headerContent}
            id="reworkDialog"
            className='rejectionReason'
          >
            <RejectReason headingText={state.cicHeader} cicCommentList={state.cicCommentList} visible={state.visible} />
          </Dialog>
          <Dialog
            visible={state.showSelctionPopUp}
            onHide={() => updateStateValue("showSelctionPopUp", false)}
            className="SelectionPopUpOverlay"
            maximizable
          >
            <SelectionPopUp
              selectedNodes={selectedNodes}
              unSelectedNodes={deSelValRef?.current}
              treedata={reworkTableData}
              onSubmission={() => updateStateValue("showSelctionPopUp", false)}
              projectPlanData={projectPlanDesign}
            />
          </Dialog>
          <Dialog
            visible={state.showReasonReworkPopUp}
            onHide={() => updateStateValue('showReasonReworkPopUp', false)}
            style={{ width: popUpConst.popUpWidth }}
          >
            <ReasonForRework
              reworkHistoryData={reworkHistory}
              selectedData={selectedNodes}
              treedata={reworkTableData}
              onSubmitReworkReason={onSubmitReworkReasonFun}
              onClosePopUp={() => { updateStateValue('showReasonReworkPopUp', false) }}
            />
          </Dialog>
        </div>
        <div className='rework-footer'>
          <footer>
            <Button variant="secondary" onClick={() => navigate(projectPlanUrl)}>
              Back
            </Button>
            <Button onClick={() => onReworkSubmit()} className={_.isEmpty(selectedNodes) ? "disabled" : ""}>Next</Button>
          </footer>
        </div>
      </div>
    </PageLayout>
  );
}
export default Rework;
