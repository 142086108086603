// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggleButton .clear-btn {
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  margin-right: 0.8rem;
  border-radius: 0.3rem;
  opacity: 0rem;
  border: none;
}
.toggleButton .active {
  background-color: #003da5;
  color: #ffffff;
}
.toggleButton .deactive {
  background-color: #ffffff;
  color: #595959;
}
.toggleButton .inactive {
  background-color: #acacac;
  color: #ffffff;
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/components/ToggleButton/ToggleButton.scss"],"names":[],"mappings":"AACE;EACE,oCAAA;EACA,oBAAA;EACA,qBAAA;EACA,aAAA;EACA,YAAA;AAAJ;AAGE;EACE,yBAAA;EACA,cAAA;AADJ;AAIE;EACE,yBAAA;EACA,cAAA;AAFJ;AAKE;EACE,yBAAA;EACA,cAAA;EACA,eAAA;AAHJ","sourcesContent":[".toggleButton {\n  .clear-btn {\n    padding: 0.3rem 0.5rem 0.3rem 0.5rem;\n    margin-right: 0.8rem;\n    border-radius: 0.3rem;\n    opacity: 0rem;\n    border: none;\n  }\n\n  .active {\n    background-color: #003da5;\n    color: #ffffff;\n  }\n\n  .deactive {\n    background-color: #ffffff;\n    color: #595959;\n  }\n\n  .inactive {\n    background-color: #acacac;\n    color: #ffffff;\n    cursor: default;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
