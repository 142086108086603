import * as types from "../types/types";

export const acpBookingData = (data) => ({
  payload: data,
  type: types.ACP_BOOKING_DATA,
});
export const acpDashboardProjectsRequiringPM = (data) => ({
  payload: data,
  type: types.ACP_DASHBOARD_PROJECTS_REQUIRING_PM,
});

export const acpBookingCapacityValuesData = (data) => ({
  payload: data,
  type: types.ACP_BOOKING_CAPACITY_VALUES_DATA,
});

export const updateRTATasksResponse = (data) => ({
  payload: data,
  type: types.UPDATE_RTA_TASKS_RESPONSE,
});

export const emptyErrorData = (data) => ({
  payload: data,
  type: types.EMPTY_ERROR_DATA,
});
