import React, { useEffect, lazy, Suspense, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import { getTasks } from '../../../store/actions/TaskActions';

const TaskList = lazy(() => import('../TaskList'));

const MyTask = () => {
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const dispatch = useDispatch();
  const { myTasks, loading } = useSelector((state) => state.TaskReducer);
  const {
    userDetails: { UserGroup },
  } = useSelector((state) => state.UserDetailsReducer);
  const [clearAllFilter , setClearAllFilter ] = useState(false)

  const filteredTasks = myTasks
    ? myTasks.filter((task) => {
        const firstPart = task.AWM_Task_ID.split('_')[0];
        const excludedPrefixes = ['URDT', 'UDAI', 'UPRA'];
        const isExcludedTask = excludedPrefixes.includes(firstPart) && task.Status === 'Complete';

        return (
          // (task.Status === "Available" || task.Status === "Complete") &&
          !isExcludedTask
        );
      })
    : [];

  useEffect(() => {    
    if(UserGroup?.length && userInformation?.userid) {
      const payLoad = {
        ArtworkAgilityPage: {
          PM: userInformation.userid,
          UserGroup: UserGroup,
        },
      };
      dispatch(getTasks(payLoad));
    }
  }, [dispatch, UserGroup?.length, userInformation?.userid]);

  return (
    <>
      <Suspense fallback={<LoadingOverlay active={true} spinner text="" />}>
        <TaskList
          myTasks={filteredTasks}
          loading={loading}
          flag="myTasks"
          userInformation={userInformation}
          setClearAllFilter ={setClearAllFilter}
        />
      </Suspense>
    </>
  );
};

export default MyTask;
