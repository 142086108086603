import React, { useState } from "react";
import { useSelector } from "react-redux";
import { textFieldMaxLength } from "../../constants/index";
import { CharacterLimit } from "../common/CharacterLimit/CharacterLimit";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import { AddNewReasonMessage } from '../../../src/constants/reworkPageTasks';

const AddNewReason = ({ markUpObj, job, handelDelete, handleChange }) => {
    const [comments, setComments] = useState("");
    const {
        DropDownValuesData: {
            ArtworkAgilityTasksPage: { Defect_Type, Proofscope_Rejection, Artwork_Role },
        },
    } = useSelector((state) => state.DropDownValuesReducer);

    const handleInputChange = (e) => {
        setComments(e.target.value);
        handleChange(e, markUpObj, job, "Comments");
    };


    return (<>
        <div className="add-new-reason-wrapper">
            <div className="addNewReason">
                <div className="contentWrapper">
                    <div className="flex-item">
                        {markUpObj?.marukUp}
                    </div>
                    <div className="flex-item">
                        {markUpObj?.marukUp === 1 && <span>
                            {AddNewReasonMessage.defect}
                        </span>}
                        <select
                            class="form-select"
                            name={"DefectType"}
                            aria-label="Default select"
                            onChange={(e) => handleChange(e, markUpObj, job, "DefectType")}
                        >
                            {Defect_Type?.map((defect) => {
                                return <option value={defect.DefectType}>{defect.DefectType}</option>;
                            })}
                        </select>
                    </div>
                    <div className="flex-item">
                        {markUpObj?.marukUp === 1 && <span>
                            {AddNewReasonMessage.reason}
                        </span>}
                        <select
                            class="form-select"
                            name="Reason"
                            aria-label="Default select"
                            onChange={(e) => handleChange(e, markUpObj, job, "ReasonForRework")}
                        >
                            {Proofscope_Rejection?.map((reason) => {
                                return (
                                    <option value={reason.RejectionReason}>{reason.RejectionReason}</option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="flex-item">
                        {markUpObj?.marukUp === 1 && <span>
                            {AddNewReasonMessage.responsible}
                        </span>
                        }
                        <select
                            class="form-select"
                            name="ResponsibleFunctionRole"
                            aria-label="Default select"
                            onChange={(e) => handleChange(e, markUpObj, job, "ResponsibleFunctionRole")}
                        >
                            {Artwork_Role?.map((role) => {
                                return <option value={role.Role_Name}>{role.Role_Name}</option>;
                            })}
                        </select>
                    </div>
                    <div className="flex-item">
                        {markUpObj?.marukUp === 1 && <span>
                            {AddNewReasonMessage.comment}
                        </span>}
                        <input
                            list="datalistOptions"
                            id="exampleDataList"
                            name="Comment"
                            placeholder="please add comments"
                            // onChange={(e) => handleChange(e, markUpObj, job, "Comments")}
                            onChange={handleInputChange}
                            className={comments === 0 ? 'form-control' : comments?.length >= textFieldMaxLength ? 'form-control border-danger' : 'form-control'}
                            maxLength={textFieldMaxLength}
                        />
                        <CharacterLimit field={comments} maxLength={textFieldMaxLength} />
                    </div>
                    <div>
                        <img
                            src={deleteIcon}
                            alt="filter logo"
                            onClick={() => handelDelete(markUpObj, job)}
                            className="header-icons"
                        />
                    </div>
                </div>
                <div className="horizontal-line"></div>
            </div>
        </div>
    </>)
}
export default AddNewReason

