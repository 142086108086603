import { Dialog } from "primereact/dialog";
import React from "react";
import { Button } from "primereact/button";
import { Row, Col } from "react-bootstrap";
import { Yes, No, Close } from "../constant";
import "./index.scss";
import ExportToExcel from "./ExportToExcel";

function AcpCloseDialog({
  showCloseDialog,
  setShowCloseDialog,
  handleNavigationTab,
  popupCloseMessageText,
  isAcp,
  setShowAcpBookingDialog,
  data,
  dashboardPMTableDataRefresh,
  headerClassName = "p-dialog-acp",
  setImportStatus,
  setValue,
}) {
  const handleCloseModal = () => {
    setShowCloseDialog(false);
    if (setValue) setValue("");
    if (setImportStatus) {
      setImportStatus();
    }
    if (setShowAcpBookingDialog) {
      setShowAcpBookingDialog(false);
    }
    if (isAcp) {
      dashboardPMTableDataRefresh();
    }
  };

  return (
    <Dialog
      visible={showCloseDialog}
      className="acp-dialog acpCloseDialogSubContainer headerIcon" //header icon styling contain the background warning icon
      onHide={() => {
        handleCloseModal();
      }}
      header={<div className={headerClassName}>{popupCloseMessageText}</div>}
    >
      <div className="col-sm-12">
        {isAcp ? (
          <Row>
            {data ? (
              <Col>
                Report : Import Report.xlsx{" "}
                <ExportToExcel allData={data} filename="Upload_long_term_projects" />
              </Col>
            ) : null}
            <Row className="justify-content-center mt-3">
              <Button
                type="btn"
                className="btn btn-primary mb-4"
                onClick={() => handleCloseModal()}
              >
                {Close}
              </Button>
            </Row>
          </Row>
        ) : (
          <Row className="justify-content-end ">
            <Button type="btn" className="btn btn-secondary" onClick={() => handleCloseModal()}>
              {No}
            </Button>

            <Button
              type="btn"
              onClick={() => handleNavigationTab()}
              disabled={null}
              className="btn btn-primary ms-3"
            >
              {Yes}
            </Button>
          </Row>
        )}
      </div>
    </Dialog>
  );
}

export default AcpCloseDialog;
