export const DEFAULT_TASK_COLNAMES = [
    "Task_Name",
    "Task_Type",
    "Project_Name",
    "Status",
    "Help_Needed",
    "Buffer",
    "Buffer_To_Work",
    "Remaining_Work",
    "Assignee",
];

export const ALL_TASKS = "allTasks";
export const MY_TASKS = "myTasks";
export const COMPLETE = "Complete";
export const INPROGRESS = "In-Progress";

export const textAreaMaxLength = 512;
export const textFieldMaxLength = 256;
export const LEVEL=[{LEVEL:"Low :",
DESCRIPTION:" No Color Development & print trial needed"},
{LEVEL:"Medium :",DESCRIPTION:" Color Development needed, print trial is optional"},
{LEVEL:"High :",DESCRIPTION:" Color development needed, print trial is strongly recommended"}]

export const warningMessage="If you leave this section, your changes will not be saved. Do you want to continue?";
export const confirmLeaveMessage="You have unsaved changes, are you sure you want to leave?";
export const saveWarningMessage="Please Review The Mapping and Save Before Proceeding.";
