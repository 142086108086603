const reworkTasksCode = [
   'RCIC',
   'RCICM',
   'ACIC',
   'ACICM',
   'ARDT',
   'CCD',
   'CNIQ',
   'CPPFA',
   'CPTD',
   'UADI',
   'UCIC',
   'UCICM',
   'URDT',
   'RWK',
];

export default reworkTasksCode;

export const popUpConst = {
  headerText: 'Selected tasks have been marked for rework. Are you sure you want to proceed?',
  textVal:
    'Confirming the action will update and recalculate the entire project, and all selected tasks will be marked for rework.',
  preHeadSel: 'Selected Task',
  preHeadDeSel: 'DeSelected Task',
  task: 'Task',
  taskID: 'Task ID',
  submit: 'Submit',
  cancel: 'Cancel',
  success: 'Tasks are submitted sucessfully for Rework',
  inputRework: 'rwk',
  approveRTD: 'ardt',
  popUpWidth:'70vw',
};
export const completeReject = "complete-rejected"
export const messages = {
  successMessage: 'Task is submitted SuccessFully',
  errorMessage: 'Task is not submitted due to some problem, please try after sometime',
};

export const RejectionReason = {
  nomarkup: "No Markup Found",
  sNo: "S.No.",
  markup: "MarkUp",
  defectType: "Defect Type",
  reason: "Reson",
  responsible: "Responsible Function/Role",
  comment: "Comments",
  rejectionHeader:"Rejection Reson "
}
export const historyColumns = [
  { field: 'ReworkCounter', header: 'Rework' },
  { field: 'DefectType', header: 'Defect Type*' },
  { field: 'Reason', header: 'Reason*' },
  { field: 'ResponsibleFunctionRole', header: 'Responsible Function/Role*' },
  { field: 'Comment', header: 'Comment' },
];
export const rejectionColumns = [
  { field: 'sNo', header: 'S. No.' },
  { field: 'ResponsibleFunctionRole', header: 'Responsible Function/Role*' },
  { field: 'Reason', header: 'Reason*' },
  { field: 'DefectType', header: 'Defect Type*' },
  { field: 'Comment', header: 'Comment' },
];
export const RejectionMessage = {
  reason: 'Reason for Rework',
  input: 'Input rework needed',
  rejection: 'Rejection Reason',
  history: 'Rework History',
  add: 'Add',
  back: 'Back',
  submit: 'Submit',
  popUpWidth:'50rem'
}

export const AddNewReasonMessage = {
  markup: 'Markup',
  defect: 'Defect',
  reason: 'Reason',
  responsible: 'Responsible Function/Role',
  comment: 'Comment'
}
