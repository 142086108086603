import { Link } from 'react-router-dom';
import './../scss/errors.scss';
import { isEmpty } from 'lodash';

export default function ErrorPage(props) {
  let params = new URL(document.location.toString()).searchParams;
  const code = params.get('code') || props.code;

  const errors = [
    {
      code: 400,
      title: 'We are sorry, Bad request!',
      content:
        'The page you are looking for might have been removed had its name changed or is temporarily unavailable.',
    },
    {
      code: 404,
      title: 'We are sorry, Page not found!',
      content:
        'The page you are looking for might have been removed had its name changed or is temporarily unavailable.',
    },
    {
      code: 500,
      title: 'We are sorry, Something went wrong!',
      content:
        'The page you are looking for might have been removed had its name changed or is temporarily unavailable.',
    },
  ];

  return (
    <div id="notfound">
      <div className="notfound">
        {!isEmpty(code) &&
          errors.map(
            (item, key) =>
              item.code === code && (
                <div key={`outer-div-${key}`}>
                  <div className="notfound-inner" key={`inner-div-${key}`}>
                    <h1>{item.code}</h1>
                  </div>
                  <h2 key={`title-${key}`}>{item.title}</h2>
                  <p key={`content-${key}`}>{item.content}</p>
                </div>
              )
          )}
        <Link to="/">Back To Homepage</Link>
        <Link to={process.env.REACT_APP_LOGOUT_URL} replace>
          Logout
        </Link>
      </div>
    </div>
  );
}
