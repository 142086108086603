import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { getAccessToken, getPingIdCode, hasRequiredAuthData, processRedirectionAfterLogin, validateCodeOnRedirect } from "../Utils/helpers";
import { isEmpty } from "lodash";
import { useEffect } from "react";

const CallbackRoute = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const redirected = searchParams.get('redirected')
    const oauth2Error = searchParams.get('error')

    // 1. Validate code on redirection from 0auth2
    const { validated } = validateCodeOnRedirect()
    const pingIdcode = getPingIdCode()
    const isValid = hasRequiredAuthData()

    useEffect(() => {
        console.info('Ready to call exchange token', pingIdcode, validated, redirected, oauth2Error, isValid)
        if (!isValid && validated && redirected !== 'true' && isEmpty(oauth2Error)) {
            console.info('Calling PingID Oauth2 to exchange access token', pingIdcode)
            getAccessToken(pingIdcode).then(async (data) => {
                if (data === "Failed to fetch access token") {
                    throw new Error(data)
                }

                const formatedTokenRes = {
                    ...data,
                    tokenExpiryON: new Date().setSeconds(new Date().getSeconds() + data.expires_in),
                }

                await processRedirectionAfterLogin(formatedTokenRes, navigate)
            }).catch(err => {
                console.error(err)
                navigate({
                    pathname: '/error',
                    search: createSearchParams({
                        auth: false,
                        redirected: true,
                        code: 500
                    }).toString(),
                    replace: true,
                })
            })
        } else {
            // NOTE: Sometime navigate not working, so that using navingation in this way
            window.location.href = `${process.env.REACT_APP_URI}/error?code=400`
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
};

export default CallbackRoute;