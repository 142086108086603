// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version-history-dialog .file-version-icon-container img {
  cursor: pointer;
}
.version-history-dialog .version-history-table .version-list-header {
  background: var(--Grey-for-background, #fafafa);
  border-width: 0 0 1px 0;
}
.version-history-dialog .version-history-table .version-list-data-row {
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/common/FileVersionHistory/index.scss"],"names":[],"mappings":"AAEI;EACE,eAAA;AADN;AAKI;EACE,+CAAA;EACA,uBAAA;AAHN;AAKI;EACE,yBAAA;EACA,uBAAA;EACA,kBAAA;AAHN","sourcesContent":[".version-history-dialog {\n  .file-version-icon-container {\n    img {\n      cursor: pointer;\n    }\n  }\n  .version-history-table {\n    .version-list-header {\n      background: var(--Grey-for-background, #fafafa);\n      border-width: 0 0 1px 0;\n    }\n    .version-list-data-row {\n      border: 1px solid #dee2e6;\n      border-width: 0 0 1px 0;\n      padding: 1rem 1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
