export const dsbpConstant = {
    xml_tooltip_value: "Awaiting response from RTA to share the COPY XML. Upload task will be sent to Agencies after this request is completed.",
    xml_awaiting_response_enovia: "POA Update in progress. Awaiting response from Enovia",
    xml_awaiting_response: "awaiting response",
    xml_view_changes_btn:"View changes",
    xml_Fail_response: "FAIL",
    xml_Error_response: "Error",
    xml_Success_response: "Success",
    xml_view_changes:"View changes!",
    xml_locked:"LOCKED",
    not_cic: "The PMPs you have selected is not a CIC. Please verify your selection",
    fullkit_not_ready: "Fullkit for all/part of PMPs you have selected is not ready. Please verify your selection",
    cic_request_sent: "CIC request for all/part of the PMPs already sent. Please verify your selection",
    cic_request_sent_no_cic: "Either CIC/CIC Matrix Request is alreday sent or the selected PMPs are not a CIC. Please verify your selection.",
    cic_matrix_added: "Please remove association from previous CIC Matrix before it can be linked with new CIC Matrix.",
    unknown_operation: "Unknown operation",
    requestedCicAcknowledgementHeader: "CIC/CIC Matrix requested",
    requestedCicAcknowledgementSuccessMessage: `Automated Copy XML request is in progress. Once it's completed, the "Upload CIC/CIC Matrix" task will be sent. 
    It should be done within 15 minutes.`,
    requestedCicAcknowledgementErrorMessage: "Automated Copy XML request was not accepted by RTA, CIC will not be requested. Please try again, if problem persists, please open a ticket",
    fromActionFullkitNotReadyMessage: "Fullkit is not ready for the CIC/CIC Matrix. Please verify and try again.",
    fromActionNoPMPMessage: "No PMPs are Locked in DSBP",
    requestCICandCICMatrix: "Request CIC/CIC Matrix",
    cicTooltipMessage:"When CIC needed = No, Complete at least one of: 'Supporting PMP layout,' 'Supporting PMP design,' or 'Other reference' before submitting.",
    deleteCicMatrixMessage:"Are you sure you want to remove this CIC from CIC Matrix?"
}