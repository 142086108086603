import * as types from "../types/types";

const initialState = {
  token: {},
};

const Authentication = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case types.CLEAR_TOKEN:
      return {
        ...state,
        token: {}
      }
    default:
      return state;
  }
};

export default Authentication;
