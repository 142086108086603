import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import enabledDelete from "../../../assets/images/enabled-delete.svg";
import disabledDelete from "../../../assets/images/disabled-delete.svg";
import DsbpCommonPopup from "../../DSBP/DsbpCommonPopup";
import FileUploadManager from "../../common/FileUploadManager/FileUploadManager";

const UploadBriefingDocuments = ({
  azureSubFolder,
  serial,
  index,
  handleDelete,
  item,
  checkReadWriteAccess,
  length,
  fileUploadSection,
  fileUploadType,
  version,
  getDataSaveAsDraft,
  fileUploadWarning,
  File_NameFromAPI,
  updateUbdData,
  setWrongFileName,
  disableDelete,
  setFileNotFound,
  groupName,
  businessUnit,
  pName,
  taskStatus,
  onFileClear,
  onFileUpload,
  sequence
  // setAzureFile,
}) => {
  const [fileName, setFileName] = useState("");
  const [selectedReason, setSelectedReason] = useState(false);

  // console.log("item121:", index, item);
  const [selectDialog, setSelectDialog] = useState(false);
  // const [uploadedWrongFilename, setUploadedWrongFilename] = useState(false);
  // useEffect(() => {
  //   if (uploadedWrongFilename) {
  //     setWrongFileName(true);
  //   } else {
  //     setWrongFileName(false);
  //   }
  // }, [uploadedWrongFilename]);

  return (
    <div>
      {selectDialog && (
        <DsbpCommonPopup
          actionHeader="Are you sure you want to delete this file ?"
          dasbpDialog={selectDialog}
          setDasbpDialog={setSelectDialog}
          rejectFormData={[{}]}
          onSubmit={() =>
            onFileClear(fileUploadSection, item)
          }
          okButtonShow={false}
          deleteButtonShow={true}
          yesButtonShow={true}
          submitButtonShow={true}
          disconnectButtonShow={true}
          showCancel={true}
          setSelectedReason={setSelectedReason}
        >
          <>{item.File_Name !== "" ? item.File_Name : fileName}</>
        </DsbpCommonPopup>
      )}
      <Row
        style={{
          marginLeft: 15,
          marginRight: 15,
          marginBottom: 15,
          marginTop: 5,
        }}
      >
        <Col sm={9}>
          <FileUploadManager
            taskdata={[item]}
            customUpload
            onFileUpload={onFileUpload}
            checkReadWriteAccess={checkReadWriteAccess}
            taskStatus={taskStatus}
            onFileClear={onFileClear}
            subFolder={azureSubFolder}
            fileUploadSection={fileUploadSection}
            sequence={sequence}
          />
        </Col>
        <Col sm={3}>
          <div className="d-flex flex-column text-center">
            <label htmlFor="additional">Delete</label>
            <div style={{ marginTop: 13 }}>
              <img
                src={disableDelete ? disabledDelete : enabledDelete}
                alt="delete logo"
                onClick={() =>
                  !disableDelete &&
                  setSelectDialog(true)
                }
                className="header-icons"
                disabled={disableDelete || taskStatus === "Complete" || !checkReadWriteAccess}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UploadBriefingDocuments;
