// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-menu.p-menu-overlay.file-menu-options {
  border-radius: 0.84rem;
  padding: 0.84rem;
  border: 0.084rem solid #acacac;
  z-index: 100012 !important;
}
.p-menu.p-menu-overlay.file-menu-options .p-menu-list {
  display: flex;
  flex-direction: column;
  gap: 0.33rem;
}
.p-menu.p-menu-overlay.file-menu-options .p-menu-list .file-menu-options-listitem .p-menuitem-link {
  padding: 0;
  display: flex;
  gap: 0.33rem;
}

.file-list-data-row {
  padding: 0rem !important;
}
.file-list-data-row .filename-container {
  display: flex;
  align-items: center;
  white-space: break-spaces;
  word-break: break-all;
}`, "",{"version":3,"sources":["webpack://./src/components/common/UploadedFileNameAndOptions/index.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,gBAAA;EACA,8BAAA;EACA,0BAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;AAEM;EACE,UAAA;EACA,aAAA;EACA,YAAA;AAAR;;AAMA;EAQE,wBAAA;AAVF;AAGE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,qBAAA;AADJ","sourcesContent":[".p-menu.p-menu-overlay.file-menu-options {\n  border-radius: 0.84rem;\n  padding: 0.84rem;\n  border: 0.084rem solid #acacac;\n  z-index: 100012 !important;\n\n  .p-menu-list {\n    display: flex;\n    flex-direction: column;\n    gap: 0.33rem;\n\n    .file-menu-options-listitem {\n      .p-menuitem-link {\n        padding: 0;\n        display: flex;\n        gap: 0.33rem;\n      }\n    }\n  }\n}\n\n.file-list-data-row {\n  .filename-container {\n    display: flex;\n    align-items: center;\n    white-space: break-spaces;\n    word-break: break-all;\n  }\n\n  padding: 0rem !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
