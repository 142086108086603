import { useSelector } from 'react-redux';

export const getRoleWithPriority = (accGroup) => {
  if (accGroup !== undefined) {
    const roleList = accGroup?.map((obj) => obj.AccessGroupNames);
    const obj = {};
    const roleWithPriority = [];

    roleList.forEach((role) => {
      roleWithPriority.push({ ...obj, accessRole: role, priority: getPriority(role) });
    });
    const highPriority = roleWithPriority.map((ele) => ele.priority);
    return Math.min(...highPriority);
  } else {
    return false;
  }
};

export const getPriority = (role) => {
  switch (role) {
    case 'AAS:CapacityManager':
      return 1;
      break;
    case 'AAS:ProjectManager':
      return 2;
      break;
    case 'AAS:TaskOwner':
      return 4;
      break;
    case 'AAS:ExternalTaskOwner':
      return 3;
      break;

    default:
      break;
  }
};

// planned to use deferent logic to get highest priority role
const rolesAndAccess = [
  {
    role: 'AAS:CapacityManager',
    pages: [
      { pageName: 'myProjects', access: ['hidden'] },
      { pageName: 'allProjects', access: ['write'] },
      { pageName: 'myTasks', access: ['hidden'] },
      { pageName: 'allTasks', access: ['hidden'] },
      { pageName: 'capacityManagement', access: ['write'] },
    ],
  },
  {
    role: 'AAS:ProjectManager',
    pages: [
      { pageName: 'myProjects', access: ['write'] },
      { pageName: 'allProjects', access: ['write'] },
      { pageName: 'myTasks', access: ['hidden'] },
      { pageName: 'allTasks', access: ['read'] },
      { pageName: 'capacityManagement', access: ['read'] },
    ],
  },
  {
    role: 'AAS:TaskOwner',
    pages: [
      { pageName: 'myProjects', access: ['hidden'] },
      { pageName: 'allProjects', access: ['read'] },
      { pageName: 'myTasks', access: ['read'] },
      { pageName: 'allTasks', access: ['hidden'] },
      { pageName: 'capacityManagement', access: ['read'] },
    ],
  },
  {
    role: 'AAS:ExternalTaskOwner',
    pages: [
      { pageName: 'myProjects', access: ['hidden'] },
      { pageName: 'allProjects', access: ['hidden'] },
      { pageName: 'myTasks', access: ['write'] },
      { pageName: 'allTasks', access: ['write'] },
      { pageName: 'capacityManagement', access: ['hidden'] },
    ],
  },
];

export const findHighestAccesRole = (accesControl) => {
  const roleAccess = accesControl.filter((ele) => {
    return ele?.pageInfo?.access?.[0] === 'read' || ele?.pageInfo?.access?.[0] === 'write';
  });

  if (roleAccess?.length > 0) {
    const getHighestRole = roleAccess.find((ele) => {
      return ele?.pageInfo?.access?.[0] === 'write';
    });

    if (getHighestRole === undefined) {
      const secondHighest = roleAccess.find((ele) => {
        return ele?.pageInfo?.access?.[0] === 'read';
      });

      return secondHighest.pageInfo?.access?.[0] === 'write'; // Return true if second highest is "write," else false
    }
    return getHighestRole.pageInfo?.access?.[0] === 'write'; // Return true if highest is "write," else false
  }
  return false; // Return false if there is no access
};

// export const findHighestAccesRole = (accesControl) => {
//     const roleAccess = accesControl.filter(ele => {
//         return ele.pageInfo.access[0] === "read" || ele.pageInfo.access[0] === "write";
//     })

//     const getHighestRole = roleAccess.find(ele => {
//         return ele.pageInfo.access[0] === "write"
//     })

//     if (getHighestRole === undefined) {
//         const secondHighest = roleAccess.find(ele => {
//             return ele.pageInfo.access[0] === "read"
//         })
//         return [roleAccess, secondHighest]
//     }

//     return [roleAccess, getHighestRole]
// }

export const usePriorityNew = (pageName) => {
  const {
    userDetails: { AccessGroup },
  } = useSelector((state) => state.UserDetailsReducer);

  const getAccesBasedOnPage = [];
  let tempObj = {};
  AccessGroup?.forEach((selRole) => {
    getAccesBasedOnPage.push({
      ...tempObj,
      roleInfo: rolesAndAccess.find((r) => {
        if (r.role === selRole.AccessGroupNames) {
          return r.role;
        }
      }).role,
      pageInfo: rolesAndAccess
        .find((r) => {
          if (r.role === selRole.AccessGroupNames) {
            return r.role;
          }
        })
        .pages?.find((pg) => {
          return pg.pageName.toLowerCase() === pageName.toLowerCase();
        }),
    });
  });

  // false means read access and true means write access
  let data = false;
  if (getAccesBasedOnPage) {
    data = findHighestAccesRole(getAccesBasedOnPage);
  }
  return data;
};
