import Api from ".";
import { DEVURL } from "./envUrl";
import { cloneDeep } from "lodash";


//getColumnsOrder is to call the API to fetch the details related to table and customize view based on screenname
export const getColumnsOrder = async (userId, screenName, bu, region, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/getScreenViewDetails/${userId}/${screenName}/${bu}/${region}`;
  // let apiURL = `https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v1/GetScreenViewDetails/${userId}/${screenName}`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "GET",
  });
  let response = ""
  if (addResponse.status === 200 && addResponse?.data?.FormData !== "") {
    const decodedData = window.atob(addResponse.data.FormData)
    response = decodedData !== "" && decodedData != null && decodedData != undefined ? JSON.parse(decodedData) : []
    response=typeof response === "string"? JSON.parse(response):response
  }
  return response
};


//setColumnsOrder is to call the API to save customize view and datatable information based on screenname, if remove is passed true then storage will be removed
export const setColumnsOrder = async (
  userId,
  screenName,
  bu,
  region,
  remove,
  formData,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/storeScreenViewDetails`;
  const strFormData = cloneDeep(formData);
  let request;
  if (strFormData != null && strFormData != "") {
    const strFormDataRes = JSON.stringify(formData)
    request = strFormDataRes != null && strFormDataRes != "" ? window.btoa(strFormDataRes) : ""
  }
  if(remove){//when resettop&GDefault is called pass "" in the formdata
    request =  window.btoa("") 
  }
  // let apiURL = "https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v1/StoreScreenViewDetails"
  await axiosInstance({
    url: apiURL,
    method: "POST",
    data: {
      User: userId,
      ScreenName: screenName,
      BU: bu,
      Region: region,
      Remove: remove,
      FormData: request,
    },
  });
};
