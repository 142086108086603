const initialState = {
  apiError: null,
};

const ApiErrorReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case "API_ERROR":
      return {
        ...state,
        apiError: payload,
      };
    default:
      return state;
  }
};

export default ApiErrorReducer;
