import { useParams, useLocation, NavLink } from 'react-router-dom';
import { changehandingHeader } from './ChangeNotificationConstant';

const ChangeNotificationHeader = ({
  AWM_SnapChange_CIC,
  AWM_SnapChange_FA,
  AWM_SnapChange_POACreate,
  AWM_SnapChange_Editable,
  assignee,
  loader,
}) => {
  const location = useLocation();
  let { ProjectID } = useParams();
  const locationPath = location?.pathname;
  const url = locationPath?.split('/');
  const breadcrumb = (
    <div>
      <nav className="p-breadcrumb p-component ProjectPlanBreadCrum" aria-label="Breadcrumb">
        <ul>
          <li className="">
            <NavLink to={`/${url[1]}`} className="p-menuitem-link">
              <span className="p-menuitem-text">
                {url[1] === 'myProjects'
                  ? 'My Projects'
                  : url[1] === 'allProjects'
                  ? 'All Projects'
                  : url[1] === 'myTasks'
                  ? ' My Tasks'
                  : 'All Tasks'}
              </span>
            </NavLink>
          </li>
          <li className="p-breadcrumb-chevron pi pi-chevron-right piChevronRightMargin"></li>
          {url[2] === 'artworkAlignment' || (url[2] === 'DSBP' && url[4] === 'artworkAlignment') ? (
            <li className="">
              <NavLink
                to={`/${url[1]}/artworkAlignment/${assignee}/${ProjectID}`}
                className="p-menuitem-link"
              >
                <span className="p-menuitem-text">{changehandingHeader.ArtworkAlignment}</span>
              </NavLink>
            </li>
          ) : url[2] === 'dependencyMapping' ||
            (url[2] === 'DSBP' && url[4] === 'dependencyMapping') ? (
            <li className="">
              <NavLink to={`/${url[1]}/dependencyMapping/${ProjectID}`} className="p-menuitem-link">
                <span className="p-menuitem-text">{changehandingHeader.DependencyMapping}</span>
              </NavLink>
            </li>
          ) : url[2] === 'artworkStatus' ? (
            <li className="">
              <NavLink to={`/${url[1]}/artworkStatus/${ProjectID}`} className="p-menuitem-link">
                <span className="p-menuitem-text">{changehandingHeader.artworkStatus}</span>
              </NavLink>
            </li>
          ) : (
            ''
          )}
          <li className="p-breadcrumb-chevron pi pi-chevron-right piChevronRightMargin"></li>
          {url[2] === 'DSBP' && (
            <li className="">
              {url[4] === 'dependencyMapping'?
              <NavLink
                to={`/${url[1]}/DSBP/tab/${url[4]}/${assignee}/${ProjectID}`}
                className="p-menuitem-link"
              >
                <span className="p-menuitem-text">{changehandingHeader.DSBP}</span>
              </NavLink>:
              <NavLink
                to={`/${url[1]}/DSBP/tab/${url[4]}/${ProjectID}`}
                className="p-menuitem-link"
              >
                <span className="p-menuitem-text">{changehandingHeader.DSBP}</span>
              </NavLink>}
            </li>
          )}
          {url[2] === 'DSBP' && (
            <li className="p-breadcrumb-chevron pi pi-chevron-right piChevronRightMargin"></li>
          )}
          <li>
            <div className="artworkchangebread">{changehandingHeader.ReviewChanges}</div>
          </li>
        </ul>
      </nav>
    </div>
  );
  return (
    <div className="artworkchangeheader">
      <div> {breadcrumb}</div>
      {!loader && (
        <div className="flag-header">
          <div className="flag-content">
            <i
              className={
                AWM_SnapChange_POACreate ? 'pi pi-circle-on redIcon' : 'pi pi-circle-on greenIcon'
              }
            ></i>
            <span> {changehandingHeader.COPY}</span>
          </div>
          <div className="flag-content">
            <i
              className={
                AWM_SnapChange_CIC ? 'pi pi-circle-on redIcon' : 'pi pi-circle-on greenIcon'
              }
            ></i>
            <span> {changehandingHeader.CIC}</span>
          </div>
          <div className="flag-content">
            <i
              className={
                AWM_SnapChange_FA ? 'pi pi-circle-on redIcon' : 'pi pi-circle-on greenIcon'
              }
            ></i>
            <span> {changehandingHeader.FA}</span>
          </div>
          <div className="flag-content">
            {AWM_SnapChange_POACreate ? (
              <i
                className={
                  AWM_SnapChange_Editable ? 'pi pi-circle-on greenIcon' : 'pi pi-circle-on redIcon'
                }
              ></i>
            ) : (
              <i className="pi pi-circle-on grayIcon"></i>
            )}
            <span> {changehandingHeader.Editable}</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default ChangeNotificationHeader;
