// basedOnFreezeLocalStorageUpdate.js
export const basedOnFreezeLocalStorageUpdate = (pageName, columnNames) => {
  switch (pageName) {
    case "My Project":
      localStorage.setItem("allColumnNames", JSON.stringify(columnNames));
      break;
    case "All Project":
      localStorage.setItem("allColumnNamesAllProjects", JSON.stringify(columnNames));
      break;
    case "myTasks":
      localStorage.setItem("myTasksAllColumnNames", JSON.stringify(columnNames));
      break;
    case "allTasks":
      localStorage.setItem("allTasksColumnNames", JSON.stringify(columnNames));
      break;
    case "booking":
      localStorage.setItem("bookingColumnNames", JSON.stringify(columnNames));
      break;
    default:
      break;
  }
};
