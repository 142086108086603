import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import PageLayout from "../../PageLayout";
import DesignHeader from "../DesignJobs/DesignHeader";
import AddNewDesign from "../DesignJobs/TaskHeader";
import AddNewDesignContent from "../DesignJobs/AddNewDesignContent";
import FooterButtons from "../DesignJobs/FooterButtons";
import {
  saveDefineProductionReadyArt,
  submitDefineProductionReadyArt,
} from "../../../apis/defineProductionReadyArt";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toLower } from "lodash";
import { AddNavigation } from "../../../utils";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import {
  selectedDesignItems,
  areAllSelectsTrue,
  checkAllDesignJobsAreDefined,
  redirectToProjectPlanOrTaskPage,
  createDIName,
  sortedDataAsPerJobID,
} from "../../../utils";

import "../DesignJobs/index.scss";
import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus } from "../../../utils";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";

const roleName = "PRA";

function DPRA() {
  const [data, setData] = useState(null);
  const [designIntent, setDesignIntent] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [submittedDI, setSubmittedDI] = useState([]);
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [projectData, setProjectData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [enableCheckBox, setEnableCheckBox] = useState(true);
  const [checked, setChecked] = useState(false);
  let { TaskID, page1, page2, pageType, ProjectID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location?.pathname;
  const dispatch = useDispatch();
  const { TaskDetailsData, loading } = useSelector((state) => state.TaskDetailsReducer);
  const headerName = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.TaskPlaceholderName}`;
  const shortName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Type;
  const { allProjects } = useSelector((state) => state.myProject);
  const id = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Key}`;
  const [pageEdited, setPageEdited] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { markDirty, markClean } = useUnsavedChangesWarning();

  let checkTaskIsComplete = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Complete" || TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Status === "Cancelled";

  useEffect(() => {
    if (pageEdited) {
      markDirty();
    } else {
      markClean();
    }
  }, [pageEdited]);

  useEffect(()=>{
    markClean();
    setPageEdited (false);
  },[]);

  let breadcrumb = AddNavigation(headerName);

  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;

  let bu = userInformation?.bu;
  let checkBU = toLower(bu) === toLower("Home Care") ? true : false;

  useEffect(() => {
    dispatch(getTaskDetails(TaskID, ProjectID));
  }, [dispatch, TaskID, ProjectID]);

  useEffect(() => {
    let allDesignJobs = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || [];
    if (TaskDetailsData) {
      let sortedData = sortedDataAsPerJobID(allDesignJobs, "Design_Job_ID");
      setDesignIntent(sortedData);
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);

      const specialProjectFlag = TaskDetailsData?.SpecialProject;
      dispatch(SpecialProjectAction(specialProjectFlag));
      redirectUnallocatedLinksToArtworkStatus(currentUrl, specialProjectFlag, navigate);
    }
    if (allDesignJobs && allDesignJobs?.length) {
      const checkAllJobsAreDefined = checkAllDesignJobsAreDefined(allDesignJobs);

      setEnableCheckBox(!checkAllJobsAreDefined);
    }
  }, [TaskDetailsData]);

  useEffect(() => {
    let projectData = allProjects?.find((project) => project.Project_ID === ProjectID);
    setProjectData(projectData);
  }, [projectData]);

  const handleCancel = () => {
    redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
  };

  const handleCancelClick = () =>{
    if(pageEdited){
      setShowDialog(true);
    }else{
      handleCancel();
    }
  }

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  }
  // it will navigate to previously saved url navigate(pendingNavigation);
  // by doing setShowDialog(false) and setPendingNavigation("");
  const confirmNavigation = () => {
    markClean();
    handleCancel();
    setPageEdited(false);
    setShowDialog(false);
  }

  const handleDelete = (index) => {
    const updatedDesignIntent = [...designIntent]; // Create a copy of the original array
    updatedDesignIntent[index].Action = "delete"; // Set the Action property to "delete" for the specified item
    updatedDesignIntent[index].Design_Job_Status = "delete";
    // Check if all items have "Action" set to "delete"
    const deleteNewlyAddedItem = updatedDesignIntent.filter(
      (item, deleteInd) => !(item.isNew === true && deleteInd === index)
    );

    setDesignIntent(deleteNewlyAddedItem);

    // Update the state with the modified array
    const checkAllJobsAreDefined = checkAllDesignJobsAreDefined(updatedDesignIntent);
    setEnableCheckBox(!checkAllJobsAreDefined);
    setDesignIntent(updatedDesignIntent);
  };

  const addNewEmptyDesign = () => {
    designIntent.push({
      Design_Job_ID: designIntent.length + 1,
      isNew: true,
      Agency_Reference: "",
      Cluster: "",
      Additional_Info: "",
      Select: false,
      Design_Job_Status: "",
    });
    setEnableCheckBox(true);
    setDesignIntent(designIntent);
    setUpdated(!updated);
    setChecked(false);
  };

  const addData = (fieldName, index, value) => {
    let Project_Name = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;
    let data = designIntent[index];
    data[fieldName] = value;
    let di_name = createDIName(
      data.Agency_Reference,
      data.Cluster,
      data.Additional_Info,
      Project_Name,
      Brand,
      Category,
      roleName
    );
    data["Design_Job_Name"] = di_name;
    data["Design_Job_Status"] = "";
    submittedDI.push(data);
    const hasValues = selectedDesignItems(designIntent, setEnableSubmit);
    setEnableSubmit(!hasValues);
    setSubmittedDI(submittedDI);
    const setCheboxValue = areAllSelectsTrue(designIntent);
    setChecked(setCheboxValue);
  };

  const onSelectAll = (checked) => {
    designIntent.map((task) => {
      if (task?.Event !== "submit") {
        task.Select = checked;
      }
      return task;
    });
    const hasValues = selectedDesignItems(designIntent, setEnableSubmit);
    setEnableSubmit(!hasValues);
    setDesignIntent(designIntent);
    setUpdated(!updated);
  };

  const onSubmit = async () => {
    setLoader(true);
    let updatedData = {};
    let updatedDataList = [];
    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };

    let submitOnlySelectedData = designIntent.filter((task) => task?.Select === true);
    submitOnlySelectedData.map((task) => {
      updatedData = {};
      if (task?.isNew) {
        task.Design_Job_ID = "";
      }
      if (task?.Action === "delete") {
        task.Action = "delete";
      } else if (task?.Action !== "delete" && task?.Design_Job_ID) {
        task.Action = "update";
      } else if (task?.Action !== "delete" && task?.isNew === true) task.Action = "add";

      updatedData.DesignJobName = task.Design_Job_Name;
      updatedData.DesignJobID = task.Design_Job_ID;
      updatedData.AgencyReference = task.Agency_Reference;
      updatedData.Cluster = task.Cluster;
      updatedData.AdditionalInfo = task.Additional_Info;
      updatedData.Select = task.Select ? task.Select : false;
      updatedData.Tier = task.Tier;
      updatedData.Action = task.Action;

      updatedDataList.push({
        instruction: "APPEND",
        target: "PRAList",
        content: updatedData,
      });
    });

    let formData = {
      caseTypeID: "PG-AAS-Work-DefineProductionReadyArt",
      content: {
        AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
        Project_Name: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name,
      },
      pageInstructions: updatedDataList,
    };

    await submitDefineProductionReadyArt(formData, id, headers);
    setLoader(false);
    redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
  };

  const onSaveAsDraft = async () => {
    setLoader(true);
    let updatedData = [];
    designIntent.filter((task) => {
      if (task?.isNew) {
        task.Design_Job_ID = "";
      }
      if (task?.Action === "delete") {
        task.Action = "delete";
      } else if (task?.Action !== "delete" && task?.Design_Job_ID) {
        task.Action = "update";
      } else if (task?.Action !== "delete" && task?.isNew === true) task.Action = "add";

      updatedData.push({
        Design_Job_Name: task.Design_Job_Name,
        Design_Job_ID: task.Design_Job_ID,
        Agency_Reference: task.Agency_Reference,
        Cluster: task.Cluster,
        Additional_Info: task.Additional_Info,
        Select: task.Select ? task.Select : false,
        Tier: task.Tier,
        Action: task.Action,
      });
    });

    let formData = {
      AWM_Project_ID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      AWM_Task_ID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
      Project_Name: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name,
      BU: projectData?.BU,
      Region: projectData?.Project_region,
      ProductionReadyArtList: updatedData,
    };

    await saveDefineProductionReadyArt(formData);
    setLoader(false);
  };
  let Brand = [];
  let Category = [];

  if (TaskDetailsData?.ArtworkAgilityPage) {
    Brand = TaskDetailsData.ArtworkAgilityPage.Artwork_Brand;
    Category = TaskDetailsData.ArtworkAgilityPage.Artwork_SMO;
  }

  return (
    <PageLayout>
      <DesignHeader
        setAddNewDesign={addNewEmptyDesign}
        onSelectAll={onSelectAll}
        breadcrumb={breadcrumb}
        headerName={headerName}
        label={shortName}
        checked={checked}
        lastLabel={"DDT"}
        pageEdited={pageEdited}
        setPageEdited={setPageEdited}
        setChecked={setChecked}
        enableCheckBox={enableCheckBox}
        checkTaskIsComplete={checkTaskIsComplete}
        {...data}
      />
      <div className="task-details">
        {<AddNewDesign {...data} TaskDetailsData={TaskDetailsData} setPageEdited={setProjectData} pageEdited={pageEdited} />}
        <LoadingOverlay active={loading || loader || designIntent === null} spinner text="">
          {designIntent &&
            designIntent.length &&
            designIntent.map((item, index) => {
              if (item && item?.Action !== "delete") {
                return (
                  <AddNewDesignContent
                    key={item.Design_Job_ID}
                    {...data}
                    item={item}
                    AWMProjectID={TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID}
                    AWMTaskID={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID}
                    Bu={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU}
                    Region={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region}
                    isTaskComplete={checkTaskIsComplete}
                    hideClearButton={false}
                    index={index}
                    addData={addData}
                    handleDelete={handleDelete}
                    roleName={roleName}
                    checkBU={checkBU}
                    pageEdited={pageEdited}
                    setPageEdited={setPageEdited}
                    Brand={Brand}
                    Category={Category}
                    taskName="Production Ready Art"
                  />
                );
              }
            })}
        </LoadingOverlay>
      </div>
      <FooterButtons
        handleCancel={handleCancelClick}
        onSaveAsDraft={onSaveAsDraft}
        onSubmit={onSubmit}
        bottomFixed={true}
        formValid={enableSubmit}
        {...data}
      />
      {/* UnsavedChangesDialog popup*/}   
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </PageLayout>
  );
}

export default DPRA;
