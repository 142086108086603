import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import plusCollapseImg from "../../../assets/images/plusCollapse.svg";
import filter from "../../../assets/images/filter.svg";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "primeicons/primeicons.css";
import _ from "lodash";
import { useSelector } from "react-redux";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";
import { CustomBreadCrum } from "../../common/CustomBreadcrum";

const IQHeader = ({
  setAddNewDesign,
  onSelectAll,
  label,
  headerName,
  disabled,
  showPage,
  data,
  selectAllCheckbox,
  showFilterHeaders,
  accessSecurityMatrix,
  setPageEdited,
  pageEdited,
  lastLabel,
  checkReadWriteAccess
}) => {
  const navigate = useNavigate();
  const { markDirty, markClean } = useUnsavedChangesWarning();
  const [showDialog, setShowDialog] = useState(false);
  const [checked, setChecked] = useState(false);
  const location = useLocation();
  let { page1, page2, pageType, TaskID, ProjectID } = useParams();
  const locationPath = location?.pathname;
  const url = locationPath?.split("/");
  const mytasks = url[1];
  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  // const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);
  // useEffect(() => {
  //   if (User && data) {
  //     if (userInformation?.userid === data?.Assignee) {
  //       setCheckReadWriteAccess(true);
  //     } else {
  //       setCheckReadWriteAccess(false);
  //     }
  //   }
  // }, [User, data]);

  const handleCrossIconClick = () => {
    if (pageEdited) {
      setShowDialog(true);
    } else {
      handleCrossIcon();
    }
  };

  const handleCrossIcon = () => {
    if (page2 && page2 === "projectPlan") {
      navigate(`/${page1}/${page2}/${ProjectID}`);
    } else if (pageType) {
      navigate(`/${pageType}`);
    }
  };

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  };
  // it will navigate to previously saved url navigate(pendingNavigation);
  // by doing setShowDialog(false) and setPendingNavigation("");
  const confirmNavigation = () => {
    markClean();
    handleCrossIcon();
    setShowDialog(false);
  };

  return (
    <div className="content-layout task-layout">
      <i
        className="pi pi-times"
        // onClick={() => {
        //   if (page2 && page2 === "projectPlan") {
        //     navigate(`/${page1}/${page2}/${ProjectID}`);
        //   } else if (pageType) {
        //     navigate(`/${pageType}`);
        //   }
        // }}
        onClick={handleCrossIconClick}
      ></i>
      <div className="actions">
        <div>
          {/* <BreadCrumb model={breadcrumb} /> */}
          <nav className="p-breadcrumb p-component" aria-label="Breadcrumb">
            <ul>
              <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>

              {url[2] === "projectPlan" ? (
                <>
                  <CustomBreadCrum
                    items={[
                      { label: _.startCase(mytasks), url: `/${mytasks}` },
                      { label: _.startCase(url[2]), url: `/${mytasks}/${url[2]}/${url[5]}` },
                    ]}
                    lastLabel={lastLabel}
                    iconColorBlack={true}
                    lastChildSecondChild={true}
                  />
                  {/* <li className="">
                    <NavLink to={`/${mytasks}`} className="p-menuitem-link">
                      <span className="p-menuitem-text">{_.startCase(mytasks)}</span>
                    </NavLink>{" "}
                  </li>{" "}
                  <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>
                  <li className="">
                    <NavLink to={`/${mytasks}/${url[2]}/${url[5]}`} className="p-menuitem-link">
                      <span className="p-menuitem-text">{_.startCase(url[2])}</span>
                    </NavLink>{" "}
                  </li> */}
                </>
              ) : (
                <>
                  {/* <li className="">
                    <NavLink to={`/${mytasks}`} className="p-menuitem-link">
                      <span className="p-menuitem-text">{_.startCase(mytasks)}</span>
                    </NavLink>{" "}
                  </li> */}
                  <CustomBreadCrum
                    items={[{ label: _.startCase(mytasks), url: `/${mytasks}` }]}
                    lastLabel={lastLabel}
                    iconColorBlack={true}
                    lastChildSecondChild={false}
                  />
                </>
              )}

              <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>
              {/* <li className="">
                <a href="#" className="p-menuitem-link">
                  <span className="p-menuitem-text">{label}</span>
                </a>
              </li> */}
              <CustomBreadCrum 
                items={[{ label: label, url: `` }]} 
                lastChildSecondChild={false}
                iconColorBlack={true}
                lastLabel={lastLabel} 
                customStyle={{color:"#003da5", fontWeight:500}}
              />
            </ul>
          </nav>
          <div className="project-title">{headerName}</div>
        </div>

        {!showFilterHeaders && (
          <div className="action-buttons">
            <div className="icon-items">
              <Checkbox
                onChange={(e) => {
                  setChecked(e.checked);
                  onSelectAll(e.checked);
                }}
                checked={selectAllCheckbox}
                className="margin-right"
                disabled={
                  (!checkReadWriteAccess ||
                    showPage === "CNIQ" ||
                    data?.Task_Status === "Complete") &&
                  true
                }
              ></Checkbox>
              <div className="icon-label">
                <label className={(!checkReadWriteAccess || disabled) && "disable-buttons"}>
                  {" "}
                  Select All{" "}
                </label>
              </div>
            </div>
            <div className="icon-items">
              <img
                src={filter}
                alt="filter logo"
                className="filter-icon filter-color-change"
                disabled={
                  (!checkReadWriteAccess ||
                    showPage === "CNIQ" ||
                    data?.Task_Status === "Complete") &&
                  true
                }
              />
              <div>
                <label
                  className="icon-label"
                  disabled={
                    (!checkReadWriteAccess ||
                      showPage === "CNIQ" ||
                      data?.Task_Status === "Complete") &&
                    true
                  }
                >
                  filter
                </label>
              </div>
            </div>
            <div className="icon-items" style={{ textAlign: "center" }}>
              <img
                src={plusCollapseImg}
                onClick={() => setAddNewDesign && setAddNewDesign()}
                className={`add-new-design-intent-icon ${
                  (!checkReadWriteAccess ||
                    showPage === "CNIQ" ||
                    data?.Task_Status === "Complete") &&
                  "disabled-add"
                }`}
                alt=""
                disabled={
                  (!checkReadWriteAccess ||
                    showPage === "CNIQ" ||
                    data?.Task_Status === "Complete") &&
                  true
                }
              />
              <div className="icon-label">
                <label
                  className={
                    (!checkReadWriteAccess ||
                      showPage === "CNIQ" ||
                      data?.Task_Status === "Complete") &&
                    "disable-buttons"
                  }
                  disabled={
                    (!checkReadWriteAccess ||
                      showPage === "CNIQ" ||
                      data?.Task_Status === "Complete") &&
                    true
                  }
                >
                  Add Ink Qualification
                </label>
              </div>
            </div>
          </div>
        )}

        {/* UnsavedChangesDialog popup*/}
        <UnsavedChangesDialog
          visible={showDialog}
          onCancel={cancelNavigation}
          onConfirm={confirmNavigation}
        />
      </div>
    </div>
  );
};

export default IQHeader;
