// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.owner-field {
  width: 80%;
  font-size: 12;
}

.owner-field-group {
  text-align: "-webkit-center";
}

.AISE-field-group {
  text-align: "-webkit-center";
}

.AddToProjectColumn {
  text-align: "-webkit-center";
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/DSBP/AgilityList.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,aAAA;AACJ;;AAEA;EACI,4BAAA;AACJ;;AAEA;EACI,4BAAA;AACJ;;AACA;EACI,4BAAA;EACA,aAAA;AAEJ","sourcesContent":[".owner-field {\n    width: 80%;\n    font-size: 12;\n}\n\n.owner-field-group {\n    text-align: '-webkit-center'\n}\n\n.AISE-field-group {\n    text-align: '-webkit-center'\n}\n.AddToProjectColumn {\n    text-align: \"-webkit-center\";\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
