// import { redirectURIs } from "../configSSO";
const DEV = process.env.REACT_APP_API_URL_DEV;
const SIT = process.env.REACT_APP_API_URL_SIT;
const PROD = process.env.REACT_APP_API_URL_PROD;
const url = process.env.REACT_APP_URI; //process.env.REACT_APP_REDIRECT_URI;
//without token
export const DEVURL = `${DEV}/artworkagility/integrate`;
// export const DEVURL = `https://awflowdev.pg.com/artworkagility/integrate`;
// export const SITURL = `https://awflowdsit.pg.com/artworkagility/integrate`;
//with token
// export const DEVURL = `${url}/optaplanner/awm`;
export const SITURL = `${SIT}/prweb/api/ArtworkAgilityFile`;
export const PRODURL = `${PROD}/prweb/api/ArtworkAgilityFile`;
export const AZUREURL = `${DEV}/artworkagility/azurefile`;
