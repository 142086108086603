import React, { useEffect, useState } from 'react';
import { Tag } from 'primereact/tag';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import FileUploadManager from '../../common/FileUploadManager/FileUploadManager';

const ApproveDesignIntentContent = ({
  designIntent,
  approve,
  setformattedValue,
  setFileName,
  setAzureFile,
  item,
  version,
  setVersion,
  setFullName,
  setEmail,
  date,
  subFolder,
  setPageEdited,
  pageEdited,
  setFormObjects,
  setFileUploaded,
  isTaskComplete = false
}) => {
  const [totalSize, setTotalSize] = useState(0);

  let di_name = '';
  if (!approve) {
    di_name =
      version !== 'V0' && designIntent[0]?.FileMetaDataList[0]?.Timestamp !== ''
        ? `${item?.Task_Name}_${version}_${date}`
        : `${item?.Task_Name}`;
  }

  const User = useSelector((state) => state.UserReducer);
  const userInformation = User.userInformation;
  const [checkReadWriteAccess, setCheckReadWriteAccess] = useState(false);

  useEffect(() => {
    if (User && item) {
      if (userInformation?.userid === item.Assignee && !isTaskComplete) {
        setCheckReadWriteAccess(true);
      } else {
        setCheckReadWriteAccess(false);
      }
    }
  }, [User, item, isTaskComplete]);

  const onFileUpload = (e, fileDetails) => {
    setAzureFile(e?.files?.[0]);
    let _totalSize = totalSize;
    let files = e.files;
    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
    setVersion(fileDetails.Version);
    setFullName(fileDetails.Full_Name);
    setEmail(fileDetails.Email)
    setformattedValue(e?.files?.[0]?.size);
    setFileName(e?.files?.[0]?.name);
    if(setFileUploaded){
      setFileUploaded(true);
    }
  };

  const onFileClear = () => {

    setAzureFile("");
    setTotalSize(0);
    setVersion(0);
    setFullName("");
    setEmail("")
    setformattedValue("");
    setFileName("");
  };

  const DesignHeader = (di_name) => {
    return (
      <>
        <div
          style={{
            marginLeft: 20,
            padding: 5,
            width: '97%',
            wordWrap: 'break-word',
          }}
          className="font-color"
        >
          {di_name}
        </div>
        {approve && (
          <Tag
            style={{
              backgroundColor: 'rgb(237, 244, 250)',
              color: '#03A000',
              fontSize: 14,
              marginRight: 10,
            }}
            className="mr-2"
            icon="pi pi-check"
            severity="Approved"
            value="Approved"
          ></Tag>
        )}
      </>
    );
  };
  return (
    <div>
      <div className="design-intent-header">{DesignHeader(di_name)}</div>
      <div className={`approve-design-intent`}>
        <FileUploadManager
          taskdata={designIntent[0]?.FileMetaDataList}
          onFileUpload={onFileUpload}
          onFileClear={onFileClear}
          subFolder={subFolder}
          setPageEdited={setPageEdited}
          pageEdited={pageEdited}
          taskStatus={item.Task_Status}
          setFormObjects={setFormObjects}
          checkReadWriteAccess={checkReadWriteAccess}
        />

      </div>
    </div>
  );
};

export default ApproveDesignIntentContent;