/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
import { Button } from "react-bootstrap";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { startCase, isEmpty } from "lodash";
import LoadingOverlay from "react-loading-overlay-ts";
import CustomizeView from "../../common/CustomizeView";
import ProjectListHeader from "../MyProjects/ProjectListHeader";
import { ProjectService } from "../../../service/PegaService";
import { AcpService } from "../../../service/ACPService";
import {
  updateProjectPlanAction,
  updateProjectPlanDesignAction,
  updateProjectStateAction,
  updateReworkHistoryAction,
} from "../../../store/actions/ProjectPlanActions";
import {
  activateProjectPlan,
  saveProjectPlanAction,
  createNewAzureFolder,
} from "../../../apis/projectPlanApi";
import { saveProofscopeURL } from "../../../apis/saveProofscopeURL";
import { getProjectPlan } from "../../../apis/projectPlanApi";
import { getRestructuredData, saveData1, extractData, sortBasedOnSelectedValue } from "./util";
import { setOwnerList } from "../../../store/actions/OwnerListActions";
import ManageDependency from "../../ManageDependency";
import { getDaysLeft } from "../../../Utils/LeftDays";
import { cloneDeep } from "lodash";
import ProjectPlanList from "./ProjectPlanList";
import { changeDateFormat } from "../../../utils";
import { usePriorityNew } from "../../../Utils/GetRolePriority";

const ActionButton = React.lazy(() => import("./ActionButton"));

const ProjectPlan = () => {
  const toast = useRef(null);
  const isAccessEmpty = true;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const locationPath1 = location?.pathname;
  const url = locationPath1?.split("/");
  let { ProjectID } = useParams();
  const { projectPlan, projectPlanDesign, mode } = useSelector((state) => state.ProjectPlanReducer);
  const currentUrl = location.pathname;
  const { myProject, allProjects } = useSelector((state) => state.myProject);
  const { userInformation } = useSelector((state) => state.UserReducer);

  let columnNames =
    ProjectService.getProjectPlanAllColumnNames()?.map((item) => {
      if (item.field_Name === "Project_TaskID") {
        return "Task ID"; // Display "Task ID" for Project_TaskID
      } else if (item.field_Name === "Project_PredecessorID") {
        return "Predecessor"; // Display "Dependency" for Project_PredecessorID
      } else {
        return item.field_Name; // Use column_Name or field_Name for other columns
      }
    }) || [];

  const [isSearch, isSearchSet] = useState(false);
  const [isColWidthSet, setColWidth] = useState(null);
  const [toggleButtons, setToggleButtons] = useState("Tabular");
  const [updatedProjectPlanDesignData, setUpdatedProjectPlanDesignData] = useState([]);
  const [projectPlanDesignData, setProjectPlanDesignData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [activeFlag, setActiveFlag] = useState(true);
  const [activeSave, setActiveSave] = useState(true);
  const [pegadata, setPegaData] = useState(null);
  const [initProjectPlanList, setInitProjectPlanList] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [fieldUpdated, setFieldUpdated] = useState(false);
  const [frozenUpdated, setFrozenUpdated] = useState(false);
  const [selectedFields, setSelectedFields] = useState({});
  const [filters, setFilters] = useState([]);
  const [projectState, setProjectState] = useState("");
  const [updatedDataFlag, setUpdatedDataFlag] = useState(false);
  const [columnWiseSelectedFields, setColumnWiseSelectedFields] = useState([]);
  const [showManageDependency, setShowManageDependency] = useState(false);
  const [projectColumnName, setProjectColumnNames] = useState([""]);
  const [projectDataData, setProjectDataData] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [allSelectedColumns, setAllSelectedColumns] = useState([]);
  const [isFilterActivated, setIsFilterActivated] = useState(false);
  const [visible, setVisible] = useState(false);
  const [frozenCoulmns, setFrozenColumn] = useState([]);
  const [customizeViewFields, setCustomizeViewFields] = useState({});
  const [DMAssignee, setDMAssignee] = useState("")
  const [finalPermission, setFinalPermission] = useState("")
  const [isCustomizeViewActive, setCustomizeViewActive] = useState(false)
  let isUserAccess;
  const accessSecurityMatrix = usePriorityNew(url[1]);

  const localStorageAllColumns = localStorage.getItem("projectPlanAllColumnNames") || null;



  const childFunc = useRef(null);
  useEffect(() => {
    isUserAccess = DMAssignee === userInformation?.userid;
    setFinalPermission(isUserAccess ? isUserAccess : accessSecurityMatrix);
  }, [DMAssignee])

  useEffect(() => {
    if (allColumns !== localStorageAllColumns) {
      if (localStorageAllColumns) {
        setAllColumns(JSON.parse(localStorageAllColumns));
      }
    }
  }, [localStorageAllColumns]);

  const buildDefualtAllColumns = (data) =>
    data.map((ele) => {
      if (ele) {
        let width =
          ele["field_Name"] === "Task"
            ? 400
            : ele.field_Name === "State" ||
              ele.field_Name === "End_Date" ||
              ele.field_Name === "Days_Left"
              ? 100
              : ele.field_Name === "TaskDuration"
                ? 120
                : 140;
        if (ele) {
          ele["sortZtoA"] = false;
          ele["sortAtoZ"] = false;
          ele["width"] = width;
          ele["reorder"] = false;
          ele["freeze"] = false
        }
      }
      return ele;
    });

  const getAllColumnNames = () => {
    let localAllColumns = localStorage.getItem("projectPlanAllColumnNames");
    let _allColumns = JSON.parse(localAllColumns);
    if (isEmpty(_allColumns)) {
      _allColumns = ProjectService.getProjectPlanAllColumnNames();
    }
    return _allColumns;
  }


  const handleColumns = (allColumnsname) => {
    const StorageData = localStorage.getItem("customizeViewFieldsProjectPlan");
    const customizeViewFieldsStorage = StorageData && JSON.parse(StorageData);
    if (customizeViewFieldsStorage !== undefined && customizeViewFieldsStorage !== null && customizeViewFieldsStorage?.length !== 0) {
      const freezedData = customizeViewFieldsStorage?.freezedColumns?.fieldsData;
      const availableData = customizeViewFieldsStorage?.availableFields?.fieldsData;
      let selectedData = customizeViewFieldsStorage?.selectedFields?.fieldsData;
      selectedData = AcpService?.removeCommonValues(selectedData, frozenCoulmns);
      if (freezedData?.length > 0) selectedData = [...freezedData, ...selectedData];
      if (freezedData?.length > 0 || availableData?.length > 0) {
        setIsFilterActivated(true);
        setCustomizeViewActive(!isCustomizeViewActive)
      } else {
        setIsFilterActivated(false);
      }
      let tempallColumnsname = cloneDeep(allColumnsname);
      tempallColumnsname?.forEach((ele) => {
        if (freezedData?.some(str => ele.field_Name === str)) {
          return ele['freeze'] = true
        }
        else {
          return ele['freeze'] = false
        }
      })
      const alteredColumns = tempallColumnsname?.filter(column => {
        if (selectedData.some(str => column.field_Name === str)) {
          return column
        }
      })
      const sortedalteredColumns = sortBasedOnSelectedValue(alteredColumns, selectedData)
      setAllSelectedColumns(sortedalteredColumns)
      localStorage.setItem("projectPlanSelectedColumnNames", JSON.stringify(sortedalteredColumns));
     
    }
  }

  useEffect(() => {
    try {
      let _allColumns = getAllColumnNames()
      _allColumns = buildDefualtAllColumns(_allColumns);
      handleColumns(_allColumns)
      setAllColumns(_allColumns);
      localStorage.setItem("projectPlanAllColumnNames", JSON.stringify(_allColumns));
    } catch (error) {
      console.error("Parsing error: ", error);
    }
  }, []);

  const designInputData = projectPlanDesign.filter(
    (data) => data.GroupName !== "Final Art" && !data?.AWM_Task_ID?.includes("CPC_")
  );

  const hasRoleAndAssignee = (item) => {
    return item.Role && item.Role.length > 0 && item.Assignee && item.Assignee.length > 0;
  };

  const activateButton = designInputData.every(hasRoleAndAssignee);

  const firstTime = designInputData.some((item) => item.Assignee !== "" || item.Role !== "");

  const headerName = "Project Plan";

  const onSearchClick = () => {
    isSearchSet(!isSearch);
  };

  const isResetEnabled = isColWidthSet;
  useEffect(() => {
    if (!isEmpty(allColumns)) {
      const _isFilterActivated = allColumns.filter((ele) => {
        let width =
          ele.field_Name === "Task"
            ? 400
            : ele.field_Name === "State" ||
              ele.field_Name === "End_Date" ||
              ele.field_Name === "Days_Left"
              ? 100
              : ele.field_Name === "TaskDuration"
                ? 120
                : 140;
        if (
          ele.freeze === true ||
          ele.sortAtoZ === true ||
          ele.sortZtoA === true ||
          ele.width !== width ||
          ele?.reorder === true ||
          (columnWiseSelectedFields && columnWiseSelectedFields.length !== 0)
        ) {
          return ele;
        }
      });
    }
  }, [allColumns]);

  useEffect(() => {
    if (projectPlanDesign) {
      setProjectDataData(projectPlanDesign[0]);
    }
  }, [projectPlanDesign]);

  useEffect(() => {
    setUpdatedProjectPlanDesignData(projectPlanDesign);
    const StorageData = localStorage.getItem("customizeViewFieldsProjectPlan");
    const customizeViewFieldsStorage = StorageData && JSON.parse(StorageData);
    // set initial column values if its empty
    if (customizeViewFieldsStorage === null) {
      setToInitialColumns()
    }
    else {
      setCustomizeViewFields(customizeViewFieldsStorage);
    }
  }, []);

  useEffect(() => {
    if (!isAccessEmpty) {
      setActiveSave(true);
    }
  }, [isAccessEmpty]);

  useEffect(() => {
    if (projectState) {
      setProjectState(projectState);
    }
  }, [projectState]);

  useEffect(() => {
    if (updatedProjectPlanDesignData) {
      setProjectPlanDesignData(updatedProjectPlanDesignData || []);
    }
  }, [updatedProjectPlanDesignData]);

  useEffect(() => {
    if (pegadata !== null && pegadata?.length !== 0) {
      const DesignAndInputData = updatedProjectPlanDesignData?.filter(
        (data) =>
          data.GroupName !== undefined &&
          data.GroupName !== "Final Art" &&
          !data?.AWM_Task_ID?.includes("CPC_")
      );
      const hasRoleAndAssignee = (item) => {
        return item.Role && item.Role.length > 0 && item.Assignee && item.Assignee.length > 0;
      };
      const result = DesignAndInputData.every(hasRoleAndAssignee);
      setUpdatedDataFlag(result);
    }
  }, [updatedProjectPlanDesignData, updatedDataFlag]);

  useEffect(() => {
    // let allProjectData = locationPath && locationPath === "allProjects" ? allProjects : myProject;
    // let projectData =
    //   isArray(allProjectData) &&
    //   allProjectData.find((project) => project.Project_ID === ProjectID);

    // setProjectState(projectData?.Project_State !== undefined && projectData?.Project_State);

    if (projectState === "Draft" && !firstTime) {
      setActiveSave(false);
    } else if ((projectState === "Draft" && firstTime) || projectState === "Active") {
      setActiveSave(true);
    }

    projectState === "Draft" && firstTime && activateButton && setActiveFlag(false);
  }, [myProject, allProjects, projectPlan, isAccessEmpty, projectPlanDesign]);

  useEffect(() => {
    if (updatedDataFlag && projectState !== "Active") {
      setActiveFlag(false);
    } else if (
      !updatedDataFlag ||
      projectState === "Active" ||
      !isAccessEmpty ||
      projectPlan.length === 0
    ) {
      setActiveFlag(true);
    }
  }, [updatedDataFlag, projectState]);

  // useEffect(() => {
  //   modeType && getProjectPlanApi();
  // }, [modeType]);

  // useEffect(()=>{
  //   setModeType(mode);
  // }, [mode])

  useEffect(() => {
    getProjectPlanApi();
  }, [mode]);

  const getProjectPlanApi = async () => {
    setLoader(true);
    let restructuredData = [];
    let apiData = [];

    if ((mode === "edit" || mode === "design") && ProjectID) {
      const getProjectPlanData = await getProjectPlan(ProjectID);
      if (getProjectPlanData?.responseStatus === 200) {
        if (typeof getProjectPlanData === "object") {
          getProjectPlanData?.projectPlanData?.map((obj) => {
            if (obj.Task_Type === "Dependency Mapping") {
              setDMAssignee(obj.Assignee)
            }
          })
          dispatch(updateProjectStateAction(getProjectPlanData.projectState));
          dispatch(updateReworkHistoryAction(getProjectPlanData.reworkHistoryData))
          apiData = getProjectPlanData.projectPlanData;
          setProjectState(getProjectPlanData.projectState);
        } else {
          apiData = [];
        }
      } else {
        apiData = [];
      }
    } else {
      apiData = [];
    }

    apiData = apiData?.map((item) => ({
      ...item,
      Buffer: item?.Buffer?.toString(),
      Remaining_Work: item?.Remaining_Work?.toString(),
      //  Days_Left: item.State === "In-Progress" && item.TaskDuration !== "" ? item.Start_Date !== "" && getDaysLeft(item.End_Date) : item.TaskDuration !== "" ? "N/A" : ""
      Days_Left: item.State === "In-Progress" ? getDaysLeft(item?.Start_Date, item?.TaskDuration) : "N/A",
      Start_Date: changeDateFormat(item.Start_Date),
      End_Date: changeDateFormat(item.End_Date),
      Help_Needed: item.GroupName === "Final Art" ? "N/A" : item.Help_Needed === "" ? "No" : item.Help_Needed
    }));

    const updatedApiData = apiData?.map((item) => {
      const formattedItem = { ...item };
      if (item.RoleOptions && item.RoleOptions.length === 1) {
        // If RoleOptions has only one option, set the "Role" field to its name
        formattedItem.Role = item.RoleOptions[0].Name;
      }
      // Check if OwnerOptionsNew has exactly one item
      if (
        item.RoleOptions &&
        item.RoleOptions.length === 1 &&
        item.RoleOptions[0].OwnerOptionsNew &&
        item.RoleOptions[0].OwnerOptionsNew.length === 1
      ) {
        formattedItem.Owner = item.RoleOptions[0].OwnerOptionsNew[0].Name;
      }
      return formattedItem;
    });
    apiData && dispatch(updateProjectPlanDesignAction(apiData));
    restructuredData = updatedApiData?.length > 0 ? getRestructuredData(updatedApiData) : [];
    dispatch(updateProjectPlanAction(restructuredData));


    // create owner list based on Role
    const uniqueNamesSet = new Set();
    updatedApiData?.map((e) => {
      if (e.RoleOptions !== undefined) {
        e.RoleOptions.forEach((subarray) => {
          if (subarray.Name) {
            const uniqueKey = `${subarray.Name.trim()}_${JSON.stringify(subarray.OwnerOptionsNew)}`;
            uniqueNamesSet.add(uniqueKey);
          }
        });
      }
    });

    const ownerList = Array.from(uniqueNamesSet.values()).map((uniqueKey) => {
      const [Name, arrayProperty] = uniqueKey.split("_");
      try {
        var OwnerOptionsNew = JSON.parse(arrayProperty);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
      return { Name, OwnerOptionsNew };
    });
    if (ownerList?.length) {
      dispatch(setOwnerList(ownerList));
    }
    setLoader(false);
  };

  const onSave = async () => {
    setLoader(true);
    let updatedData = [];
    const updatedSaveData = saveData1(
      updatedData,
      false,
      projectPlanDesignData,
      projectPlanDesign,
      projectState,
      firstTime
    );

    if (updatedSaveData.length !== 0) {
      const formData = {
        ArtworkAgilityProjects: updatedSaveData,
      };
      //Making the Save proofsocpe API and clearing the URL values in case assignee changes
      try {
        const tasksToProcess = updatedSaveData?.filter(
          (task) => task.AWM_Task_ID.startsWith("ARDT") || task.AWM_Task_ID.startsWith("ACIC")
        );
        if (tasksToProcess && tasksToProcess.length > 0) {
          for (const task of tasksToProcess) {
            const { AWM_Task_ID, Assignee } = task;
            // Find the corresponding task in projectPlanDesign based on AWM_Task_ID
            const correspondingTask = projectPlanDesign?.find(
              (designTask) => designTask.AWM_Task_ID === AWM_Task_ID
            );

            // Check if corresponding task is found
            if (correspondingTask) {
              // Fetch Assignee value from projectPlanDesign
              const projectPlanDesignAssignee = correspondingTask?.Assignee;
              if (correspondingTask?.State === "In-Progress") {
                if (projectPlanDesignAssignee !== task.Assignee) {
                  // Check if Assignee is different from the Assignee in projectPlanDesign
                  const requestData = {
                    AWMProjectID: ProjectID,
                    AWMTaskID: AWM_Task_ID,
                    PFFileURL: "",
                    ViewPFFileURL: "",
                    ApprovalID: "",
                    Assignee: Assignee,

                    // Use Assignee from projectPlanDesign
                  };

                  saveProofscopeURL(requestData)
                    .then(() => {
                      projectState !== "Draft" &&
                        dispatch(updateProjectPlanDesignAction(updatedProjectPlanDesignData));
                      saveProjectPlanAction(formData, ProjectID);
                      // await dispatch(getMyProject(userInformation));
                      getProjectPlanApi();
                      setActiveSave(true);
                      setActiveFlag(true);
                    })
                    .catch((error) => {
                      console.error("Error calling API for task:", AWM_Task_ID, error);
                    });
                }
                if (tasksToProcess?.TaskDuration !== correspondingTask?.TaskDuration) {
                  projectState !== "Draft" &&
                    dispatch(updateProjectPlanDesignAction(updatedProjectPlanDesignData));
                  saveProjectPlanAction(formData, ProjectID);
                  getProjectPlanApi();
                  setActiveSave(true);
                  setActiveFlag(true);
                }
              } else {
                projectState !== "Draft" &&
                  dispatch(updateProjectPlanDesignAction(updatedProjectPlanDesignData));
                await saveProjectPlanAction(formData, ProjectID);
                // await dispatch(getMyProject(userInformation));
                getProjectPlanApi();
                setActiveSave(true);
                setActiveFlag(true);
              }
            }
          }
        } else {
          projectState !== "Draft" &&
            dispatch(updateProjectPlanDesignAction(updatedProjectPlanDesignData));
          await saveProjectPlanAction(formData, ProjectID);
          // await dispatch(getMyProject(userInformation));
          getProjectPlanApi();
          setActiveSave(true);
          setActiveFlag(true);
        }
      } catch (error) {
        console.error("Error processing formData:", error);
      }
    }
    // else {
    //   setLoader(false);
    // }
  };

  const activate = async () => {
    setLoader(true);
    setActiveFlag(true);
    let updatedData = [];
    const updatedSaveData = saveData1(
      updatedData,
      true,
      projectPlanDesignData,
      projectPlanDesign,
      projectState
    );

    if (updatedSaveData.length !== 0) {
      const formData = {
        ArtworkAgilityProjects: updatedSaveData,
      };
      await activateProjectPlan(formData, ProjectID);
      await createNewAzureFolder(projectDataData?.Project_Name, projectDataData?.BU);
      // await dispatch(getMyProject(userInformation));
      getProjectPlanApi();
      await toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Project activated successfully!",
        life: 5000,
      });
      // setLoader(false);
      setActiveSave(true);
      setActiveFlag(true);
    }
  };

  const handleExapandCollapseAllButton = () => {
    if (expandedKeys.length === 0) {
      handleExpandAll();
    } else {
      setExpandedKeys([]);
    }
  };

  const handleExpandAll = () => {
    const allKeys = {};
    const expandChildren = (nodes) => {
      nodes.forEach((node) => {
        allKeys[node.key] = true;
        if (node.children && node.children.length > 0) {
          expandChildren(node.children);
        }
      });
    };
    pegadata?.forEach((item) => expandChildren([item]));
    setExpandedKeys(allKeys);
    // setLoader(false);
  };

  useEffect(() => {
    //expand all by default
    handleExpandAll();
  }, [pegadata]);

  const handleExapandCollapseAllFilter = () => {
    const allChildKeys = {};
    const expandChildren = (nodes) => {
      nodes.forEach((node, index) => {
        // allChildKeys.push(String(index));
        allChildKeys[node.key] = true;
        if (node?.children !== 0) {
          expandChildren(node.children);
        }
      });
    };

    expandChildren(pegadata);
    setExpandedKeys(allChildKeys);
  };

  const clearColumnWiseFilter = () => {
    setSelectedFields([]);
    setFilters([]);
    setPegaData(initProjectPlanList);
  };

  const onClickClearFilter = () => {
    const _allColumns = buildDefualtAllColumns(allColumns);
    localStorage.setItem("projectPlanAllColumnNames", JSON.stringify(_allColumns));
    setFieldUpdated(!fieldUpdated);
    setFrozenUpdated(!frozenUpdated);
    clearColumnWiseFilter();
    setVisible(false);
    setToInitialColumns()
    setAllSelectedColumns(allColumns)
    setIsFilterActivated(false)
    localStorage.setItem("projectPlanSelectedColumnNames", null);
    setCustomizeViewActive(false)
  };
  /*code for customize feild*/
  const formCustomizeObject = (availableFields, freezedColumns, selectedFeilds) => {
    const customizeViewFieldsUpdated = {
      availableFields: {
        id: "availableFields",
        title: "Available Fields",
        fieldsData: availableFields,
      },
      freezedColumns: {
        id: "freezedColumns",
        title: "freezed Columns",
        fieldsData: freezedColumns,
      },
      selectedFields: {
        id: "selectedFields",
        title: "selected Fields",
        fieldsData: selectedFeilds,
      },
    };

    return customizeViewFieldsUpdated
  }


  const setToInitialColumns = () => {
    let selectedFeilds = getAllColumnNames()
    selectedFeilds = selectedFeilds.map((ele) => {
      return ele.field_Name
    })
    const customizeViewFieldsUpdated = formCustomizeObject([], [], selectedFeilds)
    setCustomizeViewFields(customizeViewFieldsUpdated);
    localStorage.setItem("customizeViewFieldsProjectPlan", JSON.stringify(customizeViewFieldsUpdated));
  }

  const handleCustomizedView = (data) => {
    handleColumns(allColumns)
    if (data !== undefined && data !== null && data?.length !== 0) {
      const parsedData = data?.selectedFields?.fieldsData?.length
        ? data?.selectedFields?.fieldsData
        : data && JSON.parse(data);
      const freezedData = parsedData?.freezedColumns?.fieldsData;
      const availableData = parsedData?.availableColumns?.fieldsData;
      let selectedData = parsedData?.selectedFields?.fieldsData;
      localStorage.setItem("customizeViewFieldsProjectPlan", JSON.stringify(parsedData));
      selectedData = AcpService?.removeCommonValues(selectedData, frozenCoulmns);
      if (freezedData?.length > 0 || availableData?.length > 0) {
        setIsFilterActivated(true);
      } else {
        setIsFilterActivated(false);
      }


      if (freezedData?.length > 0)
        selectedData = [...freezedData, ...selectedData];
      setCustomizeViewFields(parsedData);
      setFrozenColumn([...freezedData]);
    }
  };
  const breadcrumb = (
    <div>
      <nav className="p-breadcrumb p-component ProjectPlanBreadCrum" aria-label="Breadcrumb">
        <ul>
          <li className="">
            <NavLink to={`/${url[1]}`} className="p-menuitem-link">
              <span className="p-menuitem-text">{startCase(url[1])}</span>
            </NavLink>
          </li>
          <li className="p-breadcrumb-chevron pi pi-chevron-right piChevronRightMargin"></li>
          <li className="">
            <a href="#" className="p-menuitem-link">
              <span className="p-menuitem-text">{headerName}</span>
            </a>
          </li>
          <li>
            {mode !== "create" && !loader && projectDataData?.Project_Name && (
              <div className="project-name">{!loader && projectDataData?.Project_Name}</div>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );

  const flatPegadata = pegadata?.flatMap((obj) => extractData(obj));

  const getUpdatedAllColumnFormat = (column) => {
    const finalData = column.map((el) => {
      return {
        width: el.width,
        Sequence: "",
        freeze: el.freeze,
        Field_Name: el.field_Name,
        reorder: false,
        Column_Name: el.field_Name,
        sortAtoZ: false,
        sortZtoA: false,
        Attribute_Type: "Free Text",
      };
    });
    return finalData;
  };
  let filtered;

  if (filters.length > 0) {
    filtered = filters?.flatMap((obj) => extractData(obj));
    // .map((item) => ({
    //   ...item,
    //   Start_Date: !isString(item?.End_Date) ? formatDate(item?.Start_Date) : item?.Start_Date,
    //   Owner: item?.Assignee,
    //   End_Date: !isString(item?.End_Date) ? formatDate(item?.End_Date) : item?.End_Date
    // }));
  }

  return (
    <div className="projectSetupParent project-plan-wrapper newdiv">
      <Toast ref={toast} />
      <div className="breadCrumbParent">
        <div className="row">
          <div className="col">{breadcrumb}</div>
          <div className="col" style={{ display: "flex", gap:"6px" }}>
            <CustomizeView
              availableFields={[]}
              showTaskDialog={visible}
              headerName={"Project Plan"}
              onClose={() => setVisible(false)}
              customizeViewFields={customizeViewFields}
              setCustomizeViewFields={handleCustomizedView}
              resetToPgDefault={() => onClickClearFilter()}
              allColumns={getUpdatedAllColumnFormat(allColumns)}
            />
            {projectDataData !== undefined && (
              <ProjectListHeader
                header=""
                clearFilter={onClickClearFilter}
                setVisible={() => { }}
                saveSettings={() => { }}
                clearFilters={onClickClearFilter}
                onSearchClick={onSearchClick}
                isFilterEnabled={filters?.length > 0 || isFilterActivated}
                isResetEnabled={isResetEnabled}
                allData={flatPegadata}
                headers={columnNames}
                filterFLag={false}
                CustomizeViewFlag={true}
                ResetToDefaultFlag={true}
                projectPlanView={true}
                handleExapandCollapseAllButton={handleExapandCollapseAllButton}
                expandedKeys={expandedKeys}
                filename="Project_Plan_Records"
                selected={filtered}
              />
            )}
            <Button onClick={() => navigate(`${currentUrl}/rework`)} disabled={projectState === 'Complete'}> Rework</Button>
            <Button
              variant="secondary"
              className="button-layout"
              onClick={() => setVisible(true)}
            >
              Customize View
            </Button>
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              <div className="col projectPlanButtons">
                <label
                  className={` btn border border-secondary ${toggleButtons === "GanttChart" ? "ganttChartTabular active" : ""
                    }`}
                  onClick={() => setToggleButtons("GanttChart")}
                >
                  Gantt Chart
                </label>
                <label
                  className={` btn border border-secondary ${toggleButtons === "Tabular" ? "ganttChartTabular active" : ""
                    }`}
                  onClick={() => setToggleButtons("Tabular")}
                >
                  Tabular
                </label>
              </div>
            </div>
            {/* <Button
              className="button-layout manage-dependency-btn"
              variant="primary"
              onClick={() => setShowManageDependency(true)}
            >
              Dependency
            </Button> */}
            <Suspense fallback={<div></div>}>
              <ActionButton
                showManageDependency={showManageDependency}
                setShowManageDependency={setShowManageDependency}
                getProjectPlanApi={getProjectPlanApi}
                clearFilters={onClickClearFilter}
                disabled={projectState === 'Complete'}
              />
            </Suspense>
          </div>
        </div>
      </div>
      <ManageDependency
        showManageDependency={showManageDependency}
        setShowManageDependency={setShowManageDependency}
        ProjectID={ProjectID}
        getApiParentPage={getProjectPlanApi}
        Assignee={DMAssignee}
        finalPermission={finalPermission}
      />

      {toggleButtons === "Tabular" && (
        <>
          {/* <div className="action-buttons AccodianBtn">
            <></>
          </div>
          <ProjectPlanCompo
            isSearch={isSearch}
            setColWidth={setColWidth}
            childFunc={childFunc}
            view={toggleButtons}
            updatedProjectPlanDesignData={updatedProjectPlanDesignData}
            setUpdatedProjectPlanDesignData={setUpdatedProjectPlanDesignData}
            activeSave={activeSave}
            setActiveFlag={setActiveFlag}
            setActiveSave={setActiveSave}
            getProjectPlanApi={getProjectPlanApi}
            loader={loader}
            pegadata={pegadata}
            setPegaData={setPegaData}
            activeFlag={activeFlag}
            isAccessEmpty={isAccessEmpty}
            expandedKeys={expandedKeys}
            setExpandedKeys={setExpandedKeys}
            fieldUpdated={fieldUpdated}
            setFieldUpdated={setFieldUpdated}
            clearColumnWiseFilter={clearColumnWiseFilter}
            filters={filters}
            setFilters={setFilters}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            frozenUpdated={frozenUpdated}
            setFrozenUpdated={setFrozenUpdated}
            handleExapandCollapseAllFilter={handleExapandCollapseAllFilter}
            firstTime={updatedDataFlag}
            columnWiseSelectedFields={columnWiseSelectedFields}
            setColumnWiseSelectedFields={setColumnWiseSelectedFields}
            projectColumnName={projectColumnName}
            setProjectColumnNames={setProjectColumnNames}
            projectDataData={projectDataData}
            setInitProjectPlanList={setInitProjectPlanList}
            onSetAllColumns={setAllColumns}
            allColumns={allColumns}
          /> */}

          <LoadingOverlay className="h-100" active={loader} spinner text="">
            <ProjectPlanList
              isSearch={isSearch}
              setColWidth={setColWidth}
              childFunc={childFunc}
              view={toggleButtons}
              updatedProjectPlanDesignData={updatedProjectPlanDesignData}
              setUpdatedProjectPlanDesignData={setUpdatedProjectPlanDesignData}
              activeSave={activeSave}
              setActiveFlag={setActiveFlag}
              setActiveSave={setActiveSave}
              getProjectPlanApi={getProjectPlanApi}
              loader={loader}
              pegadata={pegadata}
              setPegaData={setPegaData}
              activeFlag={activeFlag}
              isAccessEmpty={isAccessEmpty}
              expandedKeys={expandedKeys}
              setExpandedKeys={setExpandedKeys}
              fieldUpdated={fieldUpdated}
              setFieldUpdated={setFieldUpdated}
              clearColumnWiseFilter={clearColumnWiseFilter}
              filters={filters}
              setFilters={setFilters}
              selectedFields={selectedFields}
              setSelectedFields={setSelectedFields}
              frozenUpdated={frozenUpdated}
              setFrozenUpdated={setFrozenUpdated}
              handleExapandCollapseAllFilter={handleExapandCollapseAllFilter}
              firstTime={updatedDataFlag}
              columnWiseSelectedFields={columnWiseSelectedFields}
              setColumnWiseSelectedFields={setColumnWiseSelectedFields}
              projectColumnName={projectColumnName}
              setProjectColumnNames={setProjectColumnNames}
              projectDataData={projectDataData}
              setInitProjectPlanList={setInitProjectPlanList}
              onSetAllColumns={setAllSelectedColumns}
              allColumns={allSelectedColumns}
              projectState={projectState}
              isCustomizeViewActive={isCustomizeViewActive}
            />
          </LoadingOverlay>

          <div className="form-buttons" style={{ background: "#FAFAFA" }}>
            <Button
              className={!isAccessEmpty ? "btn btn-disabled" : "button-layout"}
              variant="secondary"
              onClick={() => navigate("/myProjects")}
              disabled={!isAccessEmpty}
            >
              Cancel
            </Button>

            <Button
              className={activeSave ? "btn btn-disabled" : "button-layout"}
              variant="secondary"
              onClick={onSave}
              disabled={activeSave || projectState === 'Complete'}
            >
              Save
            </Button>

            <Button
              className="button-layout"
              variant="primary"
              onClick={activate}
              disabled={loader || (pegadata && Object.keys(pegadata).length === 0) || activeFlag || projectState === 'Complete'}
            >
              Activate
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
export default ProjectPlan;