const TaskName = [
  {
    task: "Define Regional Design Template",
    transform: "Define RDT"
  },
  {
    task: "Define Design Intent",
    transform: "Define DI"
  },
  {
    task: "Artwork Scope Alignment",
    transform: "AW Scope Alignment"
  },
  {
    task: "Define Ink Qualification",
    transform: "Define Ink Qual"
  },
  {
    task: "Confirm Color Development Approved",
    transform: "Confirm CD"
  },
  {
    task: "Dispatch FA to Printer",
    transform: "Disp FA to Print"
  },
  {
    task: /^(.*)_Dispatch FA to Printer$/,
    transform: (value) => value.replace(/^(.*)_Dispatch FA to Printer$/, "$1_Disp FA to Print")
  },
  
  {
    task: /^(.*)_Trigger POA Shell Creation$/,
    transform: (value) => value.replace(/^(.*)_Trigger POA Shell Creation$/, "$1_Trigger POA")
  },
  {
    task: /^(\d+\.\)\s*Dispatch FA to Printer)$/,
    transform: (value) => value.replace(/^(\d+\.\)\s*Dispatch FA to Printer)$/, "Disp FA to Print")
  },
  {
    task: value => value.includes("Dispatch FA to Printer"),
    transform: (value) => value.replace("Dispatch FA to Printer", "Disp FA to Print")
  },
  {
    task: "Confirm Preliminary Print Feasibility Assessment",
    transform: "Prelim Print Feas"
  },
  {
    task: "Upload Approved Design Intent",
    transform: "Upload Approved DI"
  },
  {
    task: "Upload Regional Design Template",
    transform: "Upload RDT"
  },
  {
    task: "Approve Regional Design Template",
    transform: "Approve RDT"
  },
  {
    task: "Confirm Ink Qualification",
    transform: "Confirm Ink Qual"
  },
  {
    task: "Upload Briefing documents",
    transform: "Upload Brief Docs"
  },
   
  {
    task: "Request CIC Matrixes",
    transform: "Req CIC Matrixes"
  },
  {
    task: "Confirm Print Trial done",
    transform: "Confirm PT"
  },
  {
    task: "Define Color Dev./Print Trial",
    transform: "Define CD/PT"
  }
  
];

 export default TaskName;