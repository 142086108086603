import React, { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import plusCollapseImg from "../../assets/images/plusCollapse.svg";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import DsbpCommonPopup from "./DsbpCommonPopup";
import { addEllipsis } from "../../utils";
import { NumberConversion } from "./utils";
import { usePriorityNew } from "../../Utils/GetRolePriority";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../common/NavigationPrompt";

const SelectDsbpId = ({
  accessType = "Write",
  dropdownlist,
  addDSBPIntoProject,
  totalNoOfDsbpId,
  totalNoOfPMP,
  totalNoOfPOA,
  totalNoOfAddedProject,
  totalNoOfPMPLocked,
  listOfInitiativeId,
  mappedPOAS,
  mappedPOARequestedIds,
  isProjectComplete = false,
  updateDropdownList,
  projectData,
  tableLoader,
  pageEdited,
  setPageEdited,
  initiativeIdsWithPoaCreated,
  onHideReset,
}) => {
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectDialog, setSelectDialog] = useState(false);
  const [selectedDsbpData, setSelectedDsbpData] = useState({});
  const [operation, setOperation] = useState({});
  const { userInformation } = useSelector((state) => state.UserReducer);
  const { projectState } = useSelector((state) => state.ProjectPlanReducer);
  const [pendingNavigation, setPendingNavigation] = useState({});
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  // const accessSecurityMatrix = projectData?.Assignee === userInformation?.userid//usePriorityNew(currentUrl[1]);
  const accessSecurityMatrix = accessType === "Write" ? true : false;

  useEffect(() => {
    setSelectedCities(listOfInitiativeId);
  }, [listOfInitiativeId]);

  let selectedInitiativeName =
    selectedDsbpData?.InitiativeID +
    "_" +
    selectedDsbpData?.InitiativeName +
    "_" +
    selectedDsbpData?.IL +
    "_" +
    selectedDsbpData?.Scope;

  const cityOptionTemplate = (option) => {
    let initiativeName =
      option.InitiativeID + "_" + option.InitiativeName + "_" + option.IL + "_" + option.Scope;
    let deleteIconDisabled = initiativeIdsWithPoaCreated.indexOf(option.InitiativeID) !== -1;
    return (
      <div className="city-option" style={{ opacity: option.sequence === 3 ? 0.4 : 1 }}>
        <div
          className={option.sequence === 1 ? "city-name dropdown-name-color" : "city-name"}
          onClick={(e) => e.stopPropagation()}
        >
          {addEllipsis(initiativeName, 90)}
        </div>
        <div>
          <img
            src={plusCollapseImg}
            className={`add-new-design-intent-icon ${
              ((selectedCities &&
                selectedCities.length &&
                selectedCities.includes(option.InitiativeID)) ||
                !accessSecurityMatrix ||
                isProjectComplete) &&
              "disable-icons"
            }`}
            onClick={(e) => {
              if (option.sequence !== 3) {
                onChangeSelectConfirm(option, "add");
              }
            }}
            alt=""
            style={{ height: 12 }}
          />
        </div>
        <div>
          <img
            src={deleteIcon}
            onClick={(e) => {
              e.stopPropagation();
              if (option.sequence !== 3) {
                onChangeSelectConfirm(option, "delete");
              }
            }}
            alt="filter logo"
            className={`header-icons ${
              ((selectedCities && !selectedCities.includes(option.InitiativeID)) ||
                !selectedCities ||
                !accessSecurityMatrix ||
                isProjectComplete ||
                deleteIconDisabled) &&
              "disable-icons"
            }`}
            style={{ height: 12 }}
          />
        </div>
      </div>
    );
  };

  const { markDirty, markClean } = useUnsavedChangesWarning();

  // this block will make sure if page is not altered then
  // else blcok will work as earlier without any warning
  // but if page is altered then it will show warning dialog
  // and it will save pending operation details in setPendingNavigation({option,operation})
  const onChangeSelectConfirm = (option, operation) => {
    if (pageEdited) {
      setShowWarningDialog(true);
      setPendingNavigation({ option, operation });
    } else {
      onChangeSelect(option, operation);
    }
  };

  // if a user select yes on waring that means user agreed to loose the edited data
  // so once he confirms confirmNavigation  will be called, this function will make sure
  // to call the required function with pending variable details onChangeSelect(option, operation);
  const confirmNavigation = () => {
    const { option, operation } = pendingNavigation;
    onChangeSelect(option, operation);
    setShowWarningDialog(false);
    setPendingNavigation({});
    markClean();
  };

  // to hide dialog
  const cancelNavigation = () => {
    setShowWarningDialog(false);
  };

  const onChangeSelect = (option, operation) => {
    if (operation === "delete" && mappedPOAS.includes(option.InitiativeID)) {
      setOperation("poaCreated");
    } else if (operation === "delete" && mappedPOARequestedIds.includes(option.InitiativeID)) {
      setOperation("poaRequested");
    }
    //code has been kept commented purposefully for future use.
    // else if (operation === "delete" && cicMappedIds?.includes(option.InitiativeID)) {
    //   setOperation("cicMapped");
    // }
    else {
      setOperation(operation);
    }
    setSelectDialog(true);
    setSelectedDsbpData(option);
  };
  const handleOptionSelection = (option, operation) => {
    markClean();
    setPageEdited(false);
    setPendingNavigation({});
    setShowWarningDialog(false);
    // on submit making clean everything, all warnings
    const updatedSelectedCities = [...selectedCities];
    const index = updatedSelectedCities.indexOf(option.InitiativeID);
    if (index > -1) {
      updatedSelectedCities.splice(index, 1); // Deselect the option
    } else {
      updatedSelectedCities.push(option.InitiativeID); // Select the option
    }
    setSelectedCities(updatedSelectedCities); // Update selectedCities state
    updateDropdownList(updatedSelectedCities);
    addDSBPIntoProject(option.InitiativeID, operation);
    setSelectDialog(false);
  };

  const onHide = (value, operation) => {
    setSelectDialog(value);
    if (pageEdited && onHideReset && operation) {
      onHideReset();
    }
  };

  let title;

  switch (operation) {
    case "delete":
      title = "Are you sure you want to disconnect this DSBP ID ?";
      break;
    case "add":
      title = "Do you want to select this DSBP ID ?";
      break;
    case "poaCreated":
      title = "This DSBP Initiative can’t be disconnected as POA is already created";
      break;
    case "poaRequested":
      title = "This DSBP Initiative can’t be disconnected as POA creation in process in RTA.";
      break;
    case "cicMapped":
      title =
        "Please review the CIC mapping and remove the association of the PMP with CIC / CIC Matrix before you can remove the Initiative from project.";
      break;
    default:
      title = "Unknown operation";
      break;
  }

  return (
    <div className="margin-left">
      {/* to show warning in same page */}
      <UnsavedChangesDialog
        visible={showWarningDialog}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
      />
      {tableLoader ? (
        "Please wait! DSBP ID's Loading..."
      ) : (
        <>
          <div>Select DSBP ID</div>
          <div className="actions dsbp-select p-0">
            <MultiSelect
              value={selectedCities}
              // onChange={(e) => multiSelectOnChange(e)}
              options={dropdownlist}
              optionLabel={(option) =>
                `${option.InitiativeID}_${option.InitiativeName}_${option.IL}_${option.Scope}`
              }
              optionValue="InitiativeID" // Set optionValue to "name" to remove checkboxes
              placeholder="Select"
              filter
              // valueTemplate={selectedCityTemplate}
              itemTemplate={cityOptionTemplate}
              maxSelectedLabels={3}
              panelClassName="dsbp-multiselect-dropdown"
              style={{ maxWidth: 370, width: "300%" }}
              disabled={projectState === "Draft" || !accessSecurityMatrix}
            />

            <div className="action-buttons margin-right">
              <div>
                DSBP ID's :{" "}
                <span style={{ color: "#003DA5", fontWeight: 600 }}>
                  {NumberConversion(totalNoOfDsbpId)}{" "}
                </span>
              </div>
              <div>
                PMP's Locked :{" "}
                <span style={{ color: "#003DA5", fontWeight: 600 }}>
                  {" "}
                  {NumberConversion(totalNoOfPMPLocked)}{" "}
                </span>{" "}
              </div>
              <div>
                {" "}
                Added to Project :
                <span style={{ color: "#003DA5", fontWeight: 600 }}>
                  {" "}
                  {NumberConversion(totalNoOfAddedProject)}{" "}
                </span>{" "}
              </div>
              <div>
                {" "}
                Total PMP's:
                <span style={{ color: "#003DA5", fontWeight: 600 }}>
                  {" "}
                  {NumberConversion(totalNoOfPMP)}{" "}
                </span>{" "}
              </div>
              <div>
                {" "}
                POA Created :
                <span style={{ color: "#003DA5", fontWeight: 600 }}>
                  {" "}
                  {NumberConversion(totalNoOfPOA)}{" "}
                </span>{" "}
              </div>
            </div>
          </div>
        </>
      )}
      {selectDialog && (
        <DsbpCommonPopup
          actionHeader={title}
          dasbpDialog={selectDialog}
          setDasbpDialog={(value) => onHide(value, operation)}
          onSubmit={() => handleOptionSelection(selectedDsbpData, operation)}
          okButtonShow={
            operation === "poaCreated" || operation === "poaRequested" || operation === "cicMapped"
              ? true
              : false
          }
          poaCreated={
            operation === "poaCreated" || operation === "poaRequested" || operation === "cicMapped"
          }
          deleteButtonShow={false}
          submitButtonShow={true}
          // yesButtonShow={false}
          yesButtonShow={operation === "add" ? false : true}
          disconnectButtonShow={operation === "delete" ? false : true}
          cancelButtonShow={operation === "delete" ? false : true}
          widthSize="602px"
        >
          <>
            {selectedInitiativeName}
            {operation === "delete" && (
              <div style={{ color: "red", fontSize: "10px", paddingTop: "20px" }}>
                *Any changes you made will be lost
              </div>
            )}
          </>
        </DsbpCommonPopup>
      )}
    </div>
  );
};

export default SelectDsbpId;
