import React from "react";
import download from "../../../assets/images/downloadDash.svg";
import XLSX from 'xlsx';

function ExportToExcel({allData,filename}) {
  
  const onExport=()=>{
    // export json to Worksheet of Excel
      // only array possible
      var excelData = XLSX.utils.json_to_sheet(allData) 

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, excelData, filename) // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, `${filename}.xlsx`) 
  }

  return (
      <>
      <span className="excel-dw">
      <img  src={download} onClick={onExport} alt="Export to csv"/>
      </span>
      </>
  );
}

export default ExportToExcel;
