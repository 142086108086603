import React from 'react';
import FileViewer from 'react-file-viewer';
import ErrorPage from '../../../errors/ErrorPage';
import { Dialog } from 'primereact/dialog';
import './index.scss';
import { isEmpty } from 'lodash';

export default function CustomFileViewer({ fileViewerData, isFileViewerOpen, onFileViewerHide }) {
  const { fileName, filePath, fileType } = fileViewerData

  const onError = (e) => {
    console.error(e, 'error in file-viewer');
  }

  return (
    <Dialog
      visible={isFileViewerOpen}
      className={`cfv-dialog`}
      onHide={onFileViewerHide}
      header={<div className="p-dialog-ubd">File Viewer: {fileName}</div>}
    >
      {!isEmpty(filePath) ?
        <FileViewer
          fileType={fileType}
          filePath={filePath}
          errorComponent={<ErrorPage code={500} />}
          onError={onError}
        /> :
        <h4>File not found!</h4>
      }
    </Dialog>
  );
}