import "./index.scss";
import {
  autoVal,
  expandAll,
  filterData,
  getDDoptions,
  onSelectColumn,
  durationEnabled,
  getSelectedValues,
  addDisableByState,
  getdeSelectedValues,
  clearAllFilterValues,
  getformattedResponse,
  filterDataForSelection,
  getDropdownChangedValues,
  getUpdatedAllColumnFormat,
  removeUnderscore,
  sortTasksByCustomOrder
} from "../../../Utils/GridService";
import { useParams } from "react-router-dom";
import _, { cloneDeep, isEmpty } from "lodash";
import { Button } from "react-bootstrap";
import PageLayout from "../../PageLayout";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import ACPFilter from "../../ACP/common/ACPFilter";
import { multiFilterChange } from "../../../utils";
import { useSelector, useDispatch } from "react-redux";
import filter from "../../../assets/images/filter.svg";
import CustomizeView from "../../common/CustomizeView";
import { AcpService } from "../../../service/ACPService";
import ReworkHeader from "../../ReworkPage/ReworkHeader";
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { extractData } from "../../Projects/ProjectPlan/util";
import { ProjectService } from "../../../service/PegaService";
import RejectReason from "../../RejectionDialog/RejectReason";
import CommonTreeTable from "../../common/TreeTable/TreeTable";
import { usePriorityNew } from "../../../Utils/GetRolePriority";
import { popUpConst } from "../../../constants/reworkPageTasks";
import ReasonForRework from "../../ReasonForRework/ReasonForRework";
import ElementTemplate from "../../common/TreeTable/ElementTemplate";
import { RejectionReason } from "../../../constants/reworkPageTasks";
import { AcpCommonService } from "../../../service/AcpCommonService";
import { sortBasedOnSelectedValue } from "../../Projects/ProjectPlan/util";
import { SortAndFilterService } from "../../../service/SortAndFilterService";
import SelectionPopUp from "../../../components/SelectionPopUp/SelectionPopUp";
import { reworkTableDataUpdate } from "../../../store/actions/ReworkMarkUpsAction";
import { FilterMatchMode } from "primereact/api";
import { getReworkData } from "../../../apis/ReworkApi";
import LoadingOverlay from "react-loading-overlay-ts";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";
import { getColumnsOrder, setColumnsOrder } from "../../../apis/commonApi";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";
import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus } from "../../../utils";
import { isRoleMatch} from "../../../utils";
import { localWidthStorage } from "../../../utils"; 

const stateValues = {
  enableFilter: false,
  selectedColumn: "",
  projectFrozen: false,
  selectedFields: {},
  sortData: [],
  filters: [],
  frozenColumns: [],
  columnWiseSelectedFields: [],
  selectedColumnName: null,
  selectedNodeKeys: null,
  expandedKeys: null,
  breadCrumbs: [],
  cicCommentList: {},
  cicHeader: "",
  visible: false,
  showSelctionPopUp: false,
  showReasonReworkPopUp: false,
  tableHeight: "65vh",
  projectState: "",
  checkReadWriteAccess:false,
  urlAccessable:""
};

function Rework() {
  const op = useRef(null);
  const deSelValRef = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const filterArray = useRef([]);
  const colAllSelected = useRef([]);
  const selectedFieldsRef = useRef([]);
  const selectedColumnList = useRef([]);
  const [filters, setFilters] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [pegadata, setPegadata] = useState([]);
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [state, setState] = useState(stateValues);
  const [isSearch, isSearchSet] = useState(false);
  const [frozenWidth, setFrozenWidth] = useState("0px");
  const [frozenCoulmns, setFrozenColumn] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [fieldUpdated, setFieldUpdated] = useState(false);
  const [selectedFields, setSelectedFields] = useState({});
  const [frozenUpdated, setFrozenUpdated] = useState(false);
  const [InitialTableData, setInitialTableData] = useState([]);
  const [allSelectedColumns, setAllSelectedColumns] = useState([]);
  const [customizeViewFields, setCustomizeViewFields] = useState({});
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const [isCustomizeViewActive, setCustomizeViewActive] = useState(false);
  const [isGlobalFilterActivated, setGlobalFilterActivated] = useState(false);
  const [reworkApiData, setReworkApiData] = useState([]);
  const [reworkHistoryData, setReworkHistoryData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [items, setItems] = useState([]);
  const [flatPegadata, setFlatPegadata] = useState([]);

  const [pageEdited, setPageEdited] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const [resizeMode, setResizeMode] = useState("expand");
  const { reworkTableData } = useSelector((state) => state.ReworkMarkupReducer);
  //const { projectPlanDesign, reworkHistory } = useSelector((state) => state.ProjectPlanReducer);
  const { ownerList } = useSelector((state) => state.OwnerListReducer);
  const { ProjectID } = useParams();
  const initialReworkTableDataRef = useRef(reworkTableData);
  const initialselectedNodeKeysRef = useRef(stateValues.selectedNodeKeys);

  const currentUrl = useLocation()?.pathname;

  const localStorageName = "ReworkAllColumnNames";
  const [initialSearchFilters, setInitialSearchFilters] = useState({});
  const [datatableColumnFromPega, setDatatableColumnFromPega] = useState([]);
  const [customizedViewDataFromPega, setCustomizedViewDataFromPega] = useState({});
  const [pgDefaultColumns, setPGDefaultColumns] = useState({});

  const userId = localStorage.getItem("userId");
  const {
    userDetails: { UserGroup, AccessGroup },
  } = useSelector((state) => state?.UserDetailsReducer);

  const { userInformation } = useSelector((state) => state.UserReducer);

  const {checkReadWriteAccess,urlAccessable}=state;

 // 3109 security matrix
  useEffect(() => {
    const isUserAccess = isRoleMatch(AccessGroup, [
      "CapacityManager",
      "ProjectManager",
      "TaskOwner",
    ]);
    updateStateValue('checkReadWriteAccess', isUserAccess);
      setLoader(false);
  }, [AccessGroup]);

  useEffect(()=>{
    if(urlAccessable==="Hidden"){
      updateStateValue('checkReadWriteAccess', false);
    }else if(urlAccessable === "Read"){
      updateStateValue('checkReadWriteAccess', true);
    }else if(urlAccessable === "Write"){
      updateStateValue('checkReadWriteAccess', true);
    }
  },[state.urlAccessable])

  const userAccessPayLoad = {
    ArtworkAgilityPage: {
      UserId: userInformation?.userid,
      ProjectID: ProjectID,
      UserGroup: UserGroup,
    },
  };

  let jsonColumnWidth = localStorage.getItem(localStorageName);
  //Rework API call
  const getReworkApi = async () => {
    setLoader(true);
    if (ProjectID) {
      //Added payload for url access for issue 3109
      const reworkData = await getReworkData(ProjectID, userAccessPayLoad);
      if (reworkData?.responseStatus === 200) {
        updateStateValue('urlAccessable', reworkData?.AccessType);
        if (typeof reworkData === "object") {
          const apiData = reworkData?.reworkData;
          const reworkHistoryData = reworkData?.reworkHistoryData;
          const specialProjectFlag = reworkData?.specialProjectFlag;
          updateStateValue('projectState', reworkData?.projectState);
          dispatch(SpecialProjectAction(specialProjectFlag));
          redirectUnallocatedLinksToArtworkStatus(currentUrl, specialProjectFlag, navigate, true);
          if (apiData?.length > 0) {
            setReworkApiData(apiData);  
            setReworkHistoryData(reworkHistoryData);
            const itemsRework = getformattedResponse(apiData);
            const items = addDisableByState(itemsRework,reworkData?.projectState,reworkData?.AccessType);
            const sortedItems = sortTasksByCustomOrder(items);
            setItems(sortedItems);
            const flatPegadata = items?.flatMap((obj) => extractData(obj));
            setFlatPegadata(flatPegadata);
            setLoader(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    //load the api only when user has access as per 3109 security matrix
    if (UserGroup?.length && userInformation?.userid && state.checkReadWriteAccess) {
    getReworkApi();
    }
  }, [state.checkReadWriteAccess,UserGroup,userInformation]);

  useEffect(() => {
    const fetchColumnName = async () => {
      const dataFromPegaRes = await getColumnsOrder(userId, "ReworkAllColumnNames", "NA", "NA");
      if (dataFromPegaRes != null && dataFromPegaRes?.length > 0) {
        setAllSelectedColumns(dataFromPegaRes);
        setDatatableColumnFromPega(dataFromPegaRes);
      } else {
        let localAllColumns = localStorage.getItem("ReworkAllColumnNames");
        let _allColumns = JSON.parse(localAllColumns);
        if (isEmpty(_allColumns)) {
          _allColumns = ProjectService.getreworkAllColumnNames();
        }
        setAllSelectedColumns(_allColumns);
      }
    };
    fetchColumnName();
  }, []);

  useEffect(() => {
    const reworkColumns = JSON.parse(jsonColumnWidth || "[]");
    const columnNames = reworkColumns?.map((item) => item?.field_Name);

    const filters = columnNames?.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: { value: null, matchMode: FilterMatchMode.CONTAINS },
      }),
      {}
    );
    setInitialSearchFilters(filters);
  }, []);

  const [resetKey, setResetKey] = useState(0);

  var splitString = location.pathname.split("/");
  splitString.pop();
  const projectPlanUrl = splitString.join("/");
  // const accessSecurityMatrix = usePriorityNew(location.pathname.split("/")[1]);

  // initially set data to local storage
  const reworkColumnNames = jsonColumnWidth ? JSON.parse(jsonColumnWidth) : null;
  if (reworkColumnNames === null) {
    const columnNamesRW = ProjectService.getreworkAllColumnNames();
    localStorage.setItem(localStorageName, JSON.stringify(columnNamesRW));
  }

  //this use-effect is to call the tree table again to reset the width, if customize field is changes
  // commented this use effect as of now, it was causing reduntant data issue.
  // useEffect(() => {
  //   const tempData = reworkTableData
  //   setTableData([]);
  //   setTimeout(() => {
  //     tempData?.length > 0 && setTableData(tempData)
  //   }, 100);
  // }, [isCustomizeViewActive]);

  let columnNames = ProjectService.getreworkAllColumnNames()?.map((item) => item.field_Name) || [];
  //Keeping this useEffect too make table data filterable and set table data on initilize
  useEffect(() => {
    if (
      _.isEmpty(state.selectedFields) ||
      Object.values(state.selectedFields)?.every((ele) => ele.length === 0)
    ) {
      setTableData(items);
    } else {
      //if filter is selected
      const filteredData = filterData(items, state.selectedFields);
      setTableData(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedFields, items]);

  useEffect(()=>{
    if (items?.length) {
      setInitialTableData(_.cloneDeep(items));
    }
  },[items])

  const reorderColumnsSetColumnOrder = async (entities) => {
    const freezedCols = entities?.columns?.freezedColumns?.fieldsData;
    const availableCols = entities?.columns?.availableFields?.fieldsData;
    const selectedCols = entities?.columns?.selectedFields?.fieldsData;

    // for correct order of columns
    let reworkDataCols = transformReworkData(entities?.tasks, freezedCols, availableCols);
    const orderMap = entities?.columns?.selectedFields?.fieldsData?.reduce(
      (acc, fieldName, index) => {
        acc[fieldName] = index;
        return acc;
      },
      {}
    );

    // Sort columns: freeze columns first, then order the rest based on orderMap
    reworkDataCols?.sort((a, b) => {
      // Ensure freezed columns are at the front and maintain their order
      if (a.freeze && !b.freeze) {
        return -1;
      }
      if (!a.freeze && b.freeze) {
        return 1;
      }
  
      // For frozen columns, order by their appearance in freezedCols
      if (a.freeze && b.freeze) {
        const indexA = freezedCols.indexOf(a.field_Name);
        const indexB = freezedCols.indexOf(b.field_Name);
        return indexA - indexB;
      }
  
      // For non-frozen columns, order by their appearance in selectedCols
      const indexA = selectedCols.indexOf(a.field_Name);
      const indexB = selectedCols.indexOf(b.field_Name);
      return indexA - indexB;
    });

    reworkDataCols = reworkDataCols?.map(field => {
      if (removeUnderscore(field?.field_Name) === 'taskid') {
        return { ...field, field_Name: 'Task ID' };
      }
      return field;
    });

    localStorage.setItem("customizeViewFieldsRework", JSON.stringify(entities.columns));
    localStorage.setItem("ReworkAllColumnNames", JSON.stringify(reworkDataCols))
    await setColumnsOrder(
      userId,
      "customizeViewFieldsRework",
      "NA",
      "NA",
      false,
      JSON.stringify(entities.columns)
    );
    await setColumnsOrder(
      userId,
      "ReworkAllColumnNames",
      "NA",
      "NA",
      false,
      JSON.stringify(reworkDataCols)
    );
    setAllSelectedColumns(reworkDataCols);
    setCustomizeViewFields(entities.columns);
    setResetKey((prev)=> prev+1);
  };
  const transformReworkData = (data, freezedCols, availableCols) => {
    const result = [];
    let sequence = 1;

    for (const key in data) {
      const item = data[key];

      if(!availableCols?.includes(item.Field_Name)){
        result.push({
          field_Name: item.Field_Name,
          width: item.width,
          freeze: freezedCols?.includes(item.Field_Name) ? true : false,
          reorder: item.reorder,
          sequence: item.Sequence === "" ? sequence++ : parseInt(item.Sequence), // Default sequence to increment if empty
        });
      }
    }

    return result;
  };
  useEffect(() => {
    dispatch(reworkTableDataUpdate(tableData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    handleGlobalFilter();
  }, [tableData]);

  useEffect(() => {
    const expandedKeys = expandAll(items, {});
    setState((prev) => ({
      ...prev,
      expandedKeys,
    }));

    if (
      datatableColumnFromPega !== null &&
      datatableColumnFromPega !== undefined &&
      datatableColumnFromPega?.length > 0
    ) {
      setAllSelectedColumns(datatableColumnFromPega);
    } else if (isEmpty(customizedViewDataFromPega)) {
      // set initial column values if its empty
      const StorageData = localStorage.getItem("customizeViewFieldsRework");
      const customizeViewFieldsStorage = StorageData && JSON.parse(StorageData);
      if (customizeViewFieldsStorage === null) {
        setToInitialColumns();
      } else {
        setCustomizeViewFields(customizeViewFieldsStorage);
      }
      handleColumns(ProjectService.getreworkAllColumnNames());
    }
  }, [items, datatableColumnFromPega]);

  useEffect(() => {
    if (reworkTableData?.length) {
      const updatedPegadata = SortAndFilterService.updateAssigneeToOwner(reworkTableData);
      setPegadata(updatedPegadata);
    }
  }, [reworkTableData]);

  useEffect(() => {
    setFrozenUpdated(frozenUpdated);
    frozenColumn(getCustomizeViewLocalStorage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frozenUpdated]);

  useEffect(() => {
    handleGlobalFilter();
  }, [allSelectedColumns]);

  const handleChildClick = (isClicked) => {
    if (!isClicked) {
      onClickClearFilter();
      isSearchSet(false);
      if(isSearch){
        setResetKey((prev)=>prev + 1);
      }
    }
  };

  const handleGlobalFilter = () => {
    const GlobalFilter =
      (state.columnWiseSelectedFields && state.columnWiseSelectedFields.length !== 0) || isSearch || filters?.length !== 0;
    setGlobalFilterActivated(GlobalFilter);
  };

  const getMarkUps = (rowInfo) => {
    setState((prev) => ({
      ...prev,
      visible: true,
      cicCommentList: rowInfo,
      cicHeader: rowInfo.data.Task,
    }));
  };
  const handleColumns = async (allColumnsname) => {
    let StorageData;
    const customizedDataFromPega = await getColumnsOrder(
      userId,
      "customizeViewFieldsRework",
      "NA",
      "NA"
    );
    if (!isEmpty(customizedDataFromPega)) {
      StorageData = customizedDataFromPega;
      setCustomizeViewFields(customizedDataFromPega);
      setCustomizedViewDataFromPega(customizedDataFromPega);
      frozenColumn(customizedDataFromPega);
      const parsedData = customizedDataFromPega?.selectedFields?.fieldsData?.length
        ? customizedDataFromPega
        : customizedDataFromPega && JSON.parse(customizedDataFromPega);
      const freezedData = parsedData?.freezedColumns?.fieldsData;
      setFrozenColumn([...freezedData]);
      if (datatableColumnFromPega?.length === 0) {
        const reworkColFromPega = await getColumnsOrder(userId, "ReworkAllColumnNames", "NA", "NA");
        setAllSelectedColumns(reworkColFromPega);
        setDatatableColumnFromPega(reworkColFromPega);
      }
    } else {
      StorageData = localStorage.getItem("customizeViewFieldsRework");
      const customizeViewFieldsStorage = StorageData && JSON.parse(StorageData);
      if (
        customizeViewFieldsStorage !== undefined &&
        customizeViewFieldsStorage !== null &&
        customizeViewFieldsStorage?.length !== 0
      ) {
        const parsedData = customizeViewFieldsStorage?.selectedFields?.fieldsData?.length
          ? customizeViewFieldsStorage
          : customizeViewFieldsStorage && JSON.parse(customizeViewFieldsStorage);
        const freezedData = parsedData?.freezedColumns?.fieldsData;
        let selectedData = parsedData?.selectedFields?.fieldsData;
        const availableData = parsedData?.availableFields?.fieldsData;
        setFrozenColumn([...freezedData]);
        if (freezedData?.length > 0) {
          selectedData = [...freezedData, ...selectedData];
        }
        if (freezedData?.length > 0 || availableData?.length > 0) {
          setCustomizeViewActive(!isCustomizeViewActive);
        }
        let tempallColumnsname = cloneDeep(allColumnsname);
        tempallColumnsname?.forEach((ele) => {
          if (freezedData?.some((str) => ele.field_Name === str)) {
            return (ele["freeze"] = true);
          } else {
            return (ele["freeze"] = false);
          }
        });
        const alteredColumns = tempallColumnsname?.filter((column) => {
          if (selectedData?.some((str) => column.field_Name === str)) {
            return column;
          }
        });
        const sortedalteredColumns = alteredColumns?.length
          ? sortBasedOnSelectedValue(alteredColumns, selectedData)
          : selectedData;
        setAllSelectedColumns(sortedalteredColumns);
        frozenColumn(customizeViewFieldsStorage);
      }
    }
  };
  const getCustomizeViewLocalStorage = () => {
    const StorageData = localStorage.getItem("customizeViewFieldsRework");
    const customizeViewFieldsStorage = StorageData && JSON.parse(StorageData);
    return customizeViewFieldsStorage;
  };

  // const frozenColumn = () => {
  //   const customizeViewFieldsStorage = getCustomizeViewLocalStorage();
  //   let freezedDataLen;
  //   if (
  //     customizeViewFieldsStorage !== undefined &&
  //     customizeViewFieldsStorage !== null &&
  //     customizeViewFieldsStorage?.length !== 0
  //   ) {
  //     freezedDataLen = customizeViewFieldsStorage?.freezedColumns?.fieldsData?.length;
  //   }
  //   const totalFrozenWidth = freezedDataLen * 200;
  //   if (totalFrozenWidth > 0) {
  //     setFrozenWidth(`${totalFrozenWidth}px`);
  //   } else {
  //     setFrozenWidth(`0px`);
  //   }
  // };
  const frozenColumn = (customizeViewFieldsStorage) => {
    let totalFrozenWidth = 0;

    if (
      customizeViewFieldsStorage !== undefined &&
      customizeViewFieldsStorage !== null &&
      customizeViewFieldsStorage?.length !== 0
    ) {
      const freezedData = customizeViewFieldsStorage?.freezedColumns?.fieldsData || [];

      freezedData.forEach((field) => {
        totalFrozenWidth += field === "Task" ? 280 : 150;
      });
    }

    if (totalFrozenWidth > 0) {
      setFrozenWidth(`${totalFrozenWidth}px`);
    } else {
      setFrozenWidth(`0px`);
    }
  };

  const onColumnResizeEnd = (event) => {
    let columnWidth = [];
    if (jsonColumnWidth) {
      columnWidth = allSelectedColumns; // columns from customize view
    }
    localWidthStorage(event,"columnWidthRework")
    if (columnWidth) {
      columnWidth.forEach((list) => {
        if (event.column.props.field === list.field_Name && list.freeze == false) {
          list.width = event.element.offsetWidth;
        }
      });
    }
    localStorage.setItem(localStorageName, JSON.stringify(columnWidth));
    setFieldUpdated(!fieldUpdated);
  };
  const isCustomizeViewEnabled = () => {
    const customizeViewFieldsStorage = getCustomizeViewLocalStorage();
    let freezedDataLen;
    let availableDataLen;
    if (
      customizeViewFieldsStorage !== undefined &&
      customizeViewFieldsStorage !== null &&
      customizeViewFieldsStorage?.length !== 0
    ) {
      freezedDataLen = customizeViewFieldsStorage?.freezedColumns?.fieldsData?.length;
      availableDataLen = customizeViewFieldsStorage?.availableFields?.fieldsData?.length;
    }
    return freezedDataLen > 0 || availableDataLen > 0 ? true : false;
  };
  // expand collapse the table data
  const toggleApplications = () => {
    if (state.expandedKeys === null) {
      const _expandedKeys = {};
      const keys = expandAll(items, _expandedKeys);
      updateStateValue("expandedKeys", keys);
    } else {
      updateStateValue("expandedKeys", null);
    }
  };
  const onSearchClick = () => {
    isSearchSet(!isSearch);
  };

  // const onDropdownChange = (rowData, { value }, ele) => {
  //   // const updatedTreeData = getDropdownChangedValues(rowData, value, ele, reworkTableData);
  //   // setTableData(updatedTreeData);
  //   // setPageEdited(true);
  //   // on Duration change/ any edit in page setPageEdited is being true
  // };


  const onDropdownChange = (rowData, e, ele, removeUpdate, InitialValue) => {
    const value = e?.target?.value ?? null;
    const updatedTreeData = getDropdownChangedValues(rowData, value, ele, reworkTableData);
    setTableData(updatedTreeData);
    // setPageEdited(true);
    // on Duration change/ any edit in page setPageEdited is being true
  };

  const onDurationChange = (rowData, { value }, ele) => {
    rowData.data[ele] = value === null ? value : value < 1 ? 1 : value?.toString();
    setTableData([...reworkTableData]);
    // setPageEdited(true);
    // on Duration change/ any edit in page setPageEdited is being true
  };
  // body template of columns
  const elementTemplate = (options, rowData) => {
    return (
      <ElementTemplate
        optionsVal={options}
        rowDataVal={rowData}
        sortData={sortData}
        filterData={filters}
        selectedNodes={selectedNodes}
        getMarkUps={getMarkUps}
        onDropdownChange={onDropdownChange}
        autoVal={autoVal}
        getDDoptions={getDDoptions}
        onDurationChange={onDurationChange}
        durationEnabled={durationEnabled}
        className="dropdown-width"
        isDisabled={state?.projectState === "Complete" || urlAccessable==="Read" }
        RolesOwners={ownerList}
      />
    );
  };
  const toggleDialog = () => {
    setState((prev) => ({
      ...prev,
      visible: false,
    }));
  };
  // crate dynamic columns

  const dynamicColumns = () => {
    const localColumnWidth=localStorage.getItem("columnWidthRework");
    let columnWidth={};
    if(localColumnWidth){
      columnWidth=JSON.parse(localColumnWidth);
    }
    const columnWidthKeys=Object.keys(columnWidth);
    let allColumnsdata = allSelectedColumns; // columns from customize view
    if (allColumnsdata && allColumnsdata?.length) {
      return allColumnsdata?.map((ele, i) => {
        return (
          <Column
            key={ele?.field_Name}
            field={ele?.field_Name}
            filterField={ele?.field_Name}
            filterMatchMode="contains"
            filter={isSearch}
            filterPlaceholder={ele?.field_Name}
            header={columnHeader(ele?.field_Name, ele?.field_Name, ele)}
            expander={ele?.field_Name === "Task"}
            columnKey={ele?.field_Name || i}
            frozen={ele?.freeze}
            body={elementTemplate}
            // alignFrozen="left"
            // This logic dynamically assigns class names based on the value of `ele.field_Name` and `ele.freeze`.
            // - If the field is "Task" and freeze is true, apply "task-field-width fontBoldcolor".
            // - If the field is "Task" and freeze is false or undefined, apply "task-field-width".
            // - If the field is "Role" and freeze is false, apply "role-field-width".
            // - If the field is either "Rework" or "Task ID", apply "customized-min-width".
            // - If freeze is true for any other field, apply "fontBoldcolor".
            // - If none of the above conditions are met, apply "cursorMove non-task-width".
            className={
              ele?.field_Name === "Task" && ele?.freeze
                ? "task-field-width fontBoldcolor"
                : ele?.field_Name === "Task"
                ? "task-field-width"
                : ele?.field_Name === "Role" && !ele?.freeze
                ? "role-field-width"
                : ele?.field_Name === "Rework" || ele?.field_Name === "Task ID"
                ? "customized-min-width"
                : ele?.freeze
                ? "fontBoldcolor"
                : "cursorMove non-task-width"
            }
            showFilterMenu={false}
            style={{
              width:columnWidthKeys?.includes(ele?.field_Name)&& columnWidth
                && columnWidth[ele?.field_Name] 
               
            }}
          />
        );
      });
    }
    return <></>;
  };
  const columnHeader = (options, keyId, col) => {

    return (
      //<div className="flex-display" key={keyId} >
        <div 
        className={`flex-display ${col.freeze ? "font-bold frozen-width" : ""}`} 
        key={keyId}
        > 
        <img
          src={filter}
          alt="Column Filter"
          key={keyId}
          onClick={(e) => columnHeaderClick(e, keyId)}
          className={
            sortData?.includes(options) ||
            selectedFields?.[options]?.length > 0 ||
            selectedFields?.[options]?.[0] === false ||
            frozenCoulmns?.includes(options)
              ? "columnFilterIcon filter-color-change"
              : "columnFilterIcon"
          }
        />
        <span
          className={
            sortData?.includes(options) ||
            selectedFields?.[options]?.length > 0 ||
            selectedFields?.[options]?.[0] === false ||
            frozenCoulmns?.includes(options)
              ? "columnFilterIcon filter-color-change"
              : "columnFilterIcon"
          }
        >
          {options}
        </span>
      </div>
    );
  };

  // update column name on click of column header
  const columnHeaderClick = (e, options) => {
    op.current.toggle(e);
    setSelectedColumnName(options);
    setState((prev) => ({ ...prev, selectedColumnName: options }));
  };

  const onClickClearFilter = () => {
    // let allColumns = ProjectService.getreworkAllColumnNames();
    // localStorage.setItem(localStorageName, JSON.stringify(clearAllFilterValues(allColumns)));
    localStorage.removeItem("sortingDataRework");
    // setAllSelectedColumns(allColumns);
    // setFrozenUpdated(!frozenUpdated);
    setGlobalFilterActivated(false);
    selectedColumnList.current = [];
    selectedFieldsRef.current = [];
    setFieldUpdated(!fieldUpdated);
    // setCustomizeViewActive(false);
    setSelectedFields({});
    setVisible(false);
    setFilters([]);
    // clearColumnWiseFilter();
    // setToInitialColumns();
    // setFrozenColumn([]);
  };

  const removePartialSelected = (keyvalues) => {
    return (
      keyvalues &&
      Object.keys(keyvalues)?.filter((innerobj) => keyvalues[innerobj]?.checked === true)
    );
  };
  const storeDeselecVal = (deSelVal) => {
    deSelValRef.current.push(...deSelVal);
  };

  //update state value
  const updateStateValue = (key, val) => {
    // separating selection and deslection logic
    if (key === "selectedNodeKeys") {
      const nodeKeyValues = removePartialSelected(state.selectedNodeKeys) || [];
      const changeStatusValues = removePartialSelected(val) || [];
      const nodeKeyLen = nodeKeyValues?.length || 0;
      const changeStatusLen = changeStatusValues?.length;
      const dataforSelection = filterDataForSelection(flatPegadata);
      //for selection always the onSelectionChange value will be grater then perious selected value
      if (changeStatusLen >= nodeKeyLen) {
        // >= because we are removing partialSelection
        const selectedKey = _.difference(changeStatusValues, nodeKeyValues);
        const checkedValues = getSelectedValues(
          dataforSelection,
          selectedKey,
          val,
          reworkTableData
        );
        const keyValue = checkedValues.value;
        setSelectedNodes(checkedValues.selectionChangeList);
        setState((prev) => ({ ...prev, selectedNodeKeys: keyValue }));
      }
      //for DeSelection always the onSelectionChange value will be lesser then perious selected value
      else if (changeStatusLen < nodeKeyLen) {
        //deselection
        const unselectedKey = _.difference(nodeKeyValues, changeStatusValues);
        const deselectedValues = getdeSelectedValues(
          dataforSelection,
          unselectedKey,
          state.selectedNodeKeys,
          selectedNodes,
          reworkTableData
        );
        const keyValue = deselectedValues.value;
        storeDeselecVal(deselectedValues?.allDeselectionList);
        setSelectedNodes(deselectedValues.updatedSelectedNode);
        setState((prev) => ({ ...prev, selectedNodeKeys: keyValue }));
      }
    } else {
      setState((prev) => ({ ...prev, [key]: val }));
    }
  };
  const headerContent = <div>{RejectionReason.rejectionHeader}</div>;
  /*code for customize feild*/
  const formCustomizeObject = (availableFields, freezedColumns, selectedFeilds) => {
    const customizeViewFieldsUpdated = {
      availableFields: {
        id: "availableFields",
        title: "Available Fields",
        fieldsData: availableFields,
      },
      freezedColumns: {
        id: "freezedColumns",
        title: "Freezed Fields",
        fieldsData: freezedColumns,
      },
      selectedFields: {
        id: "selectedFields",
        title: "Selected Fields",
        fieldsData: selectedFeilds,
      },
    };

    return customizeViewFieldsUpdated;
  };


  const setToInitialColumns = () => {
    let selectedFeilds = ProjectService.getreworkAllColumnNames();
    const localAllColumns = selectedFeilds;
    selectedFeilds = selectedFeilds?.map((ele) => {
      return ele.field_Name;
    });
    const customizeViewFieldsUpdated = formCustomizeObject([], [], selectedFeilds);
    setCustomizeViewFields(customizeViewFieldsUpdated);
    localStorage.setItem("customizeViewFieldsRework", JSON.stringify(customizeViewFieldsUpdated));
    localStorage.setItem("ReworkAllColumnNames", JSON.stringify(localAllColumns));
    setAllSelectedColumns(localAllColumns);
    setFrozenColumn([]);
    setFrozenUpdated(!frozenUpdated);
    setCustomizeViewActive(false);
    setResetKey((prev)=> prev + 1);
  };
  const handleCustomizedView = async (data) => {
    if (data !== undefined && data !== null && data?.length !== 0) {
      const parsedData = data?.selectedFields?.fieldsData?.length
        ? data?.selectedFields?.fieldsData
        : data && JSON.parse(data);
      const freezedData = parsedData?.freezedColumns?.fieldsData;
      let selectedData = parsedData?.selectedFields?.fieldsData;
      localStorage.setItem("customizeViewFieldsRework", JSON.stringify(parsedData));
      const updatedDatafromPega = await getColumnsOrder(userId, "ReworkAllColumnNames", "NA", "NA");
      setDatatableColumnFromPega(updatedDatafromPega);
      if (datatableColumnFromPega?.length > 0) {
        handleColumns(datatableColumnFromPega);
      } else {
        handleColumns(ProjectService.getreworkAllColumnNames());
      }
      selectedData = AcpService?.removeCommonValues(selectedData, freezedData);
      if (freezedData?.length > 0) {
        selectedData = [...freezedData, ...selectedData];
        setFrozenColumn([...freezedData]);
      }
      if (selectedData?.length < 7) {
        setResizeMode("fit");
      } else {
        setResizeMode("expand");
      }
      setCustomizeViewFields(parsedData);
    }
  };

  const onSubmitReworkReasonFun = () => {
    updateStateValue("showSelctionPopUp", true);
    updateStateValue("showReasonReworkPopUp", false);
  };

  const onReworkSubmit = () => {
    const checkCICJob = selectedNodes?.some((item) => item?.code?.includes("RCIC"));
    if (checkCICJob) {
      updateStateValue("showReasonReworkPopUp", true);
    } else {
      updateStateValue("showSelctionPopUp", true);
    }
  };

  const onGlobalFilterChange = (e, colName) => {
    const { value, multiplesFlag, arr } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      reworkTableData,
      filterArray,
      selectedColumnList,
      colAllSelected,
      selectedFieldsRef
    );
    const _selectedFieldsRef = (selectedFieldsRef?.current || [])?.map((el, i) => {
      if (Object.keys(arr)?.[0] === Object.keys(el)?.[0]) {
        return {
          ...el,
          [`${selectedColumnName}`]: arr[selectedColumnName],
        };
      } else {
        return el;
      }
    });
    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      localStorage.removeItem("_selectedFieldsRefCurrentRework");
    }
    if (filterArray?.current?.length) {
      if ((value?.length > 0 && !multiplesFlag) || colAllSelected?.current?.length >= 1) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const _selectedFieldsRefWithUniqueObjects = AcpService.getUniqueObjects(
          _selectedFieldsRefWithoutEmptyArray
        );
        const convertedObj = AcpCommonService.convertArrayToObject(
          _selectedFieldsRefWithUniqueObjects
        );
        const uniqueObj = AcpCommonService.getUniqueValuesObject(convertedObj);

        if (Object.entries(uniqueObj)?.length) {
          setGlobalFilterActivated(true);
        } else {
          setGlobalFilterActivated(false);
        }
        let { Task, ...newObj } = uniqueObj;
        let filteredData2 = [];
        let filteredData = [];
        if (Task?.length) {
          const Task2 = {
            Task: Task,
          };
          filteredData = SortAndFilterService.applyMutilabelFilter(reworkTableData, Task2, filters);
        }
        if (Object.entries(newObj)?.length) {
          filteredData2 = SortAndFilterService.multipleColumnFilter(
            filteredData?.length ? filteredData : reworkTableData,
            newObj,
            filters
          );
        }
        const finalFilteredData = filteredData2?.length ? filteredData2 : filteredData;
        setSelectedFields(uniqueObj);
        if (finalFilteredData?.length && sortData?.length) {
          onSort(sortData?.[0], sortData?.[1], finalFilteredData);
        } else {
          setFilters(finalFilteredData);
          selectedColumnList.current = finalFilteredData;
        }
      } else {
        setFilters([]);
        setSelectedFields({});
        selectedFieldsRef.current = [];
        setGlobalFilterActivated(false);
        selectedColumnList.current = [];
      }
    } else {
      setFilters([]);
      setSelectedFields({});
      selectedFieldsRef.current = [];
      selectedColumnList.current = [];
      setGlobalFilterActivated(false);
    }
  };

  const clearColumnWiseFilter = () => {
    let _selectedColumnName = selectedColumnName;
    if (sortData && sortData?.length && sortData[0] === _selectedColumnName) {
      localStorage.removeItem("sortingDataRework");
      setSortData([]);
    }

    const selectedFields2 = _.cloneDeep(selectedFields);
    const uniqueObj = AcpCommonService.getUniqueValuesObject(selectedFields2);
    const _selectedFields = AcpService.emptyProperty(uniqueObj, _selectedColumnName);
    const __selectedFields = AcpCommonService.removeEmptyArraysWithNoData(_selectedFields);
    selectedFieldsRef.current = __selectedFields;
    setSelectedFields(__selectedFields);
    if (AcpService.areAllPropertiesEmptyArrays(__selectedFields)) {
      localStorage.removeItem("_selectedFieldsRefCurrentRework");
    }
    if (Object.entries(__selectedFields)?.length) {
      setGlobalFilterActivated(true);
    } else {
      setGlobalFilterActivated(false);
    }
    let { Task, ...newObj } = __selectedFields;
    let filteredData2 = [];
    let filteredData = [];
    if (Task?.length) {
      const Task2 = {
        Task: Task,
      };
      filteredData = SortAndFilterService.applyMutilabelFilter(reworkTableData, Task2, filters);
    }
    if (Object.entries(newObj)?.length) {
      filteredData2 = SortAndFilterService.multipleColumnFilter(
        filteredData?.length ? filteredData : reworkTableData,
        newObj,
        filters
      );
    }
    const finalFilteredData = filteredData2?.length ? filteredData2 : filteredData;
    if (finalFilteredData?.length && Object.keys(__selectedFields)?.length) {
      setFilters(finalFilteredData);
    } else {
      setFilters([]);
      setGlobalFilterActivated(false);
    }
  };

  const onSort = (column, direction, filterData) => {
    const sortedData = filterData?.length
      ? filterData
      : filters?.length
      ? filters
      : reworkTableData;
    let sortedDataSet = [];
    if (column === "Task") {
      sortedDataSet = SortAndFilterService.sortMultiLabelData(sortedData, column, direction);
    } else {
      sortedDataSet = SortAndFilterService.sortMultiColumnData(sortedData, column, direction);
    }
    setSortData([column, direction]);
    localStorage.setItem("sortingDataRework", JSON.stringify(true));
    if (filterData?.length) {
      setFilters(sortedDataSet);
      selectedColumnList.current = sortedDataSet;
    } else {
      setPegadata(sortedDataSet);
    }
  };

  // making a reusable function if page is edited
  const handlePageEdited = (renderType) =>{
    const hasUnsavedChanges =
        JSON.stringify(reworkTableData) !== JSON.stringify(initialReworkTableDataRef.current) ||
        JSON.stringify(stateValues.selectedNodeKeys) !==
          JSON.stringify(initialselectedNodeKeysRef.current);
      if (hasUnsavedChanges) {
        // markDirty();
        if(renderType === "handleBack"){
          setShowDialog(true);
        }
      } else {
        // markClean();
        // setPageEdited(false);
        if(renderType === "handleBack"){
          navigate(projectPlanUrl);
        }
      }
  }

  useEffect(()=>{
    const removedOwnerTableData = SortAndFilterService.removeOwnerFromTableData(_.cloneDeep(tableData));
    const isObjectEqual = JSON.stringify(InitialTableData) === JSON.stringify(removedOwnerTableData)
    if(!isObjectEqual){
      setPageEdited(true);
    }else{
      setPageEdited(false);
    }
  },[tableData])

  // this block will be responsible to show warning on sidebar click if page is edited
  const { markDirty, markClean } = useUnsavedChangesWarning();
  useEffect(() => {
    if (pageEdited) {
      handlePageEdited("reworkTableDataChange");
    }
  }, [reworkTableData, stateValues.selectedNodeKeys]);

  useEffect(()=>{
    markClean();
    setPageEdited(false);
    let selectedFeilds = ProjectService.getreworkAllColumnNames();
    selectedFeilds = selectedFeilds?.map((ele) => {
      return (ele.field_Name === "Task ID" ? "Task_ID" : ele.field_Name);
    });
    const customizeViewFieldsUpdated = formCustomizeObject([], [], selectedFeilds);
    setPGDefaultColumns(customizeViewFieldsUpdated);
  },[]);

  useEffect(()=>{
    if(pageEdited){
      markDirty();
    }else{
      markClean();
    }
  },[pageEdited])

  // this function will make sure if page is not edited then it will directly redirect
  // but if page is edited then and it has no unsaved changes also it will redirect
  // but if page is edited and has unsaved changes then warning will be shown 
  // once user confirms warning then only confirmNavigation will be called.
  const handleBack = () => {
    if (pageEdited) {
      // const hasUnsavedChanges =
      //   JSON.stringify(reworkTableData) !== JSON.stringify(initialReworkTableDataRef.current) ||
      //   JSON.stringify(stateValues.selectedNodeKeys) !==
      //     JSON.stringify(initialselectedNodeKeysRef.current);
      // if (hasUnsavedChanges) {
      //   markDirty();
      //   setShowDialog(true);//TBD
      // } else {
      //   markClean();
      //   setPageEdited(false);
      //   navigate(projectPlanUrl); //tbd
      // }
      handlePageEdited("handleBack");
    } else {
      markClean();
      setPageEdited(false);
      navigate(projectPlanUrl);
    }
  };

  // to hide warning
  const cancelNavigation = () => {
    setShowDialog(false);
  };

  // this function will be called once user confirm to remove changes, it will continue to navigate
  const confirmNavigation = () => {
    markClean();
    setShowDialog(false);
    setPageEdited(false);
    navigate(projectPlanUrl);
  };

  return (
    <PageLayout>
     {loader? (
           <LoadingOverlay className="h-100" active={loader} spinner text=""/>
          ) : 
      checkReadWriteAccess ?
      <div className="rework-wrapper">
        <CustomizeView
          availableFields={[]}
          headerName={"Rework"}
          showTaskDialog={visible}
          onClose={() => setVisible(false)}
          customizeViewFields={customizeViewFields}
          setCustomizeViewFields={handleCustomizedView}
          resetToPgDefault={() => setToInitialColumns()}
          allColumns={getUpdatedAllColumnFormat(ProjectService.getreworkAllColumnNames())}
          reorderColumnsSetColumnOrder={reorderColumnsSetColumnOrder}
          pgDefaultColumns={pgDefaultColumns}
        />
        <ReworkHeader
          headers={columnNames}
          filename={`Rework_Records`}
          selectedRows={selectedNodes}
          onSearchClick={onSearchClick}
          allData={(filters?.length ? filters : items)?.flatMap((obj) => extractData(obj))}
          clearFilter={onClickClearFilter}
          expandedKeys={state.expandedKeys}
          setVisible={() => setVisible(true)}
          onChildClick={handleChildClick}
          toggleApplications={toggleApplications}
          isFilterEnabled={isGlobalFilterActivated || isSearch}
        />
        <LoadingOverlay className="h-100" active={loader} spinner text="">
          <div className="reworkTable myProjectAnddAllProjectList">
            <div className="card">
              {tableData && tableData.length ? (
                <>
                  <ACPFilter
                    op={op}
                    onSort={onSort}
                    isTreeTable={true}
                    pageName={"Rework"}
                    sortData={sortData}
                    pegadata={pegadata}
                    setSortData={setSortData}
                    projectData={flatPegadata}
                    generateCustomOptions={true}
                    selectedFields={selectedFields}
                    selectAllColumnNotIncluded={true}
                    frozenCoulmns={state.frozenColumns}
                    selectedColumnName={selectedColumnName}
                    onGlobalFilterChange={onGlobalFilterChange}
                    clearColumnWiseFilter={clearColumnWiseFilter}
                  />
                  <CommonTreeTable
                    frozenWidth={frozenWidth}
                    scrollHeight={state.tableHeight}
                    expandedKeys={state.expandedKeys}
                    onColumnResizeEnd={onColumnResizeEnd}
                    selectedNodeKeys={state.selectedNodeKeys}
                    className={isSearch ? "searchWithTreeTable" : ""}
                    data={filters.length ? filters : reworkTableData}
                    setExpandedKeys={(node) => updateStateValue("expandedKeys", node)}
                    onSelectColumn={(rowData) => onSelectColumn(selectedNodes, rowData)}
                    searchFilters={initialSearchFilters}
                    resetKey={resetKey}
                    setSelectedNodeKeys={(value) => updateStateValue("selectedNodeKeys", value)}
                    resizeMode={resizeMode}
                  >
                    {dynamicColumns()}
                  </CommonTreeTable>
                </>
              ) : (
                "No Records Found"
              )}
            </div>
            <Dialog
              visible={state.visible}
              onHide={toggleDialog}
              showHeader={true}
              header={headerContent}
              id="reworkDialog"
              className="rejectionReason"
            >
              <RejectReason
                headingText={state.cicHeader}
                cicCommentList={state.cicCommentList}
                visible={state.visible}
              />
            </Dialog>
            <Dialog
              visible={state.showSelctionPopUp}
              onHide={() => updateStateValue("showSelctionPopUp", false)}
              className="SelectionPopUpOverlay"
              maximizable
            >
              <SelectionPopUp
                selectedNodes={selectedNodes}
                unSelectedNodes={deSelValRef?.current}
                treedata={reworkTableData}
                onSubmission={() => updateStateValue("showSelctionPopUp", false)}
                projectPlanData={reworkApiData}
              />
            </Dialog>
            <Dialog
              visible={state.showReasonReworkPopUp}
              onHide={() => updateStateValue("showReasonReworkPopUp", false)}
              style={{ width: popUpConst.popUpWidth }}
            >
              <ReasonForRework
                reworkHistoryData={reworkHistoryData}
                selectedData={selectedNodes}
                treedata={reworkTableData}
                onSubmitReworkReason={onSubmitReworkReasonFun}
                onClosePopUp={() => {
                  updateStateValue("showReasonReworkPopUp", false);
                }}
              />
            </Dialog>
          </div>
          <div className="rework-footer">
            <footer>
              <Button 
                variant="secondary" 
                onClick={handleBack}
                // stoping redirection directly, for conditional redirection using handleBack
                // onClick={() => navigate(projectPlanUrl)}
              >
                Back
              </Button>
              <Button
                onClick={() => onReworkSubmit()}
                className={_.isEmpty(selectedNodes) || state?.projectState === "Complete" || urlAccessable==="Read" ? "disabled" : ""}
              >
                Next
              </Button>
            </footer>
          </div>
        </LoadingOverlay>
      </div>:  <UnAuthorizedComponent nowrapper={true} />}
      {/* Using to Show the warning */}
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </PageLayout>
  );
}
export default Rework;