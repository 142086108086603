import { CreateSession } from "./useSession";
import { DEVURL } from "../../apis/envUrl";


export const ThumbnailViewer = async (filename, TaskID) => {
  let thumbnailResponse = "";

  try {
    const sessionCreate = await CreateSession(
      `${DEVURL}/createProofscopeSession`,
    );
    const strSession = JSON.parse(JSON.stringify(sessionCreate)).session;

    const json = {
      method: "hub.process_from_whitepaper_with_files_and_variables",
      whitepaper_name: "awm_approval",
      input_name: "get_preview",
      variables: {
        cf_art_path: filename,
        params: { task_id: TaskID },
      },
      session: strSession, // Fix the property name
    };

    const url = new URL(`${DEVURL}/thumbnailView`);
    const conn = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Content-Language": "en-US",
      },
      body: JSON.stringify(json),
    });

    // let headers = {
    //   "Content-Type": "application/json",
    //   "Content-Language": "en-US",
    // };
    // const api = new Api();
    // const axiosInstance = await api.init({ headers });
    // const conn = await axiosInstance({
    //   url: url,
    //   method: "POST",
    //   data: JSON.stringify(json),
    // });

    if (conn.ok) {
      const response = await conn.json();
      thumbnailResponse = response;
    }
  } catch (error) {
    console.error("Error:", error.message);
  }

  return thumbnailResponse;
};
