// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-more .show-more-less-text, .show-more-list .show-more-less-text {
  font-weight: bold;
  cursor: pointer;
}

.show-more-list .selected-list {
  display: flex;
  text-wrap: balance;
  overflow-wrap: anywhere;
  padding: 5px;
  width: 100%;
  background-color: #F6F6F9;
  border: 1px solid #DDDEE0;
  border-radius: 4px;
}
.show-more-list .selected-list .list-name {
  text-wrap: balance;
  overflow-wrap: anywhere;
}
.show-more-list .hide {
  display: none;
}
.show-more-list .show {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/common/ShowMoreLess/index.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;AACF;;AAGI;EACI,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;AAAR;AACQ;EACE,kBAAA;EACA,uBAAA;AACV;AAEM;EACE,aAAA;AAAR;AAEM;EACE,cAAA;AAAR","sourcesContent":["%show-more-less-text {\n  font-weight: bold;\n  cursor: pointer;\n}\n\n.show-more-list {\n    .selected-list {\n        display: flex;\n        text-wrap: balance;\n        overflow-wrap: anywhere;\n        padding: 5px;\n        width: 100%;\n        background-color: #F6F6F9;\n        border: 1px solid #DDDEE0;\n        border-radius: 4px;\n        .list-name {\n          text-wrap: balance;\n          overflow-wrap: anywhere;\n        }    \n      }\n      .hide {\n        display: none;\n      }\n      .show {\n        display: block;\n      }\n      .show-more-less-text{\n        @extend %show-more-less-text;\n      }\n}\n\n.show-more {\n  .show-more-less-text{\n    @extend %show-more-less-text;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
