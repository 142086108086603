export const defaultAllowedFileType = "*";
export const defaultAllowedFileTypeAcp = [
    "text/csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel"
  ];;
export const defaultAllowedFileSize = 2200000000;
export const fileTypeErrorMessage = `Supported file formats include PDF and AI (Adobe Illustrator) only. Please upload a file with the correct format and try again.`;
export const fileTypeErrorMessageAcp = `Supported file formats include CSV and excel only. Please upload a file with the correct format and try again.`;
export const fileSizeErrorMessage = (allowedSize) => `Invalid file size. maximum upload size is ${allowedSize}.`;
export const totalFileSizeQuotient = 1000000;