import Api from ".";
import { DEVURL } from "./envUrl";

export const saveProofscopeURL = async (formData, id, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;
  // const baseURL =
  //   "https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/SaveProofScopeURL";

  apiURL = `${DEVURL}/saveProofScopeURL`;

  const proofscopeURL = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });

  return proofscopeURL;
};
