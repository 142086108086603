import React, { useEffect, useState, useRef } from "react";
import { Col } from "react-bootstrap";
import "./UploadFile.scss";
import { downloadAzureContainer } from "../../../apis/downloadAzureContainer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FileUploadManager from "../../common/FileUploadManager/FileUploadManager";


const UploadFile = ({
  item,
  data,
  azureSubFolder,
  checkReadWriteAccess,
  onFileUpload,
  onFileClear
}) => {

  return (
    <Col sm={12} className="IQCD">
      <FileUploadManager
        customUpload
        onFileUpload={onFileUpload}
        taskStatus={data?.Task_Status}
        taskdata={item?.FileMetaDataList}
        checkReadWriteAccess={checkReadWriteAccess}
        subFolder={azureSubFolder}
        onFileClear={onFileClear}
      />
    </Col>
  );
};

export default UploadFile;
