import React, { useEffect } from "react";
import { optionList } from "../../utils";
import Rework from "../containers/Rework/Rework";
import filter from "../../assets/images/filter.svg";
import { MultiSelect } from "primereact/multiselect";
import { OverlayPanel } from "primereact/overlaypanel";
import BlueFilterIcon from "../../assets/images/BlueFilterIcon.svg";

const ConfirmationPopUp = ({
  op,
  onSort,
  filters,
  isRework,
  projectData,
  fieldUpdated,
  frozenCoulmns,
  frozenUpdated,
  selectedFields,
  setFieldUpdated,
  showSort = true,
  setFrozenUpdated,
  isFilesAndFolder,
  selectedColumnName,
  onGlobalFilterChange,
  clearColumnWiseFilter,
  columnWiseSelectedFields,
  setColumnWiseSelectedFields,
}) => {
  const localStorageName = isRework
    ? "ReworkAllColumnNames"
    : isFilesAndFolder
    ? "filesAllColumnNames"
    : "projectPlanAllColumnNames";
  let optionList1;
  if (selectedColumnName === "Owner") {
    optionList1 = selectedColumnName && optionList(projectData, "Assignee");
  } else {
    optionList1 = selectedColumnName && optionList(projectData, selectedColumnName);
  }
  let showfreeze=true
  useEffect(() => {
    if (selectedFields && selectedColumnName) {
      setColumnWiseSelectedFields(selectedFields[selectedColumnName]);
    }
  }, [selectedFields, selectedColumnName]);

  let jsonColumnWidth = localStorage.getItem(localStorageName);
  if(!isRework){
    showfreeze=selectedColumnName === "Task"?false:true
  }
 
  let allColumns = JSON.parse(jsonColumnWidth);
  let checkSelectedColumnIsFreeze = false;
  let checkSelectedColumnIsSortAtoZ = false;
  let checkSelectedColumnIsSortZtoA = false;
  let isFilterActivated = false;
  if (allColumns) {
    let checkSelectedColumn = allColumns?.filter(
      (item) => item?.field_Name === selectedColumnName
    );
    checkSelectedColumnIsFreeze = checkSelectedColumn[0]?.freeze === true;
    checkSelectedColumnIsSortAtoZ = checkSelectedColumn[0]?.sortAtoZ === true;
    checkSelectedColumnIsSortZtoA = checkSelectedColumn[0]?.sortZtoA === true;
  }

  isFilterActivated =
    checkSelectedColumnIsFreeze ||
    checkSelectedColumnIsSortAtoZ ||
    checkSelectedColumnIsSortZtoA ||
    filters?.length !== 0 ||
    (columnWiseSelectedFields && columnWiseSelectedFields?.length !== 0);

  let dateFormattedColNames = ["Start_Date", "End_Date"];

  const mutilSelectTemplate = (option) => {
    return <div>{dateFormattedColNames.includes(selectedColumnName) ? option : option}</div>;
  };

  const confirmPopData = () => {
    return (
      <div>
        <div className="clearAllFilterMainDiv">
          <div
            id="clearAllFilter"
            className="clearAllFilter"
            // onClick={() => clearColumnWiseFilter()}
            onClick={() => {
              clearColumnWiseFilter();
              setFrozenUpdated(!frozenUpdated);
              allColumns.map((ele) => {
                if (ele.field_Name === selectedColumnName) {
                  ele["sortZtoA"] = false;
                  ele["sortAtoZ"] = false;
                }
              });
              localStorage.setItem(localStorageName, JSON.stringify(allColumns));
            }}
          >
            Clear all filter
          </div>
          <div className="clearAllFilterDiv">
            {isFilterActivated ? (
              <img
                alt="filter logo"
                src={BlueFilterIcon}
                className="header-icons"
                // onClick={() => clearColumnWiseFilter()}
                onClick={() => {
                  clearColumnWiseFilter();
                  setFrozenUpdated(!frozenUpdated);
                  allColumns.map((ele) => {
                    if (ele.field_Name === selectedColumnName) {
                      ele["sortZtoA"] = false;
                      ele["sortAtoZ"] = false;
                    }
                  });
                  localStorage.setItem(localStorageName, JSON.stringify(allColumns));
                }}
              />
            ) : (
              <img src={filter} alt="filter logo" className="header-icons" />
            )}
          </div>
        </div>
        {/* sort start */}
        {showSort && (
          <>
            <div
              id="sortZtoA"
              className="sortAndFrozen"
              onClick={() => {
                allColumns.map((ele) => {
                  if (ele.field_Name === selectedColumnName) {
                    ele["sortZtoA"] = !ele.sortZtoA;
                    ele["sortAtoZ"] = false;
                  } else {
                    ele["sortZtoA"] = false;
                    ele["sortAtoZ"] = false;
                  }
                });
                onSort(selectedColumnName, "asc");
                localStorage.setItem(localStorageName, JSON.stringify(allColumns));
              }}
            >
              {checkSelectedColumnIsSortZtoA ? (
                <div style={{ color: "#003DA5" }}> Sort z to a </div>
              ) : (
                <div> Sort z to a</div>
              )}
            </div>
            <div
              id="sortAtoZ"
              className="sortAndFrozen"
              onClick={() => {
                allColumns.map((ele) => {
                  if (ele.field_Name === selectedColumnName) {
                    ele["sortAtoZ"] = !ele.sortAtoZ;
                    ele["sortZtoA"] = false;
                  } else {
                    ele["sortZtoA"] = false;
                    ele["sortAtoZ"] = false;
                  }
                });
                localStorage.setItem(localStorageName, JSON.stringify(allColumns));
                onSort(selectedColumnName, "asc");
              }}
            >
              {checkSelectedColumnIsSortAtoZ ? (
                <div style={{ color: "#003DA5" }}> Sort a to z </div>
              ) : (
                <div> Sort a to z</div>
              )}
            </div>
          </>
        )}
        {/* sort end */}
       
        <div className="multiSelect">
          <MultiSelect
            filter
            options={optionList1}
            maxSelectedLabels={3}
            className="p-column-filter"
            value={columnWiseSelectedFields}
            itemTemplate={mutilSelectTemplate}
            placeholder={`Select ${selectedColumnName}`}
            onChange={(e) =>
              selectedColumnName === "Owner"
                ? onGlobalFilterChange(e, "Assignee")
                : onGlobalFilterChange(e, selectedColumnName)
            }
          />
        </div>
      </div>
    );
  };

  return (
    <span>
      <OverlayPanel ref={op}>{confirmPopData()}</OverlayPanel>
    </span>
  );
};
export default ConfirmationPopUp;
