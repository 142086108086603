import * as types from "./../types/types";
import { DEVURL } from "../../apis/envUrl";
import Api from "../../apis";

export const getDSBPDropdownData =
  (BU, Region, ProjectID) => async (dispatch) => {
    try {
      // const res = await axios.get(`${DEVURL}/fetchDSBPID/${BU}/${Region}/${ProjectID}`);
      let headers = {
        "Content-Type": "application/json",
        "Content-Language": "en-US",
        "Access-Control-Allow-Origin": "*",
      };
      const api = new Api();
      const axiosInstance = await api.init({ headers });

      const res = await axiosInstance({
        url: `${DEVURL}/fetchDSBPID/${BU}/${Region}/${ProjectID}`,
        method: "GET",
      });

      if (res?.data === null) {
        dispatch({
          type: types.GET_ALL_DSBP_DATA,
          payload: "No records found",
        });
      } else {
        if (res.status === 200) {
          console.log("res.data", res.data);
          dispatch({
            type: types.GET_ALL_DSBP_DATA,
            payload: res.data,
          });
        } else {
          dispatch({
            type: types.GET_ALL_DSBP_DATA_ERROR,
            payload: "Data not found",
          });
        }
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: types.GET_ALL_DSBP_DATA_ERROR, payload: err });
    }
  };
