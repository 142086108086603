import moment from "moment";
import "moment-business-days";

export const getDaysLeft = (startDate, duration) => {

  if(!startDate || !duration){
    return "N/A";
  }
  // Update Moment.js locale settings
  moment.updateLocale("us", {
    workingWeekdays: [1, 2, 3, 4, 5], // Monday to Friday are considered working days
    nextBusinessDayLimit: 31, // Limit for calculating business days into the future
  });
 
  // calulate from midnight
  const today = moment().utc().startOf("day");
 
  // Calculate the end date by adding duration days to startDate
  let endDateMoment = moment(startDate, "YYYYMMDDTHHmmss.SSS [GMT]")
    .businessAdd(duration, "days")
    .startOf("day");
  // Calculate business days difference between currentDate and endDateMoment
  const diff = endDateMoment.businessDiff(today, true);

  return diff;
};
