// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cfv-dialog {
  width: 50%;
  height: auto;
}
.cfv-dialog .pg-viewer-wrapper {
  overflow-y: hidden;
}
.cfv-dialog .pg-viewer-wrapper .photo-viewer-container {
  width: auto !important;
  height: auto !important;
  display: contents;
}
.cfv-dialog .pg-viewer-wrapper .photo-viewer-container img {
  width: auto !important;
  height: auto !important;
}
.cfv-dialog .pg-viewer-wrapper .react-grid-Grid {
  min-height: 520px !important;
}
.cfv-dialog .pg-viewer-wrapper .react-grid-Grid .react-grid-Canvas {
  width: auto !important;
  height: auto !important;
}
.cfv-dialog .pg-viewer-wrapper .document-container {
  padding: 15px;
  width: auto;
}
.cfv-dialog .p-dialog-content {
  width: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/common/CustomFileViewer/index.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,YAAA;AACJ;AACI;EACI,kBAAA;AACR;AACQ;EACI,sBAAA;EACA,uBAAA;EACA,iBAAA;AACZ;AACY;EACI,sBAAA;EACA,uBAAA;AAChB;AAGQ;EACI,4BAAA;AADZ;AAGY;EACI,sBAAA;EACA,uBAAA;AADhB;AAKQ;EACI,aAAA;EACA,WAAA;AAHZ;AAMI;EACI,WAAA;AAJR","sourcesContent":[".cfv-dialog{\n    width: 50%;\n    height: auto;\n\n    .pg-viewer-wrapper {\n        overflow-y: hidden;\n\n        .photo-viewer-container{\n            width: auto !important;\n            height: auto !important;\n            display: contents;\n\n            img{\n                width: auto !important;\n                height: auto !important;\n            }\n        }\n\n        .react-grid-Grid {\n            min-height: 520px !important;\n\n            .react-grid-Canvas {\n                width: auto !important;\n                height: auto !important;\n            }\n        }\n\n        .document-container {\n            padding: 15px;\n            width: auto;\n        }\n    }\n    .p-dialog-content{\n        width: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
