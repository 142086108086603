import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
 
const CommonAutocomplete = ({ suggestions, value, onChange, onSelect, placeholder, field, disabled, columnName, className }) => {
  const [inputValue, setInputValue] = useState(value);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
 
  useEffect(() => {
    setInputValue(value);
  }, [value]);
 
  const completeMethod = (e) => {
    const inputValue = e.query;
    const filtered = suggestions?.filter((item) =>
      item[field]?.toLowerCase().includes(inputValue?.toLowerCase())
    );
    if(filtered !== null && filtered !== undefined && filtered !== "")
      setFilteredSuggestions(filtered);
  };
 
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    if(columnName === 'Role' ||  columnName === 'Owner'){
      if (inputValue && filteredSuggestions.every((item) => item[field]?.toLowerCase() !== inputValue.toLowerCase())) {
        onChange({ target: { value: '' } });
      }
      else{
        onChange(e)
      }
    }
    else{
      onChange(e)
    }
  };
 
  return (
    <AutoComplete
      value={inputValue}
      suggestions={filteredSuggestions}
      completeMethod={completeMethod}
      field={field}
      onSelect={onSelect}
      onChange={handleInputChange}
      placeholder={placeholder}
      dropdown
      forceSelection
      disabled={disabled}
      className={className}
    />
  );
};
 
export default CommonAutocomplete;