import {
  userUpdateAction,
  userProfileAction,
} from "../store/actions/userActions.js";
import { store } from "../store/store";
import { RoleUser } from "../userRole";
import Api from "./index.js";
import ssoReducer from './../store/reducers/SSOReducer';

export const updateUser = async (username, password) => {
  const userInformation = getUserInformation(username, password);
  // debugger;
  if (userInformation && Object.keys(userInformation).length > 0) {
    store.dispatch(userUpdateAction(userInformation));
  } else {
    store.dispatch(userUpdateAction({}))
  }
  return userInformation;
};
export const updateUserProfile = async (roles, BusinessUnit, Regions) => {
  const userInformation = getUserProfile(roles, BusinessUnit, Regions);
  if (userInformation && Object.keys(userInformation).length > 0) {
    console.log(userInformation, "user");
    store.dispatch(userProfileAction(userInformation));
  }
  return true;
};
//session storage user name.and user id

const getUserInformation = (username, password) => {
  const user = RoleUser?.users?.find(
    (u) => u.username.toLowerCase() === username.toLowerCase()
  );
  if (user && user.password === password) {
    return {
      username: user.username,
      userid: user.userid,
      lastName: user.lastName,
      region: user.region,
      role: user.role,
      loginTime: new Date().toUTCString(),
    };
  } else {
    return {};
  }
};


const getUserProfile = (roles, BusinessUnit, Regions) => {
  if (roles && BusinessUnit && Regions) {
    return {
      role: roles,
      bu: BusinessUnit,
      region: Regions,
    };
  } else {
    return {};
  }
};

export const getUserInfoPegaApi = async (url) => {
  const api = new Api();
  const headers = {
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
  }
  const axiosInstance = await api.init(headers);
  try {
    const response = await axiosInstance({
      url: url,
      method: "GET",
    });
    // Check for successful response status, e.g., 200 OK
    if (response?.status === 200) {
      const loggedInUserInfo = response?.data?.ArtworkAgilityPage;
      store.dispatch(ssoReducer(loggedInUserInfo))
      return loggedInUserInfo;
    }
  } catch (error) {
    console.log("Api Error ", error.message);
  }
};
