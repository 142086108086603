import Api from "../../apis";
import { DEVURL } from "../../apis/envUrl";
//pass the taskID and projectId from the my tasks

function setSelectToFalse(data) {
  if (!data || !data?.ArtworkAgilityTasks || !data?.ArtworkAgilityTasks?.[0]) {
      return data; // Return the original data if structure is invalid
  }

  // Create a deep copy of the data to avoid mutating the original object
  const updatedData = data && JSON.parse(JSON.stringify(data));

  const designJobDetails = updatedData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails;

  if (Array.isArray(designJobDetails)) {
    designJobDetails?.forEach(job => {
        if (job?.Select !== true) {
            job.Select = false;
        }
        if (job?.CD_Approved !== true) {
          job.CD_Approved = false;
        }
        if (job?.Print_Trial_Done !== true) {
          job.Print_Trial_Done = false;
        }
        if (job?.Print_Trial_Needed !== true) {
          job.Print_Trial_Needed = false;
        }
        if (job?.ReworkCounter !== true) {
          job.ReworkCounter = false;
        }
    });
  }

  return updatedData;
}

export const getTaskDetails = (taskID, projectId,userAccessPayLoad) => {
  return async (dispatch) => {
    dispatch({ type: "GET_TASK_DETAILS_REQUEST" });
    try {
      const api = new Api();
      const axiosInstance = await api.init({
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        },
      });
      let apiURL = `${DEVURL}/taskDetails/${taskID}/${projectId}`;
      const TaskDetailsData = await axiosInstance({
        url: apiURL,
        method: "POST",
        data: userAccessPayLoad
      });
      const updatedTaskDetailsData = setSelectToFalse(TaskDetailsData?.data);
      dispatch({
        type: "GET_TASK_DETAILS_SUCCESS",
        // payload: TaskDetailsData?.data,
        payload:updatedTaskDetailsData,
      });
      // return TaskDetailsData?.data;
      return updatedTaskDetailsData
    } catch (error) {
      dispatch({ type: "GET_TASK_DETAILS_FAILURE", payload: error });
    }
  };
};

export const submitUploadApproveDesignIntent = async (
  formData,
  id,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;
  //https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v2/assignments/ASSIGN-WORKLIST PG-AAS-WORK U-5708!UPLOADAPPROVEDESIGNINTENT_FLOW/actions/UploadApproveDesignIntent

  apiURL = `${DEVURL}/v2/assignments/ASSIGN-WORKLIST ${id}!UPLOADAPPROVEDESIGNINTENT_FLOW/actions/UploadApproveDesignIntent`;

  const submitUploadApproveDesignIntentData = await axiosInstance({
    url: apiURL,
    method: "PATCH",
    data: formData,
  });
  // if (submitUploadApproveDesignIntentData?.data?.ID) {
  //   App.dispatchToStore(editProjectAction(formData));
  // }
  return submitUploadApproveDesignIntentData;
};

export const submitCPPFA = async (formData, id, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  // let apiURL = `${DEVURL}/preliminaryPrintFeasibilityAssessment/${id}`;
  let apiURL = `${DEVURL}/preliminaryPrintFeasibilityAssessment/${id}`;

  const submitCPPFAData = await axiosInstance({
    url: apiURL,
    method: "PATCH",
    data: formData,
  });
  return submitCPPFAData;
};

export const saveDesignIntent = async (formData, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/v1/TaskDetails/UpdateDesignIntentJob`;
  const designIntent = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });

  console.log("response", designIntent, formData);

  return true;
};
