import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { warningMessage, confirmLeaveMessage } from "../../../constants";
const STORAGE_KEY = "unsavedChanges";


export const UnsavedChangesDialog = ({ visible, onConfirm, onCancel }) => (
  <Dialog
        visible={visible}
        className="acp-dialog acpCloseDialogSubContainer headerIcon"
        onHide={onCancel}
        header={<div className="p-dialog-acp">{warningMessage}</div>}
      >
        <div className="col-sm-12 ">
          <Row className="justify-content-end ">
            <Button   variant="secondary" onClick={onCancel}>
              No
            </Button>

            <Button
              onClick={onConfirm}
              disabled={null}
              className="ms-3"
            >
              Yes
            </Button>
          </Row>
        </div>
      </Dialog>
);

export const useUnsavedChangesWarning = (
  message = confirmLeaveMessage
) => {
  const [isDirty, setIsDirty] = useState(() => {
    // Initialize isDirty state based on local storage
    const storedValue = localStorage.getItem(STORAGE_KEY);
    return storedValue ? JSON.parse(storedValue) : false;
  });

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty, message]);

  const markDirty = () => {
    setIsDirty(true);
    localStorage.setItem(STORAGE_KEY, true);
  };

  const markClean = () => {
    setIsDirty(false);
    localStorage.setItem(STORAGE_KEY, false);
  };

  return { isDirty, markDirty, markClean };
};
