import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Portal from "./portal";
import AppRouter from "./router"
import "./scss/App.scss";
import ErrorBoundary from "./errors/ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <Router basename="/">
        <>
          <Portal />
          <AppRouter />
        </>
      </Router>
    </ErrorBoundary>
  );
}

export default App;