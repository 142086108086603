import React from "react";
import { Button } from "react-bootstrap";
import { clearToken } from "../store/actions/AuthenticationActions";
import { store } from "../store/store";
import { isOAuth2Disabled } from "../Utils/helpers";

export default function LogOut() {
  const handleLogin = () => {
    localStorage.clear()
    store.dispatch(clearToken())

    // Navigating to the Oauth2 after clearing local data
    if (!isOAuth2Disabled()) {
      window.location.href = process.env.REACT_APP_LOGOUT_URL;
    }
  };

  return (
    <div className="content-layout" id="tableDiv" >
      <div className="tabular-view" style={{ textAlign: "center", display: "flex" }}>
        <div className="logout-text">
          <h4>
            OOPS! Your token has expired.
            <br /> <b>Please click Login button for redirecting to the login page</b>
          </h4>
          <Button onClick={handleLogin}>Login Here!</Button>
        </div>
      </div>
    </div>
  );
};