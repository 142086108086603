import moment from "moment";
import "moment-business-days";
import { AcpService } from "../service/ACPService";



const addBusinessDays = (dateStr, duration) => {

  let date = new Date(dateStr);

  let result = new Date(date); // Make a copy of the parsed date
  let daysAdded = 0;

  while (daysAdded < duration) {
      result.setDate(result.getDate() + 1); // Add 1 day to the result date
      // Check if the resulting date is a weekday (Monday to Friday)
      if (result.getDay() !== 0 && result.getDay() !== 6) {
          daysAdded++; // If it's a weekday, increment the daysAdded counter
      }
  }

  result.setMinutes(0);
  result.setHours(0);
  result.setSeconds(0);
  return result; // Return the new date after adding the business days
}

const getConvertedDate = (momentDate) => {
  const date = new Date(momentDate);
  date.setMinutes(0);
  date.setHours(0);
  date.setSeconds(0);

  return date;
}

const getWeekdayDate = (startDate) => {
  const today = startDate ? new Date(startDate) : new Date();

  if (today.getDay() === 6) {
    today.setDate(today.getDate() + 2);
  }

  if (today.getDay() === 0) {
    today.setDate(today.getDate() + 1);
  }
  // Return the adjusted date as a Date object
  return today;
};

export const getDaysLeft = (startDate, duration) => {

  if(!startDate || !duration){
    return "N/A";
  }

  // Update Moment.js locale settings
  moment.updateLocale("us", {
    workingWeekdays: [1, 2, 3, 4, 5], // Monday to Friday are considered working days
    nextBusinessDayLimit: 31, // Limit for calculating business days into the future
  });
 
  // calulate from midnight
  const today = getWeekdayDate(); //moment().utc().startOf("day");
  
  const covertedDate = getConvertedDate(today);
 
  let convertedStartDate = AcpService.convertToIST(startDate)


  let  converttoWeekdayDate = getWeekdayDate(convertedStartDate);

  let newDate = addBusinessDays(converttoWeekdayDate, duration);

  // Calculate business days difference between currentDate and endDateMoment
  const diff = moment(newDate).businessDiff(moment(covertedDate), true);

  return diff;
};
