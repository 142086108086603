import * as types from "../types/types";

export const dropDownValuesAction = (data) => ({
  payload: data,
  type: types.GET_DROPDOWN_VALUES_SUCCESS,
});

// import Api from "../../apis";
// import { DEVURL, SITURL, PRODURL } from "../../apis/envUrl";
//pimport { type } from 'types';


// export const getDropDownValues = () => {
//   alert("inside method")
//   let count;
//   return async (dispatch) => {
//     dispatch({ type: "GET_DROPDOWN_VALUES_REQUEST" });
//     alert("insider try")
//     try {
//       const api = new Api();
//       const axiosInstance = await api.init({
//         headers: {
//           "Content-Type": "application/json",
//           "Access-Control-Allow-Origin": "*",
//           "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
//         },
//       });
//       count=count+1
//       console.log(`getDropDownValues API called ${count} times`);
//       let apiURL = `${DEVURL}/fetchdropdownvalues`;
//       const DropDownValuesData = await axiosInstance({
//         url: apiURL,
//         method: "GET",
//       });
//       dispatch({
//         type: "GET_DROPDOWN_VALUES_SUCCESS",
//         payload: DropDownValuesData?.data,
//       });
//     } catch (error) {
//       dispatch({ type: "GET_DROPDOWN_VALUES_FAILURE", payload: error });
//     }
//   };
// };
