import CustomizeViewDialog from "./CustomizeViewDialog";
import React, { useEffect } from "react";
const CustomizeView = (props) => {
  const {
    IQData,
    RDTData,
    onClose,
    allColumns,
    headerName,
    CDPTPageData,
    showTaskDialog,
    setFrozenColumn,
    availableFields,
    customizeViewFields,
    dependencyMappingData,
    setCustomizeViewFields,
    resetToPgDefault,
    setTableRender,
    tableRender,
    BU,
    region,
    reorderColumnsSetColumnOrder,
    setResetKey,
    pgDefaultColumns
  } = props;
  let sortedAvailableFields = availableFields?.sort(function (a, b) {
    let fieldA = a?.Field_Name?.toUpperCase();
    let fieldB = b?.Field_Name?.toUpperCase();

    if (fieldA < fieldB) {
      return -1;
    }
    if (fieldA > fieldB) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <CustomizeViewDialog
        IQData={IQData}
        onClose={onClose}
        RDTData={RDTData}
        headerName={headerName}
        CDPTPageData={CDPTPageData}
        selectedFields={allColumns}
        showTaskDialog={showTaskDialog}
        setFrozenColumn={setFrozenColumn}
        availableFields={sortedAvailableFields}
        customizeViewFields={customizeViewFields}
        setCustomizeViewFields={setCustomizeViewFields}
        resetToPgDefault={resetToPgDefault}
        setTableRender={setTableRender}
        tableRender={tableRender}
        BU={BU}
        region={region}
        reorderColumnsSetColumnOrder={reorderColumnsSetColumnOrder}
        setResetKey={setResetKey}
        pgDefaultColumns={pgDefaultColumns}
      />
    </>
  );
};

export default CustomizeView;
