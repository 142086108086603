import * as types from "../types/types";
import Api from "../../apis";
import { DEVURL } from "../../apis/envUrl";

export const createNewProjectAction = (data) => ({
  payload: data,
  type: types.CREATE_NEW_PROJECT,
});
export const editProjectAction = (data) => ({
  payload: data,
  type: types.EDIT_PROJECT,
});

export const selectedProject = (data, rootBreadCrumb) => ({
  payload: data,
  type: types.SELECTED_PROJECT,
  rootBreadCrumb: rootBreadCrumb,
});
export const updateMode = (data) => ({
  payload: data,
  type: types.UPDATE_MODE,
});

export const SpecialProjectAction = (data) => ({
  payload: data,
  type: types.SPECIAL_PROJECT,
});


export const getProjectValues = (projectId) => {
  return async (dispatch) => {
    dispatch({ type: "GET_PROJECT_VALUES_REQUEST" });
    try {
      const api = new Api();
      const axiosInstance = await api.init({
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        },
      });
      let apiURL = `${DEVURL}/fetchProjectValues/${projectId}`;
      const projectData = await axiosInstance({
        url: apiURL,
        method: "GET",
      });
      dispatch({
        type: "GET_PROJECT_VALUES_SUCCESS",
        payload: projectData,
      });
    } catch (error) {
      dispatch({ type: "GET_PROJECT_VALUES_FAILURE", payload: error });
    }
  };
};
