import { Loading } from '../../../utils';
import React, { useState, useEffect } from 'react';
import exapandCollapseAll from '../../../assets/images/expandCollapseAll.svg';

const FilesFolderHeader = ({
  header,
  allData,
  filename,
  expandedKeys,
  isTreeTableFlag,
  projectPlanView,
  handleExapandCollapseAllButton,
}) => {
  let modifiedAllData = allData;
  if (isTreeTableFlag) {
    modifiedAllData = allData?.map((obj) => obj.data);
  }
  const [loading, setLoading] = useState(false);
  

  const handleLoading = () => {
    setLoading(true);
  };

  useEffect(() => {
    if (loading) {
      handleExapandCollapseAllButton();
      setLoading(false);
    }
  }, [loading]);
  return (
    <div className="actions" style={{ paddingRight: 42 }}>
      <div className="project-title">{header}</div>

      <div className="action-buttons">
        
        {projectPlanView && (
          <>
            {loading ? (
              <Loading />
            ) : (
              <img
                className={`expand-collapse ${
                  expandedKeys?.length !== 0 ? 'invertedIcon' : 'nonInvertedIcon'
                }`}
                alt=""
                data-pr-tooltip="Expand/Collapse All"
                src={exapandCollapseAll}
                onClick={() => handleLoading()}
              ></img>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FilesFolderHeader;
