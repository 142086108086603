import * as types from "./../types/types";
import { DEVURL } from "../../apis/envUrl";
import _ from "lodash";
import Api from "../../apis";
import { changeDateFormat, sortColumns } from "../../utils";

export const getMyProject = (payload) => async (dispatch) => {
  // let PM = userInformation?.userid;
  // let region = userInformation?.region;
  // let bu = userInformation?.bu;
  dispatch({ type: "GET_PROJECT_DETAILS_REQUEST" });
  try {
    //here need to add url and pass PM name
    // const res = await axios.get(`${DEVURL}/myprojects/${PM}`);
    // const res = await axios.get(`${DEVURL}/myprojects/${PM}/${bu}/${region}`);
    // const res = await axios.get(`https://pegadev.pg.com/prweb/api/ArtworkAgilityFile/v1/myprojects/${PM}/Baby Care/EUROPE`);
    let headers = {
      "Content-Type": "application/json",
      "Content-Language": "en-US",
      "Access-Control-Allow-Origin": "*",
    };
    const api = new Api();
    const axiosInstance = await api.init({ headers });
    let apiURL = `${DEVURL}/myProjects`;
    const res = await axiosInstance({
      url: apiURL,
      method: "POST",
      data: payload,
    });

    if (res?.data === null) {
      dispatch({
        type: types.GET_PROJECT_DETAILS_ERROR,
        payload: "No records found",
      });
    } else {
      if (res.status === 200) {
        res.data.ArtworkAgilityProjects = res.data.ArtworkAgilityProjects?.map((item) => ({
          ...item,
          Estimated_AW_Printer: changeDateFormat(item.Estimated_AW_Printer),
          Estimated_AW_Readiness: changeDateFormat(item.Estimated_AW_Readiness),
          Estimated_SOP: changeDateFormat(item.Estimated_SOP),
          Estimated_SOS: changeDateFormat(item.Estimated_SOS),
        }));
        const orderByData = sortColumns('Buffer_To_Work','asc', res.data.ArtworkAgilityProjects);
        //  _.orderBy(
        //   res.data.ArtworkAgilityProjects,
        //   ["Timestamp"],
        //   ["desc"]
        // );
        dispatch({
          type: types.GET_PROJECT_DETAILS_SUCCESS,
          payload: orderByData,
        });
        dispatch({
          type: types.GET_OWNER_DETAILS_SUCCESS,
          payload: res?.data,
        });
        return orderByData;
      } else {
        dispatch({
          type: types.GET_PROJECT_DETAILS_ERROR,
          payload: "Project not found",
        });
      }
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: types.GET_PROJECT_DETAILS_ERROR, payload: err });
  }
};

export const getAllProject = (payload) => async (dispatch) => {
  // let region = userInformation?.region;
  // let bu = userInformation?.bu;
  dispatch({ type: "GET_ALL_PROJECT_DETAILS_REQUEST" });
  try {
    //here need to add url and pass PM name
    // const res = await axios.get(`${DEVURL}/allprojects/${bu}/${region}`);
    let headers = {
      "Content-Type": "application/json",
      "Content-Language": "en-US",
      "Access-Control-Allow-Origin": "*",
    };
    const api = new Api();
    const axiosInstance = await api.init({ headers });
    let apiURL = `${DEVURL}/allProjects`;
    const res = await axiosInstance({
      url: apiURL,
      method: "POST",
      data: payload,
    });

    if (res?.data === null) {
      dispatch({
        type: types.GET_ALL_PROJECT_DETAILS_ERROR,
        payload: "No records found",
      });
    } else {
      if (res.status === 200) {
        res.data.ArtworkAgilityProjects = res.data.ArtworkAgilityProjects?.map((item) => ({
          ...item,
          Estimated_AW_Printer: changeDateFormat(item.Estimated_AW_Printer),
          Estimated_AW_Readiness: changeDateFormat(item.Estimated_AW_Readiness),
          Estimated_SOP: changeDateFormat(item.Estimated_SOP),
          Estimated_SOS: changeDateFormat(item.Estimated_SOS),
        }));
       const orderByData = sortColumns('Buffer_To_Work','asc', res.data.ArtworkAgilityProjects);
        // _.orderBy(
        //   res.data.ArtworkAgilityProjects,
        //   ["Timestamp"],
        //   ["desc"]
        // );
        dispatch({
          type: types.GET_ALL_PROJECT_DETAILS_SUCCESS,
          payload: orderByData,
        });
        dispatch({
          type: types.GET_OWNER_DETAILS_SUCCESS,
          payload: res?.data,
        });
        return orderByData;
      } else {
        dispatch({
          type: types.GET_ALL_PROJECT_DETAILS_ERROR,
          payload: "Project not found",
        });
      }
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: types.GET_ALL_PROJECT_DETAILS_ERROR, payload: err });
  }
};

// export const updateProject = (data) => async (dispatch) => {
//   try {
//     if (data) {
//       dispatch({
//         type: types.UPDATE_PROJECT,
//         payload: data,
//       });
//     }
//   } catch (err) {
//     dispatch({ type: types.UPDATE_PROJECT_ERROR, payload: err });
//   }
// };
