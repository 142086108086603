import Api from ".";
import { AZUREURL, DEVURL } from "./envUrl";
import { getEnvironmentFromURL } from "../utils";

export const uploadtoAzurefileShare = async (
  file,
  BU,
  subfolder,
  ProjectID,
  DesignJobID,
  Version,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  const env = getEnvironmentFromURL();
  //DEV,QA,SIT env
  const folderStructure = `${env}/${BU}/${subfolder}/${ProjectID?.toUpperCase()}/${DesignJobID}/${Version}`;

  // Determine the appropriate API endpoint based on the file extension
  let apiURL;
  if (file?.name?.endsWith(".zip")) {
    apiURL = `${AZUREURL}/unzipAndUploadFiles?name=${folderStructure}`;
  } else {
    apiURL = `${AZUREURL}/uploadPdf?name=${folderStructure}`;
  }

  try {
    const formData = new FormData();
    formData.append("file", file, file?.name);
    const response = await axiosInstance({
      url: apiURL,
      method: "POST",
      data: formData,
    });

    console.log("File upload response:", response);

    if (response.status === 200) {
      if (file?.name?.endsWith(".zip")) {
        return response?.data;
      } else {
        return true; // Successful upload
      }
    } else {
      console.error("File upload failed with status code:", response.status);
      return false; // Failed upload
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    return false; // Failed upload
  }
};
