export const changehandingHeader = {
  ArtworkAlignment: 'Artwork Scope Alignment',
  DependencyMapping: 'Dependency Mapping',
  DSBP: 'DSBP',
  artworkStatus: 'Artwork Status',
  ReviewChanges: 'Artwork Job : Change Notification',
  COPY: 'COPY',
  CIC: 'CIC',
  Editable: 'EDITABLE',
  FA: 'FA',
  PIMaterialNumber: 'PI Material Number (DSBP)',
  POARta: 'POA (RTA)',
  RTAPOAStatus: 'RTA POA Status',
  InitiativeId: 'Initiative ID (DSBP)',
  POADescription: 'POA Description',
  PIDescriptionDSBP: 'PI Material Description (DSBP)',
  PIDescriptionDSM: 'PI Material Description (DSM)',
  ActiveCopyMatrix: 'Active Copy Matrix',
  RTAArtworkProject: 'RTA Artwork Project',
};
export const Changes = 'Changes';
export const tableHeader = {
  Attributes: 'Attributes',
  NewValue: 'New Value',
  OldValue: ' Old Value',
};
export const FooterContent = (materialNumber) => {
  return `Please confirm you have seen changes and have taken all required actions for PI Material Number (DSBP) -${materialNumber}. After your confirmation, the old values will not be visible anymore.`;
};

export const messages = {
  successMessage : 'POA update request submitted successfully to Enovia',
  acknowledgment: 'POA-A Update Acknowledgment',
  errorMessage : 'POA update request failed. Please try again.'
}
export const confirmHeader = 'Are you sure you want to send POA-A update to RTA?';
export const Confirmupdate = 'CONFIRM and UPDATE ENOVIA RTA';
export const Confirm = 'Confirm';
export const Cancel = 'Back';
export const Yes = 'Yes';
export const changeUpdateTooltip =
  "POA can not be updated ! POA must be in 'Draft' state, or have active 'Approve POA Copy Matrix' task in RTA to execute the change.";
export const changeUpdatePOATooltip ="POA Update is in progress.Awaiting responce from Enovia";

