import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import moment from "moment";
import PageLayout from "../../PageLayout";
import DesignHeader from "../DesignJobs/DesignHeader";
import FooterButtons from "../DesignJobs/FooterButtons";
import UploadDesignIntentProofscope from "../DesignJobs/UploadDesignIntentProofscope";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import { submitUploadProductionReadyArt } from "../../../apis/uploadSubmitAPIs";

import AddNewDesign from "../DesignJobs/TaskHeader";
import { UploadFileToServer } from "../../../store/actions/ProofScopeActions";
import { postSaveDesignIntent } from "../../../apis/uploadSaveAsDraft";
import { CheckReadOnlyAccess, redirectToProjectPlanOrTaskPage } from "../../../utils";
import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus } from "../../../utils";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../common/NavigationPrompt";
const breadcrumb = [
  { label: "My Tasks", url: "/myTasks" },
  { label: "Upload Production Ready Art" },
];

const UPRA = () => {
  const [data, setData] = useState(null);
  const [designIntent, setDesignIntent] = useState(null);
  const [fileName, setFileName] = useState("");
  const [azureFile, setAzureFile] = useState("");
  const [formattedValue, setformattedValue] = useState(0);
  const [date, setDate] = useState("");
  const [version, setVersion] = useState("V0");
  const location = useLocation();
  const currentUrl = location.pathname;
  const [azureFileUploadMsg, setAzureFileUploadMsg] = useState(false);

  const { TaskDetailsData, loading } = useSelector((state) => state.TaskDetailsReducer);
  let { TaskID, page1, page2, pageType, ProjectID } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Key}`;
  const headerName = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.TaskPlaceholderName}`;
  const shortName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Type;
  const roleName = "DI_";
  // const checkReadWriteAccess = CheckReadOnlyAccess();
  const checkReadWriteAccess = true;

  const [pageEdited, setPageEdited] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { markDirty, markClean } = useUnsavedChangesWarning();

  useEffect(() => {
    if (pageEdited) {
      markDirty();
    } else {
      markClean();
    }
  }, [pageEdited]);

  useEffect(()=>{
    markClean();
    setPageEdited (false);
  },[]);

  useEffect(() => {
    dispatch(getTaskDetails(TaskID, ProjectID));
    setAzureFileUploadMsg(false);
  }, [dispatch, TaskID, ProjectID]);

  useEffect(() => {
    if (TaskDetailsData) {
      setDesignIntent(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || []);
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);
      const data =
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0] ||
        [];

      const specialProjectFlag = TaskDetailsData?.SpecialProject;
      dispatch(SpecialProjectAction(specialProjectFlag));
      redirectUnallocatedLinksToArtworkStatus(currentUrl, specialProjectFlag, navigate);
      if (data) {
        data.Version !== "" && setVersion(data.Version);
        data.Timestamp !== "" &&
          setDate(moment(data.Timestamp, "YYYYMMDD[T]HHmmss.SSS [GMT]").format("DD-MMMM-YYYY"));
      }
    }
  }, [TaskDetailsData]);

  const filePath = "cloudflow://PP_FILE_STORE/aacdata/" + fileName;

  const onSaveAsDraft = async () => {
    const fileSize = Math.round(formattedValue / 1000000);
    const formData = {
      AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
      AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      Size: fileSize === 0 ? "1" : fileSize,
      Version: version.substring(0, 1) + (parseInt(version.substring(1)) + 1),
      Filename: fileName,
    };

    console.log("azure file details", azureFile, fileName, filePath);
    let res = await dispatch(UploadFileToServer(azureFile, filePath));
    await postSaveDesignIntent(formData);
  };

  const handleCancel = () => {
    redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
  };

  const handleCancelClick = () =>{
    if(pageEdited){
      setShowDialog(true);
    }else{
      handleCancel();
    }
  }

  // it will hide warning dialog
  const cancelNavigation = () => {
    setShowDialog(false);
  }
  // it will navigate to previously saved url navigate(pendingNavigation);
  // by doing setShowDialog(false) and setPendingNavigation("");
  const confirmNavigation = () => {
    markClean();
    handleCancel();
    setPageEdited(false);
    setShowDialog(false);
  }

  const onSubmit = async () => {
    const fileSize = Math.round(formattedValue / 1000000);
    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };
    console.log("azureFile", azureFile);
    const formData = {
      caseTypeID: "PG-AAS-Work-UploadProductionReadyArt",
      content: {
        AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      },
      pageInstructions: [
        {
          instruction: "APPEND",
          target: "PRAUploadList",
          content: {
            Action: "add",
            Filename: fileName,
            Size: fileSize === 0 ? "1" : fileSize,
            Version: version.substring(0, 1) + (parseInt(version.substring(1)) + 1),
          },
        },
      ],
    };
    await dispatch(UploadFileToServer(azureFile, filePath));
    await submitUploadProductionReadyArt(formData, id, headers);
    redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
  };

  return (
    <PageLayout>
      <DesignHeader
        breadcrumb={breadcrumb}
        headerName={headerName}
        disabled={true}
        label={shortName}
        lastLabel={"UPRA"}
        pageEdited={pageEdited}
        setPageEdited={setPageEdited}
        checkReadWriteAccess={checkReadWriteAccess}
        actionButtonsFlag={true}
        {...data}
      />
      <div className="task-details">
        {<AddNewDesign {...data} TaskDetailsData={TaskDetailsData} pageEdited={pageEdited} setPageEdited={setPageEdited} />}
        <LoadingOverlay active={loading} spinner text="">
          {designIntent && (
            <UploadDesignIntentProofscope
              {...designIntent}
              upload={true}
              setformattedValue={setformattedValue}
              setAzureFile={setAzureFile}
              setFileName={setFileName}
              item={data}
              pageEdited={pageEdited}
              setPageEdited={setPageEdited}
              roleName={roleName}
              ArtworkAgilityPage={TaskDetailsData?.ArtworkAgilityPage}
              version={version}
              checkReadWriteAccess={checkReadWriteAccess}
              date={date}
              fileName={fileName}
            />
          )}{" "}
        </LoadingOverlay>
      </div>

      <FooterButtons
        onSubmit={onSubmit}
        handleCancel={handleCancelClick}
        onSaveAsDraft={onSaveAsDraft}
        checkReadWriteAccess={checkReadWriteAccess}
        bottomFixed={true}
        {...data}
      />
      {/* UnsavedChangesDialog popup*/}   
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </PageLayout>
  );
};
export default UPRA;
