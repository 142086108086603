import Api from ".";
import { downloadFile } from "../Utils/FileUploadService";
import { sanitizeFileName } from "../utils";
import { AZUREURL } from "./envUrl";

export const downloadAzureContainer = async (
  fileName,
  folderStructure,
  headers = {}
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL;
  apiURL = `${AZUREURL}/downloadFileFromContainerPath?path=/${sanitizeFileName(folderStructure)}&file=${fileName}`;
  try {
    // Make a GET request to download the file
    const response = await axiosInstance({
      url: apiURL,
      method: 'GET',
      responseType: 'blob',
      // Specify the response type as blob for file downloads
    });

    // Create a temporary anchor element to trigger the download
    downloadFile(response.data, fileName)

    return true; // Successful download
  } catch (error) {
    console.error('Error downloading file:', error);
    return false; // Failed download
  }
};
