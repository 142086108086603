import {
  onSortData,
  bufferToWork,
  multiFilterChange,
} from "../../../utils";
import moment from "moment";
import _, { isArray } from "lodash";
import { Tag } from "primereact/tag";
// import { cloneDeep } from "lodash";
import { Column } from "primereact/column";
// import CustomisedView from "./CustomisedView";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import ProjectListHeader from "./ProjectListHeader";
import ProjectNameHeader from "./ProjectNameHeader";
import ProjectListFilter from "../ProjectListFilter";
import LoadingOverlay from "react-loading-overlay-ts";
// import { generateUniqueKey } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { AcpService } from "../../../service/ACPService";
// import CustomizeView from "../MyProjects/CustomizeView";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ProjectService } from "../../../service/PegaService";
import CustomizeView from "../../common/CustomizeView";
//const CustomisedView = React.lazy(() => import("./CustomisedView"));
import { setAlignFrozenColumn } from "../../../Utils/leftAlignFrozenColumn";
import { selectedProject } from "../../../store/actions/ProjectSetupActions";
// import CustomizeViewDialog from "../../DSBP/CustomizeView/CustomizeViewDialog";
import Footer from "../../common/tableFooter/tableFooter";

const ProjectList = (props) => {
  const op = useRef(null);
  const dt = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const filterArray = useRef([]);
  const colAllSelected = useRef([]);
  const currentUrl = location.pathname;
  const selectedFieldsRef = useRef([]);
  const selectedColumnList = useRef([]);
  const [filters, setFilters] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sortData, setSortData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pegadata, setPegaData] = useState(null);
  const [isSearch, isSearchSet] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [isColWidthSet, setColWidth] = useState(null);
  const [frozenCoulmns, setFrozenColumn] = useState([]);
  const [tableRender, setTableRender] = useState(false);
  const [selectedFields, setSelectedFields] = useState({});
  const [allColumnNames, setAllColumnNames] = useState([]);
  const [ProjectFrozen, setProjectFrozen] = useState(false);
  const [filterCleared, setFilterCleared] = useState(false);
  const [initialPegaData, setInitialPegaData] = useState(null);
  const [ProjectData, setProjectData] = useState(0);
  //let columnWidth = localStorage.getItem("columnWidthMyProject");
  const [isReorderedColumn, setReorderedColumn] = useState(false);
  const [projectColumnName, setProjectColumnNames] = useState([]);
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const [customizeViewFields, setCustomizeViewFields] = useState([]);
  const [updatedAllColumnNames, setUpdatedAllColumnNames] = useState([]);
  const [initialColumnLenght, setinitialColumnLenght] = useState({});
  const StorageData = localStorage.getItem("customizeViewFieldsMyProjects");
  const customizeViewFieldsStorage = StorageData && JSON.parse(StorageData);
  const [customViewIsAddedMyProject, isCustomViewIsAddedMyProject] = useState(null);
  const isFilterEnabled = frozenCoulmns?.length || filters?.length || sortData?.length || isSearch || customViewIsAddedMyProject;
  const isResetEnabled =
    isReorderedColumn || isFilterEnabled || isColWidthSet || customViewIsAddedMyProject;

  const myProjectList = useSelector((state) => state.myProject);

  // const jsonColumnWidth = JSON.parse(columnWidth);
  const searchHeader = projectColumnName.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: { value: null, matchMode: FilterMatchMode.CONTAINS },
    }),
    {}
  );
  const { loading } = myProjectList;

  useEffect(() => {
    const ProjectData = _.cloneDeep(myProjectList.myProject);
    let allCol = [];
    if (ProjectData?.length) {
      allCol = Object.keys(ProjectData[0]);
      allCol.push("Artwork_Status");
    }
    let columnWidthMyProject = {};
    if (allCol.length) {
      allCol.forEach((column) => {
        columnWidthMyProject[column] = 100;
      });
    }

    let getJsonStoredWidthColumns = localStorage.getItem("columnWidthMyProject");
    let getStoredWidthColumns = JSON.parse(getJsonStoredWidthColumns);
    const checkEmptyObject = _.isEmpty(getStoredWidthColumns);

    if (checkEmptyObject) {
      localStorage.setItem("columnWidthMyProject", JSON.stringify(columnWidthMyProject));
    }

    let jsonColWidth = localStorage.getItem("isColWidthSetMyProject");
    let isColWidthSetFlag = JSON.parse(jsonColWidth);
    if (isColWidthSetFlag) {
      setColWidth(true);
    }


    let availableFields;
    const selectedFeilds = ProjectService.getMyProjectData();
    availableFields = allColumnNames?.filter(function (objFromA) {
      return !selectedFeilds.find(function (objFromB) {
        return objFromA === objFromB;
      });
    });
    setinitialColumnLenght({ availableFields: availableFields.length, freezedColumns: 0, selectedFields: selectedFeilds.length })
  }, []);

  useEffect(() => {
    getCustomizedView();
  }, [allColumnNames]);

  useEffect(() => {
    setLoader(true);
    (async () => {
      try {
        // below is json call
        // const ProjectData = await ProjectService.getProjectData();

        //below is api call
        const ProjectData = _.cloneDeep(myProjectList.myProject);
        if (ProjectData && ProjectData.length) {
          ProjectData.filter((field) => {
            if (field.Artwork_Category) {
              let categoryNames = field?.Artwork_Category?.map((item) => item.Category_Name).join(
                ", "
              );
              field.Artwork_Category = categoryNames;
            }

            if (field.Artwork_SMO) {
              let SMOName = field?.Artwork_SMO?.map((item) => item.SMO_Name).join(", ");
              field.Artwork_SMO = SMOName;
            }

            if (field.Artwork_Brand) {
              let brandName = field?.Artwork_Brand?.map((item) => item.Brand_Name).join(", ");
              field.Artwork_Brand = brandName;
            }

            return field;
          });
        }

        if (ProjectData?.length) {
          let allCol = Object.keys(ProjectData[0]);
          allCol.push("Artwork_Status");
          setAllColumnNames(reorderColumns(allCol));
        }

        let filteredPegaDataJson = localStorage.getItem("columnWiseFilterData");
        const filteredPegaData = JSON.parse(filteredPegaDataJson);

        if (filteredPegaData && filteredPegaData.length) {
          setFilters(filteredPegaData);
          setSelectedFields(filteredPegaData);
          setPegaData(ProjectData);
        } else setPegaData(ProjectData);
        if (!initialPegaData) {
          setInitialPegaData(ProjectData);
        }

        // according to pathname we need to call api and store column name in local storage
        let columnNamesJson = localStorage.getItem("allColumnNames");
        let columnNames = JSON.parse(columnNamesJson);
        if (columnNames && columnNames?.length) {
          columnNames = AcpService?.removeCommonValues(columnNames, frozenCoulmns);
          columnNames = [...frozenCoulmns, ...columnNames];
          setProjectColumnNames(columnNames);
          setUpdatedAllColumnNames(columnNames);
        } else {
          let columnNames = ProjectService.getMyProjectData();
          columnNames = AcpService?.removeCommonValues(columnNames, frozenCoulmns);
          columnNames = [...frozenCoulmns, ...columnNames];
          setUpdatedAllColumnNames(columnNames);
          setProjectColumnNames(columnNames);
          localStorage.setItem("allColumnNames", JSON.stringify(columnNames));
        }

        // get sort data from local storage and add in state
        let jsonSortingData1 = localStorage.getItem("sortingData");
        const sortingData = JSON.parse(jsonSortingData1);

        if (sortingData && sortingData.length) {
          const sortedData = onSortData(sortingData[0], sortingData[1], ProjectData);
          setPegaData(sortedData);
          setSortData([sortingData[0], sortingData[1]]);
        }
      } catch (err) {
        console.log("error", err);
      }
    })();
    setLoader(false);
  }, [myProjectList.myProject]);

  const onSearchClick = () => {
    isSearchSet(!isSearch);
  };

  const addFrozenColumns = (name) => {
    if (!frozenCoulmns.includes(name)) {
      frozenCoulmns.push(name);
    } else {
      let columnIndex = frozenCoulmns.indexOf(name);
      frozenCoulmns.splice(columnIndex, 1);
    }
    setFrozenColumn(frozenCoulmns);
    const arr = getCustomizedView(true);
    setCustomizeViewFields(arr);
    localStorage.setItem("customizeViewFieldsMyProjects", JSON.stringify(arr));
  };

  const reorderColumns = (columns) => {
    const requiredColumnOrderArray = [
      "Project_ID",
      "Project_Name",
      "Initiative_Group_Name",
      "Project_Description",
      "BU",
      "Artwork_Category",
      "Artwork_Brand",
      "Project_region",
      "Artwork_SMO",
      "Cluster",
      "Project_Scale",
      "Project_State",
      "Buffer_To_Work",
      "Estimated_No_Of_DI",
      "Estimated_No_Of_DT",
      "Estimated_No_Of_NPF",
      "Estimated_No_Of_IQ",
      "Estimated_No_Of_PRA",
      "Estimated_No_Of_CICs",
      "Estimated_No_Of_POAs",
      "Estimated_SOS",
      "Estimated_SOP",
      "Estimated_AW_Printer",
      "Estimated_AW_Readiness",
      "IL",
      "Comments",
      "Project_Type",
      "Production_Strategy",
      "Tier",
      "Company",
      "Artwork_Status",
    ];

    let reorderedColumns = [];
    requiredColumnOrderArray.forEach((rcl) => {
      columns.forEach((cl) => {
        if (rcl === cl) {
          reorderedColumns.push(cl);
        }
      });
    });
    return reorderedColumns;
  };

  // const resetToPgDefault = async () => {
  //   const allColumnNames = [
  //     "Project_ID",
  //     "Project_Name",
  //     "Artwork_Category",
  //     "Artwork_SMO",
  //     "Project_State",
  //     "Artwork_Brand",
  //     "Buffer_To_Work",
  //     "Estimated_AW_Printer",
  //     "Artwork_Status",
  //   ];

  //   localStorage.setItem("allColumnNames", JSON.stringify(allColumnNames));
  //   localStorage.setItem("allColumnNames", JSON.stringify({}));
  //   localStorage.removeItem("isCustomViewIsAddedMyProject");
  //   setProjectColumnNames(allColumnNames);
  //   isCustomViewIsAddedMyProject(true);
  //   setVisible(false);
  // };

  const projectNameOnClick = (e, options) => {
    op.current.toggle(e);
    setSelectedColumnName(options);
  };

  const onSort = (column, direction) => (event) => {
    localStorage.setItem("sortingData", JSON.stringify([column, direction]));
    const sortedData = onSortData(column, direction, pegadata);
    setSortData([column, direction]);
    setPegaData(sortedData);
  };

  const fullKitReadinessBody = (options, rowData) => {
    let field = rowData.field;
    let projectId = options["Project_ID"];
    return (
      <>
        {field === "Project_ID" && (
          <span
            style={{ color: "#003DA5", cursor: "pointer" }}
            onClick={() => {
              if (field && field.length) {
                let option =
                  isArray(myProjectList.myProject) &&
                  myProjectList.myProject.find((project) => project.Project_ID === projectId);
                dispatch(selectedProject(option, "My Projects"));
                if (option.Action === "Draft") {
                  navigate(`${currentUrl}/projectSetup/${projectId}`);
                } else {
                  navigate(`${currentUrl}/projectPlan/${projectId}`);
                }
              }
            }}
          >
            {" "}
            {options[field]}{" "}
          </span>
        )}

        {field === "Project_Name" && (
          <span
            title={options[field]}
            style={{ color: "#003DA5", cursor: "pointer" }}
            onClick={() => {
              if (field && field.length) {
                let option =
                  isArray(myProjectList.myProject) &&
                  myProjectList.myProject.find((project) => project.Project_ID === projectId);
                dispatch(selectedProject(option, "My Projects"));
                if (option.Action === "Draft") {
                  navigate(`${currentUrl}/projectSetup/${projectId}`);
                } else {
                  navigate(`${currentUrl}/projectPlan/${projectId}`);
                }
              }
            }}
          >
            {options[field]}
          </span>
        )}

        {field === "Project State" && (
          <Tag
            value=""
            style={{
              color: "#003DA5",
              border: "1px solid",
              backgroundColor: "#DFEBFF",
            }}
          >
            Active
          </Tag>
        )}

        {field === "Artwork_Status" && (
          <Tag
            value="view"
            style={{
              color: "gray",
              margin: "2px 0",
              cursor: "pointer",
              border: "1px solid",
              backgroundColor: "white",
              padding: "0.15rem 0.4rem",
            }}
            onClick={() => navigate(`${currentUrl}/artworkStatus/${projectId}`)}
          ></Tag>
        )}

        {field === "Estimated_SOP" && (
          <span title={options[field]}>{options[field]}</span>
        )}
        {field === "Estimated_AW_Printer" && (
          <span title={options[field]}>{options[field]}</span>
        )}
        {field === "Buffer_To_Work" && options[field] !== "" && bufferToWork(options[field])}

        {field === "Estimated_AW_Readiness" && (
          <span title={options[field]}>{options[field]}</span>
        )}
        {field === "Estimated_SOS" && (
          <span title={options[field]}>{options[field]}</span>
        )}

        {field !== "Estimated_AW_Readiness" &&
          field !== "Estimated_AW_Printer" &&
          field !== "Artwork_Status" &&
          field !== "Buffer_To_Work" &&
          field !== "Estimated_SOP" &&
          field !== "Estimated_SOS" &&
          field !== "Project_Name" &&
          field !== "Project_ID" && (
            <>
              {" "}
              <span title={options[field]}>{options[field]}</span>
            </>
          )}
      </>
    );
  };

  // const saveAsPersonaliDefault = (selectedCategories) => {
  //   const columnNames = JSON.stringify(reorderColumns(selectedCategories));
  //   localStorage.setItem("allColumnNames", columnNames);
  //   if (frozenCoulmns.length > 0) {
  //     const columnNames = reorderColumns(selectedCategories);
  //     setAlignFrozenColumn(
  //       frozenCoulmns,
  //       setReorderedColumn,
  //       setProjectColumnNames,
  //       setTableRender,
  //       op,
  //       columnNames
  //     );
  //   } else {
  //     setProjectColumnNames(reorderColumns(selectedCategories));
  //   }
  //   if (selectedCategories.length > 9) {
  //     localStorage.setItem("isCustomViewIsAddedMyProject", JSON.stringify(true));
  //     isCustomViewIsAddedMyProject(true);
  //   }
  //   if (selectedCategories.length === 0) {
  //     isCustomViewIsAddedMyProject(true);
  //   }
  //   setVisible(false);
  // };

  const getUpdatedAllColumnFormat = (column) => {
    const finalData = column.map((el) => {
      return {
        width: 250,
        Sequence: "",
        freeze: false,
        Field_Name: el,
        reorder: false,
        Column_Name: el,
        sortAtoZ: false,
        sortZtoA: false,
        Attribute_Type: "Free Text",
      };
    });
    return finalData;
  };

  const onGlobalFilterChange = (e, colName) => {
    const { value, multiplesFlag, artworkCategories } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      pegadata,
      filterArray,
      selectedColumnList,
      colAllSelected,
      selectedFieldsRef
    );
    if (filterArray.current.length) {
      if (value.length > 0 && !multiplesFlag) {
        let filterProjectState = selectedColumnList.current.filter((item) => {
          if (item) {
            // Check if the selected values include the item directly (for text values)
            const hasValue = artworkCategories.includes(item[colName]);

            // If it's not a direct match for text, format the date and check for matches
            if (!hasValue) {
              const formattedDate = moment(item[colName], "YYYYMMDDTHHmmss.SSS [GMT]").format(
                "DD-MMM-YY"
              );
              return artworkCategories.includes(formattedDate);
            }

            return hasValue;
          }
          return false;
        });
        selectedColumnList.current = filterProjectState;
        setFilters(filterProjectState);
      } else {
        for (let k = 0; k < colAllSelected.current.length; k++) {
          let filterProjectState = selectedColumnList.current.filter((item) => {
            if (item && item[colAllSelected.current[k]]) {
              // Check if the selected values include the item directly (for text values)
              const hasValue = selectedFieldsRef.current[0][colAllSelected.current[k]]?.includes(
                item[colAllSelected.current[k]]
              );

              // If it's not a direct match for text, format the date and check for matches
              if (!hasValue) {
                const formattedDate = moment(
                  item[colAllSelected.current[k]],
                  "YYYYMMDDTHHmmss.SSS [GMT]"
                ).format("DD-MMM-YY");
                return selectedFieldsRef.current[0][colAllSelected.current[k]]?.includes(
                  formattedDate
                );
              }

              return hasValue;
            }
            return false;
          });
          selectedColumnList.current = filterProjectState;
          //setFilters(updatedFilters); //filterProjectState
          setFilters(filterProjectState);
        }
      }
    } else {
      selectedColumnList.current = [];
      setSelectedFields({});
      setFilters([]);
    }
  };


  const formCustomizeObject = (availableFields, freezedColumns, selectedFeilds) => {
    const customizeViewFieldsUpdated = {
      availableFields: {
        id: "availableFields",
        title: "Available Fields",
        fieldsData: availableFields,
      },
      freezedColumns: {
        id: "freezedColumns",
        title: "freezed Columns",
        fieldsData: freezedColumns,
      },
      selectedFields: {
        id: "selectedFields",
        title: "selected Fields",
        fieldsData: selectedFeilds,
      },
    };

    return customizeViewFieldsUpdated
  }


  const setToInitialColumns = () => {
    let availableFields;
    const selectedFeilds = ProjectService.getMyProjectData();
    availableFields = allColumnNames?.filter(function (objFromA) {
      return !selectedFeilds.find(function (objFromB) {
        return objFromA === objFromB;
      });
    });
    const customizeViewFieldsUpdated = formCustomizeObject(availableFields, [], selectedFeilds)
    setCustomizeViewFields(customizeViewFieldsUpdated);
    localStorage.setItem("customizeViewFieldsMyProjects", JSON.stringify(customizeViewFieldsUpdated));
  }

  const getCustomizedView = (isFrozen) => {
    const selectedData = customizeViewFieldsStorage?.selectedFields?.fieldsData || [];
    const freezedData = customizeViewFieldsStorage?.freezedColumns?.fieldsData || [];
    const availableData = customizeViewFieldsStorage?.availableFields?.fieldsData || [];
    let availableFields;
    if (availableData?.length > 0) {
      availableFields = availableData;
    } else {
      let updatedColumnFinal = allColumnNames?.filter(function (objFromA) {
        return !projectColumnName.find(function (objFromB) {
          return objFromA === objFromB;
        });
      });
      const intermidaiteColumn = AcpService?.removeCommonValues(
        updatedColumnFinal,
        freezedData?.length ? freezedData : frozenCoulmns
      );
      const availableColumnToSelect = AcpService?.removeCommonValues(
        intermidaiteColumn,
        selectedData?.length ? selectedData : projectColumnName
      );
      availableFields = availableColumnToSelect?.length
        ? availableColumnToSelect
        : updatedColumnFinal;
    }

    const freezedColumns = isFrozen
      ? frozenCoulmns
      : freezedData?.length
        ? freezedData
        : frozenCoulmns;

    let selectedFields = selectedData?.length ? selectedData : projectColumnName;
    selectedFields = AcpService?.removeCommonValues(selectedFields, freezedColumns);

    const customizeViewFieldsUpdated = formCustomizeObject(availableFields, freezedColumns, selectedFields)

    const column = AcpService?.removeCommonValues(
      selectedFields,
      freezedData?.length ? freezedData : frozenCoulmns
    );
    const projectColumnNamesUpdated = [...freezedColumns, ...column];
    if (!isFrozen) {
      setFrozenColumn(freezedColumns);
      setProjectColumnNames(projectColumnNamesUpdated);
      setCustomizeViewFields(customizeViewFieldsUpdated);
    }
    return customizeViewFieldsUpdated;
  };

  // const exportCSV = (selectionOnly) => {
  //   dt.current.exportCSV({ selectionOnly });
  // };

  const handleCustomizedView = (data) => {
    if (data !== undefined && data?.length !== 0) {
      const parsedData = data?.selectedFields?.fieldsData?.length
        ? data?.selectedFields?.fieldsData
        : data && JSON.parse(data);
      const freezedData = parsedData?.freezedColumns?.fieldsData || [];
      let selectedData = parsedData?.selectedFields?.fieldsData || [];
      localStorage.setItem("customizeViewFieldsMyProjects", JSON.stringify(parsedData));
      selectedData = AcpService?.removeCommonValues(selectedData, frozenCoulmns);
      selectedData = [...freezedData, ...selectedData];
      if (initialColumnLenght?.selectedFields !== selectedData?.length)
        isCustomViewIsAddedMyProject(true)
      setProjectColumnNames(selectedData);
      setCustomizeViewFields(parsedData);
      freezedData?.length > 0 && setFrozenColumn([...freezedData]);
    }
  };

  const storeReorderedColumns = (e) => {
    const dragColumnName = projectColumnName[e?.dragIndex];
    const index = projectColumnName.indexOf(dragColumnName);
    if (index > -1) {
      setReorderedColumn(true);
      projectColumnName.splice(index, 1);
      projectColumnName.splice(e?.dropIndex, 0, dragColumnName);
    }
    localStorage.setItem("allColumnNames", JSON.stringify(projectColumnName));
    setProjectColumnNames(projectColumnName);
    setTableRender(false);
  };

  const onColumnResizeEnd = (event) => {
    let jsonColumnWidthMyProject = localStorage.getItem("columnWidthMyProject");
    let columnWidthMyProject = {};
    if (jsonColumnWidthMyProject) {
      columnWidthMyProject = JSON.parse(jsonColumnWidthMyProject);
    }
    // let saveColumnWidth = {};
    // const updatedColumns = [...projectColumnName];
    // const resizedColumn = updatedColumns.find(
    //   (col) => col === event.column.props.field
    // );
    columnWidthMyProject[event.column.props.field] = event.element.offsetWidth;

    localStorage.setItem("columnWidthMyProject", JSON.stringify(columnWidthMyProject));
    localStorage.setItem("isColWidthSetMyProject", JSON.stringify(true));
    setProjectColumnNames(projectColumnName);
    setFilterCleared(false);
    setTableRender(false);
    setColWidth(true);
    setVisible(false);
  };

  const clearColumnWiseFilter = () => {
    let jsonFrozenItem = localStorage.getItem("frozenData");
    const frozenItem = JSON.parse(jsonFrozenItem);
    if (frozenItem && frozenItem.length && frozenItem.includes(selectedColumnName)) {
      const index = frozenItem.indexOf(selectedColumnName);
      frozenItem.splice(index, 1);
      localStorage.setItem("frozenData", JSON.stringify(frozenItem));
      setFrozenColumn(frozenItem);
    }
    if (frozenCoulmns.includes(selectedColumnName)) {
      const index = frozenCoulmns.indexOf(selectedColumnName);
      frozenCoulmns.splice(index, 1);
      setFrozenColumn(frozenCoulmns);
      setProjectFrozen(!ProjectFrozen);
    }
    let jsonSortItem = localStorage.getItem("sortingData");
    const sortItem = JSON.parse(jsonSortItem);
    if (sortItem && sortItem.length && sortItem[0] === selectedColumnName) {
      localStorage.setItem("sortingData", JSON.stringify([]));
    }
    if (sortData && sortData.length && sortData[0] === selectedColumnName) {
      setPegaData(initialPegaData);
      setSortData([]);
    }
    if (filters && filters.length) {
      localStorage.setItem("columnWiseFilterData", JSON.stringify({}));
      setSelectedFields({});
      setFilters([]);
    }
  };

  const dynamicColumns = () => {
    if (projectColumnName.length) {
      return projectColumnName.map((ele, i) => {
        let jsonColumnWidthMyProject = localStorage.getItem("columnWidthMyProject");
        const columnWidthMyProject = JSON.parse(jsonColumnWidthMyProject);
        let checkWidth = [];
        if (columnWidthMyProject) {
          checkWidth = Object.keys(columnWidthMyProject);
        }

        return (
          <Column
            filter
            key={ele}
            field={ele}
            filterField={ele}
            alignFrozen="left"
            columnKey={ele || i}
            showFilterMenu={false}
            filterPlaceholder={ele}
            body={fullKitReadinessBody}
            frozen={frozenCoulmns.includes(ele)}
            // {... checkWidth.includes(ele) && }
            className={frozenCoulmns.includes(ele) ? "font-bold" : ""}
            header={ProjectNameHeader(ele, frozenCoulmns, sortData, projectNameOnClick)}
            style={{
              width: checkWidth.length && checkWidth.includes(ele) ? columnWidthMyProject[ele] : "",
              textOverflow: "ellipsis",
            }}
          />
        );
      });
    }
  };

  const clearFilters = () => {
    let allCol = [];
    const allColumnNames = [
      "Project_ID",
      "Project_Name",
      "Artwork_Category",
      "Artwork_SMO",
      "Project_State",
      "Artwork_Brand",
      "Buffer_To_Work",
      "Estimated_AW_Printer",
      "Artwork_Status",
    ];
    const ProjectData = _.cloneDeep(myProjectList.myProject);
    if (ProjectData.length) {
      allCol = Object.keys(ProjectData[0]);
      allCol.push("Artwork_Status");
    }
    let columnWidthMyProject = {};
    if (allCol.length) {
      allCol.forEach((column) => {
        columnWidthMyProject[column] = 100;
      });
    }

    localStorage.setItem("columnWidthMyProject", JSON.stringify(columnWidthMyProject));
    localStorage.setItem("customizeViewFieldsMyProjects", JSON.stringify({}));
    localStorage.setItem("allColumnNames", JSON.stringify(allColumnNames));
    localStorage.setItem("columnWiseFilterData", JSON.stringify({}));
    localStorage.setItem("columnWiseFilterData", JSON.stringify({}));
    localStorage.setItem("sortingData", JSON.stringify({}));
    localStorage.setItem("frozenData", JSON.stringify({}));
    localStorage.removeItem("isColWidthSetMyProject");
    setProjectColumnNames(allColumnNames);
    isCustomViewIsAddedMyProject(false);
    setPegaData(initialPegaData);
    setReorderedColumn(false);
    setFilterCleared(true);
    setSelectedFields({});
    setTableRender(true);
    setFrozenColumn([]);
    setColWidth(false);
    isSearchSet(false);
    setVisible(false);
    setSortData([]);
    setFilters([]);
    setToInitialColumns()
  };

  const saveSettings = () => {
    localStorage.setItem("sortingData", JSON.stringify(sortData));
    localStorage.setItem("frozenData", JSON.stringify(frozenCoulmns));
    localStorage.setItem("columnWiseFilterData", JSON.stringify(filters));
    localStorage.setItem("allColumnNames", JSON.stringify(projectColumnName));
  };

  const clearFilter = () => {
    localStorage.setItem("customizeViewFieldsMyProjects", JSON.stringify({}));
    localStorage.setItem("columnWiseFilterData", JSON.stringify({}));
    localStorage.setItem("sortingData", JSON.stringify({}));
    localStorage.setItem("frozenData", JSON.stringify({}));
    setPegaData(initialPegaData);
    setSelectedFields({});
    setFrozenColumn([]);
    isSearchSet(false);
    setSortData([]);
    setFilters([]);
  };
  return (
    <>
      <div className="myProjectAnddAllProjectList">
        {/* <Suspense fallback={ <Loading />}> */}
        <LoadingOverlay active={loader || loading || pegadata === null} spinner text="">
          <>
            {pegadata !== undefined && (
              <ProjectListHeader
                filterFLag={false}
                allData={pegadata}
                header={props.header}
                setVisible={setVisible}
                isTreeTableFlag={false}
                // exportCSV={exportCSV}
                headers={projectColumnName}
                CustomizeViewFlag={false}
                clearFilter={clearFilter}
                ResetToDefaultFlag={false}
                clearFilters={clearFilters}
                saveSettings={saveSettings}
                filename="My_Project_Records"
                onSearchClick={onSearchClick}
                isResetEnabled={isResetEnabled}
                isFilterEnabled={isFilterEnabled}
                selected={filters}
              />
            )}
            <CustomizeView
              availableFields={[]}
              showTaskDialog={visible}
              headerName={"My Projects"}
              onClose={() => setVisible(false)}
              customizeViewFields={customizeViewFields}
              setCustomizeViewFields={handleCustomizedView}
              resetToPgDefault={() => clearFilters()}
              allColumns={getUpdatedAllColumnFormat(allColumnNames)}
            />

            <ProjectListFilter
              op={op}
              onSort={onSort}
              filters={filters}
              sortData={sortData}
              pageName="My Project"
              projectData={pegadata}
              setFilters={setFilters}
              setSortData={setSortData}
              saveSettings={saveSettings}
              ProjectFrozen={ProjectFrozen}
              frozenCoulmns={frozenCoulmns}
              selectedFields={selectedFields}
              setTableRender={setTableRender}
              setFrozenColumn={setFrozenColumn}
              setProjectFrozen={setProjectFrozen}
              addFrozenColumns={addFrozenColumns}
              projectColumnName={projectColumnName}
              setReorderedColumn={setReorderedColumn}
              selectedColumnName={selectedColumnName}
              onGlobalFilterChange={onGlobalFilterChange}
              setAlignFrozenColumn={setAlignFrozenColumn}
              clearColumnWiseFilter={clearColumnWiseFilter}
              setProjectColumnNames={setProjectColumnNames}
            />

            <DataTable
              ref={dt}
              scrollable
              resizableColumns
              className="mt-3"
              reorderableColumns
              dataKey="Project_ID"
              filters={searchHeader}
              responsiveLayout="scroll"
              columnResizeMode="expand"
              filterDisplay={isSearch && "row"}
              onColReorder={storeReorderedColumns}
              onColumnResizeEnd={onColumnResizeEnd}
              tableStyle={{
                minWidth: filterCleared && "100%",
                width: filterCleared && "max-content",
              }}
              // key={generateUniqueKey("Project_ID")}
              onResize={(e) => console.log("resize", e)}
              value={filters.length ? filters : pegadata}
              onResizeCapture={(e) => console.log("e", e)}
              key={tableRender ? `"Project_ID" + timestamp` : ""}
              emptyMessage={
                loader || loading || pegadata === null ? " " : "No records found"
              }
            >
              {dynamicColumns()}
            </DataTable>
          </>
        </LoadingOverlay>
        {/* </Suspense> */}
      </div>

      {
        !loader && pegadata && (
          <Footer className="footer" totalObjects={pegadata?.length} isSelected={false} />
        )
      }
    </>
  );
};
export default ProjectList;