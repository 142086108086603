import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import FileNameAndOptions from "../UploadedFileNameAndOptions/FileNameAndOptions";
import { changeDateFormat } from "../../../utils";
import "./FileList.scss";

const FileList = ({
  groupedFiles,
  latestVersionGroupedFiles,
  //   groupName,
  fileList,
  accept,
  subFolder,
  showVersionPopUp,
  handleUpdate,
  onDelete,
  toShowFileName = true,
  toShowVersion = true,
  toShowOwner = true,
  toShowDate = true,
  toShowDownloadOption = true,
  toShowViewOption = true,
  toShowDeleteOption = true,
  toShowUploadOption = true,
  companyName = "",
}) => {
  return (
    <div className="file-list-table-container">
      <DataTable value={fileList} className="file-list-table">
        {toShowFileName && (
          <Column
            field="File_Name"
            header="File Name"
            headerClassName="file-list-header"
            bodyClassName="file-list-data-row"
            body={(rowData) => {
              return (
                <>
                  <FileNameAndOptions
                    rowData={rowData}
                    onDelete={onDelete}
                    onUpdate={handleUpdate}
                    accept={accept}
                    subFolder={subFolder}
                    toShowDownloadOption={toShowDownloadOption}
                    toShowViewOption={toShowViewOption}
                    toShowDeleteOption={toShowDeleteOption}
                    toShowUploadOption={toShowUploadOption}
                    companyName={companyName}
                  />
                </>
              );
            }}
          ></Column>
        )}
        {toShowVersion && (
          <Column
            field="Version"
            header="Version"
            headerClassName="file-list-header"
            bodyClassName="file-list-data-row text-center"
            alignHeader={"center"}
            body={(rowData, rowOptions) => {
              return (
                <div
                  className={rowData?.FileID ? "version-active" : ""}
                  onClick={() =>
                    rowData?.FileID &&
                    showVersionPopUp(
                      groupedFiles?
                      (subFolder === "CICMATRIX"
                        ? groupedFiles[rowData.ReferenceDocumentType]
                        : rowData.GroupName.includes("RDT Files")
                        ? groupedFiles[rowData.ReferenceDocumentType]
                        : groupedFiles[rowData.GroupName])
                      :rowOptions.rowIndex, rowData, 
                    )
                  }
                >
                  {rowData?.Version}
                </div>
              );
            }}
          ></Column>
        )}
        {toShowOwner && (
          <Column
            header="Owner"
            headerClassName="file-list-header"
            bodyClassName="file-list-data-row text-center"
            body={(rowData) => {
              const { Info, Email, Full_Name } = rowData;
              const ownerName =
                Info?.Full_Name && Info?.Email ? Info.Full_Name : Full_Name ?? "Unknown Owner";
              const ownerEmail =
                Info?.Full_Name && Info?.Email ? Info.Email : Email ?? "Unknown Email";
              return (
                <div>
                  {ownerName}
                  <br />
                  {ownerEmail}
                </div>
              );
            }}
            alignHeader={"center"}
          ></Column>
        )}
        {toShowDate && (
          <Column
            field="Timestamp"
            header="Date"
            headerClassName="file-list-header file-list-header-date"
            bodyClassName="file-list-data-row text-center"
            alignHeader={"center"}
            body={(rowData) => {
              const Timestamp = rowData?.Timestamp;
              const timestamp = Timestamp ?? "Unknown Date";
              return <div>{changeDateFormat(timestamp)}</div>;
            }}
          ></Column>
        )}
      </DataTable>
    </div>
  );
};

export default FileList;
