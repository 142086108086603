import "./index.scss";
import { Records, Selected } from "../../ACP/constant";

const Footer = ({ isSelected, selected = [], totalObjects = 0 }) => {
    return (
        <div className="footer">
            <span>
                {totalObjects} {Records}{" "}
                {isSelected ? (
                    <span>
                        , {selected?.length} {Selected}
                    </span>
                ) : null}
            </span>
        </div>
    );
};

export default Footer;
