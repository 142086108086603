import * as types from "../types/types";

const initialState = {
    userNotificationsDetails: {}
};

const UserNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.USER_NOTIFICATION_DETAILS:
            return {
                ...state,
                userNotificationsDetails: action.payload,
            }
        default:
            return state;
    }
}

export default UserNotificationReducer;