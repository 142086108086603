import { cloneDeep, forEach } from "lodash";
import _ from "lodash";
import reworkTasksCode, { popUpConst, completeReject } from "../constants/reworkPageTasks";

/**
 * @method getFilterOptions
 * @description function to get filteroption for grid.
 * @param {String} options - Filter options provided in the grid.
 * @returns {String}
 */
export const getFilterOptions = (options) => {
  return options === "Dependency"
    ? "Predecessor"
    : options === "TaskDuration"
    ? `Duration (Days)`
    : options === "Buffer_To_Work"
    ? "Buffer:Work (B:W)"
    : options === "Task_ID"
    ? "Task ID"
    : options === "Start_Date"
    ? "Start Date"
    : options === "End_Date"
    ? "End Date"
    : options === "Days_Left"
    ? "Days Left"
    : options === "Remaining_Work"
    ? "Remaining Work"
    : options === "Help_Needed"
    ? "Help Needed"
    : options;
};
/**
 * @method getAllColumnNames
 * @description function to get all column names from alldata.
 * @param {String} alldata - column where filter is selected.
 * @returns {Array}
 */
export const getAllColumnNames = (alldata) => {
  let allCol = [];
  allCol = alldata.length && Object.keys(alldata[0]);
  allCol.push("Assignee");
  return allCol;
};
/**
 * @method filterColumnwiseData
 * @description function to get filtered data for grid.
 * @param {String} col - column where filter is selected.
 * @param {object} filterVals - all options in the grid.
 * @param {groupdata} groupdata - grid data.
 * @returns {Array}
 */
const filterColumnwiseData = (col, filterVals, groupdata) => {
  const filtersRes = groupdata?.map((node) => {
    if (!node.children || node.children.length === 0) {
      const filters = filterVals.some((item) => item === node.data[col]);
      if (filters) return node;
    } else {
      //if node has nested childeren need to work whn have proper dependency
    }
  });
  return _.compact(filtersRes);
};

/**
 * @method fliterGroupData
 * @description function to get filteroption for grid.
 * @param {String} options - Filter options provided in the grid.
 * @returns {Array}
 */
const fliterGroupData = (col, filterVals, alldata) => {
  const fliteredGroupList = [];
  alldata?.map((group) => {
    const hasChildren = group?.children?.length;
    if (!hasChildren) {
      const filter = filterVals.some((item) => item === group?.data[col]);
      filter && fliteredGroupList.push(group);
    } else {
      const filters = filterVals.some((item) => item === group?.data[col]); // if its parent
      if (filters) {
        filters && fliteredGroupList.push(group);
      } else {
        const childrenData = filterColumnwiseData(col, filterVals, group?.children);
        if (childrenData?.length > 0) {
          const resData = { ...group, children: childrenData };
          resData && fliteredGroupList.push(resData);
        }
      }
    }
  });
  return _.compact(fliteredGroupList);
};

/**
 * @method filterData
 * @description function to get filterData for grid.
 * @param {Array} data - provided data in the grid.
 * @param {object} selectedFields - Filter options provided in the grid.
 * @returns {Array}
 */
export const filterData = (data, selectedFields) => {
  let filterDataList = [];
  selectedFields &&
    Object.keys(selectedFields).length && //map through each column
    Object.keys(selectedFields)?.map((item, i) => {
      const filteredDataobj = fliterGroupData(item, selectedFields[item], data);
      filterDataList = [...filterDataList, ...filteredDataobj];
    });
  return combinationFilter(filterDataList);
};

/**
 * @method onGlobalFilterChange
 * @description function to update selected fields for the global filter.
 * @param {Object} event  - global filter event object.
 * @param {string} filterColumn - column name
 * @param {Object} selectedFields - Object of the fields
 * @returns {Object} temporary value and selected values
 */
export const onGlobalFilterChange = (event, filterColumn, selectedFields) => {
  const value = event.value;
  let temp = cloneDeep(selectedFields);
  temp[filterColumn] = value;
  return { temp, value };
};
/**
 * @method removeUndefinedKey
 * @description function is to remove undefined key from the object of key values
 * @param {Object} inputObj  - input object.
 * @returns {Object} returns object after removing undeifend key
 */
const removeUndefinedKey = (inputObj) => {
  const keys = Object.keys(inputObj);
  for (let i = 0; i < keys?.length; i++) {
    if (keys[i] === undefined || keys[i] === "undefined") {
      delete inputObj[keys[i]];
    }
  }
  return inputObj;
};
/**
 * @method getAllChildern
 * @description function to add siblings node for tree.
 * @param {Array} treeData  - tree data.
 * @returns {Array} returns tree data with siblings added.
 */
export const getAllChildern = (treeData, job) => {
  let allJobChildern = [];
  treeData.forEach((item) => {
    if ((job?.key === item?.key || job === item?.key) && item?.children) {
      allJobChildern = item?.children;
    }
  });
  return allJobChildern;
};

/**
 * @method getSelectedValues
 * @description function to get the checkbox checked values for parent child relationship
 * @param {Object} flatDataList  - all data in flat result
 * @param {string} keyvalues - selected values from checkbox
 * @returns {Array} temporary value and selected values
 */
export const getSelectedValues = (flatDataList, selectedValue, allValue, tableData) => {
  let selectedValuesList = [];
  let ChangedCheckboxList = [];
  let dependencyArrayList = [];
  let selectObj = {};
  /*code for current selection start*/
  const seletedNode = flatDataList.filter((item) => {
    if (selectedValue.includes(item.key.toString())) {
      return item;
    }
  });
  ChangedCheckboxList = seletedNode;
  /*code for current selection end*/
  selectedValuesList = ChangedCheckboxList;

  ChangedCheckboxList?.forEach((selectedNode) => {
    // find the dependency for all selected node
    const dependencyArray = findDependencycolList(flatDataList, selectedNode);
    dependencyArrayList = [...dependencyArray];
  });

  selectedValuesList = [...selectedValuesList, ...dependencyArrayList];
  const selectionChangeListRes = _.uniqBy(selectedValuesList, "key");

  /* check if  children are selected, push the parent,  selectionChangeListRes*/
  const selectionChangeListResUpdated = selectParentIfAllchildren(
    selectionChangeListRes,
    tableData
  );

  let checkboxinnerObj = { checked: true, partialChecked: false };
  selectionChangeListResUpdated?.forEach((ele) => {
    selectObj[ele.key] = checkboxinnerObj;
  });
  _.merge(allValue, selectObj);
  const checkedkeys = Object.keys(allValue)?.filter(
    (innerobj) =>
      allValue[innerobj]?.checked === true || allValue[innerobj]?.partialChecked === true
  );
  const allSelectedNodeList = getChangedCheckboxList(flatDataList, checkedkeys); //get all checked values

  let result = {
    selectionChangeList: allSelectedNodeList,
    value: removeUndefinedKey(allValue),
  };
  return result;
};
/**
 * @method selectParentIfAllchildren
 * @description function to check if  childern are selected select the parent
 * @param {Array} allSelctedNode  - all selected node
 * @param {Array} treeData  - tree data
 */
const selectParentIfAllchildren = (allSelctedNode, treeData) => {
  let tempAllSelctedNode = cloneDeep(allSelctedNode);
  let parentArry = [];
  //get all the parents in selected nodes
  tempAllSelctedNode?.forEach((ele) => {
    if (parentArry?.indexOf(ele?.parent) == -1) {
      parentArry.push(ele?.parent);
      treeData?.filter((item) => {
        item?.key === ele?.parent && tempAllSelctedNode.push(item);
      });
    }
  });
  return tempAllSelctedNode;
};
/**
 * @method onSelectColumn
 * @description function to highlight the checked rows for parent child relationship
 * @param {Object} selectedNodes  - collection of selected rows
 * @param {Object} rowData - contains all the row Data
 * @returns {String} className for selected rows
 */
export const onSelectColumn = (selectedNodes, rowData) => {
  const selectedNode = selectedNodes.find((ele) => ele?.Task_ID === rowData?.code);
  if (rowData.children && rowData.children.length > 0) {
    const allChildrenSelected = rowData.children.every((child) => {
      return selectedNodes.some((node) => node?.Task_ID === child.code);
    });
    if (allChildrenSelected) {
      return "selected-row-class";
    }
  }
  return selectedNode ? "selected-row-class" : null;
};
/**
 * @method checkIfChildrenSelected
 * @description function is to check if any one of the children is checked parent should be in selected list
 * @param {Array} deSelectionList  - all the deselected values
 * @param {Array} selectedNodes  - all selected values
 * @param {Array} treeData - tree data to find parent child relationship
 * @returns {Array} modified array
 */
const checkIfChildrenSelected = (deSelectionList, selectedNodes, treeData) => {
  const selectedKey = selectedNodes?.map((ele) => ele.key);
  //if any one of the children is checked parent should be removed from deselectedList
  deSelectionList?.forEach((item) => {
    if (!item?.Task_ID) {
      const children = getAllChildern(treeData, item);
      const check = children.some((child) => selectedKey.includes(child.key));
      if (check) {
        const objWithIdIndex = deSelectionList.findIndex((obj) => obj.key === item.key);
        deSelectionList.splice(objWithIdIndex, 1);
      }
    }
  });
  //if none of the children is checked parent should be added in deselectedList
  selectedNodes?.forEach((item) => {
    if (!item?.Task_ID) {
      const children = getAllChildern(treeData, item);
      const check = children.some((child) => selectedKey.includes(child.key));
      if (!check) {
        deSelectionList.push(item);
      }
    }
  });
  return deSelectionList;
};
/**
 * @method getdeSelectedValues
 * @description function to get the checkbox checked values for parent child relationship
 * @param {Object} flatDataList  - all data in flat result
 * @param {string} keyvalues - selected values from checkbox
 * @returns {Array} temporary value and selected values
 */
export const getdeSelectedValues = (
  flatDataList,
  unselectedKey,
  selectedKeyNode,
  selectedNodes,
  treeData
) => {
  let selectedValuesList = [];
  let ChangedCheckboxList = [];

  const deSelenode = flatDataList.filter((item) => {
    if (
      unselectedKey.includes(item.key.toString()) &&
      stringFormat(item?.State) !== completeReject
    ) {
      return item;
    }
  });
  ChangedCheckboxList = deSelenode.filter((item) => {
    return item?.Task_ID && stringFormat(item?.State) !== completeReject;
  });

  selectedValuesList = deSelenode;

  const dependencyArray = findDependencycolList(flatDataList, ChangedCheckboxList); // find the dependency
  selectedValuesList = [...selectedValuesList, ...dependencyArray];
  const selectionChangeListRes = _.uniqBy(selectedValuesList, "key");

  const updatedSelectedNodeBefore = _.isEqual(selectionChangeListRes, selectedNodes)
    ? []
    : getDiffrencyArray(selectedNodes, selectionChangeListRes);

  // if unselected value has JOB and its children are selected make job selected AWM-1561
  const selectionChangeListFinal = checkIfChildrenSelected(
    selectionChangeListRes,
    updatedSelectedNodeBefore,
    treeData
  );

  const updatedSelectedNodeAfter = _.isEqual(selectionChangeListFinal, selectedNodes)
    ? []
    : getDiffrencyArray(selectedNodes, selectionChangeListRes);

  const removeNodes = selectionChangeListFinal?.map((ele) => ele.key);
  removeNodes.forEach((ele) => {
    delete selectedKeyNode[ele];
  });
  let result = {
    allDeselectionList: selectionChangeListFinal,
    value: selectedKeyNode,
    updatedSelectedNode: updatedSelectedNodeAfter,
  };
  return result;
};

/**
 * @method getDiffrencyArray
 * @description function to get the diffrence from array1
 * @param {Array} array1 - array for comparision
 * @param {Array} array2 - array for comparision
 * @returns {Array} diffrence array from 1st
 */
export const getDiffrencyArray = (array1, array2) => {
  const filteredArray1 = array1.filter((obj1) => !array2.some((obj2) => obj1.key === obj2.key));
  return filteredArray1;
};
/**
 * @method getChangedCheckboxList
 * @description function to get all the checked values nodes
 * @param {Array} flatDataList - array of all data
 * @param {Array} keyValues - array of key values
 * @returns {Array} all the checked values nodes
 */
const getChangedCheckboxList = (flatDataList, keyValues) => {
  let ChangedCheckboxList = [];
  keyValues.forEach(function (keyval) {
    const filteredAry = flatDataList.filter((item) => {
      if (item.key.toString() === keyval) return item;
    });
    filteredAry?.length > 0 && ChangedCheckboxList.push(filteredAry[0]);
  });
  return ChangedCheckboxList;
};
/**
 * @method findDependencycolList
 * @description function to find the dependency array of checked nodes
 * @param {Array} flatDataList - array of all data
 * @param {Array} selectedValuesList - checked nodes
 * @returns {Array} dependency array of checked nodes
 */
const findDependencycolList = (flatDataList, selectedValue) => {
  let dependencyArray = [];
  //selectedValue[0] is sent in the case of deselection,selectedValue-selection
  const selectedTaskID = selectedValue?.length > 0 ? selectedValue[0] : selectedValue;
  const groupedObj = groupByKey(flatDataList, "Dependency");
  if (groupedObj !== undefined) {
    const dependencyArrayRec = [];
    dependencycolRecurvise(selectedTaskID, groupedObj, dependencyArrayRec);
    dependencyArray = _.uniqBy(dependencyArrayRec, "key");
  }
  return dependencyArray;
};
/**
 * @method dependencycolRecurvise
 * @description function to find all the nested level dependency
 * @param {Array} groupedObjVal - array grouped by dependency
 * @param {Array} selectedVal - checked nodes
 * @param {Array} dependencyArrayRec - reference array to be updated with dependency
 * @returns {Array} dependency array of checked nodes
 */
const dependencycolRecurvise = (selectedVal, groupedObjVal, dependencyArrayRec) => {
  dependencyArrayRec.push(selectedVal);
  let dependencyArray = [];
  let flag = true;
  const keyValueList = getGroupedKeyList(groupedObjVal);
  // if slectedval is not in groupedobj then stop
  if (!keyValueList.includes(selectedVal?.Task_ID)) {
    flag = false;
  }
  if (flag) {
    for (let key in groupedObjVal) {
      if (key.includes(selectedVal?.Task_ID)) {
        dependencyArray = [...dependencyArray, ...groupedObjVal[key]];
      }
    }
    dependencyArray.forEach((element) => {
      dependencycolRecurvise(element, groupedObjVal, dependencyArrayRec);
    });
  }
};
/**
 * @method getGroupedKeyList
 * @description function to find all keys of dependency
 * @param {Array} groupedObjInput - array grouped by dependency
 * @returns {Array} all keys of dependency
 */
const getGroupedKeyList = (groupedObjInput) => {
  const allKeys = Object.keys(groupedObjInput);
  let KeyList = [];
  allKeys.forEach((ele) => {
    const keyObj = ele?.split(",");
    KeyList = [...KeyList, ...keyObj];
  });
  return KeyList;
};
/**
 * @method combinationFilter
 * @description function to union of all the unique data  based on columns filter
 * @param {Array} allfilteredData  - all filter data from all columns
 * @returns {Array} temporary value and selected values
 */

export const combinationFilter = (allfilteredData) => {
  let allfilteredDataList = [];
  for (const object of allfilteredData) {
    const existingObject = allfilteredDataList.find((o) => o?.code === object?.code);
    if (existingObject) {
      const array1 = existingObject?.children;
      const array2 = object?.children;
      const mergedArray = array1.concat(
        array2.filter((item) => array1.find((i) => i.key === item.key) === undefined)
      );
      allfilteredDataList.filter((node) => {
        if (node.group === object.group) node.children = mergedArray;
      });
    } else {
      allfilteredDataList.push(object);
    }
  }

  return allfilteredDataList;
};
/**
 * @method clearAllFilterValues
 * @description function to clear All Filter Values
 * @param {Array} allColumns  - all columns data
 * @returns {Array} altered array
 */

export const clearAllFilterValues = (allColumns) => {
  allColumns?.map((ele) => {
    let width =
      ele["field_Name"] === "Task"
        ? 400
        : ele.field_Name === "State" ||
          ele.field_Name === "End_Date" ||
          ele.field_Name === "Days_Left"
        ? 100
        : ele.field_Name === "TaskDuration"
        ? 120
        : 140;
    if (ele) {
      ele["freeze"] = false;
      ele["width"] = width;
      ele["reorder"] = false;
    }
  });
  return allColumns;
};
/**
 * @method expandAll
 * @description expand the tree structure table data.
 * @param {Array} nodes  - Array of  grid data.
 * @param {Object} _expandedKeys - keys of the tree table data
 * @returns {Object} object contains keys
 */
export const expandAll = (nodes, _expandedKeys) => {
  nodes.forEach((node) => {
    _expandedKeys[node.key] = true;
    if (node.children && node.children.length > 0) {
      expandAll(node.children, _expandedKeys);
    }
  });
  return _expandedKeys;
};
export const stringFormat = (str) => {
  return str?.replace(/\s+/g, "")?.toLowerCase();
};
export const removeDuplicateKeys = (obj) => {
  const uniqueKeys = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      uniqueKeys[key] = obj[key];
    }
  }

  return uniqueKeys;
};
/**
 * @method getFilteredByTaskCode
 * @description filtered based on tasktype and status.
 * @param {Array} apiData  - Array of all data.
 * @param {Array} _expandedKeys - filtered array
 */
const getFilteredByTaskCode = (apiData) => {
  const reworkTasksCodeList = reworkTasksCode?.map((item) => {
    return stringFormat(item);
  });
  // lists of tasks or job which has to be shown in rework screen
  const filteredAry = apiData.filter((item) => {
    if (reworkTasksCodeList.includes(stringFormat(item?.code))) return item;
  });
  return filteredAry;
};
/**
 * @method getformattedResponse
 * @description group the data by design job ID.
 * @param {Array} apiData  - Array of all data.
 * @returns {Array} returns formatted data
 */

export const getformattedResponse = (apiData) => {
  // const updatedApiData = apiData?.map((item) => {
  //   const formattedItem = { ...item };
  //   if (item.RoleOptions && item.RoleOptions.length === 1) {
  //     // If RoleOptions has only one option, set the "Role" field to its name
  //     formattedItem.Role = item.RoleOptions[0].Name;
  //   }
  //   // Check if OwnerOptionsNew has exactly one item
  //   if (
  //     item.RoleOptions &&
  //     item.RoleOptions.length === 1 &&
  //     item.RoleOptions[0].OwnerOptionsNew &&
  //     item.RoleOptions[0].OwnerOptionsNew.length === 1
  //   ) {
  //     formattedItem.Owner = item.RoleOptions[0].OwnerOptionsNew[0].Name;
  //   }
  //   return formattedItem;
  // });
  const filteredByTaskCode = getFilteredByTaskCode(apiData);
  const mainTempArr = getStructuredArray(filteredByTaskCode);
  const groups = groupBy(mainTempArr, "Design_Job_ID");
  const treeData = [];
  const emptyDependency = groups[""];
  emptyDependency?.forEach((item) => {
    treeData.push(item);
  });
  Object.keys(groups)?.forEach((item, index) => {
    if (item) {
      const taskname = apiData.filter((ele) => {
        if (ele.Design_Job_ID === item.toString()) return ele.Design_Job_Name;
      });
      const designJobname = taskname[0]?.Design_Job_Name || "";
      const Obj = {};
      Obj.children = sequenceSort(groups[item]);
      Obj.code = item;
      Obj.data = { Task: `${item + "_" + designJobname}`, DesignJobID: item };
      Obj.key = item + "_" + index;
      Obj.redirect = true;
      treeData.push(Obj);
    }
  });
  return treeData;
};
/**
 * @method getStructuredArray
 * @description create a Structured Array
 * @param {Array} apiData  - Array of all data.
 * @returns {Array} returns StructuredArray
 */
const getStructuredArray = (apiData) => {
  let mainTempArr = [];
  for (let i = 0; i < apiData?.length; i++) {
    let tempObj = {};
    tempObj["key"] = i;
    tempObj["code"] = apiData[i].AWM_Task_ID;
    tempObj["Sequence"] = apiData[i].Sequence;
    tempObj["State"] = apiData[i].State;
    let dataObj = {};
    dataObj["Task"] = apiData[i]?.IsDefined ? apiData[i].Task_Name : apiData[i].Task_Type;
    dataObj["Sequence"] = apiData[i].Sequence;
    dataObj["Task_ID"] = apiData[i].AWM_Task_ID;
    dataObj["Task_Type"] = apiData[i].Task_Type;
    dataObj["Dependency"] = apiData[i].Dependency;
    dataObj["Role"] = apiData[i].Role;
    dataObj["Assignee"] = apiData[i].Assignee;
    dataObj["State"] = apiData[i].State;
    dataObj["TaskDuration"] = apiData[i].TaskDuration;
    dataObj["Rework"] = apiData[i].ReworkCounter;
    dataObj["GroupName"] = apiData[i].GroupName;
    dataObj["Design_Job_ID"] = apiData[i].Design_Job_ID;
    dataObj["code"] = apiData[i].code;
    dataObj["Task ID"] =
      apiData[i]?.Project_TaskID !== "" ? apiData[i]?.Project_TaskID : apiData[i].AWM_Task_ID;
    dataObj["Predecessor"] =
      apiData[i]?.Project_PredecessorID !== ""
        ? apiData[i]?.Project_PredecessorID
        : apiData[i].Dependency;
    tempObj["data"] = dataObj;
    tempObj["children"] = [];
    tempObj["redirect"] = true;
    tempObj["MarkupList"] = apiData[i].MarkupList;
    tempObj["Task_Type"] = apiData[i].Task_Type;
    tempObj["AWM_Task_ID"] = apiData[i].AWM_Task_ID;

    mainTempArr.push(tempObj);
  }
  return mainTempArr;
};
/**
 * @method sequenceSort
 * @description function to sort the items based on sequence
 * @param {Array} items  - Array of data
 * @returns {Array} returns array of sorted data
 */
const sequenceSort = (items) => {
  return items.sort((a, b) => Number(a.Sequence) - Number(b.Sequence));
};

/**
 * @method groupBy
 * @description common function to return grouped values based on key.
 * @param {Array} xs  - Array to be grouped.
 * @param {string} key  - key based on which grouping has to be done
 * @returns {Array} returns grouped array
 */
var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x.data[key]] = rv[x.data[key]] || []).push(x);
    return rv;
  }, {});
};

/**
 * @method getDDoptions
 * @description to get the dropdown options.
 * @param {Array} options  - row data .
 * @param {string} field  - field value
 * @returns {Array} returns  Array of drown down options
 */
export const getDDoptions = (field, optionsData, RolesOwners) => {
  let ddOptions;
  if (field === "Role") {
    // ddOptions = options?.data["RoleOptions"]?.map((ele) => {
    //   return ele.Name;
    // });

    const roleList = RolesOwners
      ? RolesOwners?.filter((el) => el.TaskID == optionsData?.Sequence)?.[0]?.RolesList
      : [];
    ddOptions = roleList?.map((ele) => {
      return ele.Role;
    });
  } else if (field === "Owner") {
    // const slectedrole = options?.data["Role"];
    // const ownerOpts = options?.data["RoleOptions"]?.filter((ele) => {
    //   if (ele.Name === slectedrole) return ele;
    // });
    // ddOptions =
    //   ownerOpts?.length &&
    //   ownerOpts[0].OwnerOptionsNew?.map((ele) => {
    //     return ele.Name;
    //   });
    const ownerList =
      RolesOwners &&
      RolesOwners?.filter((el) => el.TaskID == optionsData?.Sequence)?.[0]?.["RolesList"]?.find(
        (obj) => optionsData?.data?.["Role"] == obj?.Role
      )?.OwnerList;

    ddOptions =
      ownerList?.length &&
      ownerList?.map((ele) => {
        return ele.Person;
      });
  }
  return ddOptions?.length ? ddOptions : null;
};

/**
 * @method autoVal
 * @description to get value of dropdown.
 * @param {Array} options  - row data .
 * @param {string} field  - field value
 * @returns {string} returns selected value
 */
export const autoVal = (field, options) => {
  let val;
  if (field === "Role") {
    val = options?.data["Role"];
  } else if (field === "Owner") {
    val = options?.data["Assignee"];
  }
  return val;
};

/**
 * @method durationEnabled
 * @description state to enable duartion
 * @param {string} State  - current state
 * @returns {string} returns boolen for duration check
 */
export const durationEnabled = (State, code) => {
  if (State === "Awaiting" || stringFormat(code) === popUpConst.inputRework) {
    return true;
  } else {
    return false;
  }
};

/**
 * @method getDropdownChangedValues
 * @description to get the updated values based on dropdown change
 * @param {object} rowData  - rowData object
 * @param {string} value  - value of current dropdown
 * @param {string} ele  - current element value
 * @param {string} tableData  - tree table data
 * @returns {Array} returns boolen for duration check
 */
export const getDropdownChangedValues = (rowData, value, ele, tableData) => {
  if (typeof value === "string") {
    if (value.length > 2) {
      if (ele === "Role") {
        if (rowData.data["Role"] !== value) {
          rowData.data["Assignee"] = "";
        }
      }
      if (ele === "Owner") {
        rowData.data["Assignee"] = value;
      } else {
        rowData.data[ele] = value;
      }
      return updateTreeData(tableData, rowData, ele, value);
    }
  } else {
    if (ele === "Role") {
      if (rowData.data["Role"] !== value?.Name) {
        rowData.data["Assignee"] = "";
      }
    }
    if (ele === "Owner") {
      rowData.data["Assignee"] = value?.Name;
    } else {
      rowData.data[ele] = value?.Name;
    }
    return updateTreeData(tableData, rowData, ele, value);
  }
};

/**
 * @method updateTreeData
 * @description update the tree data with updated DD values
 * @param {object} rowData  - rowData object
 * @param {string} value  - value of current dropdown
 * @param {string} ele  - current element value
 * @param {string} tableData  - tree table data
 * @returns {Array} returns array of updated treedata
 */
const updateTreeData = (tableData, rowData, ele, value) => {
  return tableData?.map((obj) => {
    obj?.children?.map((data) => {
      if (data.code === rowData.code) {
        return {
          ...data,
          data: {
            ...data.data,
            [ele === "Owner" ? "Assignee" : ele]: value?.Name,
          },
        };
      }
      return data;
    });
    return obj;
  });
};
/**
 * @method formDataReworkAPI
 * @description form the data for submit rework
 * @param {object} selectedNodes  - array of selected Nodes
 * @param {ProjectID} value  - current project ID
 * @returns {Array} returns array formatted rework API data
 */
export const formDataReworkAPI = (selectedNodes, ProjectID, reworkTableData, reasonRework) => {
  let reWorkList = [];
  let requestDataObj = {
    AWMProjectID: ProjectID,
    ReworkPage: reWorkList,
    ReasonForReworkList: reasonRework?.length > 0 ? reasonRework : [],
  };
  selectedNodes?.forEach((item) => {
    const selectedrole = getItemsReducer(item, reworkTableData, "Role");
    const selectedAssignee = getItemsReducer(item, reworkTableData, "Assignee");
    const taskDuration = getItemsReducer(item, reworkTableData, "TaskDuration");
    let innerObj = {
      AWM_Task_ID: item.Task_ID,
      Role: selectedrole.length ? selectedrole[0] : "",
      Assignee: selectedAssignee.length ? selectedAssignee[0] : "",
      Status: item.State,
      ReworkCounter: item.Rework,
      Duration: taskDuration.length ? taskDuration[0] : 0,
    };
    reWorkList.push(innerObj);
  });
  return requestDataObj;
};

/**
 * @method getInputNeeded
 * @description form the data for submit rework
 * @param {Array} MarkupList  - array of markup list
 * @returns {String} returns string value of yes and no
 */
const getInputNeeded = (MarkupList) => {
  let inputNeededList = [];
  let inputNeedVal;
  MarkupList?.forEach((obj) => {
    inputNeededList.push(obj.inputNeeded?.toLowerCase());
  });
  inputNeededList = _.compact(inputNeededList);
  inputNeedVal = inputNeededList.some((ele) => ele === "yes") ? "Yes" : "No";
  return inputNeedVal;
};

/**
 * @method getItemsReducer
 * @description get the selected data from the reworkTableData reducer
 * @param {object} selectedTask  - array of selected Nodes
 * @param {Array} reworkTableData  - current project ID
 * @param {string} col  - column to return data
 * @returns {Array} returns of data from reducer
 */
const getItemsReducer = (selectedTask, reworkTableData, col) => {
  let columnData = [];
  reworkTableData?.forEach((item) => {
    if (item?.children?.length) {
      item?.children?.filter((innerobj) => {
        if (selectedTask?.Task_ID === innerobj?.data?.Task_ID) columnData.push(innerobj?.data[col]);
      });
    } else {
      if (selectedTask?.Task_ID === item?.data?.Task_ID) columnData.push(item?.data[col]);
    }
  });
  return columnData;
};

/**
 * @method groupByKey
 * @description common function to return grouped values based on key.
 * @param {Array} arr  - Array to be grouped.
 * @param {string} property  - key based on which grouping has to be done
 * @returns {Array} returns grouped array by dependency
 */
function groupByKey(arr, property) {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
}
/*
 * @method createBreadCrumbs
 * @description get the breadcrumb for the reworktask and rework page
 * @param {string} element  - array of selected Nodes
 * @param {String} currentUrl  - current project ID
 * @param {string} ProjectID  - column to return data
 * @param {string} id  - column to return data
 * @returns {string} returns string
 */
export const createBreadCrumbs = (element, currentUrl = "", ProjectID, id) => {
  switch (element) {
    case "allProjects":
      return (currentUrl = `${currentUrl}/${element}`);
    case "myProjects":
      return (currentUrl = `${currentUrl}/${element}`);
    case "projectPlan":
      return (currentUrl = `${currentUrl}/${element}/${ProjectID}`);
    case "rework":
      return (currentUrl = `${currentUrl}/${element}`);
    case "task":
      return (currentUrl = `${currentUrl}/${element}/${id}`);
    default:
      currentUrl = `${currentUrl}/${element}`;
  }
  return currentUrl;
};

/**
 * @method conditionForDisabling
 * @description function to check constion for disabling the checkbox.
 * @param {Object} innerobj  - object to be checked.
 * @returns {Boolean} returns true or false
 */
const conditionForDisabling = (innerobj) => {
  if (
    innerobj?.State === "Awaiting" ||
    stringFormat(innerobj?.Task_Type) === popUpConst.inputRework
  )
    return true;
  else return false;
};

/**
 * @method addDisableByState
 * @description function to add selectable property to treedata.
 * @param {Array} treeData  - complete treedata.
 * @returns {Array} returns treedata after adding property.
 */
export const addDisableByState = (treeData) => {
  treeData?.forEach((item) => {
    let selectablenode = false;
    if (item?.children?.length > 0) {
      item?.children?.forEach((innerobj) => {
        if (conditionForDisabling(innerobj)) {
          innerobj["selectable"] = false;
        }
        innerobj.data["parent"] = item.key;
      });
      selectablenode = item?.children?.every((ele) => ele.selectable === false);
      if (selectablenode) {
        item["selectable"] = false;
      }
    } else if (conditionForDisabling(item) || selectablenode) {
      item["selectable"] = false;
    }
  });
  return treeData;
};

/**
 * @method filterDataForSelection
 * @description function to consider for selection and deselection.
 * @param {Array} flatdata  - complete data.
 * @returns {Array} returns data which satisfy the condition.
 */
export const filterDataForSelection = (flatdata) => {
  let filteredList = [];
  flatdata.forEach((item) => {
    if (!conditionForDisabling(item)) filteredList.push(item);
  });
  return filteredList;
};

/**
 * @method getInitialStateValue
 * @description function to get getInitialStateValue for reason for rework.
 * @param {Array} flatdata  - complete data.
 * @returns {Array} returns data which satisfy the condition.
 */
export const getInitialStateValue = (
  selectedCICjobs,
  defectType,
  proofscopeRejection,
  artworkRole
) => {
  let reasonForReworkList = [];
  selectedCICjobs?.forEach((item) => {
    const innerObj = {};
    innerObj["Design_Job_ID"] = item?.DesignJobID;
    innerObj["InputNeeded"] = "No"; //initial Input rework needed shd be No
    innerObj["MarkupList"] = [
      {
        marukUp: 1,
        ReasonForRework:
          proofscopeRejection?.length > 0 ? proofscopeRejection[0]["RejectionReason"] : "",
        DefectType: defectType?.length > 0 ? defectType[0]["DefectType"] : "",
        ResponsibleFunctionRole: artworkRole?.length > 0 ? artworkRole[0]["Role_Name"] : "",
        Comments: "",
      },
    ];
    reasonForReworkList.push(innerObj);
  });
  return reasonForReworkList;
};

export const deleteMarkUp = (allValueList, selectedDesignJob, markupID) => {
  let updatedMarkupList = [];
  allValueList?.forEach((item) => {
    if (item.Design_Job_ID === selectedDesignJob) {
      updatedMarkupList = [...item.MarkupList];
      const objWithIdIndex = updatedMarkupList?.findIndex((obj) => obj.marukUp === markupID);
      updatedMarkupList.splice(objWithIdIndex, 1);
    }
  });
  return updatedMarkupList;
};

export const addNewInitialValues = (
  allValueList,
  selectedDesignJob,
  defectType,
  proofscopeRejection,
  artworkRole
) => {
  let updatedMarkupList = [];
  const selectedJob = allValueList?.filter((ele) => ele?.Design_Job_ID === selectedDesignJob);
  const innerObj = {
    marukUp: selectedJob[0]?.MarkupList?.length + 1,
    ReasonForRework: artworkRole?.length > 0 ? artworkRole[0]["Role_Name"] : "",
    DefectType: defectType?.length > 0 ? defectType[0]["DefectType"] : "",
    ResponsibleFunctionRole:
      proofscopeRejection?.length > 0 ? proofscopeRejection[0]["RejectionReason"] : "",
    Comments: "",
  };

  allValueList?.forEach((item) => {
    if (item?.Design_Job_ID === selectedDesignJob) {
      updatedMarkupList = [...item.MarkupList];
    }
  });
  updatedMarkupList.push(innerObj);
  return updatedMarkupList;
};

export const updateMarkUp = (allValueList, selectedDesignJob, markupID, field, value) => {
  let updatedMarkupList = [];
  allValueList?.forEach((item) => {
    if (item.Design_Job_ID === selectedDesignJob) {
      updatedMarkupList = [...item.MarkupList];
      updatedMarkupList?.forEach((ele) => {
        if (ele.marukUp === markupID) {
          ele[field] = value;
        }
      });
    }
  });
  return updatedMarkupList;
};
export const getUpdatedAllColumnFormat = (column) => {
  const finalData = column?.map((el) => {
    return {
      width: el.width,
      Sequence: "",
      freeze: el.freeze,
      Field_Name: el.field_Name,
      reorder: false,
      Column_Name: el.field_Name,
      sortAtoZ: false,
      sortZtoA: false,
      Attribute_Type: "Free Text",
    };
  });
  return finalData;
};
