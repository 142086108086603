import * as types from "../types/types";

const initialState = {
    userDetails: {},
    selectedBU : [],
    selectedDropdownValue: {}
};

const UserDetailsReducer = (state = initialState, action) => {
    // debugger;
    switch (action.type) {
        case types.GET_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload,
            }
        case types.UPDATE_SELECTED_USER_BU:
            return {
              ...state,
              selectedBU: action.payload.bu,
              selectedDropdownValue: action.payload.dropdownValue
            }
        default:
            return state;
    }
}

export default UserDetailsReducer;