import React, { useEffect, useState } from "react";
import FileUploadManager from "../../common/FileUploadManager/FileUploadManager";
import { updateLongTermFile } from "../../../apis/acpApi";
import AcpCloseDialog from "../common/AcpCloseDialog";
import LoadingOverlay from "react-loading-overlay-ts";
import { defaultAllowedFileTypeAcp, fileTypeErrorMessageAcp } from "../../../constants/FileUploadConstant";
import { createPayload, xlToJSONConverter } from "../../../service/DashboardService";
import { AcpService } from "../../../service/ACPService";
import { useSelector } from "react-redux";

function UploadLongTermFile({
  onFileClear,
  importData,
  setImportData,
  fileReadStatus,
  setFileReadStatus,
  setImportStatus,
  dashboardPMTableDataRefresh,
  setShowAcpBookingDialog,
}) {
  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const [resData, setResData] = useState([]);
  const [popupCloseMessageText, setPopupCloseMessageText] = useState();
  const [loader, setLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const { DropDownValuesData } = useSelector((state) => state.DropDownValuesReducer);

  const [projectSetupPageDropDownValues, setProjectSetupPageDropDownValues] = useState([]);
  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);
  const [smos, setSmos] = useState([]);
  const [scales, setScales] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [projectTypeList, setProjectTypeList] = useState([]);
  const [pageEdited, setPageEdited] = useState(false);
 
  useEffect(() => {
    if (DropDownValuesData) {
      setProjectSetupPageDropDownValues(DropDownValuesData?.ArtWorkProjectSetupPage || []);
    }
  }, [DropDownValuesData]);
  
  useEffect(() => {
    if (
      projectSetupPageDropDownValues !== undefined &&
      projectSetupPageDropDownValues.length !== 0
    ) {
      setBrands(projectSetupPageDropDownValues?.Artwork_Brand);
      setSmos(projectSetupPageDropDownValues?.Artwork_SMO);
      setScales(projectSetupPageDropDownValues.Artwork_Scale);
      setSupplierList(projectSetupPageDropDownValues.Artwork_Supplier);
      setProjectTypeList(projectSetupPageDropDownValues.Artwork_ProjectType);
      const _category=[]
      for(let item of projectSetupPageDropDownValues?.Artwork_BU){
          let pickLists=item?.Artwork_Picklist?.find(ele=>ele?.Picklist_Name==="SAPCategory")?.Labels;
         if(Array.isArray(pickLists) && pickLists?.length>0){
          pickLists=pickLists.map(ele=>({
            code:ele?.code,
            Category_Name:ele?.Label_Name
          }))
          _category.push(pickLists);
         }
      }
      setCategory(_category.flat())
    }
  }, [projectSetupPageDropDownValues]);
  
  const onFileUpload = () => {
    setImportStatus("upload");
  };
  const handleFileUpdate = async () => {
    if (importData?.length === 0) return false;
    setLoader(true);
    const payload = createPayload(importData,smos,brands,category,scales,projectTypeList,supplierList);
    const res = await updateLongTermFile(payload);
    if (res.status === 200) {
      setResData(res?.data?.ArtworkAgilityProjects);
      setLoader(false);
      setShowCloseDialog(true);
      setPopupCloseMessageText("Data Updated Successfully");
    } else {
      setLoader(false);
      setShowCloseDialog(true);
      setPopupCloseMessageText("Error: File was not uploaded. Please try again");
    }
    setImportData([]);
  };

  const updatedData = resData?.map(excelData => ({
    ...excelData,
    "Artwork_Category":excelData?.Artwork_Category?JSON.stringify(excelData?.Artwork_Category):'',
    "Artwork_SMO":excelData?.Artwork_SMO?JSON.stringify(excelData?.Artwork_SMO):'',
    "Artwork_Brand":excelData?.Artwork_Brand?JSON.stringify(excelData?.Artwork_Brand):'',
    "Project_Start_Date": AcpService.convertToShortDateFormat(excelData["Project_Start_Date"], "DD-MM-YYYY"),
    "Estimated_SOP": AcpService.convertToShortDateFormat(excelData["Estimated_SOP"], "DD-MM-YYYY"),
    "Estimated_SOS": AcpService.convertToShortDateFormat(excelData["Estimated_SOS"],"DD-MM-YYYY"),
    "Estimated_AW_Printer": AcpService.convertToShortDateFormat(excelData["Estimated_AW_Printer"],"DD-MM-YYYY"),
    "Estimated_AW_Readiness": AcpService.convertToShortDateFormat(excelData["Estimated_AW_Readiness"],"DD-MM-YYYY")
  }));

  useEffect(() => {
    if (importData.length > 0 && !fileReadStatus) {
      handleFileUpdate();
    }
  }, [importData, fileReadStatus]);

  useEffect(() => {
    async function checkFileReadStatus() {
      if (fileReadStatus && selectedFile) {
         xlToJSONConverter(selectedFile, (data) => {
          if (data?.results) {
            setImportData(data?.results);
          }
          setFileReadStatus(false);
        });
      }
    }
    checkFileReadStatus();
  }, [fileReadStatus, selectedFile]);

  return (
    <>
      {!showCloseDialog ? (
        <LoadingOverlay active={loader} spinner text="">
          <FileUploadManager
            taskStatus="In-Progress"
            checkReadWriteAccess={true}
            onFileUpload={onFileUpload}
            toShowVersion={false}
            pageEdited={pageEdited}
            setPageEdited={setPageEdited}
            toShowViewOption={false}
            onFileClear={onFileClear}
            isAcp={true}
            fileReadStatus={fileReadStatus}
            setFileReadStatus={setFileReadStatus}
            setImportData={setImportData}
            setSelectedFile={setSelectedFile}
            acceptType={defaultAllowedFileTypeAcp}
            errorType={fileTypeErrorMessageAcp}
          />
        </LoadingOverlay>
      ) : (
        <AcpCloseDialog
          setShowCloseDialog={setShowCloseDialog}
          setImportStatus={setImportStatus}
          popupCloseMessageText={popupCloseMessageText}
          showCloseDialog={showCloseDialog}
          isAcp={true}
          setShowAcpBookingDialog={setShowAcpBookingDialog}
          data={updatedData?.length > 0 ? updatedData: null}
          dashboardPMTableDataRefresh={dashboardPMTableDataRefresh}
          headerClassName={resData?.length > 0 ? "p-dialog-acp" : "p-dialog-acp-error"}
        />
      )}
    </>
  );
}

export default UploadLongTermFile;
