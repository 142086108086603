export const RoleUser = {
  users: [
    {
      username: "malashree",
      lastName: "Koley",
      password: "mala123",
      userid: "koley.mk",
      role: "TaskOwner",
      bu: "FAB",
      region: "EUROPE",
      permissions: ["create", "read", "update", "delete"],
    },
    {
      username: "pusan",
      lastName: "Chatterjee",
      password: "pusan123",
      userid: "chatterjee.pc.2",
      role: "CapacityManager",
      bu: "FAB",
      region: "EUROPE",
      permissions: ["create", "read", "update", "delete"],
    },
    {
      username: "dutta",
      lastName: "Dutta",
      password: "dutta123",
      userid: "dutta.rd.2",
      role: "CapacityManager",
      bu: "FAB",
      region: "EUROPE",
      permissions: ["create", "read", "update", "delete"],
    },
    {
      username: "Izabela",
      lastName: "Urban",
      password: "iza123",
      userid: "urban.ik",
      role: "ExternalTaskOwner",
      bu: "FAM",
      region: "EUROPE",
      permissions: ["create", "read", "update", "delete"],
    },
    {
      username: "Karol",
      lastName: "Panasewicz",
      password: "karol123",
      userid: "panasewicz.k",
      role: "TaskOwner",
      bu: "FAB",
      region: "EUROPE",
      permissions: ["read", "view"],
    },
    {
      username: "Luca",
      lastName: "Luca",
      password: "pg123",
      userid: "@luca123",
      role: "ProjectManager",
      bu: "FAB",
      region: "EUROPE",
      permissions: ["read", "view"],
    },
    {
      username: "Cherry",
      lastName: "Cherry",
      password: "cheryy123",
      userid: "@cherry123",
      role: "TaskOwner",
      bu: "OC",
      region: "EUROPE",
      permissions: ["read", "view"],
    },
    {
      username: "Nora",
      lastName: "Lo",
      password: "nora123",
      userid: "lo.no",
      role: "TaskOwner",
      bu: "FAB",
      region: "EUROPE",
      permissions: ["create", "read", "update", "delete"],
    },
    {
      username: "Gabriel",
      lastName: "Gabriel",
      password: "gabriel123",
      userid: "@gabriel123",
      role: "TaskOwner",
      bu: "OC",
      region: "EUROPE",
      permissions: ["read", "view"],
    },
    {
      username: "pg",
      lastName: "Pg",
      password: "pg123",
      userid: "@pg123",
      role: "TaskOwner",
      bu: "BBY",
      region: "EUROPE",
      permissions: ["read", "view"],
    },
    {
      username: "Praveen",
      lastName: "Saini",
      password: "praveen123",
      userid: "saini.ps.3",
      role: "TaskOwner",
      bu: "BBY",
      region: "EUROPE",
      permissions: ["create", "read", "update", "delete"],
    },
    {
      username: "asha",
      lastName: "CR",
      password: "asha123",
      userid: "cr.ac",
      role: "ProjectManager",
      bu: "BBY",
      region: "EUROPE",
      permissions: ["create", "read", "update", "delete"],
    },
  
  ],
};
